import React from 'react';
import { Stage, Layer, Group, Rect, Text, Image, Line, Circle} from 'react-konva';

import { getRandomColor } from '../common/Util';
import { connect } from 'react-redux';
import { fetchLpsJson } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
//import { logoutNode } from '../../modules/nodes';

const PIXEL_ADJUSTMENT_X = 0;
const PIXEL_ADJUSTMENT_Y = 30;
const METERS_PER_FOOT = 0.3048;

function formatTime(m) {
  if (m) {
    let d = new Date(m);
    let hour = d.getHours().toString();
    let min = d.getMinutes().toString();;
    let sec = d.getSeconds().toString();;

    if (hour.length === 1 ) { hour = "0" + hour }
    if (min.length === 1) { min = "0" + min }
    if (sec.length === 1) {sec = "0" + sec}

    return hour + ":" + min + ":" + sec;
  } else {
    return "00:00:00";
  }
}


class NavMap2dPath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      x_img: null,
      nodes: {},
      node_info: {},
      paths: {},
      circles: [],
      paths_filtered: {},
      circles_filtered: [],
      anchors: {},
      text: "",
      zoom: 1,
      loading: false,
      zoom_in: null,
      zoom_out: null,
      reset: null,
    };

    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.drag = this.drag.bind(this);
    this.wheelZoom = this.wheelZoom.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onClear = this.onClear.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    console.log("mounted")
    this.loadImages();
  }

  componentWillUnmount() {
    console.log("Unmounting Path")
    this.unloadImages();
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("./img/" + this.props.background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));

    let x = new window.Image();
    x.src = require("./img/x.png");
    bg.addEventListener('load', () => this.setState({x_img: x}));

    let zoom_in = new window.Image();
    zoom_in.src = require('./img/zoom_in.png');
    zoom_in.addEventListener('load', ()=> this.setState({zoom_in: zoom_in}));

    let zoom_out = new window.Image();
    zoom_out.src = require('./img/zoom_out.png');
    zoom_out.addEventListener('load', ()=> this.setState({zoom_out: zoom_out}));

    let reset = new window.Image();
    reset.src = require('./img/reset.png');
    reset.addEventListener('load', ()=> this.setState({reset: reset}));
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));
    this.state.x_img.removeEventListener('load', () => this.setState({x_img: null}));
    this.state.zoom_in.removeEventListener('load', ()=>this.setState({zoom_in: null}));
    this.state.zoom_out.removeEventListener('load', ()=>this.setState({zoom_out: null}));
    this.state.reset.removeEventListener('load', ()=>this.setState({reset: null}));
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.state.anchors).length !== Object.keys(this.props.anchors).length) {
      let newAnchors = {};
      for (const anchorId in this.props.anchors) {
        const anchor = this.props.anchors[anchorId];
        let newX = (anchor.x + this.props.origin.x) * this.props.pixels_per_meter;
        let newY = this.props.height - ((anchor.y + this.props.origin.y) * this.props.pixels_per_meter);
        newAnchors[anchorId] = {nodeId: anchorId, x: anchor.x, y: anchor.y, new_x: newX , new_y: newY, selected: false};
      }
      this.setState({anchors: newAnchors});
    }

    if (Object.keys(this.state.nodes).length !== Object.keys(this.props.nodes).length ||
        prevProps.pixels_per_meter !== this.props.pixels_per_meter ||
        prevProps.from !== this.props.from ||
        prevProps.to!== this.props.to) {

      console.log("Path component update");

      //clear stage
      let nodes = this.refs['nodes'];
      nodes.destroyChildren();

      let newNodes = {};
      let paths = {...this.state.paths};
      let paths_filtered = {...this.state.paths_filtered};

      for (const nodeId in this.props.nodes) {
        const node = this.props.nodes[nodeId];
        let newX = (node.x + this.props.origin.x) * this.props.pixels_per_meter - PIXEL_ADJUSTMENT_X;
        let newY = this.props.height - ((node.y + this.props.origin.y) * this.props.pixels_per_meter) - PIXEL_ADJUSTMENT_Y;
        newNodes[nodeId] = { name: node.name, nodeId: nodeId, x: node.x, y: node.y, new_x: newX, new_y: newY, selected: false, type: node.type, timestamp: node.timestamp, meas_id: node.meas_id};

        if (!paths.hasOwnProperty(nodeId)) { //initiate new nodes
          paths[nodeId] = {type: '', color: getRandomColor(), path: []}
        }

        if (!paths_filtered.hasOwnProperty(nodeId)) { //initiate new nodes
          paths_filtered[nodeId] = {type: '', color: getRandomColor(), path: []}
        }
      }

      for (const nodeId in paths) {
        paths[nodeId].path = [];
        paths_filtered[nodeId].path = [];
      } //clear out the paths

      this.setState({nodes: newNodes, paths: paths, paths_filtered: paths_filtered, circles: [], circles_filtered: [], loading: true}, this.fetchLpsHistory(Object.keys(newNodes)));
    }
  }

  fetchLpsHistory(nodes) {
    fetchLpsJson(this.props.authToken, this.props.currentSiteId, {from: this.props.from, to: this.props.to, unfiltered: 1, filtered: 0, stats: 0})
      .then(json => { return json.unfiltered_tracks})
      //.then(tracks => {console.log(nodes); console.log(Object.keys(tracks))})
      .then(tracks => {
        //console.log(JSON.stringify(tracks));
        let paths = {...this.state.paths};
        let circles = [...this.state.circles];

        for (const n in tracks) {
          if (nodes.indexOf(n) < 0) { continue; }

          let meas_id = null;
          tracks[n].locations.forEach(loc => {
          if (meas_id === loc.measurement) { console.log("duplicated " + meas_id); return } else {meas_id = loc.measurement}
          let newX = (loc.point.x * METERS_PER_FOOT + this.props.origin.x) * this.props.pixels_per_meter
          let newY = this.props.height - ((loc.point.y * METERS_PER_FOOT + this.props.origin.y) * this.props.pixels_per_meter)
          paths[n].path.push(newX)
          paths[n].path.push(newY)

          let key = n + "_" + loc.measurement + loc.timestamp;
          /*let data = tracks[n].tracker.name + ":" + n + ", " +
                     "Meas_id:" + loc.measurement  + ", " +
                     "Timestamp:" + formatTime(loc.timestamp) + ", " +
                     "Type:" + loc.type + ", " +
                     "Ranges:" + loc.ranges + ", " +
                     "X:" + loc.point.x + ", " +
                     "Y:" + loc.point.y;*/

         let data = tracks[n].tracker.name + ", " +
                    "Meas_id:" + loc.measurement  + ", " +
                    "Timestamp:" + formatTime(loc.timestamp) + ", " +
                    "Type:" + loc.type + ", " +
                    "Ranges:" + loc.ranges + ", " +
                    "X:" + loc.point.x.toFixed(1) + ", " +
                    "Y:" + loc.point.y.toFixed(1);

          circles.push(<Circle key={key} data={data} radius={4} fill={paths[n].color}  x={newX} y={newY} onMouseOver={this.onHover} onMouseOut={this.onClear}/>) // onMouseOut={this.onClear}
          })//end forEach
        }
        this.setState({paths: paths, circles: circles}, ()=>{console.log( "MakePath done for unfiltered - " + circles.length)});
      })
      .then(()=>this.fetchLpsHistoryFiltered(nodes))
  }

  fetchLpsHistoryFiltered(nodes) {
    fetchLpsJson(this.props.authToken, this.props.currentSiteId, {from: this.props.from, to: this.props.to, unfiltered: 0, filtered: 1, stats: 0}) //get filtered first
      .then(json => { return json.tracks})
      //.then(tracks => {console.log(nodes); console.log(Object.keys(tracks))})
      .then(tracks => {
        let paths_filtered = {...this.state.paths_filtered};
        let circles_filtered = [...this.state.circles_filtered];

        for (const n in tracks) {
          if (nodes.indexOf(n) < 0) { continue; }

          let meas_id = null;
          tracks[n].locations.forEach(loc => {
          if (meas_id === loc.measurement) { console.log("duplicated " + meas_id); return } else {meas_id = loc.measurement}
          let newX = (loc.point.x * METERS_PER_FOOT + this.props.origin.x) * this.props.pixels_per_meter
          let newY = this.props.height - ((loc.point.y * METERS_PER_FOOT + this.props.origin.y) * this.props.pixels_per_meter)
          paths_filtered[n].path.push(newX)
          paths_filtered[n].path.push(newY)

          let key = n + "_2_" + loc.measurement + loc.timestamp;
          let data = "REMOVED " + tracks[n].tracker.name + ", " +
                     "Meas_id:" + loc.measurement  + ", " +
                     "Timestamp:" + formatTime(loc.timestamp) + ", " +
                     "Type:" + loc.type + ", " +
                     "Ranges:" + loc.ranges + ", " +
                     "X:" + loc.point.x.toFixed(1) + ", " +
                     "Y:" + loc.point.y.toFixed(1);

          circles_filtered.push(<Circle key={key} data={data} radius={4} fill={paths_filtered[n].color}  x={newX} y={newY} onMouseOver={this.onHover} onMouseOut={this.onClear}/>) //onMouseOut={this.onClear}
          })//end forEach
        }
        this.setState({loading: false, paths_filtered: paths_filtered, circles_filtered: circles_filtered}, ()=>console.log( "MakePath done for filtered " + circles_filtered.length));
      })
  }

  zoomIn(e) {
    if (this.state.zoom >= 2) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom + .1}))
    layer.scale({x: this.state.zoom , y: this.state.zoom})
    layer.draw();
  }

  zoomOut(e) {
    if (this.state.zoom <= 1) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom - .1}))
    layer.scale({x: this.state.zoom, y: this.state.zoom})
    layer.draw();
  }

  drag(e) {
    let layer = this.refs['map']
    layer.startDrag();
  }

  wheelZoom(e) {
    /*
    if (e.evt.deltaY > 0) {
      this.zoomOut(e)
    } else {
      this.zoomIn(e)
    }
    */
  }

  reset(e) {
    let map = this.refs['map']
    map.scale({x: 1, y: 1});
    map.setPosition({x: 0, y: 0});
    map.draw();
    this.setState({zoom: 1});
  }

  onHover(e) {
    this.setState({text: e.target.attrs.data})
  }

  onClear(e) {
    this.setState({text: ""})
  }

   render() {
    let showPaths = [];
    for(const nodeId in this.state.paths) {
      const color = this.state.paths[nodeId].color;
      const p = this.state.paths[nodeId].path;
      if (p.length === 0 ) {continue}
      showPaths.push(<Line key={nodeId + "_path"} node={this.state.nodes[nodeId]} ref={nodeId} points={p} stroke={color} strokeWidth={1}/>);
    }

    let showPathsFiltered = [];
    for(const nodeId in this.state.paths_filtered) {
      const color = this.state.paths_filtered[nodeId].color;
      const p = this.state.paths_filtered[nodeId].path;
      if (p.length === 0 ) {continue}

      showPathsFiltered.push(<Line key={nodeId + "_path2"} node={this.state.nodes[nodeId]} ref={nodeId} points={p} stroke={color} strokeWidth={1}/>);
    }

    let textInfo = [];
    if (this.state.text !== "") {
      let color = "red";
      if (this.state.text.startsWith("REMOVED")) { color = '#0097ff'}
      textInfo.push(<Rect key="textInfo" x={5} y={0} width={1000} height={30} fill={'#ffffff'} stroke={'rgba(0, 0, 0, 0.25'}/>);
      textInfo.push(<Text key="textINfo2" x={10} y ={10} text={this.state.text} fontSize={14} fontFamily={'Lato'} fill={color}/>);
    }

    let showAnchors = []
    for (const nodeId in this.state.anchors) {
      const n = this.state.anchors[nodeId];
      let thisNode = <Rect fill={'#44d7b6'} width={8} height={8} x={n.new_x} y ={n.new_y - 5} key={n.nodeId} />
      showAnchors.push(thisNode);
    }

    return (
      <div className="NavMap2D">
      <div className="loading" style={this.state.loading? {}: {display: "none"}}>LOADING...</div>
        <Stage width={this.props.width} height={this.props.height >= 700 ? this.props.height : 700}>
          <Layer ref="map" onDblClick={this.zoomIn} onMouseDown={this.drag} onWheel={this.wheelZoom} y={this.props.height >= 700 ? 0 : (700-this.props.height)/2}>
            <Image
              x={0}
              y={0}
              width = {this.props.width}
              height= {this.props.height}
              image={this.state.background}
            />
            <Group ref="nodes">
              {this.state.circles}
              {showPaths}
              {this.state.circles_filtered}
              {showPathsFiltered}
              {textInfo}
            </Group>
              {showAnchors}
            </Layer>
            <Layer>
              <Image image={this.state.reset} x={this.props.width - 40} y={5} width={30} height={30} key={'resetImg'} onClick={this.reset}/>
              <Image image={this.state.zoom_in} x={this.props.width - 40} y={40} width={30} height={30} key={'zoomInImg'} onClick={this.zoomIn}/>
              <Image image={this.state.zoom_out} x={this.props.width - 40} y={75} width={30} height={30} key={'zoomOutImg'} onClick={this.zoomOut}/>
            </Layer>
        </Stage>
      </div>
    )
  }
}

export default connect(
  state => ({ authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(NavMap2dPath);
