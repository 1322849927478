import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Sort from '../common/Sort';
import Filter from '../common/Filter';
import { Link } from 'react-router-dom';

const DocksTable = ({
  origData,
  data,
  error,
  setTableData,
  sortedColumnIndex,
  setSortedData
}) => (
  <div className="main">
    <h3>SMARTDOCK</h3>
    <span>{error}</span>
    <div className="button-container"><Link to={"/dashboard/settings/editdock/new"}>ADD NEW</Link></div>
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>NAME<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            <Link to={`/dashboard/settings/editdock/${data[props.rowIndex].node}`}>{data[props.rowIndex].name}</Link>
          </Cell>
        )}
        width={160}
      />
      <Column
        header={<Cell>DOCK ID<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].node}
          </Cell>
        )}
        width={340}
      />
      <Column
        header={<Cell>EXTERIOR<Sort thisColumnIndex={5} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
          {data[props.rowIndex].exterior_name}
          </Cell>
        )}
        width={215}
      />
      <Column
        header={<Cell>INTERIOR<Sort thisColumnIndex={6} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].interior_name}
          </Cell>
        )}
        width={215}
      />
      <Column
        header={<Cell>STATUS<Sort thisColumnIndex={4} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].connected ? 'Connected' : 'Disconnected'}
          </Cell>
        )}
        width={145}
      />
    </Table>
  </div>
);

export default DocksTable;
