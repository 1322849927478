import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
import Sort from '../common/Sort';
import Filter from '../common/Filter';

const AnchorConfigTable = ({origData, type, data, error, setTableData, sortedColumnIndex, setSortedData}) => (
  <div className="main">
    <h3>ANCHOR CONFIGS</h3>
    <span id="error">{error}</span>
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>NAME<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            <Link to={`/dashboard/settings/devices/edit_anchor_config/${data[props.rowIndex].node}`}>{data[props.rowIndex].name}</Link>
          </Cell>
        )}
        width={175}
      />
      <Column
        header={<Cell>HARDWARE ID<Sort thisColumnIndex={3} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].hardware_id}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>NID</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.nid}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>CHANNEL</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.channel}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>MCS</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.mcs}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>NSS</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.nss}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>OBW</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.obw}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>PAYLOAD</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.payload_length}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>TX POWER</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.tx_power}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>MODE</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.mode}
          </Cell>
        )}
        width={80}
      />
      <Column
        header={<Cell>NPKT</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.npkt}
          </Cell>
        )}
        width={75}
      />

      <Column
        header={<Cell>ANCH ALARM</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.anch_alarm}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>ANCH HEARTBEAT</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.anch_heartbeat}
          </Cell>
        )}
        width={175}
      />
      <Column
        header={<Cell>BURST TIMEOUT</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.burst_timeout}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>LOC MIN ANCH</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.loc_min_anch}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>LOC MAX ANCH</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.loc_max_anch}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>HUB</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.hub}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>IMAGE</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.image}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>RADIO HW</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.radio_hardware}
          </Cell>
        )}
        width={175}
      />
      <Column
        header={<Cell>RADIO FW</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.radio_firmware}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>MAVS</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.mavs}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>STM</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.stm}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>ANCH VER</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.anchor}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>INTERNAL MAC</Cell>}
        cell={props => (
          <Cell {...props} style={{color: data[props.rowIndex].configs.anchor.addr.toLowerCase() ===  data[props.rowIndex].configs.lps_control.cal_tx_addr.toLowerCase() ? "" : "#ff0000"}}>
            {data[props.rowIndex].configs.anchor.addr}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>CAL</Cell>}
        cell={props => (
          <Cell {...props}>
            {new Date(data[props.rowIndex].configs.lps_control.cal_timestamp).toString()}
          </Cell>
        )}
        width={250}
      />
      <Column
        header={<Cell>CAL TX ADDR</Cell>}
        cell={props => (
          <Cell {...props} style={{color: data[props.rowIndex].configs.anchor.addr.toLowerCase() === data[props.rowIndex].configs.lps_control.cal_tx_addr.toLowerCase() ? "" : "#ff0000"}}>
            {data[props.rowIndex].configs.lps_control.cal_tx_addr}
          </Cell>
        )}
        width={150}
      />
    </Table>
  </div>
);
export default AnchorConfigTable;
