import omit from 'lodash/omit';
import merge from 'lodash/merge';
import get from 'lodash/get';
import { API_ROOT } from '../DashboardConfig';
import { fetchAPI, fetchSiteJson } from './api';
import { getCurrentSiteId } from './sites';

const LOAD = 'nodes/LOAD';
const FETCHING = 'nodes/FETCHING';
const ADD = 'nodes/ADD';
const ERROR = 'nodes/ERROR';
const REMOVE = 'nodes/REMOVE';
const UPDATE = 'nodes/UPDATE';
const REFRESH = 'nodes/REFRESH';

const initialState = {
  byId: {},
  allIds: [],
  status: 'pending',
  error: '',
};

// ======================
// The Reducer
// ======================
export default function reducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case FETCHING:
      return Object.assign({}, state, { allIds: [], byId: {} });
    case LOAD:
      return Object.assign({}, state, action.payload, { status: 'success', /* error: '' */});
    case ADD:
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.payload]: {name: '', hardware_id: '', constellation: ''},
        }),
        allIds: [...state.allIds, action.payload],
        error: '',
      });
    case ERROR:
      return Object.assign({}, state, { error: action.payload });
    case REMOVE:
      return Object.assign({}, state, {
        byId: omit(state.byId, action.payload),
        allIds: state.allIds.filter(id => id !== action.payload),
      });
    case UPDATE:
      return merge({}, state, {
        byId: { [action.payload.id]: action.payload.state },
      });
    case REFRESH:
      return merge({}, state, {
        byId: { [action.payload.id]: action.payload.update },
      });
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}

// =======================
// ==     Selectors     ==
// =======================
export function getNodesArray(state) {
  return state.nodes.allIds.map(id => state.nodes.byId[id])
}

export function getAnchorsArray(state) {
  return state.nodes.allIds.map(id => state.nodes.byId[id]).filter(n => n.node_type === 'anchor')
}

export function getTrackersArray(state) {
  return state.nodes.allIds.map(id => state.nodes.byId[id]).filter(n => n.node_type === 'tracker')
}

export function getNodesForTable(state) {
  return state.nodes.allIds.map((id) => {
    const node = state.nodes.byId[id];
    const dataNode = {
      node: id,
      name: node.name,
      node_type: node.node_type,
      hubId: '',
      hardware_id: node.hardware_id,
      constellation: node.constellation,
      rssi: '',
      battery: '',
      connection: false,
      mediaBlobUrl: node.mediaBlobUrl,
      version: '',
      configs: {
        camera: {
          auto_capture_period_minutes: 0,
          auto_capture_media_format: 'image',
          alarm_media_format: 'image',
          ambient_light_threshold: 8000,
          ir_led_brightness: 0.5,
        },
        motion: {
          motion_sensitivity: 15,
        },
        anchor: {},
        tracker: {},
        lps_control: {},
      },
      aka: '',
      role: '',

    };

    if (node.events) {
      if (node.events.radio && node.events.radio.rssi) {
        dataNode.rssi = node.events.radio.rssi;
      }
      if (node.events.battery && node.events.battery.voltage) {
        dataNode.battery = node.events.battery.voltage;
      }
      if (node.events.system && node.events.system.connection) {
        dataNode.connection = node.events.system.connection.connected;
      }

      //https://dfsniot.atlassian.net/browse/LPS-221
      //If there is no system:connection event in the API, mark the device as "connected" in the UI
      if (!node.events.hasOwnProperty('system') || !node.events.system.hasOwnProperty('connection')) {
        dataNode.connection = true;
      }

      if (node.events.system && node.events.system.firmware_version) {
        dataNode.version = node.events.system.firmware_version;
      }
    }

    if (node.configs) {
      if (node.configs.camera && node.configs.camera.auto_capture_period_minutes) {
        dataNode.configs.camera.auto_capture_period_minutes = node.configs.camera.auto_capture_period_minutes;
      }
      if (node.configs.camera && node.configs.camera.auto_capture_media_format) {
        dataNode.configs.camera.auto_capture_media_format = node.configs.camera.auto_capture_media_format;
      }
      if (node.configs.camera && node.configs.camera.alarm_media_format) {
        dataNode.configs.camera.alarm_media_format = node.configs.camera.alarm_media_format;
      }
      if (node.configs.camera && node.configs.camera.ambient_light_threshold) {
        dataNode.configs.camera.ambient_light_threshold = node.configs.camera.ambient_light_threshold;
      }
      if (node.configs.camera && node.configs.camera.ir_led_brightness) {
        dataNode.configs.camera.ir_led_brightness = node.configs.camera.ir_led_brightness;
      }
      if (node.configs.motion && node.configs.motion.motion_sensitivity) {
        dataNode.configs.motion.motion_sensitivity = node.configs.motion.motion_sensitivity;
      }
    }

   if (node.node_type === 'anchor' && node.configs.anchor) {
     dataNode.configs.anchor.x = node.configs.anchor.x;
     dataNode.configs.anchor.y = node.configs.anchor.y;
     dataNode.configs.anchor.z = node.configs.anchor.z;
     //dataNode.configs.anchor.lps_name = node.configs.anchor.name;
     dataNode.configs.anchor.lps_addr = node.configs.anchor.addr;
     //node.configs.hasOwnProperty('lps_control') && node.configs.lps_control.hasOwnProperty('cal')? dataNode.configs.lps_control.cal = node.configs.lps_control.cal : dataNode.configs.lps_control.cal = null;
     dataNode.version = node.events && node.events.system && node.events.system.versions && node.events.system.versions.image;
   } else {
     node.configs['anchor'] = {};
   }

   if (node.node_type === 'tracker' && node.configs.tracker) {
     //dataNode.configs.tracker.lps_name = node.configs.tracker.name;
     node.configs.tracker.hasOwnProperty('addr') ? dataNode.configs.tracker.lps_addr = node.configs.tracker.addr : dataNode.configs.tracker.lps_addr = null;
     node.configs.tracker.hasOwnProperty('track_backoff') ?  dataNode.configs.tracker.track_backoff = node.configs.tracker.track_backoff : dataNode.configs.tracker.track_backoff = null;
     node.configs.tracker.hasOwnProperty('locate_mode') ?  dataNode.configs.tracker.locate_mode = node.configs.tracker.locate_mode : dataNode.configs.tracker.locate_mode = null;
     node.configs.tracker.hasOwnProperty('tracking_mode') ? dataNode.configs.tracker.tracking_mode = node.configs.tracker.tracking_mode : dataNode.configs.tracker.tracking_mode = null;
     node.configs.tracker.hasOwnProperty('tdoa_tracking_mode') ?  dataNode.configs.tracker.tdoa_tracking_mode = node.configs.tracker.tdoa_tracking_mode : dataNode.configs.tracker.tdoa_tracking_mode = null;
     //node.configs.lps_control.hasOwnProperty('power_enable')? dataNode.configs.lps_control.power_enable = node.configs.lps_control.power_enable : dataNode.configs.lps_control.power_enable = null;
     //node.configs.lps_control.hasOwnProperty('cal') ? dataNode.configs.lps_control.cal = node.configs.lps_control.cal : dataNode.configs.lps_control.cal = null;
     node.configs.tracker.hasOwnProperty('tracker_type') ? dataNode.configs.tracker.tracker_type = node.configs.tracker.tracker_type : dataNode.configs.tracker.tracker_type = null;
     if (node.configs.tracker.tracker_type && node.configs.tracker.tracker_type === 'phone' && node.events && node.events.system && node.events.system.connection) {
       dataNode.connection = true;
     }
   } else {
     node.configs['tracker'] =  {};
   }

   if (node.tags) {
     dataNode.aka = node.tags.aka ? node.tags.aka : '';
     dataNode.role = node.tags.role ? node.tags.role : '';
   }

   return dataNode;
  });
}

export function getNodesForConfigTable(state) {
  return state.nodes.allIds.map((id) => {
    const node = state.nodes.byId[id];
    const dataNode = {
      node: id,
      name: node.name,
      node_type: node.node_type,
      hardware_id: node.hardware_id,
      connection: get(node, "events.system.connection", false),
      configs: {
        anchor: {
          nid: get(node, "configs.anchor.nid", "None"),
          channel: get(node, "configs.anchor.channel", "None"),
          mcs: get(node, "configs.anchor.mcs", "None"),
          nss: get(node, "configs.anchor.nss", "None"),
          obw: get(node, "configs.anchor.obw", "None"),
          payload_length: get(node, "configs.anchor.payload_length", "None"),
          tx_power: get(node, "configs.anchor.tx_power", "None"),
          mode: get(node, "configs.anchor.mode", "None"),
          npkt: get(node, "configs.anchor.npkt", "None"),
          anch_alarm: get(node, "configs.anchor.anch_alarm", "None"),
          anch_heartbeat: get(node, "configs.anchor.anch_heartbeat", "None"),
          burst_timeout: get(node, "configs.anchor.anch_heartbeat", "None"),
          loc_min_anch: get(node, "configs.anchor.loc_min_anch", "None"),
          loc_max_anch: get(node, "configs.anchor.loc_max_anch", "None"),
          addr: get(node, "configs.anchor.addr", "None"),
          name: get(node, "configs.anchor.name", "None"),
          x: get(node, "configs.anchor.x", "None"),
          y: get(node, "configs.anchor.y", "None"),
          z: get(node, "configs.anchor.z", "None"),
          hub: get(node, "events.system.versions.hub", "None"),
          image: get(node, "events.system.versions.image", "None"),
          radio_hardware: get(node, "events.system.versions.radio_hardware", "None"),
          radio_firmware: get(node, "events.system.versions.radio_firmware", "None"),
          mavs: get(node, "events.system.versions.mavs", "None"),
          stm: get(node, "events.system.versions.stm", "None"),
          anchor: get(node, "events.system.versons.anchor", "None")
        },
        tracker: {
          nid: get(node, "configs.tracker.nid", "None"),
          channel: get(node, "configs.tracker.channel", "None"),
          mcs: get(node, "configs.tracker.mcs", "None"),
          nss: get(node, "configs.tracker.nss", "None"),
          obw: get(node, "configs.tracker.obw", "None"),
          payload_length: get(node, "configs.tracker.payload_length", "None"),
          tx_power: get(node, "configs.tracker.tx_power", "None"),
          mode: get(node, "configs.tracker.mode", "None"),
          npkt: get(node, "configs.tracker.npkt", "None"),
          inert_data: get(node, "configs.tracker.inert_data", "None"),
          loc_min_anch: get(node, "configs.tracker.loc_min_anch", "None"),
          loc_max_anch: get(node, "configs.tracker.loc_max_anch", "None"),
          addr: get(node, "configs.tracker.addr", "None"),
          name: get(node, "configs.tracker.name", "None"),
          firmware_version: get(node, "events.system.firmware_version", "None"),
          hardware_version: get(node, "events.system.hardware_version", "None")
        },
        lps_control: {
          cal_timestamp: get(node, "configs.lps_control.cal.timestamp", ""),
          cal_tx_addr: get(node, "configs.lps_control.cal.tx_addr", "None"),
        },
      }
    };

    return dataNode;
  });
}

export function getNodesHash(state) {
  return state.nodes.byId;
}

export function getRecentCameraEvents(state) {
  let activeNodes = getNodesArray(state).filter(n => n.hasOwnProperty('events') && n.events.hasOwnProperty('camera')
    && n.events.camera.hasOwnProperty('media_ready')
  );

  activeNodes.forEach((n,i) => {
    activeNodes[i].update = {};
    activeNodes[i].update.events = n.events;
  })

  activeNodes.sort((a, b) => b.update.events.camera.media_ready.timestamp - a.update.events.camera.media_ready.timestamp);

  let maxNodeCount = 1000000;
  return activeNodes.slice(0, maxNodeCount);
}

export function getAllCameraEvents(state) {
  let activeNodes = getNodesArray(state).filter(n => n.hasOwnProperty('events') && n.events.hasOwnProperty('camera')
    && n.events.camera.hasOwnProperty('media_ready')
  );

  let allNodes = getNodesArray(state).filter(n => n.node_type === 'camera');
  let inActiveNodes = allNodes.filter(n => !activeNodes.includes(n))

  activeNodes.forEach((n,i) => {
    activeNodes[i].update = {};
    activeNodes[i].update.events = n.events;
  })

  //activeNodes.sort((a, b) => b.update.events.camera.media_ready.timestamp - a.update.events.camera.media_ready.timestamp);
  activeNodes.sort((a, b) => a.name.localeCompare(b.name));
  let maxNodeCount = 1000000;
  return activeNodes.concat(inActiveNodes).slice(0, maxNodeCount);
}

export function getNodeFormInfo(state, id) {
  const node = state.nodes.byId[id];
  const props = {};
  if (node.events) {
    if (node.events.battery && node.events.battery.voltage) {
      props.voltage = node.events.battery.voltage;
    }
    if (node.events.radio && node.events.radio.rssi) {
      props.rssi = node.events.radio.rssi;
    }
    if (node.events.environmental && node.events.environmental.humidity) {
      props.humidity = node.events.environmental.humidity;
    }
    if (node.events.environmental && node.events.environmental.temperature) {
      props.temp = node.events.environmental.temperature;
    }
  }
  return props;
}

export function getNodeConfigs(state, id) {
  const node = state.nodes.byId[id];
  return node.configs || {};
}

export function getNodesForSelectOptions(state) {
  return state.nodes.allIds.map((id) => {
    return {
      value: id,
      label: state.nodes.byId[id].name,
    };
  });
}

// ======================
// Action Creators
// ======================
export function loadNodes(nodes) {
  let nodeKeys = Object.keys(nodes);
  let nodeData = Object.values(nodes);

  for(let i=0; i<=nodeData.length-1; i++){
    nodeData[i].node = nodeKeys[i];
  }
  let fliteredNodes = nodeData.filter(n => n.node_type !== 'hub');
  const byId = {};
  const allIds = [];
  fliteredNodes.forEach((n) => {
    byId[n.node] = n;
    allIds.push(n.node);
  });
  return { type: LOAD, payload: { byId, allIds } };
}

export function addNode(id) {
  return { type: ADD, payload: id };
}

export function removeNode(id) {
  return { type: REMOVE, payload: id };
}

export function editNode(node) {
  return { type: UPDATE, payload: node };
}

export function refreshNode(node) {
  return { type: REFRESH, payload: node};
}

export function nodeError(message) {
  return { type: ERROR, payload: message };
}

export function fetching() {
  return { type: FETCHING };
}

export function logoutNode() {
  return { type: 'LOGOUT'};
}

// ======================
// Async Action Creators
// ======================


//if 401 then logout
export function triggerCamera(node, format) {
  console.log('triggering camera');
  return (dispatch, getState) => {
    const state = getState();
    const { authToken } = state;
    const init = {
        method: 'post',
        body: JSON.stringify({
            'camera': {
              'capture': {
                'format': format
              }
            }
        }),
    };

    const path = `nodes/${node}/action`
    fetchSiteJson(path, authToken, getCurrentSiteId(state), init)
      .then(json => console.log(json))
      .catch(error => { error.status === 401 ? dispatch(logoutNode()) : console.log(error)})
  }
}

export function fetchNodes() {
  return (dispatch, getState) => {
    dispatch(fetching());
    const { authToken, sites } = getState();
    // console.log(sites);

    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}?detailed`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then(json => dispatch(loadNodes(json.state.nodes)))
      .catch(err => console.log('Node Fetching Error :', err));
  };
}

export function fetchMediaBlob(url, node, extraInit = {}, extraHeaders = {}) {
  console.log("Nodes: fetching media blob", url);
  return (dispatch, getState) => {
    const { authToken } = getState();
    fetchAPI(url, authToken, extraInit, extraHeaders)
     .then(response =>
        { if (response.status === 401) {
           console.log('401 error at media blog');
           dispatch(logoutNode());
        } else {
           return response;
        }
      })
      .then(response => response.blob())
      .then(blob => {
        console.log(blob);
        dispatch(editNode({
          node,
          mediaBlobUrl: URL.createObjectURL(blob)
        }))
      })
      .catch(error => console.log(error));
  }
}

export function createNode(node) {
  return (dispatch, getState) => {
    const { authToken, sites } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}/nodes`;
    const init = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(node),
    };
    fetch(url, init)
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        if (json.message) {
          dispatch(nodeError(json.message));
        } else {
          dispatch(nodeError(''));
          dispatch(addNode(json.id));
          dispatch(fetchNode(json.id));
        }
      });
  };
}

export function updateNode(id, nodeUpdates) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(nodeUpdates),
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        if (json.message) {
          dispatch(nodeError(json.message));
        } else {
          dispatch(nodeError(''));
          dispatch(fetchNode(id));
        }
      });
  };
}

export function deleteNode(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then((resp) => {
        if (resp.ok) { dispatch(removeNode(id)); }
      })
  };
}

export function fetchNode(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then(json => {
        if (json.message) {
          dispatch(nodeError(json.message));
        } else {
          if (!json.state.constellation) {
            json.state.constellation = '';
          }
          dispatch(editNode(json));
        }
      });
  };
}

export function restartNode(node) {
  console.log("Restart node - " + node);
  return (dispatch, getState) => {
    const state = getState();
    const { authToken } = state;
    if (authToken === null || authToken === undefined) {
      return;
    }

    const path = `nodes/${node}/action`;
    const init = {
      method: 'post',
      body : JSON.stringify({
        "system": {"reset": {}}
      })
    }

    fetchSiteJson(path, authToken, getCurrentSiteId(state), init)
    .then(json => {
        console.log("Response: " + JSON.stringify(json))
    })
    .catch(error => {
      console.err(error);
      return false;
    });
  }
}

export function calibrateAnchor(node) {
  console.log("Calibrate anchor "  +  node)

  return (dispatch, getState) => {
    const state = getState();
    const { authToken } = state;
    if (authToken === null || authToken === undefined) {
      return;
    }

    const path = `nodes/${node}/action`;
    const init = {
      method: 'post',
      body : JSON.stringify({
        "anchor": {"calibrate":{"npkt":25}}
      })
    }

    fetchSiteJson(path, authToken, getCurrentSiteId(state), init)
    .then(json => {
      if (json.hasOwnProperty("action_id")) {
        console.log("Response: " + JSON.stringify(json))
      } else {
        console.err("Error: " + json);
        dispatch(nodeError(json));
      }
    })
    .catch(error => {
      console.err(error);
      dispatch(nodeError(error));
    });
  }
}

export function calibrateNode(node) {
  console.log("Calibrate tracker  - " + node)

  return (dispatch, getState) => {
    const state = getState();
    const { authToken } = state;
    if (authToken === null || authToken === undefined) {
      return;
    }

    const path = `nodes/${node}/action`;
    const init = {
      method: 'post',
      body : JSON.stringify({
        "tracker": {"inject":{"command":"loopback 25"}}
      })
    }

    fetchSiteJson(path, authToken, getCurrentSiteId(state), init)
    .then(json => {
        if (json.hasOwnProperty("action_id")) {
          console.log("Response: " + JSON.stringify(json));
        } else {
          console.err("Error: " + json);
          dispatch(nodeError(json));
        }
    })
    .catch(error => {
      console.err(error);
      dispatch(nodeError(error));
    });
  }
}
