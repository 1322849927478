import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { convertSiteTime } from './ChartUtil'

function makeDataSet(site_stats, durationRaw, zone_config) {
  let labels = [];
  let color = [];
  let data = [];
  let total = 0;

  if (site_stats && Object.keys(site_stats).length > 0 && durationRaw && Object.keys(zone_config).length > 0) {
    for (const z in site_stats.time_by_zone) {
      let dw = parseInt(site_stats.time_by_zone[z] * durationRaw, 10);
      total = total + dw

      if (site_stats.time_by_zone[z] < .001) {
        continue;
      }

      labels.push(zone_config[z].name + " " + convertSiteTime(dw))
      color.push("#" + zone_config[z].color)
      data.push(site_stats.time_by_zone[z].toFixed(2))
    }
  }
  return {labels: labels, color: color, data: data, total: total}
}

const options = {
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 75,
  legend: {
       display: false,
   },
   tooltips: {
     callbacks: {
       label: function(tooltipItem, data) {
         var label = data.labels[tooltipItem.index];
         return label
       }
     }
   }
};

class ZoneDonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      result: {labels: [], color: [], data: [], total : 0},
    }

  }

  componentDidMount() {
    let result = makeDataSet(this.props.site_stats, this.props.durationRaw,this.props.zone_config);
    this.setState({result: result})
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.site_stats) !== JSON.stringify(this.props.site_stats) ||
        //Object.keys(prevProps.site_stats).length !== Object.keys(this.props.site_stats).length ||
        Object.keys(prevProps.zone_config).length !== Object.keys(this.props.zone_config).length ||
        prevProps.durationRaw !== this.props.durationRaw) {
          let result = makeDataSet(this.props.site_stats, this.props.durationRaw,this.props.zone_config);
          this.setState({result: result})
    }
  }

  render() {
    return (
      <div className="SitePieChartContainer">
         <Doughnut
              width={50}
              height={50}
              data={{labels: this.state.result.labels, datasets: [{borderWidth: .2, backgroundColor: this.state.result.color, data: this.state.result.data}]}}
              options={options}
          />
         <div className="PieChartDetail">
           <div>TIME IN ZONE</div>
           <div>{convertSiteTime(this.state.result.total)}</div>
           <div>100%</div>
         </div>
      </div>
    )
  }
}

export default ZoneDonutChart;
