import React from 'react';
import TimeIntervalChart from '../charts/TimeIntervalChart';
import {makeDistanceXAxis} from '../charts/ChartUtil';
import { IMG_CONFIG } from '../2dConfig';

const METERS_PER_FOOT = 0.3048;
const LIMIT = 6;

class WmsDistanceByZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_legend: false,
      labels: [],
      data: [],
      x_axis: [], //sorted distance
      sorted_data: [], //sorted distance
    };

    this.setLegend= this.setLegend.bind(this);
  }

  componentDidMount() {
      //this.makeData();
      this.makeSortedData();
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.zone_config).length !== Object.keys(this.props.zone_config).length ||
        JSON.stringify(prevProps.sorted_nodes) !== JSON.stringify(this.props.sorted_nodes) ||
        JSON.stringify(prevProps.asset_stats) !== JSON.stringify(this.props.asset_stats) ||
        prevProps.from !== this.props.from || prevProps.to !== this.props.to ||
        prevProps.expand !== this.props.expand)
    {
      this.makeSortedData();
    }
  }

  makeSortedData() {
    let labels = Object.values(this.props.zone_config).map(z => z.name).sort()//.sort((a,b) => a.localCompare(b))
    if (!this.props.expand) { labels = labels.slice(0, LIMIT)}

    //{name: {id: id, distance:0, color: 0)
    let zones = {}
    let label_id_map = {} //{label: id}
    for (const z in this.props.zone_config) {
      let zn = this.props.zone_config[z]
      zones[z] = { name: zn.name, distance: 0, id: z, color: "#" + zn.color}
      label_id_map[zn.name] = z;
    }

    //sum up the distances
    this.props.sorted_nodes.forEach(n => {
      if (this.props.asset_stats.hasOwnProperty(n) && this.props.asset_stats[n] !== undefined && this.props.asset_stats[n].hasOwnProperty('zones')) {
        let zone_stats = this.props.asset_stats[n].zones;
        for (const z in zone_stats) {
          if (zones.hasOwnProperty(z)) {
            zones[z].distance = zone_stats[z].distance + zones[z].distance;
          }
        }
      }
    })

    //construct graph
    let all_data = [];
    let max = 0;
    labels.forEach(label => {
      let distance = zones[label_id_map[label]].distance * METERS_PER_FOOT;

      let props = {};
      props[label] = distance.toFixed(2) + " m";
      let interval = {start: 0, duration: 0, distance: distance, color: zones[label_id_map[label]].color, tooltip: {id: label_id_map[label], props: props}};
      all_data.push([interval])
      if (max < distance) { max = distance }
    })

    max = max + 20;
    for (let zones of all_data) {
      for (let zone of zones) {
          let distance = parseFloat(zone.distance) / max;
          zone.duration = distance;
      }
    }

    this.setState({labels: labels, x_axis: makeDistanceXAxis(max), sorted_data: all_data});
  }

  setLegend(e) {
    this.setState((prevState, props) => ({show_legend: !prevState.show_legend}))
  }

  render() {
    return (
      <div className="SiteChart">
      {this.props.expand ?
        <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
        <img className="ExpandIcon" alt="expand" data-chart="distance_by_zone" src={require("../img/expand.png")} onClick={this.props.setChartType} />
      }
        <div className="SiteHeader">TOTAL DISTANCE PER ZONE
        {
          this.props.displayDownload &&
          <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="distance_by_zone" data-type="" onClick={this.props.setDownloadType}/>
        }
        </div>
        <div id="distance_by_zone" className="SiteChartContainer">
        <TimeIntervalChart labels={this.state.labels} y_axis={this.state.x_axis} data={this.state.sorted_data}/>
        </div>
      </div>
    )
  }
}

export default WmsDistanceByZone;
