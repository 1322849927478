import React from 'react';
import DatePicker from 'react-datepicker';
require("react-datepicker/dist/react-datepicker-cssmodules.css");

const selected_button = {backgroundColor: "#0068b0", color: "#ffffff", borderRadius: "3px"};
const unselected_button = {backgroundColor: "#ffffff", color: "#0068b0"};
const selected_text = {color: "#0068b0"}

const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "June", "July", "AUG", "SEP", "OCT", "NOV", "DEC"];

function makeDate(from, to) {
  let start = new Date(from)
  let end = new Date(to)
  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  let end_hour = end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
  let end_min = end.getMinutes() < 10 ? "0" +  end.getMinutes() : end.getMinutes()
  let end_date = (end.getMonth() === start.getMonth() && end.getDate() === start.getDate()) ? "" : monthNames[end.getMonth()] + " " + end.getDate() + ", "
  let str = monthNames[start.getMonth()] + " " + start.getDate() + ", " + start_hour + ":" + start_min + " - " +
            end_date + end_hour + ":" + end_min
  return (str)
}

//takes time in milliseconds and return the date at 00:00:00
function reformatTime(time) {
  let t = new Date(time);
  return (new Date(t.getFullYear(), t.getMonth(), t.getDate())).getTime();
}

class NavControl2d extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: "1",
      interval: 3600000, //1 hour is default
      dateText: makeDate(this.props.from, this.props.to),
      showCalendar: false,
      custom: { "from_date" : reformatTime(Date.now()),
                "to_date" : reformatTime(Date.now()),
                "from_time": "00:00",
                "to_time": "00:00"
      },
    };

    this.toggle_hour = this.toggle_hour.bind(this);
    this.toggle_calendar = this.toggle_calendar.bind(this);
    this.back = this.back.bind(this);
    this.forward = this.forward.bind(this);
    this.setCustomDate = this.setCustomDate.bind(this);
    this.setCustomTime = this.setCustomTime.bind(this);
    this.setOkay = this.setOkay.bind(this);
  }

  reset() {
    let current = Date.now();
    this.props.setFromTo(current - 3600000, current);

    let custom = {...this.state.custom};
    custom.from_date = reformatTime(current);
    custom.to_date = reformatTime(current);
    custom.from_time = "00:00";
    custom.to_time = "00:00";
    this.setState({hour: "1", interval: 3600000, dateText:makeDate(current - 3600000, current)});
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
      this.reset();
    }
  }

  //new Date(year, month, day, hours, minutes, seconds, milliseconds)
  //console.log(new Date(e.getFullYear(), e.getMonth(), e.getDate()).getTime());
  setCustomDate(type, e) {
    let custom = {...this.state.custom};
    if (type === 'from_date') {
      custom.from_date = (new Date(e.getFullYear(), e.getMonth(), e.getDate()).getTime());
      custom.to_date = custom.from_date;
    } else {
      custom.to_date = (new Date(e.getFullYear(), e.getMonth(), e.getDate()).getTime()); //to_date
    }

    this.setState({"custom": custom});
  }

  setCustomTime(e) {
    if (e.target.value.length === 1 && (!/[0-2]/.test(e.target.value))) {
      e.target.value = ""
      return;
    }

    if (e.target.value.length === 2) {
      if ((e.target.value.charAt(0) === '0' && !/[0-9]/.test(e.target.value.charAt(1))) ||
          (e.target.value.charAt(0) === '1' && !/[0-9]/.test(e.target.value.charAt(1))) ||
          (e.target.value.charAt(0) === '2' && !/[0-3]/.test(e.target.value.charAt(1)))
        ){
            e.target.value = e.target.value.slice(0, -1);
          } else {
            e.target.value = e.target.value + ":";
          }

      return;
    }

    if (e.target.value.length === 4 && (!/[0-5]/.test(e.target.value.charAt(3)))) {
      e.target.value = e.target.value.slice(0, -1);
      return;
    }

    if (e.target.value.length === 5 && (!/[0-9]/.test(e.target.value.charAt(4)))) {
      e.target.value = e.target.value.slice(0, -1);
      return;
    }

    if (e.target.value.length > 5) {
      e.target.value = e.target.value.slice(0, -1);
      return;
    }

    if (e.target.value.length === 5){
      let custom = {...this.state.custom}
      custom[e.target.name] = e.target.value
      this.setState({"custom": custom})
    }
  }

  toggle_calendar(e) {
    this.setState((prevState, props) =>({showCalendar: !prevState.showCalendar}))
  }

  //24 hours - 86400000, 12 hours - 13200000, 6 hours - 21600000, 10 min - 600000, 5 min - 300000, 1 min - 60000
  toggle_hour(e) {
    let to = Date.now();
    let interval = 3600000;

    switch(e.target.dataset.hour) {
      case '24':
        interval = 86400000;
      break;
      case '12':
        interval = 43200000;
      break;
      default:
        interval = 3600000;
      }

      let from = to - interval;
      this.setState({hour: e.target.dataset.hour, interval: interval, dateText: makeDate(from, to)});
      this.props.setFromTo(from, to);
  }

  setOkay(e) {
    if (this.state.custom.from_date === "" || this.state.custom.to_date === "") {
      this.setState({showCalendar: false});
      return;
    }

    let from_hour = parseInt(this.state.custom.from_time.split(":")[0], 10) * 3600000;
    let from_min = parseInt(this.state.custom.from_time.split(":")[1], 10) * 60000;

    let to_hour = parseInt(this.state.custom.to_time.split(":")[0], 10) * 3600000;
    let to_min = parseInt(this.state.custom.to_time.split(":")[1], 10) * 60000;

    let start = this.state.custom.from_date + from_hour + from_min;
    let end = this.state.custom.to_date + to_hour + to_min;
    let interval = end - start;

    if (start > end) { return }
    this.setState({hour: "0", dateText: makeDate(start, end), interval: interval, showCalendar: false});
    this.props.setFromTo(start, end);
  }

  back(e) {
    let start = this.props.from - this.state.interval;
    let end = this.props.to - this.state.interval;
    this.props.setFromTo(start, end);

    this.setState({dateText: makeDate(start, end)});
  }

  forward(e) {
    let start = this.props.from + this.state.interval;
    let end = this.props.to + this.state.interval;
    if (Date.now() < end) {return}

    this.props.setFromTo(start, end);
    this.setState({dateText: makeDate(start, end)});
  }

  render() {
    return (
      <div className="ControlContainer" style={this.props.display ? {} : {display: "none"}}>
        <div className="Button2" style={this.props.maptype === 'path' ? selected_button : unselected_button}  data-maptype="path" onClick={this.props.setMapType}>PATHS</div>
        <div className="Button2" style={this.props.maptype === 'path2' ? selected_button : unselected_button}  data-maptype="path2" onClick={this.props.setMapType}>FILTERED</div>

        <div style={this.props.maptype === 'realtime' ? {display: "none"} : {display: "inline"}}>
        <i className="fa fa-caret-left" style={{marginLeft: "200px", color: "#0068b0"}} onClick={this.back}/>
        <div className="DateText" onClick={this.toggle_calendar}>{this.state.dateText}</div>
        <i className="fa fa-caret-right" style={ Date.now() < (this.props.to + this.state.interval) ? {display: "none"} : {marginLeft: "10px", color: "#0068b0"}} onClick={this.forward}/>

        <div className="HourText" data-hour="24" style={this.state.hour === '24' ? selected_text : {}} onClick={this.toggle_hour}>24 HOURS</div>
        <div className="HourText" data-hour="12" style={this.state.hour === '12' ? selected_text : {}} onClick={this.toggle_hour}>12 HOURS</div>
        <div className="HourText" data-hour="1" style={this.state.hour === '1' ? selected_text : {}} onClick={this.toggle_hour}>1 HOUR</div>

        <div className="CalendarDropdown" style={this.state.showCalendar ? {display: "block"} : {display: "none"}}>
          <img className="xImg" alt="close" src={require("./img/x.png")} onClick={this.toggle_calendar}/>
          <div className="Custom">CUSTOM</div>
          <div className="from">FROM</div>
          <img className="calImage" alt="calendar" src={require("./img/calendar.png")}/>
          <div className="cal">
            <DatePicker
              selected={this.state.custom.from_date}
              maxDate={Date.now()}
              onChange={(e)=>this.setCustomDate('from_date', e)}
            />
          </div>
          <img className="timeImage" alt="time" src={require("./img/time.png")}/>
          <input className="time" type="text" name="from_time" placeholder="00:00" onChange={this.setCustomTime}/>
          <div className="from">TO</div>
          <img className="calImage" alt="calendar" src={require("./img/calendar.png")}/>
          <div className="cal">
            <DatePicker
              selected={this.state.custom.to_date}
              minDate={this.state.custom.to_date}
              maxDate={Date.now()}
              onChange={(e)=>this.setCustomDate('to_date', e)}
            />
          </div>
          <img className="timeImage" alt="time" src={require("./img/time.png")}/>
          <input className="time" type="text" name="to_time" placeholder="00:00" onChange={this.setCustomTime}/>
          <div className="okText" onClick={this.setOkay}>OK</div>
        </div>
        </div>
      </div>
    )
  }
}

export default NavControl2d;
