
const initialState =  null;

const EDIT = 'authToken/EDIT';

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT:
      return action.payload;
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

export function editAuthToken(token) {
  return { type: EDIT, payload: token };
}
