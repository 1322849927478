import React from 'react';
import { connect } from 'react-redux';
import { getHubsForTable } from '../../modules/hubs';
import HubsTable from './HubsTable';
import { errorConfig } from '../settings/ErrorConfig';

class HubsTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: this.props.data,
      sortedColumnIndex: 0,
    }

    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.setState({tableData: this.props.data});
    }
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  render() {
    return(
      <HubsTable
        origData={this.props.data}
        data={this.state.tableData}
        error={errorConfig[this.props.error]}
        setTableData={this.setTableData}
        sortedColumnIndex={this.state.sortedColumnIndex}
        setSortedData={this.setSortedData}
      />

    )
  }
}

export default connect(
  state => (
    {
      data: getHubsForTable(state),
      error: state.hubs.error
     }
   )
 )(HubsTableContainer);
