import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
//import NodeCreateContainer from '../settings/components/node_create_container';
//import EditNode from './EditNodeNew';
//import EditAnchor from './EditAnchor';
//import EditTracker from './EditTracker';
import Sort from '../common/Sort';
import Filter from '../common/Filter';

function editScreen(data, props) {
  if (data[props.rowIndex].node_type === 'tracker') {
    //return (<EditTracker key={data[props.rowIndex].node} node={data[props.rowIndex]} displayname={data[props.rowIndex].name}/>);
    return <Link to={`/dashboard/settings/devices/edit_tracker/${data[props.rowIndex].node}`}>{data[props.rowIndex].name}</Link>
  } else {
    // return (<EditNode key={data[props.rowIndex].node} node={data[props.rowIndex]} displayname={data[props.rowIndex].name}/>);
    return <Link to={`/dashboard/settings/devices/edit_node/${data[props.rowIndex].node}`}>{data[props.rowIndex].name}</Link>
  }
}

const NewDeviceTable = ({origData, type, data, error, setTableData, sortedColumnIndex, setSortedData}) => (
  <div className="main">
    <h3>{type.toUpperCase() + "S"}</h3>
      {
        type === 'Vision Sensor' ?
          <div className="button-container"><Link to={"/dashboard/settings/devices/edit_node/new"}>ADD NEW</Link></div>
          :
          <div className="button-container"><Link to={"/dashboard/settings/devices/edit_tracker/new"}>ADD NEW</Link></div>
      }
    <span id="error">{error}</span>
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>NAME<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {editScreen(data, props)}
          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell>HARDWARE ID<Sort thisColumnIndex={4} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].hardware_id}
          </Cell>
        )}
        width={215}
      />
      <Column
        header={<Cell>ROLE<Sort thisColumnIndex={13} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].role}
          </Cell>
        )}
        width={185}
      />
      <Column
        header={<Cell>AKA NAME<Sort thisColumnIndex={12} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].aka}
          </Cell>
        )}
        width={250}
      />
      <Column
        header={<Cell>VERSION</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].version && data[props.rowIndex].version.split('.').slice(0,4).join('.')}
          </Cell>
        )}
        width={125}
      />
      <Column
        header={<Cell>RSSI (DBM)</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].rssi} dBm
          </Cell>
        )}
        width={125}
      />
      <Column
        header={<Cell>BATTERY</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].battery} v
          </Cell>
        )}
        width={125}
      />
      <Column
        header={<Cell>STATUS<Sort thisColumnIndex={8} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props} style={{color: data[props.rowIndex].connection ? "" : "#ff0000"}}>
            {!data[props.rowIndex].connection ? `Disconnected` : `Connected`}
          </Cell>
        )}
        width={125}
      />
    </Table>
  </div>
);
export default NewDeviceTable;

//<NodeCreateContainer/>
//<div className="button-container"><Link to={"/dashboard/settings/devices/edit_tracker/new"}>ADD NEW</Link></div>

/*

  data[0].node_type === 'camera' ?
  <NodeCreateContainer/>
  :
  <div className="button-container"><Link to={"/dashboard/settings/devices/edit_tracker/new"}>ADD NEW</Link></div>
  */
