import React from 'react';
import TruckCameraEvent from '../analytics/TruckCameraEvent';
import {makeSimpleDate, formatTime12Hours} from './Utility';

function dockStatus(dockStatus, queryImgTime) {
  if (!dockStatus) { return <div></div> }
  
  if(dockStatus.update.events.dock.occupancy.state === 1) {
    return(
      <div>
       <img className="DockStatusImg" src={require("./img/occupied.png")} alt="occupied"/>
       <div className="DockDetail">DATE
        <div className="DockDetailAttr">{makeSimpleDate(queryImgTime)}</div>
       </div>
       <div className="DockDetail">TIME
        <div className="DockDetailAttr">{formatTime12Hours(queryImgTime)}</div>
       </div>
      </div>
    )
  }

  if(dockStatus.update.events.dock.occupancy.state ===0) {
    return (
      <div>
        <img className="DockStatusImg" src={require("./img/available.png")} alt="available"/>
        <div className="DockDetail">DATE
         <div className="DockDetailAttr">{makeSimpleDate(queryImgTime)}</div>
        </div>
        <div className="DockDetail">TIME
         <div className="DockDetailAttr">{formatTime12Hours(queryImgTime)}</div>
        </div>
      </div>
    )
  }
}

const DockImages = ({dock, queryTime, extCameraEvent, intCameraEvent, hideThis, extImgDockStatus, queryImgTime}) => {
  if (extCameraEvent === null || intCameraEvent === null) {
    return <div></div>
  }

  return (
      <div className="DockDetailContainer">
        <div className="DockDetailHeader">{dock.name} - {new Date(queryTime).toLocaleString()}</div>
        <img className="DockDetailClose" src={require("./img/close.png")} alt="Close" onClick={hideThis}/>
        <div>
          {extCameraEvent.history.length > 0 &&
           <div style={{display: "inline-block"}}>
              <div className="ExtCamera">
                <TruckCameraEvent key={extCameraEvent.history[0].update.events.camera.media_ready.timestamp} node={Object.assign({node: extCameraEvent.node}, {events: extCameraEvent.history[0].update.events})}/>
              </div>
              <div className="ExtName">{extCameraEvent.name}
                <div className="ExtDate">{new Date(extCameraEvent.history[0].update.events.camera.media_ready.timestamp).toLocaleString()}</div>
              </div>
            </div>
          }
          {intCameraEvent.history.length > 0 &&
            <div style={{display: "inline-block"}}>
              <div className="ExtCamera">
                <TruckCameraEvent key={intCameraEvent.history[0].update.events.camera.media_ready.timestamp} node={Object.assign({node: intCameraEvent.node}, {events: intCameraEvent.history[0].update.events})}/>
              </div>
              <div className="ExtName">{intCameraEvent.name}
                <div className="ExtDate">{new Date(intCameraEvent.history[0].update.events.camera.media_ready.timestamp).toLocaleString()}</div>
              </div>
            </div>
          }
          <div className="DetailContainer">
             {dockStatus(extImgDockStatus, queryImgTime)}
          </div>
        </div>

      </div>
  )
};

export default DockImages;
