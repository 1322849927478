import React from 'react';
import { reformatNodeHistory } from '../../modules/websockets';
import { fetchObjectHistory } from '../../modules/api';
//import CameraEvent from './CameraEvent';
import PastCameraEvent from './PastCameraEvent';
import CameraEventModal from './CameraEventModal';

class PastEventsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nodeEvents: [],
      limit: 100,
      start_key: "",
      error: false,
      hasMore: true,
      isLoading: true,
      nodeForModal: null,
      start_keys: {},
    }

    this.loadNodeEvents = this.loadNodeEvents.bind(this);
    this.receiveHistory = this.receiveHistory.bind(this);
    this.receiveHistories = this.receiveHistories.bind(this);
    this.handlePastEventClick = this.handlePastEventClick.bind(this);

    // Binds our scroll event handler
    window.onscroll = () => {
        if (this.state.isLoading || this.props.from !== undefined || this.props.to !== undefined) {return};
        console.log("scroll event detected");
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight,  html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
          this.loadNodeEvents();
        }
    };
  }

  componentDidMount() {
    this.loadNodeEvents();
  }

  componentDidUpdate(prevProps) {
    if (this.props.constellation !== prevProps.constellation || (this.props.node && (this.props.node.node !== prevProps.node.node))) {
      console.log("Changed constellation or node");
      this.setState({nodeEvents: [], start_key: "", isLoading: true}, ()=> {this.loadNodeEvents()});
    }
  }

  loadNodeEvents() {
    if (this.props.node) {
      let fetchHistory = (this.state.start_key === "") ?
        fetchObjectHistory(this.props.node.node, this.props.authToken, this.props.siteId, {event: "camera:media_ready", limit: this.state.limit})
        :
        fetchObjectHistory(this.props.node.node, this.props.authToken, this.props.siteId, {event: "camera:media_ready", limit: this.state.limit, start_key: this.state.start_key});

      if (this.props.from !== undefined && this.props.to !== undefined) {
        console.log("from " + this.props.from)
        fetchHistory = fetchObjectHistory(this.props.node.node, this.props.authToken, this.props.siteId, {event: "camera:media_ready", from: this.props.from, to: this.props.to})
      }

      fetchHistory
        .then(json => this.receiveHistory(json))
        .then(()=> this.setState({isLoading: false}))
        .catch(err => { console.log(err)})
    }

    if (this.props.constellation && this.props.nodes) {
      let childNodes = this.props.nodes;

      if (Object.entries(this.state.start_keys).length === 0) {
        Promise.all (
          childNodes.map(n =>
            fetchObjectHistory(n.node, this.props.authToken, this.props.siteId, {event: "camera:media_ready", limit: this.state.limit})
            .catch(err => console.log(err)))
        )
        .then(jsons => this.receiveHistories(jsons))
        .then(()=> this.setState({isLoading: false}))
      } else {
        Promise.all(
          childNodes.map(n =>
            fetchObjectHistory(n.node, this.props.authToken, this.props.siteId, {event: "camera:media_ready", limit: this.state.limit, start_key: this.state.start_keys[n.node]})
              .catch(err => console.log(err)))
        )
        .then(jsons => this.receiveHistories(jsons))
        .then(()=> this.setState({isLoaing: false}))
      }
    }
  }

  receiveHistory(json) {
    // let nodeEvents = json.history;
    // let reformattedNodes = nodeEvents.map(aNodeEvent => reformatNodeEvent(aNodeEvent));
    this.setState({start_key: json.last_key});
    let reformattedNodes = reformatNodeHistory(json);

    reformattedNodes.sort((a, b) => b.update.events.camera.media_ready.timestamp - a.update.events.camera.media_ready.timestamp);
    //this.setState({nodeEvents: [...this.state.nodeEvents, ...reformattedNodes]})
    this.setState({nodeEvents: this.state.nodeEvents.concat(reformattedNodes)});
    //this.setState({ nodeEvents: reformattedNodes })
  }

  receiveHistories(jsons) {
    let start_keys = {...this.state.start_keys}
    jsons.forEach(n => start_keys[n.id] = n.last_key)
    this.setState({start_keys: start_keys});

    let reformattedNodes = [];
    jsons.forEach(json => reformattedNodes = reformattedNodes.concat(reformatNodeHistory(json)));
    reformattedNodes.sort((a,b) => b.update.events.camera.media_ready.timestamp - a.update.events.camera.media_ready.timestamp);
    this.setState({nodeEvents: this.state.nodeEvents.concat(reformattedNodes)});
  }

  //Open Modal
  handlePastEventClick(node) { this.setState({nodeForModal: node}) }

  render () {
    return (
      <div>
        <CameraEventModal node={this.state.nodeForModal} allEvents={this.state.nodeEvents} nodeName={this.props.node ? this.props.node.name : ""}/>
        {this.state.nodeEvents.map((n => <PastCameraEvent node={n} key={n.update.events.camera.media_ready.key} onEventClick={this.handlePastEventClick} />))}
        {this.state.isLoading && <div>Loading...</div>}
      </div>
    );
  }
}

export default PastEventsList;
