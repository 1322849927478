import React from 'react';
import { Table, Column, Cell} from 'fixed-data-table-2';

const SELECT = {backgroundColor: "#CBF6FF"};
const LapisScTable = ({rows, selected, type, onSelectTracker}) => (
  type === 'trips' ?
    <div>
      <Table className="lapis-table"
        rowHeight={38}
        headerHeight={38}
        rowsCount={rows.length}
        width={1090}
        height={270}
      >
        <Column columnKey='truck'
                header={<Cell className="lapis-tableheader">{type === 'trips' ? "Truck" : "Tracker"}</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].truck === selected && <div className="fa fa-search lapis-search"/>}
                      {type === 'trips' ? rows[props.rowIndex].truck : rows[props.rowIndex].name}
                  </Cell>
                )}
                width={125}
        />
        <Column columnKey='driver'
                header={<Cell className="lapis-tableheader">Driver</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].driver}
                  </Cell>
                )}
                width={125}
        />
        <Column columnKey='customer'
                header={<Cell className="lapis-tableheader">Customer</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].customer}
                  </Cell>
                )}
                width={205}
        />
        <Column columnKey='time'
                header={<Cell className="lapis-tableheader">Time</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].time}
                  </Cell>
                )}
                width={205}
        />
        <Column columnKey='lat'
                header={<Cell className="lapis-tableheader">Latitude</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].lat}
                  </Cell>
                )}
                width={205}
        />
        <Column columnKey='long'
                header={<Cell className="lapis-tableheader">Longitude</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].long}
                  </Cell>
                )}
                width={205}
        />
      </Table>
    </div>
    :
    <div>
      <Table className="lapis-table"
        rowHeight={38}
        headerHeight={38}
        rowsCount={rows.length}
        width={1090}
        height={270}
      >
        <Column columnKey='truck'
                header={<Cell className="lapis-tableheader">Tracker</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].truck === selected && <div className="fa fa-search lapis-search"/>}
                      {rows[props.rowIndex].name}
                  </Cell>
                )}
                width={350}
        />
        <Column columnKey='lat'
                header={<Cell className="lapis-tableheader">Latitude</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].lat}
                  </Cell>
                )}
                width={370}
        />
        <Column columnKey='long'
                header={<Cell className="lapis-tableheader">Longitude</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].truck === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectTracker(rows[props.rowIndex].truck)}
                  >
                      {rows[props.rowIndex].long}
                  </Cell>
                )}
                width={370}
        />
      </Table>
    </div>
  )

export default LapisScTable;
