import React from 'react';
import ConstellationStateButton from './ConstellationStateButton';
import ConstellationButtons from './ConstellationButtons';
import { Link } from 'react-router-dom';

const ConstellationHeading = ({ constellation }) => (
  <div className="constellation-heading">
    <Link to={`/dashboard/constellations/${constellation.constellation}`}>
      {constellation.name}
    </Link>
    <ConstellationStateButton state={constellation.state} id={constellation.constellation} />
    <ConstellationButtons id={constellation.constellation} />
  </div>
)

export default ConstellationHeading;
