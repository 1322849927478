import React from 'react';
import Select from 'react-select';

const style = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: base => ({
    ...base,
    maxHeight: "38px",
  }),
  valueContainer: base =>({
    ...base,
    maxHeight: "38px",
  }),
  indicatorsContainer: base => ({
    ...base,
    maxHeight: "38px",
  }),
  singleValue: base => ({
    ...base,
  })
};

//options, value, field, updateField
const SelectOptions = (props) => (
     < Select
        options={props.options}
        styles={style}
        value={props.value}
        placeholder=""
        isClearable={true}
        onChange={e => props.updateField(props.field , e, 'select')}
      />
)

export default SelectOptions;

/*
 <Select options={camera_options} styles={customStyles} value={int_value} placeholder="" isClearable={true}  onChange={e => updateField("int" , e)}/>
 const mount_options = [
  { value: 'front', label: 'Front' },
  { value: 'overhead', label: 'Overhead' },
  { value: 'top_middle', label: 'Top-Middle' },
  { value: 'top_right', label: 'Top-Right' },
  { value: 'top_left', label: 'Top-Left' }
];
*/
