import React from 'react';
import { connect } from 'react-redux';
//import DevicesTable from './DevicesTable';
import NewDeviceTable from './NewDeviceTable';
import AnchorTable from './AnchorTable';
import { getNodesForTable } from '../../modules/nodes';
import { errorConfig } from '../settings/ErrorConfig';

class DevicesTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      origData: [],
      tableData: [],
      sortedColumnIndex: 0,
    };

    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
  }

  componentDidMount() {
    let type = this.props.path.split("/").pop();
    let array = this.props.data.filter(n => n.node_type === type).sort((a,b) => a.name.localeCompare(b.name));
    if (type === 'camera') { type = "Vision Sensor" }
    this.setState({type: type, tableData: array, origData: array});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path !== this.props.path || prevProps.data.length !== this.props.data.length) {
      let type = this.props.path.split("/").pop();
      let array = this.props.data.filter(n => n.node_type === type).sort((a,b) => a.name.localeCompare(b.name));
      if (type === 'camera') { type = "Vision Sensor" }
      this.setState({type: type, tableData: array, origData: array});
    }
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  render() {
    return(
      <div>
      {
        this.props.path.endsWith('anchor') ?
          <AnchorTable
            origData={this.state.origData}
            data={this.state.tableData}
            error={errorConfig[this.props.error]}
            type={this.state.type}
            setTableData={this.setTableData}
            sortedColumnIndex={this.state.sortedColumnIndex}
            setSortedData={this.setSortedData}
          />
          :
          <NewDeviceTable
            origData={this.state.origData}
            data={this.state.tableData}
            error={errorConfig[this.props.error]}
            type={this.state.type}
            setTableData={this.setTableData}
            sortedColumnIndex={this.state.sortedColumnIndex}
            setSortedData={this.setSortedData}
          />
      }
     </div>
    )
  }
}

export default connect((state) => ({
  data: getNodesForTable(state),
  status: state.nodes.status,
  error: state.nodes.error,
  path: state.routing.location.pathname,
}))(DevicesTableContainer);
