import React from 'react';
import { Layer, Rect, Text } from 'react-konva';


class ToolTip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: [],
      show: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.tooltip_props) !== JSON.stringify(this.props.tooltip_props) ||
        prevProps.tooltip_show !== this.props.tooltip_show) {
      this.calTooltip();
    }
  }

  calTooltip() {
    let yOffset = 0;
    let props_length = 0;
    let width = this.props.tooltip_props.id.length * 9;

    if (this.props.tooltip_props.hasOwnProperty('props') && this.props.tooltip_props.props !== null) {
      yOffset = Object.keys(this.props.tooltip_props.props).length * 20;
      props_length = Object.keys(this.props.tooltip_props.props).length;

      Object.keys(this.props.tooltip_props.props).forEach(p => {
        let value = this.props.tooltip_props.props[p];
        let st = p + ":" + value;
        if (st.length * 8 > width) {
            width = st.length * 8;
        }
      })
    }

    let newX = this.props.tooltip_props.x > 925 ? this.props.tooltip_props.x - 100 : this.props.tooltip_props.x - 30;
    let newY = this.props.tooltip_props.y > 50 ? this.props.tooltip_props.y - yOffset - 40 : this.props.tooltip_props.y;
    let height = props_length * 20 + 30;
    let body = [];
    body.push(<Rect key={"tooltip"} x={newX} y={newY} width={width + 20} height={height} fill={'black'} opacity={.7} cornerRadius={10}/>)
    body.push(<Text key={this.props.tooltip_props.id} x={newX + 10} y={newY + 10} text={this.props.tooltip_props.id} fontStyle={'bold'} fontFamiy={'Lato'} fill={'white'} fontSize={14}/>)

    if (this.props.tooltip_props.props !== null) {
      Object.keys(this.props.tooltip_props.props).forEach((p, index) => {
        let value = this.props.tooltip_props.props[p];
        let newerY = newY + 10 + ((index + 1) * 20);
        body.push(<Text key={p} x={newX + 10} y={newerY} text={p + ": " + value} fontFamiy={'Lato'} fill={'white'} fontSize={12}/>)
      })
    }

    this.setState({text: body, show: this.props.tooltip_show});
  }

  render() {
    return (
      <Layer>
        {this.state.show ? this.state.text : [] }
      </Layer>
    )

  }
}

export default ToolTip;

/*
<Text x={310} y={110} text={'barbara'} fontFamiy={'Lato'} fill={'white'} fontSize={14}/>
<Text x={310} y={130} text={'barbara: 10'} fontFamiy={'Lato'} fill={'white'} fontSize={14}/>
*/
