import React from 'react';
import DatePicker from 'react-datepicker';

import './Common.css';
import "react-datepicker/dist/react-datepicker.css";
require("react-datepicker/dist/react-datepicker-cssmodules.css");
//require("react-datepicker/dist/react-datepicker-cssmodules.min.css");

function reformatTime(time) {
  let t = new Date(time);
  return (new Date(t.getFullYear(), t.getMonth(), t.getDate())).getTime();
}

function makeTime(time) {
  let start = new Date(time);
  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  return start_hour + ":" + start_min;
}

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from_date: reformatTime(this.props.from),
      to_date: reformatTime(this.props.to),
      from_hour: makeTime(this.props.from).split(":")[0],
      from_min: makeTime(this.props.from).split(":")[1],
      to_hour: makeTime(this.props.to).split(":")[0],
      to_min: makeTime(this.props.to).split(":")[1],
      error: "",
    };

    this.setCustomDate = this.setCustomDate.bind(this);
    this.setCustomTime = this.setCustomTime.bind(this);
    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
    this.setOkay = this.setOkay.bind(this);
    this.enterPressed=this.enterPressed.bind(this);
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
      //console.log("update calendar time");
      this.reset();
    }
  }

  reset() {
    this.setState({
      from_date: reformatTime(this.props.from),
      to_date: reformatTime(this.props.to),
      from_hour: makeTime(this.props.from).split(":")[0],
      from_min: makeTime(this.props.from).split(":")[1],
      to_hour: makeTime(this.props.to).split(":")[0],
      to_min: makeTime(this.props.to).split(":")[1],
      error: "",
    });
  }

  //from_date or to_date
  setCustomDate(type, e) {
    let date = (new Date(e.getFullYear(), e.getMonth(), e.getDate()).getTime());
    if (type === 'from_date') {
      this.setState({from_date: date});
    } else {
      this.setState({to_date: date});
    }
  }

  setCustomTime(e) {
    e.target.value = e.target.value.replace(/[^0-9]+/, '');

    if ((e.target.name === 'from_hour' || e.target.name === 'to_hour') && e.target.value > 23) {
        e.target.value = 23;
    }

    if ((e.target.name === 'from_min' || e.target.name === 'to_min') && e.target.value > 59) {
        e.target.value = 59;
    }

    switch (e.target.name) {
      case 'from_hour':
        this.setState({from_hour: e.target.value})
        break;
      case 'from_min':
        this.setState({from_min: e.target.value})
        break;
      case 'to_hour':
        this.setState({to_hour: e.target.value})
        break;
      case 'to_min':
        this.setState({to_min: e.target.value})
        break;
      default:
        break;
    }
  }

  increase(e) {
    let time = null;
    switch(e.target.dataset.name) {
      case 'from_hour':
        time = parseInt(this.state.from_hour,10);
        if (time >= 23) { return }
        this.setState({from_hour: time + 1})
        break;
      case 'from_min':
        time = parseInt(this.state.from_min, 10);
        if (time >= 59) { return }
        this.setState({from_min: time + 1})
        break;
      case 'to_hour':
        time = parseInt(this.state.to_hour, 10);
        if (time >= 23) { return }
        this.setState({to_hour: time + 1})
        break;
      case 'to_min':
        time = parseInt(this.state.to_min, 10)
        if (time >= 59) { return }
        this.setState({to_min: time + 1})
        break;
      default:
        break;
    }
  }

  decrease(e){
    let time = null;
    switch(e.target.dataset.name) {
      case 'from_hour':
        time = parseInt(this.state.from_hour,10);
        if (time === 0) { return }
        this.setState({from_hour: time - 1})
        break;
      case 'from_min':
        time = parseInt(this.state.from_min, 10);
        if (time ===0 ) { return }
        this.setState({from_min: time - 1 })
        break;
      case 'to_hour':
        time = parseInt(this.state.to_hour, 10);
        if (time === 0 ) { return }
        this.setState({to_hour: time - 1})
        break;
      case 'to_min':
        time = parseInt(this.state.to_min, 10)
        if (time === 0) { return }
        this.setState({to_min: time - 1})
        break;
      default:
        break;
    }
  }

  setOkay(e) {
    this.setState({error: ""});
    let from_hour = this.state.from_hour === '' ? 0 : parseInt(this.state.from_hour, 10) * 3600000;
    let from_min = this.state.from_min === '' ? 0 : parseInt(this.state.from_min, 10) * 60000;

    let to_hour = this.state.to_hour === '' ? 0 : parseInt(this.state.to_hour, 10) * 3600000;
    let to_min = this.state.to_min === '' ? 0 : parseInt(this.state.to_min, 10) * 60000;

    let start = this.state.from_date + from_hour + from_min;
    let end = this.state.to_date + to_hour + to_min;

    if (start > end) {
      this.setState({error: "Invalid time range"});
      return;
    }

    this.props.onSetCalendarDate(start, end);
  }

  enterPressed(e) {
    var code = e.keyCode || e.which;
    if(code === 13) {
      //console.log("key code - " + code);
      this.setOkay();
    }
  }

  render() {
    return (
        <div className="CalendarDropdownCommon" style={this.props.show ? {display: "block"} : {display: "none"}}>
          <img className="xImg" alt="close" src={require("./img/x.png")} onClick={this.props.setShow}/>
          <div className="Custom">CUSTOM</div>
          <div className="from">FROM</div>
          <img className="calImage" alt="calendar" src={require("./img/calendar.png")}/>
          <div className="cal">
            <DatePicker
              id="from-date"
              selected={this.state.from_date}
              maxDate={Date.now()}
              onChange={(e)=>this.setCustomDate('from_date', e)}
            />
          </div>
          <img className="timeImage" alt="time" src={require("./img/time.png")}/>
          <input id="from-hour" className="timeHour" type="text" name="from_hour" value={this.state.from_hour} onChange={this.setCustomTime} onKeyPress={this.enterPressed}/>
          <div className="upArrow"><img alt="up" data-name="from_hour" src={require("./img/up.png")} onClick={this.increase}/></div>
          <div className="downArrow"><img alt="down" data-name="from_hour" src={require("./img/down.png")} onClick={this.decrease}/></div>

          <div className="colon">:</div>

          <input id="from-min" className="timeMin" type="text" name="from_min" value={this.state.from_min} onChange={this.setCustomTime} onKeyPress={this.enterPressed}/>
          <div className="upArrow"><img alt="up" data-name="from_min" src={require("./img/up.png")} onClick={this.increase}/></div>
          <div className="downArrow"><img alt="down" data-name="from_min" src={require("./img/down.png")} onClick={this.decrease}/></div>


          <div className="from">TO</div>
          <img className="calImage" alt="calendar" src={require("./img/calendar.png")}/>
          <div className="cal">
            <DatePicker
              id="to-date"
              selected={this.state.to_date}
              minDate={this.state.from_date}
              maxDate={Date.now()}
              onChange={(e)=>this.setCustomDate('to_date', e)}
            />
          </div>
          <img className="timeImage" alt="time" src={require("./img/time.png")}/>
          <input id="to-hour" className="timeHour" type="text" name="to_hour" value={this.state.to_hour} onChange={this.setCustomTime} onKeyPress={this.enterPressed}/>
          <div className="upArrow"><img alt="up" data-name="to_hour" src={require("./img/up.png")} onClick={this.increase}/></div>
          <div className="downArrow"><img alt="down" data-name="to_hour" src={require("./img/down.png")} onClick={this.decrease}/></div>

          <div className="colon">:</div>

          <input id="to-min" className="timeMin" type="text" name="to_min" value={this.state.to_min} onChange={this.setCustomTime} onKeyPress={this.enterPressed}/>
          <div className="upArrow"><img alt="up" data-name="to_min" src={require("./img/up.png")} onClick={this.increase}/></div>
          <div className="downArrow"><img alt="down" data-name="to_min" src={require("./img/down.png")} onClick={this.decrease}/></div>
          <div>
            <div className="errorText">{this.state.error}</div>
            <div className="applyText" onClick={this.setOkay}>OK</div>
          </div>
        </div>
    )
  }
}

export default Calendar;
