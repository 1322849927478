import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
// import currentSiteReducer from '../components/site_settings/reducer';
import authReducer from './authReducer';
import sitesReducer from './sites';
import nodesReducer from './nodes';
import pendingNodesReducer from './pendingNodes';
import hubsReducer from './hubs';
import constellationsReducer from './constellations';
import user from './users';
import eventManager from './websockets';
import { managerHistory } from './websockets';
import docksReducer from './docks';

export default combineReducers({
  routing: routerReducer,
  // currentSite: currentSiteReducer,
  sites: sitesReducer,
  authToken: authReducer,
  nodes: nodesReducer,
  pendingNodes: pendingNodesReducer,
  hubs: hubsReducer,
  constellations: constellationsReducer,
  docks: docksReducer,
  user,
  eventManager,
  managerHistory,
});


// // EXAMPLE STATE:
// const state = {
//   authToken: '<authToken string>',
//   currentSiteId: '7d876a39a42c410286f4b5482e351e22',
//   sites: {
//     '4c598516787842d1a88762e557d83ac9': {
//       create_timestamp: 1505933939.665,
//       customer_id: 'af1151502825463598f6882fb367fadd',
//       last_update_timestamp: 1505933939.665,
//       name: 'Jeff Temp Site',
//       site_id: '4c598516787842d1a88762e557d83ac9',
//     },
//   },
// };
