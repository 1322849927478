import React from 'react';

const DockDetailSnapshot = ({trucks, delays, average, utilization, gaps}) => (
    <div className="SnapShotContainer">
      <div className="DockSnapShot">
        <img className="DockIcon TruckIcon" src={require("./img/truck.png")} alt="Trucks"/>
        <div className="DockText">{trucks}</div>
        <div className="DockStatus">TRUCKS RECEIVED</div>
      </div>
      <div className="DockSnapShot DockDelay">
        <img className="DockIcon" src={require("./img/delay-alert.png")} alt="DelayAlert"/>
        <div className="DockText">{delays}</div>
        <div className="DockStatus">EXTENDED DETENTION</div>
      </div>
      <div className="DockSnapShot">
        <img className="DockIcon ClockIcon" src={require("./img/clock.png")} alt="Dwell Time"/>
        <div className="DockText">{Math.round(average/60000)} MINS</div>
        <div className="DockStatus">AVG DWELL TIME</div>
      </div>
      <div className="DockSnapShot">
        <img className="DockIcon ClockIcon" src={require("./img/occupied-dock.png")} alt="AVG Dwell Time"/>
        <div className="DockText">{utilization}%</div>
        <div className="DockStatus">AVG TIME OCCUPIED</div>
      </div>
      <div className="DockSnapShot">
        <img className="DockIcon ClockIcon" src={require("./img/gap.png")} alt="GAP DELAY"/>
        <div className="DockText">{gaps}</div>
        <div className="DockStatus">ARRIVAL/DEPARTURE GAPs</div>
      </div>
    </div>
)

export default DockDetailSnapshot;
