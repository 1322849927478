import React, {useState} from 'react';
import Modal from 'react-modal';

const HelpModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const setShow = (e) => {
    e.stopPropagation();
    let show = !showModal;
    setShowModal(show);
  }

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
  }

  return(
    <div className={props.button}>
      {props.button && <button onClick={setShow}/>}
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        contentLabel="Help"
        className="Help"
      >
        <div className="HelpContainer" onClick={closeModal}>
          <div className="HelpContent">{props.help_content}</div>
        </div>
      </Modal>
    </div>
  )
}

export default HelpModal;
