import React, { useState } from 'react';
import LpsFloor from './LpsFloor';
import LpsMenuAnchor from './LpsMenuAnchor';
import LpsMenuZone from './LpsMenuZone';

const LpsMenu = ({setting, setSetting, zones, anchors, selected_anchor, selectAnchor, saveAnchor, deleteAnchor, selectZone, selected_zone, saveZone, deleteZone, addNewZone, floor_order, floor, selectFloor}) => {
  const [showZones, setShowZones] = useState(setting === 'zones');
  const [showAnchors, setShowAnchors] = useState(setting === 'anchors');

  const toggleShow = (e) => {
    if (e.target.dataset.setting === 'zones') {
      let temp = !showZones;
      setShowZones(temp);
      setShowAnchors(false);
      return;
    }

    if (e.target.dataset.setting === 'anchors') {
      let temp = !showAnchors;
      setShowZones(false);
      setShowAnchors(temp);
      return;
    }

    setShowZones(false);
    setShowAnchors(false);
  }

  const setSettings = (e) => {
    toggleShow(e);
    setSetting(e);
  }

  return(
    <div className="lps_menu">
      <div className="lps_header">MAP SETTINGS</div>
      <div className="lps_line"/>
      <LpsFloor floor_order={floor_order} floor={floor} selectFloor={selectFloor} disable={selected_zone || selected_anchor}/>
      <div className="lps_line"/>
      <div className={setting==='zones'  ?  "lps_menu_item_selected" : "lps_menu_item"} data-setting="zones" onClick={(e)=>setSettings(e)}>ZONES
        <div className={showZones ? "fa fa-chevron-down chevron" : "fa fa-chevron-up chevron"} data-setting="zones" onClick={(e)=>setSettings(e)}/>
      </div>
      <div className="lps_line"/>
      <div style={{display: setting === 'zones' && showZones ? "block" : "none"}}>
        <div className="anchor_param add_text" onClick={addNewZone}>+ ADD ZONE</div>
        {Object.values(zones).sort((a,b) => a.name.localeCompare(b.name)).filter(a => a.floor === floor).map(a => <LpsMenuZone key={a.id} zone={a} selectZone={selectZone} selected_zone={selected_zone} saveZone={saveZone} deleteZone={deleteZone}/>)}
        <div className="lps_line"/>
      </div>
      <div className={setting==='anchors' ?  "lps_menu_item_selected" : "lps_menu_item"} data-setting="anchors" onClick={(e)=>setSettings(e)}>ANCHORS
        <div className={showAnchors ? "fa fa-chevron-down chevron" : "fa fa-chevron-up chevron"}  data-setting="anchors" onClick={(e)=>setSettings(e)}/>
      </div>
      <div className="lps_line"/>
      <div style={{display: setting==='anchors' && showAnchors ? "block" : "none"}}>
        {Object.values(anchors).sort((a,b) => a.name.localeCompare(b.name)).filter(a => a.floor === floor).map(a => <LpsMenuAnchor key={a.node} anchor={a} selected_anchor={selected_anchor} selectAnchor={selectAnchor} saveAnchor={saveAnchor} deleteAnchor={deleteAnchor}/>)}
      </div>
    </div>
  )
}

export default LpsMenu;

/*
<div className="lps_line"/>
<div className={setting==='origin' ? "lps_menu_item_selected" : "lps_menu_item"} data-setting="origin" onClick={(e)=>setSettings(e)}>ORIGIN</div>
<div className="lps_line"/>
<div className={setting==='scale' ?  "lps_menu_item_selected" : "lps_menu_item"} data-setting="scale" onClick={(e)=>setSettings(e)}>SCALE</div>
<div className="lps_line"/>
<div className={setting==='lps_area' ?  "lps_menu_item_selected" : "lps_menu_item"} data-setting="lps_area" onClick={(e)=>setSettings(e)}>LPS AREA</div>
<div className="lps_line"/>
*/
