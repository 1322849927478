import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Column, Cell} from 'fixed-data-table-2';

const delay = {color: "#c85000", fontWeight: "bold", display: "inline-block"};
const DockDetailTable = ({dock, ext_camera, rows}) => (
  <div>
    <Table className="StatusTable"
      rowHeight={40}
      headerHeight={40}
      rowsCount={rows.length}
      width={1330}
      height={700}
    >
      <Column columnKey='date'
              header={<Cell className="StatusTableHeader">Date</Cell>}
              cell={ props => (
                <Cell className="StatusTableCell" {...props}>
                  {rows[props.rowIndex].date}
                </Cell>
              )}
              width={164}
      />
      <Column columnKey='arrival_time'
              header={<Cell className="StatusTableHeader">Arrival Time</Cell>}
              cell={ props => (
                <Cell className="StatusTableCell" {...props}>{rows[props.rowIndex].arrival_time}</Cell>
              )}
              width={164}
      />
      <Column columnKey='departure_time'
              header={<Cell className="StatusTableHeader">Departure Time</Cell>}
              cell={ props => (
                <Cell className="StatusTableCell" {...props}>
                {rows[props.rowIndex].departure_time}
                </Cell>
              )}
              width={164}
      />
      <Column columnKey='dwell_time'
              header={<Cell className="StatusTableHeader">Dwell Time</Cell>}
              cell={ props => (
                <Cell className="StatusTableCell" {...props}>{rows[props.rowIndex].dwell_time}</Cell>
              )}
              width={164}
      />
      <Column columnKey='int_activity'
              header={<Cell className="StatusTableHeader">Interior Activity</Cell>}
              cell={ props => (
                <Cell className="StatusTableCell" {...props}>
                  {rows[props.rowIndex].int_activity_warn ? <span style={delay}>{rows[props.rowIndex].int_activity}%</span> : <div>{rows[props.rowIndex].int_activity}%</div>}
                  {rows[props.rowIndex].int_activity_warn ? <div className="fa fa-warning AlarmIcon"/> : ""}
                </Cell>
              )}
              width={164}
      />
      <Column columnKey='arrival_delay'
              header={<Cell className="StatusTableHeader">Arrival Gap</Cell>}
              cell={ props => (
                <Cell className="StatusTableCell" {...props}>
                  {rows[props.rowIndex].arrival_delay}
                  {rows[props.rowIndex].arrival_delay_warn ? <img className="AlarmIcon" src={require("./img/gap.png")} alt="ARRIVAL GAP DELAY"/> : ""}
                </Cell>
              )}
              width={164}
      />
      <Column columnKey='departure_delay'
              header={<Cell className="StatusTableHeader">Departure Gap</Cell>}
              cell={ props => (
                <Cell className="StatusTableCell" {...props}>
                  {rows[props.rowIndex].departure_delay}
                  {rows[props.rowIndex].departure_delay_warn ? <img className="AlarmIcon" src={require("./img/gap.png")} alt="DEPARTURE GAP DELAY"/> : ""}
                </Cell>
              )}
              width={164}
      />
      <Column columnKey='Actions'
              header={<Cell className="StatusTableHeader">Actions</Cell>}
              cell={ props => (
                    <Cell className="StatusTableCell" {...props}>
                    <Link to={`/dashboard/summary_detail/${props.rowIndex + 1}/${dock.node}/${rows[props.rowIndex].arrival_time_raw}/${rows[props.rowIndex].departure_time_raw}`} style={{textDecoration: "none"}}><div className="Summary">SUMMARY</div></Link>
                    </Cell>
                  )}
              width={164}
      />
      </Table>
    </div>
  )

export default DockDetailTable;

//<Link to={`/dashboard/cameras/${ext_camera}/${rows[props.rowIndex].arrival_time_raw}/${rows[props.rowIndex].departure_time_raw}`}
//<div className="Summary SummaryDeactivated">SUMMARY</div>
/*
{props.rowIndex === 0 && rows[props.rowIndex].departure_time === "-" ?
  <Link to={`/dashboard/cameras/${ext_camera}/${rows[props.rowIndex].arrival_time_raw - 300000}/${Date.now()}`} style={{textDecoration: "none"}}><div className="Summary">SUMMARY</div></Link>
  :
  <Link to={`/dashboard/cameras/${ext_camera}/${rows[props.rowIndex].arrival_time_raw - 300000}/${rows[props.rowIndex].departure_time_raw + 300000}`} style={{textDecoration: "none"}}><div className="Summary">SUMMARY</div></Link>
}
*/
