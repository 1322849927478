import React from 'react';
import { connect } from 'react-redux';
import WorkersTable from './WorkersTable';

import { formatTime } from '../2dReact/charts/ChartUtil';

import { fetchObjectHistory } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import { getTrackersArray } from '../../modules/nodes';

class WorkersTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nodes: {},
      from: new Date(new Date().getTime() - 518400000).setHours(0,0,0,0), //7 days ago
      to: new Date().getTime(),
      origData: [],
      tableData: [],
      sortedColumnIndex: 0,
      fileContent: "",
      loading: false,
    };

    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
    this.setFromTo = this.setFromTo.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true}, ()=>this.fetchAttachmentHistory());
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteId !== prevProps.siteId || this.props.nodes.length !== prevProps.nodes.length) {
      this.setState({loading: true}, ()=>this.fetchAttachmentHistory());
    }
  }

  fetchAttachmentHistory() {
    console.log("querying " + this.state.from + " " + this.state.to)
    let data = [];
    let fileContent = "Date,Time,Tracker,GID,Check In/Out\n";
    Promise.all(
      this.props.nodes.map(n =>
        fetchObjectHistory(n.node, this.props.authToken, this.props.siteId, {event: "tracker:attachment", to: this.state.to, from: this.state.from})
      )
    ).then(json => json.forEach(attachment => {
      if (attachment.history.length === 0 ) { return }

      let name = this.props.nodes.filter(n => n.node === attachment.id)[0].name;
      attachment.history.forEach(h => {
        let row = {date: "", time: "", node: attachment.id, tracker: name, gid: "", attachment: "", timestamp: null}
        let date = new Date(h.timestamp);
        row.date = (date.getMonth() + 1) + "/" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "/" + date.getFullYear()
        row.time = formatTime(h.timestamp);
        row.gid = h.update.events.tracker.attachment.asset;
        row.attachment = h.update.events.tracker.attachment.attached ? "Checked Out" : "Checked In";
        row.timestamp = h.timestamp;
        data.push(row);
      })
      }
    )
  ).then(()=> { data.sort((a,b) => a.timestamp -  b.timestamp)})
   .then(()=> { fileContent += data.map(row =>  [row.date, row.time, row.tracker, row.gid, row.attachment].join(",")).join("\n")})
   .then(() => this.setState({loading: false, origData: data, tableData: data, fileContent: fileContent}))
 } //end method

  setFromTo(from, to) {
    console.log("setFromTo " + from + " " + to);
    this.setState({origData: [], tableData: [], from: from, to: to}, ()=>this.fetchAttachmentHistory());
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  render() {
    return (
      <WorkersTable
        from={this.state.from}
        to={this.state.to}
        loading={this.state.loading}
        origData={this.state.origData}
        data={this.state.tableData}
        fileContent={this.state.fileContent}
        error={this.props.error}
        setTableData={this.setTableData}
        sortedColumnIndex={this.state.sortedColumnIndex}
        setSortedData={this.setSortedData}
        setFromTo={this.setFromTo}
      />
    )
  }
}

export default connect(
  state => ({
    nodes: getTrackersArray(state),
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  })
)(WorkersTableContainer);
