import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNodesHash, updateNode, fetchNode, fetchNodes } from '../../modules/nodes';
import { checkACL } from '../acl/Config';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';
import {isEmpty, isNumber } from '../settings/form/ErrorChecking';

const URL = "/dashboard/settings/devices/tracker_config";
const TRACKER_PROPS = {
  tracker: "", hardware_id: "", nid: "", channel: "", mcs: "", nss: "", obw: "", payload_length: "", tx_power: "", mode: "", npkt: "",
  inert_data: "",  loc_min_anch: "", loc_max_anch: "", addr: "", name: "", validate: true
};

const EditTrackerConfig = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(true);
  const [tracker, setTracker] = useState(null);
  const [config, setConfig] = useState(TRACKER_PROPS);
  const [error, setError] = useState(TRACKER_PROPS);

  useEffect(()=> {
    if (props.match.params.id === "undefined" ) { return }
    if (props.match.params.id === id) { return }

    console.log("useEffect");
    resetForm(props.nodesHash[props.match.params.id])
  });

  const resetForm = (tracker) => {
    if (tracker) {
      setId(tracker.node)
      setEditForm(true);
      setTracker(tracker);
      setConfig({
        nid: tracker.configs.tracker.nid,
        channel: tracker.configs.tracker.channel,
        mcs: tracker.configs.tracker.mcs,
        nss: tracker.configs.tracker.nss,
        obw: tracker.configs.tracker.obw,
        payload_length: tracker.configs.tracker.payload_length,
        tx_power: tracker.configs.tracker.tx_power,
        mode: tracker.configs.tracker.mode,
        npkt: tracker.configs.tracker.npkt,
        inert_data: tracker.configs.tracker.inert_data,
        loc_min_anch: tracker.configs.tracker.loc_min_anch,
        loc_max_anch: tracker.configs.tracker.loc_max_anch,
        addr: tracker.configs.tracker.addr,
        name: tracker.configs.tracker.name,
      });
    }

    setError(TRACKER_PROPS);
  }

  const validateFields = () => {
    let errorNew = {...TRACKER_PROPS};

    isNumber("nid", config.nid, errorNew);
    isNumber("channel", config.channel, errorNew);
    isNumber("mcs", config.mcs, errorNew);
    isNumber("nss", config.nss, errorNew);
    isNumber("obw", config.obw, errorNew);
    isNumber("payload_length", config.payload_length, errorNew);
    isNumber("tx_power", config.tx_power, errorNew);
    isNumber("npkt", config.npkt, errorNew);
    isNumber("inert_data", config.inert_data, errorNew);
    isNumber("loc_min_anch", config.loc_min_anch, errorNew);
    isNumber("loc_max_anch", config.loc_max_anch, errorNew);
    isEmpty("mode", config.mode, errorNew);
    isEmpty("addr", config.addr, errorNew);
    isEmpty("name", config.name, errorNew);

    setError(errorNew);
    return errorNew.validate;
  };

  const handleCalibrate = (e) => {
    let err = {...error};
    err['status'] = "Tracker - " + tracker.name + " calibrating...";
    setError(err)

    setTimeout(() => {
      props.dispatch(fetchNode(tracker.node));
      err['status'] = "Tracker - " + tracker.name + " calibrated";
      setError(err)
    }, 10000);
  }

  const handleSubmit = (e)=> {
    if (!validateFields()) { return }

    console.log("submit");
    if (editForm) {
      let node = {
        node: {
          name: config.tracker,
          configs: {
            tracker: {
              nid: config.nid,
              channel: config.channel,
              mcs: config.mcs,
              nss: config.nss,
              obw: config.obw,
              payload_length: config.payload_length,
              tx_power: config.tx_power,
              mode: config.mode,
              npkt: config.npkt,
              inert_data: config.inert_data,
              loc_min_anch: config.loc_min_anch,
              loc_max_anch: config.loc_max_anch,
              addr: config.addr,
              name: config.name,
            }
          }
        }
      };

      props.dispatch(updateNode(tracker.node, node));
    }

    setError(Object.assign({}, error, {status: "Saving tracker configs... "}))
    setTimeout(()=>props.dispatch(fetchNodes()), 500);
    setTimeout(()=>props.history.push(URL), 700);
  };

  const updateField = (field, e) => {
    //console.log("update field " + field + " " + e.target.value)
    setConfig(Object.assign({}, config, {[field]: e.target.value}));
  }

  const INFO1 = {
    "Device Name" : tracker && tracker.name,
    "Hardware ID(Mac Address)": tracker && tracker.hardware_id,
    "System Id": tracker && tracker.node,
    "Internal Mac Address": tracker && tracker.configs.tracker.addr,
    "Firmware Version": tracker && tracker.events && tracker.events.system && tracker.events.system.firmware_version,
  }

  const INFO2 = {
    "Calibration Timestamp": tracker && tracker.configs.lps_control && tracker.configs.lps_control.cal ? new Date(tracker.configs.lps_control.cal.timestamp).toString() : "Not Calibrated",
  }

  return(
    <div className="main edit">
      <Link to={URL}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>Edit Tracker Config</h3>
      <HeaderContainer
        node = {tracker && tracker.node}
        name = {tracker && tracker.name}
        handleCalibrate = {handleCalibrate}
        type = "tracker"
      />
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        <div key="edit">
          <LabelContainer
            labels = {
              {
                "internal_mac" : {"help": "addr", "desc": ""},
                "nid" : {"help": "nid", "desc": ""},
                "channel" : {"help": "channel", "desc": ""},
                "mcs" : {"help": "mcs", "desc": ""},
                "nss" : {"help": "nss", "desc": ""},
                "obw" : {"help": "obw", "desc": ""},
                "payload_length" : {"help": "payload", "desc": ""},
                "tx_power" : {"help": "tx_power", "desc": ""},
                "mode" : {"help": "mode", "desc": ""},
                "npkt" : {"help": "npkt", "desc": ""},
                "inert_data" : {"help": "inert_data", "desc": ""},
                "loc_min_anch" : {"help": "min anch needed for tracker locate", "desc": ""},
                "loc_max_anch" : {"help": "max anch needed for tracker locate", "desc": ""},
                "internal_name" : {"help": "internal tracker name", "desc": ""}
              }
          }/>
          <FormContainer
            fields = {{
                "addr" : {"value": config.addr, "placeholder": "", "error": error.addr},
                "nid" : {"value": config.nid, "placeholder": "", "error": error.nid},
                "channel" : {"value": config.channel, "placeholder": "", "error": error.channel},
                "mcs" : {"value": config.mcs, "placeholder": "", "error": error.mcs},
                "nss" : {"value": config.nss, "placeholder": "", "error": error.nss},
                "obw" : {"value": config.obw, "placeholder": "", "error": error.obw},
                "payload_length" : {"value": config.payload_length, "placeholder": "", "error": error.payload_length},
                "tx_power" : {"value": config.tx_power, "placeholder": "", "error": error.tx_power},
                "mode" : {"value": config.mode, "placeholder": "", "error": error.mode},
                "npkt" : {"value": config.npkt, "placeholder": "", "error": error.npkt},
                "inert_data" : {"value": config.inert_data, "placeholder": "", "error": error.inert_data},
                "loc_min_anch" : {"value": config.loc_min_anch, "placeholder": "", "error": error.loc_min_anch},
                "loc_max_anch" : {"value": config.loc_max_anch, "placeholder": "", "error": error.loc_max_anch},
                "name" : {"value": config.name, "placeholder": "", "error": error.name}

            }}
            updateField={updateField}
          />
          <InfoContainer
            info={ checkACL(props.acl, 'Calibration Field') ? {...INFO1, ...INFO2} : INFO1}
          />
        </div>
      </div>
      <div>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
      nodesHash: getNodesHash(state),
      acl: state.user.acl,
    }),
)(EditTrackerConfig);
