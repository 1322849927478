import React from 'react';

import logo from '../../media/Co_Name.svg';
//import SearchBar from '../SearchBar';
import SiteSelector from '../SiteSelector';

const DashboardHeader = () => (
  <div className="header">
    <SiteSelector />
  </div>
)

export default DashboardHeader

//<SiteSelector />
//<SearchBar />
//  <img src={logo} alt="Locix logo" className="locix-branding"/>
//  <img src={require('../../media/zainar-logo.png')} alt='' className="locix-branding"/>
