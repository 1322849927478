import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addCustomerUser, addSiteUser } from '../../../modules/users';
import { myHighestRole, makeSiteMenu, makeUserRoleMenu, ROLE_DESC} from '../Config';

import InfoContainer from '../../settings/form/InfoContainer';
import FormContainer from '../../settings/form/FormContainer';
import LabelContainer from '../../settings/form/LabelContainer';
import HelpModal from '../../common/HelpModal';
import { isEmpty, isEmail } from '../../settings/form/ErrorChecking';

const CONFIG = { email: "", site: "", role: "", validate: true};
const CUSTOMER_ADMIN_URL = "/dashboard/management/users/customeradmin";
const SITE_ADMIN_URL = "/dashboard/management/users/siteadmin";

const AddUser= (props) => {
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [help, setHelp] = useState(CONFIG);
  const [siteMenu, setSiteMenu] = useState([]);
  const [roleMenu, setRoleMenu] = useState([]);
  const [url, setUrl] = useState("");
  const [myRole, setMyRole] = useState(null);

  useEffect(() => {
    //console.log("use effect 1");
    if (url === "") {
      let myRole = myHighestRole(props.acl);
      myRole === 'site-admin' ? setUrl(SITE_ADMIN_URL) : setUrl(CUSTOMER_ADMIN_URL);
      setMyRole(myRole);
    }
  }, [props.acl, url])

  useEffect(() => {
    //console.log("use effect 2");
    if (siteMenu.length === 0 && myRole) {
      //filter sites by customer
      let filter = Object.values(props.sites).filter( site => site.customer === props.match.params.customer);
      setSiteMenu(makeSiteMenu(filter, myRole));
    }
  }, [props.match.params.customer, props.sites, myRole, siteMenu.length])

  useEffect(() => {
    //console.log("use effect 3");
    if (roleMenu.length === 0 && myRole && siteMenu.length > 0) {
      setRoleMenu(makeUserRoleMenu(siteMenu[0].value, myRole));
    }
  }, [myRole, roleMenu.length, siteMenu])

  const validateFields = () => {
    let errorNew = {...CONFIG};
    isEmail("email", config.email, errorNew);
    isEmpty("email", config.email, errorNew);
    isEmpty("site", config.site, errorNew);
    isEmpty("role", config.role, errorNew);
    setError(errorNew);
    return errorNew.validate;
  };

  const handleSubmit = (e)=> {
    if (!validateFields()) {
      return;
   }

   let errorNew = {...error};
   errorNew.status = "An email has been sent to the user to complete registration";
   setError(errorNew);

   let body = {
     user: {
       name: config.email,
       role: config.role,
     }
   }

   let addFunction = config.site === '*' ?
    addCustomerUser(props.authToken, props.match.params.customer, body)
    :
    addSiteUser(props.authToken, config.site, body);

    addFunction
      .then(json => console.log(JSON.stringify(json)))
      .then(()=> setTimeout(()=> props.history.push(url), 1000))
      .catch(err => {
        console.error(err);
        setTimeout(()=> props.history.push(url), 1000);
      });
  };

  const updateField = (field, e, type) => {
    let configNew = {...config};

    if (type && type === 'select') {
      if (!e) { return }
      configNew[field] = e.value;
    } else {
      configNew[field]  = e.target.value;
    }

   if (field === 'site') { //update role menu when new site
      setRoleMenu(makeUserRoleMenu(e.value, myRole));
    }

    if (field === 'role') { //update role help when new role
      let helpNew = {...help};
      helpNew[field] = ROLE_DESC[e.value];
      setHelp(helpNew);
    }

    setConfig(configNew);
  }

  const LABELS = {
    "Email": {"help": "", "desc": ""},
    "Site": {"help": "", "desc": ""},
    "User Type": {"help": "", "desc": ""},
  }

  const FIELDS = {
    "email": {"value": config.email, "placeholder": "", "error": error.email},
    "site" : {"type": "select", "options": siteMenu, placeholder: "", "error": error.site, "help": help.site},
    "role" : {"type": "select", "options": roleMenu, placeholder: "", "error": error.role, "help": help.role},
  }

  return (
    <div className="main edit">
      <Link to={url}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>Add User</h3>
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
      <div key="save">
        <LabelContainer labels= {LABELS}/>
        <FormContainer
          fields = {FIELDS}
          updateField={updateField}
        />
        <InfoContainer
          info={{"Customer": props.customers && props.customers[props.match.params.customer]}}
          help=< HelpModal
                  button="help"
                  help_content=<img alt="site" src={require(myRole === 'site-admin' ? "../img/site-admin-table.png" : "../img/cust-admin-table.png")}/>
                />
        />
      </div>
      </div>
      <div>
          <Link to={url}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
    authToken: state.authToken,
    acl: state.user.acl,
    sites: state.sites.sites,
    customers: state.sites.customers,
  })
)(AddUser);
