import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import SideBar from './SideBar';
import Sites from './sites/Sites';
import EditSite from './sites/EditSite';
import UsersCustomerAdmin from './users/UsersCustomerAdmin'; //customer admin
import UsersSiteAdmin from './users/UsersSiteAdmin'; //site admin
import AddUser from './users/AddUser';
import EditUser from './users/EditUser';
import MyProfile from './myprofile/MyProfile';

const ManagementPage = (props) => {
  const [customer, setCustomer] = useState(null);

  return(
    <div className="settings">
      <SideBar
        acl={props.acl}
        path={props.path}
        customer={customer}
        customers={props.customers}
        selectCustomer={(customer)=> setCustomer(customer)}
      />
      <Switch>
        <Route exact path="/dashboard/management/sites" render={(props) => <Sites {...props} customer={customer} />}/>
        <Route exact path="/dashboard/management/sites/:customer/:id" component={EditSite} />
        <Route exact path="/dashboard/management/users/customeradmin" render={(props) => <UsersCustomerAdmin {...props} customer={customer} />}/>
        <Route exact path="/dashboard/management/users/:customer/new" component={AddUser} />
        <Route exact path="/dashboard/management/users/siteadmin" render={(props) => <UsersSiteAdmin {...props} customer={customer} />}/>
        <Route exact path="/dashboard/management/users/:customer/:site/:id" component={EditUser} />
        <Route exact path="/dashboard/management/myprofile" component={MyProfile} />
      </Switch>
    </div>
)};

export default connect (
  state => ({
    acl: state.user.acl,
    customers: state.sites.customers,
    path: state.routing.location.pathname,
  })
)(ManagementPage);
