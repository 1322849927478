import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
import Sort from '../common/Sort';
import Filter from '../common/Filter';

const TrackerConfigTable = ({origData, type, data, error, setTableData, sortedColumnIndex, setSortedData}) => (
  <div className="main">
    <h3>TRACKER CONFIGS</h3>
    <span id="error">{error}</span>
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>NAME<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            <Link to={`/dashboard/settings/devices/edit_tracker_config/${data[props.rowIndex].node}`}>{data[props.rowIndex].name}</Link>
          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell>HARDWARE ID<Sort thisColumnIndex={3} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].hardware_id}
          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell>NID</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.nid}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>CHANNEL</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.channel}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>MCS</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.mcs}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>NSS</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.nss}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>OBW</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.obw}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>PAYLOAD</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.payload_length}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>TX POWER</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.tx_power}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>MODE</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.mode}
          </Cell>
        )}
        width={100}
      />
      <Column
        header={<Cell>NPKT</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.npkt}
          </Cell>
        )}
        width={50}
      />
      <Column
        header={<Cell>INERT DATA</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.inert_data}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>LOC MIN ANCH</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.loc_min_anch}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>LOC MAX ANCH</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.loc_max_anch}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>FW VERSION</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.firmware_version}
          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell>HW VERSION</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.tracker.hardware_version}
          </Cell>
        )}
        width={125}
      />
      <Column
        header={<Cell>INTERNAL MAC</Cell>}
        cell={props => (
          <Cell {...props} style={{color: data[props.rowIndex].configs.tracker.addr.toLowerCase() === data[props.rowIndex].configs.lps_control.cal_tx_addr ? "" : "#ff0000"}}>
            {data[props.rowIndex].configs.tracker.addr}
          </Cell>
        )}
        width={150}
      />
      <Column
        header={<Cell>CAL</Cell>}
        cell={props => (
          <Cell {...props}>
            {new Date(data[props.rowIndex].configs.lps_control.cal_timestamp).toString()}
          </Cell>
        )}
        width={250}
      />
      <Column
        header={<Cell>CAL TX ADDR</Cell>}
        cell={props => (
          <Cell {...props} style={{color: data[props.rowIndex].configs.tracker.addr.toLowerCase() === data[props.rowIndex].configs.lps_control.cal_tx_addr ? "" : "#ff0000"}}>
            {data[props.rowIndex].configs.lps_control.cal_tx_addr}
          </Cell>
        )}
        width={150}
      />
    </Table>
  </div>
);

export default TrackerConfigTable;
