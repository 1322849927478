import React from 'react';
import Calendar from '../common/Calendar';
import Download from './wms/Download';

import {IMG_CONFIG} from './2dConfig';
import { Tooltip } from 'react-tippy';

const selected_text = {color: "#1b5faa"}
const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "June", "July", "AUG", "SEP", "OCT", "NOV", "DEC"];

function makeDate(from, to) {
  let start = new Date(from)
  let end = new Date(to)
  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  let end_hour = end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
  let end_min = end.getMinutes() < 10 ? "0" +  end.getMinutes() : end.getMinutes()
  let end_date = (end.getMonth() === start.getMonth() && end.getDate() === start.getDate()) ? "" : monthNames[end.getMonth()] + " " + end.getDate() + ", "
  let str = monthNames[start.getMonth()] + " " + start.getDate() + ", " + start_hour + ":" + start_min + " - " +
            end_date + end_hour + ":" + end_min
  return (str)
}

class NavControl2d extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: "1",
      interval: 3600000, //1 hour is default
      dateText: makeDate(this.props.from, this.props.to),
      showCalendar: false,
    };

    this.toggle_hour = this.toggle_hour.bind(this);
    this.back = this.back.bind(this);
    this.forward = this.forward.bind(this);
    this.toggle_calendar = this.toggle_calendar.bind(this);
    this.setCalendarDate = this.setCalendarDate.bind(this);
  }

  reset() {
    let current = Date.now();
    this.props.setFromTo(current - 3600000, current);
    this.setState({hour: "1", interval: 3600000, dateText: makeDate(current - 3600000, current)});
  }

  componentDidMount() {
    //this.reset();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
      this.reset();
    }
  }

  toggle_calendar(e) {
    this.setState((prevState, props) =>({showCalendar: !prevState.showCalendar}))
  }

  //24 hours - 86400000, 12 hours - 13200000, 6 hours - 21600000, 10 min - 600000, 5 min - 300000, 1 min - 60000
  toggle_hour(e) {
    let to = Date.now();
    let interval = 3600000;

    switch(e.target.dataset.hour) {
      case '24':
        interval = 86400000;
      break;
      case '12':
        interval = 43200000;
      break;
      default:
        interval = 3600000;
      }

      let from = to - interval;
      this.setState({hour: e.target.dataset.hour, interval: interval, dateText: makeDate(from, to)});
      this.props.setFromTo(from, to);
  }

  setCalendarDate(start, end) {
    //console.log("setCalendarDate - " + start + " " + end);
    let interval = end - start;

    this.setState({hour: "0", dateText: makeDate(start, end), interval: interval, showCalendar: false});
    this.props.setFromTo(start, end);
  }

  back(e) {
    let start = this.props.from - this.state.interval;
    let end = this.props.to - this.state.interval;
    this.props.setFromTo(start, end);

    this.setState({dateText: makeDate(start, end)});
  }

  forward(e) {
    let start = this.props.from + this.state.interval;
    let end = this.props.to + this.state.interval;
    if (Date.now() < end) {return}

    this.props.setFromTo(start, end);
    this.setState({dateText: makeDate(start, end)});
  }

  render() {
    return (
      <div className="ControlContainer" style={this.props.display ? {} : {display: "none"}}>
        <div style={this.props.view === 'map' ? {display: "inline-block"} : {display: "none"}}>
          <div className="IconSelectorContainer">
            <Tooltip title={this.props.maptype==='realtime' ? IMG_CONFIG['live']['active']['tooltip'] : IMG_CONFIG['live']['inactive']['tooltip']} theme="transparent">
                <img className="MapTypeIcon" alt="live" src={this.props.maptype==='realtime' ? IMG_CONFIG['live']['active']['img'] : IMG_CONFIG['live']['inactive']['img']} data-maptype="realtime" onClick={this.props.setMapType}/>
            </Tooltip>
            <Tooltip title={this.props.maptype==='path' ? IMG_CONFIG['path']['active']['tooltip'] : IMG_CONFIG['path']['inactive']['tooltip']} theme="transparent">
                <img className="MapTypeIcon" alt="path" src={this.props.maptype==='path' ? IMG_CONFIG['path']['active']['img'] : IMG_CONFIG['path']['inactive']['img']} data-maptype="path" onClick={this.props.setMapType}/>
            </Tooltip>
            <Tooltip title={this.props.maptype==='heatmap' ? IMG_CONFIG['heatmap']['active']['tooltip'] : IMG_CONFIG['heatmap']['inactive']['tooltip']} theme="transparent">
                <img className="MapTypeIcon" alt="heatmap" src={this.props.maptype==='heatmap' ? IMG_CONFIG['heatmap']['active']['img'] : IMG_CONFIG['heatmap']['inactive']['img']} data-maptype="heatmap" onClick={this.props.setMapType}/>
            </Tooltip>
            <Tooltip title={this.props.showZone && this.props.zone_src ? IMG_CONFIG['zone']['active']['tooltip'] : IMG_CONFIG['zone']['inactive']['tooltip']} theme="transparent">
                <img className="MapTypeIcon" alt="zone" src={this.props.showZone && this.props.zone_src ? IMG_CONFIG['zone']['active']['img'] : IMG_CONFIG['zone']['inactive']['img']} onClick={this.props.onShowZone}/>
            </Tooltip>
            <Tooltip title={this.props.displayAnchors? IMG_CONFIG['anchor']['active']['tooltip'] : IMG_CONFIG['anchor']['inactive']['tooltip']} theme="transparent">
                <img className="MapTypeIcon" alt="anchor" src={this.props.displayAnchors? IMG_CONFIG['anchor']['active']['img'] : IMG_CONFIG['anchor']['inactive']['img']} onClick={this.props.showAnchors}/>
            </Tooltip>
          </div>
        </div>
        <div style={this.props.view === 'chart' ? {display: "inline-block"} : {display: "none"}}>
          <div className="IconSelectorContainer">
            <Download from={this.props.from} to={this.props.to} isLps={true}/>
          </div>
        </div>
        <div style={this.props.view === 'map' && this.props.maptype === 'realtime' ? {display: "none"} : {display: "inline"}}>
         <div className="DateSelectorContainer">
          <i className="fa fa-caret-left BackStyle"
              onClick={this.back}
          />
          <div className="DateText" onClick={this.toggle_calendar}>{this.state.dateText}</div>
          <i className="fa fa-caret-right" style={ Date.now() < (this.props.to + this.state.interval) ? {display: "none"} : {display: "inline-block", marginLeft: "10px", color: "#0068b0"}} onClick={this.forward}/>

          <div className="HourText" data-hour="24" style={this.state.hour === '24' ? selected_text : {}} onClick={this.toggle_hour}>24 HOURS</div>
          <div className="HourText" data-hour="12" style={this.state.hour === '12' ? selected_text : {}} onClick={this.toggle_hour}>12 HOURS</div>
          <div className="HourText" data-hour="1" style={this.state.hour === '1' ? selected_text : {}} onClick={this.toggle_hour}>1 HOUR</div>
          <div>
            <Calendar from={this.props.from} to={this.props.to} show={this.state.showCalendar} setShow={this.toggle_calendar} onSetCalendarDate={this.setCalendarDate}/>
          </div>
         </div>
        </div>
      </div>
    )
  }
}

export default NavControl2d;

/*
  <div style={this.props.view === 'map' ? {display: "inline-block"} : {display: "none"}}>
    <div className="MapType" style={this.props.maptype === 'realtime' ? selected_text : {}} data-maptype="realtime" onClick={this.props.setMapType}>LIVE</div>
    <div className="MapType2" style={this.props.maptype === 'path' ? selected_text : {}}  data-maptype="path" onClick={this.props.setMapType}>PATHS</div>
    <div className="MapType2" style={this.props.maptype === 'heatmap' ? selected_text : {}}  data-maptype="heatmap" onClick={this.props.setMapType}>HEATMAP</div>
  </div>
*/
