import React from 'react';
import ZoneDonutChart from '../charts/ZoneDonutChartNew';
import {addCommas, convertSiteTime} from '../charts/ChartUtil';

const METERS_PER_FOOT = 0.3048;

const WmsSummary = (({site_stats, zone_config}) => (
      <div className="SummaryChart">
        <div className="SiteHeader">SUMMARY</div>
        <div className="SiteAnalyticsContainer">
          <div className="SummaryAnalyticsBox">
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteDistance.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{addCommas(parseInt(site_stats["distance"] * METERS_PER_FOOT,10))} M</div>
              <div className="SummaryLabel">TOTAL DISTANCE</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {site_stats.hasOwnProperty("forklift") && <div className="SummaryProperty">Forklift</div>}
                <div className="SummaryProperty">Worker</div>
              </div>
              <div className="SummaryValueContainer">
                {site_stats.hasOwnProperty("forklift") && <div className="SummaryValue">{addCommas(parseInt(site_stats['forklift']['distance'] * METERS_PER_FOOT,10))} M</div>}
                <div className="SummaryValue">{addCommas(parseInt(site_stats['worker']['distance'] * METERS_PER_FOOT,10))} M</div>
              </div>
            </div>
          </div>
          <div className="SummaryAnalyticsBox">
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteActiveTime.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{convertSiteTime(site_stats["active_time"])}</div>
              <div className="SummaryLabel">TOTAL ACTIVE TIME</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {site_stats.hasOwnProperty("forklift") && <div className="SummaryProperty">Forklift</div>}
                <div className="SummaryProperty">Worker</div>
              </div>
              <div className="SummaryValueContainer">
                {site_stats.hasOwnProperty("forklift") && <div className="SummaryValue">{convertSiteTime(site_stats['forklift']['active_time'])}</div>}
                <div className="SummaryValue">{convertSiteTime(site_stats['worker']['active_time'])}</div>
              </div>
            </div>
          </div>
          <div className="SummaryAnalyticsBox">
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteInactive.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{convertSiteTime(site_stats["inactive_time"])}</div>
              <div className="SummaryLabel">TOTAL INACTIVE TIME</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {site_stats.hasOwnProperty("forklift") && <div className="SummaryProperty">Forklift</div>}
                <div className="SummaryProperty">Worker</div>
              </div>
              <div className="SummaryValueContainer">
                {site_stats.hasOwnProperty("forklift") &&  <div className="SummaryValue">{convertSiteTime(site_stats['forklift']['inactive_time'])}</div>}
                <div className="SummaryValue">{convertSiteTime(site_stats['worker']['inactive_time'])}</div>
              </div>
            </div>
          </div>
          <div className="SummaryAnalyticsBox" style={{marginRight: "0px"}}>
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteInactive.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{site_stats['active_count']}</div>
              <div className="SummaryLabel">ACTIVE TRACKERS</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {site_stats.hasOwnProperty("forklift") && <div className="SummaryProperty">Forklift</div>}
                <div className="SummaryProperty">Worker</div>
              </div>
              <div className="SummaryValueContainer">
                {site_stats.hasOwnProperty("forklift") && <div className="SummaryValue">{site_stats['forklift']['active_count']}</div>}
                <div className="SummaryValue">{site_stats['worker']['active_count']}</div>
              </div>
            </div>
          </div>
        </div>
        <ZoneDonutChart site_stats={site_stats} durationRaw={1} zone_config={zone_config}/>
      </div>
  )
)

export default WmsSummary;
