import React from 'react';
import h337 from 'heatmap.js';

import { Stage, Layer, Line, Text, Rect, Circle, Label, Tag } from 'react-konva';

import { connect } from 'react-redux';
import { postWmsJsonNew } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import { logoutNode } from '../../modules/nodes';
import { makeLegendNew } from '../common/Util';
import { debounce } from 'lodash';

import HeatmapLegend from './HeatmapLegend';
import EditHeatmapCriteria from './EditHeatmapCriteria';

const METERS_PER_FOOT = 0.3048;


const WIDTH_CUTOFF = 525;
const ZONE_ADJUSTMENT = {
  1: {
    font : 22,
    stroke: 5,
  },
  .5: {
    font: 12,
    stroke: 3,
  }
}

class NavMap2dHeatmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: {show: true, active: true, inactive: true},
      data: [],
      active_data: [],
      inactive_data: [],
      openModal: false,
      loading: false,
      anchors: [],
      gts: [],
      zones: [],
      legend: [".25",".55", ".85", "1"],
      legend_show: true,
      max: 1,
      heatmap_id: Math.random().toString(36).substring(7),
      loading_key: null,
      scale: 1,
    };

    this.onSetType = this.onSetType.bind(this);
    this.onOpenModal = this.onOpenModal.bind(this);
    this.onSetMax = this.onSetMax.bind(this);
    this.repaint = this.repaint.bind(this);
    this.onSetShowLegend = this.onSetShowLegend.bind(this);
    this.debounceRendering = debounce(this.fetch_wms_tracks, 2000);
  }

  componentDidMount() {
    //console.log("Mounted heatmap - " + JSON.stringify(this.state.type));
    this.attach_background();
    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=>this.fetch_wms_tracks(loading_key));
    this.setAnchors();
    this.setGTs();
    this.setState({scale: this.props.width < WIDTH_CUTOFF ? .5 : 1}, ()=>this.loadZones())
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.zones).length !== Object.keys(this.props.zones).length) {
      this.loadZones();
    }

    if (Object.keys(prevProps.anchors).length !== Object.keys(this.props.anchors).length) {
      this.setAnchors();
    }

    if (Object.keys(prevProps.gts).length !== Object.keys(this.props.gts).length) {
      this.setGTs();
    }

    if (Object.keys(prevProps.nodes).length !== Object.keys(this.props.nodes).length ||
        prevProps.pixels_per_meter !== this.props.pixels_per_meter ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals))
      {
          let loading_key = new Date().getTime();
          this.setState({loading_key: loading_key}, ()=>this.debounceRendering(loading_key));
      }
  }

  loadZones() {
    let zones = [];
    for (const z in this.props.zones) {
      let zone = this.props.zones[z];
      zones.push(<Text x={zone.x} y ={zone.y} key={zone.name + "_name"} text={zone.name} fontSize={ZONE_ADJUSTMENT[this.state.scale].font} fontFamily={'Lato'} fill={zone.color}/>)
      zones.push(<Line key={zone.name} points={zone.points} stroke={zone.color} strokeWidth={ZONE_ADJUSTMENT[this.state.scale].stroke} closed={true} />)
    }
    this.setState({zones: zones});
  }

  setAnchors() {
    let anchors = [];
    for (const anchorId in this.props.anchors) {
      const anchor = this.props.anchors[anchorId];
      let newX = (anchor.x + this.props.origin.x) * this.props.pixels_per_meter;
      let newY = this.props.height - ((anchor.y + this.props.origin.y) * this.props.pixels_per_meter);
      anchors.push(<Rect fill={'#44d7b6'} width={8} height={8} x={newX} y ={newY - 5} key={anchorId} />);
    }
    this.setState({anchors: anchors});
  }

  setGTs(){
    let allGTs = [];
    for (const gt in this.props.gts) {
      const g = this.props.gts[gt];
      let newX = (g.x + this.props.origin.x) * this.props.pixels_per_meter;
      let newY = this.props.height - ((g.y + this.props.origin.y) * this.props.pixels_per_meter);
      allGTs.push(<Circle radius={4} fill={"#800080"} x={newX} y={newY} key={gt}/>);

      let thisGTInfo = <Label key={gt + "_label"} x={newX - 10} y={newY - 25}>
                          <Tag key={gt + "_tag"} fill={'white'} stroke={'rgba(0, 0, 0, 0.15'}/>
                          <Text key={gt + "_info"} text={gt} fontSize={12} fontFamily={'Lato'} fill={'#0068b0'} padding={3}/>
                        </Label>
      allGTs.push(thisGTInfo)
    }

    this.setState({gts: allGTs})
  }

  attach_background() {
    document.getElementById(this.state.heatmap_id).innerHTML = "";
    var img = document.createElement("img");
    img.src = require("./img/" + this.props.background_src);
    img.width = this.props.width;
    img.height = this.props.height;
    document.getElementById(this.state.heatmap_id).append(img);
  }

  fetch_wms_tracks(loading_key) {
    //console.log("make heatmap " + loading_key)
    this.setState({legend: makeLegendNew(this.props.duration), max: 1, loading: true});

    let body = {
      time_intervals: this.props.time_intervals,
      assets: Object.keys(this.props.nodes),
      outputs: {heat_map: {cell_size: 1}},
      use_wms: false,
    };
    if (this.props.floor) { body["outputs"]["heat_map"]["floor"] = this.props.floor }

    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        if (loading_key === this.state.loading_key) {
          let data = [];
          let data_active = [];
          let data_inactive = [];
          json.heat_map.forEach(hm => {
            let offset_y = 0; //z offset
            if (this.props.z !== null && hm.point.z >= this.props.z.tracker_min) {
              offset_y = this.props.z.y_offset;
            }

            let newX = (hm.point.x * METERS_PER_FOOT + this.props.origin.x) * this.props.pixels_per_meter;
            let newY = this.props.height - (((hm.point.y + offset_y) * METERS_PER_FOOT + this.props.origin.y) * this.props.pixels_per_meter);

            if (hm.active > 0) {
              data_active.push({x: parseInt(newX, 10), y: parseInt(newY, 10), value: hm.active/this.props.duration });
            }

            if (hm.inactive > 0) {
              data_inactive.push({x: parseInt(newX, 10), y: parseInt(newY, 10), value: hm.inactive/this.props.duration });
            }

            if (hm.active > 0 || hm.inactive > 0) {
              data.push({x: parseInt(newX, 10), y: parseInt(newY, 10), value: (hm.active + hm.inactive)/this.props.duration});
            }
          });

          this.setState({data: data, active_data: data_active, inactive_data: data_inactive, loading: false, loading_key: null}, ()=> this.repaint());
        } else {
          console.log("NavHeatmap - There's another process "  + this.state.loading_key)
        }
      })
      .catch(error => { error.status === 401 ? this.props.dispatch(logoutNode()) : console.log(error)})
  }

  onOpenModal() {
    this.setState((prevState, props)=>({openModal: !prevState.openModal}));
  }

  onSetMax(e) {
    let newMax = parseFloat(e, 10) * 3600000; //assume it's h

    //let newMax = parseFloat(e,10) * 60000; //change legend assume it's m
    //console.log((newMax)/(this.props.to - this.props.from));
    this.setState({legend: makeLegendNew(newMax),
                    max: (newMax)/(this.props.duration)},
                    ()=>this.repaint());
  }

  makeHeatmap() {
    let data = [];
    if (this.state.type.active && this.state.type.inactive) {
      data = [...this.state.data];
    } else if (this.state.type.active) {
      data = [...this.state.active_data];
    } else if (this.state.type.inactive) {
      data = [...this.state.inactive_data];
    }

    let config = {
      container: document.getElementById(this.state.heatmap_id),
      blur: .75,
    }

    this.heatmapInstance = h337.create(config)
    this.heatmapInstance.setData({min: 0, max: this.state.max, data: data})
    console.log("makeHeatmap done - " + JSON.stringify(this.state.type))
  }

  repaint(e) {
    this.attach_background();
    this.makeHeatmap();
  }

  onSetType(e) {
    //console.log(JSON.stringify(e.target.dataset.type));
    this.setState({type: Object.assign({}, this.state.type, JSON.parse(e.target.dataset.type))}, ()=>this.repaint());
  }

  onSetShowLegend() {
    this.setState(prevState => ({legend_show: !prevState.legend_show}))
  }

  render() {
    let marginTop = "0px"
    if (this.props.height <= 700) {
      let adjust = (700 - this.props.height) / 2
      marginTop = adjust + "px"
    }

    return (
      <div>
          <div className="loading">{this.state.loading && "Loading... Please wait..."}</div>
          <div style={{opacity: this.state.loading ? .20 : 1}}>
            <div id={this.state.heatmap_id} style={{width: this.props.width, height: this.props.height, marginLeft: "10px", marginTop: marginTop}}/>
            <div style={{marginLeft: "10px", marginTop: this.props.height * -1, position: "absolute"}}>
              <Stage width={1055} height={this.props.height < 710 ? 710 : this.props.height}>
                <Layer>
                  {this.props.showZone && this.state.zones}
                  {this.state.anchors}
                  {this.state.gts}
                </Layer>
              </Stage>
            </div>
              <div style={{marginLeft: "10px", marginTop: this.props.height * -1, position: "absolute"}}>
                {
                  this.state.legend_show ?
                    <div>
                      <HeatmapLegend
                        legend={this.state.legend}
                        type={this.state.type}
                        onSetType={this.onSetType}
                        onOpenModal={this.onOpenModal}
                      />
                      <img className="ShowHideLegend" alt="hide_legend" src={require("./img/hide_legend_short.png")} onClick={this.onSetShowLegend}/>
                   </div>
                   :
                   <img className="ShowHideLegend" alt="hide_legend" src={require("./img/show_legend_short.png")} onClick={this.onSetShowLegend}/>
                }
              <EditHeatmapCriteria
                openModal={this.state.openModal}
                onOpenModal={this.onOpenModal}
                onSetMax={this.onSetMax}
              />
            </div>
          </div>
      </div>
    )
  }
}

export default connect(
  state => ({ authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(NavMap2dHeatmap);

/*
margin-top: -740px;
margin-left: 10px;

fetch_lps_tracks() {
  let body = {
    "from" : this.props.from,
    "to" : this.props.to,
    "trackers": Object.keys(this.props.nodes),
    "outputs" : { "heat_map": {}},
  }

  postLpsJson(this.props.authToken, this.props.siteId, body)
    .then(json => {
      let data = [];
      json.heat_map.forEach(hm => {
        let newX = hm.point.x * METERS_PER_FOOT * this.props.pixels_per_meter;
        let newY = this.props.height - (hm.point.y * METERS_PER_FOOT * this.props.pixels_per_meter);
        let value = 0;
        switch(this.state.type) {
          case 'active':
            value = hm.active;
            break;
          case 'inactive':
            value = hm.inactive;
            break;
          default: //total
            value = hm.active + hm.inactive
        }
        if (value <= 0 ) { return; }
        data.push({x: parseInt(newX, 10), y: parseInt(newY, 10), value: value })
      })

      return data;
    })
    .then(data => this.setState({data: data, loading: false}))
    .then(()=> this.makeHeatmap())
    .catch(error => { error.status === 401 ? this.props.dispatch(logoutNode()) : console.log(error)})
}


<div className="ShowHideLegend" style={{marginLeft: "10px", position: "absolute"}}>
{
  this.state.legend_show && <img alt="show_legend" src={require("./img/hide_legend_short.png")}/>
}
</div>
*/
