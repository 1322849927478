import React, { useState } from 'react';
import TruckCameraEvent from '../analytics/TruckCameraEvent';
import { calDockStatus, formatTime12Hours, convertToHourMin } from './Utility';

const dockImage = (dock, cameras, showThisDock) => {
  if (!dock.events || !dock.events.dock || !dock.events.dock.occupancy_change) { return ""; }
  if (!cameras.hasOwnProperty(dock.events.dock.occupancy.camera_id)) {
    return "";
  }

  switch (calDockStatus(dock, cameras[dock.events.dock.occupancy.camera_id])) {
    //OFFLINE, DEAD_BATTERY, OCCUPIED_DELAY, OCCUPIED_LOW_BATTERY, AVAILABLE_LOW_BATTERY; OCCUPIED, AVAILABLE
    case 'OFFLINE':
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-offline.png")} alt="Dock Offline" onClick={showThisDock}/>
    case 'OFFLINE_DEAD_BATTERY':
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-offline-dead-battery.png")} alt="Dock Offline Dead Battery" onClick={showThisDock}/>
    case 'OCCUPIED_DELAY':
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-delay.png")} alt="Dock Delayed" onClick={showThisDock}/>
    case 'OCCUPIED_DELAY_LOW_BATTERY':
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-delay.png")} alt="Dock Delayed" onClick={showThisDock}/>
    case 'OCCUPIED_LOW_BATTERY':
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-occupied-low-battery.png")} alt="Dock Occupied Low Battery" onClick={showThisDock}/>
    case 'AVAILABLE_LOW_BATTERY':
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-available-low-battery.png")} alt="Dock available Low Battery" onClick={showThisDock}/>
    case 'OCCUPIED':
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-occupied.png")} alt="Dock Occupied" onClick={showThisDock}/>
    default: //Available
      return <img className="DockImg" data-dock={dock.node} src={require("./img/dock-available.png")} alt="Dock Available" onClick={showThisDock}/>
  }
}

const dwellTime = (dock) => {
  if (!dock.events.dock || !dock.events.dock.occupancy_change) { return <div></div> }
  if (dock.events.dock.occupancy.state === 1) {
    let dwell_time = Date.now() - dock.events.dock.occupancy_change.timestamp;
    return <div className="DockDwell">{convertToHourMin(dwell_time)}</div>
  } else {
    return <div></div>
  }
}

/**************** camera **********************/
const showCameraImage = (dock, cameras, takePicture) => {
  if (!dock.events.dock || !dock.events.dock.occupancy_change) { return "" }
  if (!cameras.hasOwnProperty(dock.events.dock.occupancy.camera_id)) {
    return "";
  }

  let image = <TruckCameraEvent node={cameras[dock.events.dock.occupancy.camera_id]} />
  let statusIcon = <img src={require("./img/available-dock.png")} alt="available"/>
  let delay_alarm = "";
  let occupied_time = <div className="OccupiedTime">{convertToHourMin(Date.now() - dock.events.dock.occupancy_change.timestamp)}</div>
  let camera_time = formatTime12Hours(new Date(cameras[dock.events.dock.occupancy.camera_id].events.camera.media_ready.timestamp).getTime());
  let refresh = <img src={require("./img/refresh.png")} alt="Refresh" data-dock={[dock.node, dock.events.dock.occupancy.camera_id, dock.events.dock.activity.camera_id]} onClick={takePicture}/>

  switch (calDockStatus(dock, cameras[dock.events.dock.occupancy.camera_id])) {
    case 'OFFLINE':
      image = <img src={require("./img/camera-offline.png")} alt="Dock Offline"/>
      statusIcon = "";
      occupied_time = "";
      refresh = <img src={require("./img/refresh_disabled.png")} alt="Refresh-disabled"/>
      break;
    case 'OFFLINE_DEAD_BATTERY':
      image = <img className="DockImg" src={require("./img/camera-offline-dead-battery.png")} alt="Dock Offline Dead Battery"/>
      statusIcon = "";
      occupied_time = "";
      refresh = <img src={require("./img/refresh_disabled.png")} alt="Refresh-disabled"/>
      break;
    case 'OCCUPIED_DELAY':
      statusIcon = <img src={require("./img/occupied-dock.png")} alt="occupied"/>
      delay_alarm = <div className="fa fa-warning DockAlarmIcon"/>
      break;
    case 'OCCUPIED_DELAY_LOW_BATTERY':
      statusIcon = <img src={require("./img/occupied-dock.png")} alt="occupied"/>
      delay_alarm = <div className="fa fa-warning DockAlarmIcon "/>
      break;
    case 'OCCUPIED_LOW_BATTERY':
      statusIcon = <img src={require("./img/occupied-dock.png")} alt="occupied"/>
      break;
    case 'AVAILABLE_LOW_BATTERY':
      statusIcon = <img src={require("./img/available-dock.png")} alt="available"/>
      occupied_time = "";
      break;
    case 'OCCUPIED':
      statusIcon = <img src={require("./img/occupied-dock.png")} alt="occupied"/>
      break;
    default: //Available
      statusIcon = <img src={require("./img/available-dock.png")} alt="available"/>
      occupied_time = "";
    }

  let header =
  <div className="DockCameraHeader">
    {"#" + dock.name.replace(/dock\s+/i, "")}
    {statusIcon}
    {occupied_time}
    {delay_alarm}
  </div>

  let footer = <div className="DockCameraFooter">{camera_time}{refresh}</div>
  return <div style={{display: "inline-block"}}>{header}{image}{footer}</div>
}

const SmartDocks = ({docks, cameras, showThisDock, takePicture}) => {
  const [view, setView] = useState("icon");

  return (
    <div className="DocksContainer">
     <div>
      <div className="DockHeader">SMART DOCK</div>
      <div className={view === 'camera' ? "DockViewOption SelectedOption" : "DockViewOption"} onClick={() => setView("camera")}>CAMERA VIEW</div>
      <div className={view === 'icon' ? "DockViewOption SelectedOption" : "DockViewOption"} onClick={() => setView("icon")}>ICON VIEW</div>
     </div>
     <div className="CameraContainer" style={{display: view !== "icon" && "none"}}>
      {
        Object.values(docks).sort((a,b)=> a.name.localeCompare(b.name)).map (dock =>
          <div key = {dock.node} className="Dock">
            <div className="DockId">{dock.name.replace(/dock\s+/i, "")}</div>
            {dockImage(dock, cameras, showThisDock)}
            {dwellTime(dock)}
          </div>
        )
      }
      </div>

      <div className="CameraContainer" style={{display: view !== "camera" && "none"}}>
       {
         Object.values(docks).sort((a,b)=> a.name.localeCompare(b.name)).map (dock =>
           <div key = {dock.node} className="DockCameraImg">
             {showCameraImage(dock, cameras, takePicture)}
           </div>
         )
       }
       </div>

    </div>
  )
}

export default SmartDocks;

/*<div className="IconContainer" style={{display: view !== "icon" && "none"}}>*/
