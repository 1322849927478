import { API_ROOT } from '../DashboardConfig';
import { WAREHOUSE } from '../components/2dReact/2dConfig';

export function fetchAPI(url, authToken, extraInit = {}, extraHeaders = {}) {

  // let authToken = window.localStorage.authToken;
  if (!authToken) {
    return Promise.reject(new Error('Not logged in'));
  }

  return new Promise((resolve, reject) => {

    let headers = Object.assign({
      'Authorization': 'Bearer ' + authToken,
    }, extraHeaders);

    let init = Object.assign({
      headers: headers,
    }, extraInit);

    fetch(url, init)
      .then(response => {
        if (response.ok) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchPath(path, authToken, extraInit = {}, extraHeaders = {}) {
    let url = API_ROOT + path;
    return fetchAPI(url, authToken, extraInit, extraHeaders);
}

export function fetchJson(path, authToken, extraInit = {}, extraHeaders = {}) {

    let headers = Object.assign({
        'Content-Type': 'application/json',
    }, extraHeaders);

    return fetchPath(path, authToken, extraInit, headers)
        .then(response => response.json());

}



export function fetchSiteJson(sitePath, authToken, siteId, extraInit = {}, extraHeaders = {}) {

    if (!siteId) {
      return Promise.reject(new Error('No currentSiteId'));
    }
    let path = sitePath;
    return fetchJson(path, authToken, extraInit, extraHeaders);

}

export function fetchObject(path, authToken, extraInit={}, extraHeaders = {}) {
    let url = API_ROOT + path;

    let headers = Object.assign({
        'Content-Type': 'application/json',
    }, extraHeaders);

    return fetchAPI(url, authToken, extraInit, headers);
}

export function fetchObjectHistory(objectId, authToken, siteId, params = {}) {
    let extraParams = '';
    if(params) {
      extraParams = '?' + Object.keys(params).map(function(key) {
          return [key, params[key]].join("=");
      }).join("&");
    } else {
      extraParams = '';
    }

    let path = 'objects/' + objectId + '/history' + extraParams;
    let init = {
      method: 'get',
    }

    return fetchSiteJson(path, authToken, siteId, init);
}

export function fetchLpsJson(authToken, siteId, params = {}) {
    let extraParams = '';
    if(params) {
      extraParams = '?' + Object.keys(params).map(function(key) {
          return [key, params[key]].join("=");
      }).join("&");
    } else {
      extraParams = '';
    }

    let path = 'sites/' + siteId + '/lps' + extraParams;
    let init = {
      method: 'get',
    }

    return fetchSiteJson(path, authToken, siteId, init);
}

export function postLpsJson(authToken, siteId, body = {}) {
  let path = 'sites/' + siteId + '/lps';
  let init = {
    method: "post",
    body: JSON.stringify(body)
  }

  return fetchSiteJson(path, authToken, siteId, init);
}

export function postWmsJson(authToken, siteId, body = {}) {
  let path = 'test/sites/' + siteId + '/lps';

  let time_zone_offset = new Date().getTimezoneOffset()/60;
  if (time_zone_offset === -9) { //check for JST timezone
    body = Object.assign({}, body, {time_zone: 'JST'});
  } else if (time_zone_offset === 7) {
    body = Object.assign({}, body, {time_zone: 'PDT'});
  } else if (time_zone_offset === 6) {
    body = Object.assign({}, body, {time_zone: 'MDT'});
  } else if (time_zone_offset === 5) {
    body = Object.assign({}, body, {time_zone: 'CDT'});
  } else if (time_zone_offset === 4) {
    body = Object.assign({}, body, {time_zone: 'EDT'});
  }

  let init = {
    method: "post",
    body: JSON.stringify(body)
  }

  return fetchSiteJson(path, authToken, siteId, init);
}

export function postWmsJsonNew(authToken, siteId, body = {}) {
  let path = 'sites/' + siteId + '/lps-wms-stats';

  let time_zone_offset = new Date().getTimezoneOffset()/60;
  if (time_zone_offset === -9) { //check for JST timezone
    body = Object.assign({}, body, {time_zone: 'JST'});
  } else if (time_zone_offset === 7) {
    body = Object.assign({}, body, {time_zone: 'PDT'});
  } else if (time_zone_offset === 6) {
    body = Object.assign({}, body, {time_zone: 'MDT'});
  } else if (time_zone_offset === 5) {
    body = Object.assign({}, body, {time_zone: 'CDT'});
  } else if (time_zone_offset === 4) {
    body = Object.assign({}, body, {time_zone: 'EDT'});
  }

  let init = {
    method: "post",
    body: JSON.stringify(body)
  }

  return fetchSiteJson(path, authToken, siteId, init);
}

/*sites/[site]/wms/upload/[time_zone] */
export function postWmsFile(authToken, siteId, body = {}) {
  let path = 'test/sites/' + siteId + '/wms/data/Japan';

  /*
  if (siteId === 'd954674dabd7411e812759b0edf0a4e5') { //ABInBevTulsa
    path = 'test/sites/' + siteId + '/wms/data/' + encodeURIComponent("America/Chicago");
  }
  */

  if (WAREHOUSE[siteId].hasOwnProperty("wms_time_zone")) {
    path = 'test/sites/' + siteId + '/wms/data/' + encodeURIComponent(WAREHOUSE[siteId].wms_time_zone);
  }

  let url = API_ROOT + path;

  let init = {
    method: "post",
    body: body,
  }

  let header = {
    "Content-Type": "text/csv",
  }

  return fetchAPI(url, authToken, init, header);
}

export function downloadWmsFile(authToken, siteId, body = {}) {
  let path = 'test/sites/' + siteId + '/lps/download';
  let url = API_ROOT + path;

  let init = {
    method: "post",
    body: JSON.stringify(body),
  }

  let header = {
    'Content-Type': 'application/json',
  }

  return fetchAPI(url, authToken, init, header);
}

//{{api}}/sites/{{site}}?detailed
export function fetchSiteDetailJson(authToken, siteId, params = {}) {
    let extraParams = '';
    if(params) {
      extraParams = '&' + Object.keys(params).map(function(key) {
          return [key, params[key]].join("=");
      }).join("&");
    } else {
      extraParams = '';
    }

    let path = 'sites/' + siteId + '?detailed' + extraParams;
    let init = {
      method: 'get',
    }

    return fetchSiteJson(path, authToken, siteId, init)
}

// export function fetchNodeHistory(nodeId, authToken, siteId) {
//     let reqBody = {
//         histogram: "0",
//         limit: "50", // Need to change this or support pagination
//         event_type: 'camera_media',
//         node_id: nodeId
//     };

//     let init = {
//         method: 'post',
//         body: JSON.stringify(reqBody),
//     };
//     return fetchSiteJson('history', authToken, siteId, init)
//         // .then(json => this.onHistoryResponse(json))
//         // .catch(this.onError);
//     // this.onHistoryHistogramResponse(testdata);
// }

// export function fetchConstellationHistory(constellation_id, authToken, siteId) {
//     let reqBody = {
//         histogram: "0",
//         limit: "50", // Need to change this or support pagination
//         event_type: 'camera_media',
//         constellation_id
//     };

//     let init = {
//         method: 'post',
//         body: JSON.stringify(reqBody),
//     };
//     return fetchSiteJson('history', authToken, siteId, init);
// }

//this function will timet out - put a need to logout if it's 401 response.
//also this function doens't work connecting to staging - srcURl problem
//put a catch in for now
export function fetchMediaBlob(key, authToken, extraInit = {}, extraHeaders = {}) {
  //console.log("FETCHMEDIABLOB - " + key);
  return new Promise((resolve, reject) => {
    let url = `${API_ROOT}blobs/` + key;
    //console.log(url);
    fetchAPI(url, authToken, extraInit, extraHeaders)
      .then(response => {
        if (response.ok) {
          //console.log("fetch media is okay");
          return response;
        } else {
          console.log("API.js - FetchMediaBlob - response not okay " + response.status);
          return response;
        }
      })
      .then(response => response.blob())
      .then(blob => resolve(URL.createObjectURL(blob)))
      .catch(error => reject(error));
  });
}

export function login(username, password, rememberMe) {
  return new Promise(function(resolve, reject) {

      let url = API_ROOT + 'authenticate';

      // credentials: 'include',
      let init = {
          method: 'post',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              username,
              password,
          })
      };

      console.log('Logging in', url, username);
      fetch(url, init)
        .then(response => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
  });
}

/************ Lapis Demo *********/
export function fetchLapisData(type) {
  //type = trips, logger, logger_data, etc.

  let url = "https://api.np.locix.com/stt/test/1.0/" + type + "/";
  let authToken = 'd5d3021b-9b8e-42c5-89df-91d4443010fb';
  let init = {
    method: 'get'
  }
  return fetchAPI(url, authToken, init, {})
      .then(response => response.json());
}

export function createLapisTracker(body = {}) {
  let url = "https://api.np.locix.com/stt/test/1.0/trackers/";
  let authToken = 'd5d3021b-9b8e-42c5-89df-91d4443010fb';

  let init = {
    method: "post",
    body: JSON.stringify(body),
  }

  return fetchAPI(url, authToken, init, {})
    .then(response => response.json());
}

export function fetchLapisTracker(id) {
  let url = "https://api.np.locix.com/stt/test/1.0/trackers/" + id;
  let authToken = 'd5d3021b-9b8e-42c5-89df-91d4443010fb';
  let init = {
    method: 'get'
  }
  return fetchAPI(url, authToken, init, {})
      .then(response => response.json());
}

/*export function fetchLapisData(type) {
  return new Promise(function(resolve, reject) {
      let url = "https://api.np.locix.com/stt/test/1.0/loggers/";
      //let url = 'https://api.np.locix.com/stt/test/1.0/sites/b4d8242e-a850-45df-8b2b-84a55e00497b/trips/';
      //let url = "https://api.publicapis.org/entries";
      //let url = "https://prodk.api.locix.com/pipeline/r/api/v2/client/"
      let authToken = 'd5d3021b-9b8e-42c5-89df-91d4443010fb';
      let init = {
        method: 'get',
        headers: {
          //'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authToken
        },
      };

      fetch(url, init)
        .then(response => {
          if (response.ok) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch(error => {
          reject(error);
        });
  });
}*/

/************ Lapis Demo *********/
