import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNodesHash, createNode, updateNode, fetchNodes } from '../../modules/nodes';
import { fetchSiteDetailJson } from '../../modules/api';
import { checkACL } from '../acl/Config';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';
import {isEmpty, isSelectEmpty, isAtleastHex, isNumber, isGreaterEqual} from '../settings/form/ErrorChecking';

const CONFIG = {tracker: "", hardware_id: "", type: "", mode: "", rate: "", sensitivity: "", color: "", status: "", role: "", aka: "", validate: true};
const URL = "/dashboard/settings/devices/tracker";
const OPTIONS =  {worker: { value: 'worker', label: 'Worker' }, forklift: {value: 'forklift', label: 'Forklift' }, phone: {value: 'phone', label: 'Phone'}};
const MOTION = ['HIGH', 'MEDIUM', 'LOW']; //high = 1, medium = 2, low = 3

const MODE = {
  off: {tracking_mode:0, locate_mode: 0},
  continuous: {tracking_mode:1, locate_mode: 0},
  motion: {tracking_mode:2, locate_mode: 1},
  'on stop': {tracking_mode:0, locate_mode: 1},
}

const ROLEOPTIONS = {
  site_manager: { value: 'site_manager', label: 'Site Manager' },
  subcontract_manager: {value: 'subcontract_manager', label: 'Subcontract Manager'},
  subcontractor: {value: 'subcontractor', label: 'Subcontractor'},
  equipment: {value: 'equipment', label: 'Equipment'}
};

const EditTrackerNew = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [tracker, setTracker] = useState(null);
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [roleAka, setRoleAka] = useState({});
  const [roleOptions, setRoleOptions] = useState(ROLEOPTIONS);
  const [akaOptions, setAkaOptions] = useState({});

  useEffect(()=> {
    if (props.match.params.id === "undefined" ) { return; }
    if (props.match.params.id === id) { return; }

    console.log("useEffect 1");
    switch (props.match.params.id) {
      case 'new':
        resetForm(null);
        break;
      default:
        resetForm(props.nodesHash[props.match.params.id])
    }
  });

  useEffect(()=>{
    console.log("useEffect 2");

    fetchSiteDetailJson(props.authToken, props.site)
    .then(json => {
      let roleAka = json.state?.configs?.lps?.role_aka || {};
      setRoleAka(roleAka);

      let rOptions = {};
      Object.keys(roleAka).forEach(role => {
        rOptions[role] = {value: role, label: role};
      })

      setRoleOptions(rOptions);
    })
  },[props.site, props.authToken])

  useEffect(()=> {
      console.log("useEffect3");
      let akas = roleAka[config.role?.value ? config.role.value : config.role] || [];

      let akaOptions = {};
      akas.forEach(aka => {
        akaOptions[aka] = {value: aka, label: aka};
      })

      setAkaOptions(akaOptions);
  }, [roleAka, config.role])

  const resetForm = (tracker) => {
    if (tracker) {
      //console.log("set editForm - " + JSON.stringify(tracker))
      setId(tracker.node)
      setEditForm(true);
      setTracker(tracker);
      setConfig({
        tracker: tracker.name,
        hardware_id: tracker.hardware_id,
        type: tracker.configs.tracker.tracker_type,
        mode: calculateMode(tracker.configs.tracker.tracking_mode, tracker.configs.tracker.locate_mode),
        rate: (tracker.configs.tracker.track_backoff / 1000).toFixed(1),
        sensitivity: MOTION[tracker.configs.tracker.inert_threshold - 1],
        color: tracker.tags && tracker.tags.color,
        aka: tracker.tags?.aka,
        role: tracker.tags?.role,
        company: tracker.tags?.company
      });
    } else {
      console.log("set newForm")
      setId("new");
      setEditForm(false);
      setTracker(null);
      setConfig(CONFIG);
    }
    setError(CONFIG);
  }

  const calculateMode = (tracking_mode, locate_mode) => {
    for (const m in MODE) {
      if (tracking_mode === MODE[m].tracking_mode && locate_mode === MODE[m].locate_mode) {
        return m
      }
    }
  }

  const handleDelete = (e) => {
    setTimeout(()=>props.history.push(URL), 700);
  };

  const handleRestart = (e) => {
   let err = {...error};
   e === 'success' ?  err['status'] = tracker.name +  " restarted " :  err['status'] = "Error restarting " + tracker.name;
   setError(err);
  }

  const validateFields = () => {
    let errorNew = {...CONFIG};

    if (!editForm) { //if save
      for (const node in props.nodesHash) {
        if (config.tracker === props.nodesHash[node].name) {
          errorNew['tracker'] = 'Field has to be unique';
          errorNew['validate'] = false;
        }
      }

      isEmpty("hardware_id", config.hardware_id, errorNew);
      isAtleastHex("hardware_id", config.hardware_id, errorNew);
    }

    if (editForm){
      isEmpty("tracker", config.tracker, errorNew);
      isSelectEmpty("type", config.type, errorNew);
      isEmpty("rate", config.rate, errorNew);
      isNumber("rate", config.rate, errorNew);
      if (!checkACL(props.acl, 'Tracking Rate Field')) {
        isGreaterEqual("rate", config.rate, errorNew, 2);
      }
    }

    setError(errorNew);
    return errorNew.validate;
  };

  const handleSubmit = (e)=> {
    if (!validateFields()) {
      return;
   }

    console.log("submit");
    if (editForm) {
      let node = {
        node: {
          name: config.tracker,
          configs: {
            tracker: {
              tracker_type: config.type.value,
              tracking_mode: MODE[config.mode].tracking_mode,
              locate_mode: MODE[config.mode].locate_mode,
              track_backoff: config.rate * 1000,
              inert_threshold: config.sensitivity ?  MOTION.indexOf(config.sensitivity) + 1 : 1,
            }
          },
          tags: {
            color: config.color,
            aka: config.aka?.value ? config.aka.value : config.aka,
            role: config.role?.value ? config.role.value : config.role,
            company: config.company?.value ? config.company.value : config.company,
          }
        }
      };

      props.dispatch(updateNode(tracker.node, node));
    } else {
      console.log("Save Node");
      let node = {
        node: {
          name: config.tracker,
          hardware_id: config.hardware_id,
          node_type: 'tracker',
        }
      };
      props.dispatch(createNode(node));
    }

    setTimeout(()=>props.dispatch(fetchNodes()), 500);
    setTimeout(()=>props.history.push(URL), 700);
  };

  const updateField = (field, e, type) => {
    let configNew = {...config};
    if (type && (type === 'select' || type === 'color_picker' || type === 'button_group')) {
      configNew[field] = e;
    } else {
      configNew[field]  = e.target.value;
    }
    setConfig(configNew);
  }

  const INFO1 = {
    "Hardware ID(Mac Address)": tracker && tracker.hardware_id,
    "System Id": tracker && tracker.node,
    "Internal Mac Address": tracker && tracker.configs.tracker.addr,
    "Firmware Version": tracker && tracker.events && tracker.events.system && tracker.events.system.firmware_version,
  }

  const INFO2 = {
    "Calibration Timestamp": tracker && tracker.configs.lps_control && tracker.configs.lps_control.cal ? new Date(tracker.configs.lps_control.cal.timestamp).toString() : "Not Calibrated",
  }

  return(
    <div className="main edit">
      <Link to={URL}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? "Edit Tracker " : "Add New Tracker"}</h3>
      {editForm &&
        <HeaderContainer
          node = {tracker && tracker.node}
          name = {tracker && tracker.name}
          charge = {tracker && tracker.events && tracker.events.battery && tracker.events.battery.charge}
          voltage = {tracker && tracker.events && tracker.events.battery && tracker.events.battery.voltage}
          current = {tracker && tracker.events && tracker.events.battery && tracker.events.battery.current}
          rssi = {tracker && tracker.events && tracker.events.radio && tracker.events.radio.rssi}
          status={tracker && tracker.events && tracker.events.system && tracker.events.system.connection && (tracker.events.system.connection.connected || tracker.configs.tracker.tracker_type === 'phone') ? "connected" : "disconnected"}
          handleDelete={handleDelete}
          handleRestart={handleRestart}
        />
      }
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        { editForm ?
          <div key="edit">
            <LabelContainer
              labels = {
                { "Device Name": {},
                  "Tracker Type": {"help": "Sets the icon type displayed for the tracker in the map"},
                  "Tracking Mode": {"help" : "Trackers can be set to locate when the tracker is in motion, continuously on an interval, or whenever the tracker stops moving. When set to off, the tracker locations will only update when a user refreshes the tracker in the UI"},
                  "Tracking Rate": {"help": "The rate at which a tracker sends updates of its location"},
                  "Motion Sensitivity": {"help" : "Determines the sensitivity of the tracker when in \"Motion\" tracking mode."},
                  "Path Color": { "help": "Sets the path color for the tracker when using \"Path View\" in the site map"},
                  "Company": { "help": "Company for this equipment or subcontractor"},
                  "Role": {"help": "External role for customer reporting and analytics. Site Manager - A manager and system owner for all site activitie. Subcontract Manager - A manager responsible for a specific set of subcontract labor. Subcontractor - A subcontract worker under supervision of the subcontract manager. Equipment - A set of equipment for use by various subcontract companies."},
                  "AKA": {"help": "External names for customer reporting and analytics"}
                }
            }/>
            <FormContainer
              fields = {{
                  "tracker": {"value": config['tracker'] ? config.tracker : "", "placeholder": "", "error": error.tracker},
                  "type" :  {"type": "select",
                             "options": Object.values(OPTIONS),
                             "value": config['type'] ? OPTIONS[config.type] : "",
                             "placeholder": "",
                             "error": error.type
                            },
                  "mode" :  {"type": "button_group", "options": Object.keys(MODE), "value": config['mode'], "error": error.mode},
                  "rate" :  {"type": "short_input", "text": "seconds", "value": config['rate' ]? config.rate : "", "placeholder": "", "error": error.rate},
                  "sensitivity" :  {"type": "button_group", "options": [...MOTION].reverse(), "value": config['sensitivity' ], "error": error.sensitivity},
                  "color" :  {"type": "color_picker", "value": config.color, "error": error.color},
                  "company": {
                            "value": config.company,
                            "placeholder": "",
                            "error": error.company
                          },
                  "role": { "type": "select",
                            "options": Object.values(roleOptions),
                            "value": config['role'] ? roleOptions[config.role] : "",
                            "placeholder": "",
                            "error": error.role
                          },
                  "aka": { "type": "select",
                            "options": Object.values(akaOptions),
                            "value": config['aka'] ? akaOptions[config.aka] : "",
                            "placeholder": "",
                            "error": error.aka
                          },
              }}
              updateField={updateField}
            />
            <InfoContainer
              info={ checkACL(props.acl, 'Calibration Field') ? {...INFO1, ...INFO2} : INFO1}
            />
          </div>
          :
          <div key="save">
            <LabelContainer labels= {{"Device Name": {"help": "", "desc": ""}, "Hardware ID": {"help": "", "desc": ""}}}/>
            <FormContainer
              fields = {{
                  "tracker": {"value": config.tracker, "placeholder": "", "error": error.tracker},
                  "hardware_id": {"value": config.hardware_id, "placeholder": "", "error": error.hardware_id},
              }}
              updateField={updateField}
            />
          </div>
        }
      </div>
      <div>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
      authToken: state.authToken,
      site: state.sites.currentSite.site,
      nodesHash: getNodesHash(state),
      acl: state.user.acl,
    }),
)(EditTrackerNew);
