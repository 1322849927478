import React from 'react';
import { IMG_CONFIG } from '../2dReact/2dConfig';

const LpsFloor = (props) => (
    <div className="lps_menu_item">FLOOR
    <div className="floor_menu">
      {props.floor_order.map(f =>
        props.disable ?
          <img className="floor_button" key={f} alt={f} src={IMG_CONFIG[f]['inactive'].img}/>
          :
          <img className="floor_button" key={f} alt={f} data-source={f} src={f === props.floor ? IMG_CONFIG[f]['active'].img : IMG_CONFIG[f]['inactive'].img} onClick={props.selectFloor}/>
      )}
    </div>
    </div>
);

export default LpsFloor;
