import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import './css/2dReactChart.css';
//import Site from './charts/Site';
import Summary from './charts/Summary';
import Activity from './charts/Activity';
import ZoneTime from './charts/ZoneTime';
import ZoneDistance from './charts/ZoneDistance';
import Metric from './charts/Metric';
import DownloadChart from './wms/DownloadChart';

import { fetchSiteDetailJson, /*fetchLpsJson,*/ postLpsJson} from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
//import { logoutNode } from '../../modules/nodes';

const LIMIT = 6;
class NavChart2d extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: "",
      sortedNodes: [],
      zone_config: {},
      site_stats: {}, //will be obsoleted
      tracker_stats: {},
      tracker_stats_filtered: {},
      summary: {},
      expand: false,
      chartType: null, //chart of expanded - activity
      loading_key: null,
      downloadChartType: "", //metric,
      downloadType: "", //time or percent
      downloadFile: false, //hide or show modal
    };

    this.onSetClose = this.onSetClose.bind(this);
    this.onSetChartType = this.onSetChartType.bind(this);
    this.onSetDownloadType = this.onSetDownloadType.bind(this);
    this.closeDownload = this.closeDownload.bind(this);
    this.debounceRendering = debounce(this.fetchLpsStats, 2000);
  }

  componentDidMount() {
    this.fetchZoneDetail(this.props.siteId);
    this.sortAndFilterNodes(this.props.nodes);
    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=>this.fetchLpsStats(loading_key));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
       this.fetchZoneDetail(this.props.siteId);
    }

    if (Object.keys(prevProps.nodes).length !== Object.keys(this.props.nodes).length) {
      this.sortAndFilterNodes(this.props.nodes);
    }

    if (prevProps.siteId !== this.props.siteId ||
        Object.keys(prevProps.nodes).length !== Object.keys(this.props.nodes).length ||
        prevProps.from !== this.props.from ||
        prevProps.to !== this.props.to) {
          let loading_key = new Date().getTime();
          this.setState({loading_key: loading_key}, ()=>this.debounceRendering(loading_key));
    }
  }

  fetchZoneDetail(siteId) {
    fetchSiteDetailJson(this.props.authToken, this.props.siteId, {})
     .then(json => {
       if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('tracker') && json.state.configs.tracker.hasOwnProperty('zones')) {
         this.setState({zone_config: json.state.configs.tracker.zones});
       }
     })
  }

  sortAndFilterNodes(nodes) {
    let sorted = [];
    for (const n in nodes) {
      sorted.push(nodes[n])
    }

    sorted.sort((a,b) => a.name.localeCompare(b.name));
    this.setState({sortedNodes: sorted});
  }

  sortAndFilterStats(stats) {
    let filteredStats = {};
    this.state.sortedNodes.slice(0, LIMIT).forEach(n => {
      filteredStats[n.id] = stats[n.id];
    })

    return filteredStats;
  }

  /*let body = { //this.props.from, this.props.to, trackers???
    "from": 1562634000000,
    "to": 1562634600000,
    "trackers": [
          "c39a861236e9406ab5f906aaae9fb5ec",
          "36501c57d7764c40b26c190ecaa97565"
    ],
    "outputs": {
          "stats": {},
          "summary": {},
          "tracks": {},
          "unfiltered_tracks": {}
    }
  }
  //returns summary, stats, tracks, unfiltered_tracks
  */

  fetchLpsStats(loading_key) {
    //console.log("Nodes: " + Object.keys(this.props.nodes).length + " LPS query start: " + new Date());
    this.setState({loading: "Loading..Please wait...", summary: {}, site_stats: {}, tracker_stats: {}, tracker_stats_filtered: {}});
    let body = {"from": this.props.from, "to": this.props.to, "trackers": Object.keys(this.props.nodes), "outputs": {"stats":{}, "summary": {}}};

    postLpsJson(this.props.authToken, this.props.siteId, body)
      //.then(json => {console.log("LPS query end: " + new Date()); return json})
      .then(json => {
        if (this.state.loading_key === loading_key) {
          this.setState({summary: json.summary, tracker_stats: json.stats, tracker_stats_filtered: this.sortAndFilterStats(json.stats), loading: "", loading_key: null}, /*()=>console.log(JSON.stringify(json.summary))*/)
        } else {
          console.log("NavChart - there's another process")
        }
      });
  }

  onSetClose(e) {
    if (this.state.chartType !== null) {
      this.setState({chartType: null, expand: false})
    }
  }

  onSetChartType(e) {
    //console.log(e.target.dataset.chart)
    if (this.state.chartType !== e.target.dataset.chart) {
      this.setState({chartType: e.target.dataset.chart, expand: true})
    }
  }

  onSetDownloadType(e) {
    console.log(e.target.dataset.charttype);
    console.log(e.target.dataset.type);
    this.setState({downloadChartType: e.target.dataset.charttype, downloadType: e.target.dataset.type, downloadFile: true});
  }

  closeDownload(e) {
    this.setState({downloadFile: false}); //open and close the modal
  }

  render() {
    return(
      <div>
        <div className="loading">{this.state.loading}</div>
        <div style={{opacity: this.state.loading.length > 0 ? .2 : 1}}>
          <DownloadChart
            from={this.props.from}
            to={this.props.to}
            chart_type={this.state.downloadChartType}
            type={this.state.downloadType}
            sorted_nodes={this.state.sortedNodes}
            asset_stats={this.state.tracker_stats}
            zone_config={this.state.zone_config}
            downloadFile={this.state.downloadFile}
            closeDownload={this.closeDownload}
            expand={this.state.expand}
           />
          {!this.state.expand &&
            <div>
              <Summary site_id={this.props.siteId} site_stats={this.state.summary} duration={this.props.to - this.props.from} zone_config={this.state.zone_config}/>
            </div>
          }
          {(!this.state.expand || this.state.chartType === 'activity') &&
            <div>
              <Activity
                from={this.props.from}
                to={this.props.to}
                sorted_nodes={this.state.expand? this.state.sortedNodes : this.state.sortedNodes.slice(0,LIMIT)}
                tracker_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
                expand={this.state.expand}
                setClose={this.onSetClose}
                setChartType={this.onSetChartType}
                setDownloadType={this.onSetDownloadType}
                displayDownload={true} //{this.props.displayDownload} user permissions
                />
            </div>
          }
          {(!this.state.expand || this.state.chartType === 'zone_time') &&
            <div>
              <ZoneTime
                from={this.props.from}
                to={this.props.to}
                zone_src={this.props.zone_src}
                zone_config={this.state.zone_config}
                sorted_nodes={this.state.expand? this.state.sortedNodes : this.state.sortedNodes.slice(0,LIMIT)}
                tracker_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
                expand={this.state.expand}
                setClose={this.onSetClose}
                setChartType={this.onSetChartType}
                setDownloadType={this.onSetDownloadType}
                displayDownload={true} //{this.props.displayDownload} user permissions
                />
            </div>
          }
          {(!this.state.expand || this.state.chartType === 'zone_distance') &&
            <div>
              <ZoneDistance
                from={this.props.from}
                to={this.props.to}
                zone_src={this.props.zone_src}
                zone_config={this.state.zone_config}
                sorted_nodes={this.state.expand? this.state.sortedNodes : this.state.sortedNodes.slice(0,LIMIT)}
                tracker_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
                expand={this.state.expand}
                setClose={this.onSetClose}
                setChartType={this.onSetChartType}
                setDownloadType={this.onSetDownloadType}
                displayDownload={true} //{this.props.displayDownload} user permissions
                />
           </div>
         }
         {(!this.state.expand || this.state.chartType === 'metric') &&
           <div>
             <Metric
               from={this.props.from}
               to={this.props.to}
               sorted_nodes={this.state.expand? this.state.sortedNodes : this.state.sortedNodes.slice(0,LIMIT)}
               tracker_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
               expand={this.state.expand}
               setClose={this.onSetClose}
               setChartType={this.onSetChartType}
               setDownloadType={this.onSetDownloadType}
               displayDownload={true} //{this.props.displayDownload} user permissions
               />
            </div>
          }
        </div>
      </div>
    )
  }

}

export default connect(
  state => ({
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  }),
)(NavChart2d);


/*
{this.state.expand ? "" : <Site site_stats={this.state.site_stats} duration={this.props.to - this.props.from} zone_config={this.state.zone_config}/> }
{this.state.expand ? "" : <Summary site_stats={this.state.summary} duration={this.props.to - this.props.from} zone_config={this.state.zone_config}/> }

{this.state.expand && this.state.chartType !== 'activity' ? "" : <Activity from={this.props.from} to={this.props.to} sorted_nodes={this.state.sortedNodes} tracker_stats={this.state.tracker_stats} expand={this.state.expand} setClose={this.onSetClose} setChartType={this.onSetChartType}/>}
{this.state.expand && this.state.chartType !== "zone_time" ? "" : <ZoneTime from={this.props.from} to={this.props.to} zone_src={this.props.zone_src} zone_config={this.state.zone_config} sorted_nodes={this.state.sortedNodes} tracker_stats={this.state.tracker_stats} expand={this.state.expand} setClose={this.onSetClose} setChartType={this.onSetChartType}/>}
{this.state.expand && this.state.chartType !== 'zone_distance' ? "" : <ZoneDistance zone_src={this.props.zone_src} zone_config={this.state.zone_config} expand={this.state.expand} setClose={this.onSetClose} setChartType={this.onSetChartType}/>}

{this.state.expand && this.state.chartType !== 'zone_distance' ? "" : <ZoneDistance zone_src={this.props.zone_src} zone_config={this.state.zone_config} expand={this.state.expand} setClose={this.onSetClose} setChartType={this.onSetChartType}/>}

key={this.props.siteId + "_NavChart2d"}
siteId={this.props.siteId}
nodes={this.state.worldNodes}
from={this.state.from}
to={this.state.to}
zone_src={warehouse[this.props.siteId] && warehouse[this.props.siteId].zone_src ? warehouse[this.props.siteId].zone_src : null}

"site_stats": {
        "distance": 74487.47089701738,
        "active_time": 4.09216965,
        "inactive_time": 29.90783035,
        "active_count_by_type": {
            "unknown": 4,
            "forklift": 1,
            "worker": 0
        },
        "active_time_by_zone": {
            "1": 0.34846240000000034,
            "2": 0.5318983999999998
        }
    },
*/

/*
{"distance":12658.371460018147,"active_time":1.2884833333333325,"inactive_time":32.71151666666667,"active_count_by_type":{"worker":25,"forklift":5,"unknown":0},
"time_by_zone":{"1":0.005205833333333333,"2":0.060990555555555555,"3":0.23110083333333345,"4":0.005508055555555555,"5":0.011893611111111113,"6":0.045676666666666664,"7":0.06332472222222223,
"8":0.04561916666666666,"10":0.11781611111111107,"11":0.029451666666666675,"12":0.1296413888888889,"13":6.95429194444444,"14":0.11720777777777777,"15":0.14217527777777775,"16":0.0011305555555555557,"17":0.004034166666666666,
"19":0.005489722222222223,"20":0.016189999999999996,"21":0.04002499999999999,"22":0.0007425,"23":0.28681027777777773,"24":0.03889083333333334,"25":0.36470916666666675,
"26":0.2268388888888889}}

"ea6c6b7b877e495a8088fa4e91ac575a":{
"distance":3850.64567838468,
"active_time":0.3527544444444446,
"zone_stats":{
"10":{
"distance":525.6226569899189,
"active_time":0.3477719444444444
},
"16":{
"distance":7.401763616868617,
"active_time":0.0008022222222222222
},
"24":{
"distance":155.26782346175702,
"active_time":0.026063888888888886
}
},
*/
//.then(tracks => {console.log(nodes); console.log(Object.keys(tracks))})
/*.then(tracks => {
  let paths_filtered = {...this.state.paths_filtered};
  let circles_filtered = [...this.state.circles_filtered];

  for (const n in tracks) {
    if (nodes.indexOf(n) < 0) { continue; }

    let meas_id = null;
    tracks[n].locations.forEach(loc => {
    if (meas_id === loc.measurement) { console.log("duplicated " + meas_id); return } else {meas_id = loc.measurement}
    let newX = loc.point.x * METERS_PER_FOOT * this.props.pixels_per_meter
    let newY = this.props.height - (loc.point.y * METERS_PER_FOOT * this.props.pixels_per_meter)
    paths_filtered[n].path.push(newX)
    paths_filtered[n].path.push(newY)

    let key = n + "_2_" + loc.measurement + loc.timestamp;
    let data = "REMOVED " + tracks[n].tracker.name + ", " +
               "Meas_id:" + loc.measurement  + ", " +
               "Timestamp:" + formatTime(loc.timestamp) + ", " +
               "Type:" + loc.type + ", " +
               "Ranges:" + loc.ranges + ", " +
               "X:" + loc.point.x.toFixed(1) + ", " +
               "Y:" + loc.point.y.toFixed(1);

    circles_filtered.push(<Circle key={key} data={data} radius={4} fill={paths_filtered[n].color}  x={newX} y={newY} onMouseOver={this.onHover} onMouseOut={this.onClear}/>)
    })//end forEach
  }
  this.setState({loading: false, paths_filtered: paths_filtered, circles_filtered: circles_filtered}, ()=>console.log( "MakePath done for filtered " + circles_filtered.length));
})*/
