import React from 'react';
import { connect } from 'react-redux';
import { getNodesArray, triggerCamera } from '../../modules/nodes';
import { getDocksArray } from '../../modules/docks';

import { fetchObjectHistory } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import { setCookie, getCookie } from '../common/Util';

import './css/SmartDock.css';
import { makeDate, cal_full_occupancy, make_occupancy_data, make_activity_data, calBizType, calBizFromTo } from './Utility';
import SmartDockDetail from './SmartDockDetail';
import Calendar from '../common/Calendar';
import DockDetailSnapshot from './DockDetailSnapshot';
import DockDetailChart from './DockDetailChart';
import DockImages from './DockImages';
import DockDetailTable from './DockDetailTable';

const selected_text_style = {color: "#0068b0"}

class DockDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nodeId: this.props.match.params.dock,
      dock: null,
      cameras: null,
      history: [],
      last_occupancy_history: [],
      from: Date.now() - 86400000, //default is last 24 hours 86400000
      to: Date.now(),
      stats: { "trucks" : 0, "delays": 0, "average": 0, "utilization": 0, "arrival_gap": 0, "departure_gap": 0 },
      dateText: "TODAY",
      selectedText: "1 DAY",
      showCalendar: false,
      switchMenu: false,
      occupied_data: [], //chart
      activity_data: [], //chart
      showPastImgs: false,
      queryImgTime: null,
      extImgEvent: null,
      intImgEvent: null,
      extImgDockStatus: null,
      table: [],
    }

    this.onRefresh = this.onRefresh.bind(this);
    this.selectTimeRange = this.selectTimeRange.bind(this);
    this.toggle_calendar = this.toggle_calendar.bind(this);
    this.toggle_menu = this.toggle_menu.bind(this);
    this.setCalendarDate = this.setCalendarDate.bind(this);
    this.setCameraEvents = this.setCameraEvents.bind(this);
    this.toggleCamervaEvents = this.toggleCamervaEvents.bind(this);
  }

  componentDidMount() {
    let day = getCookie('smartdock_select') ? getCookie('smartdock_select') : 7;
    let selectedText = calBizType(day);
    let fromTo = calBizFromTo(Date.now(), selectedText);
    this.setState({from: fromTo[0], to: fromTo[1], dateText: makeDate(fromTo[0], fromTo[1]), selectedText: selectedText});

    /*if (getCookie('smartdock_select')) {
      let day = getCookie('smartdock_select');
      let from = Date.now() - 86400000 * day;
      let to = Date.now();
      let selectedText = "";

      switch(parseInt(day)) {
        case 3:
          selectedText = '3 DAYS';
          break;
        case 7:
          selectedText = '1 WEEK';
          break;
        default:
          selectedText = '1 DAY';
          break;
      }
      this.setState({from: from, to: to, dateText: makeDate(from, to), selectedText: selectedText});
    }*/

    this.loadDock();
  }

  componentDidUpdate(prevProps) {
    let prev = prevProps.docks.filter(d => d.node === this.state.nodeId);
    let now = this.props.docks.filter(d => d.node === this.state.nodeId);

    if (JSON.stringify(prev) !== JSON.stringify(now)) {
      console.log("Dock Detail Page component update")
      this.loadDock();
    }
  }

  loadDock() {
    let found = null;
    for(let d of this.props.docks) {
      if (this.state.nodeId === d['node']) {
        found = d;
        break;
      }
    }

    let cameras = {};
    for(let n of this.props.nodes) {
      if (n.node === found.events.dock.occupancy.camera_id) {
        cameras[found.events.dock.occupancy.camera_id] = n;
      }

      if (n.node === found.events.dock.activity.camera_id) {
        cameras[found.events.dock.activity.camera_id] = n;
      }

      if (Object.keys(cameras).length === 2) { break; }
    }

    this.setState({dock: found, cameras: cameras}, ()=> this.fetchActivityChangeHistory())
  }

  fetchActivityChangeHistory() {
    fetchObjectHistory(this.state.nodeId, this.props.authToken, this.props.currentSiteId, {event: "dock:activity_change", from: this.state.from, to: this.state.to, limit: 100000})
      .then(json => this.fetchOccupancyChangeHistory(json.history))
  }

  fetchOccupancyChangeHistory(prev_history) {
    fetchObjectHistory(this.state.nodeId, this.props.authToken, this.props.currentSiteId, {event: "dock:occupancy_change", from: this.state.from, to: this.state.to, limit: 100000})
      .then(json => prev_history.concat(json.history).sort((a,b) => a.timestamp - b.timestamp))
      .then(json => this.setState({history: json}))
      .then(() => this.fetchPreviousOccupancy())
  }

  fetchPreviousOccupancy() {
    fetchObjectHistory(this.state.nodeId, this.props.authToken, this.props.currentSiteId, {event: "dock:occupancy", to: this.state.from, limit: 1})
      .then(json => this.setState({last_occupancy_history: json.history}))
      .then(() => this.calculate_dock())
      //.then(()=> this.fetchActivityHistory())
  }

  //for motion
  fetchActivityHistory(){
     fetchObjectHistory(this.state.nodeId, this.props.authToken, this.props.currentSiteId, {event: "dock:activity", from: this.state.from, to: this.state.to, limit: 100000})
      .then(json => json.history.sort((a,b) => a.timestamp - b.timestamp))
      .then(json => {
        let data = [];
        json.forEach(h => {
          data.push({"x": h.timestamp, "y": h.update.events.dock.activity.motion/8});
        })
        return data;
      })
      .then(data => this.setState({activity_data: data}));
  }

  calculate_dock() {
    //console.log("History length - " + this.state.history.length)
    let result = cal_full_occupancy(this.state.history, this.state.from, this.state.to, this.state.last_occupancy_history);
    let occupied_data = make_occupancy_data(this.state.history.filter(h => h.update.events.dock.hasOwnProperty('occupancy_change')), this.state.from, this.state.to, this.state.last_occupancy_history);
    let activity_data = make_activity_data(this.state.history.filter(h => h.update.events.dock.hasOwnProperty('activity_change')), (this.state.to - this.state.from));
    this.setState({stats: result[0], occupied_data: occupied_data, activity_data: activity_data, table: result[1]})
  }

  onRefresh(e) {
    this.props.dispatch(triggerCamera(e.target.dataset.dock.split(",")[1], 'image'));
    this.props.dispatch(triggerCamera(e.target.dataset.dock.split(",")[2], 'image'))
  }

  selectTimeRange(e) {
    if (!isNaN(e.target.dataset.days)) {
      let to = Date.now();
      let from = to -  86400000;
      let selectedText = calBizType(e.target.dataset.days);
      let fromTo = calBizFromTo(Date.now(), selectedText);
      switch(e.target.dataset.days) {
        case '60':
          setCookie("smartdock_select", 60, 30);
          break;
        case '30':
          setCookie("smartdock_select", 30, 30);
          break;
        case '14':
          setCookie("smartdock_select", 14, 30);
          break;
        case '7':
          setCookie("smartdock_select", 7, 30);
          break;
        case '2':
          setCookie("smartdock_select", 2, 30);
          break;
        default: //1 day
          setCookie("smartdock_select", 1, 30);
      }

      this.setState({from: fromTo[0], to: fromTo[1], selectedText: selectedText, dateText: makeDate(fromTo[0], fromTo[1])}, ()=> this.fetchActivityChangeHistory())
      return;
    }

    let interval = this.state.to - this.state.from;
    let selectedText = "CUSTOM";
    let now = Date.now();
    let to = this.state.to;
    let from = this.state.from;

    if (e.target.dataset.days === "back") {
      interval = interval * -1;
    }

    to += interval;
    from += interval;
    if (to > now) { to = now}
    if (from > now) { from  = now}

    setCookie('smartdock_select', "", 30);
    this.setState({from: from, to: to, selectedText: selectedText, dateText: makeDate(from, to)}, ()=> this.fetchActivityChangeHistory());
  }

  toggle_calendar(e) {
    this.setState((prevState, props) =>({showCalendar: !prevState.showCalendar}))
  }

  /*this.setState((prevState, props) => ({
    counter: prevState.counter + props.increment
  }));*/
  toggle_menu(e) {
    this.setState((prevState, props)=> ({switchMenu : !prevState.switchMenu}))
  }

  setCalendarDate(start, end) {
    setCookie('smartdock_select', "", 30);
    this.setState({from: start, to: end, selectedText: "custom", dateText: makeDate(start, end), showCalendar: false}, ()=>this.fetchActivityChangeHistory());
  }

/*
this.setState((prevState, props) => ({
  counter: prevState.counter + props.increment
}));
*/
  //query for camera images closest to the timestamp
  /*setCameraEvents(e) {
    //console.log(e.target.value);
    if (!this.state.activity_data.length && !this.state.occupied_data.length) {
      console.log("No activity or occupied data");
      return;
    }

    let from = this.state.activity_data.length ? this.state.activity_data[0].x : this.state.occupied_data[0].x;
    if (this.state.occupied_data.length > 0 && this.state.activity_data.length && this.state.activity_data[0].x > this.state.occupied_data[0].x) {
      from = this.state.occupied_data[0].x;
    }

    let to = this.state.activity_data.length ? this.state.activity_data[this.state.activity_data.length - 1].x : this.state.occupied_data[this.state.occupied_data.length - 1].x;
    if (this.state.occupied_data.length > 0 && this.state.activity_data.length && this.state.activity_data[this.state.activity_data.length - 1].x < this.state.occupied_data[this.state.occupied_data.length - 1].x) {
      to = this.state.occupied_data[this.state.occupied_data.length - 1].x;
    }

    let t = Math.round(from + (e.target.value * (to - from)));

    fetchObjectHistory(this.state.dock.events.dock.occupancy.camera_id, this.props.authToken, this.props.currentSiteId, {event: "camera:media_ready", to: t, limit: 1})
      .then(json => json.history.length > 0 && this.setState({queryImgTime: t, extImgEvent: Object.assign({}, {name: this.state.cameras[this.state.dock.events.dock.occupancy.camera_id].name}, json), showPastImgs: true}));


    fetchObjectHistory(this.state.dock.events.dock.activity.camera_id, this.props.authToken, this.props.currentSiteId, {event: "camera:media_ready", to: t, limit: 1})
      .then(json => this.setState({queryImgTime: t, intImgEvent: Object.assign({}, {name: this.state.cameras[this.state.dock.events.dock.activity.camera_id].name}, json),  showPastImgs: true}))

    //fetch dock occupany history -
    //{"id":"03465b19a7c5477a8abf67d1936c6829","type":"node","last_key":"03465b19a7c5477a8abf67d1936c6829:dock:occupancy/1627908647672:0000000000003","history":[{"timestamp":1627908647672,"version":3,"update":{"events":{"dock":{"occupancy":{"camera_id":"9ee97f8073b44cb9ab3cefa4f2346ad7","key":"prodk-a24748754e84469f9738ba2832dd7f46-b43d4ac9b6c14fa2b4b9e8193bc1d033-211503edc09e4e1398f47b7eea2c92f4/9ee97f8073b44cb9ab3cefa4f2346ad7/image/1627908653754-b2ca49d726b44b2cb3fcae21a398aa52.jpg","state":1}}}}}]}
    fetchObjectHistory(this.state.nodeId, this.props.authToken, this.props.currentSiteId, {event: "dock:occupancy", to: t, limit: 1})
        .then(json => json.history.length > 0 && this.setState({extImgDockStatus: json.history[0]}))
  }*/

  setCameraEvents(e) {
    let t = Math.round(this.state.from + (e.target.value * (this.state.to - this.state.from)));

    fetchObjectHistory(this.state.dock.events.dock.occupancy.camera_id, this.props.authToken, this.props.currentSiteId, {event: "camera:media_ready", to: t, limit: 1})
      .then(json => json.history.length > 0 && this.setState({queryImgTime: t, extImgEvent: Object.assign({}, {name: this.state.cameras[this.state.dock.events.dock.occupancy.camera_id].name}, json), showPastImgs: true}));


    fetchObjectHistory(this.state.dock.events.dock.activity.camera_id, this.props.authToken, this.props.currentSiteId, {event: "camera:media_ready", to: t, limit: 1})
      .then(json => this.setState({queryImgTime: t, intImgEvent: Object.assign({}, {name: this.state.cameras[this.state.dock.events.dock.activity.camera_id].name}, json),  showPastImgs: true}))

    //fetch dock occupany history -
    //{"id":"03465b19a7c5477a8abf67d1936c6829","type":"node","last_key":"03465b19a7c5477a8abf67d1936c6829:dock:occupancy/1627908647672:0000000000003","history":[{"timestamp":1627908647672,"version":3,"update":{"events":{"dock":{"occupancy":{"camera_id":"9ee97f8073b44cb9ab3cefa4f2346ad7","key":"prodk-a24748754e84469f9738ba2832dd7f46-b43d4ac9b6c14fa2b4b9e8193bc1d033-211503edc09e4e1398f47b7eea2c92f4/9ee97f8073b44cb9ab3cefa4f2346ad7/image/1627908653754-b2ca49d726b44b2cb3fcae21a398aa52.jpg","state":1}}}}}]}
    fetchObjectHistory(this.state.nodeId, this.props.authToken, this.props.currentSiteId, {event: "dock:occupancy", to: t, limit: 1})
        .then(json => json.history.length > 0 && this.setState({extImgDockStatus: json.history[0]}))
  }


  toggleCamervaEvents(e) {
    this.setState((prevState, props) => ({showPastImgs: !prevState.showPastImgs}));
  }

  render() {
    return(
      <div>
        {
          this.state.showPastImgs ?
          <DockImages
            dock={this.state.dock}
            queryTime={this.state.queryImgTime}
            extCameraEvent={this.state.extImgEvent}
            intCameraEvent={this.state.intImgEvent}
            hideThis={this.toggleCamervaEvents}
            extImgDockStatus={this.state.extImgDockStatus}
            queryImgTime={this.state.queryImgTime}
          />
          :
          <SmartDockDetail
            key={this.props.match.params.dock}
            dock={this.state.dock}
            cameras={this.state.cameras}
            showThisDock={null}
            history={this.state.history}
            refresh={this.onRefresh}
          />
        }
        <div className="SmartDockPage">
          <div>
            <div className="History">HISTORY</div>
              <div className="DockDateContainer">
                <i className="fa fa-caret-left Left" data-days="back" onClick={this.selectTimeRange}/>
                <div className="DockDate" onClick={this.toggle_calendar}>{this.state.dateText}</div>
                  {
                    this.state.to + (this.state.to - this.state.from )> Date.now() ? ""
                      :
                      <i className="fa fa-caret-right Right" data-days="forward" onClick={this.selectTimeRange}/>
                  }

                  <div className="CannedSelect" style={{display: !this.state.switchMenu && "none"}}>
                    <i className="fa fa-caret-left Left" onClick={this.toggle_menu}/>
                    <div className="Days" data-days="2"  style={this.state.selectedText === 'YESTERDAY' ? selected_text_style : {}} onClick={this.selectTimeRange}>
                      YESTERDAY
                    </div>
                    <div className="Days" data-days="14" style={this.state.selectedText === 'LAST WEEK' ? selected_text_style : {}} onClick={this.selectTimeRange}>
                      LAST WEEK
                    </div>
                    <div className="Days" data-days="60" style={this.state.selectedText === 'LAST MONTH' ? selected_text_style : {}} onClick={this.selectTimeRange}>
                      LAST MONTH
                    </div>
                  </div>
                  <div className="CannedSelect" style={{display: this.state.switchMenu && "none"}}>
                    <div className="Days" data-days="1"  style={this.state.selectedText === 'TODAY' ? selected_text_style : {}} onClick={this.selectTimeRange}>
                      TODAY
                    </div>
                    <div className="Days" data-days="7" style={this.state.selectedText === 'THIS WEEK' ? selected_text_style : {}} onClick={this.selectTimeRange}>
                      WEEK
                    </div>
                    <div className="Days" data-days="30" style={this.state.selectedText === 'THIS MONTH' ? selected_text_style : {}} onClick={this.selectTimeRange}>
                      MONTH
                    </div>
                    <i className="fa fa-caret-right Left" onClick={this.toggle_menu}/>
                  </div>

                <Calendar from={this.state.from} to={this.state.to} show={this.state.showCalendar} setShow={this.toggle_calendar} onSetCalendarDate={this.setCalendarDate}/>
              </div>
          </div>

          <DockDetailSnapshot trucks={this.state.stats.trucks} delays={this.state.stats.delays} average={this.state.stats.average} utilization={this.state.stats.utilization} gaps={this.state.stats.arrival_gap + this.state.stats.departure_gap}/>
          <div className="History">ACTIVITY</div>
          <div className="RangeSliderContainer DockDetailRangeContainer">
            <input className="RangeSlider" type="range" step=".001" defaultValue="1" min="0" max="1" onMouseUp={this.setCameraEvents} onKeyUp={this.setCameraEvents} />
          </div>
          <DockDetailChart dock={this.props.match.params.dock}
                           labels={[]}
                           dock_occupied={this.state.occupied_data}
                           interior_activity={this.state.activity_data}
                           min={this.state.from}
                           max={this.state.to}
                           />
          <DockDetailTable dock={this.state.dock} ext_camera={this.state.dock ? this.state.dock.events.dock.occupancy.camera_id : ""} rows={this.state.table}/>
        </div>
      </div>
    )
  }
}

export default connect(
  state => (
    {
      docks: getDocksArray(state), //docks only
      nodes: getNodesArray(state), //cameras and docks together
      authToken: state.authToken,
      currentSiteId: getCurrentSiteId(state)
   }),
)(DockDetailPage);

/*
<div className="Days" data-days="7" style={this.state.selectedText === '1 WEEK' ? selected_text_style : {}} onClick={this.selectTimeRange}>
  1 WEEK
</div>
<div className="Days" data-days="3" style={this.state.selectedText === '3 DAYS' ? selected_text_style : {}} onClick={this.selectTimeRange}>
  3 DAYS
</div>
<div className="Days" data-days="1"  style={this.state.selectedText === '1 DAY' ? selected_text_style : {}} onClick={this.selectTimeRange}>
  1 DAY
</div>

selectTimeRange(e) {
  if (!isNaN(e.target.dataset.days)) {
    let to = Date.now();
    let from = to -  86400000;
    let selectedText = "1 DAY";
    setCookie('smartdock_select', 1, 30);

    if (e.target.dataset.days === "3") {
      from = to - (86400000 * 3);
      selectedText = "3 DAYS";
      setCookie('smartdock_select', 3, 30);
    }

    if (e.target.dataset.days === "7") {
        from = to - (86400000 * 7);
        selectedText = "1 WEEK";
        setCookie('smartdock_select', 7, 30);
    }

    this.setState({from: from, to: to, selectedText: selectedText, dateText: makeDate(from, to)}, ()=> this.fetchActivityChangeHistory())
    return;
  }

  let interval = this.state.to - this.state.from;
  let selectedText = "CUSTOM";
  let now = Date.now();
  let to = this.state.to;
  let from = this.state.from;

  if (e.target.dataset.days === "back") {
    interval = interval * -1;
  }

  to += interval;
  from += interval;
  if (to > now) { to = now}
  if (from > now) { from  = now}

  setCookie('smartdock_select', "", 30);
  this.setState({from: from, to: to, selectedText: selectedText, dateText: makeDate(from, to)}, ()=> this.fetchActivityChangeHistory());
}
*/
