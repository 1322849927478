import React from 'react';
import { connect } from 'react-redux';

import '../css/2dReact.css';
import { WAREHOUSE } from '../2dConfig.js';
import { checkSiteACL} from '../../acl/Config';
import { OOB } from '../OOBConfig.js'

//import { GIDS, DEFAULT_GROUP_SELECT, DEFAULT_WORKER_SUBFILTER } from './GIDConfig';
import {RACKS, COORDINATES } from './NewRackConfig';

import { getCurrentSiteId} from '../../../modules/sites';
import { fetchSiteDetailJson, postWmsJsonNew } from '../../../modules/api';

import { setCookie, getCookie } from '../../common/Util';
import WMSList from './WMSList';
import WMSControl from './WMSControl';
import NavFloorControl from '../NavFloorControl';
import WMSCharts from './WMSCharts';
import WmsHeatmap from './WmsHeatmap';
import WmsPathmap from './WmsPathmap';
import WmsRacks from './WmsRacks';

class WMSPageMultMaps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      time_intervals: [{from: Date.now() - 3600000, to: Date.now()}],
      duration: 3600000,
      worker_hash: {}, //{id => {id, name, type, attached, select}}
      selected_worker_hash: {}, //{id => {id, name, type}} selected workers
      view: "map", //"chart", //map, chart
      maptype: "heatmap", //path, heatmap, racks
      showNode: "",
      map_config: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId] : WAREHOUSE['default'],
      floors: {}, //{id: show}
      height: 700, //default
      gids: {groups: {}, default_group_selection: {}, default_worker_subselection: {}, default_list_selection: {}, worker_ids: {}, rack_config: {}},
      selectAll: {},  //{'Floor 2 Forklift': true, 'Floor 2 Worker': true,'Floor 1 Forklift':true, 'Floor 1 Worker':true};
      selectSubfilter: {}, //={'attached': true, 'unattached': false};
      loading: "",
      oobs: {}, //{floor: oobs}
    };

    this.setTimeIntervals = this.setTimeIntervals.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onSelectSubfilter = this.onSelectSubfilter.bind(this);
    this.setView = this.setView.bind(this);
    this.setMapType = this.setMapType.bind(this);
    this.onSetShowNode = this.onSetShowNode.bind(this);
    this.onSetFloor = this.onSetFloor.bind(this);
  }

  componentDidMount() {
    console.log("mounted");
    this.loadFloors();
    this.queryGIDs();
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteId !== prevProps.siteId) {
      this.setState({
        map_config: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId] : WAREHOUSE['default'],
      }, () => this.loadFloors());

      console.log("updated");
      this.setState({
        worker_hash: {},
        selected_worker_hash: {},
      }, () => this.queryGIDs());
    }
  }

  loadFloors() {
    let floors = {"All": true}; //insert default for all
    let height = 0;
    this.state.map_config.map_order.forEach(id => {
      floors[id] = true;
      height += this.state.map_config.maps[id].small.height > 700 ? this.state.map_config.maps[id].small.height  + 60 : 700;
    })

    this.setState({floors: floors, height: height}, ()=>this.loadOOB())
  }

  loadOOB() {
    if (OOB.hasOwnProperty(this.props.siteId)) {
      let oob_config = {}
      for (const floor in OOB[this.props.siteId]) {
        oob_config[floor] = {};
        for (const zoneId in OOB[this.props.siteId][floor]) {
          oob_config[floor][zoneId] = {
              name: zoneId,
              color: "#A9A9A9",
              points: [],
          };

          OOB[this.props.siteId][floor][zoneId].points.forEach((p, index) => {
            let newX = (p.x + this.state.map_config.maps[floor].origin.x) * this.state.map_config.maps[floor].small.pixels_per_meter;
            let newY = this.state.map_config.maps[floor].small.height - ((p.y + this.state.map_config.maps[floor].origin.y) * this.state.map_config.maps[floor].small.pixels_per_meter);

            oob_config[floor][zoneId].points.push(newX);
            oob_config[floor][zoneId].points.push(newY);
          })
        }
      }
      this.setState({oobs: oob_config})
    }
  }

  queryGIDs() {
    this.setState({loading: "Loading assets..Please wait..."});
    fetchSiteDetailJson(this.props.authToken, this.props.siteId)
    .then(json => {
      if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('wms')) {
        Object.keys(json.state.configs.wms.default_group_selection).forEach(type => {
          if (getCookie("wms_select_all_" + type) === "") {
            setCookie("wms_select_all_" + type, true);
          }
        });

        if (getCookie("wms_select") === "") {
          setCookie("wms_select", JSON.stringify({}), 30);
        }

        this.setState({gids: json.state.configs.wms}, ()=>this.queryWMsWorkers());
      }
    })
  }

  queryWMsWorkers() {
    this.setState({loading: "Loading assets..Please wait..."})
    let body = {time_intervals: this.state.time_intervals, outputs: {assets: {}}};
    console.log("queryWMsWorkers " + JSON.stringify(body))

    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => this.filterSortWorkers(this.state.gids.worker_ids, json))
      .catch(error => {
        this.setState({loading: "Error - Status " + error.status});
        console.log(error)
      })
  }

  filterSortWorkers(json, wms_json) { //gids, from wms query
    let worker_hash = {...this.state.worker_hash};

    for (const n in json) {
      worker_hash[n] = Object.assign(json[n], {assigned: wms_json.hasOwnProperty(n)});
    }

    this.setState({loading: "", selectAll: this.state.gids.default_group_selection, selectSubfilter: this.state.gids.default_worker_subselection, worker_hash: worker_hash}, ()=> this.filterSelect());
  }

  //goes to this.state.selectAll and this.state.selectSubfilter and update this.state.selected_worker_hash
  filterSelect() {
    let selected_workers = {};
    let already_selected = JSON.parse(getCookie("wms_select"));

    for (const n in this.state.worker_hash) {
      let node = this.state.worker_hash[n];

      if (this.state.selectAll[node.type]) {
        if (node.type.endsWith("Forklift")) {
          selected_workers[n] = node;
          already_selected[n] = true;
          continue;
        }

        if ((!this.state.selectSubfilter.attached && !this.state.selectSubfilter.unattached) ||
            (this.state.selectSubfilter.attached && node.assigned) ||
            (this.state.selectSubfilter.unattached && !node.assigned)){
          selected_workers[n] = node;
          already_selected[n] = true;
        }
      }
    }
    setCookie("wms_select", JSON.stringify(already_selected), 30);
    this.setState({selected_worker_hash: selected_workers});
  }

  setTimeIntervals(timeArray) {
    this.setState({loading: "Loading assets..Please wait..."})
    let body = {time_intervals: timeArray, outputs: {assets: {}}};
    console.log("settimeintervals " + JSON.stringify(body));
    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        let worker_hash = {...this.state.worker_hash};
        for (const n in this.state.gids.worker_ids) {
          worker_hash[n] = Object.assign(this.state.gids.worker_ids[n], {assigned: json.hasOwnProperty(n)});
        }
        return worker_hash;
      })
      .then(worker_hash => {
        let duration = 0;
        timeArray.forEach(t => duration += t.to - t.from);
        this.setState({loading: "", time_intervals: timeArray, duration: duration, worker_hash: worker_hash, selectSubfilter: this.state.gids.default_worker_subselection})
    })
      .catch(error => {
        this.setState({loading: "Error - Status " + error.status})
      })
  }

  onSelectAll(e) {
    let select_all = {...this.state.selectAll};
    let type = e.target.dataset.type;
    let selected = JSON.parse(e.target.dataset.checked);

    if (selected /*e.target.checked*/) {
      setCookie("wms_select_all_" + type, true, 30);
    } else {
      setCookie("wms_select_all_" + type, false, 30);
    }

    select_all[type] = selected;
    this.setState({selectAll: select_all}, () => this.filterSelectAll(type, selected));
  }

  filterSelectAll(type, selected) {
    let selected_workers = {...this.state.selected_worker_hash};
    let already_selected = JSON.parse(getCookie("wms_select"));

    for (const n in this.state.worker_hash) {
      let node = this.state.worker_hash[n];
      if (node.type !== type) { continue; }

      delete selected_workers[n];
      delete already_selected[n];
      if (selected) {
        if (node.type.endsWith('Forklift')) {
          selected_workers[n] = node;
          already_selected[n] = true;
          continue;
        }

        //worker
        if ((!this.state.selectSubfilter.attached && !this.state.selectSubfilter.unattached) ||
            (this.state.selectSubfilter.attached && node.assigned) ||
            (this.state.selectSubfilter.unattached && !node.assigned)){
          selected_workers[n] = node;
          already_selected[n] = true;
        }
      }
    }

    setCookie("wms_select", JSON.stringify(already_selected), 30);
    this.setState({selected_worker_hash: selected_workers});
  }

  onSelectSubfilter(e) {
    let config = {...this.state.selectSubfilter}
    config[e.target.dataset.filter] = JSON.parse(e.target.dataset.filterby)
    this.setState({selectSubfilter: config}, ()=>this.filterSelectSubfilter())
  }

  filterSelectSubfilter() {
    let selected_workers = {...this.state.selected_worker_hash};
    let already_selected = JSON.parse(getCookie("wms_select"));

    for (const n in this.state.worker_hash) {
      let node = this.state.worker_hash[n];
      if (node.type.endsWith('Forklift')) { continue; }

      delete selected_workers[n];
      delete already_selected[n];
      if (this.state.selectAll[node.type]) {
        if ((!this.state.selectSubfilter.attached && !this.state.selectSubfilter.unattached) ||
            (this.state.selectSubfilter.attached && node.assigned) ||
            (this.state.selectSubfilter.unattached && !node.assigned)){
          selected_workers[n] = node;
          already_selected[n] = true;
        }
      }
    }

    setCookie("wms_select", JSON.stringify(already_selected), 30);
    this.setState({selected_worker_hash: selected_workers});
  }

  onSelect(e) {
    let all_workers = {...this.state.worker_hash};
    let workers = {...this.state.selected_worker_hash};
    //let select_all = {...this.state.selectAll};
    let already_selected = JSON.parse(getCookie("wms_select"));

    if (e.target.checked) { //add
      workers[e.target.dataset.node] = all_workers[e.target.dataset.node];
      already_selected[e.target.dataset.node] = true;
    } else { //delete
      setCookie("wms_select_all_" + e.target.dataset.type, false, 30);
      delete workers[e.target.dataset.node];
      delete already_selected[e.target.dataset.node];
      //select_all[e.target.dataset.type] = false;
    }

    setCookie("wms_select", JSON.stringify(already_selected), 30);
    this.setState({/*selectAll: select_all,*/ selected_worker_hash: workers});
  }

  setView(e) {
    this.setState({view: e.target.dataset.view});
  }

  setMapType(e) {
    if (e.type === 'click') {
      this.setState({maptype: e.target.dataset.maptype});
    } else {
      this.setState({maptype: 'racks'});
    }
  }

  onSetShowNode(e) {
    //console.log(e.target.dataset.node)
    let time = new Date().getTime().toString();
    this.setState({showNode: e.target.dataset.node + "-" + time});
  }

  onSetFloor(e) {
    let floors = { ...this.state.floors };
    let display = floors[e.target.dataset.source];
    floors[e.target.dataset.source] = !display;

    if (e.target.dataset.source === 'All') {
      this.state.map_config.map_order.forEach(m => {floors[m] = !display} )
    } else {
      floors['All'] = false;
    }

    let height = 0; //do height correction
    this.state.map_config.map_order.forEach(id => {
      if (floors[id]) {
       height += this.state.map_config.maps[id].small.height > 700 ? this.state.map_config.maps[id].small.height : 700;
      }
    })

    this.setState({floors: floors, height: height})
  }

  render() {
    let map = [];
    for (const id of this.state.map_config.map_order) {
      if (!this.state.floors[id]) { continue; }

      const m = this.state.map_config.maps[id];
      if (this.state.view === 'map' && this.state.maptype === 'heatmap') {
        map.push(
          <div key={id}>
            <WmsHeatmap
              key={this.props.siteId + "_wms_heatmap" + id}
              siteId={this.props.siteId}
              nodes={Object.keys(this.state.selected_worker_hash)}
              background_src={m.background_src}
              width={m.small.width}
              height={m.small.height}
              pixels_per_meter={m.small.pixels_per_meter}
              origin={m.origin}
              time_intervals={this.state.time_intervals}
              duration={this.state.duration}
              floor={id}
              racks={this.state.gids.rack_config.racks === undefined ? RACKS[this.props.siteId] : this.state.gids.rack_config.racks}
              coordinates={this.state.gids.rack_coordinates === undefined ? COORDINATES[this.props.siteId] : this.state.gids.rack_coordinates}
            />
          </div>
        )
      } else if (this.state.view === 'map' && this.state.maptype === 'path') {
        map.push(
          <div key={id}>
            <WmsPathmap
              key={this.props.siteId + "_wms_pathmap" + id}
              siteId={this.props.siteId}
              nodes={Object.keys(this.state.selected_worker_hash)}
              background_src={m.background_src}
              width={m.small.width}
              height={m.small.height}
              pixels_per_meter={m.small.pixels_per_meter}
              origin={m.origin}
              time_intervals={this.state.time_intervals}
              showNode={this.state.showNode}
              floor={id}
              gids={this.state.gids.worker_ids}
              oobs={this.state.oobs.hasOwnProperty(id) ? this.state.oobs[id] : {}}
            />
          </div>
        )
      } else if (this.state.view === 'map' && this.state.maptype === 'racks') {
        map.push(
          <div key={id}>
            <WmsRacks //need floor
              key={this.props.site_id + "_wms_racks" + id}
              siteId={this.props.siteId}
              background_src={m.background_src}
              width={m.small.width}
              height={m.small.height}
              pixels_per_meter={m.small.pixels_per_meter}
              floor={id}
              picking_origin={this.state.gids.rack_config.picking_origin_meters}
              coordinates={this.state.gids.rack_coordinates === undefined ? COORDINATES[this.props.siteId] : this.state.gids.rack_coordinates }
            />
          </div>
        )
      }
    }

    return (
    <div className="Container2D" style={{minHeight: 755, height: this.state.view === 'map' ? this.state.height : Object.keys(this.state.selected_worker_hash).length > 0 ?  1900 : 1235}}>
      <WMSList
        key={this.props.siteId + "_wms_list"}
        siteId={this.props.siteId}
        worker_hash={Object.values(this.state.worker_hash)}
        selected_worker_hash={this.state.selected_worker_hash}
        selectAll={this.state.selectAll}
        selectSubfilter={this.state.selectSubfilter}
        view={this.state.view}
        onSelect={this.onSelect}
        onSelectAll={this.onSelectAll}
        onSelectSubfilter={this.onSelectSubfilter}
        setView={this.setView}
        onSetShowNode={this.onSetShowNode}
        groups={this.state.gids.groups}
        default_list_selection={this.state.gids.default_list_selection}
      />
      <div className="Container2DRight">
        <div className="loading">{this.state.loading}</div>
        <WMSControl
          key={this.props.siteId + "_wms_control"}
          siteId={this.props.siteId}
          time_intervals={this.state.time_intervals}
          setTimeIntervals={this.setTimeIntervals}
          nodes={Object.keys(this.state.selected_worker_hash)}
          view={this.state.view}
          maptype={this.state.maptype}
          setMapType={this.setMapType}
          displayUpload={checkSiteACL(this.props.site.customer, this.props.site.site, this.props.acl, 'WMS Upload Button')}
          displayDownload={checkSiteACL(this.props.site.customer, this.props.site.site, this.props.acl, 'WMS Download Button')}
        />
        { this.state.view === 'map' &&
          <NavFloorControl
              floor_order={WAREHOUSE[this.props.siteId].map_order}
              floors={this.state.floors}
              onSetFloor={this.onSetFloor}
          />
        }
        {this.state.view === 'chart' &&
          <WMSCharts
            key={this.props.site_id + "_wms_chart"}
            nodes={Object.values(this.state.selected_worker_hash).sort((a,b) => (a.order - b.order)).map(n => n.id)}
            worker_hash={this.state.selected_worker_hash}
            time_intervals={this.state.time_intervals}
            duration={this.state.duration}
            displayDownload={checkSiteACL(this.props.site.customer, this.props.site.site, this.props.acl, 'WMS Download Button')}
            picking_origin={this.state.gids.rack_config.picking_origin_meters}
            racks={this.state.gids.rack_config.racks === undefined ? RACKS[this.props.siteId] : this.state.gids.rack_config.racks}
            coordinates={this.state.gids.rack_coordinates === undefined ? COORDINATES[this.props.siteId] : this.state.gids.rack_coordinates}
          />
        }
        <div>{map}</div>
      </div>
    </div>
    )
  }
}

export default connect(
  state => ({
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
    site: state.sites.currentSite,
    acl: state.user.acl,
  }),
)(WMSPageMultMaps);
