import React from 'react';

const InfoContainer = (props) => {
  let info = []
  for (const i in props.info) {
    info.push(
      <div key={i}>
        {props.help}
        {i}
        <div className="sysid">
          {props.info[i]}
        </div>
      </div>)
  }

  return (
    <div className="info_container">
    {info}
    </div>
  )
}

export default InfoContainer;

//props.info = {"key": value}
