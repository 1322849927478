import React from 'react';
import { Stage, Layer, Group, Rect, Text, Line} from 'react-konva';
import ToolTip from '../../common/ToolTip';

class TimeIntervalChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltip_props: {id:null, props:null, x:null, y:null},
      tooltip_show: false,
    };

    this.makeToolTip = this.makeToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.stageRef = React.createRef();
    this.nodesRef = React.createRef();
  }

  componentWillUnmount() {
    let nodes = this.nodesRef.current;
    nodes.destroyChildren();
  }

  makeToolTip(e) {
    let tooltip = {
      id: e.target.attrs.tooltip.id,
      x: this.stageRef.current.getPointerPosition().x,
      y: this.stageRef.current.getPointerPosition().y,
      props: e.target.attrs.tooltip.props,
    }

    this.setState({tooltip_props: tooltip, tooltip_show: true});
  }

  closeToolTip(e) {
    this.setState({tooltip_show: false});
  }

  render() {
    let length = 0; //find label maxlength
    this.props.labels.forEach(l => {
      if (l.length > length) {
        length = l.length
      }
    })

    let label_width = (length + 2) * 7;
    let chart_width = 960 - label_width;

    let bars = []
    this.props.data.forEach((tracker, index1) => {
      tracker.forEach((interval, index2) => {
        if (interval.hasOwnProperty('tooltip')) {
          bars.push(
            <Rect key={"bar " + index1 + " " + index2} x={label_width + (interval['start'] * chart_width)} y={4 + (25 * index1)} width={interval['duration'] * chart_width} height={16} fill={interval['color']} tooltip={interval['tooltip']} onMouseOver={this.makeToolTip} onMouseOut={this.closeToolTip}/>
        )} else {
          bars.push(<Rect key={"bar " + index1 + " " + index2} x={label_width + (interval['start'] * chart_width)} y={4 + (25 * index1)} width={interval['duration'] * chart_width} height={16} fill={interval['color']} />);
        }
      })
    })

    return (
      <div style={{marginTop: "35px"}}>
        <Stage ref={this.stageRef} width={970} height={(this.props.labels.length * 25) + 40}>
          <Layer>
            <Group ref={this.nodesRef}>
              <Rect x={0} y={0} width={960} height={190} fill={'white'}/>

              <Line points={[label_width, 0, label_width, (this.props.labels.length * 25) + 10]} strokeWidth={.5} stroke={'#979797'} />
              <Line points={[label_width,(this.props.labels.length * 25), 960, (this.props.labels.length * 25)]} strokeWidth={.5} stroke={'#979797'} />

              {this.props.labels.map((value, index) => //horizontal line for labels
                <Line key={"hLine" + index} points={[label_width - 10, 25 * (index + 1), 960, 25 * (index + 1)]} strokeWidth={.5} stroke={'#979797'} />
              )}

              {[1,2,3,4,5,6,7,8,9,10].map(n =>
                <Line key={"vLine" + n} points={[label_width + (chart_width/10 * n),0, label_width + (chart_width/10 * n), this.props.labels.length * 25 + 10]} strokeWidth={.5} stroke={'#979797'} />

              )}

              {this.props.labels.map((value, index) => { //label name
                  return <Text key={"label" + index}  y={10 + (25 * index)} width={label_width - 10} align={"right"} text={value} fontSize={12} fontFamiy={'Lato'} fill={' #2d2d2d'} />
                }
              )}

              {this.props.y_axis.map((value, index) => //y label
                <Text key={"y_label" + index} x={label_width - 20 + (chart_width/10 * index)} y={this.props.labels.length * 25 + 15} text={value} fontSize={12} fontFamiy={'Lato'} fill={'#2d2d2d'} />
              )}

              {bars}
            </Group>
          </Layer>
          <ToolTip tooltip_props={this.state.tooltip_props} tooltip_show={this.state.tooltip_show}/>
        </Stage>
      </div>
    )
  }
}

export default TimeIntervalChart;
