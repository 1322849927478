import React, {useState} from 'react';
//import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { checkSiteACL } from '../../acl/Config';

function isSiteActive(match, location) {
  if (!match) {
    return location.pathname === '/dashboard/settings';
  } return match;
}

const SettingsSidebar = ({site, acl}) => {
  const [show, setShow] = useState(true);
  const [showConfig, setShowConfig] = useState(true);
  //const [siteId, setSiteId] = useSelector(state => state.sites.currentSite.site);

  const toggleShow = () => {
    let temp = show;
    setShow(!temp);
  }

  const toggleShowConfig = () => {
    let temp = showConfig;
    setShowConfig(!temp);
  }

  return (
    <div className="sidebar">
      <h2>SETTINGS</h2>
      <ul>
        <li><NavLink to="/dashboard/settings/site" isActive={isSiteActive}>GENERAL</NavLink></li>
        <li><h4>DEVICES<div className={show? "fa fa-chevron-down chevron": "fa fa-chevron-up chevron"} onClick={()=>toggleShow()}/></h4></li>
        <ul style={{display: show? "block": "none"}}>
          <li><NavLink to="/dashboard/settings/hubs">HUBS</NavLink></li>
          <li><NavLink to="/dashboard/settings/devices/anchor">ANCHORS</NavLink></li>
          <li><NavLink to="/dashboard/settings/devices/tracker">TRACKERS</NavLink></li>
        </ul>
        <li><NavLink to="/dashboard/settings/smartlps/config">ZONES</NavLink></li>
        {
          checkSiteACL(site.customer, site.site, acl, 'HW Config Menu') &&
            <div>
            <li><h4>CONFIGS<div className={showConfig? "fa fa-chevron-down chevron": "fa fa-chevron-up chevron"} onClick={()=>toggleShowConfig()}/></h4></li>
            <ul style={{display: showConfig? "block": "none"}}>
              <li><NavLink to="/dashboard/settings/devices/anchor_config">ANCHORS</NavLink></li>
              <li><NavLink to="/dashboard/settings/devices/tracker_config">TRACKERS</NavLink></li>
            </ul>
            </div>
        }
      </ul>
    </div>
  )
};

export default SettingsSidebar;

/*
return (
  <div className="sidebar">
    <h2>SETTINGS</h2>
    <ul>
      <li><NavLink to="/dashboard/settings/site" isActive={isSiteActive}>GENERAL</NavLink></li>
      <li><h4>DEVICES<div className={show? "fa fa-chevron-down chevron": "fa fa-chevron-up chevron"} onClick={()=>toggleShow()}/></h4></li>
      <ul style={{display: show? "block": "none"}}>
        <li><NavLink to="/dashboard/settings/hubs">HUBS</NavLink></li>
        <li><NavLink to="/dashboard/settings/devices/camera">VISION SENSORS</NavLink></li>
        <li><NavLink to="/dashboard/settings/devices/anchor">ANCHORS</NavLink></li>
        <li><NavLink to="/dashboard/settings/devices/tracker">TRACKERS</NavLink></li>
      </ul>
      <li><NavLink to="/dashboard/settings/constellations">CONSTELLATIONS</NavLink></li>
      <li><NavLink to="/dashboard/settings/smartdock">SMARTDOCK</NavLink></li>
      <li><NavLink to="/dashboard/settings/smartlps/config">SMARTLPS</NavLink></li>
      {checkSiteACL(site.customer, site.site, acl, 'Workerlog Link') &&
        <ul>
          <li><NavLink to="/dashboard/settings/smartlps/workers">WORKER LOG</NavLink></li>
          <li><NavLink to="/dashboard/settings/smartlps/gids">WORKERS</NavLink></li>
        </ul>
      }
    </ul>
  </div>
)
*/
