import React from 'react';
// import ReactDOM from 'react-dom';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import 'fixed-data-table-2/dist/fixed-data-table.min.css';
import store, { history } from './store';
import './index.css';
// import './css/skeleton.css';
// import './css/normalize.css';
import './css/Login.css';
import './css/DashboardMenu.css';
import './css/DashboardContent.css';
import './css/Header.css';
import './css/NodeOverlay.css';
import App from './App.jsx';
import registerServiceWorker from './registerServiceWorker';
import {IntlProvider} from "react-intl";
import Japanese from './lang/jp.json';
import English from './lang/en.json';

const locale = navigator.language;
let lang = English;
if (locale ==="ja") {
   lang = Japanese;
}

console.log("locale - " + locale);

render(
  <Provider store={store}>
    <IntlProvider locale={locale} messages={lang}>
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
        </ConnectedRouter>
      </IntlProvider>
  </Provider>,

  document.getElementById('root'),
);
registerServiceWorker();
