import React from 'react';
import { Table, Column, Cell} from 'fixed-data-table-2';
import { IMG_CONFIG } from '../2dConfig';
import Sort from '../../common/Sort';

const LIMIT = 6;

class Racks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      sortedColumnIndex: 0,
    };

    this.setSortedData = this.setSortedData.bind(this);
  }

  componentDidMount() {
    this.makeTableData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.rack_stats) !== JSON.stringify(this.props.rack_stats) ||
        prevProps.expand !== this.props.expand)
    {
      this.makeTableData();
    }
  }

  makeTableData() {
    let data = [];
    Object.keys(this.props.rack_stats).forEach(r => {
      let d = {rack: null, visit_count: 0, quantity: 0, distance: null};
      d['rack'] = r;
      if (this.props.rack_stats && this.props.rack_stats.hasOwnProperty(r)) {
        let stats = this.props.rack_stats[r];
        d['visit_count'] = stats.assignments;
        d['quantity'] = stats.items;

        //calculate distance of rack from ticket location
        let distance = null;
        let floor = null;
        for (const f in this.props.racks) {
          if (this.props.racks[f].hasOwnProperty(r)) {
            floor = f;
            break;
          }
        }

        if (floor !== null) {
          if (this.props.coordinates[floor][this.props.racks[floor][r]]) {
            //look up for parent
            let x = this.props.coordinates[floor][this.props.racks[floor][r]].x;
            let y = this.props.coordinates[floor][this.props.racks[floor][r]].y;
            distance = (Math.abs(x - this.props.origin[floor].x) + Math.abs(y - this.props.origin[floor].y)).toFixed(2);
          } else {
            distance = "-"
          }
        }

        d['distance'] = distance;
      }

      data.push(d);
    })

    data = data.sort((a,b) => b.visit_count - a.visit_count);
    if (!this.props.expand) {data = data.slice(0,LIMIT)}
    this.setState({tableData: data});
  }

  setSortedData(columnIndex, sortedData) {
    this.setState({sortedColumnIndex: columnIndex, tableData: sortedData});
  }

  render() {
    return (
      <div className="SiteChart">
      {this.props.expand ?
        <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
        <img className="ExpandIcon" alt="expand" data-chart="racks" src={require("../img/expand.png")} onClick={this.props.setChartType} />
      }
        <div className="SiteHeader">PICKING ANALYSIS
        {
          this.props.displayDownload &&
          <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="picking" data-type="" onClick={this.props.setDownloadType}/>
        }
        </div>
        <div id="picking" className="SiteChartContainer">
          <Table className="MetricTable"
            rowHeight={35}
            headerHeight={35}
            rowsCount={Object.keys(this.state.tableData).length}
            width={625}
            height={Object.keys(this.state.tableData).length * 35 + 40} //210
          >
            <Column columnKey='rack'
                    header={<Cell className="MetricHeader">LOCATION<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].rack ? this.state.tableData[props.rowIndex].rack : "-"}
                      </Cell>
                    )}
                    width={135}
            />
            <Column columnKey='visit_count'
                    header={<Cell className="MetricHeader">VISIT COUNT<Sort thisColumnIndex={1} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].visit_count ? this.state.tableData[props.rowIndex].visit_count : "-"}
                      </Cell>
                    )}
                    width={135}
            />
            <Column columnKey='quantity'
                    header={<Cell className="MetricHeader">QUANTITY<Sort thisColumnIndex={2} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].quantity ? this.state.tableData[props.rowIndex].quantity : "-"}
                      </Cell>
                    )}
                    width={135}
            />
            <Column columnKey='distance'
                    header={<Cell className="MetricHeader">ESTIMATED RACK DISTANCE (m)<Sort thisColumnIndex={3} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].distance ? this.state.tableData[props.rowIndex].distance : "-"}
                      </Cell>
                    )}
                    width={220}
            />
          </Table>
        </div>
      </div>
    )
  }
}

export default Racks;
