import React, { Component } from 'react';
import merge from 'lodash/merge';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import NodeFormInfo from './NodeFormInfo';
import NodeFormConfigs from './NodeFormConfigs';
import { updateNode, deleteNode } from '../../modules/nodes';
import { getConstellationsForTable } from '../../modules/constellations';
import './MediaButton.css';
import CameraSetting from '../../media/CameraSetting.svg';

const EditNode = ({
  handleOpenModal,
  handleCloseModal,
  handleSubmit,
  handleDelete,
  updateField,
  updateConfigField,
  showModal,
  name,
  configs,
  hardware_id,
  constellation,
  constellations,
  pending,
  errors,
  node,
  disabled,
  displayname
}) => (
  <div>
    { displayname == null  ? <img src={CameraSetting} alt="" onClick={handleOpenModal}/>: <a onClick={handleOpenModal}>{displayname}</a>}
    <Modal isOpen={showModal} onRequestClose={handleCloseModal} ariaHideApp={false}>
      <h3>Edit Node</h3>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            NAME
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => { updateField('name', e.target.value); }}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="system_id">
            SYSTEM ID
            <input
              id="system_id"
              type="string"
              value={node.node}
              disabled
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="version">
            VERSION
            <input
              id="version"
              type="string"
              value={node.events ? node.events.system.firmware_version.split(".").slice(0,4).join('.') : node.version.split(".").slice(0,4).join('.')}
              disabled
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="hardware_id">
            HARDWARE ID
            <span>{errors.hardware_id}</span>
            <input
              id="hardware_id"
              type="string"
              value={hardware_id}
              disabled
              //should never change the hardward id
              //onChange={(e) => { updateField('hardware_id', e.target.value); }}
              //required
            />
          </label>
        </div>
        <NodeFormInfo node={node.node} />
        <div className="form-group">
          <label htmlFor="constellation">
            CONSTELLATION ID
            <select id="constellation" value={constellation} onChange={(e) => { updateField('constellation', e.target.value); }}>
              <option value=''>None</option>
              {
                constellations.map(c => (
                  <option value={c.constellation} key={c.constellation}>{c.name}</option>
                ))
              }
            </select>
          </label>
        </div>
        <NodeFormConfigs
          camera={configs.camera}
          motion={configs.motion}
          editCamera={(val, attr) => updateConfigField('camera', val, attr)}
          editMotion={(val, attr) => updateConfigField('motion', val, attr)}
          disabled={pending}
        />
        <div className="button-group">
          <button
            type="button"
            onClick={handleCloseModal}
            className="cancel-button"
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={pending}
          >
            SAVE
          </button>
          <button
            onClick={handleDelete}
            disabled={pending}
            className="delete-button left"
          >
            DELETE
          </button>
        </div>
      </form>
    </Modal>
  </div>
);

EditNode.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  updateConfigField: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  hardware_id: PropTypes.string.isRequired,
  // constellation: PropTypes.string.isRequired,
  constellations: PropTypes.array,
  errors: PropTypes.shape({ hardware_id: PropTypes.string }).isRequired,
  //displayname: PropTypes.string.isRequired,
};

EditNode.defaultProps = {
  constellations: [],
};

class NodeEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      name: this.props.node.name,
      hardware_id: this.props.node.hardware_id,
      constellation: this.props.node.constellation,
      errors: {
        hardware_id: '',
      },
      configs: {
        camera: {
          auto_capture_period_minutes: this.props.node.configs.camera.auto_capture_period_minutes,
          auto_capture_media_format: this.props.node.configs.camera.auto_capture_media_format,
          alarm_media_format: this.props.node.configs.camera.alarm_media_format,
          ambient_light_threshold: this.props.node.configs.camera.ambient_light_threshold,
          ir_led_brightness: this.props.node.configs.camera.ir_led_brightness,
        },
        motion: {
          motion_sensitivity: this.props.node.configs.motion.motion_sensitivity,
        }
      },
      pending: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateConfigField = this.updateConfigField.bind(this);
  }

  /* I dont' want to update the screen while it's in edit mode
  componentWillReceiveProps(nextProps) {
    this.setState({
      name: nextProps.node.name,
      hardware_id: nextProps.node.hardware_id,
      constellation: nextProps.node.constellation,
      configs: {
        camera: {
          auto_capture_period_minutes: nextProps.node.configs.camera.auto_capture_period_minutes,
          auto_capture_media_format: nextProps.node.configs.camera.auto_capture_media_format,
          alarm_media_format: nextProps.node.configs.camera.alarm_media_format,
          ambient_light_threshold: nextProps.node.configs.camera.ambient_light_threshold,
          ir_led_brightness: nextProps.node.configs.camera.ir_led_brightness,
        },
        motion: {
          motion_sensitivity: nextProps.node.configs.motion.motion_sensitivity,
        }
      }
    });
  }
  */

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.validateFields()) {
      this.handleCloseModal();
      this.props.dispatch(updateNode(this.props.node.node, {
        node: {
          configs: this.state.configs,
          name: this.state.name,
          //updating hardward id is rejected by api
          //hardware_id: this.state.hardware_id,
          constellation: this.state.constellation,
        }
      }));
    } else {
      this.setState({ errors: { hardware_id: 'requires a 12 digit hex number' } });
    }

    // this.setState({ pending: true });
  }

  handleDelete(event) {
    event.preventDefault();
    this.handleCloseModal();
    this.props.dispatch(deleteNode(this.props.node.node));
  }

  validateFields() {
    return this.state.hardware_id.length === 16 && /^[A-Fa-f0-9]+$/.test(this.state.hardware_id);
  }

  updateField(name, value) {
    this.setState({ [name]: value });
  }

  updateConfigField(name, value, attr) {
    this.setState({
      configs: merge(
        {},
        this.state.configs,
        { [name]: { [attr]: value } },
      ),
    });
  }

  render() {
    return (
      <EditNode
        node={this.props.node}
        handleOpenModal={this.handleOpenModal}
        handleCloseModal={this.handleCloseModal}
        handleSubmit={this.handleSubmit}
        handleDelete={this.handleDelete}
        updateField={this.updateField}
        updateConfigField={this.updateConfigField}
        showModal={this.state.showModal}
        configs={this.state.configs}
        name={this.state.name}
        hardware_id={this.state.hardware_id}
        constellation={this.state.constellation}
        constellations={this.props.constellations}
        pending={this.state.pending}
        errors={this.state.errors}
        disabled={this.props.status === 'pending'}
        displayname={this.props.displayname}
      />
    );
  }
}

export default connect(state => ({
  constellations: getConstellationsForTable(state),
  nodes: state.nodes,
  pendingNodes: state.pendingNodes,
}))(NodeEditContainer);
