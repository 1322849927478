import { connect } from 'react-redux';

import { triggerCamera } from '../../modules/nodes';
import TakeVideoBtn from '../buttons/TakeVideoBtn';

export default connect(
  null,
  (dispatch, ownProps) => ({ triggerCamera: (e) => {
    e.preventDefault();
    dispatch(triggerCamera(ownProps.id, 'video'));
  }})
)(TakeVideoBtn);
