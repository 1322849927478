import React from 'react';
import { connect } from 'react-redux';
import TakePictureBtn from '../buttons/TakePictureBtn';
import TakeVideoBtn from '../buttons/TakeVideoBtn';

import { triggerCamera } from '../../modules/constellations';

const ConstellationButtons = ({
  videoTrigger,
  pictureTrigger,
}) => (
  <div className="constellation-btns">
    <TakeVideoBtn triggerCamera={videoTrigger} />
    <TakePictureBtn triggerCamera={pictureTrigger} />
  </div>
)

export default connect(
  null,
  (dispatch, ownProps) => ({
    videoTrigger: () => dispatch(triggerCamera(ownProps.id, 'video')),
    pictureTrigger: () => dispatch(triggerCamera(ownProps.id, 'image')),
  }),
)(ConstellationButtons);
