import React from 'react';
import { connect } from 'react-redux';
import { getNodesArray } from '../../modules/nodes';
import { fetchObjectHistory } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import TruckCameraEvent from './TruckCameraEvent';
import AnalyticsTable from './AnalyticsTable';
import AnalyticsChart from './AnalyticsChart';
import './AnalyticsPage.css';
/*
{{api}}/objects/{{node}}/history?event=analytics:trucks
fetchObjectHistory(objectId, authToken, siteId, params = {})

NODE 273c069d54d544b5bcaff8b894472ee7
<Timestamp millis={node.events ? node.events.camera.media_ready.timestamp : node.update.events.camera.media_ready.timestamp}
*/

class AnalyticsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = ({
      nodeHistory: [],
      bay_1_last: null,
      bay_2_last: null,
      bay_1_last_interval: null,
      bay_2_last_interval: null,
      duration_array1: [],
      duration_array2: [],
      bay_1_min: null,
      bay_1_max: null,
      bay_1_avg: null,
      bay_1_total: null,
      bay_2_min: null,
      bay_2_max: null,
      bay_2_avg: null,
      bay_2_total: null,
      bay_pic_url: "",
      bay_1_percent_occupied: null,
      bay_2_percent_occupied: null,
      bay_1_time_unoccupied: null,
      bay_2_time_unoccupied: null,
      bay_1_raw_avg: null,
      bay_2_raw_avg: null,
      bay_1_warning: false,
      bay_2_warning:false,
    })

    this.fetchTruckHistory = this.fetchTruckHistory.bind(this);
    this.calLastStateChange = this.calLastStateChange.bind(this);
    this.calInterval = this.calInterval.bind(this);
    this.calMinMaxAvg = this.calMinMaxAvg.bind(this);
    this.convertTime = this.convertTime.bind(this);
    this.setBayPic = this.setBayPic.bind(this);
    this.getLabels = this.getLabels.bind(this);
  }

  //let limit = Math.floor(Math.random() * 1000) + 1;
  componentDidMount() {
    this.fetchTruckHistory();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodes !== this.props.nodes) {
      if (this.props.nodes.length > 0)  {
        //console.log("component updated");
        this.fetchTruckHistory();
      }
    }
  }

  /*fetchObjectHistory("273c069d54d544b5bcaff8b894472ee7", this.props.authToken, this.props.currentSiteId, {event: "analytics:trucks", limit: 250 })
    last:28800000
    from: 1546761600000, to:1546934400000
    let limit = Math.floor(Math.random() * 1000) + 1;
  */
  fetchTruckHistory() {
    if (!this.props.nodes[0]) { return }
    fetchObjectHistory(this.props.nodes[0].node, this.props.authToken, this.props.currentSiteId, {event: "analytics:trucks", last:28800000})
      .then(json => this.setState({nodeHistory: json.history.sort((a, b ) => b.timestamp - a.timestamp)}))
      .then(()=>this.setBayPic())
      .then(()=>this.calInterval())
      .then(()=>this.calLastStateChange());
    return;
  }

  setBayPic() {
    let current = this.state.nodeHistory[0];
    if (!current) { return; }
    if (current.update.events.analytics.trucks.bay_0 === 1 && current.update.events.analytics.trucks.bay_1 === 1) {
      this.setState({bay_pic_url: require("./Full_Dock.jpg")});
    } else if (current.update.events.analytics.trucks.bay_0 === 1 && current.update.events.analytics.trucks.bay_1 === 0) {
      this.setState({bay_pic_url: require("./LH_Bay_only.jpg")});
    } else if (current.update.events.analytics.trucks.bay_0 === 0 && current.update.events.analytics.trucks.bay_1 === 1) {
      this.setState({bay_pic_url: require("./RH_Bay_only.jpg")});
    } else if (current.update.events.analytics.trucks.bay_0 === 0 && current.update.events.analytics.trucks.bay_1 === 0) {
      this.setState({bay_pic_url: require("./Empty_Dock.jpg")});
    }
  }

  calLastStateChange() {
    let current = this.state.nodeHistory[0];
    let found = this.state.nodeHistory.find(n => n.update.events.analytics.trucks.bay_0 !== current.update.events.analytics.trucks.bay_0);
    let found2 = this.state.nodeHistory.find(n => n.update.events.analytics.trucks.bay_1 !== current.update.events.analytics.trucks.bay_1);

    //warn if time last change is greater than AVERAGE
    let warning = false;
    if (found) {
      if (current.update.events.analytics.trucks.bay_0 === 1 && ((current.timestamp - found.timestamp) > this.state.bay_1_raw_avg)) { warning = true; }
      this.setState ({ bay_1_warning: warning, bay_1_last: (new Date(found.timestamp).toLocaleString()), bay_1_last_interval: this.convertTime(current.timestamp - found.timestamp)});
    } else {
      this.setState ({ bay_1_warning: warning, bay_1_last: null, bay_1_last_interval: null});
    }

    warning = false;
    if (found2) {
      if (current.update.events.analytics.trucks.bay_1 === 1 && ((current.timestamp - found2.timestamp) > this.state.bay_2_raw_avg)) { warning = true; }
      this.setState({ bay_2_warning: warning, bay_2_last: (new Date(found2.timestamp).toLocaleString()), bay_2_last_interval: this.convertTime(current.timestamp - found2.timestamp)});
    } else {
      this.setState ({ bay_2_warning: warning, bay_2_last: null, bay_2_last_interval: null});
    }
  }

  calInterval() {
    let start1 = null;
    let start2 = null;
    let durationArray1 = [];
    let durationArray2 = [];
    let index = null;
    let index2 = null;

    //do bay 1
    for (index of this.state.nodeHistory) {
      if (start1 === null && index.update.events.analytics.trucks.bay_0 === 0) { continue; }
      if (start1 === null && index.update.events.analytics.trucks.bay_0 === 1) { //found 1, set start
        start1 = index.timestamp;
        continue;
      }
      if (start1 !== null && index.update.events.analytics.trucks.bay_0 === 0) {
        durationArray1.push([start1, index.timestamp]);
        start1 = null;
      }
    }
    if (start1 !== null) {
      durationArray1.push([start1, this.state.nodeHistory[this.state.nodeHistory.length - 1].timestamp]);
    }

    //do bay 2
    for (index2 of this.state.nodeHistory) {
      if (start2 === null && index2.update.events.analytics.trucks.bay_1 === 0) { continue; }

      if (start2 === null && index2.update.events.analytics.trucks.bay_1 === 1) { //found 1, set start
        start2 = index2.timestamp;
        continue;
      }

      if (start2 !== null && index2.update.events.analytics.trucks.bay_1 === 0) {
        durationArray2.push([start2, index2.timestamp]);
        start2 = null;
      }
    }
    if (start2 !== null) {
      durationArray2.push([start2, this.state.nodeHistory[this.state.nodeHistory.length - 1].timestamp]);
    }

    this.setState({duration_array1: durationArray1, duration_array2: durationArray2});
    let result1 = this.calMinMaxAvg(durationArray1);
    let result2 = this.calMinMaxAvg(durationArray2);

    this.setState (
      {
        bay_1_min: this.convertTime(result1[0]), bay_1_max: this.convertTime(result1[1]), bay_1_avg: this.convertTime(result1[2]), bay_1_total: this.convertTime(result1[3]),
        bay_1_percent_occupied: result1[4], bay_1_time_unoccupied: this.convertTime(result1[5]), bay_1_raw_avg: result1[2],
        bay_2_min: this.convertTime(result2[0]), bay_2_max: this.convertTime(result2[1]), bay_2_avg: this.convertTime(result2[2]), bay_2_total: this.convertTime(result2[3]),
        bay_2_percent_occupied: result2[4], bay_2_time_unoccupied: this.convertTime(result2[5]), bay_2_raw_avg: result2[2],
      }
    )
  }

  calMinMaxAvg(durationArray) {
    let min = 0;
    let max = 0;
    let avg = 0;
    let total_stay = 0;

    for (let i of durationArray) {
      let diff = i[0] - i[1];
      if (diff < min || min === 0 ) { min = diff; }
      if (diff > max || max === 0 ) { max = diff; }
      avg += diff;
      total_stay += diff;
    }
    avg = avg / durationArray.length;
    let percent_occupied = Math.round((total_stay/28800000) * 100);
    let time_unoccupied = 28800000 - total_stay;
    return [min, max, avg, total_stay, percent_occupied, time_unoccupied];
  }

  //day, hour, min, sec
  convertTime(mill) {
    //let days = null;
    let hours = null;
    let mins = null;
    //let secs = null;

    //day`
    if (mill > 86400000) {
      //days = Math.floor(mill/86400000);
      mill = mill % 86400000;
    }

    if (mill > 3600000) {
      hours = Math.floor(mill/3600000);
      mill = mill % 3600000;
    }

    if (mill > 60000) {
      //mins = Math.floor(mill/60000);
      mins = Math.round(mill/60000);
      //mill = mill % 60000;
    }

    //if (mill > 1000) {
    //  secs = Math.floor(mill/1000);
    //}

    //lets format the string now
    let strr = [];
    if (hours != null ) {
      if (hours < 10) {
        strr.push("0" + hours);
      } else {
        strr.push(hours);
      }
    } else {
      strr.push("00");
    }

    if (mins != null ) {
      if (mins < 10) {
        strr.push("0" + mins);
      } else {
        strr.push(mins)
      }
    } else {
      strr.push("00");
    }

    /*if (secs != null ) {
      if (secs < 10) {
        strr.push("0" + secs);
      } else {
        strr.push(secs)
      }
    } else {
      strr.push("00");
    }*/

    return strr.join(":");
  }

  getLabels() {
    //return this.state.nodeHistory.map((n)=>new Date(n.timestamp).toLocaleString().split(/,/)[1]);
    return this.state.nodeHistory.map((n)=>new Date(n.timestamp));
  }

  render() {
    if (this.props.nodes.length > 0 && this.props.nodes[0].events && this.props.nodes[0].events.camera) {
      return(
        <div className="content-row">
          <div className="dashboard-content analytics-page">
            <div className="asset-tracking-header">SMART DOCK</div>
            <div className="row1">
              <div className="item1"><div className="crop-left"><TruckCameraEvent node={this.props.nodes[0]} /></div>
              <div className="truck-overlay">
                <div className="truck-overlay-text">BAY 1</div>
                <div className="truck-overlay-text">{(this.props.nodes[0].events && this.props.nodes[0].events.camera) ? new Date(this.props.nodes[0].events.camera.media_ready.timestamp).toLocaleString() : ""}</div>
              </div>
              </div>

              <div className="item2"><AnalyticsTable node={this.props.nodes[0]} bay="1" last_state={this.state.bay_1_last} last_state_interval={this.state.bay_1_last_interval}
                                                                min={this.state.bay_1_min} max={this.state.bay_1_max} avg={this.state.bay_1_avg} total={this.state.bay_1_total}
                                                                percent_occupied={this.state.bay_1_percent_occupied} time_unoccupied = {this.state.bay_1_time_unoccupied}
                                                                warning={this.state.bay_1_warning}
                                                                />
              </div>

              <div className="item3"><div className="crop-right"><TruckCameraEvent node={this.props.nodes[0]} /></div>
                <div className="truck-overlay">
                  <div className="truck-overlay-text">BAY 2</div>
                  <div className="truck-overlay-text">{(this.props.nodes[0].events && this.props.nodes[0].events.camera) ? new Date(this.props.nodes[0].events.camera.media_ready.timestamp).toLocaleString() : ""}</div>
                </div>
              </div>
              <div className="item4"><AnalyticsTable node={this.props.nodes[0]} bay="2" last_state={this.state.bay_2_last} last_state_interval={this.state.bay_2_last_interval}
                                                                min={this.state.bay_2_min} max={this.state.bay_2_max} avg={this.state.bay_2_avg} total={this.state.bay_2_total}
                                                                percent_occupied={this.state.bay_2_percent_occupied} time_unoccupied = {this.state.bay_2_time_unoccupied}
                                                                warning={this.state.bay_2_warning}
                                                                />
              </div>
            </div>

            <div className="row2">
               <div className="item5"><AnalyticsChart bay="Bay 1" labels={this.getLabels()} data={this.state.nodeHistory.map((n) => n.update.events.analytics.trucks.bay_0)}/></div>
               <div className="item6"><AnalyticsChart bay="Bay 2" labels={this.getLabels()} data={this.state.nodeHistory.map((n) => n.update.events.analytics.trucks.bay_1)}/></div>
            </div>

            <div className="row3">
            <div className="item7"><img src={this.state.bay_pic_url} alt="Loading..."/></div>
            </div>
          </div>
        </div>
      );
    } else {
      return(<div></div>);
    }
  }
}

export default connect(
  state => ({ nodes: getNodesArray(state),
              authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(AnalyticsPage)
