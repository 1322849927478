import React, { useState, useEffect } from 'react';
import { makeDate, calBizType, calBizFromTo } from './Utility';
import Calendar from '../common/Calendar';
import Download from './Download';
import { checkSiteACL } from '../acl/Config';
import { setCookie, getCookie } from '../common/Util';

const selected_text_style = {color: "#1b5faa"};

const calSelectedText = () => {
  let r = "";
  let day = getCookie('smartdock_select')
  if (day !== "") {
    r = calBizType(day);
  }
  return r;
}

const SmartDockInterval = ({start, end, setFromTo, docks, history, last_occupancy, site, acl}) => {
  const[from, setFrom] = useState(start);
  const[to, setTo] = useState(end);
  const[interval, setInterval] = useState(end - start);
  const[dateText, setDateText] = useState(makeDate(start, end));
  const[selectedText, setSelectedText] = useState(calSelectedText());
  const[showCalendar, setShowCalendar] = useState(false);
  const[switchMenu, setSwitchMenu] = useState(false);

  /*useEffect(()=> {
    console.log("use effect")
    setFromTo(from, to);
  }, [from, to])*/

  useEffect(()=> {
    console.log("use effect")
    setFrom(start);
    setTo(end);
    setInterval(end - start);
    setDateText(makeDate(start, end));
    setSelectedText(calSelectedText());
  }, [start, end])

  const selectTimeRange = (e) => {
    let tempTo = Date.now();
    let tempFrom = null;
    switch(e.target.dataset.days) {
      case 'back':
        tempFrom = from - interval;
        tempTo = to - interval;
        setCookie("smartdock_select", "", 30);
        break;
      case 'forward':
        tempFrom = from + interval;
        tempTo = to + interval;
        setCookie("smartdock_select", "", 30);
        break;
      case '60':
        let fromTo = calBizFromTo(Date.now(), 'LAST MONTH');
        tempFrom = fromTo[0];
        tempTo = fromTo[1];
        setCookie("smartdock_select", 60, 30);
        break;
      case '30':
        fromTo = calBizFromTo(Date.now(), 'THIS MONTH');
        tempFrom = fromTo[0];
        tempTo = fromTo[1];
        setCookie("smartdock_select", 30, 30);
        break;
      case '14':
        fromTo = calBizFromTo(Date.now(), 'LAST WEEK');
        tempFrom = fromTo[0];
        tempTo = fromTo[1];
        setCookie("smartdock_select", 14, 30);
        break;
      case '7':
        fromTo = calBizFromTo(Date.now(), 'THIS WEEK');
        tempFrom = fromTo[0];
        tempTo = fromTo[1];
        setCookie("smartdock_select", 7, 30);
        break;
      case '2':
        fromTo = calBizFromTo(Date.now(), 'YESTERDAY');
        tempFrom = fromTo[0];
        tempTo = fromTo[1];
        setCookie("smartdock_select", 2, 30);
        break;
      default: //1 day
        fromTo = calBizFromTo(Date.now(), 'TODAY');
        tempFrom = fromTo[0];
        tempTo = fromTo[1];
        setCookie("smartdock_select", 1, 30);
    }

    setFrom(tempFrom);
    setTo(tempTo);
    setFromTo(tempFrom, tempTo);
  };

  const toggle_display = () => {
    setSwitchMenu(!switchMenu);
  }

  const toggle_calendar = () => {
    setShowCalendar(!showCalendar)
  };

  const setCalendarDate = (start, end) => {
    setCookie('smartdock_select', "", 30);
    setFrom(start);
    setTo(end);
    setFromTo(start, end);
    //setDateText(makeDate(start, end));
    //setSelectedText("");
    //setInterval(end - start);
    toggle_calendar();
  };

  return (
    <div className="DockInterval">
      <div className="DockIntervalContainer">
          { checkSiteACL(site.customer, site.site, acl, 'SmartDock Download Button') &&
              <Download from={start} to={end} docks={docks} history={history} last_occupancy={last_occupancy} />
          }
          <div className="DockIntervalDateContainer">
            <i className="fa fa-caret-left Left" data-days="back" onClick={selectTimeRange}/>
            <div className="DockDate" onClick={toggle_calendar}>{dateText}</div>
              {
                (to + interval) < Date.now() &&
                  <i className="fa fa-caret-right Right" data-days="forward" onClick={selectTimeRange}/>
              }
            <div className="CannedSelect" style={{display: !switchMenu && "none"}}>
              <i className="fa fa-caret-left Left" onClick={toggle_display}/>
              <div className="Days" data-days="2"  style={selectedText === 'YESTERDAY' ? selected_text_style : {}} onClick={selectTimeRange}>
                YESTERDAY
              </div>
              <div className="Days" data-days="14" style={selectedText === 'LAST WEEK' ? selected_text_style : {}} onClick={selectTimeRange}>
                LAST WEEK
              </div>
              <div className="Days" data-days="60" style={selectedText === 'LAST MONTH' ? selected_text_style : {}} onClick={selectTimeRange}>
                LAST MONTH
              </div>
            </div>
            <div className="CannedSelect" style={{display: switchMenu && "none"}}>
              <div className="Days" data-days="1"  style={selectedText === 'TODAY' ? selected_text_style : {}} onClick={selectTimeRange}>
                TODAY
              </div>
              <div className="Days" data-days="7" style={selectedText === 'THIS WEEK' ? selected_text_style : {}} onClick={selectTimeRange}>
                WEEK
              </div>
              <div className="Days" data-days="30" style={selectedText === 'THIS MONTH' ? selected_text_style : {}} onClick={selectTimeRange}>
                MONTH
              </div>
              <i className="fa fa-caret-right Left" onClick={toggle_display}/>
            </div>
            <Calendar from={from} to={to} show={showCalendar} setShow={toggle_calendar} onSetCalendarDate={setCalendarDate}/>
          </div>
      </div>
    </div>
  )
}

export default SmartDockInterval;

/*
<div className="Days" data-days="30" style={selectedText === '1 MONTH' ? selected_text_style : {}} onClick={selectTimeRange}>
  1 MONTH
</div>
<div className="Days" data-days="7" style={selectedText === '1 WEEK' ? selected_text_style : {}} onClick={selectTimeRange}>
  1 WEEK
</div>
<div className="Days" data-days="3" style={selectedText === '3 DAYS' ? selected_text_style : {}} onClick={selectTimeRange}>
  3 DAYS
</div>
<div className="Days" data-days="1"  style={selectedText === '1 DAY' ? selected_text_style : {}} onClick={selectTimeRange}>
  1 DAY
</div>

const selectTimeRange = (e) => {
  let tempTo = Date.now();
  let tempFrom = null;
  switch(e.target.dataset.days) {
    case 'back':
      tempFrom = from - interval;
      tempTo = to - interval;
      setCookie("smartdock_select", "", 30);
      break;
    case 'forward':
      tempFrom = from + interval;
      tempTo = to + interval;
      setCookie("smartdock_select", "", 30);
      break;
    case '3':
      tempFrom = tempTo - (86400000 * 3);
      setCookie("smartdock_select", 3, 30);
      break;
    case '7':
      tempFrom = tempTo - (86400000 * 7);
      setCookie("smartdock_select", 7, 30);
      break;
    case '30':
      //let d = new Date(tempTo);
      //let days = new Date(d.getFullYear(), d.getMonth(), 0).getDate();

      tempFrom = tempTo - (86400000 * 30);
      setCookie("smartdock_select",30, 30);
      break;
    default: //1 day
      tempFrom = tempTo - (86400000 * 1);
      setCookie("smartdock_select", 1, 30);
      break;
  }

  setFrom(tempFrom);
  setTo(tempTo);
  setFromTo(tempFrom, tempTo);
};

const calSelectedText = (from, to) => {
  let r = "";
  switch(to - from) {
    case(86400000 * 3):
      r = "3 DAYS";
      break;
    case(86400000 * 7):
      r = "1 WEEK";
      break;
    case(86400000 * 30):
      r = "1 MONTH";
      break;
    case(86400000):
      r =  "1 DAY";
      break;
    default:
      r = "";
  }
  return r;
}
*/
