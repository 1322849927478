/* vim: set fileencoding=utf-8 */

import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';
import { getUserData } from '../LocixSession.js';

import { selectSite, fetchSites, getSitesArray, fetchCustomers } from '../modules/sites';
import { logout } from '../modules/users';

function compareSiteTuple(a, b) {
    if (a.site_name.toUpperCase() < b.site_name.toUpperCase()) {
        return -1;
    }
    if (a.site_name.toUpperCase() > b.site_name.toUpperCase()) {
        return 1;
    }
    return 0;
}

class SiteSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: null,
            isExpanded: false,
            siteList: [],
            filteredSiteList: [],
            currentSiteName: "",
        };

        this.onClick = this.onClick.bind(this);
        this.onSiteClick = this.onSiteClick.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    /*defaultProps = {
        session: null,
        onSiteIdChange: function(session) {
            console.warning('No onSiteIdChange property', session);
        },
        onLogout: function() {
            console.warning('No onLogout property');
        },
    }*/

    onError(error) {
        console.error('NodeEventFrameContainer error', error);
    }

    // TODO QUESTION: No, really, there's got to be a better way than the next
    // three methods!

    componentDidMount() {
      this.props.fetchCustomers();
      this.props.fetchSites();
      // this.onNewSession(this.props.session);
    }

    componentDidUpdate(prevProps) {
    //     if (nextProps.session !== this.props.session) {
    //         this.onNewSession(nextProps.session);
    //     }
      if (this.props.sites.length !== this.state.siteList.length || prevProps.currentSiteId !== this.props.currentSiteId) {
        //console.log("component update");
        let siteList;
        let currentSiteName = "";

        if (!this.props.sites) {
          siteList = null;
        }
        else {
            let siteEntries = this.props.sites;
            siteEntries.sort(compareSiteTuple);
            siteList = siteEntries.map(entry => {
              let { site, site_name } = entry
              // let [siteId, siteDatum] = siteTuple;
              let className = classNames({
                  'selected': this.props.currentSiteId === site
              });

              if (this.props.currentSiteId === site) { currentSiteName = site_name; }

              return <li className={className}
                      data-site-id={site}
                      key={site}
                      onClick={this.onSiteClick}
                      >
                  {site_name}
              </li>
            });

            this.setState({siteList: [...siteList], filteredSiteList: [...siteList], currentSiteName: currentSiteName});
        }
      }
    }

    onClick(e) {
        this.setState(prevState => {
            return { isExpanded: !prevState.isExpanded };
        });
    }

    onBlur(e) {
      e.stopPropagation()
      this.setState({ isExpanded: false });
    }

    onSiteClick(e) {
        let newSiteId = e.target.getAttribute('data-site-id');
        this.props.selectSite(newSiteId);
        // this.props.onSiteIdChange(newSiteId);
    }

    onNewSession(session) {
        getUserData()
            .then(userData => {
                this.setState({
                    userData: userData,
                });
            })
            .catch(error => {
                this.onError(error);
            });
    }

    handleChange(e) {
       e.stopPropagation();
       if (e.target.value.length === 0) {
         this.setState({filteredSiteList: [...this.state.siteList]});
         return;
       }

       let re = new RegExp(e.target.value, "i");
       let found = this.state.siteList.filter((n) => n.props.children.match(re));
       this.setState({filteredSiteList: [...found]});
     }

    render() {
        // let username = this.state.userData ? this.state.userData.username :
        //         'Loading...';

        let username = this.props.userName || 'Loading...';
        let dropDown = null;
        if (this.state.isExpanded) {
            dropDown = <ul className="drop-down">
              <li
                className="logout"
                key="logout"
              >
                <NavLink to="/dashboard/management/myprofile">
                  <i className="fa fa-user-circle userName"></i>
                  <div className="userName">{username}</div>
                </NavLink>
                <div className="signout" onClick={this.props.onLogout}>
                  Sign Out
                  <i className="fa fa-sign-out"></i>
                </div>
              </li>
              <input id="search-site" type="text"
                     size="25"
                     placeholder="SEARCH SITE"
                     style={{display: this.props.sites.length >= 8 ? '' : 'None'}}
                     onClick={(e)=> e.stopPropagation()}
                     onChange={(e)=>this.handleChange(e)}
              />
              {this.state.filteredSiteList}
            </ul>
        }

        let nameTitle = !this.state.userData || !this.state.userData.siteData ?
            null :
            this.state.userData.siteData[this.state.userData.currentSiteId].site_name;
        return (
            //don't display with user management
            !this.props.path.startsWith('/dashboard/management/') && 
            <div className='site-selector' onClick={this.onClick} >
              <div title={nameTitle} className='user-name'>{this.state.currentSiteName}</div>
              {dropDown}
              <div
                className="dropdown-overlay"
                onClick={this.onBlur}
                style={{ display: this.state.isExpanded ? '' : 'none' }}
              />
            </div>
        );

    }
}

export default connect(
  state => ({
    currentSiteId: state.sites.currentSite.site,
    userName: state.user.userName,
    sites: getSitesArray(state),
    path: state.routing.location.pathname,
  }),
  dispatch => ({
    onLogout: () => dispatch(logout()),
    selectSite: (id) => dispatch(selectSite(id)),
    fetchSites: () => dispatch(fetchSites()),
    fetchCustomers: () => dispatch(fetchCustomers()),
  }),
)(SiteSelector);
