import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getConstellationsForTable, createConstellation, updateConstellation, updateConstellationNodes, fetchConstellations} from '../../modules/constellations';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';
import {isEmpty} from '../settings/form/ErrorChecking';

const CONFIG = {constellation: "", state: "", nodes: null, validate: true};
const URL = "/dashboard/settings/constellations";
const STATE = ['active', 'inactive'];

const EditConstellationNew = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [constellation, setConstellation] = useState(null);
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [nodes, setNodes] = useState([]);

  useEffect(()=> {
    if (props.match.params.id === id) { return; }

    switch(props.match.params.id) {
      case 'new':
        resetForm(null);
        break;
      default:
        resetForm(props.constellations.find(c => c.constellation === props.match.params.id));
    }
  });

  useEffect(()=> {
    if (nodes.length !== props.nodes.length) {
      resetForm(props.constellations.find(c => c.constellation === props.match.params.id));
    }
  }, [props.nodes]);

  const resetForm = (constellation) => {
    if (constellation) {
      console.log("set editForm " + JSON.stringify(constellation));
      setId(props.match.params.id);
      setEditForm(true);
      setConstellation(constellation);

      //const OPTIONS =  {worker: { value: 'worker', label: 'Worker' }, forklift: {value: 'forklift', label: 'Forklift' }};
      let options = props.nodes.map(n => ({value: n.node, label: n.name}));
      let defaultNodes = [];
      constellation.nodes.forEach(n => {
        let camera = props.nodes.find(camera => camera.node === n);
        defaultNodes.push({value: n, label: camera.name});
      });

      setConfig({
        constellation: constellation.name,
        state: constellation.state,
        nodes: defaultNodes,
      });

      setNodes(options);
    } else {
      console.log("set newForm")
      setId('new');
      setEditForm(false);
      setConstellation(null);
      setConfig(CONFIG);
      setNodes({});
    }
    setError(CONFIG);
  }

  const handleDelete = (e) => {
    setTimeout(()=>props.history.push(URL), 700);
  };

  const validateFields = () => {
    let errorNew = {...CONFIG};
    isEmpty("constellation", config.constellation, errorNew);
    setError(errorNew);
    return errorNew.validate;
  };

  const handleSubmit = (e)=> {
    if (!validateFields()) {
      return;
   }

   console.log("submit");
    let errorNew = {...error}
    errorNew.status = "Saving " + config.constellation;
    setError(errorNew);

    if (editForm) {
      props.dispatch(updateConstellationNodes(config.nodes, constellation.constellation));
      setTimeout(() => {
        props.dispatch(updateConstellation(constellation.constellation, {
          constellation: {
            name: config.constellation,
            state: config.state,
          },
        }))
      }, 500);
    } else {
      props.dispatch(createConstellation({
        constellation: {
          name: config.constellation,
          state: 'inactive',
        }
      }));
    }
    setTimeout(()=>props.dispatch(fetchConstellations()), 700);
    setTimeout(()=>props.history.push(URL), 1000);
  };

  const updateField = (field, e, type) => {
    let configNew = {...config};
    if (type && (type === 'select' || type === 'button_group' || type === 'multiselect')) {
      configNew[field] = e;
    } else {
      configNew[field]  = e.target.value;
    }
    setConfig(configNew);
  }

  return(
    <div className="main edit">
      <Link to={URL}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? `Edit ${constellation && constellation.name}` : "Add Constellation"}</h3>
      {editForm &&
        <HeaderContainer
          node = {constellation && constellation.constellation}
          name = {constellation && constellation.name}
          type = {"constellation"}
          handleDelete={handleDelete}
        />
      }
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        { editForm ?
          <div key="edit">
            <LabelContainer
              labels = {{
                  "Name": {},
                  "State": {"help" : "Motion triggers associated vision sensors to take an image or clip"},
                  "Associated Vision Sensors": {"help" : "Associate vision sensors with constellation"},
                }}
             />
            <FormContainer
              fields = {{
                  "constellation": {"value": config['constellation'] && config.constellation, "placeholder": "", "error": error.constellation},
                  "state" :  {"type": "button_group", "options": STATE, "value": config['state'], "error": error.state},
                  "nodes" : {"type": "multiselect", "options": nodes, "value": config.nodes, "error": error.nodes },
              }}
              updateField={updateField}
            />
            <InfoContainer
              info={{"constellation Id": constellation && constellation.constellation}}
            />
          </div>
          :
          <div key="save">
            <LabelContainer labels= {{"Name": {"help": "", "desc": ""}}}/>
            <FormContainer
              fields = {{"constellation": {"value": config.constellation, "placeholder": "", "error": error.constellation}}}
              updateField={updateField}
            />
          </div>
        }
      </div>
      <div>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (state=>({
    constellations: getConstellationsForTable(state),
    nodes: state.nodes.allIds.map(id => state.nodes.byId[id]).filter(n => n.node_type === 'camera'),
  })
)(EditConstellationNew);
