//Utility.js

const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];
const DWELL_THRESHOLD = 7200000; //2 hour
const GAP_THRESHOLD = 900000; //15 min

//8 dock states - OFFLINE, OFFLINE_DEAD_BATTERY, OCCUPIED_DELAY, OCCUPIED_DELAY_LOW_BATTERY, OCCUPIED_LOW_BATTERY, AVAILABLE_LOW_BATTERY; OCCUPIED, AVAILABLE
export function calDockStatus(dock, ext_camera) {
  let status = "";
  if (ext_camera && !ext_camera.events.system.connection.connected) {
    status = ext_camera.events.battery.voltage <= 2.9 ? "OFFLINE_DEAD_BATTERY" : "OFFLINE";
  } else if (dock.events.dock.occupancy_change.state === 1 && dock.events.dock.occupancy_change.timestamp < (Date.now() - DWELL_THRESHOLD)) {
    status = ext_camera.events.battery.voltage <=  3.0 ? "OCCUPIED_DELAY_LOW_BATTERY" : "OCCUPIED_DELAY"
  } else if (dock.events.dock.occupancy_change.state === 1 && ext_camera.events.battery.voltage <= 3.0) {
    status = "OCCUPIED_LOW_BATTERY";
  } else if (dock.events.dock.occupancy_change.state === 0 && ext_camera.events.battery.voltage <= 3.0) {
    status = "AVAILABLE_LOW_BATTERY";
  } else if (dock.events.dock.occupancy_change.state === 1) {
    status = "OCCUPIED";
  } else {
    status = "AVAILABLE";
  }
  return status;
};

//calendar date string
export function makeDate(from, to) {
  let start = new Date(from)
  let end = new Date(to)
  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  let end_hour = end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
  let end_min = end.getMinutes() < 10 ? "0" +  end.getMinutes() : end.getMinutes()
  let end_date = (end.getMonth() === start.getMonth() && end.getDate() === start.getDate()) ? "" : monthNames[end.getMonth()] + " " + end.getDate() + ", "
  let str = monthNames[start.getMonth()] + " " + start.getDate() + ", " + start_hour + ":" + start_min + " - " +
            end_date + end_hour + ":" + end_min
  return (str)
}

//Make smple date string, Feb 20, 2019
export function makeSimpleDate(mill) {
  let start = new Date(mill)
  return ( monthNames[start.getMonth()] + " " + start.getDate() + ", " + start.getFullYear());
}

//m/d/y
export function makeSimpleDateExport(mill) {
  let start = new Date(mill)
  return ((start.getMonth() + 1) + "/" + start.getDate() + "/" + start.getFullYear())
}

//formatTime return hour:min military time - current time
export function formatTime(mill) {
  let start = new Date(mill);
  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  return start_hour + ":" + start_min
}

//formatTime return hour:min AM/PM - current time
export function formatTime12Hours(mill) {
  let start = new Date(mill);
  let meridiem = "AM";
  let start_hour = start.getHours();

  if (start_hour > 11) { meridiem = "PM" }
  if (start_hour > 12) { start_hour = start_hour - 12 }
  if (start_hour === 0) {start_hour = 12 }
  if (start_hour < 10) { start_hour = "0" + start_hour}

  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  return start_hour + ":" + start_min + " " + meridiem;
}

//format time interval to return hour:min - time elapsed
export function convertTime(mill) {
  let hours = null;
  let mins = null;

  if (mill > 86400000) { mill = mill % 86400000; } // get rid of day

  if (mill > 3600000) { //hour
    hours = Math.floor(mill/3600000);
    mill = mill % 3600000;
  }

  if (mill > 60000) { mins = Math.ceil(mill/60000)}

  let strr = [];
  if (hours != null ) {
    if (hours < 10) {
      strr.push("0" + hours);
    } else {
      strr.push(hours);
    }
  } else {
    strr.push("00");
  }

  if (mins != null ) {
    if (mins < 10) {
      strr.push("0" + mins);
    } else {
      strr.push(mins)
    }
  } else {
    strr.push("00");
  }
  return strr.join(":");
}

//Convert milliseconds to "x hour y min" string
export function convertToHourMin(mill) {
  let hours = 0;
  let mins = 0;

  if (mill > 3600000) { //hour
    hours = Math.floor(mill/3600000);
    mill = mill % 3600000;
  }
  if (mill > 60000) { mins = Math.round(mill/60000)}

  let str = "";
  if (hours > 0) {
    str += hours + "h ";
  }

  str += mins + "m";
  return  str;
};

export function cal_full_int_activity(history, start, end) {
  let result = {
    activity_raw: 0,
    activity_percent: 0,
    duration:end - start,
    arrival_gap_raw: 0,
    arrival_gap_alarm: false,
    departure_gap_raw: 0,
    departure_gap_alarm: false,
    last_activity: 0,
    last_inactivity: 0,
  }

  if (history.length === 0) { return result }

  let activity_arr = [];
  let activity = unique_history_array(history.filter(h => h.update.events.dock.hasOwnProperty('activity_change')));
  activity = activity.filter(h => (h.timestamp >= start && h.timestamp <= end));

  if (activity.length === 0) {return result}
  if (activity[0].update.events.dock.activity_change.state === 1) { //arrival_gap
    result.arrival_gap_raw = activity[0].timestamp - start;
    if (result.arrival_gap_raw >= GAP_THRESHOLD) {
      result.arrival_gap_alarm = true;
    }
  }

  if (activity[activity.length - 1].update.events.dock.activity_change.state === 0) { //departure gap
    result.departure_gap_raw = end - activity[activity.length - 1].timestamp;
    if (result.departure_gap_raw >= GAP_THRESHOLD) {
      result.departure_gap_alarm = true;
    }
  }

  let start_time = start; //calcualte interior activity
  let last_state = null;
  activity.forEach(h => {
      if (h.update.events.dock.activity_change.state === 1) {
        start_time = h.timestamp;
        result.last_activity = h.timestamp;
      }

      if (h.update.events.dock.activity_change.state === 0) {
        result.last_inactivity = h.timestamp;
        activity_arr.push(h.timestamp - start_time);
      }

      last_state = h.update.events.dock.activity_change.state;
  })
  if (last_state === 1) { activity_arr.push(end - start_time) }

  activity_arr.forEach(n => result.activity_raw += n);
  result.activity_percent = Math.round(result.activity_raw/result.duration * 100);

  return result;
}

export function cal_full_occupancy(history, start, end, last_occupancy_history) {
  let result = { "trucks" : 0, "delays": 0, "average": 0, "utilization": 0,
                 "arrival_gap": 0, "departure_gap": 0 };
  let table = [];
  let occupancy_array = [];
  let startTime = start;
  let last_occupancy = null;
  let interior_array = [];
  let sum = 0;

  unique_history_array(history).forEach( h => {
    if (h.update.events.dock.hasOwnProperty('occupancy_change')) {
      if (h.update.events.dock.occupancy_change.state === 1) {
        startTime = h.timestamp;
        interior_array = [];
      }

      if (h.update.events.dock.occupancy_change.state === 0) {
        let interval = h.timestamp - startTime;
        occupancy_array.push(interval);
        let int_result = cal_full_int_activity(interior_array, startTime, h.timestamp);
        if (int_result.arrival_gap_alarm) { result.arrival_gap += 1; }
        if (int_result.departure_gap_alarm) { result.departure_gap += 1; }
        if (interval > DWELL_THRESHOLD ) { result.delays += 1 }

        let row = {};
        row['date'] = startTime === start ? "-" : makeSimpleDate(startTime);
        row['arrival_time'] = startTime === start ? "-" : formatTime12Hours(startTime);
        row['departure_time'] = formatTime12Hours(h.timestamp);
        row['dwell_time'] = convertToHourMin(interval);
        row['int_activity'] = int_result.activity_percent;
        row['int_activity_warn'] = int_result.arrival_gap_alarm || int_result.departure_gap_alarm;
        row['arrival_delay'] = startTime === start ? "-" : convertToHourMin(int_result.arrival_gap_raw);
        row['arrival_delay_warn'] = startTime === start ? false : int_result.arrival_gap_alarm;
        row['departure_delay'] = convertToHourMin(int_result.departure_gap_raw);
        row['departure_delay_warn'] = int_result.departure_gap_alarm;
        row['arrival_time_raw'] = startTime;
        row['departure_time_raw'] = h.timestamp;
        table.push(row);
      }
      last_occupancy = h.update.events.dock.occupancy_change.state;
    } else {
      interior_array.push(h);
    }
  });

  if (last_occupancy === 1) {
    let interval = end - startTime;
    occupancy_array.push(interval);
    let int_result = cal_full_int_activity(interior_array, startTime, end);
    if (int_result.arrival_gap_alarm) { result.arrival_gap += 1; }
    if (int_result.departure_gap_alarm) { result.departure_gap += 1; }
    if (interval > DWELL_THRESHOLD ) { result.delays += 1 }

    let row = {};
    row['date'] = makeSimpleDate(startTime);
    row['arrival_time'] = formatTime12Hours(startTime);
    row['departure_time'] = "-";
    row['dwell_time'] = convertToHourMin(interval);
    row['int_activity'] = int_result.activity_percent;
    row['int_activity_warn'] = int_result.arrival_gap_alarm;
    row['arrival_delay'] = convertToHourMin(int_result.arrival_gap_raw);
    row['arrival_delay_warn'] = int_result.arrival_gap_alarm;
    row['departure_delay'] = "-";
    row['departure_delay_warn'] = false;
    row['arrival_time_raw'] = startTime;
    row['departure_time_raw'] = end;
    table.push(row);
  }

  occupancy_array.forEach(n => {sum = sum + n});
  result.trucks = occupancy_array.length;
  result.average = occupancy_array.length === 0 ? 0 : sum / occupancy_array.length;
  result.utilization = Math.round(sum/(end - start) * 100);

  //no metric change
  if (table.length === 0 && last_occupancy_history && last_occupancy_history[0].update.events.dock.occupancy.state === 1) {
     result['trucks'] = 1;
     result['average'] = end - start;
     result['utilization'] = 100;

     let int_result = cal_full_int_activity(history, start, end);
     let row = {};
     row['date'] = "-";
     row['arrival_time'] = "-";
     row['departure_time'] = "-";
     row['dwell_time'] = convertToHourMin(end - start);
     row['int_activity'] = int_result.activity_percent;
     row['int_activity_warn'] = false;
     row['arrival_delay'] = "-";
     row['arrival_delay_warn'] = false;
     row['departure_delay'] = "-";
     row['departure_delay_warn'] = false;
     row['arrival_time_raw'] = start;
     row['departure_time_raw'] = end;
     table.push(row);
  }

  return [result, table.reverse()];
}

//simple function to eliminate duplicates
export function unique_history_array(hArray) {
    let unique = [];
    let time =  null;
    hArray.forEach(h => {
      if (h.timestamp !== time) { unique.push(h)}
      time = h.timestamp;
    })

    return unique;
}

//for occupancy and activity chart
export function make_occupancy_data(history, start, end, last_occupancy_history) {
  let data = [];

  if (history[0] && history[0].update.events.dock.occupancy_change.state === 0) {
    data.push({"x": start, "y": 1});
  }

  let last = null;
  history.forEach(h => {
    data.push({"x": h.timestamp, "y": h.update.events.dock.occupancy_change.state});
    last = h.update.events.dock.occupancy_change.state;
  });

  if (last === 1) {
    data.push({"x": end, "y": 0});
  }

  if (data.length === 0 && last_occupancy_history && last_occupancy_history[0].update.events.dock.occupancy.state === 1) {
    data.push({"x": start, "y": 1});
    data.push({"x": end, "y": 0});
  }

  return data;
}

export function make_activity_data(history, interval) {
  let bucket = [];
  if (history.length === 0) { return bucket; }

  let sum = 0;
  let normalizer = 1;
  let start = new Date(history[0].timestamp);
  let start_hour = new Date(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours()).getTime();
  let bucket_interval = null;

  switch(interval) { //calculate bucket interval - < 24 hours is 15 min, = 24 hours is 30 min, > 24 hours is 1 hour
    case interval < 86400000:
      bucket_interval = 900000;
      break;
    case interval > 86400000:
      bucket_interval = 3600000;
      break;
    default:
      bucket_interval = 1800000;
      break;
    }

  history.forEach(h => {
    if (h.timestamp > start_hour) {
      bucket.push({'x': start_hour, 'y':  sum});
      sum = 0;
      start_hour = start_hour + bucket_interval;
    }

    sum = sum + h.update.events.dock.activity_change.state;
    if (sum > normalizer) { normalizer = sum }
  })

  bucket.push({'x': history[history.length -1 ].timestamp, 'y':  sum});
  bucket.forEach(b => {
    let val = b.y;
    b.y = (val/normalizer).toFixed(2);
  })

  return bucket;
}

/******************/
//SmartDockPage - stats[dock] = {"dwell": 0, "total_dwell_time": 0, "avg_dwell_raw": 0, "avg_dwell": 0, "utilization": 0};
export function calculate_dwells(history, start, end, last_occupancy_history) {
  let stats = {"dwell": 0, "total_dwell_time": 0, "avg_dwell_raw": 0, "avg_dwell": 0, "utilization": 0};

  let occupancy = history.filter(h => h.update.events.dock.hasOwnProperty('occupancy_change'));
  if (occupancy.length === 0 && last_occupancy_history[0] && last_occupancy_history[0].update.events.dock.occupancy.state === 1) {
    //console.log(last_occupancy_history[0].update.events.dock.occupancy.state)
    stats['dwell'] = 1;
    stats['total_dwell_time'] = end - start;
    stats['avg_dwell_raw'] = end - start;
    stats["avg_dwell"] = convertToHourMin(stats["avg_dwell_raw"]);
    stats["utilization"] = 100;
    return stats
  }

  let dwell_arr = [];
  let start_time = start;
  let last_state = null;
  unique_history_array(occupancy).forEach(h => {
      if (h.update.events.dock.occupancy_change.state === 1) {
        start_time = h.timestamp;
      }

      if (h.update.events.dock.occupancy_change.state === 0) {
        dwell_arr.push(h.timestamp - start_time);
      }

      last_state = h.update.events.dock.occupancy_change.state;
    }
  )

  if (last_state === 1) { dwell_arr.push(end - start_time) }

  stats["dwell"] = dwell_arr.length;
  dwell_arr.forEach(d => stats["total_dwell_time"] += d);
  stats["avg_dwell_raw"] = stats["total_dwell_time"] / dwell_arr.length;
  stats["utilization"] = Math.round(stats["total_dwell_time"]/(end - start) * 100);
  stats["avg_dwell"] = convertToHourMin(stats["avg_dwell_raw"]);

  return stats;
}

/******* File Export ******/
export function cal_export_data(dock_name, history, start, end, last_occupancy_history) {
  //console.log(history.length)
  let table = [];
  let occupancy_array = [];
  let startTime = start;
  let last_occupancy = null;
  let interior_array = [];

  unique_history_array(history).forEach( h => {
    if (h.update.events.dock.hasOwnProperty('occupancy_change')) {
      if (h.update.events.dock.occupancy_change.state === 1) {
        startTime = h.timestamp;
        interior_array = [];
      }

      if (h.update.events.dock.occupancy_change.state === 0) {
        let interval = h.timestamp - startTime;
        occupancy_array.push(interval);
        let int_result = cal_full_int_activity(interior_array, startTime, h.timestamp);

        let row = {};
        row['arrival_date'] = startTime === start ? "-" : makeSimpleDateExport(startTime);
        row['arrival_time'] = startTime === start ? "-" : formatTime12Hours(startTime);
        row['departure_date'] = makeSimpleDateExport(h.timestamp);
        row['departure_time'] = formatTime12Hours(h.timestamp);
        row['dwell_time'] = (interval/3600000).toFixed(2);
        row['int_activity'] = int_result.activity_percent + "%";
        row['arrival_gap'] = startTime === start ? "-" : (int_result.arrival_gap_raw/3600000).toFixed(2);
        row['departure_gap'] = (int_result.departure_gap_raw/3600000).toFixed(2);
        table.push(row);
      }
      last_occupancy = h.update.events.dock.occupancy_change.state;
    } else {
      interior_array.push(h);
    }
  });

  if (last_occupancy === 1) {
    let interval = end - startTime;
    occupancy_array.push(interval);
    let int_result = cal_full_int_activity(interior_array, startTime, end);
    let row = {};
    row['arrival_date'] = makeSimpleDateExport(startTime);
    row['arrival_time'] = formatTime12Hours(startTime);
    row['departure_date'] = "-";
    row['departure_time'] = "-";
    row['dwell_time'] =(interval/3600000).toFixed(2);
    row['int_activity'] = int_result.activity_percent + "%";
    row['arrival_gap'] = (int_result.arrival_gap_raw/3600000).toFixed(2);
    row['departure_gap'] = "-";
    table.push(row);
  }

  //empty history occupancy
  if (table.length === 0 && last_occupancy_history[0].update.events.dock.occupancy.state === 1) {
    //console.log(history.length)
    let int_result = cal_full_int_activity(history, start, end);
    let row = {};
    row['arrival_date'] = "-";
    row['arrival_time'] =  "-";
    row['departure_date'] =  "-";
    row['departure_time'] =  "-";
    row['dwell_time'] = ((end - start)/3600000).toFixed(2);
    row['int_activity'] = int_result.activity_percent + "%";
    row['arrival_gap'] = "-";
    row['departure_gap'] = "-";
    table.push(row);
  }

  let text = "";
  table.forEach(r => {
    text += dock_name + "," + r['arrival_date'] + "," + r['arrival_time'] + "," +
          r['departure_date'] + "," + r['departure_time'] + "," +
          r['dwell_time'] + "," + r['int_activity'] + "," +
          r['arrival_gap'] + "," + r['departure_gap'] + "\n"
   })

  return text;
}

export function calBizType(days) {
  switch(parseInt(days)) {
    case 1:
      return 'TODAY';
    case 2:
      return 'YESTERDAY';
    case 7:
      return 'THIS WEEK';
    case 14:
      return 'LAST WEEK';
    case 30:
      return 'THIS MONTH';
    case 60:
      return 'LAST MONTH';
    default:
      return '';
  }
}

//to is milliseconds
//type is today, this week, last week, this month, last month
//uses business hours midnight to midnight
export function calBizFromTo(to, type) {
  let from = new Date(to);
  let finalTo = new Date(to);
  let day = finalTo.getDay();

  switch (type) {
    case 'TODAY':
      from.setHours(0,0,0,0)
      finalTo.setHours(24,0,0,0);
      break;
    case 'YESTERDAY':
      from.setHours( -24, 0,0,0);
      finalTo.setHours(0,0,0,0);
      break;
    case 'THIS WEEK':
      //set from to Sun 12AM
      // sun = 0, mon = 1, tue = 2, wed = 3, thur = 4, fri = 5, sat = 6
      from.setHours( -24 * day, 0,0,0)
      finalTo.setHours(24 * (7 - day),0,0,0);
    break;
    case 'LAST WEEK':
      from.setHours( -24 * (7 + day), 0,0,0)
      finalTo.setHours( -24 * day, 0,0,0)
      break;
    case 'THIS MONTH':
      from.setDate(1);
      from.setHours(0,0,0,0);
      finalTo.setMonth(finalTo.getMonth() + 1, 1);
      finalTo.setHours(0,0,0,0);
      break;
    case 'LAST MONTH':
      from.setMonth(from.getMonth() - 1, 1);
      from.setHours(0,0,0,0);
      finalTo.setMonth(finalTo.getMonth() - 1, 31);
      finalTo.setHours(0,0,0,0);
      break;
    default:
      //from.setHours(0,0,0,0)
  }
  console.log(type);
  console.log(from);
  console.log(finalTo);

  return [from.getTime(), finalTo.getTime()]
}
