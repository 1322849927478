import React from 'react';

const selectedStyle = {
  backgroundColor: "#164c88",
  color: "#ffffff",
};

const IMG = {
  'image' : <i className="fa fa-camera icon" />,
  'video' : <i className="fa fa-video-camera icon" />
}

const ButtonGroup = (props) => {
  let buttons = props.options.map(button =>
    props.value === button ?
              <button key={button} style={selectedStyle}>{IMG[button]}{button}</button> :
              <button key={button} onClick={()=>props.updateField(props.field, button, 'button_group')}>{IMG[button]}{button}</button>
    )

  return (
    <div id="button_group">
    {buttons}
    </div>
  )
}

export default ButtonGroup;

/*
<ButtonGroup
 options = {props.fields[f].options} ['one', 'two' 'three']
 value = {props.fields[f].value}
 field={f}
 updateField={props.updateField}
/>
*/
