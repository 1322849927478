import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Sort from '../common/Sort';
import Filter from '../common/Filter';
import WarnModal from '../common/WarnModal';
import Select from '../common/Select';

const GIDsTable = ({
  origData,
  data,
  error,
  setTableData,
  sortedColumnIndex,
  setSortedData,
  GIDTypes,
  deleteGID,
  updateGIDStatus
}) => (
  <div className="main">
    <h3>WORKERS</h3>
    <div className="button-container"><Link to={`/dashboard/settings/smartlps/edit_gid/new`}>ADD NEW</Link></div>
    <div className="line"/>
    <div className="edit">
      <Filter data={origData} setNewData={setTableData} />
      <Table
        rowsCount={data.length}
        rowHeight={55}
        headerHeight={35}
        width={1075}
        height={500}
      >
        <Column
          header={<Cell>GID<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
          cell={props => (
            <Cell {...props}>
              <Link to={`/dashboard/settings/smartlps/edit_gid/${data[props.rowIndex].id}`}>{data[props.rowIndex].id}</Link>
            </Cell>
          )}
          width={375}
        />
        <Column
         header={<Cell>NAME<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
         cell={props => (
           <Cell {...props}>
           {data[props.rowIndex].name}
         </Cell>
         )}
         width={300}
       />
         <Column
          header={<Cell>GROUP<Sort thisColumnIndex={2} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
          cell={props => (
            <Cell {...props}>
              <Select
                name={"Group"}
                options={GIDTypes.map(g => { return {"value": g, "label": g}})}
                value={data[props.rowIndex].type}
                warn={`Are you sure you want to change ${data[props.rowIndex].name}?`}
                action={(value)=>updateGIDStatus(data[props.rowIndex].id, value)}
              />
            </Cell>
          )}
          width={300}
        />
        <Column
          header={<Cell></Cell>}
          cell={props => (
            <Cell {...props}>
            <
              WarnModal
              //button="delete"
              html=<i className="fa fa-trash trash"></i>
              warn={`Are you sure you want to remove ${data[props.rowIndex].name}?`}
              action={()=>deleteGID(data[props.rowIndex].id)}
            />
            </Cell>
          )}
          width={75}
        />
      </Table>
    </div>
  </div>
);

export default GIDsTable;

/*
<Column
  header={<Cell></Cell>}
  cell={props => (
    <Cell {...props}>
      {data[props.rowIndex].active_employee ?
        <
          WarnModal
          html=<i className="fa fa-toggle-off toggle"></i>
          warn={`Are you sure you want to deactivate ${data[props.rowIndex].name}?`}
          action={()=>updateGID(data[props.rowIndex].id, !data[props.rowIndex].active_employee) }
        />
        :
        <
          WarnModal
          html=<i className="fa fa-toggle-on toggle"></i>
          warn={`Are you sure you want to activate ${data[props.rowIndex].name}?`}
          action={()=>updateGID(data[props.rowIndex].id, !data[props.rowIndex].active_employee)}
        />
    }
    </Cell>
  )}
  width={40}
/>
*/
