import React from 'react';
import { connect } from 'react-redux';
import GIDsTable from './GIDsTable';

import { fetchSiteDetailJson, fetchSiteJson } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';

class GIDsTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: {},
      gids: {},
      origData: [],
      tableData: [],
      sortedColumnIndex: 0,
      loading: "",
    };

    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
    this.deleteGID = this.deleteGID.bind(this);
    this.updateGIDStatus = this.updateGIDStatus.bind(this);
  }

  componentDidMount() {
    this.setState({loading: "Loading workers... Please wait..."}, ()=>this.fetchGIDs());
  }

  componentDidUpdate(prevProps) {
    if (this.props.siteId !== prevProps.siteId) {
      this.setState({loading: "Loading workers... Please wait...",  origData: [], tableData: [], gids: {}, groups: {}}, ()=>this.fetchGIDs());
    }
  }

  fetchGIDs() {
    fetchSiteDetailJson(this.props.authToken, this.props.siteId)
    .then(json => {
      //console.log(JSON.stringify(json.state.configs.wms))
      if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('wms')) {
        let data = Object.values(json.state.configs.wms.worker_ids);
        this.setState({groups: json.state.configs.wms.groups, gids: json.state.configs.wms.worker_ids, origData: data, tableData: data});
      }
    })
    .then(()=> this.setState({loading: ""}))
    .catch(err => this.setState({loading: "Worker query failed"}))
  }

  updateGIDStatus(id, status) {
    console.log("Update GID " + id + " - " + status);

    let path = 'sites/' + this.props.siteId;
    let body = {site: {configs: {wms: {worker_ids: {[id]: {type: status}}}}}}
    let init = {
     method: "put",
     body: JSON.stringify(body)
   }

   fetchSiteJson(path, this.props.authToken, this.props.siteId, init)
    .then(()=> {
      let gids = {...this.state.gids};
      gids[id].type = status;

      let origData = [...this.state.origData];
      origData.forEach( d => {
        if (d.id === id) {
          d.type = status;
        }
      })

      let tableData = [...this.state.tableData];
      tableData.forEach( d => {
        if (d.id === id) {
          d.type = status;
        }
      })

      this.setState({gids: gids, tableData: tableData,  origData: origData});
    })
    .catch(error => {
      console.log(error)
      this.setState({loading: "Worker update failed. Error - " + error.status})
    })
  }


  deleteGID(id) {
    console.log("DEL GID " + id)

    let path = 'sites/' + this.props.siteId;
    let body = {site: {configs: {wms: {worker_ids: {[id]: null}}}}};
    let init = {
     method: "put",
     body: JSON.stringify(body)
   }

   fetchSiteJson(path, this.props.authToken, this.props.siteId, init)
   .then(() => {
     let gids = {...this.state.gids};
     delete gids[id];

     let origData = [...this.state.origData];
     origData = origData.filter(d => d.id !== id);

     let tableData = [...this.state.tableData];
     tableData = tableData.filter(d => d.id !== id);

     this.setState({gids: gids, tableData: tableData,  origData: origData});
   })
   .catch(error => {
     console.log(error)
     this.setState({loading: "Worker delete failed. Error - " + error.status})
   })
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  render() {
    return (
      <div>
        <div className="loading">{this.state.loading}</div>
        <GIDsTable
          origData={this.state.origData}
          data={this.state.tableData}
          error={this.props.error}
          setTableData={this.setTableData}
          sortedColumnIndex={this.state.sortedColumnIndex}
          setSortedData={this.setSortedData}
          GIDTypes={Object.values(this.state.groups).flat()}
          deleteGID={this.deleteGID}
          updateGIDStatus={this.updateGIDStatus}
        />
      </div>
    )
  }
}

export default connect(
  state => ({
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  })
)(GIDsTableContainer);
