import React from 'react';
import { connect } from 'react-redux';
import { getNodeFormInfo } from '../../modules/nodes';

const NodeFormInfo = ({
  rssi,
  voltage,
  temp,
  humidity,
  status,
}) => (
  <div>
    <div className="form-group">
      <label>RSSI <span className="input">{rssi} db</span></label>
    </div>
    <div className="form-group">
      <label>Battery Voltage <span className="input">{voltage} v</span></label>
    </div>
    <div className="form-group">
      <label>Temperature <span className="input">{temp}°</span></label>
    </div>
    <div className="form-group">
      <label>Humidity <span className="input">{humidity}%</span></label>
    </div>
    <div className="form-group">
      <label>Status <span className="input">{status}</span></label>
    </div>
  </div>
);

// NodeFormInfo.defaultProps = {
//   rssi: '',
//   voltage: '',
//   temp: 'N/A',
//   humidity: 'N/A',
//   status: '',
// };

export default connect((state, ownProps) => getNodeFormInfo(state, ownProps.node))(NodeFormInfo);
