import React from 'react';
import { Link } from 'react-router-dom';
import './Lapis.css';

import { fetchLapisData } from '../../modules/api';
import LapisSdTable from './LapisSdTable';
import LapisSdMap from './LapisSdMap';

class LapisSdPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table_data: [],
      gps_data: {},
      data_type: "trips", //"trackers"
      last_update: "",
      selected_tracker: null,
    };

    this.selectTracker = this.selectTracker.bind(this);
    this.selectDataType = this.selectDataType.bind(this);
  }

  componentDidMount() {
    this.fetchTrackerData();
    setInterval(()=>this.fetchTrackerData(),15000); //15sec
  }

  componentDidUpdate(prevProps) {
    //console.log("HERE")
  }

/*
trackers
[
    {
        "_id": "b725dc41-3abf-4dc4-9d65-627c427f7cd8",
        "site_id": "b4d8242e-a850-45df-8b2b-84a55e00497b",
        "serial_number": "123",
        "act_state": "activated",
        "sf_id": "E312C7",
        "sf_pac": "F5F2133D0B8883BD"
    },
    {
        "_id": "11012414-343a-4b9c-b1ca-fcf79bd35164",
        "site_id": "b4d8242e-a850-45df-8b2b-84a55e00497b",
        "serial_number": "E312C9",
        "act_state": "activated",
        "sf_id": "E312C9",
        "sf_pac": "18F372C35468B01A",
        "name": "E312C9",
        "trip_id": "ae2d89ff-fa71-4a87-b4a6-06e0ebf9b279",
        "last_location": {
            "latitude": 35.672965007977986,
            "longitude": 139.7330967847931
        }
    }
]
*/
  fetchTrackerData() {
    fetchLapisData(this.state.data_type)
    .then(json => {
      //console.log("DATA - " + JSON.stringify(json))

      let data = {};
      let gps = {}; //{name: []}

      if (this.state.data_type === 'trips') {
        json.forEach(truck => {
          if (!truck.hasOwnProperty('truck')) { return; }

          data[truck.truck] = {
            truck: truck.truck,
            name: "",
            driver: truck.driver,
            time: (new Date(truck.std).toLocaleString()),
            lat: truck.coordinates[0],
            long: truck.coordinates[1],
            customer: truck.customer
          };

          gps[truck.truck] = {lat : truck.coordinates[0], long : truck.coordinates[1]};
        })
      }

      if (this.state.data_type === 'trackers') {
        json.forEach(tracker => {
          if (!tracker.hasOwnProperty('last_location')) { return; }
          data[tracker._id] = {
            truck: tracker._id,
            name: tracker.sf_id,
            driver: "",
            time: "",
            lat: tracker.last_location.latitude,
            long: tracker.last_location.longitude,
            customer: ""
          };

          gps[tracker._id] = {lat: tracker.last_location.latitude, long: tracker.last_location.longitude};
        })
      }

      this.setState({last_update: (new Date()).toLocaleString(), table_data: Object.values(data), gps_data: gps});
    })
  }

  selectTracker(id) {
    console.log(id)
    if (id === this.state.selected_tracker) {
      this.setState({selected_tracker: null});
    } else {
      this.setState({selected_tracker: id});
    }
  }

  selectDataType(e) {
    //console.log(e.target.dataset.type)
    this.setState({data_type: e.target.dataset.type, selected_tracker: null}, ()=>this.fetchTrackerData())
  }

// <Link to={`/dashboard/settings/devices/edit_node/${data[props.rowIndex].node}`}>
  render() {
    return (
      <div className="lapis-container">
        <div>
          <div className="lapis-header">Smart Truck Monitoring</div>
          <div className="lapis-update">Updated {this.state.last_update}</div>
          <div className="lapis-type">
            <span style={{fontWeight: this.state.data_type === "trips" ? "bold" : ""}} data-type="trips" onClick={this.selectDataType}>Trip </span>
            |
            <span style={{fontWeight: this.state.data_type === "trackers" ? "bold" : ""}} data-type="trackers" onClick={this.selectDataType}> Tracker</span>
          </div>
          <div className="lapis-settings"><Link to="/dashboard/lapis/setting" className="lapis-link">+ Add New</Link></div>
        </div>
        <div className="grid-container">
          <div className="grid-item card1">
          <LapisSdMap
            key="LapisSdMap"
            truck={this.state.selected_tracker}
            gps={this.state.gps_data}
          />
          </div>
          <div className="grid-item card2">
            <LapisSdTable
              key="LapisSdTable"
              type={this.state.data_type}
              rows={this.state.table_data}
              selected={this.state.selected_tracker}
              onSelectTracker={this.selectTracker}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default LapisSdPage;
