import React  from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
//import ConstellationCreateContainer from '../settings/components/constellation_create_container';
//import EditConstellation from './EditConstellation';
import ConstellationStateButton from './ConstellationStateButton';
import { getConstellationsForTable }  from '../../modules/constellations';
import Sort from '../common/Sort';
import Filter from '../common/Filter';

const LINK = "/dashboard/settings/editconstellation/";

const ConstellationsTable = ({ origData, data, error, sortedColumnIndex, setSortedColumnData, setTableData}) => (
    <div className="main">
      <h3>CONSTELLATIONS</h3>
      <div className="button-container"><Link to={LINK + "new"}>ADD NEW</Link></div>
      <span>{error}</span>
      <div className="line"/>
      <Filter data={origData} setNewData={setTableData} />
      <Table
        rowsCount={data.length}
        rowHeight={45}
        headerHeight={35}
        width={1075}
        height={500}
      >
        <Column
          header={<Cell>NAME<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedColumnData}/></Cell>}
          cell={props => (
            <Cell {...props}>
              <Link to={LINK + data[props.rowIndex].constellation}>{data[props.rowIndex].name}</Link>
            </Cell>
          )}
          width={295}
        />
        <Column
          header={<Cell>CONSTELLATION ID<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} setSortedData={setSortedColumnData} data={data} /></Cell>}
          cell={props => (
            <Cell {...props}>
              {data[props.rowIndex].constellation}
            </Cell>
          )}
          width={400}
        />
        <Column
          header={<Cell># OF DEVICES<Sort thisColumnIndex={3} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} setSortedData={setSortedColumnData} data={data} /></Cell>}
          cell={props => (
            <Cell {...props} style={{ paddingLeft: '1rem' }}>
              {data[props.rowIndex].nodes ? data[props.rowIndex].nodes.length : ''}
            </Cell>
          )}
          width={200}
        />
        <Column
          header={<Cell>STATUS<Sort thisColumnIndex={2} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} setSortedData={setSortedColumnData} data={data} /></Cell>}
          cell={props => (
            <Cell {...props}>
              <ConstellationStateButton
                state={data[props.rowIndex].state}
                id={data[props.rowIndex].constellation}
              />
            </Cell>
          )}
          width={160}
        />
      </Table>
    </div>
);

class ConstellationsTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: this.props.data,
      sortedColumnIndex: 0,
    };

    this.setTableData = this.setTableData.bind(this);
    this.setSortedColumnData= this.setSortedColumnData.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      this.setState({tableData: this.props.data});
    }
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedColumnData(index, data) {
    this.setState({sortedColumnIndex: index, tableData: data});
  }

  render() {
    return (
      <ConstellationsTable
        origData={this.props.data}
        data={this.state.tableData}
        error={this.props.error}
        sortedColumnIndex={this.state.sortedColumnIndex}
        setSortedColumnData={this.setSortedColumnData}
        setTableData={this.setTableData}
        />
  )};
};

export default connect(state => ({
  data: getConstellationsForTable(state),
  error: state.constellations.error,
}))(ConstellationsTableContainer);

/*
<ConstellationCreateContainer />
<EditConstellation constellation={data[props.rowIndex]} />
*/
