import React from 'react';
import { connect } from 'react-redux';

import '../css/mscs.css';
import { WAREHOUSE } from '../2dConfig.js';

import { calBizIntervals } from '../../common/Util';
import { getCurrentSiteId} from '../../../modules/sites';
import { fetchSiteDetailJson, postWmsJsonNew } from '../../../modules/api';

import MscsLiveView from './MscsLiveView';
import MscsHeatmap from './MscsHeatmap';
import MscsTopRacks from './MscsTopRacks';
import MscsOpMetric from './MscsOpMetric';
import MscsFlMetric from './MscsFlMetric';
import MscsBulletin from './MscsBulletin';
import MscsOpChart from './MscsOpChart';
import MscsFlChart from './MscsFlChart';

const DEFAULT_INT = "LAST WEEK";
//const DEFAULT_INT = "4 WEEKS AGO";
const DEFAULT_WDAYS = ["Monday","Tuesday","Wednesday","Thursday","Friday"];
const DEFAULT_HOURS = [["6:00:00", "23:00:00"],["6:00:00", "23:00:00"],["6:00:00", "23:00:00"],["6:00:00", "23:00:00"],["6:00:00", "23:00:00"]];
const DEFAULT_FL_HOURS = [["9:00:00", "19:00:00"], ["9:00:00", "19:00:00"],["9:00:00", "19:00:00"],["9:00:00", "19:00:00"], ["9:00:00", "19:00:00"]];
const DEFAULT_FL_GIDS = ['1F R55', '1F R58', '1F R59', '1F R60', '1F R61', '1F R62', '1F R63', '1F R64', '1F R65', '2F R02', '2F R03', '2F R04', '2F R57'];
const DEFAULT_LABELS = ["Mon", "Tue", "Wed", "Thur", "Fri"];

const METERS_PER_FOOT = 0.3048;

class MscsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeIntervals: [],
      flTimeIntervals: [],
      loading: "",
      gids: {}, //worker ids
      fids: {}, //forklift ids
      rids: {},
      coordinates: {},
      picking_origin: {},
      total_time: null,
      assets: {},
      racks: {},
      types: {},
      fl_total_time: null,
      fl_assets: {},
      fl_types: {},
      operatorMetrics: { items: 0, distance: 0},
      forkliftMetrics: { activity:0, distance: 0},
      operatorData: [],
      forkliftData: [],
      labels: [],
      size: 'size1', //laptop (size1) = 1280 × 800, laptop2 (size2) = 1400x 800, monitor (size 3) = 1920 x 1080 pixels
    };

    /*const mediaQuery = window.matchMedia('(max-width: 768px)')
    console.log(mediaQuery);
    console.log("width: " + window.innerWidth);
    console.log("height: " + window.innerHeight)

    window.onresize = () => {
      console.log("Window width: " + window.innerWidth);
      console.log("Window height: " + window.innerHeight);

      //console.log("Live width: " + document.getElementById('mscsLiveView').clientWidth);
      //console.log("Live height: " + document.getElementById('mscsLiveView').clientWidth);
    }
    */
  }

  componentDidMount() {
    console.log("mount  - " + this.props.siteId)
    const mediaQuery = window.matchMedia('(min-width: 1400px)')
    if (mediaQuery.matches) { this.setState({size: 'size2'}, ()=>console.log("Size - " + this.state.size))}

    const mediaQuery2 = window.matchMedia('(min-width: 1450px)')
    if (mediaQuery2.matches) { this.setState({size: 'size3'}, ()=>console.log("Size - " + this.state.size))}

    this.setTimeIntervals();
    setInterval(()=>this.setTimeIntervals(), 900000); //15 min
  }

  componentDidUpdate(prevProps) {
    //console.log("HERE")
  }

  setTimeIntervals() {
    console.log("Checking Time ... ")
    let intervals = calBizIntervals(Date.now(), DEFAULT_INT, DEFAULT_WDAYS, DEFAULT_HOURS);
    let flIntervals = calBizIntervals(Date.now(), DEFAULT_INT, DEFAULT_WDAYS, DEFAULT_FL_HOURS);

    let labels = intervals.map((i, index) => {
      let date = new Date(i.from);
      return [(date.getMonth() + 1) + "/" + date.getDate(),  DEFAULT_LABELS[index]];
    })

    if (JSON.stringify(intervals) !== JSON.stringify(this.state.timeIntervals)) {
      console.log("New time detected ... ")
      this.setState({loading: "Loading data ... Please wait ...", timeIntervals: intervals, flTimeIntervals: flIntervals, labels: labels}, ()=>this.queryWMsWorkers());
    }
  }

  queryWMsWorkers() {
    fetchSiteDetailJson(this.props.authToken, this.props.siteId)
    .then(json => {
      let gids = {};
      let fids = {};
      for (const w in json.state.configs.wms.worker_ids) {
        let info = json.state.configs.wms.worker_ids[w];
        if (info.type.endsWith("Worker")) {
          gids[w] = info;
          continue;
        }

        if (info.type.endsWith("Forklift") && DEFAULT_FL_GIDS.indexOf(info.name) > 0) {
          fids[w] = info;
        }
      }

      this.setState({
        gids: gids,
        fids: fids,
        rids: json.state.configs.wms.rack_config.racks,
        coordinates: json.state.configs.wms.rack_coordinates,
        picking_origin: json.state.configs.wms.rack_config.picking_origin_meters,
      }, ()=>this.queryGidWmsStats());
    })
    .catch(error => {
      this.setState({
        loading: "Error - Status " + error.status
      });
      console.log(error)
    })
  }

  queryGidWmsStats() {
    let body = {time_intervals: this.state.timeIntervals, assets: Object.keys(this.state.gids), outputs: { percentage: {}, intervals: {}}};
    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        //console.log(JSON.stringify(json))
        this.setState({total_time: json.total_time, assets: json.assets, racks: json.racks, types: json.types}, ()=>this.parseGidWmsStats());
      })
      .catch(error => {
        this.setState(
          { loading: "Error - " + error.status,
            assets: {},
            racks: {},
            types: {}
          })
        })
    }

    parseGidWmsStats() {
      let total_picking_time = 0;
      let total_picking_line = 0;
      //let total_active_pickers = 0;
      for (const asset in this.state.assets) {
        let as = this.state.assets[asset];
        if (as.type !== 'picker') { continue; }
        if (as.picking_time < 900000) { continue; } //15 minutes
        total_picking_time += as.picking_time;
        total_picking_line += as.picking_lines;
      }

      let items = (total_picking_line/(total_picking_time/3600000)).toFixed(0);
      let operatorDistance = parseInt((this.state.types.picker.distance / this.state.types.picker.active_count / DEFAULT_WDAYS.length) * METERS_PER_FOOT, 10);

      this.setState({
        operatorMetrics: {items: isNaN(items) ? 0: items, distance: operatorDistance},
      }, ()=>this.queryDailyStats(0, []));
    }

    queryDailyStats(index, results) {
      if (index === this.state.timeIntervals.length) {
        this.parseDailyStats(results);
        return;
      }

      let body = {time_intervals: [this.state.timeIntervals[index]], assets: Object.keys(this.state.gids), outputs: { intervals: {}}};
      postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {results.push(json)})
      .then(() => this.queryDailyStats(index + 1, results))
      .catch(error => {this.setState({ loading: "Error - " + error.status })});
    }

    parseDailyStats(results) {
      let op = [];
      results.forEach(json => {
        op.push((json.types.picker.distance * METERS_PER_FOOT).toFixed(2));
      })
      this.setState({operatorData: op}, ()=>this.queryFidWmsStats());
    }

    queryFidWmsStats() {
      let body = {time_intervals: this.state.flTimeIntervals, assets: Object.keys(this.state.fids), outputs: { percentage: {}, intervals: {}}};
      postWmsJsonNew(this.props.authToken, this.props.siteId, body)
        .then(json => {
          this.setState({fl_total_time: json.total_time, fl_assets: json.assets, fl_types: json.types}, ()=>this.parseFidWmsStats());
        })
        .catch(error => {
          this.setState(
            { loading: "Error - " + error.status,
              fl_assets: {},
              fl_types: {}
            })
          })
      }

    parseFidWmsStats() {
      let totalPct = 0;
      for (const asset in this.state.fl_assets) {
        let as = this.state.fl_assets[asset];
        if (as.type !== 'forklift') { continue; }

        let totalActive = 0;
        as.activity_percentage.forEach( active => {
          totalActive += active.duration;
        })

        let tempPct = totalActive/this.state.fl_total_time * 100;
        totalPct += tempPct;
      }
      let forkliftActivity = (totalPct / this.state.fl_types.forklift.active_count).toFixed(2);
      let forkliftDistance = parseInt((this.state.fl_types.forklift.distance / this.state.fl_types.forklift.active_count / DEFAULT_WDAYS.length) * METERS_PER_FOOT, 10)

      this.setState({
          forkliftMetrics: {activity: forkliftActivity, distance: forkliftDistance}
      }, ()=>this.queryFlDailyStats(0, []));
    }

    queryFlDailyStats(index, results) {
      if (index === this.state.flTimeIntervals.length) {
        this.parseFlDailyStats(results);
        return;
      }

      let body = {time_intervals: [this.state.flTimeIntervals[index]], assets: Object.keys(this.state.fids), outputs: { intervals: {}}};
      postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {results.push(json)})
      .then(() => this.queryFlDailyStats(index + 1, results))
    }

    parseFlDailyStats(results) {
      let flSt = {};
      for (const f in this.state.fids) {
        let detail = this.state.fids[f];
        flSt[f] = {label: detail.name, data: [],  backgroundColor: detail.color, barThickness: 30}
      }

      //let fl = [];
      results.forEach(json => {
        //fl.push((json.types.forklift.distance * METERS_PER_FOOT).toFixed(2));

        for (const asset in json.assets) {
          flSt[asset].data.push((json.assets[asset].distance * METERS_PER_FOOT).toFixed(2));
        }
      })
      this.setState({forkliftData: Object.values(flSt), loading: ""});
    }

  render() {
    return (
      <div>
        <div className="mscsLoading">{this.state.loading}</div>
        <div className="grid-container" style={this.state.loading ? {opacity: "0.3"} : {}}>
          <div className="grid-item card1">
            <MscsLiveView
              key={this.props.siteId + "liveview"}
              siteId={this.props.siteId}
              config={WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId]}
              floor={WAREHOUSE[this.props.siteId].mscs.default_floor}
              size={this.state.size}
            />
          </div>
          <div className="grid-item card2">
            < MscsBulletin
              key={this.props.siteId + "_mscsbulletin"}
              acl={this.props.acl}
              site={this.props.site}
            />
          </div>
          <div className="grid-item card3">
            < MscsOpChart
              key={this.props.siteId + "_mscsOpChart"}
              labels={this.state.labels}
              datasets={this.state.operatorData}
            />
          </div>
          <div className="grid-item card4">
            < MscsFlChart
              key={this.props.siteId + "_mscsFlChart"}
              labels={this.state.labels}
              datasets={this.state.forkliftData}
              />
          </div>
          <div className="grid-item card5">
            <MscsHeatmap
              key={this.props.siteId + "_mscsheatmap"}
              siteId={this.props.siteId}
              nodes={Object.keys(this.state.gids)}
              config={WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId]}
              time_intervals={this.state.timeIntervals}
              floor={WAREHOUSE[this.props.siteId].mscs.default_floor}
              racks={this.state.rids}
              coordinates={this.state.coordinates}
              size={this.state.size}
            />
          </div>
          <div className="grid-item card6">
            <MscsTopRacks
              key={this.props.siteId + "_topRacks"}
              rack_stats={this.state.racks}
              origin={this.state.picking_origin}
              racks={this.state.rids}
              coordinates={this.state.coordinates}
              config={WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId]}
              size={this.state.size}
              />
          </div>
          <div className="grid-item card7">
            <MscsOpMetric
              key={this.props.siteId + "_opMetric"}
              opMetric={this.state.operatorMetrics}
            />
          </div>
          <div className="grid-item card8">
            <MscsFlMetric
              key={this.props.siteId + "_flMetric"}
              flMetric={this.state.forkliftMetrics}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
    site: state.sites.currentSite,
    acl: state.user.acl,
  }),
)(MscsPage);

/*
"types":{
  "forklift":{
  "active_count":14,
  "active_time":244713259,
  "inactive_time":6624014741,
  "distance":814576.1913509977
  },
  "picker":{
  "active_count":40,
  "active_time":901328836,
  "inactive_time":14362511164,
  "distance":2279893.714032429
  }
}
operatorMetrics: { items: 0, distance: 0},
forkliftMetrics: { activity:0, distance: 0},
activity % / active forklifts / days

data={{labels: this.state.labels,
      datasets: [
                 {label: 'PICKING', data: this.state.picking_data, backgroundColor: '#f4d41f' },
                 {label: '1F ACTIVITY', data: this.state.f1_activity_data, backgroundColor: '#b3f66d' },
                 {label: '2F ACTIVITY', data: this.state.f2_activity_data, backgroundColor: '#165FC5' },
                 {label: '4F ACTIVITY', data: this.state.f4_activity_data, backgroundColor: '#FF5733' },
                 {label: 'INACTIVITY', data: this.state.inactivity_data, backgroundColor: '#969696'},
                ]
      }}
      data = [monva, tuesval, wedval, thurval, frival]
*/
