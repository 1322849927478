import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//import ActivityChart from '../ActivityChart';
import CameraEvent from '../events/CameraEvent';
import PastEventsList from '../events/PastEventsList';
import './CameraDetails.css';
import EditNode from './EditNode';

import { getCurrentSiteId } from '../../modules/sites';

const CameraDetailPage = ({ match, node, authToken, siteId }) => {
  if (node) {
    return (
      <div className="content-row">
        <div className="dashboard-content camera-detail-page">
          <div>
             <div className="camera-title">{node.name}
             <div className="camera-detail-icon"><EditNode node={node}/></div>
          </div>
          <div className="detail-header">
            <CameraEvent node={node} />
          </div>

          <div className="recent-events">
            <h2>Past Events</h2>
            <PastEventsList node={node} authToken={authToken} siteId={siteId} from={match.params.from} to={match.params.to}/>
          </div>
        </div>
      </div>
     </div>
    );
  } else {
    return <Redirect to="/dashboard" />
  }
}

export default connect(
  (state, ownProps) => ({
    node: state.nodes.byId[ownProps.match.params.node],
    authToken: state.authToken,
    siteId: getCurrentSiteId(state),
  })
)(CameraDetailPage);

/*
<ActivityChart
  chartWidth={640}
  chartHeight={275}
  defaultRange="last_24h"
  filterType="Node"
  filterId={match.params.node}
/>
*/
