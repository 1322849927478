import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { getNodesHash, createNode, updateNode, fetchNodes } from '../../modules/nodes';
import { checkACL } from '../acl/Config';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';

const EditAnchorNew = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [anchor, setAnchor] = useState(null);
  const [config, setConfig] = useState({anchor: "", hardware_id: "", x: "", y: "", z: "" });
  const [error, setError] = useState({anchor: "", hardware_id: "", x: "", y: "", z: "", status: "" });
  const [disable_save, setDisable_save] = useState(false);

  useEffect(()=> {
    if (props.match.params.id === "undefined" ) { return; }
    if (props.match.params.id === id) { return; }
    console.log("useEffect");
    switch(props.match.params.id) {
      case 'new':
        resetForm(null);
        break;
      default:
        resetForm(props.nodesHash[props.match.params.id])
    }
  });

  const resetForm = (anchor) => {
    if (anchor) {
      console.log("set editForm " + anchor.configs.anchor.y);
      setId(props.match.params.id);
      setEditForm(true);
      setAnchor(anchor);
      setConfig({
        anchor:  anchor.name,
        hardware_id: anchor.hardware_id,
        x:  anchor.configs.anchor.x/100,
        y:  anchor.configs.anchor.y/100,
        z:  anchor.configs.anchor.z/100,
      });
    } else {
      console.log("set newForm")
      setId('new');
      setEditForm(false);
      setAnchor(null);
      setConfig({anchor: "", hardware_id: "", x: "", y: "", z: "" });
      setDisable_save(false);
    }

    setError({anchor: "", x: "", y: "", z: "", status: "" });
  }

  const handleDelete = (e) => {
    setTimeout(()=>props.history.push("/dashboard/settings/devices/anchor"), 700);
  };

  const handleRestart = (e) => {
   let err = {...error};
   e === 'success' ?  err['status'] = anchor.name +  " restarted " :  err['status'] = "Error restarting " + anchor.name;
   setError(err);
  }

  //name, hardware_id, x, y, z
  const validateFields = () => {
    let validate = true;
    let errorNew = {...error};

    if (!editForm) { //if save
      for (const node in props.nodesHash) {
        if (config.anchor === props.nodesHash[node].name) {
          validate = false;
          errorNew['anchor'] = 'Name has to be unique';
        }
      }

      if (config.hardware_id.trim().length !== 12) {
        validate = false;
        errorNew['hardware_id'] = "Hardware id has to be 12 digit";
      }
    }

    if (config.anchor.trim() === '') {
      validate = false;
      errorNew['anchor'] = 'Name has to be not empty';
    }

    if (config.hardware_id.trim() === '') {
      validate = false;
      errorNew['hardware_id'] = 'Hardware ID has to be not empty';
    }

    if (isNaN(config.x)) {
      validate = false;
      errorNew['x'] = "X has to be a number"
    }

    if (isNaN(config.y)) {
      validate = false;
      errorNew['y'] = "Y has to be a number"
    }

    if (isNaN(config.z)) {
      validate = false;
      errorNew['z'] = "Z has to be a number"
    }

    setError(errorNew);
    return validate;
  };


  const handleSubmit = (e)=> {
    if (!validateFields()) { return; }

    console.log("submit");
    if (editForm) {
      console.log("Update Node");
      let node = {
        node: {
          name: config.anchor,
          configs: {
            anchor: {
              x: config.x * 100,
              y: config.y * 100,
              z: config.z * 100,
            }
          }
        }
      };

      props.dispatch(updateNode(anchor.node, node));
    } else {
      console.log("Save Node");
      let node = {
        node: {
          name: config.anchor,
          hardware_id: config.hardware_id,
          node_type: 'anchor',
        }
      };
      props.dispatch(createNode(node));
    }

    setError(Object.assign({}, error, {status: "Saving device... "}))
    setTimeout(()=>props.dispatch(fetchNodes()), 500);
    setTimeout(()=>props.history.push("/dashboard/settings/devices/anchor"), 700);
  };

  const updateField = (field, e) => {
    setConfig(Object.assign({}, config, {[field]: e.target.value}));
  }

  const INFO1 = {
    "Hardware ID(Mac Address)": get(anchor, 'hardware_id'),
    "System Id": get(anchor, 'node'),
    "Internal Mac Address":get(anchor, 'configs.anchor.addr'),
    "IP Address": get(anchor, 'events.system.metrics.network_interfaces.eth0.address'),
    "Firmware Version": get(anchor, 'events.system.versions.radio_firmware'),
  }

  const INFO2 = {
    "Calibration Timestamp": anchor && anchor.configs.lps_control && anchor.configs.lps_control.cal ? new Date(anchor.configs.lps_control.cal.timestamp).toString() : "Not Calibrated",
  }

  return(
    <div className="main edit">
      <Link to={"/dashboard/settings/devices/anchor"}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? "Edit Anchor " : "Add New Anchor"}</h3>
      {editForm &&
        <HeaderContainer
          node = {anchor && anchor.node}
          name = {anchor && anchor.name}
          voltage = {anchor && anchor.events && anchor.events.battery && anchor.events.battery.voltage}
          rssi = {anchor && anchor.events && anchor.events.radio && anchor.events.radio.rssi}
          status={anchor && anchor.events && anchor.events.system && anchor.events.system.connection && anchor.events.system.connection.connected ? "connected" : "disconnected"}
          handleDelete={handleDelete}
          handleRestart={handleRestart}
        />
      }
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        { editForm ?
          <div key="edit">
            <LabelContainer
              labels = {
                {"Device Name": {"help": "", "desc": ""}, "x": {"help": "Relative position from the site origin (feet)"}, "y": {"help": "Relative position from the site origin (feet)"}, "z": {"help": "Height of the anchor measured from the floor to the base of the antenna (feet)"}}
            }/>
            <FormContainer
              fields = {{
                  "anchor": {"value": config['anchor'] ? config.anchor : "", "placeholder": "", "error": error.anchor},
                  "x" :  {"value": config['x'] || config['y'] === 0 ? config.x : "", "placeholder": "", "error": error.x},
                  "y" :  {"value": config['y'] || config['y'] === 0 ? config.y : "", "placeholder": "", "error": error.y},
                  "z" :  {"value": config['z' ]|| config['y'] === 0 ? config.z : "", "placeholder": "", "error": error.z},
              }}
              updateField={updateField}
            />
            <InfoContainer
              info={checkACL(props.acl, 'Calibration Field') ? {...INFO1, ...INFO2} : INFO1}
            />
          </div>
          :
          <div key="save">
            <LabelContainer labels= {{"Device Name": {"help": "", "desc": ""}, "Hardware ID": {"help": "", "desc": ""}}}/>
            <FormContainer
              fields = {{
                  "anchor": {"value": config.anchor, "placeholder": "", "error": error.anchor},
                  "hardware_id": {"value": config.hardware_id, "placeholder": "", "error": error.hardware_id},
              }}
              updateField={updateField}
            />
          </div>
        }
      </div>
      <div style={{display: disable_save ? "none" : "block"}}>
          <Link to={"/dashboard/settings/devices/anchor"}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" disabled={disable_save} onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
      nodesHash: getNodesHash(state),
      acl: state.user.acl,
    }),
)(EditAnchorNew);
