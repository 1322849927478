import React from 'react';
import { connect } from 'react-redux';
import { /*debounce,*/ throttle } from 'lodash';

import '../components/2dReact/css/2dReact.css';
import 'react-tippy/dist/tippy.css';
import { WAREHOUSE } from '../components/2dReact/2dConfig.js';
import { checkSiteACL, checkACL } from './acl/Config';
import { OOB } from '../components/2dReact/OOBConfig.js';

import NavList2d from '../components/2dReact/NavList2d';
import NavControl2d from '../components/2dReact/NavControl2dNew';
import NavFloorControl from '../components/2dReact/NavFloorControl';
import NavMap2d from '../components/2dReact/NavMap2dNew';
import NavMap2dPath from '../components/2dReact/NavMap2dMultPaths'; //need to uncomment floor below
import NavMap2dHeatmap from '../components/2dReact/NavMap2dHeatmapClustered'; //need to uncomment floor below

import NavChart2d from '../components/2dReact/NavChart2dNew';

import { getTrackersArray, getAnchorsArray /*, getNodesArray*/ } from '../modules/nodes';
import { getCurrentSiteId} from '../modules/sites';
import { fetchSiteJson, fetchSiteDetailJson } from '../modules/api';
import { updateNode } from '../modules/nodes';

const METERS_PER_FOOT = 0.3048;
const DEFAULT_FLOOR = "1";
const DEFAULT_HARDWARE = "LETV2"; //LETV1 - thick, LETV2 - thin

class TrackAssetsPage2DMultMaps extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      worldNodes: {}, //all nodes with locations {floor:{}, floor:{}}
      worldAnchors: {},  //all anchors with locations {floor:{}, floor:{}}
      showNodes: {}, //showNodes only contains nodes with locations
      map_config: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId] : WAREHOUSE['default'],
      display_nodelist: true,
      maptype: 'realtime', //'realtime', //default 'paths', 'heatmap'
      time_intervals: [{from: Date.now() - 3600000, to: Date.now()}],
      duration: 3600000,
      showAnchors: false,
      showGTs: false,
      showCards: {}, //hash of cards with flag
      zones: {}, //{floor: {zoneid: zondconfig}}
      showZone: false,
      gids: {}, //{id: {name:}} for attachment name
      view: "map", //"map", //map or chart
      isAdmin: false,
      floors: {}, //{id: display}
      height: 700, //default
      floorNodes: {}, //all nodes with locations {floor:{}, floor:{}}
      oob: {}, ////{floor: {zoneid: zondconfig}}
    };

    this.onLocate = this.onLocate.bind(this);
    this.onUnlink = this.onUnlink.bind(this);
    this.onTDOA = this.onTDOA.bind(this);
    this.onSelect = this.onSelect.bind(this); //show the node in the maps
    this.onSelectIds = this.onSelectIds.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.setMapType = this.setMapType.bind(this);
    this.setTimeIntervals = this.setTimeIntervals.bind(this);
    this.showAnchors = this.showAnchors.bind(this);
    this.showGTs = this.showGTs.bind(this);
    this.onShowCard = this.onShowCard.bind(this); //show the node card in maps
    this.onShowZone = this.onShowZone.bind(this);
    this.onSetView = this.onSetView.bind(this);
    this.onSetFloor = this.onSetFloor.bind(this);
    this.handleNewNodes = throttle(this.newNodes, 10); //1000
  }

  componentDidMount() {
    console.log("TrackAssetsPage2D - 2D Mounted: " + this.props.siteId);

    if (checkSiteACL(this.props.site.customer, this.props.site.site, this.props.acl, 'LPS Tracker Checkin Button')) {
      this.setState({isAdmin: true}, ()=> console.log("User is admin - " + this.state.isAdmin));
    }

    this.loadZones(this.props.siteId);
    this.newAnchors(this.props.anchors);
    this.newNodes(this.props.nodes);
    this.loadFloors();
    this.loadOOB(this.props.siteId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.siteId !== nextProps.siteId) {
      console.log("TrackAssetsPage2D - my site changed: " + this.props.siteId)
      this.setState({
        map_config: WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId] : WAREHOUSE['default'],
        isAdmin: checkSiteACL(this.props.site.customer, this.props.site.site, this.props.acl, 'LPS Tracker Checkin Button'),
      }, () => {
        this.loadFloors();
        this.loadZones(nextProps.siteId);
        this.loadOOB(nextProps.siteId);
      });
    }

    if (this.props.anchors.length !== nextProps.anchors.length) {
      this.newAnchors(nextProps.anchors);
    }

    if (this.props.nodes.length !== nextProps.nodes.length) {
      this.newNodes(nextProps.nodes);
    }

    if (this.state.view === 'map' && this.state.maptype === 'realtime') {
      //console.log(this.state.view + " " + this.state.maptype)
      this.handleNewNodes(nextProps.nodes);
    }
  }

  loadZones(newSiteId) { //and also gids
    fetchSiteDetailJson(this.props.authToken, newSiteId)
    .then(json => {
      let zone_config = {};
      if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('lps') && json.state.configs.lps.hasOwnProperty('zones')) {
        for (const zoneId in json.state.configs.lps.zones) {
          let zone = json.state.configs.lps.zones[zoneId];
          let floor = zone.hasOwnProperty('floor') ? zone.floor : DEFAULT_FLOOR;
          if (!zone_config.hasOwnProperty(floor)) { zone_config[floor] = {} }
          zone_config[floor][zoneId] = { name: zone.name, color: zone.color.startsWith("#") ? zone.color : "#" + zone.color, x: "", y: "", points:[]};

          //adjust for z
          let y_offset = 0;
          if (this.state.map_config.maps[floor].hasOwnProperty('z') && zone.z_min >= (this.state.map_config.maps[floor].z.zone_min * METERS_PER_FOOT)) {
            y_offset = this.state.map_config.maps[floor].z.y_offset * METERS_PER_FOOT;
          }

          zone.points.forEach((p, index) => {
            let newX = (p.x + this.state.map_config.maps[floor].origin.x) * this.state.map_config.maps[floor].small.pixels_per_meter;
            let newY = this.state.map_config.maps[floor].small.height - ((p.y + y_offset + this.state.map_config.maps[floor].origin.y) * this.state.map_config.maps[floor].small.pixels_per_meter);

            zone_config[floor][zoneId].points.push(newX);
            zone_config[floor][zoneId].points.push(newY);

            if (index === 0) {
              zone_config[floor][zoneId].x = newX + 10;
              zone_config[floor][zoneId].y = newY - 25;
            }
          })
        }
      }

      this.setState({zones: zone_config});

      //GIDS
      if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('wms') && json.state.configs.wms.hasOwnProperty('worker_ids')) {
        this.setState({gids: json.state.configs.wms.worker_ids});
      }
    })
  }

  loadOOB(newSiteId) {
    this.setState({oob: {}});
    if (OOB.hasOwnProperty(newSiteId)) {
      let oob_config = {}
      for (const floor in OOB[newSiteId]) {
        oob_config[floor] = {};
        for (const zoneId in OOB[newSiteId][floor]) {
          oob_config[floor][zoneId] = {
              name: zoneId,
              color: OOB[newSiteId][floor][zoneId]['color'],
              points: [],
          };

          OOB[newSiteId][floor][zoneId].points.forEach((p, index) => {
            let newX = (p.x + this.state.map_config.maps[floor].origin.x) * this.state.map_config.maps[floor].small.pixels_per_meter;
            let newY = this.state.map_config.maps[floor].small.height - ((p.y + this.state.map_config.maps[floor].origin.y) * this.state.map_config.maps[floor].small.pixels_per_meter);

            oob_config[floor][zoneId].points.push(newX);
            oob_config[floor][zoneId].points.push(newY);
          })
        }
      }
      this.setState({oob: oob_config}/*, ()=> console.log(this.state.oob)*/)
    }
  }

  loadFloors() {
    let floors = {"All": true}; //insert default for all
    let height = 0;
    this.state.map_config.map_order.forEach(id => {
      floors[id] = true;
      height += this.state.map_config.maps[id].small.height > 700 ? this.state.map_config.maps[id].small.height : 700;
    })

    this.setState({floors: floors, height: height})
  }

  newAnchors(anchors) {
    const newAnchorCollection = {};
    anchors.forEach( node => {
      if (node.configs.hasOwnProperty("anchor") && node.configs.anchor.hasOwnProperty('x') && node.configs.anchor.hasOwnProperty('y') && node.configs.anchor.hasOwnProperty('z')) {
        let floor = node.configs.anchor.hasOwnProperty('floor') ? node.configs.anchor.floor : DEFAULT_FLOOR;

        let y_offset = 0; //adjust for z for anchors
        if (this.state.map_config.maps[floor].hasOwnProperty('z') && (node.configs.anchor.z / 100) >= this.state.map_config.maps[floor].z.anchor_min) {
          y_offset = this.state.map_config.maps[floor].z.y_offset;
        }

        if (!newAnchorCollection.hasOwnProperty(floor)) { newAnchorCollection[floor] = {}}

        //console.log(node.name)
        newAnchorCollection[floor][node.name] = {
          name: node.name,
          x: node.configs.anchor.x / 100 * METERS_PER_FOOT,
          y: (node.configs.anchor.y / 100 + y_offset) * METERS_PER_FOOT,
          z: node.configs.anchor.z / 100 * METERS_PER_FOOT,
          connected: node.hasOwnProperty('events') && node.events.hasOwnProperty('system') && node.events.system.hasOwnProperty('connection') ? node.events.system.connection.connected : "",
        }
      }
    });
    this.setState({worldAnchors: newAnchorCollection})
  }

  newNodes(nodes) {
    this.setState((prevState, props) => {
      //console.log("New Nodes - " + nodes.length);
      const newNodeCollection = {};
      const newFloorCollection = {};
      const nodeList = [];

      let showNodes = {...this.state.showNodes};
      let showCards = {...this.state.showCards};

      nodes.forEach((node) => {
        const nodeId = node.node;
        //console.log('NODE VALUES: ', JSON.stringify(node));
        if (node.node_type === 'tracker') {
          let type = node.configs.tracker.tracker_type ? node.configs.tracker.tracker_type : 'worker';
          let group = node.hasOwnProperty('tags') && node.tags.hasOwnProperty('group') ? node.tags.group : "";
          let nodeListNode = {id: nodeId, name: node.name, type: type, group: group,
                              connected: (node.hasOwnProperty('events') && node.events.hasOwnProperty('system') && node.events.system.hasOwnProperty('connection') ? node.events.system.connection.connected : ""),
                              tdoa_tracking_mode: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_tracking_mode') ?
                                              node.configs.tracker.tdoa_tracking_mode : null,
                             }

          if (node.hasOwnProperty('events') && node.events.hasOwnProperty('tracker') && node.events.tracker.hasOwnProperty('location') && node.events.tracker.location.hasOwnProperty('meas_id')) {
            const theLoc = node.events.tracker.location;
            let floor = theLoc.hasOwnProperty('floor') ? theLoc.floor : DEFAULT_FLOOR;
            if (floor === "0" || floor === undefined)  { floor = DEFAULT_FLOOR; } //send floor 0 and floor undefined to floor 1
            if (!newFloorCollection.hasOwnProperty(floor)) { newFloorCollection[floor] = {} }

            let y_offset = 0 //adjust for z
            if (this.state.map_config.maps.hasOwnProperty(floor) && this.state.map_config.maps[floor].hasOwnProperty('z') &&
                ((theLoc.hasOwnProperty('z') && theLoc.z >= this.state.map_config.maps[floor].z.tracker_min) ||
                 (theLoc.filtered[2] >= this.state.map_config.maps[floor].z.tracker_min))) {
                y_offset = this.state.map_config.maps[floor].z.y_offset;
            }

            nodeListNode = Object.assign({}, nodeListNode, {
                       meas_id: parseInt(theLoc.meas_id, 16),
                       timestamp: theLoc.timestamp,
                       connected: node.hasOwnProperty('events') && node.events.hasOwnProperty('system') && node.events.system.hasOwnProperty('connection') ? node.events.system.connection.connected : "",
                       hardware_version: node.events.hasOwnProperty('system') && node.events.system.hardware_version ? node.events.system.hardware_version : DEFAULT_HARDWARE,
                       voltage: node.events.hasOwnProperty('battery') && node.events.battery.voltage,
                       charge: node.events.hasOwnProperty('battery') && node.events.battery.charge,
                       current: node.events.hasOwnProperty('battery') && node.events.battery.current,
                       color: node.hasOwnProperty('tags') && node.tags.hasOwnProperty('color') && this.state.maptype !== 'heatmap'? node.tags.color : null, //'fafafa'
                       tdoa_master: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_master') ?
                                       node.configs.tracker.tdoa_master : null,
                       addr: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('addr') ?
                                       node.configs.tracker.addr : null,
                       tdoa_npkt: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_npkt') ?
                                       node.configs.tracker.tdoa_npkt : "",
                       tdoa_ftm: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_ftm') ?
                                       node.configs.tracker.tdoa_ftm : "",
                       tdoa_tracking_mode: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_tracking_mode') ?
                                       node.configs.tracker.tdoa_tracking_mode : null,
                       x: theLoc.hasOwnProperty('x') ? theLoc.x * METERS_PER_FOOT : theLoc.filtered[0] * METERS_PER_FOOT,
                       y: theLoc.hasOwnProperty('y') ? (theLoc.y + y_offset) * METERS_PER_FOOT : (theLoc.filtered[1] + y_offset) * METERS_PER_FOOT,
                       z: theLoc.hasOwnProperty('z') ? theLoc.z * METERS_PER_FOOT : theLoc.filtered[2] * METERS_PER_FOOT,
                       asset: node.events.tracker.attachment && node.events.tracker.attachment.attached && (
                         this.state.gids.hasOwnProperty(node.events.tracker.attachment.asset) ?
                          this.state.gids[node.events.tracker.attachment.asset].name
                          :
                          node.events.tracker.attachment.asset
                       ),
            })

            //https://dfsniot.atlassian.net/browse/LPS-221
            //If there is no system:connection event in the API, mark the device as "connected" in the UI
            if (!node.events.hasOwnProperty('system') || !node.events.system.hasOwnProperty('connection')) {
              nodeListNode.connected = true;
            }

            if (!showNodes.hasOwnProperty(nodeId)) {
              let default_show = true;

              //if site is wms - default is forklifts and attached workers so throw out the unattached workers
              if (WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].wms && type === 'worker' && (!node.events.tracker.hasOwnProperty('attachment') || (node.events.tracker.attachment && !node.events.tracker.attachment.attached))) {
                default_show = false;
              }

              //if tdoa only show active nodes
              if (node.configs.tracker.tracker_type === 'phone') {
                if (node.events.tracker.location.timestamp === undefined || node.events.tracker.location.timestamp < Date.now() - 60000) {
                  default_show = false;
                } else {
                  showCards[nodeId] = true;
                }
              }

              showNodes[nodeId] = {show: default_show, type: type, group: group}
            }
            if (!showCards.hasOwnProperty(nodeId)) { showCards[nodeId] = false }
            if (showNodes[nodeId].show) {
              newFloorCollection[floor][nodeId] = nodeListNode;
              newNodeCollection[nodeId] = nodeListNode;
            }
          }
          nodeList.push(nodeListNode);
        }
      });

      return {
        worldNodes: newNodeCollection,
        listNodes: nodeList.sort((a,b) => a.name.localeCompare(b.name)), //sort by name
        showNodes: showNodes,
        showCards: showCards,
        floorNodes: newFloorCollection,
      };
    });
  }

  onSelect(id) { //[id, true/false]
    //console.log("On Select " + id)
    let status = id.split(" ");
    let showNodes = {...this.state.showNodes}
    let showCards = {...this.state.showCards}

    showNodes[status[0]].show = JSON.parse(status[1])
    if (showNodes[status[0]].show === false) { showCards[status[0]] = false }

    this.setState({showNodes: showNodes, showCards: showCards}, ()=>this.newNodes(this.props.nodes))
  }

  onSelectIds(ids) { //[[id, true/false], [id, true/false]]
    let showNodes = {...this.state.showNodes}
    let showCards = {...this.state.showCards}

    ids.forEach(id => {
      if (!showNodes.hasOwnProperty(id[0])) { return; }
      showNodes[id[0]].show = id[1];
      if (showNodes[id[0]].show === false) { showCards[id[0]] = false }
    })

    this.setState({showNodes: showNodes, showCards: showCards}, ()=>this.newNodes(this.props.nodes))
  }

  onSelectAll(id) {
    let showNodes = {...this.state.showNodes}
    let cards = {...this.state.showCards}

    for (const nodeId in showNodes) {
      if (id.target.dataset.group) { //group
        if (id.target.dataset.checked === "true" && showNodes[nodeId].type === id.target.dataset.type && showNodes[nodeId].group === id.target.dataset.group) {
          showNodes[nodeId].show = true
        }

        if (id.target.dataset.checked === "false" && showNodes[nodeId].type === id.target.dataset.type && showNodes[nodeId].group === id.target.dataset.group) {
          showNodes[nodeId].show = false
          cards[nodeId] = false
        }
      } else { //type
        if (id.target.dataset.checked === "true" && showNodes[nodeId].type === id.target.dataset.type) {
          showNodes[nodeId].show = true
        }

        if (id.target.dataset.checked === "false" && showNodes[nodeId].type === id.target.dataset.type) {
          showNodes[nodeId].show = false
          cards[nodeId] = false
        }
      }
    }
    this.setState({showNodes: showNodes, showCards: cards}, ()=>this.newNodes(this.props.nodes))
  }

  onLocate(id) {
    let locatePath = `nodes/${id}/action`;
    let init = {};
    init.method = 'post';
    init.body = JSON.stringify({
      "tracker": {
        "locate": {"ref_id" : 0}
      }
    });

    fetchSiteJson(locatePath, this.props.authToken, this.props.siteId, init)
      .then(json => console.log(JSON.stringify(json)))
      .catch(error => console.log(error));
  }

  onUnlink(id, worker) {
    let path = 'test/sites/' + this.props.siteId + '/wms/assignment';

    let init = {};
    init.method = 'post';
    init.body = JSON.stringify({
      "timestamp": new Date().getTime(),
      "worker": worker,
      "tracker": id,
      "type": "unassign"
    });

    fetchSiteJson(path, this.props.authToken, this.props.siteId, init)
      .then(json => console.log(JSON.stringify(json)))
      .catch(error => console.log(error));
  }

  onTDOA(node) {
    //console.log(JSON.stringify(node));
    let mode = 0;
    if (node.tdoa_tracking_mode === 0 || node.tdoa_tracking_mode === null) {
      mode = 1;
    }

    //console.log(mode);
    this.props.dispatch(
      updateNode(node.id, {
        node: {
          configs: {
            tracker: { tdoa_tracking_mode: mode }
          }
        }
      })
    );

    /* deprecated - don't need anymore
    if (mode === 0) { console.log("TDOA tracking mode is off"); return; } //return if mode is off

    let tdoa = "tdoa";
    if (node.tdoa_ftm === 1) { tdoa = "tdoa_ftm" }

    let locatePath = `nodes/${node.tdoa_master}/action`;
    let init = {};
    let body = {
                "anchor": {
                  "inject": {"command" :  tdoa + " " + node.addr + " " + node.tdoa_npkt}
                }
              };
    init.method = 'post';
    init.body = JSON.stringify(body);

    fetchSiteJson(locatePath, this.props.authToken, this.props.siteId, init)
    .then(json => console.log("TDOA Response: " + JSON.stringify(json)))
    .catch(error => console.log("TDOA Error: ") + error);
    */
  }

  setMapType(e) {
    console.log("set maptype " + e.target.dataset.maptype)
    let showCards = {...this.state.showCards}

    for (const nodeId in showCards) { showCards[nodeId] = false }

    this.setState({showCards: showCards, maptype: e.target.dataset.maptype}, ()=>this.newNodes(this.props.nodes))
  }

  setTimeIntervals(timeArray) {
    let duration = 0;
    timeArray.forEach(t => duration += t.to - t.from);
    this.setState({time_intervals: timeArray, duration: duration});
  }

  showAnchors() {
    this.setState((prevState, props)=> ({showAnchors: !prevState.showAnchors}), ()=>this.newNodes(this.props.nodes));
  }

  showGTs() {
    this.setState((prevState, props) => ({showGTs: !prevState.showGTs}))
  }

  onShowCard(id) {
    //console.log(id.target.attrs.node.nodeId) //from maps
    //console.log("set onShowCards " + id + " " + showCards[id]);
    if (id.hasOwnProperty('target')) { id = id.target.attrs.node.nodeId}

    let showCards = {...this.state.showCards};
    if (!this.state.showNodes[id].show) {
      showCards[id] = false;
    } else {
      showCards[id] ? showCards[id] = false : showCards[id] = true
    }

    this.setState({showCards: showCards})
  }

  onShowZone() {
    this.setState((prevState, props)=> ({showZone: !prevState.showZone}));
  }

  onSetView(e) { //map or chart
    this.setState({view: e.target.dataset.view})
  }

  //need to do height
  onSetFloor(e) {
    let floors = { ...this.state.floors };
    let display = floors[e.target.dataset.source];
    floors[e.target.dataset.source] = !display;

    if (e.target.dataset.source === 'All') {
      this.state.map_config.map_order.forEach(m => {floors[m] = !display} )
    } else {
      floors['All'] = false;
    }

    let height = 0; //do height correction
    this.state.map_config.map_order.forEach(id => {
      if (floors[id]) {
       height += this.state.map_config.maps[id].small.height > 700 ? this.state.map_config.maps[id].small.height : 700;
      }
    })

    this.setState({floors: floors, height: height})
  }

  render() {
    let map = []
    for (const id of this.state.map_config.map_order) {
      if (!this.state.floors[id]) { continue; }

      const m = this.state.map_config.maps[id];
      if (this.state.view === 'map' && this.state.maptype === 'realtime') {
        map.push(
          <div key={id}>
            <NavMap2d
              key={this.props.siteId + "_realtime" + id}
              siteId={this.props.siteId}
              nodes={this.state.floorNodes.hasOwnProperty(id) ? this.state.floorNodes[id] : {}}
              anchors={this.state.showAnchors && this.state.worldAnchors.hasOwnProperty(id) ? this.state.worldAnchors[id] : {}}
              gts={(this.state.showGTs && m.gt) || {}}
              background_src={m.background_src}
              width={m.small.width}
              height={m.small.height}
              pixels_per_meter={m.small.pixels_per_meter}
              cards={this.state.showCards}
              onShowCard={this.onShowCard}
              onLocate={this.onLocate}
              onTDOA={this.onTDOA}
              showZone={this.state.showZone}
              zones={this.state.zones.hasOwnProperty(id) ? this.state.zones[id] : {}}
              origin={m.origin}
              isAdmin={checkACL(this.props.acl, 'Anchor Outage')}
            />
          </div>
        )
      } else if (this.state.view === 'map' && this.state.maptype === 'path') {
        map.push(
          <div key={id}>
          <NavMap2dPath
            key={this.props.siteId + "_path" + id}
            siteId={this.props.siteId}
            nodes={this.state.worldNodes}
            anchors={this.state.showAnchors && this.state.worldAnchors.hasOwnProperty(id) ? this.state.worldAnchors[id] : {}}
            gts={(this.state.showGTs && m.gt) || {}}
            background_src={m.background_src}
            width={m.small.width}
            height={m.small.height}
            pixels_per_meter={m.small.pixels_per_meter}
            time_intervals={this.state.time_intervals}
            cards={this.state.showCards}
            onShowCard={this.onShowCard}
            showZone={this.state.showZone}
            zones={this.state.zones.hasOwnProperty(id) ? this.state.zones[id] : {}}
            origin={m.origin}
            z={m.hasOwnProperty('z') ? m.z : null}
            floor={id}
            oobs={this.state.oob.hasOwnProperty(id) ? this.state.oob[id] : {}}
          />
          </div>
        )
      } else if (this.state.view === 'map' && this.state.maptype === 'heatmap') {
        map.push(
          <div key={id}>
          <NavMap2dHeatmap
            key={this.props.siteId + "_heatmap" + id}
            siteId={this.props.siteId}
            nodes={this.state.worldNodes}
            anchors={this.state.showAnchors && this.state.worldAnchors.hasOwnProperty(id) ? this.state.worldAnchors[id] : {}}
            gts={(this.state.showGTs && m.gt) || {}}
            background_src={m.background_src}
            width={m.small.width}
            height={m.small.height}
            pixels_per_meter={m.small.pixels_per_meter}
            time_intervals={this.state.time_intervals}
            duration={this.state.duration}
            showZone={this.state.showZone}
            zones={this.state.zones.hasOwnProperty(id) ? this.state.zones[id] : {}}
            origin={m.origin}
            z={m.hasOwnProperty('z') ? m.z : null}
            floor={id}
          />
          </div>
        )
      }
    }

    let chart = <div></div>
    if (this.state.view === 'chart') {
      chart = <NavChart2d
                key={this.props.siteId + "_NavChart2d"}
                siteId={this.props.siteId}
                nodes={this.state.worldNodes}
                time_intervals={this.state.time_intervals}
                duration={this.state.duration}
                zone_src={WAREHOUSE[this.props.siteId] && WAREHOUSE[this.props.siteId].legend_src ? WAREHOUSE[this.props.siteId].legend_src : null}
                types={WAREHOUSE[this.props.siteId] && WAREHOUSE[this.props.siteId].tracker_type ? WAREHOUSE[this.props.siteId].tracker_type : []}
                displayDownload={checkSiteACL(this.props.site.customer, this.props.site.site, this.props.acl, 'LPS Download Button')}
              />
    }

    return (
      <div className="Container2D" style={{minHeight: "755px", maxHeight: this.state.view === 'map' ? this.state.height + 55 : "1900px"}}>
          <NavList2d
            key={this.props.siteId + "_list"}
            display={this.state.display_nodelist}
            siteId={this.props.siteId}
            nodes={this.state.listNodes}
            showNodes={this.state.showNodes}
            onSelect={this.onSelect}
            onLocate={this.onLocate}
            onUnlink={this.onUnlink}
            onTDOA={this.onTDOA}
            onSelectAll={this.onSelectAll}
            onSelectIds={this.onSelectIds}
            onShowCard={this.onShowCard}
            setView={this.onSetView}
            view={this.state.view}
            isAdmin={this.state.isAdmin}
          />
          <div className="Container2DRight">
            <NavControl2d
              key={this.props.siteId + "_control"}
              display={this.state.display_nodelist}
              siteId={this.props.siteId}
              maptype={this.state.maptype}
              setMapType={this.setMapType}
              time_intervals={this.state.time_intervals}
              setTimeIntervals={this.setTimeIntervals}
              view={this.state.view}
              showAnchors={this.showAnchors}
              displayAnchors={this.state.showAnchors}
              showGTs={this.showGTs}
              displayGTs={this.state.showGTs}
              showZone={this.state.showZone}
              onShowZone={this.onShowZone}
              zone_src={Object.keys(this.state.zones).length > 0 ? true: false}
              displayDownload={checkSiteACL(this.props.site.customer, this.props.site.site, this.props.acl, 'LPS Download Button')}
            />
            { this.state.view === 'map' &&
              <NavFloorControl
                floor_order={this.state.map_config.map_order}
                floors={this.state.floors}
                onSetFloor={this.onSetFloor}
              />
            }
            <div>{map}</div>
            <div>{chart}</div>
          </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    //nodes: getNodesArray(state),
    nodes: getTrackersArray(state),
    anchors: getAnchorsArray(state),
    //eventManager: state.eventManager,
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
    site: state.sites.currentSite,
    acl: state.user.acl,
  }),
)(TrackAssetsPage2DMultMaps);
