import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSites, createSite, updateSite} from '../../../modules/sites';

import InfoContainer from '../../settings/form/InfoContainer';
import FormContainer from '../../settings/form/FormContainer';
import LabelContainer from '../../settings/form/LabelContainer';
import { isEmpty } from '../../settings/form/ErrorChecking';

const CONFIG = { name: "", validate: true};
const URL = "/dashboard/management/sites";

//{site: "7afc875e246c47fb84d0ce2b8298e56a", site_name: "adi test", customer: "a580fab1e26741b2b9ef310e9444e2f8", customer_name: "Locix"}
const EditSite = (props) => {
  const [site, setSite] = useState(null);
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);

  useEffect(() => {
    //console.log("use effect");
    if (props.match.params.id === 'new') {
      console.log("set newForm");
      setSite(null);
      setConfig(CONFIG);
    } else {
      console.log("set editForm");
      setSite(props.sites[props.match.params.id]);
      setConfig({name: props.sites[props.match.params.id].site_name});
    }
    setError(CONFIG);
  }, [props.match.params.id, props.sites, props.customers])


  const validateFields = () => {
    let errorNew = {...CONFIG};
    isEmpty("name", config.name, errorNew);
    setError(errorNew);
    return errorNew.validate;
  };

  const handleSubmit = (e)=> {
    if (!validateFields()) {
      return;
   }

    console.log("submit");
    let errorNew = {...error}
    errorNew.status = "Saving " + config.name;
    setError(errorNew);

    if (site) {
      let body = {
        site: {
          name: config.name,
          configs: {}
        }
      };
      props.dispatch(updateSite(site.site,body));
    } else {
      let newsite = {
        site: {
          name: config.name,
          customer: props.match.params.customer,
        }
      };
      props.dispatch(createSite(newsite));
    }
    setTimeout(()=>props.dispatch(fetchSites()), 500);
    setTimeout(()=>props.history.push(URL), 700);
  };

  const updateField = (field, e, type) => {
    let configNew = {...config};
    if (type && type === 'select') {
      configNew[field] = e.value;
    } else {
      configNew[field]  = e.target.value;
    }
    setConfig(configNew);
  }

  const NEW_FORM =
    <div key="save">
      <LabelContainer labels= {{"Name": {"help": "", "desc": ""}}}/>
      <FormContainer
        fields = {{
            "name": {"value": config.name, "placeholder": "", "error": error.name},
        }}
        updateField={updateField}
      />
      <InfoContainer info={{"Customer": props.customers && props.customers[props.match.params.customer]}}/>
    </div>

  const EDIT_FORM =
    <div key="edit">
      <LabelContainer labels = {{"Site Name": {}}}/>
      <FormContainer
        fields = {{
            "name": {"value": config['name'] ? config.name : "", "placeholder": "", "error": error.name},
        }}
        updateField={updateField}
      />
      <InfoContainer info={{"Customer": site && site.customer_name}}/>
    </div>

  return(
    <div className="main edit">
      <Link to={URL}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{site ? "Edit Site" : "Add New Site"}</h3>
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        { site ? EDIT_FORM : NEW_FORM}
      </div>
      <div>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
    sites: state.sites.sites,
    customers: state.sites.customers,
  })
)(EditSite);
