import React from 'react';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

const NodeFormConfigs = ({
  camera,
  motion,
  editCamera,
  editMotion,
  disabled,
}) => (
  <div>
    <div className="form-group">
      <label>
        Autocapture
        <input
          type="number"
          value={camera.auto_capture_period_minutes}
          onChange={e => editCamera(parseInt(e.target.value, 10), 'auto_capture_period_minutes')}
          min="0"
          max="1439"
          placeholder="Off (0 Mins)"
          disabled={disabled}
        />
      </label>
    </div>
    <div className="form-group small">
      <label >
        Media Type
        <div>
          <span
            className={`media-btn ${camera.auto_capture_media_format === 'image' ? 'checked' : ''}`}
            onClick={() => editCamera('image', 'auto_capture_media_format')}
          >
            <i className="fa fa-camera" />
            IMAGE
          </span>
          <span
            className={`media-btn ${camera.auto_capture_media_format === 'video' ? 'checked' : ''}`}
            onClick={() => editCamera('video', 'auto_capture_media_format')}
          >
            <i className="fa fa-video-camera" />
            VIDEO
          </span>
        </div>
      </label>
    </div>
    <div className="form-group">
      <label>
        Alarm Action
        <span style={{ textAlign: 'left' }} className="input">Choose a media type for alarms:</span>
      </label>
    </div>
    <div className="form-group small">
      <label>
        Media Type
        <div>
          <span
            className={`media-btn ${camera.alarm_media_format === 'image' ? 'checked' : ''}`}
            onClick={() => editCamera('image', 'alarm_media_format')}
          >
            <i className="fa fa-camera" />
            IMAGE
          </span>
          <span
            className={`media-btn ${camera.alarm_media_format === 'video' ? 'checked' : ''}`}
            onClick={() => editCamera('video', 'alarm_media_format')}
          >
            <i className="fa fa-video-camera" />
            VIDEO
          </span>
        </div>
      </label>
    </div>
    <div className="form-group">
      <label>
        Motion Sensitivity
        <div style={{
            display: 'flex', alignItems: 'center', width: '250px', justifyContent: 'space-between'
          }}
        >
          <input
            type="number"
            value={motion.motion_sensitivity}
            onChange={e => editMotion(e.target.value, 'motion_sensitivity')}
            min="0"
            max="255"
            style={{ width: '35px' }}
            disabled={disabled}
          />
          <Slider
            min={0}
            max={255}
            orientation="horizontal"
            value={motion.motion_sensitivity}
            onChange={val => editMotion(val, 'motion_sensitivity')}
          />
        </div>
      </label>
    </div>
    <div className="form-group">
      <label>
        Camera LED Power
        <input
          type="number"
          value={camera.ir_led_brightness}
          onChange={e => editCamera(Number(e.target.value), 'ir_led_brightness')}
          min="0"
          max="255"
          disabled={disabled}
        />
      </label>
    </div>
    <div className="form-group">
      <label>
        Ambient Light Threshold
        <input
          type="number"
          value={camera.ambient_light_threshold}
          onChange={e => editCamera(parseInt(e.target.value,10), 'ambient_light_threshold')}
          min="0"
          max="60000"
          disabled={disabled}
        />
      </label>
    </div>
  </div>
);

NodeFormConfigs.defaultProps = {
  camera: {
    auto_capture_period_minutes: 0,
    auto_capture_media_format: 'image',
    alarm_media_format: 'image',
    ambient_light_threshold: 8000,
    ir_led_brightness: 0.5,
  },
  motion: {
    motion_sensitivity: 15,
  },
  // disabled: true,
};

export default NodeFormConfigs;
