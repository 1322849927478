import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { editConstellation, getRecentConstellationNodeEvents } from '../../modules/constellations';
import { fetchMediaBlob } from '../../modules/api';
import { Timestamp } from '../Timestamp.js';
import ConstellationStateButton from '../constellations/ConstellationStateButton';
import ConstellationHistoryOverlay from './ConstellationHistoryOverlay';

const ConstellationEvent = ({
  constellation,
  nodes,
  mediaFormat,
  srcUrl,
  millis,
}) => {
  return (
    <div className="constellation-container">
      <div className="constellation-header">
        <Link to={`/dashboard/constellations/${constellation.constellation}`}>
          <h3 className="constellation-name">{constellation.name}</h3>
        </Link>
        <ConstellationStateButton
          state={constellation.state}
          id={constellation.constellation}
        />
      </div>
      <div className="event-frame">
        {
          mediaFormat === 'video' ? (
            <video autoPlay loop className="event-frame-vid" alt="No Media Available" src={srcUrl} />
          ) : (
            <img className="event-frame-img" alt="No Media Available" src={srcUrl} />
          )
        }
        <div className="node-overlay">
          <div className="event-caption">
            <Timestamp millis={millis}/>
          </div>
        </div>
      </div>
      <ConstellationHistoryOverlay constellation={constellation} nodes={nodes} />
    </div>
  );
}

class ConstellationEventContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaFormat: '',
      key: '',
      srcUrl: '',
      millis: ''
    }
  }
  componentDidMount() {
    // fetchConstellationNodes(this.props.constellation.constellation)
    //   .then(console.warn(json));
  }

  componentWillUnmount() {
    this.props.dispatch(editConstellation({
      constellation: this.props.constellation.constellation,
      mediaBlob: '',
    }));
  }

  componentWillReceiveProps(nextProps) {
    // Get the node events and sort them by most recent
    let childNodes = nextProps.nodes;
    let nodeEvents = childNodes.map(n => {
      return n.events.camera.media_ready;
    })
    nodeEvents.sort((a,b) => b.timestamp - a.timestamp);

    // Get the most recent event
    let recentEvent = nodeEvents[0];

    let media = {};
    let oldMediaKey = '';
    try {
      media = recentEvent;
      oldMediaKey = this.state.key;
    } catch(err) {
      console.warn('No media found', err);
    }

    // Compare new image key to existing image key and update
    if (media && oldMediaKey !== media.key) {
      this.setState({
        mediaFormat: media.format,
        key: media.key,
        srcUrl: require('../../media/Loading_Img.svg'),
        millis: media.timestamp
      })
      //setTimeout(() => {
        fetchMediaBlob(media.key, this.props.authToken)
          .then(srcUrl => {
          this.setState({ srcUrl, mediaFormat: media.format, key: media.key })
        })
      //}, 1000)
    }
  }

  render() {
    return (
      <ConstellationEvent
        srcUrl={this.state.srcUrl || require('../../media/Loading_Img.svg')}
        millis={this.state.millis}
        mediaFormat={this.state.mediaFormat}
        constellation={this.props.constellation}
        nodes={this.props.nodes}
      />
    );
  }
}

export default connect((state, ownProps) => ({
  authToken: state.authToken,
  nodes: getRecentConstellationNodeEvents(state, ownProps.constellation.constellation)
}))(ConstellationEventContainer);
