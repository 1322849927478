import React from 'react';
import { /*WAREHOUSE,*/ IMG_CONFIG } from '../2dConfig';
import { WMS_IMG_CONFIG} from './GIDConfig';
import { Tooltip } from 'react-tippy';

class WMSList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sorted_nodes: [],
      search: null,
      display_list: {},
    };

    this.filter = this.filter.bind(this);
    this.toggle_display = this.toggle_display.bind(this);
    this.toggle_type = this.toggle_type.bind(this);
  }

  componentDidMount() {
    let display = {};
    Object.values(this.props.groups).flat().forEach(type => {
      display[type] = this.props.default_list_selection[type];
    });

    this.setState({sorted_nodes: this.props.worker_hash, display_list: display});
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.worker_hash) !== JSON.stringify(this.props.worker_hash)) {
      if (this.state.search &&  this.state.search !== "") {
        let re =  new RegExp(this.state.search, "i")
        let found = this.props.worker_hash.filter(n => n.name.match(re))
        this.setState({sorted_nodes: found});
      } else {
        this.setState({sorted_nodes: this.props.worker_hash})
      }
    }

    if (JSON.stringify(this.props.selectAll) !== JSON.stringify(prevProps.selectAll)) {
      let temp = {...this.state.display_list};
      for (const group in this.props.selectAll) {
        if (this.props.selectAll[group] === true) {
          temp[group] = true;
        }
      }
      this.setState({display_list: temp})
    }
  }

  filter(e) {
    let re =  new RegExp(e.target.value, "i")
    let found = this.props.worker_hash.filter(n => n.name.match(re))
    this.setState({search: e.target.value, sorted_nodes: found});
  }

  toggle_display(e) {
    let toggle = {...this.state.display_list};
    toggle[e.target.dataset.type] = JSON.parse(e.target.dataset.toggle);
    this.setState({display_list: toggle});
  }

  toggle_type(e) {
    e.stopPropagation();
    let toggle = {...this.state.display_list};
    let show = toggle[e.target.dataset.type]
    toggle[e.target.dataset.type] = !show;
    this.setState({display_list: toggle});
  }

  render() {
    return (
        <div className="NavList2D">
        <div className="AvailableDevices">WMS</div>
        <div className="AnalyticsIconContainer">
          <img className="AnalyticsIcon" alt="maps" data-view="map" src={this.props.view === 'map' ? IMG_CONFIG['map']['active']['img'] : IMG_CONFIG['map']['inactive']['img']} onClick={this.props.setView}/>
          <img className="AnalyticsIcon" alt="analytics" data-view="chart" src={this.props.view === 'chart' ? IMG_CONFIG['analytics']['active']['img'] : IMG_CONFIG['analytics']['inactive']['img']} onClick={this.props.setView}/>
        </div>
          <div className="BlueLine"/>
          <div>
            <input type="text" className="SearchNodesBox" onChange={this.filter}/>
            <div className="fa fa-search SearchNodesIcon"/>
          </div>
          <div id="floor">
            <div className="Filter">
            { this.props.selectSubfilter['attached'] ?
                <Tooltip title={"Hide Active Workers"} theme="transparent">
                  <img key="checked_out" className="SelectGroupIcon" alt="select" data-filter="attached" data-filterby={false} src={IMG_CONFIG["checked_out"].active.img} onClick={this.props.onSelectSubfilter}/>
                </Tooltip>
                :
                <Tooltip title={"Show Active Workers"} theme="transparent">
                <img key="checked_out" className="SelectGroupIcon" alt="select" data-filter="attached" data-filterby={true} src={IMG_CONFIG["checked_out"].inactive.img} onClick={this.props.onSelectSubfilter}/>
                </Tooltip>
            }
            {
              this.props.selectSubfilter['unattached'] ?
                <Tooltip title={"Hide Inactive Workers"} theme="transparent">
                <img key="checked_in" className="SelectGroupIcon" alt="select" data-filter="unattached" data-filterby={false} src={IMG_CONFIG["checked_in"].active.img} onClick={this.props.onSelectSubfilter}/>
                </Tooltip>
                :
                <Tooltip title={"Show Inactive Workers"} theme="transparent">
                <img key="checked_in" className="SelectGroupIcon" alt="select" data-filter="unattached" data-filterby={true} src={IMG_CONFIG["checked_in"].inactive.img} onClick={this.props.onSelectSubfilter}/>
                </Tooltip>
            }
            </div>
            { //groups and types
              Object.keys(this.props.groups).map(group => {
                let floor = <div className="Group" key={group}>{group}</div>
                let types = this.props.groups[group].map(type =>
                   this.props.selectAll[type] ?
                    <Tooltip title={"Hide " + type + "s"} theme="transparent">
                      <img key={group + "_" + type + "_select"} className="SelectGroupIcon" data-checked="false" data-type={type} alt="select" src={WMS_IMG_CONFIG[type] ? WMS_IMG_CONFIG[type].select : WMS_IMG_CONFIG['Default'].select}  onClick={this.props.onSelectAll}/>
                    </Tooltip>
                     :
                    <Tooltip title={"Show " + type + "s"} theme="transparent">
                      <img key={group + "_" + type + "_unselect"} className="SelectGroupIcon" data-checked="true" data-type={type} alt="unselect" src={WMS_IMG_CONFIG[type] ? WMS_IMG_CONFIG[type].unselect : WMS_IMG_CONFIG['Default'].unselect} onClick={this.props.onSelectAll}/>
                    </Tooltip>
               )
               return ([floor, types])
             })
            }
          </div>
          {
            Object.values(this.props.groups).flat().map (type =>
              <div key={type} data-type={type} onClick={this.toggle_type}>
                <div className="Line2"/>
                {
                  this.props.selectAll[type] ?
                    <img className="SelectIconSmall " alt="worker_select" src={WMS_IMG_CONFIG[type]? WMS_IMG_CONFIG[type].select : WMS_IMG_CONFIG['Default'].select}/>  :
                    <img className="SelectIconSmall " alt="worker_unselect" src={WMS_IMG_CONFIG[type] ? WMS_IMG_CONFIG[type].unselect : WMS_IMG_CONFIG['Default'].unselect}/>
                }
                <div className="CheckBoxAllText2">{type}</div>
                { this.state.display_list[type] ?
                    <img className="ToggleDown2" alt="ToggleDown" src={require("../img/ToggleDown.png")} data-type={type} data-toggle={false} onClick={this.toggle_display}/>
                     :
                    <img className="ToggleDown2" alt="ToggleDown" src={require("../img/ToggleUp.png")} data-type={type} data-toggle={true} onClick={this.toggle_display}/>
                }
                <div className="Line3"/>
                {
                  this.state.display_list[type] ?
                    this.state.sorted_nodes.filter(n => n.type === type).map( n =>
                      (
                        <div key={n.id} data-node={n.id} onClick={this.props.onSetShowNode}>
                        <input type="checkbox" className="CheckBoxAll" data-type={n.type} data-node={n.id} checked={this.props.selected_worker_hash.hasOwnProperty(n.id) ? true : false} onClick={e => {e.stopPropagation()}} onChange={this.props.onSelect}/>
                        <div className="WorkerText">{n.name}</div>
                        <div className="Line4"/>
                        </div>
                      )
                    )
                    :
                    <div/>
                }
            </div>
          )
        }
      </div>
    )
  }
}

export default WMSList;
//<input type="checkbox" className="CheckBoxAll" style={{marginTop: "0px"}} data-type={type} checked={check_selected_all(type, this.props.worker_hash, this.props.selected_worker_hash)} onClick={e => {e.stopPropagation()}} onChange={this.props.onSelectAll}/>
/*function check_selected_all(type, workers, selected_worker_hash) { //check if worker hash === selected_worker_hash for type
  let all = workers.filter(n => n.type === type)
  let selected = Object.values(selected_worker_hash).filter(n => n.type === type)
  return (all.length === selected.length)
}*/
