import React from 'react';
import { connect } from 'react-redux';

import '../components/2dReact/css/2dReact.css';
import 'react-tippy/dist/tippy.css';
import { WAREHOUSE } from '../components/2dReact/2dConfig.js';

import NavList2d from '../components/2dReact/NavList2d';
import NavControl2d from '../components/2dReact/NavControl2d';
import NavMap2d from '../components/2dReact/NavMap2dNew';
//import NavMap2dPath from '../components/2dReact/NavMap2dPathFiltered';
import NavMap2dPath from '../components/2dReact/NavMap2dMultPaths';
import NavMap2dHeatmap from '../components/2dReact/NavMap2dHeatmapClustered';

import NavChart2d from '../components/2dReact/NavChart2d';

import { getNodesArray } from '../modules/nodes';
import { getCurrentSiteId} from '../modules/sites';
import { fetchSiteJson, fetchSiteDetailJson } from '../modules/api';
import { updateNode } from '../modules/nodes';

const METERS_PER_FOOT = 0.3048;
const ADMIN = [{userName: 'mscs_user', userId: '75da4030acb34e208f00e5a33d2277c7'}, {userName: 'barbara', userId: '3f95c89bbb164caaa735ca851f5cd462'}];

class TrackAssetsPage2D extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      worldNodes: {}, //all nodes with locations
      worldAnchors: {},  //all anchors with locations
      listNodes: [], //nodelist that goes into menu has all nodes
      showNodes: {}, //showNodes only contains nodes with locations
      width: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.width : WAREHOUSE['default'].small.width,
      height: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.height: WAREHOUSE['default'].small.height,
      pixels_per_meter: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.pixels_per_meter : WAREHOUSE['default'].small.pixels_per_meter,
      origin: WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId].origin : WAREHOUSE['default'].origin,
      display_nodelist: true,
      maptype: 'realtime', //'realtime', //default 'paths', 'heatmap'
      from: Date.now() - 3600000,
      to: Date.now(),
      showAnchors: false,
      showCards: {}, //hash of cards with flag
      zones: [],
      showZone: false,
      view: "map", //"map", //map or chart
      isAdmin: false,
    };

    this.onLocate = this.onLocate.bind(this);
    this.onUnlink = this.onUnlink.bind(this);
    this.onTDOA = this.onTDOA.bind(this);
    this.onSelect = this.onSelect.bind(this); //show the node in the maps
    this.onSelectAll = this.onSelectAll.bind(this);
    this.setMapType = this.setMapType.bind(this);
    this.setFromTo = this.setFromTo.bind(this);
    this.showAnchors = this.showAnchors.bind(this);
    this.onShowCard = this.onShowCard.bind(this); //show the node card in maps
    this.onShowZone = this.onShowZone.bind(this);
    this.onSetView = this.onSetView.bind(this);
  }

  componentDidMount() {
    console.log("TrackAssetsPage2D - 2D Mounted: " + this.props.siteId);

    if (ADMIN.find(user => this.props.userId === user.userId)) {
      this.setState({isAdmin: true}, ()=> console.log("User is admin - " + this.state.isAdmin));
    }

    this.loadZones(this.props.siteId);
    this.newNodes(this.props.nodes);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.siteId !== nextProps.siteId) {
      console.log("TrackAssetsPage2D - my site changed: " + this.props.siteId)
      this.setState({
          width: WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].small.width : WAREHOUSE['default'].small.width,
          height: WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].small.height: WAREHOUSE['default'].small.height,
          pixels_per_meter: WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].small.pixels_per_meter : WAREHOUSE['default'].small.pixels_per_meter,
          origin: WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].origin : WAREHOUSE['default'].origin,
      });

      this.loadZones(nextProps.siteId);
    }
    this.newNodes(nextProps.nodes);
  }

  loadZones(newSiteId) {
    fetchSiteDetailJson(this.props.authToken, newSiteId)
    .then(json => {
      let zone_config = {};
      if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('tracker') && json.state.configs.tracker.hasOwnProperty('zones')) {
        for (const zoneId in json.state.configs.tracker.zones) {
          let zone = json.state.configs.tracker.zones[zoneId];
          zone_config[zoneId] = {name: zone.name, color: zone.color.startsWith("#") ? zone.color : "#" + zone.color, x: "", y: "", points:[]};

          //adjust for z
          let y_offset = 0;
          if (WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('z') && zone.z_min >= WAREHOUSE[this.props.siteId].z.zone_min) {
            y_offset = WAREHOUSE[this.props.siteId].z.y_offset;
          }

          zone.points.forEach((p, index) => {
            let newX = (p.x * METERS_PER_FOOT + this.state.origin.x) * this.state.pixels_per_meter;
            let newY = this.state.height - (((p.y + y_offset) * METERS_PER_FOOT + this.state.origin.y) * this.state.pixels_per_meter);

            zone_config[zoneId].points.push(newX);
            zone_config[zoneId].points.push(newY);

            if (index === 0) {
              zone_config[zoneId].x = newX + 10;
              zone_config[zoneId].y = newY - 25;
            }
          })
        }
      }
    return zone_config;
    })
   .then(zone_config => this.setState({zones: zone_config}));
  }

  newNodes(nodes) {
    this.setState((prevState, props) => {
      const newNodeCollection = {};
      const newAnchorCollection = {};
      const nodeList = [];
      let showNodes = {...this.state.showNodes};
      let showCards = {...this.state.showCards};

      nodes.forEach((node) => {
        const nodeId = node.node;
        //console.log('NODE VALUES: ', JSON.stringify(node));
        if (node.node_type === 'tracker') {
          let type = node.configs.tracker.tracker_type ? node.configs.tracker.tracker_type : 'worker';
          let group = node.hasOwnProperty('tags') && node.tags.hasOwnProperty('floor') ? "Floor " + node.tags.floor : "";
          let nodeListNode = {id: nodeId, name: node.name, type: type, group: group, connected: (node.hasOwnProperty('events') && node.events.hasOwnProperty('system') && node.events.system.hasOwnProperty('connection') ? node.events.system.connection.connected : "")}

          if (node.hasOwnProperty('events') && node.events.hasOwnProperty('tracker') && node.events.tracker.hasOwnProperty('location') && node.events.tracker.location.hasOwnProperty('meas_id')) {
            const theLoc = node.events.tracker.location;
            let y_offset = 0 //adjust for z
            if (WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('z') &&
                ((theLoc.hasOwnProperty('z') && theLoc.z >= WAREHOUSE[this.props.siteId].z.tracker_min) ||
                 (theLoc.filtered[2] >= WAREHOUSE[this.props.siteId].z.tracker_min))) {
                y_offset = WAREHOUSE[this.props.siteId].z.y_offset;
            }

            nodeListNode = Object.assign({}, nodeListNode, {
                       meas_id: parseInt(theLoc.meas_id, 16),
                       timestamp: theLoc.timestamp,
                       connected: node.hasOwnProperty('events') && node.events.hasOwnProperty('system') && node.events.system.hasOwnProperty('connection') ? node.events.system.connection.connected : "",
                       voltage: node.events.hasOwnProperty('battery') ? node.events.battery.voltage : null,
                       color: node.hasOwnProperty('tags') && node.tags.hasOwnProperty('color') && this.state.maptype !== 'heatmap'? node.tags.color : null, //'fafafa'
                       tdoa_master: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_master') ?
                                       node.configs.tracker.tdoa_master : null,
                       addr: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('addr') ?
                                       node.configs.tracker.addr : null,
                       tdoa_npkt: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_npkt') ?
                                       node.configs.tracker.tdoa_npkt : "",
                       tdoa_ftm: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_ftm') ?
                                       node.configs.tracker.tdoa_ftm : "",
                       tdoa_tracking_mode: node.hasOwnProperty('configs') && node.configs.hasOwnProperty('tracker') && node.configs.tracker.hasOwnProperty('tdoa_tracking_mode') ?
                                       node.configs.tracker.tdoa_tracking_mode : null,
                       x: theLoc.hasOwnProperty('x') ? theLoc.x * METERS_PER_FOOT : theLoc.filtered[0] * METERS_PER_FOOT,
                       y: theLoc.hasOwnProperty('y') ? (theLoc.y + y_offset) * METERS_PER_FOOT : (theLoc.filtered[1] + y_offset) * METERS_PER_FOOT,
                       z: theLoc.hasOwnProperty('z') ? theLoc.z * METERS_PER_FOOT : theLoc.filtered[2] * METERS_PER_FOOT,
                       asset: node.events.tracker.attachment && node.events.tracker.attachment.attached && node.events.tracker.attachment.asset,
            })

            if (!showNodes.hasOwnProperty(nodeId)) { showNodes[nodeId] = {show: true, type: type, group: group}}
            if (!showCards.hasOwnProperty(nodeId)) { showCards[nodeId] = false }
            if (showNodes[nodeId].show) { newNodeCollection[nodeId] = nodeListNode }
          }
          nodeList.push(nodeListNode);
        }

        if (node.node_type === 'anchor' && this.state.showAnchors) {
          if (node.configs.anchor.hasOwnProperty('x') && node.configs.anchor.hasOwnProperty('y') && node.configs.anchor.hasOwnProperty('z')) {

            let y_offset = 0; //adjust for z for anchors
            if (WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('z') && (node.configs.anchor.z / 100) >= WAREHOUSE[this.props.siteId].z.anchor_min) {
              y_offset = WAREHOUSE[this.props.siteId].z.y_offset;
            }

            newAnchorCollection[node.name] = {
              x: node.configs.anchor.x / 100 * METERS_PER_FOOT,
              y: (node.configs.anchor.y / 100 + y_offset) * METERS_PER_FOOT,
              z: node.configs.anchor.z / 100 * METERS_PER_FOOT,
            }
          }
        }
      });

      return {
        worldNodes: newNodeCollection,
        worldAnchors: newAnchorCollection,
        listNodes: nodeList.sort((a,b) => a.name.localeCompare(b.name)), //sort by name
        showNodes: showNodes,
        showCards: showCards,
      };
    });
  }

  onSelect(id) {
    console.log("On Select " + id)
    let status = id.split(" ");
    let showNodes = {...this.state.showNodes}
    let showCards = {...this.state.showCards}

    showNodes[status[0]].show = JSON.parse(status[1])
    if (showNodes[status[0]].show === false) { showCards[status[0]] = false }

    this.setState({showNodes: showNodes, showCards: showCards}, ()=>this.newNodes(this.props.nodes))
  }

  onSelectAll(id) {
    //console.log(id.target.dataset.checked);
    let showNodes = {...this.state.showNodes}
    let cards = {...this.state.showCards}

    for (const nodeId in showNodes) {
      if (id.target.dataset.group) { //group
        if (id.target.dataset.checked === "true" && showNodes[nodeId].type === id.target.dataset.type && showNodes[nodeId].group === id.target.dataset.group) {
          showNodes[nodeId].show = true
        }

        if (id.target.dataset.checked === "false" && showNodes[nodeId].type === id.target.dataset.type && showNodes[nodeId].group === id.target.dataset.group) {
          showNodes[nodeId].show = false
          cards[nodeId] = false
        }
      } else {
        if (id.target.dataset.checked === "true" && showNodes[nodeId].type === id.target.dataset.type) {
          showNodes[nodeId].show = true
        }

        if (id.target.dataset.checked === "false" && showNodes[nodeId].type === id.target.dataset.type) {
          showNodes[nodeId].show = false
          cards[nodeId] = false
        }
      }
    }
    this.setState({showNodes: showNodes, showCards: cards}, ()=>this.newNodes(this.props.nodes))
  }

  onLocate(id) {
    let locatePath = `nodes/${id}/action`;
    let init = {};
    init.method = 'post';
    init.body = JSON.stringify({
      "tracker": {
        "locate": {"ref_id" : 0}
      }
    });

    fetchSiteJson(locatePath, this.props.authToken, this.props.siteId, init)
      .then(json => console.log(JSON.stringify(json)))
      .catch(error => console.log(error));
  }

  onUnlink(id, worker) {
    let path = 'test/sites/' + this.props.siteId + '/wms/assignment';

    let init = {};
    init.method = 'post';
    init.body = JSON.stringify({
      "timestamp": new Date().getTime(),
      "worker": worker,
      "tracker": id,
      "type": "unassign"
    });

    fetchSiteJson(path, this.props.authToken, this.props.siteId, init)
      .then(json => console.log(JSON.stringify(json)))
      .catch(error => console.log(error));
  }

  onTDOA(node) {
    //console.log(JSON.stringify(node));
    let mode = 0;
    if (node.tdoa_tracking_mode === 0 || node.tdoa_tracking_mode === null) {
      mode = 1;
    }

    this.props.dispatch(
      updateNode(node.id, {
        node: {
          configs: {
            tracker: { tdoa_tracking_mode: mode }
          }
        }
      })
    );

    if (mode === 0) { console.log("TDOA tracking mode is off"); return; } //return if mode is off

    let tdoa = "tdoa";
    if (node.tdoa_ftm === 1) { tdoa = "tdoa_ftm" }

    let locatePath = `nodes/${node.tdoa_master}/action`;
    let init = {};
    let body = {
                "anchor": {
                  "inject": {"command" :  tdoa + " " + node.addr + " " + node.tdoa_npkt}
                }
              };
    init.method = 'post';
    init.body = JSON.stringify(body);

    //console.log(locatePath);
    //console.log(init.body);
    fetchSiteJson(locatePath, this.props.authToken, this.props.siteId, init)
    .then(json => console.log("TDOA Response: " + JSON.stringify(json)))
    .catch(error => console.log("TDOA Error: ") + error);
  }

  setMapType(e) {
    console.log("set maptype " + e.target.dataset.maptype)
    let showCards = {...this.state.showCards}

    for (const nodeId in showCards) { showCards[nodeId] = false }

    this.setState({showCards: showCards, maptype: e.target.dataset.maptype}, ()=>this.newNodes(this.props.nodes))
  }

  setFromTo(from, to) {
    console.log("set from and to " + from + " " + to + " : " + (new Date(from)) + " " + (new Date(to)));
    this.setState({from: from, to: to});
  }

  showAnchors() {
    /*if (e.target.dataset.show === 'true') {
      this.setState({showAnchors: true}, ()=>this.newNodes(this.props.nodes))
    } else {
      this.setState({showAnchors: false}, ()=>this.newNodes(this.props.nodes))
    }*/
    this.setState((prevState, props)=> ({showAnchors: !prevState.showAnchors}), ()=>this.newNodes(this.props.nodes));
  }

  onShowCard(id) {
    //console.log(id.target.attrs.node.nodeId) //from maps
    if (id.hasOwnProperty('target')) { id = id.target.attrs.node.nodeId}

    let showCards = {...this.state.showCards};
    if (!this.state.showNodes[id].show) {
      showCards[id] = false;
    } else {
      showCards[id] ? showCards[id] = false : showCards[id] = true
    }

    console.log("set onShowCards " + id + " " + showCards[id]);
    this.setState({showCards: showCards})
  }

  onShowZone() {
    this.setState((prevState, props)=> ({showZone: !prevState.showZone}));
  }

  onSetView(e) { //map or chart
    this.setState({view: e.target.dataset.view})
  }

  render() {
    let map = <div></div>
    if (this.state.view === 'map' && this.state.maptype === 'realtime') {
      map = <NavMap2d
        key={this.props.siteId + "_realtime"}
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        background_src={WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].background_src : WAREHOUSE['default'].background_src}
        width={this.state.width}
        height={this.state.height}
        pixels_per_meter={this.state.pixels_per_meter}
        cards={this.state.showCards}
        onShowCard={this.onShowCard}
        onLocate={this.onLocate}
        onTDOA={this.onTDOA}
        showZone={this.state.showZone}
        zones={this.state.zones}
        origin={this.state.origin}
      />
    } else if (this.state.view === 'map' && this.state.maptype === 'path') {
      map = <NavMap2dPath
        key={this.props.siteId + "_path"}
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        background_src={WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].background_src : WAREHOUSE['default'].background_src}
        width={this.state.width}
        height={this.state.height}
        pixels_per_meter={this.state.pixels_per_meter}
        from={this.state.from}
        to={this.state.to}
        cards={this.state.showCards}
        onShowCard={this.onShowCard}
        showZone={this.state.showZone}
        zones={this.state.zones}
        origin={this.state.origin}
        z={WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('z') ? WAREHOUSE[this.props.siteId].z : null}
        />
    } else if (this.state.view === 'map' && this.state.maptype === 'heatmap') {
      map = <NavMap2dHeatmap
        key={this.props.siteId + "_heatmap"}
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        background_src={WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].background_src : WAREHOUSE['default'].background_src}
        width={this.state.width}
        height={this.state.height}
        pixels_per_meter={this.state.pixels_per_meter}
        from={this.state.from}
        to={this.state.to}
        showZone={this.state.showZone}
        zones={this.state.zones}
        origin={this.state.origin}
        z={WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('z') ? WAREHOUSE[this.props.siteId].z : null}
      />
    }

    let chart = <div></div>
    if (this.state.view === 'chart') {
      chart = <NavChart2d
                key={this.props.siteId + "_NavChart2d"}
                siteId={this.props.siteId}
                nodes={this.state.worldNodes}
                from={this.state.from}
                to={this.state.to}
                zone_src={WAREHOUSE[this.props.siteId] && WAREHOUSE[this.props.siteId].legend_src ? WAREHOUSE[this.props.siteId].legend_src : null}
              />
    }

    return (
      <div className="Container2D" style={{maxHeight: this.state.view === 'map' ? (this.state.height > 700 ? this.state.height + 55  : 755) : "1620px"}}>
          <NavList2d
            key={this.props.siteId + "_list"}
            display={this.state.display_nodelist}
            siteId={this.props.siteId}
            nodes={this.state.listNodes}
            showNodes={this.state.showNodes}
            onSelect={this.onSelect}
            onLocate={this.onLocate}
            onUnlink={this.onUnlink}
            onTDOA={this.onTDOA}
            onSelectAll={this.onSelectAll}
            onShowCard={this.onShowCard}
            setView={this.onSetView}
            view={this.state.view}
            isAdmin={this.state.isAdmin}
          />
          <div className="Container2DRight">
            <NavControl2d
              key={this.props.siteId + "_control"}
              display={this.state.display_nodelist}
              siteId={this.props.siteId}
              maptype={this.state.maptype}
              setMapType={this.setMapType}
              setFromTo={this.setFromTo}
              from={this.state.from}
              to={this.state.to}
              view={this.state.view}
              showAnchors={this.showAnchors}
              displayAnchors={this.state.showAnchors}
              showZone={this.state.showZone}
              onShowZone={this.onShowZone}
              zone_src={Object.keys(this.state.zones).length > 0 ? true: false}
            />
            {map}
            {chart}
          </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    nodes: getNodesArray(state),
    eventManager: state.eventManager,
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
    userName: state.user.userName,
    userId: state.user.userId,
  }),
)(TrackAssetsPage2D);
