import React, {useState, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import { Tooltip } from 'react-tippy';
import { IMG_CONFIG } from './2dConfig';

function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function formatTime(m) {
  if (m) {
    let d = new Date(m);

    //do date string
    let dateStr = "";
    let today = new Date();
    let midNight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let diff = d.getTime() - midNight.getTime();

    if (diff < 0 ) {
      if (diff < -86400000) {
        dateStr = (d.getMonth() + 1) + "/" + d.getDate() + ", ";
      } else {
        dateStr = "Yesterday, "
      }
    }

    let hour = d.getHours().toString();
    let min = d.getMinutes().toString();;
    let sec = d.getSeconds().toString();;

    if (hour.length === 1 ) { hour = "0" + hour }
    if (min.length === 1) { min = "0" + min }
    if (sec.length === 1) {sec = "0" + sec}

    return dateStr + hour + ":" + min + ":" + sec;
  } else {
    return "00:00:00";
  }
}

/*
function formatBattery(voltage, connected, node) {
  if (!connected && voltage < 3.1) {
    return <img className="Battery" alt="battery" src={require("./img/battery_dead.png")}/>
  } else if (voltage < 3.15) {
      return <img className="Battery" alt="battery" src={require("./img/battery_low.png")}/>
  //} else if (voltage < 3.3) {
  } else if (voltage < 3.273) {
    return <img className="Battery" alt="battery" src={require("./img/battery_half.png")}/>
  } else {
    return ""
  }
}
*/

function formatBattery(node) {
  if (node.current && node.current > -6) {
    return <img className="Battery" alt="battery" src={require("./img/battery_charging2.png")}/>
  }

  switch (node.hardware_version) {
    case 'LEVT1':
      if (node.voltage < 3.1) {
        return <img className="Battery" alt="battery" src={require("./img/battery_dead.png")}/>
      } else if (node.voltage < 3.15) {
          return <img className="Battery" alt="battery" src={require("./img/battery_low.png")}/>
      } else if (node.voltage < 3.273) {
        return <img className="Battery" alt="battery" src={require("./img/battery_half.png")}/>
      } else {
        return "";
      }
    case 'LEVT2':
      if (node.charge < 10) {
        return <img className="Battery" alt="battery" src={require("./img/battery_dead.png")}/>
      } else if (node.charge < 20) {
          return <img className="Battery" alt="battery" src={require("./img/battery_low.png")}/>
      } else if (node.charge < 40) {
        return <img className="Battery" alt="battery" src={require("./img/battery_half.png")}/>
      } else {
        return "";
      }
    default:
      return ""
  }
}

const NavNode = ({node, onSelect, onLocate, onUnlink, isAdmin, checked, disable, onShowCard}) => {
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const previousValue = usePreviousValue(node.timestamp);

  const setShow = (e) => {
    e.stopPropagation();
    let show = !showModal;
    setShowModal(show);
  }

  const unlink = (e) => {
    e.stopPropagation();
    onUnlink(node.id, node.asset);
    setShowModal(false);
  }

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
  }

  useEffect(() => {
    if (previousValue && node.timestamp > previousValue) {
      setRefresh(((node.timestamp - previousValue)/1000).toFixed(1) + " sec")
    }
  }, [previousValue, node.timestamp]);

  return (
    <div className="Nodes" onClick={e => disable? e.stopPropagation() : onShowCard(node.id)}>
      <input type="checkbox" className="CheckBoxAll" checked={checked} onClick={(e)=>e.stopPropagation()} onChange={(e)=>{onSelect(node.id + " " + e.target.checked); e.stopPropagation()}} disabled={disable}/>
      {node.connected ? <div className="ForkliftText">{node.name}</div> : <div className="ForkliftTextDisconnected">{node.name}</div>}
      <Tooltip title={IMG_CONFIG['refresh'].tooltip} theme="transparent">
        <img className="Refresh" alt="refresh" src={IMG_CONFIG['refresh'].img} onClick={(e)=>{e.stopPropagation(); onLocate(node.id)}}/>
      </Tooltip>
      {node.asset && isAdmin &&
        <Tooltip title={IMG_CONFIG['unlink'].tooltip} theme="transparent">
          <img className="Unlink" alt="check-in" src={IMG_CONFIG['unlink'].img} onClick={setShow}/>
        </Tooltip>
      }
      {formatBattery(node)}
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        contentLabel="Check-in Tracker"
        className="Confirm"
      >
        <img className="xImg" alt="close" src={require("./img/x.png")} onClick={closeModal}/>
        <div className="UploadContainer">
          <div>Are you sure you want to check in {node.name}?</div>
            <div className="Text" onClick={unlink}>check-in</div>
            <div className="Text" onClick={closeModal}>cancel</div>
          </div>
      </Modal>
      <div className="Last-Locate-Container">
        {node.asset && <div className="Asset">{node.asset}</div>}
        <div>
          {node.connected ? <div className="Last-Locate">LAST LOCATE</div> : <div className="Disconnected">DISCONNECTED</div>}
          <div className="Last-Locate-Time">{node.timestamp ? formatTime(node.timestamp) : formatTime(node.meas_id)}</div>
        </div>
        <div>
          <div className="Last-Locate">REFRESH RATE</div>
          <div className="Last-Locate-Time">{refresh}</div>
        </div>
      </div>
      <div className="Color" style={{backgroundColor: "#" + node.color}}/>
      <div className="Line2"/>
      </div>
  )
};

export default NavNode;


/*
<div className="PillContainer"><div className="Pill"/>
  <div className="PillText">0 h 00 m</div>
  </div>
//{"name":"Tracker mur215","selected":false,"x":30.48,"y":5.063543162672954,"z":1.2192,"type":"continuous","meas_id":1556562377946,"timestamp":1556562377946,"tracked":false,"battery":3.339,"connected":true}
<div onClick={(e)=>onSelect(node.id + " " + !checked)}>
onloacate method
onselect method

const NavNode = ({dock,
                  selectDock}) => (
      <div>
        <div className="QuickView">QUICK VIEW</div>
        <div className="QuickViewIcon" data-dock={dock} onClick={selectDock}><div className="fa fa-camera"></div></div>
      </div>
);
*/
