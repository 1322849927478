/*ACL Configs and Functions*/

/*
  roles are global
  only these user types should allow a wildcard site in their ACL:
  super-admin, admin, user, customer-admin,customer-user,
*/
export const ROLES = ['super-admin', 'admin', 'customer-admin', 'site-admin', 'customer-user', 'site-full-access', 'site-operator', 'user', 'site-viewer','site-guest'];

export const ACL = {
  //site specific -
  'Setting Menu Button': ['super-admin', 'admin', 'customer-admin', 'customer-user', 'site-admin', 'site-full-access'],
  'Workerlog Link' : ['super-admin', 'admin', 'customer-admin', 'customer-user', 'site-admin', 'site-full-access'],
  'LPS Tracker Checkin Button' : ['super-admin', 'admin', 'customer-admin', 'customer-user', 'site-admin', 'site-full-access', 'site-operator'],
  'LPS Download Button' : ['super-admin', 'admin', 'user', 'customer-admin', 'customer-user', 'site-admin', 'site-full-access', 'site-operator', 'site-viewer'],
  'WMS Upload Button' : ['super-admin', 'admin', 'user', 'customer-admin', 'customer-user', 'site-admin', 'site-full-access', 'site-operator'],
  'WMS Download Button' : ['super-admin', 'admin', 'user', 'customer-admin', 'customer-user', 'site-admin', 'site-full-access', 'site-operator', 'site-viewer'],
  'SmartDock Download Button': ['super-admin', 'admin', 'user', 'customer-admin', 'customer-user', 'site-admin', 'site-full-access', 'site-operator', 'site-viewer'],
  'Add Site' : ['super-admin', 'admin', 'customer-admin'],
  'Edit Site' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'Add User' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'Edit User' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'Delete User' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'Mscs Edit Button' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'HW Config Menu' : ['super-admin', 'admin'],

  //not site specific -
  'Site Management Button' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'Customer Select' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'Site Link' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'User Link' : ['super-admin', 'admin', 'customer-admin', 'site-admin'],
  'Calibration Field' : ['super-admin', 'admin', 'user'],
  'Anchor Outage' : ['super-admin', 'admin', 'user'],
  'Tracking Rate Field': ['super-admin', 'admin']
};

//For UI Display
export const ROLES_LOOKUP = {
  'super-admin' : 'Super Admin',
  'admin' : 'Admin',
  'customer-admin' : 'Customer Admin',
  'site-admin' : 'Site Admin',
  'customer-user' : 'Customer User',
  'site-full-access' : 'Site Full Access',
  'site-operator' : 'Site Operator',
  'user' : 'User',
  'site-viewer' : 'Site Viewer',
  'site-guest' : 'Site Guest',
};

//For help text
export const ROLE_DESC = {
  'super-admin' : '',
  'admin' : '',
  'user' : '',
  'customer-admin' : 'Customer Admins have full access and control of all sites associated with the customer. ',
  'customer-user' : 'Customer Users have full access and control of all sites associated with the customer but cannot manage other users.',
  'site-admin' : 'Site Admins have full access and control of the site including devices and user management. ',
  'site-full-access' : 'Site Full Access have full access to the site including device management. ',
  'site-operator' : 'Site Operators can view the site and download data. ',
  'site-viewer' : 'Site Viewers can view the site and download data.',
  'site-guest' : 'Site Guests can view the site.',
};

//Calculate whether I am an customer admin or site admin
export function myHighestRole(acl) {
  let role = 1000;
  acl.forEach( acl => {
    if (ROLES.indexOf(acl[2]) < role) {
      role = ROLES.indexOf(acl[2]);
    }
  })
  return ROLES[role];
}

//Not site specific Site/Users Management components
export function checkACL(acl, component) {
  let role = 1000;
  acl.forEach( acl => {
    if (ROLES.indexOf(acl[2]) < role) {
      role = ROLES.indexOf(acl[2]);
    }
  })
  //console.log("checkACL" + component + " " + ROLES[role]);
  return ACL[component].indexOf(ROLES[role]) > -1 ? true : false;
}

//Site specific - buttons and links
export function checkSiteACL(customer, site, acl, component) {
  //console.log("check site ACL " + customer + " " + site + " " + JSON.stringify(acl))

  let role1 = 1000; //*,*, role1
  let role2 = 1000; //customer, *, role2
  let role3 = 1000; //customer, site, role3
  acl.forEach( acl => {
    if (acl[0] === '*' && acl[1] === '*' && ROLES.indexOf(acl[2]) < role1) {
      role1 = ROLES.indexOf(acl[2]);
    }

    if (acl[0] === customer && acl[1] === '*' && ROLES.indexOf(acl[2]) < role2) {
      role2 = ROLES.indexOf(acl[2]);
    }

    if (acl[1] === site && ROLES.indexOf(acl[2]) < role3) {
      role3 = ROLES.indexOf(acl[2]);
    }
  });

  if (role3 < 1000) { // if there's a role for the site - it always wins
    return ACL[component].indexOf(ROLES[role3]) > -1 ? true : false;
  }

  if (role2 < 1000) { // customer role 2nd
    return ACL[component].indexOf(ROLES[role2]) > -1 ? true : false;
  }

  if (role1 < 1000) { //super role wins next
    return ACL[component].indexOf(ROLES[role1]) > -1 ? true : false;
  }
}

  //for site options in adduser/edit user
  export function makeSiteMenu(sites, myRole) {
    let siteMenu = sites.map(site => ({label: site.site_name , value: site.site}));
    siteMenu.sort((a,b) => a.label.localeCompare(b.label));

    //if I"m an admin, add wildcard site -  *
    if (['super-admin', 'admin', 'customer-admin'].indexOf(myRole) > -1) {
      siteMenu.unshift({label: "All Sites", value: "*"})
    }
    return siteMenu;
  }

 //if site is a wildcard then customer-admin and customer user only
 //if normal site then site -admin, site full access, site operator, site view, and site guest only
 //filter by my role
 export function makeUserRoleMenu(site, myRole) {
   let ORDER = [];
   if (site === '*' || site === 'All Sites') {
     ORDER = ['user', 'customer-admin', 'customer-user'];
   } else {
     ORDER = ['site-admin', 'site-full-access', 'site-operator', 'site-viewer','site-guest'];
   }

   //special case the admins
   let start = ORDER.indexOf(myRole);
   if (start < 0) { start = 0}

   return ORDER.slice(start).map(role => ({label: ROLES_LOOKUP[role], value: role}))
 }
