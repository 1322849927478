import React from 'react';
import {Line} from 'react-chartjs-2';

//Make Stateless Functional Component
const DockDetailChart = ({dock,
                          labels,
                          dock_occupied,
                          interior_activity,
                          interior_motion,
                          ext_camera_events,
                          int_camera_events,
                          min,
                          max,
                        }) => {
  let data = {
    labels: labels,
    datasets: [
    ]
  };

  if (interior_activity) {
    data.datasets.push ({
        label: "INTERIOR ACTIVITY",
        fill: true,
        lineTension: 0.1,
        backgroundColor: "rgba(95, 158, 160, .5)", //"#5F9EA0"
        borderColor: "rgba(95, 158, 160, 0)",
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: "rgba(95, 158, 160, .5)",
        pointBackgroundColor: "rgba(95, 158, 160, .5)",
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(95, 158, 160, .5)",
        pointHoverBorderColor: "rgba(95, 158, 160, .5)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: interior_activity,
        steppedLine: false,
      }
    )
  };

  if (dock_occupied) {
    data.datasets.push(
      {
        label: "OCCUPIED",
        fill: true,
        lineTension: 0.1,
        backgroundColor: "#b6e9fe",//'rgba(111, 187, 28, 0.5)',
        borderColor:  "#b6e9fe",
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: "#b6e9fe",
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#b6e9fe",
        pointHoverBorderColor: "#b6e9fe",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: dock_occupied,
        steppedLine: true,
      }
    )
  };

  if (interior_motion) {
    data.datasets.push ({
        label: "INTERIOR MOTION",
        fill: true,
        lineTension: 0.1,
        backgroundColor: "rgba(55, 160, 164, 0.2)", //"#5F9EA0"
        borderColor:  "#0bced6",
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: "rgba(55, 160, 164, 0.2)",
        pointBackgroundColor: "rgba(95, 158, 160, .5)",
        pointBorderWidth: 0,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(55, 160, 164, 0.2)",
        pointHoverBorderColor: "rgba(55, 160, 164, 0.2)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: interior_motion,
        steppedLine: false,
      }
    )
  };

  if (ext_camera_events) {
    data.datasets.push({
        label: "EXTERIOR CAMERA",
        fill: true,
        lineTension: 0.1,
        backgroundColor: "#4489e2",//'rgba(111, 187, 28, 0.5)',
        borderColor:  "#4489e2",
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: "#4489e2",
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#4489e2",
        pointHoverBorderColor: "#4489e2",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: ext_camera_events,
        steppedLine: true,
      }
    )
  };

  if (int_camera_events) {
    data.datasets.push({
        label: "INTERIOR CAMERA",
        fill: true,
        lineTension: 0.1,
        backgroundColor: "#00b367",//'rgba(111, 187, 28, 0.5)',
        borderColor:  "#00b367",
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: "#00b367",
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#00b367",
        pointHoverBorderColor: "#00b367",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: int_camera_events,
        steppedLine: true,
      }
    )
  };

  const options = {
    maintainAspectRatio: false,
    legend: {position: 'top'},
    hover: {mode: null},
    tooltips: {
        enabled: false
    },
    scales: {
        yAxes: [{
            ticks: {
                display: false,
                min: 0,
                max: 1,
            }
        }],
        xAxes: [{
              type: 'time',
              ticks: {
                min: min,
                max: max,
              },
              time: {
                stepSize: 2,
                displayFormats: {
                  hour: 'M/D HH:mm',
                }
              }
            }],
    }
  };

  return (
      <div className="DockChartContainer"><Line data={data} options={options}/></div>
   );
}

export default DockDetailChart;

//width: interior_motion? "1315px" : "1330px"
