import React, { useState, useEffect } from 'react';

//{"node":"49fa708d1ca94e1eb9d661e7ea5f0031","name":"mur2bd","x":22.780752,"y":1.161288,"z":1.8288000000000002}
const LpsMenuAnchor = (props) => {
  const [openAnchor, setOpenAnchor] = useState(false);
  const [anchorConfig, setAnchorConfig] = useState(props.anchor);

  useEffect(()=>{
    if (props.anchor.node === props.selected_anchor) {
      setOpenAnchor(true);
    } else {
      setOpenAnchor(false);
    }
  }, [props.selected_anchor, props.anchor.node])

  const editAnchor = (e) => {
    let temp = !openAnchor;
    setOpenAnchor(temp);

    if (temp) {
      props.selectAnchor(anchorConfig)
    } else {
      props.selectAnchor(null);
    }
  }

  const updateField = (field, e) => {
    //do error checking here
    if (e.target.value.trim() === '') { return; }
    if (field !== 'name' && isNaN(e.target.value)) { return; }

    let config = {...anchorConfig};
    config[field] = e.target.value;
    setAnchorConfig(config);
    props.selectAnchor(config);
  }

  const saveAnchor = (e) => {
    e.stopPropagation();
    props.saveAnchor(anchorConfig);
    setOpenAnchor(false);
  }

  const deleteAnchor = (e) => {
    e.stopPropagation();
    props.deleteAnchor(anchorConfig);
    setOpenAnchor(false);
  }

  return (
    <div>
      <div className="lps_menu_anchor" style={{background: openAnchor ? "white" : "", color: openAnchor? "#1b5faa" : ""}}>
        {props.anchor.name}
        <img className="edit" alt="Edit Anchor" src={openAnchor? require('../2dReact/img/x.png') : require('../2dReact/img/edit.png')} data-node={props.anchor.node} onClick={(e)=>{editAnchor(e)}}/>
      </div>
      <div style={{display: openAnchor ? "block" : "none"}}>
        <div className="lps_line"/>
        <div className="anchor_param">NAME<input type="text" defaultValue={props.anchor.name} onChange={(e)=>{updateField('name', e)}}/></div>
        <div className="anchor_param">X (METER)<input type="text" value={props.anchor.x} onChange={(e)=>{updateField('x', e)}}/></div>
        <div className="anchor_param">Y (METER)<input type="text" value={props.anchor.y} onChange={(e)=>{updateField('y', e)}}/></div>
        <div className="anchor_param">Z (METER)<input type="text" value={props.anchor.z} onChange={(e)=>{updateField('z', e)}}/></div>
        <div className="anchor_param">
          <button onClick={(e)=>{saveAnchor(e)}}>SAVE</button>
          <div className="delete" style={{display: "inline-block"}}><button onClick={(e)=>{deleteAnchor(e)}}/></div>
        </div>
      </div>
      <div className="lps_line"/>
    </div>
  )
}

export default LpsMenuAnchor;
