import React from 'react';
import WorldReact from './3dReact/src/WorldReact.js';

export class NavMap extends React.Component {
    constructor(props) {
        super(props);

        this.clearSelectCanvas = this.clearSelectCanvas.bind(this);
        this.drawSelectedDialog = this.drawSelectedDialog.bind(this);

        //const FEET_TO_METERS = 0.3048;

        /* locix default
        anchor101: {x: 0, y: 16.85, z: 4.56},
        anchor103: {x: 54.7, y: 0.6, z: 4.56},
        anchor105: {x: 98.5, y: 13.3, z: 4.56},
        anchor111: {x: 47.4, y: 35.1, z: 4.56},
        anchor112: {x: 39.5, y: 1, z: 4.56},
        anchor115: {x: 65.1, y: 26, z: 4.56},
        anchor117: {x: 90.07, y: 36.1, z: 4.56},

        /* venetian anchors
        anchor111: {x: 	49.77896, y: 20.41358, z: 4.56 },
        anchor113: {x: 	50.11896, y: 33.89258, z: 4.56 },
        anchor103: {x: 33.77563, y: 16.39458, z: 4.56},
        anchor112: {x: 	19.77296, y: 18.27458, z: 4.56},
        anchor115: {x: 35.99896, y: 32.06258, z: 4.56 },
        anchor117: {x: 	4.928958,	y: 3.729583, z: 4.56},
        anchor106: {x: 18.77296,	y: 33.89258, z:4.56},
        */

        /* daikin final anchors
        anchor003: {x: 	60.53, y: 29.53, z: 9.00 },
        anchor006: {x: 	54.34, y: 61.35, z: 9.00 },
        anchor011: {x: 	3.42, y: 75.88, z: 9.00 },
        anchor012: {x:  39.64,  y: 47.99, z: 9.00 },
        anchor013: {x: 	38.41, y: 75.62, z: 9.00 },
        anchor015: {x: 	46.75, y: 3.92, z: 9.00 },
        anchor017: {x: 	16.73,  y: 4.00, z: 9.00 },
        anchor009: {x: 	3.92, y: 46.57, z: 9.00 },

        /*IQT 6 anchors
            -0.129	0.273	1
            11.697	0.221	1
            15.374	2.481	1
            17.496	5.464	1
            12.094	7.567	1
            5.257	9.495	1
        */

        /*const anchors = {
          anchor101: {x: -0.129, y: 0.273, z: 1},
          anchor102: {x: 11.697, y: 0.221, z: 1},
          anchor103: {x: 15.374, y: 2.481, z: 1},
          anchor104: {x: 17.496, y: 5.464, z: 1},
          anchor105: {x: 12.094, y: 7.567, z: 1},
          anchor106: {x: 5.257, y: 9.567, z: 1},
        };*/

        /*for (const anchor of Object.values(anchors)) {
          anchor.x *= FEET_TO_METERS;
          anchor.y *= FEET_TO_METERS;
          anchor.z *= FEET_TO_METERS;
        }*/

        // this.setState({
        //     anchors: anchors
        // });

        this.state = {
            nodes: this.props.nodes,
            anchors: this.props.anchors,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
          this.setState({
            nodes: nextProps.nodes,
            anchors: nextProps.anchors
          });
        }
    }

    componentDidMount() {
        this.clearSelectCanvas();
    }

    componentDidUpdate() {
        this.clearSelectCanvas();
    }

    drawSelectedDialog() {
        // var selCanvas = document.getElementById('select-info-canvas');
        // var context = selCanvas.getContext('2d');
    }

    clearSelectCanvas() {
        // Clear out any drawings on the dialog canvas overlay
        var selCanvas = document.getElementById('select-info-canvas');
        var context = selCanvas.getContext('2d');
        context.clearRect(0, 0, selCanvas.width, selCanvas.height);
    }

    handleNodeClick(id, mouseEvent) {
        console.log("NODE CLICKED!");
        // var node = this.state.nodes[id];

        this.props.onNodeSelect('nodeMap', id);
    }

    render() {
        const nodes = this.state.nodes;
        console.log("Re-rendering map with nodes: ", nodes);
        const anchors = this.state.anchors;
        console.log("Re-rendering map with anchors: ", anchors);

        let siteModel = "/" + this.props.siteId + ".dae"
        //fcb03682e3534ce98bbbc9f32716fe2f.dae is lps office demo. Default to locix.dae if it's not lps
        if (this.props.siteId !== 'fcb03682e3534ce98bbbc9f32716fe2f' && this.props.siteId !== 'e2983e19bf8a4b349f430e883b9f2352' &&
            this.props.siteId !== '9d5d9e9f973048118c81f2ac0c5fa838') {
          siteModel = "/locix.dae"
        }

        return <WorldReact
          key={this.props.siteId}
          parentId="dashboard-content"
          nodes={nodes}
          anchors={anchors}
          session={this.props.session}
          siteModel={siteModel}
          className="asset-site-map"
          onNodeClick={(id, event) => this.handleNodeClick(id, event)}
        />
    }
}
