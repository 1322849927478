import React from 'react';
import './AnalyticsPage.css';
import RefreshImage from './RefreshImage'


const AnalyticsTable = (props) => (

  <div className="item2-content">
    <div className="item2-content-header">BAY-{props.bay}
      {
        props.warning ? <div className="item2-status-icon"><div className="fa fa-warning"/></div> : <div></div>
      }
    </div>
    {
      props.bay === "1" ?
        (props.node.events.analytics && props.node.events.analytics.trucks.bay_0 ? <div className="item2-status-occupied"><div className="item2-status-text">OCCUPIED</div></div> : <div className="item2-status-empty"><div className="item2-status-text">EMPTY</div></div>) :
        (props.node.events.analytics && props.node.events.analytics.trucks.bay_1 ? <div className="item2-status-occupied"><div className="item2-status-text">OCCUPIED</div></div> : <div className="item2-status-empty"><div className="item2-status-text">EMPTY</div></div>)
    }
    <div className="item2-status-content-container">
    <div className="item2-status-key-value">
      <div className="item2-status-key">TIME SINCE LAST CHANGE</div>
      <div className="item2-status-value">{props.last_state_interval}</div>
    </div>
    <div className="item2-status-key-value">
      <div className="item2-status-key">AVERAGE DURATION</div>
      <div className="item2-status-value">{props.avg}</div>
    </div>
    <div className="item2-status-key-value">
      <div className="item2-status-key">SHORTEST STAY</div>
      <div className="item2-status-value">{props.min}</div>
    </div>
    <div className="item2-status-key-value">
      <div className="item2-status-key">LONGEST STAY</div>
      <div className="item2-status-value">{props.max}</div>
    </div>
    <div className="item2-status-key-value">
      <div className="item2-status-key">TIME OCCUPIED</div>
      <div className="item2-status-value">{props.total}</div>
    </div>
    <div className="item2-status-key-value">
      <div className="item2-status-key">TIME UNOCCUPIED</div>
      <div className="item2-status-value">{props.time_unoccupied}</div>
    </div>
    <div className="item2-status-key-value">
      <div className="item2-status-key">% OCCUPIED</div>
      <div className="item2-status-value">{props.percent_occupied}%</div>
    </div>
      <RefreshImage id={props.node.node}/>
    </div>
    </div>
);

export default AnalyticsTable;

/*
<div className="item2-status-key-value">
  <div className="item2-status-key">EFFICIENCY</div>
  <div className="item2-status-value">??</div>
</div>
*/
