//ChartUtil.js

export function convertSiteTime(mill) {
  if (mill === 0) { return "0m"}
  if (mill < 60000) { return parseInt(mill / 1000, 10) + "s"}

  let hours = null;
  let mins = null;

  if (mill > 3600000) { //hour
    hours = Math.floor(mill/3600000);
    mill = mill % 3600000;
  }

  if (mill > 60000) { mins = Math.floor(mill/60000)}

  let strr = [];
  if (hours != null ) {
    strr.push(hours + "h");
  }

  if (mins != null ) {
    strr.push(mins + "m")
  }
  return strr.join(" ");
}

export function convertSiteTimeMinSec(mill) {
  if (mill === 0) { return "0m"}

  let hours = null;
  let mins = null;
  let secs = null;

  if (mill > 3600000) { //hour
    hours = Math.floor(mill/3600000);
    mill = mill % 3600000;
  }

  if (mill > 60000) {
    mins = Math.floor(mill/60000)
    mill = mill % 60000;
  }

  if (mill > 0) {
    secs = parseInt(Math.floor(mill /1000), 10);
  }

  let strr = [];
  if (hours != null ) {
    strr.push(hours + "h");
  }

  if (mins != null ) {
    strr.push(mins + "m")
  }

  if (secs !== null && secs) {
    strr.push(secs + "s")
  }

  return strr.join(" ");
}

export function convertReportTime(mill) {
  if (mill === null || mill === undefined) {
    return "00:00:00";
  }

  let hours = null;
  let mins = null;
  let secs = null;

  if (mill >= 3600000) { //hour
    hours = Math.floor(mill/3600000);
    mill = mill % 3600000;
  } else {
    hours = 0;
  }

  if (mill >= 60000) {
    mins = Math.floor(mill/60000);
    mill = mill % 60000;
  } else {
    mins = 0;
  }

  if (mill >= 0) {
    secs = parseInt(Math.floor(mill /1000), 10);
  } else {
    secs = 0;
  }

  return hours.toString() + ":" + mins.toString() + ":" + secs.toString();
}

export function formatTime(mill) {
  let start = new Date(mill);
  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  return start_hour + ":" + start_min
}

//shoule be name X Axis
export function makeYAxis(from, to) {
  let y_axis = [];
  let duration = (to - from) / 10;
  for (let i = 0; i <= 10; i++) { //make 11 elements
    y_axis.push(formatTime(from + (duration * i)))
  }

  return y_axis;
}

export function makeDistanceXAxis(distance) {
  let x_axis = [];
  let duration = distance/ 10;
  let i = 0;
  while (i <= 10 ) {
    x_axis.push(Math.round(i * duration) + " m");
    i += 1;
  }

  return x_axis;
}

export function addCommas(number) {
  let nStr = number.toString();
  var x = nStr.split('.'); //if there's decimal
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
          x1 = x1.replace(rgx, "$1,$2");
  }
  return x1 + x2;
}

export function formatTextExcel(text) {
  return "\"=\"\"" + text + "\"\"\""
}
