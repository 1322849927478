//export const ADMIN = ['barbara', 'matt', 'jeff', 'minjoo', 'jenna', 'manu', 'dave', 'tommi', 'aditya', 'mark', 'pat', 'suchitra', 'nithin', 'piyush'];

export const WAREHOUSE = {
  'default' : { //ichikawa test 23.09m wide 10.73 height
    background_src: 'Locix_Office_Furniture.png', //1055 x 492
    big: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    small: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    group_type: [],
    tracker_type: ['phone', 'tdoa_tracker', 'worker'],
    origin: {x: 0, y: 0}, //in meters*/
  },
  '2a9642e57d38453bb401d2571825af1d' : { //Ichikawa Japan
    background_src: '2F_Full.png',
    small: {
      width: 1122,
      height:  656,
      pixels_per_meter: 7.06,
    },
    origin: {x: 53.1, y: .2}, //in meters
    legend_src: 'Ichikawa_legend.png',
    group_type: ['Floor 1', 'Floor 2'],
    tracker_type: ['forklift', 'worker'],
  },
  'bf7d3aba38dd4f54bdc493b9d89539ed' : { //ichikawa test 23.09m wide 10.73 height
    background_src: 'Locix_Office_Furniture.png', //1055 x 492
    big: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    small: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: 0, y: 0}, //in meters
  },
  'e2983e19bf8a4b349f430e883b9f2352' : { //Proplogis LPS: 214.87 feet = 65.49 meters,  width -  59.17 feet = 18.03 meters height
    background_src: 'Prologis.png',
    big: {
      width: 1055,
      height: 300,
      pixels_per_meter: 16.066,
    },
    small: {
      width: 1055,
      height: 300,
      pixels_per_meter: 16.066,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 65.3554089381, y: 0.4357027263}, //in meters
  },
  '9d5d9e9f973048118c81f2ac0c5fa838' : { //daiken japan
    background_src: 'DaikinTIC-2d.png',
    big: {
      width: 1360,
      height: 430,
      pixels_per_meter: 23.08,
    },
    small: {
      width: 1000,
      height: 316,
      pixels_per_meter: 16.97,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 0, y: 0}, //in meters
  },
  '2f007bf9c94d4e6c99d0aae751de351f' : {
    background_src: 'YEDHQ-GUIbackground-S1.png',
    big: {
      width: 615,
      height: 725,
      pixels_per_meter: 48.61,
    },
    small: {
      width: 615,
      height: 725,
      pixels_per_meter: 48.61,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: .48, y: .49}, //in meters
  },
  'df0389eb7fb647f2921077b69288ded5' : { //K & N POC
    background_src: 'KuehneNagelSingapore.png',
    big: {
      width: 1055,
      height: 976,
      pixels_per_meter: 11.75,
    },
    small: {
      width: 1055,
      height:  976,
      pixels_per_meter: 11.75,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 0.2627737226 , y: 0.08759124088}, //in meters
  },
 'dd6ebee548c941a0a15b070f2c5826d5' : { //MSCS Malaysia
    background_src: 'BangiExpansion_Mezztop.png',
    big: {
      width: 1055,
      height: 520,
      pixels_per_meter:  3.9,
    },
    small: {
      width: 1055,
      height: 520,
      pixels_per_meter: 3.9,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: 104.87179, y: 46.66667}, //in meters
    z: {zone_min: 7.6, anchor_min: 11, tracker_min: 11.17, y_offset: 225}, //feet - need to use feet b/c x,y is in feet, y_offset is relative position in meters
  },
  'ed3f28af18564fada8dff20e4ee15631' : { //Mist
    background_src: 'mist_demo2.png',
    big: {
      width: 815,
      height: 725,
      pixels_per_meter: 48.84,
    },
    small: {
      width: 815,
      height: 725,
      pixels_per_meter: 48.84,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: .164, y: .369}, //in meters
  },
  'b5fcb9a07a684160b750e07bd5b36eef' : {
    background_src: 'Uniqlomap.png',
    big: {
      width: 883,
      height: 735,
      pixels_per_meter: 11.92,
    },
    small: {
      width: 883,
      height: 735,
      pixels_per_meter: 11.92,
    },
    group_type: ['Floor 2'],
    tracker_type: ['worker'],
    origin: {x: 4.6977, y: .25167}, //in meters
  },
  '51fe12cd8d1546279d82230c6da07f0d' : { //Daikin - Point Zero
    background_src: 'Daikin_ZeroPoint.png',
    big: {
      width: 625,
      height: 725,
      pixels_per_meter: 20.0719,
    },
    small: {
      width: 625,
      height: 725,
      pixels_per_meter: 20.0719,
    },
    group_type: ['DK', 'P0', 'OKM', 'PA', 'MC', 'MEC', 'TOA', 'SMZ', 'IS', 'MS', 'KJM', 'SB'],
    tracker_type: ['worker'],
    origin: {x: .2491, y: .2491}, //in meters
  },
  '3988d5b4a50f4c0f9c5bc53dd80e4ea1' : { //Daikin DSV
    background_src: 'DSV2D.jpg',
    big: {
      width: 681,
      height: 839,
      pixels_per_meter: 33.4645,
    },
    small: {
      width: 681,
      height: 839,
      pixels_per_meter: 33.4645,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: 1.165 , y: 1.105}, //in meters
  },
  '372860a9fd6b48d88f1dedacacfcacb1' : { //Daikin DSV TDOA
    group_type: [],
    tracker_type: ['phone'],
    background_src: 'DSV2D_tdoa.jpg',
    small: {
      width: 681,
      height: 839,
      pixels_per_meter: 33.4645,
    },
    origin: {x: 1.165 , y: 1.105}, //in meters
  },
  'd954674dabd7411e812759b0edf0a4e5' : { //ABInBevTulsa
    background_src: 'Tulsa.png',
    big: {
      width: 1055,
      height: 797,
      pixels_per_meter: 9.870587707,
    },
    small: {
      width: 1055,
      height: 797,
      pixels_per_meter: 9.870587707,
    },
    group_type: [],
    tracker_type: ['forklift', 'worker'],
    origin: {x: .2 , y: .2}, //in meters
  },

  'f064e086e6574b8cb7da0831cb40e05b' : { //BML
    background_src: 'BML.png',
    big: {
      width: 844,
      height: 379,
      pixels_per_meter: 18.6041,
    },
    small: {
      width: 844,
      height: 379,
      pixels_per_meter: 18.6041,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: 1.129, y: 0.806}, //in meters
  },
  '7dc5005560e0477f93c5a5e0e0dd7221' : { //SCSK
    background_src: 'SCSK.png',
    big: {
      width: 339,
      height: 555,
      pixels_per_meter: 30.0562,
    },
    small: {
      width: 339,
      height: 555,
      pixels_per_meter: 30.0562,
    },
    group_type: [],
    tracker_type: ['worker'],
    origin: {x: 0.3327, y: 0.3992}, //in meters
  },
  '05c2da1ffe9247bc96585321c5146d36' : { //Gu Kobe/Fast Retailing
    group_type: [],
    tracker_type: ['worker'],
    background_src: 'MGLKobe4F.png',
    small: {
      width: 371,
      height: 726,
      pixels_per_meter: 7.55981,
    },
    origin: {x: .3968, y: .6614}, //in meters
  },
  '96cc946ca4ba419590dd3e5767e3e76b' : { //Kraft Heinz
    group_type: [],
    tracker_type: ['forklift'],
    background_src: 'KHA-POC.png',
    small: {
      width: 416,
      height: 1250,
      pixels_per_meter: 8.7364, //went with the x pixels_per_meter
    },
    origin: {x: -198.3, y: .5381}
  },
  'da9972bc80e64945bfc96076b1e84edc' : {
    group_type: [],
    tracker_type: ['phone', 'tdoa_tracker', 'worker'],
    origin: {x: 0, y: 0},
    background_src: 'Locix_Office_Furniture.png', //1055 x 492
    small: {
      width: 1055,
      height: 492,
      pixels_per_meter: 45.7
    }
  },
  '481fd6485d4c499ca97b25016fa9800b' : { //Nippon Express
    group_type: [],
    tracker_type: ['worker'],
    background_src: 'NEUPhillipsburgPOCareaDBWfeet_px18_741_0.6906077.png',
    small: {
      width: 518,
      height: 764,
      pixels_per_meter: 4.75066,
    },
    origin: {x: 3.7889, y: 4.8414}
  },
  '88748292ee864d7490bc3e9409de4c06' : { //JR Central
    group_type: [],
    tracker_type: ['worker'],
    background_src: 'JRCentralNagoyaStationPOCFeet_px215_389_0.1447429.png',
    small: {
        width: 791,
        height: 570,
        pixels_per_meter:  22.6667,
    },
    origin: {x: 9.4852, y: 7.9852} //meter
  },
}
