import React from 'react';
import { Table, Column, Cell} from 'fixed-data-table-2';
import { addCommas, convertSiteTime } from './ChartUtil';
import Sort from '../../common/Sort';
import {IMG_CONFIG} from '../2dConfig';

const METERS_PER_FOOT = 0.3048;

class Metric extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      sortedColumnIndex: 0,
    };

    this.setSortedData = this.setSortedData.bind(this);
  }

  componentDidMount() {
    this.makeTableData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sorted_nodes.length !== this.props.sorted_nodes.length ||
        Object.keys(prevProps.tracker_stats).length !== Object.keys(this.props.tracker_stats).length ||
        prevProps.from !== this.props.from || prevProps.to !== this.props.to ||
        prevProps.expand !== this.props.expand)
    {
      this.makeTableData();
    }
  }

  componentWillUnmount() {
    //console.log("unmounted")
  }

  makeTableData() {
    let data = [];
    this.props.sorted_nodes.forEach(n => {
      let d = {tracker: null, distance: null, active_time: null, activity: null};
      d['tracker'] = n.name;
      if (this.props.tracker_stats && this.props.tracker_stats.hasOwnProperty(n.id)) {
        let stats = this.props.tracker_stats[n.id];
        d['distance'] = (stats.distance * METERS_PER_FOOT).toFixed(2);
        d['active_time'] = ((this.props.to - this.props.from) * stats.active_time).toFixed(2);
        d['activity'] = (stats.active_time * 100).toFixed(2);
      }

      data.push(d);
    })
    this.setState({tableData: data});
  }

  setSortedData(columnIndex, sortedData) {
    //console.log(columnIndex + " - " + JSON.stringify(sortedData));
    this.setState({sortedColumnIndex: columnIndex, tableData: sortedData});
  }

  render() {
    return (
      <div className="SiteChart">
      {this.props.expand ?
        <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
        <img className="ExpandIcon" alt="expand" data-chart="metric" src={require("../img/expand.png")} onClick={this.props.setChartType} />
      }
        <div className="SiteHeader">METRICS
          {
            this.props.displayDownload &&
            <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="lps_metric" data-type={this.state.type} onClick={this.props.setDownloadType}/>
          }
        </div>

        <div id="lps_metric" className="SiteChartContainer">
          <Table className="MetricTable"
            rowHeight={35}
            headerHeight={35}
            rowsCount={Object.keys(this.props.sorted_nodes).length}
            width={532}
            height={Object.keys(this.props.sorted_nodes).length * 35 + 40} //210
          >
            <Column columnKey='tracker'
                    header={
                            <Cell className="MetricHeader">TRACKER
                              <Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} />
                            </Cell>
                           }
                    cell={ props => (
                      <Cell className="MetricCell" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].tracker ? this.state.tableData[props.rowIndex].tracker : "-"}
                      </Cell>
                    )}
                    width={133}
            />
            <Column columnKey='distance'
                    header={
                      <Cell className="MetricHeader">DISTANCE (M)
                        <Sort thisColumnIndex={1} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} data={this.state.tableData}  setSortedData={this.setSortedData}/>
                      </Cell>
                    }
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].distance? addCommas(this.state.tableData[props.rowIndex].distance) : "-"}
                      </Cell>
                    )}
                    width={133}
            />
            <Column columnKey='active_time'
                    header={<Cell className="MetricHeader">ACTIVE TIME<Sort thisColumnIndex={2} thisDataType={"float"}  columnIndexToSort={this.state.sortedColumnIndex} data={this.state.tableData}  setSortedData={this.setSortedData}/></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].active_time ? convertSiteTime(this.state.tableData[props.rowIndex].active_time) : "-"}
                      </Cell>
                    )}
                    width={133}
            />
            <Column columnKey='activity'
                    header={<Cell className="MetricHeader">ACTIVITY (%)<Sort thisColumnIndex={3} thisDataType={"float"}  columnIndexToSort={this.state.sortedColumnIndex} data={this.state.tableData}  setSortedData={this.setSortedData}/></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].activity ? this.state.tableData[props.rowIndex].activity : "-"}
                      </Cell>
                    )}
                    width={133}
            />
          </Table>
        </div>
      </div>
    )
  }
}

export default Metric;
