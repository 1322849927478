import React from 'react';
import Select from 'react-select';

const style = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: base => ({
    ...base,
  }),
  valueContainer: base =>({
    ...base,
  }),
  indicatorsContainer: base => ({
    ...base,
    maxHeight: "38px",
  }),
};

const MultiSelectOptions = (props) => {
  return (
     < Select
        isMulti
        value={props.values}
        defaultValue={props.values}
        options={props.options}
        styles={style}
        onChange={e => props.updateField(props.field, e, 'multiselect')}
      />
)};


export default MultiSelectOptions;
