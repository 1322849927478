import { LocixEventManager } from '../LocixEventManager.js';
import { fetchNodes, refreshNode, fetchMediaBlob } from './nodes';
import { fetchDocks, refreshDock } from './docks';
import { fetchPendingNodes } from './pendingNodes';
import { fetchHubs, refreshHub } from './hubs';
import { fetchConstellations } from './constellations';

Window.eventManagers = {};

export const NEW_MANAGER = 'websockets/NEW_MANAGER';

const initialState = null

// ==========================
// ==      The Reducer     ==
// ==========================
export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_MANAGER:
      if (state && state.hasOwnProperty('disconnect')) {
        state.disconnect();
      }
      return action.payload;
    case 'LOGOUT':
      if (state && state.hasOwnProperty('disconnect')) {
        state.disconnect();
      }
      return null
    default:
      return state;
  }
}

export function managerHistory(state = [], action) {
  switch (action.type) {
    case NEW_MANAGER:
      return [...state, action.payload];
    default:
      return state;
  }
}

// ==========================
// ==      Selectors       ==
// ==========================
export const getEventManager = function(state) {

}

// ==========================
// ==   Action Creators    ==
// ==========================
export function loadNewManager(eventManager) {
  return { type: NEW_MANAGER, payload: eventManager };
}

export function logoutManager(eventManager) {
  console.log("Expired token - I am logging out");
  return { type: 'LOGOUT', payload: eventManager };
}

export function createEventManager(siteId) {
  return (dispatch, getState) => {
    const { eventManager, sites, authToken } = getState();
    const customerId = sites.currentSite.customer;
    if (eventManager && eventManager.hasOwnProperty('disconnect')) {
      console.log("disconnecting");
      eventManager.disconnect();
    } else {
      console.log("didn't disconnect for some reason");
    }

    dispatch(fetchNodes());
    dispatch(fetchPendingNodes());
    dispatch(fetchHubs());
    dispatch(fetchConstellations());
    dispatch(fetchDocks());

    let newEventManager = new LocixEventManager(siteId, customerId, authToken, [`sites/${siteId}`]);
    newEventManager.connect();
    dispatch(loadNewManager(newEventManager));
    dispatch(addNodeEventListeners());
    dispatch(addHubEventListener());
    dispatch(addSocketEventListener());
    //console.log("Websockets.js state -  " + JSON.stringify(getState()));
  }
}

// ===========================
// == Async Action Creators ==
// ===========================
export function addNodeEventListeners() {
  console.log("node event listener added");
  return (dispatch, getState) => {
    const { eventManager } = getState();
    eventManager.addEventListener(
      'media_ready', { receiveWsEvent: (e) => dispatch(receiveNodeEvent(e)) }
    );
    eventManager.addEventListener(
      'temperature', { receiveWsEvent: (e) => dispatch(receiveNodeEvent(e)) }
    );
    eventManager.addEventListener(
      'humidity', { receiveWsEvent: (e) => dispatch(receiveNodeEvent(e)) }
    );
  }
}

export function addHubEventListener() {
  console.log("hub event listener added");
  return (dispatch, getState) => {
    const { eventManager } = getState();
    eventManager.addEventListener(
      'hub', { receiveWsEvent: (e) => dispatch(receiveHubEvent(e)) }
    );
  }
}

export function addSocketEventListener() {
  console.log("socket event listener added");
  return (dispatch, getState) => {
    const { eventManager } = getState();
    eventManager.addEventListener(
      'expired_token', { receiveWsEvent: (e) => dispatch( logoutManager(e)) }
    );
  }
}

export function receiveNodeEvent(e) {
  return (dispatch, getState) => {
    /* if (e.type === 'node' && e.update.events && ((e.update.events.system && e.update.events.system.metrics) || e.update.events.radio || e.update.events.anchor || e.update.events.hub || e.update.events.hub_client)) {
      return;
    }*/

    if (e.source_type === 'location_updater' && getState().nodes.byId.hasOwnProperty(e.id) &&
        getState().nodes.byId[e.id].events.tracker &&
        e.update.events.tracker.location.timestamp < getState().nodes.byId[e.id].events.tracker.location.timestamp) {
        //console.log("Event is in the past - Node: " + e.id + " Meas Id:" + theLoc.meas_id + " Time: " + theLoc.timestamp +
        //            " Latest Event Time: " + reduxLoc.timestamp);
        console.log("Event is in the past - Node: " + e.id)
        return;
    }

    if (e.update.events && e.update.events.hasOwnProperty('camera') && e.update.events.camera.hasOwnProperty('media_ready')
      && e.source_type !== 'transcoder') {
      dispatch(fetchMediaBlob(e.update.events.camera.media_ready.key, e.id))
    }

    if (e.update.events && e.update.events.hasOwnProperty('dock')) {
      dispatch(refreshDock(e));
    }

    //console.log("node events - " + JSON.stringify(e))
    dispatch(refreshNode(e));
  }
}

export function receiveHubEvent(e) {
  return (dispatch, getState) => {
    /*if (e.update && e.update.events && (e.update.events.radio || (e.update.events.system && e.update.events.system.metrics))) {
      return;
    }

    if (e.update && e.update.events && e.update.events.hub && e.update.events.hub.associated_nodes) {
      return;
    }

    if (e.update && e.update.events && e.update.events.hub && e.update.events.hub.association_granted) {
      return;
    }*/

    if (e.update && e.update.events && (e.update.events.radio || e.update.events.hub || e.update.events.system)) {
        //console.log("hub events " + JSON.stringify(e))
        dispatch(refreshHub(e));
    }
  }
}

/*export function receiveNodeEvent(event) {
  let cameraEvent = event.filter(e => e.update.events && e.update.events.hasOwnProperty('camera') && e.update.events.camera.hasOwnProperty('media_ready')
    && e.source_type !== 'transcoder');

  return (dispatch, getState) => {
    if(cameraEvent.length) {
      dispatch(fetchMediaBlob(cameraEvent.update.events.camera.media_ready.key, cameraEvent.id))
    }
    event.forEach(e => {
      if (e.update.events.hasOwnProperty('dock')) {
        dispatch(refreshDock(e));
      }
      dispatch(refreshNode(e));
    })
  }
}

export function receiveHubEvent(wsevents) {
  let events = wsevents.filter(e => e.update && e.update.events && (e.update.events.radio || e.update.events.hub || e.update.events.system))

  return (dispatch, getState) => {
    events.forEach(e => {
      dispatch(refreshHub(e));
    })
  }
}*/

// ======================
//   Utility Functions
// ======================
export function reformatNodeHistory(json) {
  // Store the node id for use later
  let identifier = json.id;
  // Filter the history JSON for only camera events
  let newNodeHistory = json.history.filter(h => h.hasOwnProperty('update') && h.update.hasOwnProperty('events')
    && h.update.events.hasOwnProperty('camera') && h.update.events.camera.hasOwnProperty('media_ready'));
  // Run through each event collection to add the node identifier
  newNodeHistory.forEach((instance) => {
    instance.node = identifier;
  })
  return newNodeHistory;
}
