import merge from 'lodash/merge';
import { API_ROOT } from '../DashboardConfig';
import { updateNode, getNodesArray } from './nodes';
import { getCurrentSiteId } from './sites';
//import { fetchAPI, fetchMediaBlob, fetchSiteJson } from './api';
import { fetchSiteJson } from './api';

const LOAD = 'constellations/LOAD';
const FETCHING = 'constellations/FETCHING';
const ADD = 'constellations/ADD';
const ERROR = 'constellations/ERROR';
const REMOVE = 'constellations/REMOVE';
const UPDATE = 'constellations/UPDATE';

const initialState = {
  byId: {},
  allIds: [],
  status: 'pending',
};


// ======================
// The Reducer
// ======================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING:
      return Object.assign({}, state, { allIds: [], byId: {} });
    case LOAD:
      return Object.assign({}, state, action.payload, { status: 'success' });
    case ADD:
      return merge({}, state, {
        byId: { [action.payload.id]: action.payload[action.payload.id] },
        allIds: [...state.allIds, action.payload.id],
        error: '',
      });
    case ERROR:
      return Object.assign({}, state, { error: action.payload });
    // case REMOVE: {
    //   const idx = state.constellations.findIndex(h => h.constellation_id === action.payload)
    //   return Object.assign({}, state, {
    //     constellations: [
    //       ...state.constellations.slice(0, idx),
    //       ...state.constellations.slice(idx + 1),
    //     ],
    //   });
    // }
    case UPDATE: {
      return merge({}, state, {
        byId: {
          [action.payload.id]: action.payload.state,
        },
      });
    }
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}

// ======================
// Selectors
// ======================
export function getConstellationsArray(state) {
  return state.constellations.allIds.map(id => state.constellations.byId[id]);
}

export function getConstellationsForTable(state) {
  let constellations = getConstellationsArray(state).filter(c => c);

  constellations.forEach((c,i) => {
    let childNodes = getConstellationNodes(state, c.constellation);
    childNodes.forEach((n,x) => {
      childNodes[x] = n.node;
    })
    constellations[i].nodes = childNodes;
  })
  return constellations;
}

export function getRecentConstellationNodeEvents(state, constellation) {
  let activeNodes = getConstellationNodes(state, constellation);

  if(!activeNodes) {
    return {};
  }

  activeNodes.forEach((n,i) => {
    activeNodes[i].update = {};
    activeNodes[i].update.events = n.events;
  })

  activeNodes.sort((a, b) => b.update.events.camera.media.timestamp - a.update.events.camera.media.timestamp);
  let maxNodeCount = 10000;

  return activeNodes.slice(0, maxNodeCount);
}

export function getConstellationNodes(state, id) {
  let childNodes = getNodesArray(state).filter(n => n.hasOwnProperty('events') && n.events.hasOwnProperty('camera') && n.constellation === id
  );

  return childNodes;
}

// ======================
// Action Creators
// ======================
export function loadConstellations(json) {
  const byId = {};
  const allIds = [];
  json.constellations.forEach((c) => {
    byId[c.constellation] = c;
    allIds.push(c.constellation);
  });
  return { type: LOAD, payload: { byId, allIds } };
}

export function addConstellation(json) {
  return { type: ADD, payload: json.id };
}

export function constellationError(message) {
  return { type: ERROR, payload: message };
}

export function removeConstellation(id) {
  return { type: REMOVE, payload: id };
}

export function editConstellation(constellation) {
  return { type: UPDATE, payload: constellation };
}

export function fetching() {
  return { type: FETCHING };
}

// ===========================
// == Async Action Creators ==
// ===========================
export function triggerCamera(id, format) {
  console.log('triggering camera');
  return (dispatch, getState) => {
    const state = getState();
    const { authToken } = state;
    const init = {
        method: 'post',
        body: JSON.stringify({
            'camera': {
              'capture': {
                'format': format
              }
            }
        }),
    };

    const path = `constellations/${id}/action`
    fetchSiteJson(path, authToken, getCurrentSiteId(state), init)
      .then(json => console.log(json))
      .catch(error => console.log(error));
  }
}

export function fetchConstellationNodes(id) {

  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    const url = `${API_ROOT}constellations/${id}`;
    fetch(url, init)
      .then(
        (resp) => {
          return resp.json();
        },
        (error) => console.error(error)
      )
      .then(json => {
        return json;
      })
       .catch(err => console.log('Constellation Fetching Error :', err));
  };
}

export function fetchRecentHistoryMediaBlob(json) {
  console.log(json);
  // return (dispatch, getState) => {
  //   const { authToken } = getState();
  //   // const imageEvents = history.events.filter(event => (
  //   //   event.event_id === 'camera_media' &&
  //   //   event.camera_media.media_format === 'image'
  //   // ));
  //   // const videoEvents = history.events.filter(event => (
  //   //   event.event_id === 'camera_media' &&
  //   //   event.camera_media.media_format === 'video'
  //   // ));
  //   // const event = imageEvents[0] ? imageEvents[0] : videoEvents[0];
  //   const event = json.history[0]
  //   if (!event) {
  //     return;  //Promise.reject(new Error('No media event found'));
  //   }
  //   // console.log(event);
  //   const url = event ? event.camera_media.url : null;
  // //   fetchMediaBlob(url, authToken)
  // //     .then(
  // //       (resp) => {
  // //         console.log(resp);
  // //         // dispatch(editConstellation({
  // //         //   constellation: event ? json.id : null,
  // //         //   recentEvent: event,
  // //         //   mediaBlob: resp,
  // //         // }));
  // //       },
  // //       (error) => console.error(error)
  // //     )
  // }
}

export function fetchConstellations() {
  return (dispatch, getState) => {
    dispatch(fetching());
    const { authToken, sites } = getState();
    // console.log(sites);

    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}/constellations`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then(json => dispatch(loadConstellations(json)))
      .catch(err => console.log('Constellation Fetching Error :', err));
  };
}

export function createConstellation(constellation) {
  return (dispatch, getState) => {
    const { authToken, sites } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}/constellations`;
    const init = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(constellation),
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        if (json.message) {
          dispatch(constellationError(json.message));
        } else {
          dispatch(addConstellation(json))
        }
      });
  };
}

export function updateConstellation(id, constellationUpdates) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }
    const url = `${API_ROOT}constellations/${id}`;
    const init = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(constellationUpdates),
    };
    fetch(url, init)
      .then((resp) => {
        if (resp.ok) {
          dispatch(fetchConstellation(id));
        }
        return resp.json();
      })
      .then((json) => {
        if (json.message) {
          dispatch(constellationError(json.message));
        } else {
          console.log(json);
        }
      });
  };
}

export function deleteConstellation(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}constellations/${id}`;
    const init = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then((resp) => {
        if (resp.ok) { dispatch(removeConstellation(id)); }
      });
  };
}

export function fetchConstellation(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}constellations/${id}`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        dispatch(editConstellation(json));
      })
      .catch(error => dispatch(constellationError(error)));
  };
}

export function updateConstellationNodes(cNodes, constellation) {
  const nodeIds = cNodes.map(n => n.value);

  return (dispatch, getState) => {
    const { nodes } = getState();
    nodeIds.forEach(id => dispatch(updateNode(id, {
      node: {
        constellation,
      }
    })));

    nodes.allIds.forEach((id) => {
      if (nodes.byId[id]
        && nodes.byId[id].constellation === constellation
        && !nodeIds.includes(id)) {
        dispatch(updateNode(id, {
          node: {
            constellation: null,
          }
        }));
      }
    });
  };
}
