import React from 'react';
import { Stage, Layer, Image, Rect} from 'react-konva';
import {FormattedMessage} from 'react-intl';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { postWmsJsonNew } from '../../../modules/api';

import ToolTip from '../../common/ToolTip';
const MON = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class MscsHeatmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      percent_or_quantity: "percentile", //percentile, rack_quantity
      rack_raw: {},
      racks: [],
      loading: "",
      rack_criteria: {
        0: {to: 25, color: "blue"},
        1: {to: 50, color: "green"},
        2: {to: 75, color: "yellow"},
        3: {to: 100, color: "red"}
      },
      p_legend: [0,0,0,0],
      tooltip_props: {id:null, props:null, x:null, y:null},
      tooltip_show: false,
      loading_key: null,
      date_string: "",
    };

    this.loadImages = this.loadImages.bind(this);
    this.unloadImages = this.unloadImages.bind(this);
    this.makeToolTip = this.makeToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.debounceRendering = debounce(this.fetch_wms_tracks, 1000);
  }

  componentDidMount() {
    this.loadImages();
    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=>this.fetch_wms_tracks(loading_key));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodes.length !== this.props.nodes.length ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals)) {
          let loading_key = new Date().getTime();
          this.setState({loading_key: loading_key, racks: [], rack_raw: {}}, ()=>{this.debounceRendering(loading_key)});
      }
  }

  componentWillUnmount() {
    this.unloadImages();
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("../img/" + this.props.config.mscs[this.props.size].rack[this.props.floor].background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));
  }

  fetch_wms_tracks(loading_key) {
    if (this.props.nodes.length === 0) {return}
    let startDate = new Date(this.props.time_intervals[0].from);
    let endDate = new Date(this.props.time_intervals[this.props.time_intervals.length - 1].to);
    let st = MON[startDate.getMonth()] + " " + startDate.getDate() + " - " + MON[endDate.getMonth()] + " " + endDate.getDate();

    this.setState({loading: "Loading..Please wait...", date_string: st, racks: [], rack_raw: {}});
    let body = {time_intervals: this.props.time_intervals, assets: this.props.nodes, outputs: {heat_map: {cell_size: 1}}};
    if (this.props.floor) { body["outputs"]["heat_map"]["floor"] = this.props.floor }

    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        if (loading_key === this.state.loading_key) {
          this.setState({loading: "", loading_key: null, rack_raw: json.racks});
        } else {
          console.log("Another process is going " + loading_key + " " + this.state.loading_key)
        }
      })
      .then(()=> this.makeRacks())
      .catch(error => {
        this.setState({loading: "ERROR - STATUS " + error.status}, ()=>console.log(error))
      })
  }

  makeRacks() {
    let highest_assignment = 0;
    let parent_racks = {};
    let coords_floor = this.props.coordinates[this.props.floor];
    let racks_floor = this.props.racks[this.props.floor];

    for(const r in this.state.rack_raw) {
      if (!racks_floor.hasOwnProperty(r)) { /*console.log("Missing parent " + r);*/  continue; }
      if (!coords_floor.hasOwnProperty(this.props.racks[this.props.floor][r])) { /*console.log("Missing coordinate " + r);*/ continue;}
      let rr = this.state.rack_raw[r]
      let parent_id = racks_floor[r];
      if (rr.assignments > highest_assignment) { highest_assignment = rr.assignments }

      if (parent_racks.hasOwnProperty(parent_id)) {
        if (parent_racks[parent_id].p < rr.percentile_by_assignments) {
          parent_racks[parent_id].p = rr.percentile_by_assignments
        }
      } else {
        parent_racks[parent_id] = {
          x: coords_floor[parent_id].x,
          y: coords_floor[parent_id].y,
          width: coords_floor[parent_id].width,
          height: coords_floor[parent_id].height,
          assignments: rr.assignments,
          p: rr.percentile_by_assignments,
        }
      }
    } //end for

    let racks = [];
    let low = Math.round(highest_assignment * this.state.rack_criteria["0"].to / 100);
    let middle = Math.round(highest_assignment * this.state.rack_criteria["1"].to / 100);
    let high = Math.round(highest_assignment * this.state.rack_criteria["2"].to / 100);
    let higher = highest_assignment;

    //console.log("processing - " + this.state.percent_or_quantity);
    for (const p in parent_racks) {
      let rr = parent_racks[p];
      let newX =  (rr.x + this.props.config.mscs[this.props.size].rack[this.props.floor].origin.x) * this.props.config.mscs[this.props.size].rack[this.props.floor].small.pixels_per_meter;
      let newY = this.props.config.mscs[this.props.size].rack[this.props.floor].small.height - ((rr.y + this.props.config.mscs[this.props.size].rack[this.props.floor].origin.y) * this.props.config.mscs[this.props.size].rack[this.props.floor].small.pixels_per_meter);
      let width = rr.width * this.props.config.mscs[this.props.size].rack[this.props.floor].small.pixels_per_meter;
      let height = rr.height * this.props.config.mscs[this.props.size].rack[this.props.floor].small.pixels_per_meter;

      let color = "";
      //filter by percentile
      if (this.state.percent_or_quantity === 'percentile') {
        if (rr.p <= this.state.rack_criteria["0"].to) {
          color = this.state.rack_criteria["0"].color;
          if (low <= rr.assignments ) { low = rr.assignments }
        } else if (rr.p <= this.state.rack_criteria["1"].to) {
          color = this.state.rack_criteria["1"].color;
          if (middle <= rr.assignments) { middle = rr.assignments}
        } else if (rr.p <= this.state.rack_criteria["2"].to) {
          color = this.state.rack_criteria["2"].color;
          if (high <= rr.assignments) { high = rr.assignments }
        } else {
          color = this.state.rack_criteria["3"].color;
          if (higher <= rr.assignments) { higher = rr.assignments }
        }
      }

      //filter by percent
      let percent = Math.round(rr.assignments / highest_assignment * 100);
      if (this.state.percent_or_quantity === 'percent') {
        if (percent <= this.state.rack_criteria["0"].to) {
          color = this.state.rack_criteria["0"].color;
        } else if (percent <= this.state.rack_criteria["1"].to) {
          color = this.state.rack_criteria["1"].color;
        } else if (percent <= this.state.rack_criteria["2"].to) {
          color = this.state.rack_criteria["2"].color;
        } else {
          color = this.state.rack_criteria["3"].color;
        }
      }

      //filter by assignments
      if (this.state.percent_or_quantity === 'quantity') {
        if (rr.assignments <= this.state.rack_criteria["0"].to) {
          color = this.state.rack_criteria["0"].color;
          if (low <= rr.assignments ) { low = rr.assignments }
        } else if (rr.assignments <= this.state.rack_criteria["1"].to) {
          color = this.state.rack_criteria["1"].color;
          if (middle <= rr.assignments) { middle = rr.assignments}
        } else if (rr.assignments <= this.state.rack_criteria["2"].to) {
          color = this.state.rack_criteria["2"].color;
          if (high <= rr.assignments) { high = rr.assignments }
        } else {
          color = this.state.rack_criteria["3"].color;
          if (higher <= rr.assignments) { higher = rr.assignments }
        }
      }

      let rack_info = {id: p, props: {Visit: rr.assignments, Percentile: rr.p, Percent: percent}}
      racks.push(<Rect key={p} rack_info={rack_info} x={newX} y={newY} width={width} height={height} fill={color} onMouseOver={this.makeToolTip} onMouseOut={this.closeToolTip}/*onClick={()=>console.log(p)}*//>);
    }

    this.setState({racks: racks, p_legend: [low, middle, high, higher]});
  }

  makeToolTip(e) {
    let tooltip = {
      id: e.target.attrs.rack_info.id,
      x: this.refs['stage'].getPointerPosition().x,
      y: this.refs['stage'].getPointerPosition().y,
      props: e.target.attrs.rack_info.props,
    }

    this.setState({tooltip_props: tooltip, tooltip_show: true});
  }

  closeToolTip(e) {
    this.setState({tooltip_show: false});
  }

  render() {
    return(
      <div className="mscsHeatmap">
        <FormattedMessage id="mscs.heatmap" defaultMessage="Heatmap"/><div> {this.state.date_string}</div>
        <div className="mscsMap">
        <Stage ref="stage" width={this.props.config.mscs[this.props.size].rack[this.props.floor].small.width} height={this.props.config.mscs[this.props.size].rack[this.props.floor].small.height}>
          <Layer>
            <Image
              x={0}
              y={0}
              width = {this.props.config.mscs[this.props.size].rack[this.props.floor].small.width}
              height= {this.props.config.mscs[this.props.size].rack[this.props.floor].small.height}
              image={this.state.background}
            />
            {this.state.racks}
          </Layer>
          <ToolTip tooltip_props={this.state.tooltip_props} tooltip_show={this.state.tooltip_show}/>
        </Stage>
        </div>
        </div>

    )
  }
}

export default connect(
  state => ({ authToken: state.authToken }),
)(MscsHeatmap);
