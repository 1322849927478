import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { fetchMediaBlob } from '../../modules/api';
//import NodeOverlay from '../devices/NodeOverlay';
import {Timestamp} from '../Timestamp'

//Modal.setAppElement('#CameraEventModal');
const customStyles = {
  content : {
    top                   : '3%',
    left                  : '3%',
    right                 : '3%',
    bottom                : '3%',
    //marginRight           : '-50%',
    //transform             : 'translate(-50%, -50%)'
  }
};

class CameraEventModal extends React.Component {
  constructor(props) {
    super(props);
    let media = {};
    try {
      if(this.props.node) {
        media = this.props.node.update.events.camera.media_ready;
      }
    } catch (err) {
      console.warn('No media found', err);
    }

    this.state = {
      cameraName: this.props.nodeName.toUpperCase(),
      mediaFormat: media.format,
      key: media.key,
      srcUrl: require('../../media/Loading_Img.svg'),
      node: null,
      modalisOpen: false,
      showNext: false,
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.newNode = this.newNode.bind(this);
  }

  componentDidUpdate(prevProps) {
    //if there's an update of node
    if(prevProps.node !== this.props.node) {
      let media = {};
      media = this.props.node.update.events.camera.media_ready;

      let index = this.props.allEvents.findIndex(n => n === this.props.node);
      this.setState({
        mediaFormat: "image",
        key: media.key,
        srcUrl: require('../../media/Loading_Img.svg'),
        node: this.props.node,
        size: media.size,
        showNext: index === 0 ? false : true,
      })

      //setTimeout(() => {
      fetchMediaBlob(media.key, this.props.authToken)
        .then(srcUrl => {
          this.setState({srcUrl, mediaFormat: media.format, key: media.key, modalIsOpen: true }, ()=>{'set state'})
        })
      //}, 1000)
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  newNode(e) {
    e.preventDefault();
    let position = parseInt(e.target.dataset.position, 10);
    let index = this.props.allEvents.findIndex(n => n === this.state.node);
    if ((index === 0 && position === -1) || (index === (this.props.allEvents.length - 1) && position === 1)) { position = 0; }
    let media = this.props.allEvents[index + position].update.events.camera.media_ready;

    this.setState({
      mediaFormat: "image",
      key: media.key,
      srcUrl: require('../../media/Loading_Img.svg'),
      node: this.props.allEvents[index + position],
      size: media.size,
      showNext: index + position === 0 ? false : true,
    })

    //setTimeout(() => {
    fetchMediaBlob(media.key, this.props.authToken)
      .then(srcUrl => {
        this.setState({srcUrl, mediaFormat: media.format, key: media.key, modalIsOpen: true}, ()=>{'set state'})
      })
    //}, 1000)
  }

  /* need to move style away into css */
  render() {
    let nextButton = <div></div>
    if (this.state.showNext) {
      nextButton = <button style={{float: "right"}} data-position="-1" onClick={this.newNode}>Next</button>
    }

    return (
       <div>
         <Modal
           isOpen={this.state.modalIsOpen}
           onRequestClose={this.closeModal}
           style={customStyles}
           contentLabel="Example Modal"
           ariaHideApp={false}
         >
           <h2>{this.state.cameraName}</h2>
           <h4><Timestamp millis={this.state.node ? this.state.node.update.events.camera.media_ready.timestamp : ''}/> ({Math.round(this.state.size/1024)} KB)</h4>
           {
             (this.state.mediaFormat === 'image') ? <img alt={this.state.srcUrl} src={this.state.srcUrl} /> :
                <video autoPlay loop muted src={this.state.srcUrl} type={""}/>
           }
           <form>
              <button style={{float: "right"}} onClick={this.closeModal}>Close</button>
              <button style={{float: "right"}}><a href={this.state.srcUrl} download style={{"textDecoration": "none", color: "white", fontWeight: 200}}>Download</a></button>
              {nextButton}
              <button style={{float: "right"}} data-position="1" onClick={this.newNode}>Prev</button>
            </form>
         </Modal>
       </div>
     );
   }
}

window.fetchMediaBlob = fetchMediaBlob

export default connect(
  state => ({ authToken: state.authToken })
)(CameraEventModal);
