import omit from 'lodash/omit';
import merge from 'lodash/merge';
import { API_ROOT } from '../DashboardConfig';
//import { fetchAPI, fetchSiteJson } from './api';
//import { getCurrentSiteId } from './sites';

const LOAD = 'docks/LOAD';
const FETCHING = 'docks/FETCHING';
const REFRESH = 'docks/REFRESH';
const ADD = 'docks/ADD';
const ERROR = 'docks/ERROR';
const REMOVE = 'docks/REMOVE';
const UPDATE = 'docks/UPDATE';

const initialState = {
  byId: {},
  allIds: [],
  status: 'pending',
  error: '',
};

// ======================
// The Reducer
// ======================
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING:
      return Object.assign({}, state, { allIds: [], byId: {} });
    case LOAD:
      return Object.assign({}, state, action.payload, { status: 'success', error: '' });
    case REFRESH:
      /*let meta = {}; //old
      //metric_change events - occupancy or activity need timestamp
      if (action.payload.update.events.dock.hasOwnProperty('metric_change')) {
        if (action.payload.update.events.dock.metric_change.metrics.hasOwnProperty('occupancy')) {
          //console.log("metric_change - occupancy " + action.payload.id + " " + action.payload.timestamp);
          meta = {metric_change: { metrics: {occupancy: {timestamp: action.payload.timestamp}}}};
        } else {
          //console.log("metric_change - activity " + action.payload.id + " " + action.payload.timestamp);
          meta = {metric_change: { metrics: {activity: {timestamp: action.payload.timestamp}}}};
        }
      } else {
        //activity or occupancy events
        //console.log(Object.keys(action.payload.update.events.dock)[0] + " " + action.payload.id + " " + action.payload.timestamp);
        meta[Object.keys(action.payload.update.events.dock)[0]] = {timestamp: action.payload.timestamp};
      }

      return merge({}, state, {
        byId: { [action.payload.id]: action.payload.update },
        }, {
        byId: { [action.payload.id] : {"metadata": meta}},
        },
      );*/

      let payload = {...action.payload.update};
      payload.events.dock[Object.keys(payload.events.dock)[0]].timestamp = action.payload.timestamp;

      return merge({}, state, {
        byId: { [action.payload.id]: payload },
        }
      );

    case ADD:
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.payload]: {name: '', hardware_id: '', constellation: ''},
        }),
        allIds: [...state.allIds, action.payload],
        error: '',
      });
    case ERROR:
      return Object.assign({}, state, { error: action.payload });
    case REMOVE:
      return Object.assign({}, state, {
        byId: omit(state.byId, action.payload),
        allIds: state.allIds.filter(id => id !== action.payload),
      });
    case UPDATE:
      return merge({}, state, {
        byId: { [action.payload.id]: action.payload.state },
      });
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}

// =======================
// ==     Selectors     ==
// =======================
export function getDocksArray(state) {
  return state.docks.allIds.map(id => state.docks.byId[id])
}

export function getDocksConfigs(state, id) {
  const node = state.docks.byId[id];
  return node.configs || {};
}

/*export function getDocksForTable(state) {
  return state.docks.allIds.map(id => {
    let dock = {
      name: state.docks.byId[id].name,
      node: id,
      exterior: state.docks.byId[id].hasOwnProperty('events') &&
                state.docks.byId[id].events.hasOwnProperty('dock') &&
                state.docks.byId[id].events.dock.hasOwnProperty('occupancy')
                ?
                  state.docks.byId[id].events.dock.occupancy.camera_id
                  :
                  null,
      interior: state.docks.byId[id].hasOwnProperty('events') &&
                state.docks.byId[id].events.hasOwnProperty('dock') &&
                state.docks.byId[id].events.dock.hasOwnProperty('activity')
                ?
                  state.docks.byId[id].events.dock.activity.camera_id
                  :
                  null,
      connected: state.docks.byId[id].hasOwnProperty('events') &&
                 state.docks.byId[id].events.hasOwnProperty('system') &&
                 state.docks.byId[id].events.system.hasOwnProperty('connection')?
                    state.docks.byId[id].events.system.connection.connected : false,
    }
    return dock;
  });
}*/

export function getDocksForTable(state) {
  return state.docks.allIds.map(id => {
    let dock = {
      name: state.docks.byId[id].name,
      node: id,
      exterior: null,
      interior: null,
      connected: state.docks.byId[id].hasOwnProperty('events') &&
                 state.docks.byId[id].events.hasOwnProperty('system') &&
                 state.docks.byId[id].events.system.hasOwnProperty('connection')?
                  state.docks.byId[id].events.system.connection.connected : false,
    }

    if (state.docks.byId[id].configs && state.docks.byId[id].configs.dock && state.docks.byId[id].configs.dock.cameras) {
      for (const c in state.docks.byId[id].configs.dock.cameras) {
        if (state.docks.byId[id].configs.dock.cameras[c].view === 'exterior') {
          dock.exterior = c;
        }

        if (state.docks.byId[id].configs.dock.cameras[c].view === 'interior') {
          dock.interior = c;
        }

        if (state.docks.byId[id].configs.dock.cameras[c].view === 'combined') {
          dock.interior = c;
          dock.exterior = c;
        }
      }
    }

    return dock;
  });
}

// ======================
// Action Creators
// ======================
export function loadDocks(json) {
  let filteredNodes = [];
  for (const node in json.state.nodes) {
    if (json.state.nodes[node].node_type !== 'dock') { continue; }

    let nodeData = Object.assign({}, {"node": node}, json.state.nodes[node]);
    if (json.metadata && json.metadata.nodes[node] && json.metadata.nodes[node].events && json.metadata.nodes[node].events.dock) {
      //nodeData = Object.assign({}, {"node": node}, {"metadata": json.metadata.nodes[node].events.dock}, json.state.nodes[node]);

      Object.keys(json.metadata.nodes[node].events.dock).forEach(k => {
        nodeData.events.dock[k]['timestamp'] = json.metadata.nodes[node].events.dock[k].timestamp;
      })
    }

    filteredNodes.push(nodeData);
  }

  //console.log(JSON.stringify(filteredNodes));
  const byId = {};
  const allIds = [];
  filteredNodes.forEach((n) => {
    byId[n.node] = n;
    allIds.push(n.node);
  });
  return { type: LOAD, payload: { byId, allIds } };
}

export function refreshDock(node) {
  //console.log(JSON.stringify(node));
  return { type: REFRESH, payload: node};
}

export function addDock(id) {
  return { type: ADD, payload: id };
}

export function removeDock(id) {
  return { type: REMOVE, payload: id };
}

export function editDock(node) {
  return { type: UPDATE, payload: node };
}

export function dockError(message) {
  return { type: ERROR, payload: message };
}

export function fetching() {
  return { type: FETCHING };
}

export function logoutDock() {
  return { type: 'LOGOUT'};
}

// ======================
// Async Action Creators
// ======================
export function fetchDocks() {
  return (dispatch, getState) => {
    dispatch(fetching());
    const { authToken, sites } = getState();

    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}?detailed`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then(json => dispatch(loadDocks(json)))
      .catch(err => console.log('Dock Fetching Error :', err));
  };
}

/* Will need later to add,edit,delete docks
export function createNode(node) {
  return (dispatch, getState) => {
    const { authToken, sites } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}/nodes`;
    const init = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(node),
    };
    fetch(url, init)
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        if (json.message) {
          dispatch(nodeError(json.message));
        } else {
          dispatch(addNode(json.id));
          dispatch(fetchNode(json.id));
        }
      });
  };
}
export function updateNode(id, nodeUpdates) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(nodeUpdates),
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        if (json.message) {
          dispatch(nodeError(json.message));
        } else {
          dispatch(fetchNode(id));
        }
      });
  };
}

export function deleteNode(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then((resp) => {
        if (resp.ok) { dispatch(removeNode(id)); }
      })
  };
}
export function fetchNode(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then(json => {
        if (json.message) {
          dispatch(nodeError(json.message));
        } else {
          if (!json.state.constellation) {
            json.state.constellation = '';
          }
          dispatch(editNode(json));
        }
      });
  };
}
*/
