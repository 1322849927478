import React from 'react';
import {Timestamp} from '../Timestamp';
import TakeNodePictureBtn from './TakeNodePictureBtn';
import TakeNodeVideoBtn from './TakeNodeVideoBtn';

const NodeOverlay = ({ node }) => {
  const hoverCaptions = [];
  if (node.update) {
    if (node.update.events.hasOwnProperty('environmental')) {
      hoverCaptions.push(<dt key="temp">Temperature</dt>);
      hoverCaptions.push(<dd key="temp-val">{node.update.events.environmental.temperature}°C</dd>);

      hoverCaptions.push(<dt key="humid">Humidity</dt>);
      hoverCaptions.push(<dd key="humid-val">{node.update.events.environmental.humidity}%</dd>);
    }

    //hoverCaptions.push(<dt key="type">Type</dt>);
    //hoverCaptions.push(<dd key="type-vale">{node.update.events.camera.media_ready.type}</dd>);
  }

  let timestamp = <div></div>

  if (node.events && node.events.camera && node.events.camera.media_ready) {
    timestamp =  <Timestamp millis={node.events.camera.media_ready.timestamp}/>
  } else if (node.update && node.update.events && node.update.events.camera && node.update.events.camera.media_ready){
    timestamp = <Timestamp millis={node.update.events.camera.media_ready.timestamp}/>
  }

  //  <div>{node.update.events.camera.media_ready.type.toUpperCase()}</div>
  // <Timestamp millis={node.events ? node.events.camera.media_ready.timestamp : node.update.events.camera.media_ready.timestamp}/>
  return (
    <div className="node-overlay">
      <div className="event-caption">
        <div key="name" className="name-caption" data-site-id={node.site} data-node-id={node.node}>{node.name}</div>
        {timestamp}
        <dl className="hover-caption">
          { hoverCaptions }
        </dl>
      </div>
      <div className="overlay-btns">
        <TakeNodePictureBtn id={node.node} />
        <TakeNodeVideoBtn id={node.node} />
      </div>
    </div>
  );
}

export default NodeOverlay;
