import React from 'react';
import { connect } from 'react-redux';
import { restartNode } from '../../../modules/nodes';

const RestartButton = (props) => {
  const handleRestart = (e) => {
    props.dispatch(restartNode(props.node));
    props.handleRestart('success');
  }

  return (
    <div className="restart">
      <button onClick={()=>handleRestart()}>RESTART</button>
    </div>
  )
}

export default connect()(RestartButton);
