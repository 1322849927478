import React from 'react';
import RecentCameraEventList from './events/RecentCameraEventList';
import ConstellationEventList from './events/ConstellationEventList';
//import ActivityChart from './ActivityChart.js';
// import { ConstellationEventFrameContainer } from './ConstellationEventFrameContainer.js';

export class HomePage extends React.Component {
    render() {
        return (
          <div className="content-row">
            <div className="dashboard-content home-page" id="dashboard-content">
            </div>
          </div>
        )
    }
}

/*
//Hide activity chart for now
<ActivityChart session={this.props.session}
               chartWidth={1000}
               chartHeight={250}
               defaultRange="last_24h" filterType="none" filterId="" />

               <RecentCameraEventList heading="Recent"/>
               <ConstellationEventList />
*/
