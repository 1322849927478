import React from 'react';
import {FormattedMessage} from 'react-intl';
import {addCommas} from '../charts/ChartUtil';

const MscsOpMetric = ({opMetric}) => {
  return (
    <div className="mscsMetric">
      <div className="container">
        <img className="icon" alt="icon-worker" src={require("../img/icon-worker.png")}/>
        <FormattedMessage id="mscs.opmetrics" defaultMessage="Operator Metrics"/>
        <div className="field">
          <FormattedMessage id="mscs.itemspicked" defaultMessage="Items Picked"/>
        </div>
        <div className="value">
          {addCommas(opMetric.items)} <FormattedMessage id="mscs.itemshour" defaultMessage="items/hour"/>
        </div>
        <div className="field">
          <FormattedMessage id="mscs.opdailywalkingdistance" defaultMessage="Daily Walking Distance"/>
        </div>
        <div className="value">
          {addCommas(opMetric.distance)} <FormattedMessage id="mscs.mperson" defaultMessage="m/person"/>
        </div>
      </div>
    </div>
  )
}

export default MscsOpMetric;
