import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Sort from '../../common/Sort';
import Filter from '../../common/Filter';
import { checkSiteACL} from '../Config';

const SitesTable = ({origData, data, setTableData, sortedColumnIndex, setSortedData, acl, customer}) => (
  <div className="main">
    <h3>{customer && customer.label} - SITES</h3>
    {
      customer && checkSiteACL(customer.value, null, acl, 'Add Site') &&
      <div className="button-container"><Link to={`/dashboard/management/sites/${customer.value}/new`}>ADD NEW</Link></div>
    }
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>Site<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {
              customer && checkSiteACL(customer.value, data[props.rowIndex].id, acl, 'Edit Site') ?
                <Link to={`/dashboard/management/sites/${customer.value}/${data[props.rowIndex].id}`}>{data[props.rowIndex].site_name}</Link>
                :
                <div>{data[props.rowIndex].site_name}</div>
            }
          </Cell>
        )}
        width={815}
      />
    </Table>
  </div>
);

class Sites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      origData: [],
      tableData: [],
      sortedColumnIndex: 0,
    };

    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
  }

  componentDidMount() {
    if (this.props.customer) {
      let filter = this.props.sites.filter( site => site.customer === this.props.customer.value);
      this.createTableData(filter);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      if (this.props.customer.value === 'All') {
        this.createTableData(this.props.sites);
      } else {
        let filter = this.props.sites.filter( site => site.customer === this.props.customer.value);
        this.createTableData(filter);
      }
    }
  }

  createTableData(sites) {
    let data = sites.map(site => ({site_name: site.site_name, customer_name: site.customer_name, id: site.site}));
    data = data.sort((a,b) => a.site_name.localeCompare(b.site_name))
    this.setState({origData: data, tableData: data});
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  render() {
    return(
          <SitesTable
            origData={this.state.origData}
            data={this.state.tableData}
            setTableData={this.setTableData}
            sortedColumnIndex={this.state.sortedColumnIndex}
            setSortedData={this.setSortedData}
            acl={this.props.acl}
            customer={this.props.customer}
          />
    )
  }
}

export default connect(
  state => ({
    acl: state.user.acl,
    sites: Object.values(state.sites.sites),
  }),
)(Sites);

/*
const Sites = (props) => {
  const [origData, setOrigData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sortedColumnIndex, setSortedColumnIndex] = useState(0);

  useEffect(() => {
    console.log("useeffect");
    let data = props.sites.map(site => ({site_name: site.site_name, customer_name: site.customer_name, id: site.site}));
    data = data.sort((a,b) => a.site_name.localeCompare(b.site_name))
    setOrigData(data);
    setTableData(data);
  }, props.sites)

  const setSortedData = (index, data) => {
    console.log(index);
    console.log(data)
    setTableData(data);
    setSortedColumnIndex(index);
  }

  return (
    <SitesTable
      origData={origData}
      data={tableData}
      setTableData={(data)=>setTableData(data)}
      sortedColumnIndex={sortedColumnIndex}
      setSortedData={setSortedData}
    />
  );
}
*/
