import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//import { get } from 'lodash';

import { getRandomColor } from '../common/Util';
import { getCurrentSiteId } from '../../modules/sites';
import { fetchSiteDetailJson, fetchSiteJson } from '../../modules/api';

import HeaderContainer from '../settings/form/HeaderContainer';
import LabelContainer from '../settings/form/LabelContainer';
import FormContainer from '../settings/form/FormContainer';

import {isEmpty, isSelectEmpty} from '../settings/form/ErrorChecking';

const URL = "/dashboard/settings/smartlps/gids";
const CONFIG = {id: "", name: "", type: "", color: "", order: 0, validate: true, status: ""}

const EditGIDNew = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [workers, setWorkers]  = useState(null); //worker_ids
  const [groups, setGroups] = useState(null);
  const [disable_save, setDisable_save] = useState(false);

  useEffect(()=> {
    if (!id) {
      console.log("useEffect - wms query ");
      setError(Object.assign({},error, {status: "Loading Form... Please wait..."}))
      setId(props.match.params.id);
      fetchSiteDetailJson(props.authToken, props.siteId)
      .then(json => {
        setWorkers(json.state.configs.wms.worker_ids);
        setGroups(json.state.configs.wms.groups);

        switch(props.match.params.id) {
          case 'new':
            resetForm(null);
          break;
          default:
            resetForm(json.state.configs.wms.worker_ids[props.match.params.id])
          }
      })
      .catch(err => console.error(err))
    }
  }, [id, workers, props.match.params.id, props.authToken, props.siteId, error])

  const resetForm = (worker) => {
    if (worker) {
      console.log("set editForm " + worker.id);
      setId(worker.id);
      setEditForm(true);
      console.log (JSON.stringify(worker))
      setConfig(Object.assign({}, CONFIG, worker));
    } else {
      console.log("set newForm")
      setId('new');
      setEditForm(false);
      setConfig(Object.assign({}, CONFIG, {color: getRandomColor()}));
      setDisable_save(false);
    }
    setError(CONFIG);
  }

  //id, name, type, color, order
  const validateFields = () => {
    let errorNew = {...CONFIG};
    isEmpty("id", config.id, errorNew);
    isEmpty("name", config.name, errorNew);
    isEmpty("color", config.color, errorNew);
    isSelectEmpty("type", config.type, errorNew);
    setError(errorNew);
    return errorNew.validate;
  }

  const handleSubmit = (e)=> {
    if (!validateFields()) { return; }
    //console.log(JSON.stringify(config))
    //{"id":"test","name":"test","type":"Floor 2 Worker",", "color":"899953","order":0,"validate":true,"status":""}
    setError(Object.assign({}, error, {status: "Saving worker..."}));

    let start = workers ? Object.keys(workers).length : 1;
    let w = {
      [config.id] : {
         id: config.id,
         name: config.name,
         type: config.type,
         order: config.order === 0 ? start : config.order,
         color: config.color.startsWith("#") ? config.color : "#" + config.color,
       }
     }
     console.log(JSON.stringify(w));

     let path = 'sites/' + props.siteId;
     let body = {site: {configs: {wms: {worker_ids: w}}}};
     let init = {
     method: "put",
     body: JSON.stringify(body)
   }

   fetchSiteJson(path, props.authToken, props.siteId, init)
    .then(()=> {
      console.log("Updated");
      setTimeout(()=> props.history.push(URL), 1000);
    })
    .catch(error => {
      console.log(error)
      setError(Object.assign({}, error, {status: "Worker update failed"}))
    })
  };


  const handleDelete = (e) => {
    console.log("deleting worker")
    setError(Object.assign({}, error, {status: "Deleting worker..."}));

    let path = 'sites/' + props.siteId;
    let body = {site: {configs: {wms: {worker_ids: {[config.id] : null}}}}};
    let init = {
     method: "put",
     body: JSON.stringify(body)
   }

   fetchSiteJson(path, props.authToken, props.siteId, init)
    .then(()=> {
      console.log("Deleted");
      setTimeout(()=> props.history.push(URL), 1000);
    })
    .catch(error => {
      console.log(error)
      setError(Object.assign({}, error, {status: "Worker delete failed"}))
    })
  };

  const updateField = (field, e, type) => {
    //console.log("update field " + field + " " + JSON.stringify(e));
    switch (type) {
      case 'select':
        if (!e) { return; }
        setConfig(Object.assign({}, config, {[field]: e.value}));
      break;
      case 'color_picker':
        setConfig(Object.assign({}, config, {[field]: e}));
      break;
      default:
        setConfig(Object.assign({}, config, {[field]: e.target.value}));
    }
  }

  const add_form =
    <div key="save">
      <LabelContainer labels= {{
        "ID": {"help": "The Node ID for the tracker in the system. Use this to always associate a tracker with a worker.", "desc": ""},
        "Worker Name": {"help": "The display name for the worker in the dashboard", "desc": ""},
        "Group": {"help": "Set groups to distinguish based on type (worker or forklift driver), floor, or past workers", "desc": ""},
        "Color": {"help": "Sets the path color for the worker", "desc": ""}
      }}/>
      <FormContainer
        fields = {{
            "id": {"value": config.id, "placeholder": "", "error": error.id},
            "name": {"value": config.name, "placeholder": "", "error": error.name},
            "type" :  {"type": "select",
                       "options": groups && Object.values(groups).flat().map(g => { return {label: g, value:g}}),
                       "value": config.type && {label: config.type, value: config.type},
                       "placeholder": "",
                       "error": error.type
                      },
            "color" :  {"type": "color_picker", "value": config.color.replace("#", ""), "error": error.color},
        }}
        updateField={updateField}
      />
    </div>

 const edit_form =
   <div key="edit">
     <LabelContainer labels= {{
       "ID": {"help": "The Node ID for the tracker in the system. Use this to always associate a tracker with a worker.", "desc": ""},
       "Worker Name": {"help": "The display name for the worker in the dashboard", "desc": ""},
       "Group": {"help": "Set groups to distinguish based on type (worker or forklift driver), floor, or past workers", "desc": ""},
       "Color": {"help": "Sets the path color for the worker", "desc": ""}
     }}/>
     <FormContainer
       fields = {{
           "id": {"type": "disabled_textfield", "placeholder": config.id},
           "name": {"value": config.name, "placeholder": "", "error": error.name},
           "type" :  {"type": "select",
                      "options": groups ? Object.values(groups).flat().map(g => { return {label: g, value: g}}) : {},
                      "value": config.type && {label: config.type, value: config.type},// need to change
                      "placeholder": "",
                      "error": error.type
                     },
           "color" :  {"type": "color_picker", "value": config.color.replace("#", ""), "error": error.color},
       }}
       updateField={updateField}
     />
   </div>

  return(
    <div className="main edit">
      <Link to={URL}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? "Edit Worker" : "Add New"}</h3>
      {editForm &&
        <HeaderContainer
           message={`Are you sure you want to delete worker ${config.name}?`}
           delete={handleDelete}
        />
      }
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        { editForm ? edit_form : add_form }
      </div>
      <div style={{display: disable_save ? "none" : "block"}}>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" disabled={disable_save} onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect(
  state => ({
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  })
)(EditGIDNew);
