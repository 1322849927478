import React from 'react';

export class TrackAssetItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleLocateNode = this.handleLocateNode.bind(this);
        this.handleTrackNode = this.handleTrackNode.bind(this);
        this.handleNodeSelect = this.handleNodeSelect.bind(this);
        this.handleTdoaNode = this.handleTdoaNode.bind(this);

        this.state = {
            tracking: this.props.node.configs.tracker.tracking_mode,
            selected: false,
            hasLoc: (this.props.node.events && this.props.node.events.tracker && this.props.node.events.tracker.location &&
                    this.props.node.events.tracker.location.meas_id),
            hasMaster: (this.props.node.configs && this.props.node.configs.tracker && this.props.node.configs.tracker.tdoa_master)
        };
    }


    componentDidUpdate(prevProps) {
        if (prevProps.node.configs.tracker.tracking_mode !== this.props.node.configs.tracker.tracking_mode) {
          console.log("component updated")
          this.setState({tracking: this.props.node.configs.tracker.tracking_mode})
        }
    }

    handleLocateNode(event) {
        if (event !== null) {
            event.stopPropagation();
        }
        this.props.doLocateNode(this.props.node.node);
    }

    handleTdoaNode(event) {
        if (event !== null) {
            event.stopPropagation();
        }
        this.props.doTdoaNode(this.props.node);
    }

    handleTrackNode(event) {
        event.stopPropagation();
        this.setState(function(prevState, props){
            return {
                //tracking: !prevState.tracking
                tracking: prevState.tracking === 0 ? 2 : 0
            };
        },
        function(){
            this.props.onTrackNode(this.props.node.node, this.state.tracking);
            if (this.state.tracking) {
                this.handleLocateNode(null);
            }
        });
    }

    handleNodeSelect() {
        if (!this.state.hasLoc) {return;}

        this.props.onNodeSelect('nodeList', this.props.node.node);
        this.setState(function(prevState, props){
            return {
                selected: !prevState.selected
            };
        });
    }

    //if lps_control.power_enable = 0 blank out all buttons
    //node selected not working
    render() {
        let trackingText = "";
        let trackingClass = "";

        if (this.state.tracking) {
            trackingText = "STOP TRACKING";
            trackingClass = "node-tracking";
        }
        else {
            trackingText = "TRACK";
            trackingClass = "node-not-tracking";
        }

        let nodeSelClass = "";
        if (this.props.node.selected) {
            nodeSelClass = "node-selected";
        }
        else {
            nodeSelClass = "node-not-selected";
        }

        return (<li className={nodeSelClass} onClick={this.handleNodeSelect}>
                <div>
                    <label title={this.props.node.node}>{this.props.node.name}</label>
                    {
                      this.props.node.configs.lps_control.power_enable ?
                        (<button onClick={(e) => this.handleLocateNode(e)} className="locate">LOCATE</button>):
                         <button className="locate" disabled>LOCATE</button>
                    }
                    {
                      this.props.node.configs.lps_control.power_enable ?
                        (this.state.hasMaster ? <button onClick={(e) => this.handleTdoaNode(e)} className="locate">TDOA</button> : "") :
                        <button className="locate" disabled>TDOA</button>
                    }
                    {
                      this.props.node.configs.lps_control.power_enable?
                        (<button onClick={(e) => this.handleTrackNode(e)} className={trackingClass}>{trackingText}</button>) :
                        <button className="trackingClass" disabled>{trackingText}</button>
                    }
                </div>
                </li>)
    }
}
