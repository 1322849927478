import React from 'react';
import { IMG_CONFIG } from './2dConfig';

const NavFloorControl = (props) => (
    <div className="NavFloorContainer">
      <img key="All" alt="All" data-source="All" src={props.floors['All'] ? IMG_CONFIG["All"]['active'].img : IMG_CONFIG["All"]['inactive'].img} onClick={props.onSetFloor}/>
      {
        props.floor_order.map(f =>
          <img key={f} alt={f} data-source={f} src={props.floors[f] ? IMG_CONFIG[f]['active'].img : IMG_CONFIG[f]['inactive'].img} onClick={props.onSetFloor}/>
        )
      }
    </div>
);

export default NavFloorControl;

/*
<img alt="all" data-source="All" src={IMG_CONFIG['All']['active'].img}/>
<img alt="all" src={IMG_CONFIG['1']['active'].img}/>
<img alt="all" src={IMG_CONFIG['2']['active'].img}/>
*/
