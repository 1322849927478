import React from 'react';
import  NavNode  from './NavNode'

class NavList2d extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display_forklift_list: true,
      display_worker_list: true,
      display_all_forklift: true,
      display_all_workers: true,
    };

    this.toggle_forklift_list = this.toggle_forklift_list.bind(this);
    this.toggle_worker_list = this.toggle_worker_list.bind(this);
    this.toggle_all_forklift = this.toggle_all_forklift.bind(this);
    this.toggle_all_workers = this.toggle_all_workers.bind(this);
  }

  toggle_forklift_list() {
    this.setState((prevState, props) => ({display_forklift_list: !prevState.display_forklift_list}));
  }

  toggle_worker_list() {
    this.setState((prevState, props)=> ({display_worker_list: !prevState.display_worker_list}));
  }

  toggle_all_forklift(e) {
    this.setState({display_all_forklift: e.target.checked})
    this.props.onSelectAll(e)
  }

  toggle_all_workers(e) {
    this.setState({display_all_workers: e.target.checked})
    this.props.onSelectAll(e)
  }

  render() {
    let workers = this.props.nodes.filter(n=> n.type === 'worker' && n.hasOwnProperty("meas_id")).sort((a,b) => a.name.localeCompare(b.name));
    let nWorkers = this.props.nodes.filter(n=> n.type === 'worker' && !n.hasOwnProperty("meas_id")).sort((a,b) => a.name.localeCompare(b.name)); //no location
    let forklifts = this.props.nodes.filter(n=> n.type === 'forklift' && n.hasOwnProperty("meas_id")).sort((a,b) => a.name.localeCompare(b.name));
    let nForklifts = this.props.nodes.filter(n=> n.type === 'forklift' && !n.hasOwnProperty("meas_id")).sort((a,b) => a.name.localeCompare(b.name)); //no location

    return (
      <div style={this.props.display ? {display: "inline-block" } : {display: "none"}}>
        <div className="NavList2D">
        <div className="AvailableDevices">AVAILABLE DEVICES</div>
          <div style={forklifts.length === 0 && nForklifts.length === 0 ? {display: "none"} : {}}>
            <input type="checkbox" className="CheckBoxAll" data-type="forklift" defaultChecked={this.state.display_all_forklift} onChange={this.toggle_all_forklift}/>
            <div className="CheckBoxAllText">FORKLIFTS</div>
            { this.state.display_forklift_list ?
                (<img className="ToggleDown" alt="ToggleDown" src={require("./img/ToggleDown.png")} onClick={this.toggle_forklift_list}/>) :
                (<img className="ToggleDown" alt="ToggleDown" src={require("./img/ToggleUp.png")} onClick={this.toggle_forklift_list}/>)
            }
          </div>
          <div className="Line"/>
          <div style={this.state.display_forklift_list ? {}: {display: "none"}}>
            {forklifts.map(n=><NavNode key={n.id} node={n} onSelect={this.props.onSelect} onLocate={this.props.onLocate} checked={this.props.showNodes[n.id].show} disable={false} onShowCard={this.props.onShowCard}/>)}
            {nForklifts.map(n=><NavNode key={n.id} node={n} onLocate={this.props.onLocate} checked={false} disable={true}/>)}
          </div>

          <div style={workers.length === 0 && nWorkers.length === 0 ? {display: "none"} : {}}>
            <input type="checkbox" className="CheckBoxAll" data-type="worker" defaultChecked={this.state.display_all_workers} onChange={this.toggle_all_workers}/>
            <div className="CheckBoxAllText">WORKERS</div>
            { this.state.display_worker_list ?
              (<img className="ToggleDown" alt="ToggleDown" src={require("./img/ToggleDown.png")} onClick={this.toggle_worker_list}/>) :
              (<img className="ToggleDown" alt="ToggleDown" src={require("./img/ToggleUp.png")} onClick={this.toggle_worker_list}/>)
            }
          </div>
          <div className="Line"/>
          <div style={this.state.display_worker_list ? {} : {display: "none"}}>
            {workers.map(n=><NavNode key={n.id} node={n} onSelect={this.props.onSelect} onLocate={this.props.onLocate} checked={this.props.showNodes[n.id].show} disable={false} onShowCard={this.props.onShowCard}/>)}
            {nWorkers.map(n=><NavNode key={n.id} node={n} onLocate={this.props.onLocate} checked={false} disable={true}/>)}
          </div>

          <div style={this.props.maptype === 'realtime' || this.props.maptype.startsWith("path") ? {} : {display: "none"}}>
            <input type="checkbox" className="CheckBoxAll"  defaultChecked={this.props.displayAnchors} onChange={this.props.showAnchors}/>
            <div className="CheckBoxAllText">SHOW ANCHORS</div>
            <div className="Line"/>
          </div>
        </div>
      </div>
    )
  }
}

export default NavList2d;
