import React,{useState , useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import './Lapis.css';

const KEY = "AIzaSyAS2p-NntAUWlYnL8cODmzhYDNJL85GP6Q";
const DEFAULT = {
  orig_zoom: 1,
  lat: 36,
  long: 138,
  zoom: 8,
}

const SELECTED = {color: "#FF0000"};
const Marker = (props: any) => {
    const { color, name, id, selected } = props;
    return (
      <div className="fa fa-map-marker lapis-truck" style={selected ? SELECTED : {}} onClick={()=>console.log(name)}/>
    );
  };

function LapisSdMap(props){
    const [truck, setTruck ] = useState(props.truck)
    const [lat, setLat] = useState(DEFAULT.lat)
    const [long, setLong] = useState(DEFAULT.long)
    const [zoom, setZoom] = useState(DEFAULT.orig_zoom)
    const [markers, setMarkers] = useState([])

    useEffect(() => {
      console.log("useEffect")
      setTruck(props.truck);

      if (props.truck !== null) {
        setLat(props.gps[props.truck].lat)
        setLong(props.gps[props.truck].long)
        setZoom(DEFAULT.zoom)
      } else {
        setZoom(DEFAULT.orig_zoom)
        setLat(DEFAULT.lat)
        setLong(DEFAULT.long)
      }

      //create markers
      let markers = [];
      for (const truck in props.gps) {
        markers.push(
          <Marker
            key={truck}
            name={truck}
            id={truck}
            lat={props.gps[truck].lat}
            lng={props.gps[truck].long}
            selected={truck === props.truck ? true : false}
          />
        )
      }
      setMarkers(markers);
   }, [props])

    return(
        <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{key: KEY}}
            defaultCenter={{ lat: DEFAULT.lat, lng: DEFAULT.long}}
            center={{lat: lat, lng: long}}
            defaultZoom={DEFAULT.orig_zoom}
            zoom={zoom}
        >
        {markers}
        </GoogleMapReact>
    )
}

export default LapisSdMap;

/*

const Map = ({ latitude, longitude, truck }) => {
  console.log(latitude);
  console.log(longitude);
  console.log(truck);
   const renderMarkers = (map, maps) => {
      let marker = new maps.Marker({
        position: { lat: latitude, lng: longitude },
        map,
        label: truck,
      });
      return marker;
   };

 return (
    <GoogleMapReact
      bootstrapURLKeys={{key:  "AIzaSyAS2p-NntAUWlYnL8cODmzhYDNJL85GP6Q"}}
      defaultCenter={{ lat: 34, lng: 132 }}
      defaultZoom={12}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    >
    </GoogleMapReact>
 );
};


class LapisSdMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: 34,
      long: 132,
      truck: "test",
    };

  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.truck !== this.props.truck) {
      console.log("here")
      this.setState({lat: this.props.latitude, long: this.props.longitude, truck: this.props.truck})
    }
  }

 renderMarkers(map, maps) {
     let marker = new maps.Marker({
       position: { lat: this.state.lat, lng: this.state.long },
       map,
       label: this.state.truck,
     });
     return marker;
  };

  render() {
    return (
    <div className="lapis-chart">
    <GoogleMapReact
      bootstrapURLKeys={{key:  "AIzaSyAS2p-NntAUWlYnL8cODmzhYDNJL85GP6Q"}}
      defaultCenter={{ lat: 34, lng: 132 }}
      defaultZoom={12}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
    >
    </GoogleMapReact>

    </div>
    )
  }
}
export default LapisSdMap;


/*
const LapisSdMap = ({ latitude, longitude }) => {
  console.log(latitude)
  console.log(longitude)

  console.log(Math.random())
 const renderMarkers = (map, maps) => {
  let marker = new maps.Marker({
  position: { lat: (latitude + Math.random()), lng: longitude },
  map,
  title: 'Hello World!'
  });
  return marker;
 };

 return (
   <div className="lapis-chart">
    <GoogleMapReact
      bootstrapURLKeys={{key:  "AIzaSyAS2p-NntAUWlYnL8cODmzhYDNJL85GP6Q"}}
      defaultCenter={{ lat: 34, lng: 132 }}
      defaultZoom={5}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
    >
    </GoogleMapReact>
   </div>
 );
};

export default LapisSdMap;
*/
/*
const AnyReactComponent = ({text}: any) => <div>{text}</div>;

const LapisSdMap = (props: any) => {
    const [center, setCenter] = useState({lat: 11.0168, lng: 76.9558 });
    const [zoom, setZoom] = useState(11);
    return (
        <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyAS2p-NntAUWlYnL8cODmzhYDNJL85GP6Q"}}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <AnyReactComponent
            lat={11.0168}
            lng={76.9558}
            text="BARBARA MARKER"
          />
        </GoogleMapReact>
      </div>
    );
}

export default LapisSdMap
*/

/*const defaultProps = {
    center: {
      lat: 34,
      lng: 133,
    },
    zoom: 11,
  };

  const Marker = props => {
    return <div className="SuperAwesomePin"></div>
  }
//34.583644128194
//133.54472383446816

const KEY = "AIzaSyAS2p-NntAUWlYnL8cODmzhYDNJL85GP6Q"
//Make Stateless Functional Component
const LapisSdMap = ({ data}) => {
  return (
      <div className="lapis-chart">
        <GoogleMapReact
          bootstrapURLKeys={{ key: KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
        <Marker lat={34.583644128194} lng={133.54472383446816}/>
        </GoogleMapReact>
      </div>
   );
}

export default LapisSdMap;
*/
