import React from 'react';
import { connect } from 'react-redux';
import { calibrateAnchor, calibrateNode } from '../../../modules/nodes';

const CalibrateButton = (props) => {
  const handleCalibrate = (e) => {
    props.type === "anchor" ?
      props.dispatch(calibrateAnchor(props.node))
      :
      props.dispatch(calibrateNode(props.node))

    props.handleCalibrate('success');
  }

  return (
    <div className="calibrate restart">
      <button onClick={()=>handleCalibrate()}>Calibrate</button>
    </div>
  )
}

export default connect()(CalibrateButton);
