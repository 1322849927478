import React from 'react';
import { connect } from 'react-redux';
import CameraEventList from './CameraEventList';
import ConstellationButtons from '../constellations/ConstellationButtons';
import './HistoryOverlay.css';

class ConstellationHistoryOverlay extends React.Component {
  render() {
    return (
      <div className="history-overlay">
        <CameraEventList nodes={this.props.nodes} heading={<ConstellationButtons id={this.props.constellation} />}/>
      </div>
    )
  }
}


export default connect(
  (state, ownProps) => ({
    nodes: ownProps.nodes,
    constellation: ownProps.constellation.constellation
  })
)(ConstellationHistoryOverlay);
