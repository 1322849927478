import React from 'react';
import './Common.css';

const style = {
  margin: "0px 0px 10px 0px",
  width: "280px",
  height: "10px",
  borderRadius: "2px",
  border: "solid 0.5px #979797",
  paddingLeft: "30px",
  textAlign: "left",
  outline: "none",
};

const Filter = ({data, setNewData}) => {

  const searchMe = (e) => {
    let re =  new RegExp(e.target.value, "i");

    let found = data.filter(row =>
      //brute force pattern matching
      JSON.stringify(Object.values(row)).match(re)
    )

    setNewData(found);
  };

  return(
    <div className="common" style={{display: "inline-block"}}>
      <input style={style} type="text" onChange={(e)=>searchMe(e)}/>
      <div className="fa fa-search searchIconStyle" />
    </div>
  )
};

export default Filter;
