import React, {useState/*, useEffect*/} from 'react';

import { checkSiteACL} from '../../acl/Config';
import { setCookie, getCookie } from '../../common/Util';

const MscsBulletin = ({acl, site}) => {
  const [display, setDisplay] = useState(true);

  const onSetValue = (name, e) => {
    setCookie(name, e.target.value, 100);
  }

  const DISPLAY =
    <div className="mscsBulletin">
      {getCookie("mscs1") ? getCookie("mscs1"): "Target for the Week"}
      {checkSiteACL(site.customer, site.site, acl, 'Mscs Edit Button') &&<button onClick={()=>setDisplay(!display)}/>}
      <div className="container">
        <div className="subcontainer">
          <div className="field">
            {getCookie("mscs2") ? getCookie("mscs2"): "Items Picked"}
          </div>
          <div className="value">
            {getCookie("mscs3") ? getCookie("mscs3"): "83 items/hour"}
          </div>
        </div>
        <div className="subcontainer">
          <div className="field">
            {getCookie("mscs4") ? getCookie("mscs4"): "Walking Distance"}
          </div>
          <div className="value">
            {getCookie("mscs5") ? getCookie("mscs5"): "Greater than 8500m"}
          </div>
        </div>
      </div>
    </div>

  const EDIT =
    <div className="mscsBulletin">
      <input type="text" name="mscs1" onChange={(e)=>onSetValue("mscs1", e)}/>
      {checkSiteACL(site.customer, site.site, acl, 'Mscs Edit Button') &&<button onClick={()=>setDisplay(!display)}/>}
      <div className="container">
        <div className="subcontainer">
          <div className="field">
          <input type="text" name="mscs2" onChange={(e)=>onSetValue("mscs2", e)}/>
          </div>
          <div className="value">
            <input type="text" name="mscs3" onChange={(e)=>onSetValue("mscs3", e)}/>
          </div>
        </div>
        <div className="subcontainer">
          <div className="field">
            <input type="text" name="mscs4" onChange={(e)=>onSetValue("mscs4", e)}/>
          </div>
          <div className="value">
            <input type="text" name="mscs5" onChange={(e)=>onSetValue("mscs5", e)}/>
          </div>
        </div>
      </div>
    </div>

  return (
    <div>
      {display ? DISPLAY : EDIT}
    </div>
  )
}

export default MscsBulletin;
