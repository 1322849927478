import React from 'react';
import { connect } from 'react-redux';
import { fetchMediaBlob } from '../../modules/api';
import NodeOverlay from '../devices/NodeOverlay';
import './HistoryOverlay.css';

class PastCameraEventContainer extends React.Component {
  constructor(props) {
    super(props);
    let media = {};
    try {
      media = this.props.node.update.events.camera.media_ready;
    } catch (err) {
      console.warn('No media found', err);
    }
    this.state = {
      mediaFormat: media.format,
      key: media.key,
      srcUrl: require('../../media/Loading_Img.svg'),
    }
  }

  componentDidMount() {
    let media = {}
    try {
      media = this.props.node.update.events.camera.media_ready;
    } catch (err) {
      console.warn('No media found', err);
    }
    //setTimeout(() => {
      fetchMediaBlob(media.key, this.props.authToken)
        .then(srcUrl => this.setState({ srcUrl }))
    //}, 1000)
  }

  componentWillReceiveProps(nextProps) {
    /*let media = {};
    let oldMedia = {}
    try {
      media = nextProps.node.update.events.camera.media_ready;
      oldMedia = this.props.node.update.events.camera.media_ready;
    } catch (err) {
      console.warn('No media found', err);
    }
    if (oldMedia.key !== media.key) {
      this.setState({
        mediaFormat: "image",
        key: media.key,
        srcUrl: require('../../media/Loading_Img.svg'),
        node: nextProps.node
      })

      setTimeout(() => {
      fetchMediaBlob(media.key, this.props.authToken)
        .then(srcUrl => {
          this.setState({ srcUrl, mediaFormat: media.format, key: media.key })
        })
      }, 1000)
    }*/
  }

  render() {
    let alert = <div></div>
    let alert_text = <div></div>
    if (this.props.node.update.events.camera.media_ready.type === 'alarm') {
      alert = <div className="past-event-alarm-overlay"><i className="fa fa-warning"></i></div>
      alert_text = <div className="past-event-alarm-text" style={{color: "red"}}>Alarm</div>
    } else if (this.props.node.update.events.camera.media_ready.type === 'action') {
      alert = <div className="past-event-action-overlay"><i className="fa fa-adn"></i></div>
      alert_text = <div className="past-event-alarm-text" style={{color: "green"}}>User Capture</div>
    } else {
      //do nothing
    }

    switch (this.state.mediaFormat) {
      case 'image':
        return (
          <div className="event-frame">
            <img className="event-frame-img" alt={this.state.srcUrl} src={this.state.srcUrl}/>
            <div>
            <NodeOverlay node={this.props.node} />
            <button onClick={()=>{this.props.onEventClick(this.props.node)}} className="modal-button"><i className="fa fa-search" aria-hidden="true"></i></button>
            <div className="past-alert-container">
              {alert}
              {alert_text}
            </div>
            </div>
          </div>
        );
      case 'video':
        return (
          <div className="event-frame">
            <video autoPlay loop muted width="100%" height="100%" src={this.state.srcUrl} type={""}/>
            <NodeOverlay node={this.props.node} />
            <button onClick={()=>{this.props.onEventClick(this.props.node)}} className="modal-button"><i className="fa fa-search" aria-hidden="true"></i></button>
            <div className="past-alert-container">
              {alert}
              {alert_text}
            </div>
            </div>
        );
      default:
        return (
          <div className="event-frame">
            <div className="event-frame no-media">
              <NodeOverlay node={this.props.node} />
            </div>
          </div>
        );
    }
  }
}

//window.fetchMediaBlob = fetchMediaBlob

export default connect(
  state => ({ authToken: state.authToken })
)(PastCameraEventContainer);
