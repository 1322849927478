import React from 'react';
import { Stage, Layer, Group, Rect, Text, Image, Line, Circle} from 'react-konva';

import { getRandomColor } from '../common/Util';
import { connect } from 'react-redux';
import { fetchObjectHistory } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import { logoutNode } from '../../modules/nodes';

const PIXEL_ADJUSTMENT_X = 0;
const PIXEL_ADJUSTMENT_Y = 30;
const METERS_PER_FOOT = 0.3048;

function formatTime(m) {
  if (m) {
    let d = new Date(m);
    let hour = d.getHours().toString();
    let min = d.getMinutes().toString();;
    let sec = d.getSeconds().toString();;

    if (hour.length === 1 ) { hour = "0" + hour }
    if (min.length === 1) { min = "0" + min }
    if (sec.length === 1) {sec = "0" + sec}

    return hour + ":" + min + ":" + sec;
  } else {
    return "00:00:00";
  }
}

class NavMap2dPath extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      x_img: null,
      nodes: {},
      node_info: {},
      paths: {},
      circles: [],
      anchors: {},
      text: "",
      zoom: 1,
      loading: false,
      zoom_in: null,
      zoom_out: null,
      reset: null,
    };

    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.drag = this.drag.bind(this);
    this.wheelZoom = this.wheelZoom.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onClear = this.onClear.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    console.log("mounted")
    this.loadImages();
  }

  componentWillUnmount() {
    console.log("Unmounting Path")
    this.unloadImages();
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("./img/" + this.props.background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));

    let x = new window.Image();
    x.src = require("./img/x.png");
    bg.addEventListener('load', () => this.setState({x_img: x}));

    let zoom_in = new window.Image();
    zoom_in.src = require('./img/zoom_in.png');
    zoom_in.addEventListener('load', ()=> this.setState({zoom_in: zoom_in}));

    let zoom_out = new window.Image();
    zoom_out.src = require('./img/zoom_out.png');
    zoom_out.addEventListener('load', ()=> this.setState({zoom_out: zoom_out}));

    let reset = new window.Image();
    reset.src = require('./img/reset.png');
    reset.addEventListener('load', ()=> this.setState({reset: reset}));
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));
    this.state.x_img.removeEventListener('load', () => this.setState({x_img: null}));
    this.state.zoom_in.removeEventListener('load', ()=>this.setState({zoom_in: null}));
    this.state.zoom_out.removeEventListener('load', ()=>this.setState({zoom_out: null}));
    this.state.reset.removeEventListener('load', ()=>this.setState({reset: null}));
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.state.anchors).length !== Object.keys(this.props.anchors).length) {
      let newAnchors = {};
      for (const anchorId in this.props.anchors) {
        const anchor = this.props.anchors[anchorId];
        let newX = (anchor.x + this.props.origin.x) * this.props.pixels_per_meter;
        let newY = this.props.height - ((anchor.y  + this.props.origin.y) * this.props.pixels_per_meter);
        newAnchors[anchorId] = {nodeId: anchorId, x: anchor.x, y: anchor.y, new_x: newX , new_y: newY, selected: false};
      }
      this.setState({anchors: newAnchors});
    }

    if (Object.keys(this.state.nodes).length !== Object.keys(this.props.nodes).length ||
        prevProps.pixels_per_meter !== this.props.pixels_per_meter ||
        prevProps.from !== this.props.from ||
        prevProps.to!== this.props.to) {

      console.log("Path component update");
      let nodes = this.refs['nodes'];
      nodes.destroyChildren();

      let newNodes = {};
      let paths = {...this.state.paths};

      for (const nodeId in this.props.nodes) {
        const node = this.props.nodes[nodeId];
        let newX = (node.x + this.props.origin.x) * this.props.pixels_per_meter - PIXEL_ADJUSTMENT_X;
        let newY = this.props.height - ((node.y + this.props.origin.y) * this.props.pixels_per_meter) - PIXEL_ADJUSTMENT_Y;
        newNodes[nodeId] = { name: node.name, nodeId: nodeId, x: node.x, y: node.y, new_x: newX, new_y: newY, selected: false, type: node.type, timestamp: node.timestamp, meas_id: node.meas_id};

        if (!paths.hasOwnProperty(nodeId)) { //initiate new nodes
          paths[nodeId] = {type: '', color: getRandomColor(), path: []}
        }
      }

      for (const nodeId in paths) { paths[nodeId].path = [] } //clear out the paths
      this.setState({nodes: newNodes, paths: paths, circles: []}, ()=>Object.keys(this.state.nodes).forEach(n => this.fetch_all_history(n, [], null)));
    }
  }

  fetch_all_history (n, history, last_key) {
    this.setState({loading: true});
    console.log("Node: " + n + " Key: " + last_key)
    var getHistory = (last_key === null || last_key === undefined) ?
      fetchObjectHistory(n, this.props.authToken, this.props.currentSiteId, {event: "tracker:location", from: this.props.from, to: this.props.to, limit: 10000}) :
      fetchObjectHistory(n, this.props.authToken, this.props.currentSiteId, {event: "tracker:location", from: this.props.from, to: this.props.to, limit: 10000, start_key: last_key})

    getHistory.then(json => {
      json.hasOwnProperty('last_key') ?
       this.fetch_all_history(n, history.concat(json.history), json.last_key) :
       this.makePath(n, history.concat(json.history))
      }).catch(error => { error.status === 401 ? this.props.dispatch(logoutNode()) : console.log(error)})
  }

  //{"timestamp":1562668711226,"version":219091,
  //"update":{"events":{"tracker":{"location":
  //{"filtered":[334,68.03544,3.3],"unfiltered":[334,68.03544,3.3],"type":"motion_stopped","timestamp":1562668708840,"x":334,"y":68.03544,"z":3.3,"ranges":7,"meas_id":"0000016bd64c6624"}}}}}
  makePath(nodeId, historyArray) {
    let paths = {...this.state.paths};
    let circles = [...this.state.circles];
    //let unique = {};
    let meas_id = null;

    historyArray.forEach((history) => {
      //console.log(JSON.stringify(history));
      if (history.update.events.tracker.location.meas_id === meas_id) { console.log("filtered"); return; } else { meas_id = history.update.events.tracker.location.meas_id}
      let newX = (history.update.events.tracker.location.x * METERS_PER_FOOT + this.props.origin.x) * this.props.pixels_per_meter
      let newY = this.props.height - ((history.update.events.tracker.location.y * METERS_PER_FOOT + this.props.origin.y) * this.props.pixels_per_meter)
      paths[nodeId].path.push(newX)
      paths[nodeId].path.push(newY)

      let key = nodeId + "_" + history.update.events.tracker.location.meas_id + history.timestamp;
      //if (unique.hasOwnProperty(key)) {console.log("repeat");return} else {unique[key] = 1;}
      let data = this.state.nodes[nodeId].name + ", " +
                 "Meas_id:" + history.update.events.tracker.location.meas_id + ", " +
                 "Timestamp:" + formatTime(history.update.events.tracker.location.timestamp) + ", " +
                 "Type:" + history.update.events.tracker.location.type + ", " +
                 "Ranges:" + history.update.events.tracker.location.ranges + ", " +
                 "X:" + history.update.events.tracker.location.x.toFixed(1) + ", " +
                 "Y:" + history.update.events.tracker.location.y.toFixed(1);

      circles.push(<Circle key={key} data={data} radius={3} fill={paths[nodeId].color}  x={newX} y={newY} onMouseOver={this.onHover} onMouseOut={this.onClear}/>) //onMouseOut={this.onClear}
    })//end history

    console.log(nodeId + " length:" + circles.length)
    this.setState({loading: false, paths: paths, circles: circles}, ()=>console.log("Node: " + nodeId + " MakePath done"));
  }

  zoomIn(e) {
    if (this.state.zoom >= 2) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom + .1}))
    layer.scale({x: this.state.zoom , y: this.state.zoom})
    layer.draw();
  }

  zoomOut(e) {
    if (this.state.zoom <= 1) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom - .1}))
    layer.scale({x: this.state.zoom, y: this.state.zoom})
    layer.draw();
  }

  drag(e) {
    let layer = this.refs['map']
    layer.startDrag();
  }

  wheelZoom(e) {
    /*
    if (e.evt.deltaY > 0) {
      this.zoomOut(e)
    } else {
      this.zoomIn(e)
    }
    */
  }

  reset(e) {
    let map = this.refs['map']
    map.scale({x: 1, y: 1});
    map.setPosition({x: 0, y: 0});
    map.draw();
    this.setState({zoom: 1});
  }

  onHover(e) {
    this.setState({text: e.target.attrs.data})
  }

  onClear(e) {
    this.setState({text: ""})
  }

   render() {
    let showPaths = [];
    for(const nodeId in this.state.paths) {
      const color = this.state.paths[nodeId].color;
      const p = this.state.paths[nodeId].path;
      if (p.length === 0 ) {continue}

      /*
      dash={[10, 5]
      dash={[1, 10]
      tension={.5}
      */

      showPaths.push(<Line key={nodeId + "_path"} node={this.state.nodes[nodeId]} ref={nodeId} points={p} stroke={color} strokeWidth={1}/>);
    }

    let textInfo = [];
    if (this.state.text !== "") {
      textInfo.push(<Rect key="textInfo" x={5} y={0} width={1000} height={30} fill={'#ffffff'} stroke={'rgba(0, 0, 0, 0.25'}/>);
      textInfo.push(<Text key="textINfo2" x={10} y ={10} text={this.state.text} fontSize={14} fontFamily={'Lato'} fill={'#0097ff'}/>);
    }

    let showAnchors = []
    for (const nodeId in this.state.anchors) {
      const n = this.state.anchors[nodeId];
      let thisNode = <Rect fill={'#44d7b6'} width={8} height={8} x={n.new_x} y ={n.new_y - 5} key={n.nodeId} />
      showAnchors.push(thisNode);
    }

    /*for (const nodeId in this.state.node_info) {
      const n = this.state.node_info[nodeId];

      let offset_x = 0
      if (this.props.width - n.new_x <= 130 ) {
        offset_x = (120 - (this.props.width - n.new_x)) * -1 //left
      } else if (n.new_x <= 130) {
        offset_x = 100 - n.new_x //right
      }

      let offset_y = 0
      if (n.new_y <= 130) { offset_y = 160 } //top

      let rect =  <Rect onClick={this.props.onShowCard} node={n} x={n.new_x - 100 + offset_x}  y ={n.new_y - 140 + offset_y} width={230} height={130} key={n.nodeId + "_box"} ref={nodeId + "_box"} fill={'#ffffff'} stroke={'rgba(0, 0, 0, 0.25'}/>
      let thisNodeInfo = <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 125 + offset_y} key={n.nodeId + "_info"} text={n.name} fontSize={16} fontFamily={'Lato'} fill={'#0068b0'}/>
      let thisNodeInfo2 = <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 95 + offset_y} key={n.nodeId + "_info2"} text={"ACTIVE TIME"} fontSize={10} fontFamily={'Lato'} fill={'#656565'}/>
      let thisNodeInfo3 = <Text x={n.new_x + 45 + offset_x} y ={n.new_y - 95 + offset_y} key={n.nodeId + "_info3"} text={"LAST LOCATE"} fontSize={10} fontFamily={'Lato'} fill={'#656565'}/>
      let thisNodeInfo4 = <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 80 + offset_y} key={n.nodeId + "_info4"} text={'00:00:00'} fontSize={14} fontFamily={'Lato'} fill={'#132c4f'}/>
      let thisNodeInfo5 = <Text x={n.new_x + 45 + offset_x} y ={n.new_y - 80 + offset_y} key={n.nodeId + "_info5"} text={n.timestamp ? formatTime(n.timestamp) : formatTime(n.meas_id)} fontSize={14} fontFamily={'Lato'} fill={'#132c4f'}/>
      let thisNodeInfo6 = <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 55 + offset_y} key={n.nodeId + "_info6"} text={"DISTANCE TRAVELLED"} fontSize={10} fontFamily={'Lato'} fill={'#656565'}/>
      let thisNodeInfo7 = <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 40 + offset_y} key={n.nodeId + "_info7"} text={'0m'} fontSize={14} fontFamily={'Lato'} fill={'#132c4f'}/>
      let thisNodeInfo8 = <Image image={this.state.x_img} x={n.new_x + 110 + offset_x} y ={n.new_y - 130 + offset_y} node={n} key={n.nodeId + "_info8"} onClick={this.props.onShowCard}/>
      showNodeInfo.push(rect, thisNodeInfo, thisNodeInfo2, thisNodeInfo3, thisNodeInfo4, thisNodeInfo5, thisNodeInfo6, thisNodeInfo7, thisNodeInfo8,);
    }*/

    return (
      <div className="NavMap2D">
      <div className="loading" style={this.state.loading? {}: {display: "none"}}>LOADING...</div>
        <Stage width={this.props.width} height={this.props.height >= 700 ? this.props.height : 700}>
          <Layer ref="map" onDblClick={this.zoomIn} onMouseDown={this.drag} onWheel={this.wheelZoom} y={this.props.height >= 700 ? 0 : (700-this.props.height)/2}>
            <Image
              x={0}
              y={0}
              width = {this.props.width}
              height= {this.props.height}
              image={this.state.background}
            />
            <Group ref="nodes">
              {this.state.circles}
              {showPaths}
              {textInfo}
            </Group>
              {showAnchors}
            </Layer>
            <Layer>
              <Image image={this.state.reset} x={this.props.width - 40} y={5} width={30} height={30} key={'resetImg'} onClick={this.reset}/>
              <Image image={this.state.zoom_in} x={this.props.width - 40} y={40} width={30} height={30} key={'zoomInImg'} onClick={this.zoomIn}/>
              <Image image={this.state.zoom_out} x={this.props.width - 40} y={75} width={30} height={30} key={'zoomOutImg'} onClick={this.zoomOut}/>
            </Layer>
        </Stage>
      </div>
    )
  }
}

export default connect(
  state => ({ authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(NavMap2dPath);

/*
{showNodeInfo}
{{api}}/objects/{{node}}/history?event=tracker:location&last=86400000
24 hours - 86400000, 12 hours - 13200000, 6 hours - 21600000, 10 min - 600000, 5 min - 300000, 1 min - 60000
type = this.state.nodes[n.id].type
from: 1546761600000, to:1546934400000
*/
