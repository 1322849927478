import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import { format } from './Util';

const DateRangeTime = ({show, timeArray, setShow, onSetCalendarDate}) => { //timeArray = [{from: start, to: stop}]
  const [start, setStart] = useState(timeArray[0].from); //date instance
  const [end, setEnd] = useState(timeArray[timeArray.length - 1].to); //date instance
  const [showWeekday, setShowWeekday] = useState(false);
  const [weekday, setWeekday] = useState([false, true, true, true, true, true, false]); //0-6, sun-sat
  const [allday, setAllday] = useState(false); //all day flag
  const [hour, setHour] = useState(["09", "00", "17", "00"]); //from hour, from min, tohour, to min
  const [error, setError] = useState("");

  useEffect(()=> {
    if (!show) { return }
    //calculate the earliest and latest timestamps, assume from to always the same
    let start = 0;
    let end = 0;
    timeArray.forEach((item, i) => {
      if (start === 0) {
        start = item.from;
      }

      if (item.from < start ) { start = item.from }
      if (item.to > end) { end = item.to }
    });

    //convert to date instance for calendar
    start = new Date(start);
    start.setHours(0,0,0);
    end = new Date(end);
    end.setHours(0,0,0);

    setStart(start);
    setEnd(end);
    setHour([format(new Date(timeArray[0].from).getHours()), format(new Date(timeArray[0].from).getMinutes()), format(new Date(timeArray[0].to).getHours()), format(new Date(timeArray[0].to).getMinutes())]);
    setError("");

    /*if (timeArray.length == 1) { //if more than 1 interval, toggle flags
      setShowWeekday(false);
      setAllday(false);
    }*/

  }, [show, timeArray])

  const onSelectDay = (index) => {
    let temp = [...weekday];
    temp[index] = !weekday[index];
    setWeekday(temp);
  }

  const onSelectAllDay = () => {
    let temp = allday;
    if (!temp) {
      setHour(["00","00", "23","59"]);
    }
    setAllday(!temp);
  }

  const onSetValue = (index, e) => {
    e.target.value = e.target.value.replace(/[^0-9]+/, '');
    if ((index ===  0 || index === 2) && e.target.value > 23) { return; }
    if ((index === 1 || index === 3) && e.target.value > 59) { return; }

    let temp = [...hour];
    temp[index] = format(e.target.value);
    setHour(temp);
  }

  const onUpDown = (index, e) => {
    let temp = [...hour];
    if (e.target.dataset.name === 'up') {
      temp[index] = parseInt(hour[index]) + 1;
    }

    if (e.target.dataset.name === 'down') {
      temp[index] = parseInt(hour[index]) - 1;
    }
    if (temp[index] < 0) { return;}
    if ((index ===  0 || index === 2) && temp[index] > 23) { return; }
    if ((index ===  1 || index === 3) && temp[index] > 59) { return; }

    temp[index] = format(temp[index]);
    setHour(temp);
  }

  const onSetApply = () => {
    //Error Checking
    if (!start) {
      setError("Invalid Start Date Selection");
      return;
    }

    let tempEnd = end;
    if (!end) {
      setEnd(start);
      tempEnd = start;
    }

    let intervals = [];
    if (showWeekday) {   //if showWeekday then calculate biz hour intervals
      let temp = start.getTime(); //convert to milliseconds
      while (temp <= new Date(tempEnd).setHours(23, 59,59, 999)) {
        let day = new Date(temp).getDay();
        if (weekday[day]) {
          intervals.push({from: new Date(temp).setHours(hour[0], hour[1], 0), to: new Date(temp).setHours(hour[2], hour[3], allday? 59: 0)});
        }
        temp = temp + 86400000;
      }
    } else { //no biz hour
      intervals.push({from: new Date(start).setHours(hour[0], hour[1], 0), to: new Date(tempEnd).setHours(hour[2], hour[3], allday? 59: 0)}) //milliseconds
    }

    //Error Checking
    if (intervals[0].from >= intervals[0].to || intervals[0].from >= intervals[intervals.length - 1].to) {
      setError("Invalid Time Selection");
      return;
    }

    console.log("Intervals: " + JSON.stringify(intervals));
    intervals.forEach((item, i) => {
      console.log(i + " - from: " +  new Date(item.from).toString() + " to: " + new Date(item.to).toString());
    });

    let config = {from: intervals[0].from, to: intervals[intervals.length - 1].to, allday: allday, week: weekday, hour: hour};
    onSetCalendarDate(intervals[0].from, intervals[intervals.length - 1].to, intervals, config);
  }

  const onChangeThis = dates => {
    const [start, end] = dates;
    setStart(start);
    setEnd(end);
  };

  return (
    <div className="common">
      <div className="daterangetime" style={{display: show ? "block" : "none"}}>
      <div style={{display: "inline-block"}}>
        <DatePicker
          onChange={onChangeThis}
          startDate={start}
          endDate={end}
          selectsRange
          inline
          />
        </div>
        <div className="timeform">
        <input type="checkbox" className="checkbox" checked={showWeekday} onChange={(e)=>{setShowWeekday(e.target.checked); if (e.target.checked && !allday) {setHour(["09", "00", "17", "00"])}}}/>Filter data by day/time

        <div style={{display: showWeekday ? "block" : "none"}}>
          <div className="daysofweek">Days of the Week</div>
          <div className="daycontainer">
            <img alt="sun" className="daybutton" src={require(weekday[0] ? "./img/filter/sun_active.png" : "./img/filter/sun_inactive.png")} onClick={()=>onSelectDay(0)}/>
            <img alt="mon" className="daybutton" src={require(weekday[1] ? "./img/filter/mon_active.png" : "./img/filter/mon_inactive.png")} onClick={()=>onSelectDay(1)}/>
            <img alt="tue" className="daybutton" src={require(weekday[2] ? "./img/filter/tue_active.png" :"./img/filter/tue_inactive.png")}  onClick={()=>onSelectDay(2)}/>
            <img alt="wed" className="daybutton" src={require(weekday[3] ? "./img/filter/wed_active.png" : "./img/filter/wed_inactive.png")} onClick={()=>onSelectDay(3)}/>
            <img alt="thur" className="daybutton" src={require(weekday[4] ? "./img/filter/thur_active.png" : "./img/filter/thur_inactive.png")} onClick={()=>onSelectDay(4)}/>
            <img alt="fri" className="daybutton" src={require(weekday[5] ? "./img/filter/fri_active.png" : "./img/filter/fri_inactive.png")} onClick={()=>onSelectDay(5)}/>
            <img alt="sat" className="daybutton" src={require(weekday[6] ? "./img/filter/sat_active.png": "./img/filter/sat_inactive.png")} onClick={()=>onSelectDay(6)}/>
          </div>
          <div className="daysofweek">Time of Day</div>
          <img alt="allday" className="alldaybutton" src={require(allday ? "./img/filter/all_day_active.png" : "./img/filter/all_day_inactive.png")} onClick={()=>onSelectAllDay()}/>
        </div>

        <div className="fromto">From</div>
        <div className="fromto">To</div>
        <input type="text" name="to_hour" value={hour[0]} onChange={(e)=>onSetValue(0, e)}/>
        <img className="uparrow" alt="up" data-name="up" src={require("./img/up.png")} onClick={(e)=>onUpDown(0, e)}/>
        <img className="downarrow" alt="down" data-name="down" src={require("./img/down.png")} onClick={(e)=>onUpDown(0, e)}/>
        <div className="colon">:</div>
        <input type="text" name="to_hour" value={hour[1]} onChange={(e)=>onSetValue(1, e)}/>
        <img className="uparrow" alt="up" data-name="up" src={require("./img/up.png")} onClick={(e)=>onUpDown(1, e)}/>
        <img className="downarrow" alt="up" data-name="down" src={require("./img/down.png")} onClick={(e)=>onUpDown(1, e)}/>
        <div className="colon"/>
        <input type="text" name="to_hour" value={hour[2]} onChange={(e)=>onSetValue(2, e)} />
        <img className="uparrow" alt="up" data-name="up" src={require("./img/up.png")}  onClick={(e)=>onUpDown(2, e)}/>
        <img className="downarrow" alt="up" data-name="down" src={require("./img/down.png")} onClick={(e)=>onUpDown(2, e)}/>
        <div className="colon">:</div>
        <input type="text" name="to_hour" value={hour[3]} onChange={(e)=>onSetValue(3, e)}/>
        <img className="uparrow" alt="up" data-name="up" src={require("./img/up.png")} onClick={(e)=>onUpDown(3, e)}/>
        <img className="downarrow" alt="up" data-name="down" src={require("./img/down.png")} onClick={(e)=>onUpDown(3, e)}/>
        <div className="applycontainer">
          <div className="error">{error}</div>
          <div className="cancel apply" onClick={()=>onSetApply()}>APPLY</div>
          <div className="cancel" onClick={()=>setShow()}>CANCEL</div>
        </div>
      </div>
      </div>
    </div>
  )
}

export default DateRangeTime;
