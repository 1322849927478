import React from 'react';
import NavNodeTdoa  from './NavNodeTdoa';
import { TRACKER_CONFIG } from './2dConfig.js';

class NavTypeTdoa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "workers", //this props type
      nodes: [],
      nNodes: [],
      display_list: true,
    };

    this.toggle_list = this.toggle_list.bind(this);
  }

  componentDidMount() {
      this.setState({type: this.props.type});
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.nodes) !== JSON.stringify(this.props.nodes)) {
      let nodes = [];
      let nNodes = [];
      this.props.nodes.forEach( n => {
        n.hasOwnProperty("meas_id") ? nodes.push(n) : nNodes.push(n)
      })

      this.setState({nodes: nodes, nNodes: nNodes});
    }
  }

  toggle_list() {
    this.setState((prevState, props) => ({display_list: !prevState.display_list}));
  }

  render() {
    let tracker_config = {...TRACKER_CONFIG[this.props.type]};

    return (
        <div style={this.props.nodes.length === 0 ? {display: "none"} : {}}>
          {
            this.props.display_all_nodes ?
              <img className="SelectIconSmall " alt="worker_select" src={tracker_config.select}/>  :
              <img className="SelectIconSmall " alt="worker_unselect" src={tracker_config.unselect}/>
          }
          <div className="CheckBoxAllText2">{this.props.type}</div>
          { this.state.display_list ?
            (<img className="ToggleDown2" alt="ToggleDown" src={require("./img/ToggleDown.png")} onClick={this.toggle_list}/>) :
            (<img className="ToggleDown2" alt="ToggleDown" src={require("./img/ToggleUp.png")} onClick={this.toggle_list}/>)
          }
        <div className="Line3"/>
          <div style={this.state.display_list ? {} : {display: "none"}}>
            {this.state.nodes.map(n=><NavNodeTdoa key={n.id + "active"} node={n} onSelect={this.props.onSelect} onTDOA={this.props.onTDOA} checked={this.props.showNodes[n.id].show} disable={false} onShowCard={this.props.onShowCard} />)}
            {this.state.nNodes.map(n=><NavNodeTdoa key={n.id + "inactive"} node={n} onTDOA={this.props.onTDOA} checked={false} disable={true}/>)}
          </div>
        </div>
    )
  }
}

export default NavTypeTdoa;

/*
  {this.state.nodes.map(n=><NavNode key={n.id} node={n} onSelect={this.props.onSelect} onLocate={this.props.onLocate} checked={this.props.showNodes[n.id].show} disable={false} onShowCard={this.props.onShowCard}/>)}
*/
