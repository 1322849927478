import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import LapisDeviceTable from './LapisDeviceTable';
import LapisEditDevice from './LapisEditDevice';

class LapisSettingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return (
      <div className="lapis-container lapis-container2">
        <div className="lapis-header">Settings</div>
        <div className="grid-container2">
          <div className="grid-item card3">
            <div className="settings">
              <Switch>
                <Route exact path="/dashboard/lapis/setting" component={LapisDeviceTable} />
                <Route path="/dashboard/lapis/setting/device/:id" component={LapisEditDevice}/>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LapisSettingPage;

/*
return (
  <div className="settings">
    <SettingsSidebar site={this.props.site} acl={this.props.acl}/>
    <Switch>
      <Route exact path="/dashboard/settings" component={SiteSettingsContainer} />
      <Route path="/dashboard/settings/site" component={SiteSettingsContainer} />
      <Route path="/dashboard/settings/devices/camera" component={DevicesTableContainer} />
      <Route path="/dashboard/settings/devices/edit_node/:id" component={EditNodeNew} />
      <Route path="/dashboard/settings/devices/anchor" component={DevicesTableContainer} />
      <Route path="/dashboard/settings/devices/editanchor/:id" component={EditAnchorNew} />
      <Route path="/dashboard/settings/devices/tracker" component={DevicesTableContainer} />
      <Route path="/dashboard/settings/devices/edit_tracker/:id" component={EditTrackerNew} />
      <Route path="/dashboard/settings/hubs" component={HubsTableContainer} />
      <Route path="/dashboard/settings/devices/edit_hub/:id" component={EditHubNew} />
      <Route path="/dashboard/settings/constellations" component={ConstellationsTableContainer} />
      <Route path="/dashboard/settings/editconstellation/:id" component={EditConstellationNew} />
      <Route path="/dashboard/settings/smartdock" component={DocksTableContainer} />
      <Route path="/dashboard/settings/editdock/:id" component={EditDock} />
      <Route path="/dashboard/settings/smartlps/config" component={LpsSettings} />
      <Route path="/dashboard/settings/smartlps/workers" component={WorkersTableContainer} />
      <Route path="/dashboard/settings/smartlps/gids" component={GIDsTableContainer} />
      <Route path="/dashboard/settings/smartlps/edit_gid/:id" component={EditGIDNew} />
    </Switch>
  </div>
);

import LapisSdDevices from '../lapis/LapisScDevices';
import LapisSdAdd from '../lapis/LapisDeviceAdd';
import LapisSdDetail from '../lapis/LapisDeviceDetail';

<Route path="/dashboard/lapis/sd/devices" component={LapisSdDevices} />
<Route path="/dashboard/lapis/sd/add" component={LapisSdAdd} />
<Route path="/dashboard/lapis/sd/device/:id" component={LapisDeviceDetail} />
*/
