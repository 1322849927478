import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNodesHash, createNode, updateNode, fetchNodes } from '../../modules/nodes';
import { getConstellationsForTable } from '../../modules/constellations';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';
import {isEmpty, isLongHex} from '../settings/form/ErrorChecking';

const URL = "/dashboard/settings/devices/camera";
const CONFIG = {name: "", hardware_id: "", auto_capture_period_minutes: "", auto_capture_media_format: "", ir_led_brightness: "", ambient_light_threshold: "", motion_sensitivity: "", constellation: "", alarm_media_format: "", validate: true};
const AUTOCAPTURE_ALARM_OPTIONS = ['image', 'video'];

const MOTION = ['OFF', 'LOW', 'MEDIUM', 'HIGH'];
const MOTION_OPTIONS={255:'OFF', 15: 'LOW', 10: 'MEDIUM', 5: 'HIGH'};

const LIGHT_OPTIONS = {
  0: {value: 0, label: 0},
  4000: {value: 4000, label: 4000},
  8000: {value: 8000, label: 8000},
  12000: {value: 12000, label: 12000},
  16000: {value: 16000, label: 16000},
  20000: {value: 20000, label: 20000},
  24000: {value: 24000, label: 24000},
  28000: {value: 28000, label: 28000},
  32000: {value: 32000, label: 32000},
  36000: {value: 36000, label: 36000},
  40000: {value: 40000, label: 40000},
  44000: {value: 44000, label: 44000},
  48000: {value: 48000, label: 48000},
  52000: {value: 52000, label: 52000},
  56000: {value: 56000, label: 56000},
  60000: {value: 60000, label: 60000},
};

const EditNodeNew = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [node, setNode] = useState(null);
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [constellations, setConstellations] = useState({});

  useEffect(()=> {
    if (props.match.params.id === "undefined" ) { return; }

    if (props.match.params.id !== id) {
      switch (props.match.params.id) {
        case 'new':
          resetForm(null);
          break;
        default:
          resetForm(props.nodesHash[props.match.params.id])
      }
    }
  });

  const resetForm = (node) => {
    if (node) {
      //console.log("set editForm - " + JSON.stringify(node))
      setId(node.node)
      setEditForm(true);
      setNode(node);
      setConfig({
        name: node.name,
        hardware_id: node.hardware_id,
        auto_capture_period_minutes: node.configs.camera.auto_capture_period_minutes,
        auto_capture_media_format: node.configs.camera.auto_capture_media_format,
        ir_led_brightness: node.configs.camera.ir_led_brightness ? node.configs.camera.ir_led_brightness : 0,
        ambient_light_threshold: node.configs.camera.ambient_light_threshold,
        alarm_media_format: node.configs.camera.alarm_media_format,
        motion_sensitivity: node.configs.motion.motion_sensitivity,
        constellation: node.constellation ? node.constellation : null,
      });
    } else {
      console.log("set newForm")
      setId("new");
      setEditForm(false);
      setNode(null);
      setConfig(CONFIG);
    }

    //do constellation options here
    let cons = {};
    props.constellations.forEach(c => {
      cons[c.constellation] = {label: c.name, value: c.constellation};
    });

    setConstellations(cons);
    setError(CONFIG);
  }

  const handleDelete = (e) => {
    setTimeout(()=>props.history.push(URL), 700);
  };

  const handleRestart = (e) => {
   let err = {...error};
   e === 'success' ?  err['status'] = node.name +  " restarted " :  err['status'] = "Error restarting " + node.name;
   setError(err);
  }

  const validateFields = () => {
    let errorNew = {...CONFIG};
    isEmpty("name", config.name, errorNew);

    if (!editForm) { //new
      for (const node in props.nodesHash) {
        if (config.name === props.nodesHash[node].name) {
          errorNew['name'] = 'Field has to be unique';
          errorNew['validate'] = false;
        }
      }

      isEmpty("hardware_id", config.hardware_id, errorNew);
      isLongHex("hardware_id", config.hardware_id, errorNew);
    }

    setError(errorNew);
    return errorNew.validate;
  };

  const handleSubmit = (e)=> {
   if (!validateFields()) {
      return;
   }

    console.log("submit");
    if (editForm) {
      //console.log(JSON.stringify(config.auto_capture_period_minutes))

      let node = {
        node: {
          name: config.name,
          constellation: config.constellation ? config.constellation.value: null,
          configs: {
            camera: {
              alarm_media_format: config.alarm_media_format,
              auto_capture_period_minutes: parseInt(config.auto_capture_period_minutes, 10),
              auto_capture_media_format: config.auto_capture_media_format,
              ir_led_brightness: config.ir_led_brightness,
              ambient_light_threshold: config.ambient_light_threshold.value,
            },
            motion: {
              motion_sensitivity: parseInt(config.motion_sensitivity, 10),
            }
          }
        }
      };

      //console.log(JSON.stringify(node))
      props.dispatch(updateNode(id, node));
    } else {
      console.log("Save Node");
      let node = {
        node: {
          name: config.name,
          hardware_id: config.hardware_id,
          node_type: 'camera',
        }
      };
      props.dispatch(createNode(node));
    }

    setTimeout(()=>props.dispatch(fetchNodes()), 500);
    setTimeout(()=>props.history.push(URL), 700);
  };

  const updateField = (field, e, type) => {
    //console.log("update field " + field + " " + e)
    if (type && (type === 'select' || type === 'button_group')) {
      if (field === 'motion_sensitivity') {
        for (const v in MOTION_OPTIONS) {
          if (e === MOTION_OPTIONS[v]) {
              setConfig(Object.assign({}, config, {[field]: v}));
              break;
          }
        }
      } else {
        setConfig(Object.assign({}, config, {[field]: e}));
      }
    } else if (type === 'range_slider') {
      setConfig(Object.assign({}, config, {[field]: e / 100}));
    } else {
      setConfig(Object.assign({}, config, {[field]: e.target.value}));
    }
  }

  return(
    <div className="main edit">
      <Link to={URL}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? "Edit Vision Sensor " : "Add New Vision Sensor"}</h3>
      {editForm &&
        <HeaderContainer
          node = {node && node.node}
          name = {node && node.name}
          charge = {node && node.events && node.events.battery && node.events.battery.charge}
          voltage = {node && node.events && node.events.battery && node.events.battery.voltage}
          current = {node && node.events && node.events.battery && node.events.battery.current}
          rssi = {node && node.events && node.events.radio && node.events.radio.rssi}
          status={node && node.events && node.events.system && node.events.system.connection && node.events.system.connection.connected ? "connected" : "disconnected"}
          handleDelete={handleDelete}
          handleRestart={handleRestart}
        />
      }
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        { editForm ?
          <div key="edit">
            <LabelContainer
              labels = {
                { "Device Name": {},
                  "Autocapture Interval": {"help": "The interval at which the Vision Sensor captures media (photo or clip)"},
                  "Media Type": {"help" : "The type of media captured at the determined Autocapture Interval for this device"},
                  "Ir LED Power": {"help": "The level of illumination of the night vision LEDs on the Vision Sensor. The setting can be adjusted from 0% (off) to 100% (brightest)"},
                  "Ambient Light Threshold": {"help" : "The ambient light threshold determines when the night vision is enabled, based on the amount of light detected by the ambient light sensor. The values range from 0 (never enabling the IR LED’s) to 60,000 (effectively always enabling the IR LED’s)"},
                  "Motion Sensitivity": { "help": "Motion Sensitivity determines sensitivity of the Passive Infrared (PIR) Motion Detector. Low makes the motion sensor less likely to detect small, far away movements. High is the most sensitive setting, making the device most prone to detect motion which can lead to false positives"},
                  "Constellation" : {"help": "A grouping of Vision Sensors that capture image or video together when in an \"Active\" state"},
                  "Alarm Action" : {"help" : "The type of media captured by a Vision sensor when its constellation has an\"Alarm\" (motion) event"},
                }
            }/>
            <FormContainer
              fields = {{
                  "name": {"value": config['name'] ? config.name : "", "placeholder": "", "error": error.name},
                  "auto_capture_period_minutes" : {"type": "short_input", "text": "mins", "value": config['auto_capture_period_minutes'], "placeholder": "", "error": error.auto_capture_period_minutes},
                  "auto_capture_media_format" : {"type": "button_group", "options": AUTOCAPTURE_ALARM_OPTIONS, "value": config['auto_capture_media_format'], "error": error.auto_capture_media_format},
                  "ir_led_brightness": {type: "range_slider", min: 0, max: 100, "value": (config.ir_led_brightness * 100).toFixed(), "error": error.ir_led_brightness},
                  "ambient_light_threshold": {type: "select", options: Object.values(LIGHT_OPTIONS), "value":  LIGHT_OPTIONS[config['ambient_light_threshold']], "error": error.ambient_light_threshold},
                  "motion_sensitivity": {type: "button_group", "options": MOTION, "value": MOTION_OPTIONS[config['motion_sensitivity']], "error": error.motion_sensitivity},
                  "constellation": {type: "select", options: Object.values(constellations), "value": constellations[config['constellation']], "error": error.constellation},
                  "alarm_media_format": {"type": "button_group", "options": AUTOCAPTURE_ALARM_OPTIONS, "value": config['alarm_media_format'], "error": error.alarm_media_format},
              }}
              updateField={updateField}
            />
            <InfoContainer
              info={{
                      "Hardware ID(Mac Address)": node && node.hardware_id,
                      "System Id": node && node.node,
                      "Firmware Version": node && node.events && node.events.system && node.events.system.firmware_version,
                    }}
            />
          </div>
          :
          <div key="save">
            <LabelContainer labels= {{"Device Name": {"help": "", "desc": ""}, "Hardware ID": {"help": "", "desc": ""}}}/>
            <FormContainer
              fields = {{
                  "name": {"value": config.name, "placeholder": "", "error": error.name},
                  "hardware_id": {"value": config.hardware_id, "placeholder": "", "error": error.hardware_id},
              }}
              updateField={updateField}
            />
          </div>
        }
      </div>
      <div>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
      nodesHash: getNodesHash(state),
      constellations: getConstellationsForTable(state),
    }),
)(EditNodeNew);
