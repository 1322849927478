//import { WAREHOUSE, ADMIN } from '../2dReact/2dConfig';

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function makeLegend(from, to) {
  let duration = to - from;

  let y_axis = [];
  y_axis.push(convertLegendTime(duration * .25));
  y_axis.push(convertLegendTime(duration * .55));
  y_axis.push(convertLegendTime(duration * .85));
  y_axis.push(convertLegendTime(duration * 1));

  return y_axis;
}

export function makeLegendNew(duration) {
  let y_axis = [];
  y_axis.push(convertLegendTime(duration * .25));
  y_axis.push(convertLegendTime(duration * .55));
  y_axis.push(convertLegendTime(duration * .85));
  y_axis.push(convertLegendTime(duration * 1));

  return y_axis;
}

export function convertLegendTime(mill) {
  if (mill === 0) { return "0"}

  let hours = null;
  let mins = null;

  if (mill > 3600000) { //hour
    hours = Math.floor(mill/3600000);
    mill = mill % 3600000;
  }

  if (mill > 60000) { mins = Math.round(mill/60000)}

  let strr = [];
  if (hours != null ) {
    strr.push(hours + "h");
  }

  if (mins != null ) {
    strr.push(mins + "m")
  }
  return strr.join(" ");
}

/* sort functions */
function alphabetically(ascending, key) {
  return function (a, b) {
    if (a[key] === b[key]) {
        return 0;
    }

    else if (a[key] === null || a[key] === undefined) {
        return 1;
    }
    else if (b[key] === null || a[key] === undefined) {
        return -1;
    }
    else if (ascending) {
        return a[key] < b[key] ? -1 : 1;
    }
    else {
        return a[key] < b[key] ? 1 : -1;
    }
  };
}

function numerically(ascending, key) {
  return function (a, b) {
    if (!a.hasOwnProperty(key) || a[key] === null || a[key] === undefined) {
        return 1;
    } else if (!b.hasOwnProperty(key) || b[key] === null || b[key] === undefined) {
        return -1;
    } else if (parseFloat(a[key]) === parseFloat(b[key])) {
        return 0;
    } else if (ascending) {
        return parseFloat(a[key]) < parseFloat(b[key]) ? -1 : 1;
    } else {
        return parseFloat(a[key]) < parseFloat(b[key]) ? 1 : -1;
    }
  };
}

export function sortData(data, index, asc_or_desc, str_or_float) {
  if (data === undefined || data.length === 0) { return data; }
  let key = Object.keys(data[0])[index];
  console.log("key: " + key);

  if (asc_or_desc === 'asc') {
    if (str_or_float === 'string') {
      data = data.sort(alphabetically(true, key));
    } else {
      data = data.sort(numerically(true, key));
    }
  } else { //desc
    if (str_or_float === 'string') {
      data = data.sort(alphabetically(false, key));
    } else {
      data = data.sort(numerically(false, key));
    }
  }
}

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

/* retired
export function checkPermission(siteId, userName, component) {
  if (!WAREHOUSE.hasOwnProperty(siteId)) {
    return true;
  }

  if (WAREHOUSE[siteId].hasOwnProperty('acl') && WAREHOUSE[siteId].acl[component].includes(userName)) {
    return true;
  }

  if (!WAREHOUSE[siteId].hasOwnProperty('acl')) {
    return true;
  }

  if (ADMIN.includes(userName)) {
    return true;
  }

  return false;
}
*/

export function format(num) {
  let temp = parseInt(num, 0);
  if (temp < 10) {
    return "0" + temp.toString();
  } else {
    return temp.toString();
  }
}

/*
//to is milliseconds
//type is today, this week, last week, this month, last month
//wdays is ["Monday","Tuesday","Wednesday","Thursday","Friday"];
//hours is [["8:00", "18:00"],["8:00", "18:00"],["8:00", "18:00"],["8:00", "18:00"],["8:00", "18:00"]];
//returns - [{from: Date.now() - 3600000, to: Date.now()}],
 */
export function calBizIntervals(to, type, wdays, hours) {
  console.log(to + " " + type + " " + wdays + " " + hours);

  let from = new Date(to);
  let finalTo = new Date(to);
  let day = finalTo.getDay();

  switch (type) {
    case 'TODAY':
      from.setHours(0,0,0,0)
      finalTo.setHours(24,0,0,0);
      break;
    case 'YESTERDAY':
      from.setHours( -24, 0,0,0);
      finalTo.setHours(0,0,0,0);
      break;
    case 'THIS WEEK':
      //set from to Sun 12AM
      // sun = 0, mon = 1, tue = 2, wed = 3, thur = 4, fri = 5, sat = 6
      from.setHours( -24 * day, 0,0,0)
      finalTo.setHours(24 * (7 - day),0,0,0);
      break;
    case 'LAST WEEK':
      from.setHours( -24 * (7 + day), 0,0,0)
      finalTo.setHours( -24 * day, 0,0,0)
      break;
    case '2 WEEKS AGO':
        from.setHours( -24 * (14 + day), 0,0,0)
        finalTo.setHours( -24 * (7 + day), 0,0,0)
        break;
    case '3 WEEKS AGO':
        from.setHours( -24 * (21 + day), 0,0,0)
        finalTo.setHours( -24 * (14 + day), 0,0,0)
        break;
    case '4 WEEKS AGO':
        from.setHours( -24 * (28 + day), 0,0,0)
        finalTo.setHours( -24 * (21 + day), 0,0,0)
        break;
    case 'THIS MONTH':
      from.setDate(1);
      from.setHours(0,0,0,0);
      finalTo.setMonth(finalTo.getMonth() + 1, 1);
      finalTo.setHours(0,0,0,0);
      break;
    case 'LAST MONTH':
      from.setMonth(from.getMonth() - 1, 1);
      from.setHours(0,0,0,0);
      finalTo.setMonth(finalTo.getMonth() - 1, 31);
      finalTo.setHours(0,0,0,0);
      break;
    default:
      //from.setHours(0,0,0,0)
  }

  const weekdays = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
  let intervals = [];
  let temp = from.getTime(); //milliseconds
  while (temp <= new Date(finalTo).setHours(23, 59,59, 999)) {
    let day = new Date(temp).getDay();
    let index = wdays.indexOf(weekdays[day]);
    if (index >= 0) {
      let start_hours = hours[index][0].split(":");
      let end_hours = hours[index][1].split(":");
      intervals.push({from: new Date(temp).setHours(start_hours[0], start_hours[1], start_hours[2]), to: new Date(temp).setHours(end_hours[0], end_hours[1], end_hours[2])});
    }
    temp = temp + 86400000;
  }

  return intervals;
}

  /* retired
  export function checkPermission(siteId, userName, component) {
    if (!WAREHOUSE.hasOwnProperty(siteId)) {
      return true;
    }

    if (WAREHOUSE[siteId].hasOwnProperty('acl') && WAREHOUSE[siteId].acl[component].includes(userName)) {
      return true;
    }

    if (!WAREHOUSE[siteId].hasOwnProperty('acl')) {
      return true;
    }

    if (ADMIN.includes(userName)) {
      return true;
    }

    return false;
  }
  */
