import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PrivateRoute from './components/routes/PrivateRoute';
import Dashboard from './components/dashboard/Dashboard';
import LoginForm from './components/LoginForm.js';
import { getCurrentSiteId } from './modules/sites';
import { createEventManager } from './modules/websockets';
import './css/App.css';
import './css/AssetNodeList.css';

const App = ({ loggedIn, authToken }) => {
  if (loggedIn) {
    document.cookie = `Authorization-part1="Bearer ${authToken}"; domain=api.locix.com; path=/`;
  }
  return (
    <div className="App">
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard} loggedIn={loggedIn} />
        <PrivateRoute path="/dashboard" component={Dashboard} loggedIn={loggedIn} />
        <Route path="/login" component={LoginForm} />
        <Redirect from="/index.html" to="/" />
      </Switch>
    </div>
  );
}

class AppWrapper extends React.Component {

  componentDidMount() {
    if (this.props.siteId) {
      this.props.dispatch(createEventManager(this.props.siteId));
    }
  }

  componentWillReceiveProps(nextProps) {
    //console.log(nextProps, this.props);
    if (nextProps.siteId && nextProps.siteId !== this.props.siteId) {
      this.props.dispatch(createEventManager(nextProps.siteId));
    }
  }

  render() {
    return (
      <App
        loggedIn={this.props.loggedIn}
        authToken={this.props.authToken}
        siteId={this.props.siteId}
      />
    )
  }
}

export default withRouter(connect(state => ({
  loggedIn: !!state.authToken,
  authToken: state.authToken,
  siteId: getCurrentSiteId(state)
}))(AppWrapper));
