import React from 'react';
import { connect } from 'react-redux';
import { getConstellationsArray } from '../../modules/constellations';
import ConstellationSummary from './ConstellationSummary';

const ConstellationsPage = ({ constellations }) => (
  <div className="content-row">
    <div className="dashboard-content constellations-page" id="dashboard-content">
      { constellations.map(c => (
        <ConstellationSummary key={c.constellation} constellation={c}/>
      )) }
    </div>
  </div>
);

export default connect(
  state => ({ constellations: getConstellationsArray(state) })
)(ConstellationsPage);
