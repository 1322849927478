import React, {useState, useEffect, useRef} from 'react';
import { Tooltip } from 'react-tippy';
import { IMG_CONFIG } from './2dConfig';

function usePreviousValue(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function formatTime(m) {
  if (m) {
    let d = new Date(m);

    //do date string
    let dateStr = "";
    let today = new Date();
    let midNight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let diff = d.getTime() - midNight.getTime();

    if (diff < 0 ) {
      if (diff < -86400000) {
        dateStr = (d.getMonth() + 1) + "/" + d.getDate() + ", ";
      } else {
        dateStr = "Yesterday, "
      }
    }

    let hour = d.getHours().toString();
    let min = d.getMinutes().toString();;
    let sec = d.getSeconds().toString();;

    if (hour.length === 1 ) { hour = "0" + hour }
    if (min.length === 1) { min = "0" + min }
    if (sec.length === 1) {sec = "0" + sec}

    return dateStr + hour + ":" + min + ":" + sec;
  } else {
    return "00:00:00";
  }
}

function formatBattery(voltage, connected) {
  if (!connected && voltage < 3.1) {
    return <img className="Battery" alt="battery" src={require("./img/battery_dead.png")}/>
  } else if (voltage < 3.15) {
      return <img className="Battery" alt="battery" src={require("./img/battery_low.png")}/>
  //} else if (voltage < 3.3) {
  } else if (voltage < 3.273) {
    return <img className="Battery" alt="battery" src={require("./img/battery_half.png")}/>
  } else {
    return ""
  }
}

const NavNodeTdoa = ({node, onSelect, onTDOA, checked, disable, onShowCard}) => {
  const [refresh, setRefresh] = useState(null);
  const previousValue = usePreviousValue(node.timestamp);

  useEffect(() => {
    if (previousValue && node.timestamp > previousValue) {
      setRefresh(((node.timestamp - previousValue)/1000).toFixed(1) + " sec")
    }
  }, [previousValue, node.timestamp]);

  return(
    <div className="Nodes" onClick={e => disable? e.stopPropagation() : onShowCard(node.id)}>
      <input type="checkbox" className="CheckBoxAll" checked={checked} onClick={(e)=>e.stopPropagation()} onChange={(e)=>{onSelect(node.id + " " + e.target.checked); e.stopPropagation()}} disabled={disable}/>
      {node.type === 'tdoa_tracker' && !node.connected ? <div className="ForkliftTextDisconnected">{node.name}</div> :  <div className="ForkliftText">{node.name}</div>}
      <Tooltip title={IMG_CONFIG['refresh'].tooltip} theme="transparent">
        <img className="Refresh" alt="refresh" src={IMG_CONFIG['refresh'].img} onClick={(e)=>{e.stopPropagation(); onTDOA(node)}}/>
      </Tooltip>
      {node.type==='tdoa_tracker' ? formatBattery(node.voltage, node.connected): ""}
      <div className="Last-Locate-Container">
        {node.type ==='tdoa_tracker' && !node.connected ? <div className="Disconnected">DISCONNECTED</div> : <div className="Last-Locate">LAST LOCATE</div> }
        <div className="Last-Locate-Time">{node.timestamp ? formatTime(node.timestamp) : formatTime(node.meas_id)}</div>
         <div>
           <div className="Last-Locate">REFRESH RATE</div>
           <div className="Last-Locate-Time">{refresh}</div>
         </div>
         <div>
           <div className="Last-Locate">TDOA TRACKING MODE
           <div className="Last-Locate-Time">{node.tdoa_tracking_mode}</div>
          </div>
        </div>
      </div>
      <div className="Color" style={{backgroundColor: "#" + node.color}}/>
      <div className="Line2"/>
      </div>
  )
};

export default NavNodeTdoa;
