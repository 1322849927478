import React from 'react';
import { IMG_CONFIG } from './2dConfig';

const HeatmapLegend = ({legend, type, onSetType, onOpenModal}) => (
  <div className="HeatmapLegendContainer">
    <div className="LegendTitle">ACTIVITY</div>
    <img className="LegendIcon2" alt="None" src={type.show ? IMG_CONFIG['toggle_on']['active']['img'] : IMG_CONFIG['toggle_on']['inactive']['img']}
          data-type={type.show ? JSON.stringify({show: false, active: false, inactive:false}) : JSON.stringify({show: true, active:true, inactive:true})}
          onClick={(e)=>onSetType(e)}
    />
    <img className="LegendIcon"
         alt="edit_heatmap"
         src={require('./img/edit.png')}
         onClick={onOpenModal}
    />
    <img className="LegendIcon" alt="inactive" src={type.inactive ? IMG_CONFIG['inactive']['active']['img'] : IMG_CONFIG['inactive']['inactive']['img']}
          data-type={type.inactive ? JSON.stringify({inactive: false}) : JSON.stringify({inactive:true})}
          onClick={(e)=>onSetType(e)}
    />
    <img className="LegendIcon" alt="active" src={type.active ? IMG_CONFIG['active']['active']['img'] : IMG_CONFIG['active']['inactive']['img']}
          data-type={type.active? JSON.stringify({active: false}) : JSON.stringify({active:true})}
          onClick={(e)=>onSetType(e)}
    />
    <img className="Legend" alt="legend" src={require('./img/gradient.png')}/>
    <div className="Unit" style={{marginLeft: (275 * .25) - (legend[0].length * 3)}}>{legend[0]}</div>
    <div className="Unit" style={{marginLeft: (275 * .55) - (legend[1].length * 3)}}>{legend[1]}</div>
    <div className="Unit" style={{marginLeft: (275 * .85) - (legend[2].length * 4)}}>{legend[2]}</div>
    <div className="Unit" style={{marginLeft: (275 * .98) - (legend[3].length * 4)}}>{legend[3]}+</div>
  </div>
);

export default HeatmapLegend;

/*


class HeatmapLegend extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      legend: [".25",".55", ".85", "1"]
    };
  }

  componentDidMount() {
    this.setState({legend: this.props.legend});
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.legend) !== JSON.stringify(this.props.legend)) {
      this.setState({legend: this.props.legend});
    }
  }

  render() {
    return (
      <div className="HeatmapLegendContainer">
        <div className="LegendTitle">ACTIVITY</div>
        <img className="LegendIcon2" alt="None" src={this.props.type.show ? IMG_CONFIG['toggle_on']['active']['img'] : IMG_CONFIG['toggle_on']['inactive']['img']}
              data-type={this.props.type.show ? JSON.stringify({show: false, active: false, inactive:false}) : JSON.stringify({show: true, active:true, inactive:true})}
              onClick={(e)=>this.props.onSetType(e)}
        />
        <img className="LegendIcon"
             alt="edit_heatmap"
             src={require('./img/edit.png')}
             onClick={this.props.onOpenModal}
        />
        <img className="LegendIcon" alt="inactive" src={this.props.type.inactive ? IMG_CONFIG['inactive']['active']['img'] : IMG_CONFIG['inactive']['inactive']['img']}
              data-type={this.props.type.inactive ? JSON.stringify({inactive: false}) : JSON.stringify({inactive:true})}
              onClick={(e)=>this.props.onSetType(e)}
        />
        <img className="LegendIcon" alt="active" src={this.props.type.active ? IMG_CONFIG['active']['active']['img'] : IMG_CONFIG['active']['inactive']['img']}
              data-type={this.props.type.active? JSON.stringify({active: false}) : JSON.stringify({active:true})}
              onClick={(e)=>this.props.onSetType(e)}
        />
        <img className="Legend" alt="legend" src={require('./img/gradient.png')}/>
        <div className="Unit" style={{marginLeft: (275 * .25) - (this.state.legend[0].length * 3)}}>{this.state.legend[0]}</div>
        <div className="Unit" style={{marginLeft: (275 * .55) - (this.state.legend[1].length * 3)}}>{this.state.legend[1]}</div>
        <div className="Unit" style={{marginLeft: (275 * .85) - (this.state.legend[2].length * 4)}}>{this.state.legend[2]}</div>
        <div className="Unit" style={{marginLeft: (275 * .98) - (this.state.legend[3].length * 4)}}>{this.state.legend[3]}+</div>
      </div>
    )
  }
}

export default HeatmapLegend;

ruler  {1: "rgb(255,0,0)", 0.25: "rgb(0,0,255)", 0.55: "rgb(0,255,0)", 0.85: "yellow"}
<div className="Unit" style={{marginLeft: 185 * .20}}>{this.state.legend[0]}</div>
<div className="Unit" style={{marginLeft: 185 * .50}}>{this.state.legend[1]}</div>
<div className="Unit" style={{marginLeft: 185 * .75}}>{this.state.legend[2]}</div>
<div className="Unit" style={{marginLeft: 185 * .95}}>{this.state.legend[3]}+</div>
</div>

this.state.display_forklift_list ?
    (<img className="ToggleDown" alt="ToggleDown" src={require("./img/ToggleDown.png")} onClick={this.toggle_forklift_list}/>) :
    (<img className="ToggleDown" alt="ToggleDown" src={require("./img/ToggleUp.png")} onClick={this.toggle_forklift_list}/>)
}
</div>

{this.props.type === 'inactive' ?
  <img className="LegendIcon" alt="inactive-select" src={require("./img/inactive_select.png")} data-type="total" onClick={(e)=>this.props.onSetType(e)}/>
  :
  <img className="LegendIcon" alt="inactive-unselect" src={require("./img/inactive_unselect.png")} data-type="inactive" onClick={(e)=>this.props.onSetType(e)}/>
}

{this.props.type === 'active' ?
  <img className="LegendIcon" alt="active-select" src={require("./img/active_select.png")} data-type="total" onClick={(e)=>this.props.onSetType(e)}/>
  :
  <img className="LegendIcon" alt="active-unselect" src={require("./img/active_unselect.png")} data-type="active" onClick={(e)=>this.props.onSetType(e)}/>
}
*/
