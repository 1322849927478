import React from 'react';
import {FormattedMessage} from 'react-intl';
import {addCommas} from '../charts/ChartUtil';

const MscsFlMetric = ({flMetric}) => {
  return (
    <div className="mscsMetric">
      <div className="container">
        <img className="icon2" alt="icon-forklift" src={require("../img/icon-forklift.png")}/>
        <FormattedMessage id="mscs.flmetrics" defaultMessage="Forklift Metrics"/>
        <div className="field">
        <FormattedMessage id="mscs.activityrate" defaultMessage="Activity Rate (Movement/Unit)"/>
        </div>
        <div className="value">
          {flMetric.activity} %
        </div>
        <div className="field">
        <FormattedMessage id="mscs.dailydistancetravelled" defaultMessage="Daily Distance Travelled"/>
        </div>
        <div className="value">
          {addCommas(flMetric.distance)} <FormattedMessage id="mscs.munit" defaultMessage="m/unit"/>
        </div>
      </div>
    </div>
  )
}

export default MscsFlMetric;
