import React, {useState, useEffect} from 'react';
import {sortData} from './Util';
import './Common.css';

const Sort = ({thisColumnIndex, thisDataType, columnIndexToSort, data, setSortedData}) => {
  const [sortState, setSortState] = useState("none"); //none, asc, desc
  const [sortIcon, setSortIcon] = useState("fa fa-sort");//fa-sort, fa-sort-up, fa-sort-down

  useEffect(()=> {
    if (thisColumnIndex !== columnIndexToSort) {
      setSortState("none");
      setSortIcon("fa fa-sort")
    }
  }, [thisColumnIndex, columnIndexToSort]);

  const sortMe = ((e)=> {
    e.stopPropagation();
    let newState = "";
    let newStateIcon = "";

    switch (sortState) {
      case "none":
        newState = "asc";
        newStateIcon = "fa fa-sort-down";
        break;
      case "asc":
        newState = "desc";
        newStateIcon = "fa fa-sort-up";
        break;
      default: //"desc"
        newState = "asc";
        newStateIcon = "fa fa-sort-down";
    }

    setSortState(newState);
    setSortIcon(newStateIcon);

    sortData(data, thisColumnIndex, newState, thisDataType);
    setSortedData(thisColumnIndex, data);
  });

  return(
    <div className={sortIcon + " sortIconStyle"} onClick={(e)=>{sortMe(e)}}/>
  )
};

export default Sort;

/*
const [sortState, setSortState] = useState(thisColumnIndex === columnIndexToSort ? "asc" : "none"); //none, asc, desc
const [sortIcon, setSortIcon] = useState(thisColumnIndex === columnIndexToSort ? "fa fa-sort-down" : "fa fa-sort");//fa-sort, fa-sort-up, fa-sort-down
*/
