import React from 'react';
import WarnModal from '../../common/WarnModal';

import { connect } from 'react-redux';
import { deleteNode, fetchNodes } from '../../../modules/nodes';
import { deleteHub, fetchHubs } from '../../../modules/hubs';
import { deleteConstellation, fetchConstellations } from '../../../modules/constellations';

const DeleteButton = (props) => {
  const handleDelete = () => {
    console.log("deleting - " + props.node + " " + props.type);

    if (props.type === 'hub') {
      props.dispatch(deleteHub(props.node));
      setTimeout(()=>props.dispatch(fetchHubs()), 500);
    } else if (props.type === 'constellation') {
      props.dispatch(deleteConstellation(props.node));
      setTimeout(()=>props.dispatch(fetchConstellations()), 500);
    } else {
      props.dispatch(deleteNode(props.node));
      setTimeout(()=>props.dispatch(fetchNodes()), 500);
    }

    props.handleDelete("success")
  }

  return (
    <
      WarnModal
      button="delete"
      warn={`Removing ${props.name} may affect your site performance. Are you sure you want to remove ${props.name}?`}
      action={handleDelete}
    />
  )
}

export default connect()(DeleteButton);
