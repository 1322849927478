import React from 'react';
import SelectOptions from './SelectOptions';
import LongButton from './LongButton';
import ButtonGroup from './ButtonGroup';
import ColorPicker from './ColorPicker';
import RangeSlider from './RangeSlider';
import MultiSelectOptions from './MultiSelectOptions';
import WarnModal from './../../common/WarnModal';

//input, select, select_button, color_picker
const FormContainer = (props) => {
  let fields = []
  for (const f in props.fields) {
    switch (props.fields[f].type) {
      case 'select' :
        fields.push(
          <div className="fields" key={f}>
            <SelectOptions
             options = {props.fields[f].options}
             value = {props.fields[f].value}
             field={f}
             updateField={props.updateField}
            />
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        );
        break;
      case 'multiselect' :
        fields.push(
          <div className="fields" key={f}>
            <MultiSelectOptions
             options = {props.fields[f].options}
             values = {props.fields[f].value}
             field={f}
             updateField={props.updateField}
            />
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        );
        break;
      case 'button_group':
        fields.push(
          <div className="fields" key={f}>
            <ButtonGroup
             options = {props.fields[f].options}
             value = {props.fields[f].value}
             field={f}
             updateField={props.updateField}
            />
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'long_button':
        fields.push(
          <div className="fields" key={f}>
            <LongButton
              label={props.fields[f].label}
              value = {props.fields[f].value}
              field={f}
              updateField={props.updateField}
            />
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'color_picker':
        fields.push(
          <div className="fields short_input" key={f}>
            <ColorPicker field={f} value={props.fields[f].value} updateField={props.updateField}/>
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'range_slider':
        fields.push(
          <div className="fields" key={f}>
            <RangeSlider field={f} value={props.fields[f].value} min={props.fields[f].min} max={props.fields[f].max} updateField={props.updateField}/>
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'short_input':
        fields.push (
          <div className="fields short_input" key={f}>
            <input type="text"
               value={props.fields[f].value}
               placeholder={props.fields[f].placeholder}
               onChange={e => props.updateField(f , e)}
             />
             {props.fields[f].text && props.fields[f].text.toUpperCase()}
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'text':
        fields.push (
          <div className="fields" key={f}>
             {props.fields[f].value && props.fields[f].value.toUpperCase()}
             <div className="error">{props.fields[f].error}</div>
             <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'empty':
        fields.push (
          <div className="fields" key={f}>
            <div className="error">{props.fields[f].error}</div>
            <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'delete_button':
        fields.push (
          <div className="fields" key={f}>
             <
               WarnModal
               html= <i className="fa fa-minus-circle delete_field"></i>
               warn={props.fields[f].warn}
               action={(e)=> props.updateField(f, props.fields[f].value, "delete_button")}
             />
             {props.fields[f].text && props.fields[f].text.toUpperCase()}
             <div className="error">{props.fields[f].error}</div>
             <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'disabled_textfield':
        fields.push (
          <div className="fields long_input" key={f}>
            <input type="text"
               placeholder={props.fields[f].placeholder}
               disabled
             />
            <div className="error">{props.fields[f].error}</div>
           <div className="help">{props.fields[f].help}</div>
          </div>
        )
        break;
      case 'password':
        fields.push (
          <div className="fields long_input" key={f}>
            <input type="password"
               value={props.fields[f].value}
               placeholder={props.fields[f].placeholder}
               onChange={e => props.updateField(f , e)}
             />
            <div className="error">{props.fields[f].error}</div>
           <div className="help">{props.fields[f].help}</div>
          </div>
        )
      break;
      default:
       fields.push (
         <div className="fields long_input" key={f}>
           <input type="text"
              value={props.fields[f].value || props.fields[f].value === 0 ? props.fields[f].value : ""  }
              placeholder={props.fields[f].placeholder}
              onChange={e => props.updateField(f , e)}
            />
           <div className="error">{props.fields[f].error}</div>
          <div className="help">{props.fields[f].help}</div>
         </div>
       )
     }
  }

  return (
    <div className="form_container">
    {fields}
    </div>
  )
}

export default FormContainer;

//props.fields = {"key": {"type": "text", "value": "", placeholder:"", error"", "help": ""}}
//props.onChange = onchange
