import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Column, Cell} from 'fixed-data-table-2';

import { fetchLapisData } from '../../modules/api';
import Sort from '../common/Sort';
import Filter from '../common/Filter';

const ADD = "/dashboard/lapis/setting/device/new";
const DETAIL = "/dashboard/lapis/setting/device";

const DeviceTable = ({origData, data, error, lastUpdate, setTableData, sortedColumnIndex, setSortedData}) => (
  <div className="main">
    <h3>ASSOCIATED Devices</h3>
    <div className="button-container"><Link to={ADD}>+ ADD NEW</Link></div>
    <span id="error">{error}</span>
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData}/>
    <div className="lapis-update">Updated {lastUpdate}</div>
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>Device ID<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
           <Link to={`${DETAIL}/${data[props.rowIndex].id}`}>{data[props.rowIndex].name}</Link>
          </Cell>
        )}
        width={300}
      />
      <Column
        header={<Cell>NODE ID<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].id}
          </Cell>
        )}
        width={560}
      />
      <Column
        header={<Cell>STATUS<Sort thisColumnIndex={2} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].status ? data[props.rowIndex].status.toUpperCase() : ""}
          </Cell>
        )}
        width={200}
      />
    </Table>
    </div>
  )

class LapisDeviceTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      last_update: "",
      origData: [],
      tableData: [],
      sortedColumnIndex: 0,
    };

    this.fetchDeviceData = this.fetchDeviceData.bind(this);
    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
  }

  componentDidMount() {
    this.fetchDeviceData();
    setInterval(()=>this.fetchDeviceData(),15000); //15sec
  }

  componentDidUpdate(prevProps) {

  }

  /*
  [
      {
          "_id": "b725dc41-3abf-4dc4-9d65-627c427f7cd8",
          "site_id": "b4d8242e-a850-45df-8b2b-84a55e00497b",
          "serial_number": "123",
          "act_state": "activated",
          "sf_id": "E312C7",
          "sf_pac": "F5F2133D0B8883BD"
      },
      {
          "_id": "11012414-343a-4b9c-b1ca-fcf79bd35164",
          "site_id": "b4d8242e-a850-45df-8b2b-84a55e00497b",
          "serial_number": "E312C9",
          "act_state": "activated",
          "sf_id": "E312C9",
          "sf_pac": "18F372C35468B01A",
          "name": "E312C9",
          "trip_id": "ae2d89ff-fa71-4a87-b4a6-06e0ebf9b279",
          "last_location": {
              "latitude": 35.672965007977986,
              "longitude": 139.7330967847931
          }
      }
  ]
  */
  fetchDeviceData() {
    let array = [];

    fetchLapisData("trackers")
    .then(json => {
      json.forEach(tracker => {
        //console.log(tracker)
        if (!tracker.sf_id) { return; } //activated only
        array.push({id: tracker._id, name: tracker.sf_id, status: tracker.act_state});
      })
      this.setState({last_update: (new Date()).toLocaleString(),  tableData: array, origData: array});
    })
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  render() {
    return (
      <DeviceTable
        origData={this.state.origData}
        data={this.state.tableData}
        error=""
        setTableData={this.setTableData}
        sortedColumnIndex={this.state.sortedColumnIndex}
        setSortedData={this.setSortedData}
        lastUpdate={this.state.last_update}
        />
    )
  }
}

export default LapisDeviceTable;
