import { API_ROOT } from '../DashboardConfig';

const PENDING = 'sites/PENDING';
const LOAD = 'sites/LOAD';
const LOAD_CUSTOMERS = 'sites/LOAD_CUSTOMERS';
const SELECT_SITE = 'sites/SELECT_SITE';
const EDIT_NAME = 'sites/EDIT_NAME';

const initialState = {
  status: 'pending',
  sites: {},
  currentSite: { site_name: '', site: '' },
  customers: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PENDING:
      return Object.assign({}, state, { status: 'pending' });
    case LOAD: {
      const sites = {};
      action.payload.sites.forEach((s) => { sites[s.site] = s; });
      return Object.assign({}, state, { status: 'success', sites });
    }
    case LOAD_CUSTOMERS: {
      return Object.assign({}, state, { customers: action.payload });
    }
    case SELECT_SITE: {
      return Object.assign(
        {},
        state,
        { currentSite: Object.assign(
          {},
          state.sites[action.payload.id],
          { site: action.payload.id },
        )},
      );
    }
    case EDIT_NAME:
      return Object.assign(
        {},
        state,
        { currentSite: Object.assign({}, state.currentSite, { site_name: action.payload.site_name }) },
      );
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
};

// ============================
// ==        Selectors       ==
// ============================
export function getCurrentSiteId(state) { return state.sites.currentSite.site; }

export function getCurrentSite(state) { return state.sites.currentSite; }

export function getSitesArray(state) {
  return Object.values(state.sites.sites);
}

// ============================
// ==     Action Creators    ==
// ============================
export function setSite(id) {
  return { type: SELECT_SITE, payload: { id } };
}

export function selectSite(id) {
  console.log("selecting site:", id);
  return (dispatch) => {
    dispatch(setSite(id));
    // dispatch(newEventManager(id))
  };
  // console.log('selecting site', id);
}

export function editName(site_name) {
  return {
    type: EDIT_NAME,
    payload: { site_name },
  };
}

export function pendingSites() {
  return { type: PENDING };
}

export function loadSites(sites) {
  // console.log(sites);
  return { type: LOAD, payload: sites };
}

export function loadCustomers(customers) {
  return { type: LOAD_CUSTOMERS, payload: customers };
}

export function fetchSites() {
  return (dispatch, getState) => {
    dispatch(pendingSites());

    const { authToken, sites } = getState();

    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    };
    fetch(url, init)
      .then(response => response.json())
      .then((json) => {
        dispatch(loadSites(json));
        const firstSite = json.sites[0];
        if (firstSite && !sites.currentSite.site) {
          dispatch(selectSite(firstSite.site));
        }

        // if (!sites.currentSite.site_id) {
        //   dispatch(selectSite(json.sites[0].site_id));
        // }
      })
      .catch(err => console.log('Pending Node Fetching Error :', err));
  };
}

export function fetchCustomers() {
  return (dispatch, getState) => {
    dispatch(pendingSites());

    const { authToken } = getState();

    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}customers`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    };
    fetch(url, init)
      .then(response => response.json())
      .then((json) => {
        dispatch(loadCustomers(json.customers));
      })
      .catch(err => console.log('Customer Fetching Error :', err));
  };
}

/*
POST {{api}}/sites?
{
  "site": {
    "name": "Site 1",
    "customer": "{{customer}}"
  }
}
*/
export function createSite(site) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites`;
    const init = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(site),
    };

    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        if (json.message) {
          console.log(JSON.stringify(json));
          //handle error
          //dispatch(hubError(json.message))
        } else {
          console.log(json);
          dispatch(fetchSites());
        }
      });
  };
}

/*
PUT {{api}}/sites/{{site}}
{
  "site": {
                "name": "Barbara Site2",
                "configs": {}
          }
}
*/
export function updateSite(id, siteUpdates) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${id}`;
    const init = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(siteUpdates),
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        if (json.message) {
          //dispatch(hubError(json.message));
        } else {
          dispatch(fetchSites());
        }
      });
  };
}
