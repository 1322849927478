//file to store oob zone_src
export const OOB = {
  "2a9642e57d38453bb401d2571825af1d" : {
    "2" : {
      "1": {
          "name": "1",
          "floor": "2",
          "color": "#A9A9A9",
          "points": [
              {
                  "x": -18.2,
                  "y": 81.31
              },
              {
                  "x": 95.47,
                  "y": 81.31
              },
              {
                  "x": 95.47,
                  "y": 95.33
              },
              {
                  "x": -18.2,
                  "y": 95.33
              }
          ]
      },
      "2": {
          "name": "2",
          "floor": "2",
          "color": "#A9A9A9",
          "points": [
              {
                  "x": 95.96,
                  "y": 0
              },
              {
                  "x": 105.51,
                  "y": 0
              },
              {
                  "x": 105.51,
                  "y": 95.33
              },
              {
                  "x": 95.96,
                  "y": 95.33
              }
          ]
      },
      "3": {
          "name": "3",
          "floor": "2",
          "color": "#A9A9A9",
          "points": [
              {
                  "x": -52.34,
                  "y": 0
              },
              {
                  "x": -31.17,
                  "y": 0
              },
              {
                  "x": -31.17,
                  "y": 71.1
              },
              {
                  "x": -52.34,
                  "y": 71.1
              }
          ]
      },
      "4": {
          "name": "4",
          "floor": "2",
          "color": "#A9A9A9",
          "points": [
              {
                  "x": -30.81,
                  "y": 0
              },
              {
                  "x": 0,
                  "y": 0
              },
              {
                  "x": 0,
                  "y": 40.93
              },
              {
                  "x": -30.81,
                  "y": 40.93
              }
          ]
      }
    },
    "1" : {
      "1": {
          "name": "1",
          "floor": "1",
          "color": "#A9A9A9",
          "points": [
              {
                  "x": -53.36,
                  "y": 72.73
              },
              {
                  "x": 95.97,
                  "y": 72.73
              },
              {
                  "x": 95.97,
                  "y": 96.03
              },
              {
                  "x": -53.36,
                  "y": 96.03
              }
          ]
      },
      "2": {
          "name": "2",
          "floor": "1",
          "color": "#A9A9A9",
          "points": [
              {
                  "x": 96.07,
                  "y": 0
              },
              {
                  "x": 104.74,
                  "y": 0
              },
              {
                  "x": 104.74,
                  "y": 96.03
              },
              {
                  "x": 96.07,
                  "y": 96.03
              }
          ]
      }
    }
  }
}
