import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { WAREHOUSE} from '../2dReact/2dConfig';
import { checkACL, checkSiteACL, myHighestRole } from '../acl/Config';

const DashboardMenu = (props) => (
  <div className="dashboard-menu" >
    <ul className="menu-nav">
      <li>
        <NavLink exact to="/dashboard/" className={props.path === "" || props.path==="/" || props.path === "/dashboard/" ? "home-selected" : "home"} >
          <img alt=""/>
        </NavLink>
      </li>

      {
        WAREHOUSE.hasOwnProperty(props.currentSiteId) && WAREHOUSE[props.currentSiteId].hasOwnProperty('wms') && WAREHOUSE[props.currentSiteId].wms &&
        <li>
          <NavLink to="/dashboard/wms_multmaps" className={props.path.startsWith('/dashboard/wms_multmaps') ? 'wms-selected' : 'wms'}>
            <img alt=""/>
          </NavLink>
        </li>
      }
      <li>
        <NavLink to="/dashboard/asset_tracking_2d_multmaps"
          className={props.path.startsWith('/dashboard/asset_tracking_2d_multmaps') ? "asset-tracking-selected" : "asset-tracking"}
        >
          <img alt=""/>
        </NavLink>
      </li>
      { checkSiteACL(props.customer, props.currentSiteId, props.acl, 'Setting Menu Button') &&
        <li>
          <NavLink to="/dashboard/settings" className={props.path.startsWith('/dashboard/settings') ? "settings-icon-selected" : "settings-icon"} >
            <img alt=""/>
          </NavLink>
        </li>
      }
      { checkACL(props.acl, 'Site Management Button') && ['super-admin', 'admin', 'customer-admin'].includes(myHighestRole(props.acl)) &&
        <li>
          <NavLink to="/dashboard/management/users/customeradmin"
            className={props.path.startsWith('/dashboard/management/') ? "management-icon-selected" : "management-icon"}
          >
            <img alt=""/>
          </NavLink>
        </li>
      }
      { checkACL(props.acl, 'Site Management Button') &&   myHighestRole(props.acl) === 'site-admin' &&
        <li>
          <NavLink to="/dashboard/management/users/siteadmin"
            className={props.path.startsWith('/dashboard/management/') ? "management-icon-selected" : "management-icon"}
          >
            <img alt=""/>
          </NavLink>
        </li>
      }
    </ul>
  </div>
);

export default connect(
  state => ({
    currentSiteId: state.sites.currentSite.site,
    path: state.routing.location.pathname,
    customer: state.sites.currentSite.customer,
    acl: state.user.acl,
  }),
)(DashboardMenu);
