import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DashboardHeader from './DashboardHeader';
import DashboardMenu from './DashboardMenu';
import {HomePage} from '../HomePage.js';
import Settings from '../settings/Settings';
import CamerasPage from '../events/CamerasPage';
import TrackAssetsPage from '../TrackAssetsPage.js';
import TrackAssetsPage2D from '../TrackAssetsPage2D.js';
import TrackAssetsPage2DSandbox from '../TrackAssetsPage2DSandbox.js';
import TrackAssetsPage2DMultMaps from '../TrackAssetsPage2DMultMaps.js';
import WMSPageMultMaps from '../2dReact/wms/WMSPageMultMaps.js'
import CameraDetailPage from '../devices/CameraDetailPage';
import ConstellationsPage from '../constellations/ConstellationsPage';
import ConstellationDetailsPage from '../constellations/ConstellationDetailsPage';
import AnalyticsPage from '../analytics/AnalyticsPage';
import SmartDockPage from '../smart_dock/SmartDockPage';
import DockDetailPage from '../smart_dock/DockDetailPage';
import SummaryDetail from '../smart_dock/SummaryDetail';
import ManagementPage from '../acl/ManagementPage';
import MscsPage from '../2dReact/mscs/MscsPage';
import LapisScPage from '../lapis/LapisScPage';
import LapisSdPage from '../lapis/LapisSdPage';
import LapisSettingPage from '../lapis/LapisSettingPage';

//<Route path="/dashboard/smart_dock/:dock" component={DockDetailPage} />
class Dashboard extends React.Component {
  render() {
    return (
      <div className="dashboard-page">
        <DashboardHeader />
        <DashboardMenu />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/dashboard/" component={HomePage} />
          <Route exact path="/dashboard/cameras" component={CamerasPage} />
          <Route exact path="/dashboard/constellations" component={ConstellationsPage} />
          <Route exact path="/dashboard/constellations/:constellation" component={ConstellationDetailsPage} />
          <Route path="/dashboard/settings" component={Settings} />
          <Route path="/dashboard/asset_tracking" component={TrackAssetsPage} />
          <Route path="/dashboard/asset_tracking_2d" component={TrackAssetsPage2D} />
          <Route path="/dashboard/asset_tracking_2d_sandbox" component={TrackAssetsPage2DSandbox} />
          <Route path="/dashboard/asset_tracking_2d_multmaps" component={TrackAssetsPage2DMultMaps} />
          <Route path="/dashboard/wms_multmaps" component={WMSPageMultMaps} />
          <Route path="/dashboard/cameras/:node/:from?/:to?" component={CameraDetailPage} />
          <Route path="/dashboard/analytics" component={AnalyticsPage} />
          <Route path="/dashboard/smart_dock" component={SmartDockPage} />
          <Route path="/dashboard/dock_detail/:dock" component={DockDetailPage} />
          <Route path="/dashboard/summary_detail/:index/:dock/:from/:to" component={SummaryDetail} />
          <Route path="/dashboard/management" component={ManagementPage} />
          <Route path="/dashboard/mscs" component={MscsPage} />
          <Route path="/dashboard/lapis/sc" component={LapisScPage} />
          <Route path="/dashboard/lapis/sd" component={LapisSdPage} />
          <Route path="/dashboard/lapis/setting" component={LapisSettingPage} />
        </Switch>
      </div>
    )
  }
}

export default Dashboard;
