import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateUser } from '../../../modules/users';

import InfoContainer from '../../settings/form/InfoContainer';
import FormContainer from '../../settings/form/FormContainer';
import LabelContainer from '../../settings/form/LabelContainer';
import { ROLES_LOOKUP } from '../Config';
import { isEmpty, isNotPassword, isNotSame} from '../../settings/form/ErrorChecking';

//{"userId":"54f4a26713364cce85ebb8409b93397a","userName":"site-guestsite-full-access","error":null,"acl":[["e04874ba6528470ca13a2856d8eadcfa","1dae6638dcee4d479a2b27b3a40e0ddb","site-full-access"],["ce2d9c8fba0b410fa630cf02cd07ecb1","4ac3dd2aa3d94fe29996867653b21788","site-guest"]]}
const CONFIG = { username: "", new_password: "", confirm_password: "", validate: true};
const URL = '/dashboard/';
const PASSWORD_HELP = <div>
                        <div>Make sure your password has...</div>
                        <div>At least 8 characters</div>
                        <div>At least one uppercase letter (A-Z)</div>
                        <div>At least one lowercase letter (a-z)</div>
                        <div>At least one number (0-9)</div>
                        <div>At least one special character (e.g. !@#$)</div>
                      </div>

const MyProfile = (props) => {
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [updatePass, setUpdatePass] = useState(false);

  useEffect(() => {
    console.log("useeffect")
    let configNew = {...CONFIG};
    configNew.username = props.user.userName;
    setConfig(configNew);
  }, [props.user])

  const validateFields = () => {
    let errorNew = {...CONFIG};
    isNotPassword("confirm_password", config.confirm_password, errorNew);
    isEmpty("new_password", config.new_password, errorNew);
    isEmpty("confirm_password", config.confirm_password, errorNew);
    isNotSame("confirm_password", config.new_password, config.confirm_password, errorNew)
    setError(errorNew);
    return errorNew.validate;
  };

  const updateField = (field, e, type) => {
    if (type === 'long_button') {
      setUpdatePass(true);
      return;
    }

    let configNew = {...config};
    configNew[field]  = e.target.value;
    //console.log(JSON.stringify(configNew));
    setConfig(configNew);
    setError(CONFIG);
  }

  const handleSubmit = (e)=> {
    if (!validateFields()) {
      return;
   }

   console.log("submitting...")
   let body = {
    password: config.new_password
  }

  updateUser(props.authToken, body)
    .then(resp => {
      let errorNew = {...error};
      errorNew.status = "Your password has been updated";
      setError(errorNew);
    })
    .catch(err => {
      console.error(err.status)
      let errorNew = {...error};
      errorNew.status = "Update error - " + err.status;
      setError(errorNew);
    })
  };

  const LABELS = {
    "Username": {"help": "", "desc": ""},
    "Password": {"help": "", "desc": ""},
  }

  const LABELS2 = {
    "Username": {"help": "", "desc": ""},
    "New Password": {"help": "", "desc": ""},
    "Confirm New Password": {"help": "", "desc": ""},
  }
  const FIELDS = {
    "email": {"type": "disabled_textfield", "placeholder": config.username},
    "password": {type: "long_button", label: "Update Password", value: "", error: ""},
  }

  const FIELDS2 = {
    "email": {"type": "disabled_textfield", "placeholder": config.username},
    "new_password": {type: "password", "value": config.new_password, "placeholder": "", "error": error.new_password},
    "confirm_password": {type:"password", "value": config.confirm_password, "placeholder": "", "error": error.confirm_password, help: PASSWORD_HELP},
  }

  return (
    <div className="main edit">
      <h3>User Profile</h3>
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
      <div key="save">
        <LabelContainer labels= {updatePass ? LABELS2 : LABELS}/>
        <FormContainer
          fields = {updatePass ? FIELDS2 : FIELDS}
          updateField={updateField}
        />
        <InfoContainer
        info={{"Site Access" :
                props.user.acl && props.user.acl.map(acl =>
                  <div key={acl[1]}>
                    <div className="field_prop">{acl[1] === '*' ? "All Sites" : props.sites[acl[1]].site_name }</div>
                      :
                      <div className="field_value">{ROLES_LOOKUP[acl[2]]}</div>
                  </div>
                )
              }}
        />
      </div>
      </div>
      <div>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
    authToken: state.authToken,
    user: state.user,
    sites: state.sites.sites,
    //customers: state.sites.customers,
  })
)(MyProfile);
