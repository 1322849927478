/* vim: set fileencoding=utf-8 */
import React from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../modules/users';
//import locixLogoImg from '../media/Locix_Logo.svg';

/**
 * The login form. The onLogin property should be a function that accepts a
 * LocixSession when the user logs in. TODO: Handle errors nicely.
 */
class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            rememberMe: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onForgetPassword = this.onForgetPassword.bind(this);
    }

    defaultProps = {
        onLogin: function(session) {
            console.warning('No onLogin property', session);
        }
    }

    onForgetPassword(event) {
    }

    onSubmit(event) {
      event.preventDefault();
      this.props.dispatch(loginUser(this.state.username, this.state.password));
    }

    onChange(event) {
        let {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }

    render() {
        //<img src={locixLogoImg} alt='' /> //Remove from form
        //<img src={require('../media/zainar-logo.png')} alt='' />
        return (
            <div className='login-page'>
                <div className='login-dialogue'>
                    <div className='locix-image'>
                      <img src={require('../media/zainar-logo2.png')} alt='' />
                    </div>
                    <form className='login-form' onSubmit={this.onSubmit}>
                        <span className="error-message">{this.props.user.error ? this.props.user.error.message : ''}</span>
                        <input
                            name="username"
                            type="text"
                            placeholder="USERNAME"
                            value={this.state.username}
                            onChange={this.onChange} />
                        <input
                            name="password"
                            type="password"
                            placeholder="PASSWORD"
                            value={this.state.password}
                            onChange={this.onChange} />
                        <label>
                            <input
                                id="rememberMe"
                                name="rememberMe"
                                type="checkbox"
                                value={this.state.rememberMe}
                                onChange={this.onChange} />
                            Remember me
                        </label>
                        <a onClick={this.onForgetPassword}
                            className="forgot-password">Forgot Password?</a>
                        <input type="submit" value="SUBMIT" />
                    </form>
                </div>
            </div>
        );

    }
}

export default connect(
  state => ({ authToken: state.authToken, user: state.user }),
)(LoginForm);
