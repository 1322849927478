import React from 'react';
import { TrackAssetItem } from './TrackAssetItem.js';

export class NavNodeList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nodes: this.props.nodes
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
          this.setState({
            nodes: nextProps.nodes
          });
        }
    }

    render() {
        const nodes = this.state.nodes;
        let nodeListItems = nodes.map(node => <TrackAssetItem
                                      key={node.node}
                                      onNodeSelect={this.props.onNodeSelect}
                                      doLocateNode={this.props.doLocateNode}
                                      onTrackNode={this.props.onTrackNode}
                                      doTdoaNode={this.props.doTdoaNode}
                                      session={this.props.session}
                                      node={node} />)

        return (
            <div id="asset-map-node-list-container">
                <ul>{nodeListItems}</ul>
            </div>
        );
    }
}
