import React from 'react';
import CameraEvent from './CameraEvent';

const CameraEventList = ({ nodes, heading }) => (
  <div className="node-event-frame-container">
    <h2>{heading}</h2>
    {
      nodes.map(n => <CameraEvent node={n} key={n.node} />)
    }
  </div>
)


export default CameraEventList;
