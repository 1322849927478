import React from 'react';
import { Table, Column, Cell} from 'fixed-data-table-2';
import {FormattedMessage} from 'react-intl';

const LIMIT = 5;
class MscsTopRacks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
    };
  }

  componentDidMount() {
    this.makeTableData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.rack_stats) !== JSON.stringify(this.props.rack_stats)) {
      this.makeTableData();
    }
  }

  makeTableData() {
    let data = [];
    Object.keys(this.props.rack_stats).forEach(r => {
      let d = {rack: null, visit_count: 0, quantity: 0, distance: null};
      d['rack'] = r;
      if (this.props.rack_stats && this.props.rack_stats.hasOwnProperty(r)) {
        let stats = this.props.rack_stats[r];
        d['visit_count'] = stats.assignments;
        d['quantity'] = stats.items;

        //calculate distance of rack from ticket location
        let distance = null;
        let floor = null;
        for (const f in this.props.racks) {
          if (this.props.racks[f].hasOwnProperty(r)) {
            floor = f;
            break;
          }
        }

        if (floor !== null) {
          if (this.props.coordinates[floor][this.props.racks[floor][r]]) {
            //look up for parent
            let x = this.props.coordinates[floor][this.props.racks[floor][r]].x;
            let y = this.props.coordinates[floor][this.props.racks[floor][r]].y;
            distance = (Math.abs(x - this.props.origin[floor].x) + Math.abs(y - this.props.origin[floor].y)).toFixed(2);
          } else {
            distance = "-"
          }
        }

        d['distance'] = distance;
      }

      data.push(d);
    })

    data = data.sort((a,b) => b.visit_count - a.visit_count);
    this.setState({tableData: data.slice(0, LIMIT)});
  }

  render() {
    return (
      <div>
      <FormattedMessage id="mscs.topfive" defaultMessage="Top 5 Items"/>
      <div className="mscsTopRacks">
        <div className="mscsTable">
          <Table className="MetricTable"
            rowHeight={this.props.config.mscs[this.props.size].top.rowHeight}
            headerHeight={this.props.config.mscs[this.props.size].top.rowHeight}
            rowsCount={Object.keys(this.state.tableData).length}
            width={this.props.config.mscs[this.props.size].top.tableWidth} //356
            height={(Object.keys(this.state.tableData).length + 1) * this.props.config.mscs[this.props.size].top.rowHeight + 5} //210
          >
            <Column columnKey='rack'
                    header={<Cell className="MetricHeader"><span style={{fontSize: "93%"}}><FormattedMessage id="mscs.location" defaultMessage="LOCATION"/></span></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].rack ? this.state.tableData[props.rowIndex].rack : "-"}
                      </Cell>
                    )}
                    width={this.props.config.mscs[this.props.size].top.columnWidth}
            />
            <Column columnKey='visit_count'
                    header={<Cell className="MetricHeader"><FormattedMessage id="mscs.visits" defaultMessage="VISITS"/></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].visit_count ? this.state.tableData[props.rowIndex].visit_count : "-"}
                      </Cell>
                    )}
                    width={this.props.config.mscs[this.props.size].top.columnWidth}
            />
            <Column columnKey='quantity'
                    header={<Cell className="MetricHeader"><FormattedMessage id="mscs.quantity" defaultMessage="QUANTITY"/></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].quantity ? this.state.tableData[props.rowIndex].quantity : "-"}
                      </Cell>
                    )}
                    width={this.props.config.mscs[this.props.size].top.columnWidth}
            />
            <Column columnKey='distance'
                    header={<Cell className="MetricHeader"><FormattedMessage id="mscs.distance" defaultMessage="DISTANCE"/></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].distance ? this.state.tableData[props.rowIndex].distance : "-"}
                      </Cell>
                    )}
                    width={this.props.config.mscs[this.props.size].top.columnWidth}
            />
          </Table>
        </div>
      </div>
      </div>
    )
  }
}

export default MscsTopRacks;
