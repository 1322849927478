import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import persistState from 'redux-localstorage'
//import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
import rootReducer from './modules';
// import { webSocketInit } from './modules/websockets';

//export const history = createHistory();
export const history = createBrowserHistory();

const initialState = {};
const enhancers = [
  persistState([
    'authToken',
    'sites',
    'user',
  ])
];
const middleware = [
  thunk,
  routerMiddleware(history),
];

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }

  const logger = createLogger({
    collapsed: (getState, action) => action.type === '@@router/LOCATION_CHANGE' || 'nodes/UPDATE'
  });
  // middleware.push(logger)
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers,
);

// webSocketInit(store);
window.store = store
export default store;
