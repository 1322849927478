/* vim: set fileencoding=utf-8 */

import { API_ROOT } from './DashboardConfig.js';

import { LocixEventManager } from './LocixEventManager.js';

/**
 * A Locix client session. In addition to the eventManager and siteId stored in
 * the session, the following data is in localStorage, and should be accessed
 * via this module only:
 *
 *  • authToken:    The authorization token for the logged in user, or null if
 *                  no user is logged in
 *  • rememberMe:   true if the currently or previously logged in user selected
 *                  'Remember Me' when they logged in
 *  • username:     The username for the currently or previously logged in
 *                  user, or null if no user is currently logged in or
 *                  rememberMe is false
 *  • email:        The email address for username
 *  • customerId:   The customer ID for the logged in user, or null if unknown
 *  • siteJson:     The name and customerId for each site the user has access
 *                  to, keyed by its site ID, or an empty object if the user
 *                  has access to no sites, or null if unknown
 *  • currentSiteId:The ID for the site the user currently has selected
 *                  (perhaps from a previous login), or null if no site is yet
 *                  selected (because the sites are unknown)
 */
class LocixSession {

    constructor(siteId) {
        this._siteId = siteId;

        // Only relevant to track last Camera Detail page
        this._node = null;

        // Only relevant to track last Constellation Detail page
        this._constellation = null;

        if (siteId) {
            this._eventManager = new LocixEventManager(siteId, ['sites/'+siteId]);
            this._eventManager.connect();
        } else {
            this._eventManager = null;
        }
    }

    set currentNode(currNode) {
        this._node = currNode;
    }

    get currentNode() {
        return this._node;
    }

    get siteId() {
        return this._siteId;
    }

    get eventManager() {
        return this._eventManager;
    }

    disconnect() {
        if (this._eventManager) {
            this._eventManager.disconnect();
        }
    }

    fetch(url, extraInit = {}, extraHeaders = {}) {

        let authToken = window.localStorage.authToken;
        if (!authToken) {
            return Promise.reject(new Error('Not logged in'));
        }

        return new Promise((resolve, reject) => {

            let headers = Object.assign({
                'Authorization': 'Bearer ' + authToken,
            }, extraHeaders);

            let init = Object.assign({
                headers: headers,
            }, extraInit);

            fetch(url, init)
                .then(response => {
                    if (response.ok) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });

    }

    fetchObjectUrl(url, extraInit = {}, extraHeaders = {}) {
        return this.fetch(url, extraInit, extraHeaders)
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob));
    }

    fetchPath(path, extraInit = {}, extraHeaders = {}) {
        let url = API_ROOT + path;
        return this.fetch(url, extraInit, extraHeaders);
    }

    fetchJson(path, extraInit = {}, extraHeaders = {}) {

        let headers = Object.assign({
            'Content-Type': 'application/json',
        }, extraHeaders);

        return this.fetchPath(path, extraInit, headers)
            .then(response => response.json());

    }

    fetchSiteJson(sitePath, extraInit = {}, extraHeaders = {}) {

        let currentSiteId = window.localStorage.currentSiteId;
        if (!currentSiteId) {
            return Promise.reject(new Error('No currentSiteId'));
        }
        let path = 'sites/' + currentSiteId + '/' + sitePath;
        return this.fetchJson(path, extraInit, extraHeaders);

    }

}

/**
 * Attempt to construct a valid session from data in localStorage.
 *
 * @return {@type LocixSession} the session, or null if no valid session could
 *         be constructed
 */
export function getSession() {

    return new Promise((resolve, reject) => {

        let authToken = window.localStorage.authToken;
        if (!authToken) {
            // No user is logged in
            console.log('getSession: no authToken');
            resolve(null);
        } else {
            updateLocalStorage()
                .then(() => {
                    resolve(new LocixSession(window.localStorage.currentSiteId));
                })
                .catch(error => {
                    window.localStorage.authToken = null;
                    console.log('getSession: invalid authToken');
                    resolve(null);
                });
        }
    });
}

/**
 * Log in. Returns a Promise that resolves with a LocixSession.
 */
export function login({ username, password, rememberMe }) {

    return new Promise(function(resolve, reject) {

        let url = API_ROOT + 'authenticate';

        // credentials: 'include',
        let init = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username,
                password: password,
            })
        };

        console.log('Logging in', username, url);
        fetch(url, init)
            .then(response => response.json())
            .then(json => {

                console.log('Successfully logged in');

                window.localStorage.authToken = json.token;
                window.localStorage.rememberMe = rememberMe;
                window.localStorage.username = username;

                let session = new LocixSession(null);

                resolve(session);
            })
            .catch(error => {
                window.localStorage.authToken = null;
                reject(error);
            });

    });
}

export function getUserData() {

    return new Promise((resolve, reject) => {
        updateLocalStorage()
            .then(() => {
                let siteJson = window.localStorage.siteJson;
                let userData = {
                    username: window.localStorage.username,
                    email: window.localStorage.email,
                    rememberMe: window.localStorage.rememberMe,
                    siteIds: JSON.parse(window.localStorage.siteIds),
                    siteData: siteJson ? JSON.parse(siteJson) : null,
                    currentSiteId: window.localStorage.currentSiteId,
                }
                resolve(userData);
            })
            .catch(error => {
console.log('XXX getUserData failed', error);
                reject(error);
            });
    });
}

export function setSiteId(siteId) {
    window.localStorage.currentSiteId = siteId;
    return new LocixSession(siteId);
}

export function logout() {
    window.localStorage.authToken = null;
    window.localStorage.email = null;
    window.localStorage.customerId = null;
    window.localStorage.siteJson = null;
    if (!window.localStorage.rememberMe) {
        window.localStorage.username = null;
    }
}

function updateLocalStorage() {

    return new Promise((resolve, reject) => {

        let authToken = window.localStorage.authToken;
        if (!authToken) {
            return Promise.reject(new Error('Not logged in'));
        }

        // Test is the token is still valid
        let url = API_ROOT + 'whoami';
        let headers = {
            'Authorization': 'Bearer ' + authToken,
        }
        fetch(url, { headers: headers })
            .then(response => {
                if (!response.ok) {
                    window.localStorage.authToken = null;
                    console.log('updateLocalStorage: authToken failed',
                            response);
                    reject(response);
                } else {
                    return response.json();
                }
            })
            .then(whoAmI => {
                // At this point, we know authToken is valid.

                if (!whoAmI) {
console.log('XXX not doing anything');
                    // TODO: Get promise chaining right
                    return;
                }

                console.log('Updating localStorage data for', whoAmI);

                window.localStorage.username = whoAmI.user.name;
                window.localStorage.email = whoAmI.user.email;

                // Save latest siteData
                let firstSiteId = null;
                let siteIds = [];
                Object.assign(siteIds, whoAmI.sites);
                let siteData = Object.entries(whoAmI.sites).reduce(
                        (sd, [i, datum]) => {
                    if (!firstSiteId) {
                        firstSiteId = datum.site;
                    }
                    sd[datum.site] = {
                        name: datum.site_name,
                        customerId: datum.customer_id,
                    }
                    return sd;
                }, {});


                // Make sure currentSiteId and customerId are valid or null
                let currentSiteId = window.localStorage.currentSiteId || null;
                if (!(currentSiteId in siteData)) {
                    currentSiteId = firstSiteId;
                }
                let customerId = currentSiteId ?
                        siteData[currentSiteId].customerId : null;

                window.localStorage.siteIds = JSON.stringify(siteIds);

                window.localStorage.siteJson = siteData ?
                        JSON.stringify(siteData) : null;
                window.localStorage.currentSiteId = currentSiteId;
                window.localStorage.customerId = customerId;

                resolve();
            })
            .catch(error => {
                window.localStorage.authToken = null;
console.log('XXX updateLocalStorage failed', error);
                reject(error);
            });

    });
}
