import React from 'react';

import {IMG_CONFIG} from '../2dConfig';
import {makeYAxis} from './ChartUtil';
import {HorizontalBar} from 'react-chartjs-2';
//import ActivityBarChart from './ActivityBarChartNew';
import TimeIntervalChart from './TimeIntervalChart';

const COLOR = {'active': '#b3f66d', 'inactive': '#969696', '1f_active': '#b3f66d', '2f_active': '#165FC5'};

const options = {
  responsive: false,
  tooltips: {
        mode: 'nearest'
  },
  legend: {
       display: false,
   },
  animation: false,
  scales: {
    yAxes: [{ stacked: true }],
    xAxes: [{
      stacked: true,
      ticks: {
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
        }
      }
    }]
  }
}

class Activity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "time", //"time", "percent"
      labels: [], //percent chart
      activity_data: [],  //percent - activity, inactivity
      has_floor_1: false,
      has_floor_2: false,
      f1_activity_data: [], //percent
      f2_activity_data: [], //percent
      inactivity_data: [], // percent
      y_axis: [], //time_series chart
      time_data: [], //time_series chart
    };

    this.setType = this.setType.bind(this);
  }

  componentDidMount() {
    this.makeData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sorted_nodes.length !== this.props.sorted_nodes.length ||
        JSON.stringify(prevProps.tracker_stats) !== JSON.stringify(this.props.tracker_stats) ||
        prevProps.from !== this.props.from || prevProps.to !== this.props.to ||
        prevProps.expand !== this.props.expand)
    {
      this.makeData();
    }
  }

  makeData() {
    //make labels
    let labels = this.props.sorted_nodes.map(n => n.name);
    let activity = [];
    let inactivity = [];

    //make 1f/2f activity
    let has_floor_1 = false;
    let has_floor_2 = false;
    let f1_active = [];
    let f2_active = [];

    let time_series = []; //[[{}, {}, {}], [], etc...]
    this.props.sorted_nodes.forEach(n => {
        if (this.props.tracker_stats.hasOwnProperty(n.id)) {
            let active = (this.props.tracker_stats[n.id].active_time * 100).toFixed(2);
            let inactive = (100 - active).toFixed(2);
            activity.push(active);
            inactivity.push(inactive);

            //make time series
            let node_time = [];
            node_time.push({start: 0, duration: 1, color: COLOR['inactive']});

            let f1 = 0;
            let f2 = 0;
            this.props.tracker_stats[n.id].active_intervals.forEach( i => {
                if (i['start'] < 0) { return }
                if (i['duration'] * 100 < .001) { return }
                let interval = {...i};
                interval['color'] = COLOR['active'];
                if (i.hasOwnProperty('floor') && i.floor === "1") { interval['color'] = COLOR['1f_active']; }
                if (i.hasOwnProperty('floor') && i.floor === "2") { interval['color'] = COLOR['2f_active']; }
                node_time.push(interval);

                //make 1f/2f percentage
                if (i.hasOwnProperty('floor') && i.floor === "1") { has_floor_1 = true; f1 += i.duration * 100; }
                if (i.hasOwnProperty('floor') && i.floor === "2") { has_floor_2 = true; f2 += i.duration * 100; }
            })

            time_series.push(node_time);
            f1_active.push(f1);
            f2_active.push(f2);
        }
      }
    )

    this.setState({f1_activity_data: f1_active, f2_activity_data: f2_active, has_floor_1: has_floor_1, has_floor_2: has_floor_2, labels: labels, activity_data: activity, y_axis: makeYAxis(this.props.from, this.props.to), inactivity_data: inactivity, time_data: time_series});
  }

  setType(e) {
    this.setState({type: e.target.dataset.type});
  }

  render() {
    return (
      <div className="SiteChart">
        {this.props.expand ?
          <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
          <img className="ExpandIcon" alt="expand" data-chart="activity" src={require("../img/expand.png")} onClick={this.props.setChartType} />
        }
        <div className="SiteHeader">ACTIVITY
        {
          this.props.displayDownload &&
          <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="lps_activity" data-type={this.state.type} onClick={this.props.setDownloadType}/>
        }
        </div>
        <div id="lps_activity" className="SiteChartContainer">
          { this.state.type === 'time' ?
            <div>
              <img className="ChartOptionsImg" alt="percent_unselect" data-type='percent' src={require("../img/percent_unselect.png")} onClick={this.setType}/>
              <img className="ChartOptionsImg" alt="time_select" src={require("../img/time_select.png")} />
            </div>
             :
            <div>
              <img className="ChartOptionsImg" alt="percent_select" src={require("../img/percent_select.png")} />
              <img className="ChartOptionsImg" alt="time_unselect" data-type='time' src={require("../img/time_unselect.png")} onClick={this.setType}/>
            </div>
          }

          <div className="ActivityText">INACTIVITY</div>
          <div className="ActivityLegend" style={{backgroundColor: "#969696"}}/>
          {
            this.state.has_floor_1 &&
            <div>
              <div className="ActivityText">1F ACTIVITY</div>
              <div className="ActivityLegend" style={{backgroundColor: '#b3f66d'}}/>
            </div>
          }
          { this.state.has_floor_2 &&
            <div>
              <div className="ActivityText">2F ACTIVITY</div>
              <div className="ActivityLegend" style={{backgroundColor: '#165FC5'}}/>
            </div>
          }

          <div style={{display: this.state.type==='percent' ? "" : "none", marginTop: "30px"}}>
            <HorizontalBar
                key={this.state.labels.length}
                width={980}
                height={this.state.labels.length * 25 + 30}
                data={{labels: this.state.labels,
                       datasets: [ {label: 'INACTIVITY', data: this.state.inactivity_data, backgroundColor: '#969696'},
                                   {label: '1F ACTIVITY', data: this.state.f1_activity_data, backgroundColor: '#b3f66d'},
                                   {label: '2F ACTIVITY', data: this.state.f2_activity_data, backgroundColor: '#165FC5'},
                                 ],
                     }}
                options={options}
              />
          </div>
          <div style={{display: this.state.type==='time' ? "" : "none"}}>
            <TimeIntervalChart labels={this.state.labels} y_axis={this.state.y_axis} data={this.state.time_data}/>
          </div>
        </div>
      </div>
    )
  }
}

export default Activity;
/*
<Activity from={this.props.from} to={this.props.to} sorted_nodes={this.state.sortedNodes} tracker_stats={this.state.tracker_stats} expand={this.state.expand} setClose={this.onSetClose} setChartType={this.onSetChartType}/>}
{!this.state.has_floor_1 && !this.state.has_floor_2 &&
  <div>
    <div className="ActivityText">ACTIVITY</div>
    <div className="ActivityLegend"/>
  </div>
}

let dset = [ {label: 'INACTIVITY', data: this.state.inactivity_data, backgroundColor: '#969696'} ];
if (this.state.has_floor_1 || this.state.has_floor_2) {
  dset.push({label: '1F ACTIVITY', data: this.state.f1_activity_data, backgroundColor: '#b3f66d'});
  dset.push({label: '2F ACTIVITY', data: this.state.f2_activity_data, backgroundColor: '#5ea8dd'});
} else {
  dset.push({label: 'ACTIVITY', data: this.state.activity_data, backgroundColor: '#b3f66d' })
}

*/
