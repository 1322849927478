//wms report generator
import { convertReportTime, formatTime, formatTextExcel } from '../charts/ChartUtil';

const METERS_PER_FOOT = 0.3048;
const QUERY_LIMIT=86400000;
const HOUR_LIMIT=3600000; //1 hour

export function makeMetricReport(from, to, sorted_nodes, asset_stats, worker_hash) {
    let headers = ['Tracker', 'Picking Time'];
    if (to - from <= QUERY_LIMIT) { headers.push('Working Hours')}
    headers.push(['Items Picked', 'Locations Visited',  'Location/Pick Hr', 'Time per Visit', 'Picking Lines']);
    if (to - from <= QUERY_LIMIT) { headers.push('Time/Line')}
    headers.push(['Distance(m)', 'Active Time', 'Activity(%)']);
    if (to - from <= QUERY_LIMIT) { headers.push(['Tracker Check Out', 'Tracker Check In'])}
    headers.push('Tracker Working Hours');
    headers.push('Distance/Pick');

    let data = [headers.flat().join(",")];
    sorted_nodes.forEach(n => {
      let d = [];
      d.push(formatTextExcel(worker_hash[n].name));
      if (asset_stats && asset_stats.hasOwnProperty(n) && asset_stats[n] !== undefined) {
        let stats = asset_stats[n];
        d.push(stats.picking_time ? convertReportTime(stats.picking_time) : '');
        if (to - from <= QUERY_LIMIT) { d.push(stats.working_time ? convertReportTime(stats.working_time) : '')}
        d.push(stats.picked_items ? stats.picked_items : '');
        d.push(stats.picking_assignments ? stats.picking_assignments : '');
        d.push(stats.picking_assignments && (stats.picking_time >= HOUR_LIMIT) ? parseInt(stats.picking_assignments/(stats.picking_time/HOUR_LIMIT)) : '');
        d.push(stats.picking_time && stats.picking_assignments ? convertReportTime((stats.picking_time / stats.picking_assignments).toFixed(2)) : '');
        d.push(stats.picking_lines ? stats.picking_lines : '');
        if (to - from <= QUERY_LIMIT) { d.push(stats.picking_lines ? convertReportTime(stats.picking_lines && (stats.working_time / stats.picking_lines).toFixed(2)) : '') }
        d.push(stats.distance ? (stats.distance * METERS_PER_FOOT).toFixed(2) : '');
        d.push(stats.active_time ? convertReportTime(stats.active_time) : '');
        d.push(stats.active_time && stats.attached_time ? (stats.active_time / stats.attached_time * 100).toFixed(2) : '')

        if (to - from <= QUERY_LIMIT) {
          if (stats.attachment_intervals && stats.attachment_intervals.length > 0) {
            let start = new Date().getTime();
            let end = 0;
            stats.attachment_intervals.forEach(a => {
              if (a.start < start) {
                start = a.start;
              }

              if ((a.start + a.duration) > end) {
                end = a.start + a.duration;
              }
            })

            d.push(formatTime(start));
            d.push(end >= to - 5000 ? "-" : formatTime(end));
        } else {
          d.push("", "");
        }
      }
      d.push(stats.attached_time ? convertReportTime(stats.attached_time): "");
      d.push((stats.distance && stats.picked_items) ? (stats.distance * METERS_PER_FOOT / stats.picked_items).toFixed(2) : "");
    }
    data.push(d.join(","));
  });

  return data.join("\n");
}

export function makePickingReport(rack_stats, origin, racks, coordinates) {
  let headers = ['Location', 'Visit Count', 'Quantity', 'Estimated Rack Distance(m)'];
  let data = [headers.join(',')];
  Object.keys(rack_stats).forEach(r => {
    let d = [];
    if (rack_stats && rack_stats.hasOwnProperty(r)) {
      let stats = rack_stats[r];
      d.push(r);
      d.push(stats.assignments);
      d.push(stats.items);

      let distance = "";
      let floor = null;
      for (const f in racks) {
        if (racks[f].hasOwnProperty(r)) {
          floor = f;
          break;
        }
      }

      if (floor !== null) {
        if (coordinates[floor][racks[floor][r]]) {
          let x = coordinates[floor][racks[floor][r]].x;
          let y = coordinates[floor][racks[floor][r]].y;
          distance = (Math.abs(x - origin[floor].x) + Math.abs(y - origin[floor].y)).toFixed(2);
        } else {
          distance = ""
        }
      }

      d.push(distance);
    }

    data.push(d.join(","));
  })

  return data.join("\n");
}

export function makeDistanceReport(sorted_nodes, zone_config, asset_stats, worker_hash) {
  let headers = ['Tracker', 'Zone', 'Distance(m)'];
  let data = [headers.join(',')];

  sorted_nodes.forEach(n => {
    if (asset_stats.hasOwnProperty(n) && asset_stats[n] !== undefined && asset_stats[n].hasOwnProperty('zones')) {
      let zone_stats = asset_stats[n].zones;

      for (const z in zone_stats) {
        let d = [];
        d.push(formatTextExcel(worker_hash[n].name));
        d.push(zone_config[z].name);
        d.push((zone_stats[z].distance * METERS_PER_FOOT).toFixed(2));
        data.push(d.join(","));
      }
    }
  });

  return data.join('\n');
}

export function makeLpsZonedistanceReport(sorted_nodes, zone_config, tracker_stats) {
  let headers = ['Tracker', 'Zone', 'Distance(m)'];
  let data = [headers.join(',')];

  sorted_nodes.forEach(n => {
    if (tracker_stats.hasOwnProperty(n.id) && tracker_stats[n.id].hasOwnProperty('zone_stats')) {
      let zone_stats = tracker_stats[n.id].zone_stats
      for (const z in zone_stats) {
        data.push([formatTextExcel(n.name), formatTextExcel(zone_config[z].name), (zone_stats[z].distance * METERS_PER_FOOT).toFixed(2)].join(","))
      }
    }
  })

  return data.join("\n");
}

export function makeActivityPercentReport(from, to, dur, sorted_nodes, asset_stats, worker_hash) {
  let headers = ['Tracker', 'Picking(%)', '1F Active(%)', '2F Active(%)', '4F Activit(%)','Inactive(%)'];
  let data = [headers.join(",")];

  sorted_nodes.forEach( n => {
    let d = [];
    if (asset_stats.hasOwnProperty(n) && asset_stats[n] !== undefined && asset_stats[n].hasOwnProperty('activity_percentage')) {
      let pick = 0;
      let f1_active = 0;
      let f2_active = 0;
      let f4_active = 0;
      let inactive = 0;
      let duration = asset_stats[n].attached_time ? asset_stats[n].attached_time : dur;

      asset_stats[n].activity_percentage.forEach( a => {
        if (a.activity === 'picking') {
          pick = a.duration
        } else {
          if (a.floor === "1") { f1_active = a.duration }
          if (a.floor === "2") { f2_active = a.duration }
          if (a.floor === "4") { f4_active = a.duration }
        }
      });

      f1_active = duration ? ((f1_active / duration) * 100).toFixed(2) : 0;
      f2_active = duration ? ((f2_active / duration) * 100).toFixed(2) : 0;
      f4_active = duration ? ((f4_active / duration) * 100).toFixed(2) : 0;

      pick = duration ? ((pick/ duration) * 100).toFixed(2) : 0;
      inactive = (100 - f1_active - f2_active - f4_active - pick).toFixed(2);
      d = [formatTextExcel(worker_hash[n].name), pick, f1_active, f2_active, f4_active, inactive];
    } else {
      d = [formatTextExcel(worker_hash[n].name), 0, 0, 0, 0, 100];
    }

    data.push(d.join(","));
  })

  return data.join("\n");
}

export function makeLpsActivityPercentReport(sorted_nodes, tracker_stats) {
  let header = ["Tracker Name", "1F Active(%)", "2F Active(%)", "Inactive(%)"];
  let data = [header.join(",")];

  sorted_nodes.forEach(n => {
      if (tracker_stats.hasOwnProperty(n.id)) {
          let f1 = 0;
          let f2 = 0;
          tracker_stats[n.id].active_intervals.forEach( i => {
              if (i.hasOwnProperty('floor') && i.floor === "1") { f1 += i.duration * 100 }
              if (i.hasOwnProperty('floor') && i.floor === "2") { f2 += i.duration * 100 }
          })
          data.push([formatTextExcel(n.name), f1.toFixed(2), f2.toFixed(2), 100 - f1.toFixed(2) - f2.toFixed(2)].join(","))
      }
    }
  )

  return data.join("\n");
}

export function makeActivityTimeReport(sorted_nodes, asset_stats, worker_hash) {
  let headers = ['Tracker', 'Type', "Floor", 'Date', 'Time', 'Duration'];
  let data = [headers.join(",")];

  sorted_nodes.forEach( n => {
    if (asset_stats.hasOwnProperty(n) && asset_stats[n] !== undefined && asset_stats[n].hasOwnProperty('activity_intervals')) {
      asset_stats[n].activity_intervals.forEach( a => {
        let d = [];
        let date = new Date(a.start);

        d.push(formatTextExcel(worker_hash[n].name));
        d.push(a.activity);
        d.push(a.floor);
        d.push((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
        d.push(date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
        d.push(convertReportTime(a.duration));
        data.push(d.join(","));
      });

      if (!asset_stats[n].hasOwnProperty('attachment_intervals')) { return; }
      asset_stats[n].attachment_intervals.forEach( a => {
        let d = [];
        let date = new Date(a.start);

        d.push(formatTextExcel(worker_hash[n].name));
        d.push('checked out');
        d.push('');
        d.push((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
        d.push(date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
        d.push(convertReportTime(a.duration));
        data.push(d.join(","));
      })
    }
  });

  return data.join("\n");
}

export function makeLpsActivityTimeReport(from, to, sorted_nodes, tracker_stats) {
  let headers = ['Tracker', 'Floor', 'Date', 'Time', 'Duration'];
  let data = [headers.join(",")];

  sorted_nodes.forEach(n => {
      if (tracker_stats.hasOwnProperty(n.id)) {
          tracker_stats[n.id].active_intervals.forEach( i => {
              if (i['start'] < 0) { return }
              if (i['duration'] * 100 < .001) { return }

              let duration = to - from;
              let start = duration * i.start + from;
              let date = new Date(start);

              let d = [formatTextExcel(n.name), i.floor];
              d.push((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
              d.push(date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
              d.push(convertReportTime(duration * i.duration));

              data.push(d.join(","))
          })
      }
    }
  )

  return data.join("\n");
}

export function makeZonePercentReport(from, to, dur, sorted_nodes, zone_config, asset_stats, worker_hash) {
  let headers = ['Tracker', 'Zone', 'Percent of Time(%)'];
  let data = [headers.join(",")];

  sorted_nodes.forEach(n => {
    if (asset_stats.hasOwnProperty(n) && asset_stats[n] !== undefined && asset_stats[n].hasOwnProperty('zones')) {
      console.log(dur)
      let zone_stats = asset_stats[n].zones;
      let duration = asset_stats[n].attached_time ? asset_stats[n].attached_time : dur;

      for (const z in zone_stats) {
        let d = [];
        d.push(formatTextExcel(worker_hash[n].name));
        d.push(formatTextExcel(zone_config[z].name));
        d.push(((zone_stats[z].time/duration) * 100).toFixed(2));
        data.push(d.join(","));
      }
    }
  });

  return data.join("\n");
}

export function makeLpsZonetimePercentReport(sorted_nodes, zone_config, tracker_stats) {
  let headers = ['Tracker', 'Zone', 'Percent of Time(%)'];
  let data = [headers.join(",")];

  sorted_nodes.forEach(n => {
    if (tracker_stats.hasOwnProperty(n.id) && tracker_stats[n.id].hasOwnProperty('zone_stats')) {
      let zone_stats = tracker_stats[n.id].zone_stats
      for (const z in zone_stats) {
        data.push([formatTextExcel(n.name), formatTextExcel(zone_config[z].name), (zone_stats[z].time * 100).toFixed(2)].join(","))
      }
    }
  })

  return data.join("\n");
}

export function makeZoneTimeReport(sorted_nodes, zone_config, asset_stats, worker_hash) {
  let headers = ['Tracker', 'Zone', 'Date', 'Time', 'Duration'];
  let data = [headers.join(",")];

  sorted_nodes.forEach(n => {
    if (asset_stats.hasOwnProperty(n) && asset_stats[n] !== undefined && asset_stats[n].hasOwnProperty('zone_intervals')) {
      asset_stats[n].zone_intervals.forEach(z => {
        if (z['zones'].length === 0 || !zone_config.hasOwnProperty(z['zones'][0])) { return }
        let d = [];
        let date = new Date(z.start);

        d.push(formatTextExcel(worker_hash[n].name));
        d.push(zone_config[z['zones'][0]].name);
        d.push((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
        d.push(date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
        d.push(convertReportTime(z.duration));
        data.push(d.join(","));
      });

      if (!asset_stats[n].hasOwnProperty('attachment_intervals')) { return; }
      asset_stats[n].attachment_intervals.forEach( a => {
        let d = [];
        let date = new Date(a.start);

        d.push(formatTextExcel(worker_hash[n].name));
        d.push('checked out');
        d.push((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
        d.push(date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
        d.push(convertReportTime(a.duration));
        data.push(d.join(","));
      })
    }
  });

  return data.join("\n");
}

export function makeLpsZonetimeTimeReport(from, to, sorted_nodes, zone_config, tracker_stats) {
  let headers = ['Tracker', 'Zone', 'Date', 'Time', 'Duration'];
  let data = [headers.join(",")];
  let duration = to - from;

  sorted_nodes.forEach(n => {
    if (tracker_stats.hasOwnProperty(n.id) && tracker_stats[n.id].hasOwnProperty('zone_intervals')) {
      tracker_stats[n.id].zone_intervals.forEach(i => {
        if (i['start'] < 0) { return }
        if (i['duration'] * 100 < .1) { return }
        if (i['zones'].length === 0 || !zone_config.hasOwnProperty(i['zones'][0])) { return }

        let date = new Date(i.start * duration + from);
        let d = [formatTextExcel(n.name), formatTextExcel(zone_config[i['zones'][0]].name)];
        d.push((date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear());
        d.push(date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
        d.push(convertReportTime(duration * i.duration));
        data.push(d.join(","));
      })
    }
  });

  return data.join("\n");
}

export function makeLpsMetricReport(from, to, sorted_nodes, tracker_stats) {
  let headers = ['Tracker', 'Distance(m)', 'Active Time', 'Activity(%)'];
  let data = [headers.join(",")];

  sorted_nodes.forEach(n => {
    if (tracker_stats && tracker_stats.hasOwnProperty(n.id)) {
      let stats = tracker_stats[n.id];
      let d = [formatTextExcel(n.name)];
      d.push((stats.distance * METERS_PER_FOOT).toFixed(2));
      d.push( convertReportTime((to - from) * stats.active_time) );
      d.push((stats.active_time * 100).toFixed(2));
      data.push(d.join(","))
    }
  })

  return data.join("\n");
}

export function makeLpsMetricReportNew(duration, sorted_nodes, tracker_stats, worker_hash) {
  let headers = ['Tracker', 'Distance(m)', 'Active Time', 'Activity(%)'];
  let data = [headers.join(",")];

  sorted_nodes.forEach(n => {
    if (tracker_stats && tracker_stats.hasOwnProperty(n)) {
      let stats = tracker_stats[n];
      let d = [formatTextExcel(worker_hash[n].name)];
      d.push((stats.distance * METERS_PER_FOOT).toFixed(2));
      d.push( convertReportTime(stats.active_time) );
      d.push(((stats.active_time/duration) * 100).toFixed(2));
      data.push(d.join(","))
    }
  })

  return data.join("\n");
}

export function makeZoneDistanceReport(sorted_nodes, zone_config, asset_stats) {
  let headers = ['Zone', 'Distance(m)'];
  let data = [headers.join(",")];

  let zones = {}
  for (const z in zone_config) {
    let zn = zone_config[z]
    zones[z] = { name: zn.name, distance: 0, id: z, color: "#" + zn.color}
  }

  //sum up the distances
  sorted_nodes.forEach(n => {
    if (asset_stats.hasOwnProperty(n) && asset_stats[n] !== undefined && asset_stats[n].hasOwnProperty('zones')) {
      let zone_stats = asset_stats[n].zones;
      for (const z in zone_stats) {
        if (zones.hasOwnProperty(z)) {
          zones[z].distance = zone_stats[z].distance + zones[z].distance;
        }
      }
    }
  })

  for (const z in zones) {
    data.push([zones[z].name, (zones[z].distance * METERS_PER_FOOT).toFixed(2)].join(","))
  }

  return data.join("\n");
}
