import React from 'react';

const SmartDockOverview = ({available, occupied, average, warning, active}) => (
      <div className="SnapShotContainer">
        <div className="DockSnapShot">
          <img className="DockIcon" src={require("./img/available-dock.png")} alt="AvailableDocks"/>
          <div className="DockText">{available} DOCKS</div>
          <div className="DockStatus">Available</div>
        </div>
        <div className="DockSnapShot">
          <img className="DockIcon" src={require("./img/occupied-dock.png")} alt="OccupiedDocks"/>
          <div className="DockText">{occupied} DOCKS</div>
          <div className="DockStatus">Occupied</div>
        </div>
        <div className="DockSnapShot DockDelay">
          <img className="DockIcon" src={require("./img/delay-alert.png")} alt="DelayAlert"/>
          <div className="DockText">{warning} WARNING</div>
          <div className="DockStatus">Time Delays</div>
        </div>
        <div className="DockSnapShot">
          <img className="DockIcon" src={require("./img/activity.png")} alt="Activity"/>
          <div className="DockText">{active} ACTIVE</div>
          <div className="DockStatus">Interior Activity</div>
        </div>
        <div className="DockSnapShot">
          <img className="DockIcon ClockIcon" src={require("./img/clock.png")} alt="Dwell Time"/>
          <div className="DockText">{average} MINUTES</div>
          <div className="DockStatus">Avg Dwell Time</div>
        </div>
      </div>
);

export default SmartDockOverview;
