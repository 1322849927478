import React from 'react';
import { connect } from 'react-redux';

import '../components/2dReact/css/2dReact.css';
import { WAREHOUSE } from '../components/2dReact/2dConfigSandbox.js';

import NavMap2d from '../components/2dReact/NavMap2dNew';
import NavControl2d from '../components/2dReact/NavControl2dSandbox';
import NavList2d from '../components/2dReact/NavList2dSandbox';
import NavMap2dPath from '../components/2dReact/NavMap2dPathSandbox'
import NavMap2dPath2 from '../components/2dReact/NavMap2dPathSandbox2';
//import NavMap2dHeatmap from '../components/2dReact/NavMap2dHeatmapSandbox';

import { getNodesArray } from '../modules/nodes';
import { getCurrentSiteId} from '../modules/sites';
import { fetchSiteJson } from '../modules/api';

import control from './2dReact/img/controller.png';
import control_small from './2dReact/img/controller_small.png';

const METERS_PER_FOOT = 0.3048;

class TrackAssetsPage2D extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      worldNodes: {}, //all nodes with locations
      worldAnchors: {},  //all anchors with locations
      listNodes: [], //nodelist that goes into menu has all nodes
      showNodes: {}, //showNodes only contains nodes with locations
      make_large: false,
      control_icon: control_small,
      width: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.width : WAREHOUSE['default'].small.width,
      height: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.height: WAREHOUSE['default'].small.height,
      pixels_per_meter: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.pixels_per_meter : WAREHOUSE['default'].small.pixels_per_meter,
      display_nodelist: true,
      maptype: 'path', //'path', //default
      from: Date.now() - 3600000,
      to: Date.now(),
      showAnchors: false,
      showCards: {}, //hash of cards with flag
    };

    this.onLocate = this.onLocate.bind(this);
    this.onSelect = this.onSelect.bind(this); //show the node in the maps
    this.onSelectAll = this.onSelectAll.bind(this);
    this.setMapType = this.setMapType.bind(this);
    this.setFromTo = this.setFromTo.bind(this);
    this.switch_view = this.switch_view.bind(this);
    this.showAnchors = this.showAnchors.bind(this);
    this.onShowCard = this.onShowCard.bind(this); //show the node card in maps
  }

  componentDidMount() {
    console.log("TrackAssetsPage2DSandBox - 2D Mounted: " + this.props.siteId);
    this.newNodes(this.props.nodes);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.siteId !== nextProps.siteId) {
      console.log("TrackAssetsPage2D - my site changed: " + this.props.siteId)

      let width = WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].small.width : WAREHOUSE['default'].small.width ;
      let height = WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].small.height: WAREHOUSE['default'].small.height;
      let pm = WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].small.pixels_per_meter : WAREHOUSE['default'].small.pixels_per_meter;

      if (this.state.make_large) {
        width = WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].big.width : WAREHOUSE['default'].big.width ;
        height = WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].big.height : WAREHOUSE['default'].big.height;
        pm = WAREHOUSE.hasOwnProperty(nextProps.siteId) ? WAREHOUSE[nextProps.siteId].big.pixels_per_meter : WAREHOUSE['default'].big.pixels_per_meter;
      }

      this.setState({
          width: width,
          height: height,
          pixels_per_meter:pm,
      })
    }

    this.newNodes(nextProps.nodes);
  }

  newNodes(nodes) {
    this.setState((prevState, props) => {
      const newNodeCollection = {};
      const newAnchorCollection = {};
      const nodeList = [];
      let showNodes = {...this.state.showNodes};
      let showCards = {...this.state.showCards};

      nodes.forEach((node) => {
        const nodeId = node.node;
        //console.log('NODE VALUES: ', JSON.stringify(node));
        if (node.node_type === 'tracker') {
          //for sandbox - just have 2 types for now - forklist or worker
          let type = node.configs.tracker && node.configs.tracker.tracker_type === 'forklift' ? node.configs.tracker.tracker_type : 'worker';

          let nodeListNode = {id: nodeId, name: node.name, type: type, connected: (node.hasOwnProperty('events') && node.events.hasOwnProperty('system') && node.events.system.hasOwnProperty('connection') ? node.events.system.connection.connected : "")}

          if (node.hasOwnProperty('events') && node.events.hasOwnProperty('tracker') && node.events.tracker.hasOwnProperty('location') &&
            node.events.tracker.location.hasOwnProperty('meas_id')) {
            const theLoc = node.events.tracker.location;

            nodeListNode = Object.assign({}, nodeListNode,{meas_id: parseInt(theLoc.meas_id, 16), timestamp: theLoc.timestamp,
                       voltage: node.events.hasOwnProperty('battery') ? node.events.battery.voltage : null,
                       connected: node.events.hasOwnProperty("connection") && node.events.connection.hasOwnProperty("connection") ? node.events.system.connection.connected : ""})

            //remove the nodes I don't want to display
            if (!showNodes.hasOwnProperty(nodeId)) { showNodes[nodeId] = {show: true, type: type}}
            if (!showCards.hasOwnProperty(nodeId)) { showCards[nodeId] = false }

            if (showNodes[nodeId].show) {
              newNodeCollection[nodeId] = {
                id: nodeId,
                name: node.name,
                x: theLoc.hasOwnProperty('x') ? theLoc.x * METERS_PER_FOOT : theLoc.filtered[0] * METERS_PER_FOOT,
                y: theLoc.hasOwnProperty('y') ? theLoc.y * METERS_PER_FOOT : theLoc.filtered[1] * METERS_PER_FOOT,
                z: theLoc.hasOwnProperty('z') ? theLoc.z * METERS_PER_FOOT : theLoc.filtered[2] * METERS_PER_FOOT,
                meas_id: parseInt(theLoc.meas_id, 16),
                timestamp: theLoc.timestamp,
                voltage: node.events.hasOwnProperty('battery') ? node.events.battery.voltage : null,
                connected: node.events.hasOwnProperty("connection") && node.events.connection.hasOwnProperty("connection") ? node.events.system.connection.connected : "",
                type: type,
              }
            }
          }

          nodeList.push(nodeListNode);
        }

        if (node.node_type === 'anchor' && this.state.showAnchors) {
          if (node.configs.anchor.hasOwnProperty('x') && node.configs.anchor.hasOwnProperty('y') && node.configs.anchor.hasOwnProperty('z')) {
            newAnchorCollection[node.name] = {
              x: node.configs.anchor.x / 100 * METERS_PER_FOOT,
              y: node.configs.anchor.y / 100 * METERS_PER_FOOT,
              z: node.configs.anchor.z / 100 * METERS_PER_FOOT,
            }
          }
        }
      });

      return {
        worldNodes: newNodeCollection,
        worldAnchors: newAnchorCollection,
        listNodes: nodeList,
        showNodes: showNodes,
        showCards: showCards,
      };
    });
  }

  switch_view() {
    if (this.state.make_large) {
      this.setState({
        make_large: false,
        control_icon: control_small,
        width: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.width : WAREHOUSE['default'].small.width ,
        height: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.height : WAREHOUSE['default'].small.height,
        pixels_per_meter: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].small.pixels_per_meter : WAREHOUSE['default'].small.pixels_per_meter,
        display_nodelist: true
      }, ()=>{this.newNodes(this.props.nodes)})
    } else {
      this.setState({
        make_large: true,
        control_icon: control,
        width: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].big.width : WAREHOUSE['default'].big.width,
        height: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].big.height : WAREHOUSE['default'].big.height,
        pixels_per_meter: WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].big.pixels_per_meter : WAREHOUSE['default'].big.pixels_per_meter,
        display_nodelist: false
      }, ()=>{this.newNodes(this.props.nodes)})
    }
  }

  onSelect(id) {
    console.log("On Select " + id)
    let status = id.split(" ");
    let showNodes = {...this.state.showNodes}
    let showCards = {...this.state.showCards}

    showNodes[status[0]].show = JSON.parse(status[1])
    if (showNodes[status[0]].show === false) { showCards[status[0]] = false }

    this.setState({showNodes: showNodes, showCards: showCards}, ()=>this.newNodes(this.props.nodes))
  }

  onSelectAll(id) {
    let showNodes = {...this.state.showNodes}
    let cards = {...this.state.showCards}

    for (const nodeId in showNodes) {
      if (id.target.checked && showNodes[nodeId].type === id.target.dataset.type) {
        showNodes[nodeId].show = true
      }

      if (!id.target.checked && showNodes[nodeId].type === id.target.dataset.type) {
        showNodes[nodeId].show = false
        cards[nodeId] = false
      }
    }
    this.setState({showNodes: showNodes, showCards: cards}, ()=>this.newNodes(this.props.nodes))
  }

  onLocate(id) {
    let locatePath = `nodes/${id}/action`;
    let init = {};
    init.method = 'post';
    init.body = JSON.stringify({
      "tracker": {
        "locate": {"ref_id" : 0}
      }
    });

    fetchSiteJson(locatePath, this.props.authToken, this.props.siteId, init)
      .then(json => console.log(JSON.stringify(json)))
      .catch(error => console.log(error));
  }

  setMapType(e) {
    console.log("set maptype " + e.target.dataset.maptype)
    let showCards = {...this.state.showCards}

    for (const nodeId in showCards) { showCards[nodeId] = false }

    this.setState({showCards: showCards, maptype: e.target.dataset.maptype}, ()=>this.newNodes(this.props.nodes))
  }

  setFromTo(from, to) {
    console.log("set from and to " + from + " " + to + " : " + (new Date(from)) + " " + (new Date(to)));
    this.setState({from: from, to: to});
  }

  showAnchors(e) {
    console.log("set show anchor " + e.target.checked)
    this.setState({showAnchors: e.target.checked}, ()=>this.newNodes(this.props.nodes))
  }

  onShowCard(id) {
    //console.log(id.target.attrs.node.nodeId) //from maps
    if (id.hasOwnProperty('target')) { id = id.target.attrs.node.nodeId}

    let showCards = {...this.state.showCards};
    if (!this.state.showNodes[id].show) {
      showCards[id] = false;
    } else {
      showCards[id] ? showCards[id] = false : showCards[id] = true
    }

    console.log("set onShowCards " + id + " " + showCards[id]);
    this.setState({showCards: showCards})
  }

  render() {
    let map = <div></div>
    if (this.state.maptype === 'realtime') {
      map = <NavMap2d
        key={this.props.siteId + "_realtime"}
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        background_src={WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].background_src : WAREHOUSE['default'].background_src}
        width={this.state.width}
        height={this.state.height}
        pixels_per_meter={this.state.pixels_per_meter}
        cards={this.state.showCards}
        onShowCard={this.onShowCard}
        onLocate={this.onLocate}
        origin={WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId].origin : WAREHOUSE['default'].origin}
      />
    } else if (this.state.maptype === 'path') {
      map = <NavMap2dPath
        key={this.props.siteId + "_path"}
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        background_src={WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].background_src : WAREHOUSE['default'].background_src}
        width={this.state.width}
        height={this.state.height}
        pixels_per_meter={this.state.pixels_per_meter}
        from={this.state.from}
        to={this.state.to}
        cards={this.state.showCards}
        onShowCard={this.onShowCard}
        origin={WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId].origin : WAREHOUSE['default'].origin}
        />
    } else if (this.state.maptype === 'path2') {
      map = <NavMap2dPath2
        key={this.props.siteId + "_path_filtered"}
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        background_src={WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].background_src : WAREHOUSE['default'].background_src}
        width={this.state.width}
        height={this.state.height}
        pixels_per_meter={this.state.pixels_per_meter}
        from={this.state.from}
        to={this.state.to}
        cards={this.state.showCards}
        onShowCard={this.onShowCard}
        origin={WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId].origin : WAREHOUSE['default'].origin}
        />
    }

    /*else if (this.state.maptype.startsWith('heatmap')) {
      map = <NavMap2dHeatmap
        key={this.props.siteId + "_heatmap"}
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        background_src={WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].background_src : WAREHOUSE['default'].background_src}
        width={this.state.width}
        height={this.state.height}
        pixels_per_meter={this.state.pixels_per_meter}
        from={this.state.from}
        to={this.state.to}
        type={this.state.maptype.split("-")[1]}
      />
    }*/

    return (
      <div className="Container2D">
          <div style={{position: "absolute", marginTop: "3px", marginLeft: "35px", fontSize: 18, color: "red", zIndex:"1"}}>LOCIX SANDBOX</div>
          <NavList2d
            key={this.props.siteId + "_list"}
            display={this.state.display_nodelist}
            siteId={this.props.siteId}
            nodes={this.state.listNodes}
            showNodes={this.state.showNodes}
            onSelect={this.onSelect}
            onLocate={this.onLocate}
            onSelectAll={this.onSelectAll}
            showAnchors={this.showAnchors}
            displayAnchors={this.state.showAnchors}
            onShowCard={this.onShowCard}
            maptype={this.state.maptype}
          />
          <div className="Container2DRight">
            <img className="ToggleController" src={this.state.control_icon} alt="controller" onClick={this.switch_view}/>
            <NavControl2d
              key={this.props.siteId + "_control"}
              display={this.state.display_nodelist}
              siteId={this.props.siteId}
              maptype={this.state.maptype}
              setMapType={this.setMapType}
              setFromTo={this.setFromTo}
              from={this.state.from}
              to={this.state.to}
            />
            {map}
          </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    nodes: getNodesArray(state),
    eventManager: state.eventManager,
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  }),
)(TrackAssetsPage2D);
