import React from 'react';
import { connect } from 'react-redux';
import { getDocksForTable } from '../../modules/docks';
import { getNodesHash } from '../../modules/nodes';
import DocksTable from './DocksTable';

class DocksTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      origData: [],
      tableData: [],
      sortedColumnIndex: 0,
    }

    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
  }

  componentDidMount() {
    let dockArray = this.props.dock_data.map(d => {
      d['connected'] = this.props.node_data.hasOwnProperty(d.exterior) && this.props.node_data[d.exterior].hasOwnProperty('events') ? this.props.node_data[d.exterior].events.system.connection.connected : false;
      d['interior_name'] = this.props.node_data[d.interior] && this.props.node_data[d.interior].name;
      d['exterior_name'] = this.props.node_data[d.exterior] && this.props.node_data[d.exterior].name;
      return d;
    });

    this.setState({origData: dockArray, tableData: dockArray});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dock_data.length !== this.props.dock_data.length ||
        prevProps.node_data.length !== this.props.node_data.length
      ) {

      let dockArray = this.props.dock_data.map(d => {
        d['connected'] = this.props.node_data.hasOwnProperty(d.exterior) && this.props.node_data[d.exterior].hasOwnProperty('events') ? this.props.node_data[d.exterior].events.system.connection.connected : false;
        d['interior_name'] = this.props.node_data[d.interior] && this.props.node_data[d.interior].name;
        d['exterior_name'] = this.props.node_data[d.exterior] && this.props.node_data[d.exterior].name;
        return d;
      });

      this.setState({origData: dockArray, tableData: dockArray});
    }
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  render() {
    return(
      <DocksTable
        origData={this.state.origData}
        data={this.state.tableData}
        error={this.props.error}
        setTableData={this.setTableData}
        sortedColumnIndex={this.state.sortedColumnIndex}
        setSortedData={this.setSortedData}
      />

    )
  }
}

export default connect(
  state => (
    {
      dock_data: getDocksForTable(state),
      node_data: getNodesHash(state),
      error: state.docks.error,
     }
   )
 )(DocksTableContainer);
