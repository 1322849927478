import React from 'react';
import { connect } from 'react-redux';
import { updateConstellation } from '../../modules/constellations';
import './ConstellationStateButton.css';

const ConstellationStateButton = ({ state, toggleState }) => (
  <span className={`constellation-btn ${state}`} onClick={(e) => toggleState(state)}>
    {state}
  </span>
);

export default connect(
  null,
  (dispatch, ownProps) => {
    if (ownProps.toggleState && typeof ownProps.toggleState === 'function') {
      return ownProps;
    }
    return {
      toggleState: (currentState) => {
        dispatch(updateConstellation(ownProps.id, {
          constellation: {
            state: currentState === 'active' ? 'inactive' : 'active',
          }
        }));
      },
    };
  },
)(ConstellationStateButton);
