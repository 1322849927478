import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';

import { fetchDocks, getDocksArray } from '../../modules/docks';
import { getNodesArray, createNode, updateNode, deleteNode } from '../../modules/nodes';

import { getCurrentSiteId } from '../../modules/sites';

const mount_options = [
  { value: 'front', label: 'Front' },
  { value: 'overhead', label: 'Overhead' },
  { value: 'top_middle', label: 'Top-Middle' },
  { value: 'top_right', label: 'Top-Right' },
  { value: 'top_left', label: 'Top-Left' }
];

const mount_options_hash = {
  front: "Front",
  overhead: "OverHead",
  top_middle: 'Top-Middle',
  top_right: 'Top-Right',
  top_left: 'Top-Left',
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: base => ({
    ...base,
    maxHeight: "38px",
  }),
  valueContainer: base =>({
    ...base,
    maxHeight: "38px",
  }),
  indicatorsContainer: base => ({
    ...base,
    maxHeight: "38px",
  }),
  singleValue: base => ({
    ...base,
    //padding: "2px 8px",
  })
};

const EditDock = (props) => {
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [dock, setDock] = useState(props.docks.find(dock => dock.node === props.match.params.id));
  const [camera_options, setCamera_options] = useState([]);

  const [dock_name, setDock_name] = useState("");
  const [int_value, setInt_value] = useState({label: "", value: ""});
  const [int_mount_value, setInt_mount_value] = useState({label: "", value: ""});
  const [ext_value, setExt_value] = useState({label: "", value: ""});
  const [ext_mount_value, setExt_mount_value] = useState({label: "", value: ""});
  const [error, setError] = useState({status: "", dock: "", int: "", int_mount: "", ext: "", ext_mount: ""});
  const [disable_save, setDisable_save] = useState(false);

  useEffect(() => {
    setEditForm(props.match.params.id !== 'new');
    setDock(props.docks.find(dock => dock.node === props.match.params.id));
    if (dock) { setDock_name(dock.name) } //dock name field

    if (camera_options.length === 0) { //cameralist field
      setCamera_options(props.nodes.filter(n => n.node_type === 'camera').map(n => ({label: n.name, value: n.node})).sort((a,b) => a.label.localeCompare(b.label)));
    }

    if (dock && dock.configs && dock.configs.dock && dock.configs.dock.cameras) { //int/ext camera and int/ext mounts
      for (const c in dock.configs.dock.cameras) { //interior, exterior, combined
        if (dock.configs.dock.cameras[c].view === 'interior') {
          let camera = props.nodes.find(n => n.node === c);
          setInt_value({label: camera.name, value: camera.node});

          if (dock.configs.dock.cameras[c].hasOwnProperty('mount_location')) {
            setInt_mount_value({value: dock.configs.dock.cameras[c].mount_location, label: mount_options_hash[dock.configs.dock.cameras[c].mount_location]});
          }
        }

        if (dock.configs.dock.cameras[c].view === 'exterior') {
          let camera = props.nodes.find(n => n.node === c);
          setExt_value({label: camera.name, value: camera.node});

          if (dock.configs.dock.cameras[c].hasOwnProperty('mount_location')) {
            setExt_mount_value({value: dock.configs.dock.cameras[c].mount_location, label: mount_options_hash[dock.configs.dock.cameras[c].mount_location]});
          }
        }

        //combined
        if (dock.configs.dock.cameras[c].view === 'combined') {
          let camera = props.nodes.find(n => n.node === c);
          setInt_value({label: camera.name, value: camera.node})
          setExt_value({label: camera.name, value: camera.node});
          setInt_mount_value({value: dock.configs.dock.cameras[c].mount_location, label: mount_options_hash[dock.configs.dock.cameras[c].mount_location]});
          setExt_mount_value({value: dock.configs.dock.cameras[c].mount_location, label: mount_options_hash[dock.configs.dock.cameras[c].mount_location]});
        }
      }
    }
  }, [props.docks.length, props.nodes.length, dock])

  const updateField = (field, e) => {
    if (e === null) {
      e = {label: "", value: ""}
    }

    switch(field) {
      case 'dock':
        setDock_name(e.target.value);
        break;
      case 'int':
        setInt_value(e)
        break;
      case 'int_mount':
        setInt_mount_value(e);
        break;
      case 'ext':
        setExt_value(e);
        break;
      case 'ext_mount':
        setExt_mount_value(e);
        break
      default:
        //nothing
    }
  }

  const validateFields = () => {
    let status = true;
    let err = {...error};
    err = {status: "", dock: "", int: "", int_mount: "", ext: "", ext_mount: ""};

    if (dock_name.trim() === '') {
      err['dock'] = 'Name must be not empty';
      status = false;
    }

    let found = props.docks.filter(d => d.name === dock_name.trim());
    if (!editForm && found.length > 0 ) {
      err['dock'] = 'Name must be unique';
      status = false;
    }

    if (editForm && dock_name !== dock.name && found.length > 0 ) {
      err['dock'] = 'Name must be unique';
      status = false;
    }

    setError(err);
    return status;
  }

  const getNodeJSON = (isEdit) => {
    let node = {
      node: {
        name: dock_name,
      }
    };

    if (!isEdit) {
      node.node['hardware_id'] = "test";
      node.node['node_type'] = 'dock';
    }


    if (int_value.value && int_value.value === ext_value.value) { //combined
      let config = {
        dock: {
          cameras: {
              [int_value.value] : {
                view : "combined",
              }
            }
          }
        }

        if (int_mount_value.value) {
          config.dock.cameras[int_value.value]['mount_location'] = int_mount_value.value;
        }

        if (ext_mount_value.value) {
          config.dock.cameras[int_value.value]['mount_location'] = ext_mount_value.value;
        }

        node.node['configs'] = config;
    }

    if (int_value.value !== ext_value.value) { //not combined
      let config = {dock: {cameras:{}}};
      if (int_value.value) {
        config.dock.cameras[int_value.value] = {view : "interior"};
        if (int_mount_value.value) {
          config.dock.cameras[int_value.value]['mount_location'] = int_mount_value.value;
        }
      }

      if (ext_value.value) {
        config.dock.cameras[ext_value.value] = {view : "exterior"};
        if (ext_mount_value.value) {
          config.dock.cameras[ext_value.value]['mount_location'] = ext_mount_value.value;
        }
      }
      node.node['configs'] = config;
    }

    //blank out old cameralist
    if (dock && dock.configs && dock.configs.dock && dock.configs.dock.cameras) {
      let config = {dock: {cameras: {}}};
      let ids = [];

      if (node.node.configs) {
        config = {...node.node.configs}
        ids = Object.keys(node.node.configs.dock.cameras);
      }

      for (const c in dock.configs.dock.cameras) {
        if (!ids.includes(c)) {
          config.dock.cameras[c] = null;
        }
      }

      node.node['configs'] = config;
    }

    return node;
  }

  const handleSubmit = (e) => {
    if (!validateFields()) { return }

    let node = getNodeJSON(editForm);
    //console.log(JSON.stringify(node));

   if (editForm) { //edit
      props.dispatch(updateNode(dock.node, node));
      setError({status: dock_name + " updated", dock: "", int: "", int_mount: "", ext: "", ext_mount: ""});
    } else { //new
      props.dispatch(createNode(node))
      setError({status: dock_name + " added", dock: "", int: "", int_mount: "", ext: "", ext_mount: ""});
    }
    setDisable_save(true);
    setTimeout(()=>props.dispatch(fetchDocks()), 500);
    setTimeout(()=>props.history.push("/dashboard/settings/smartdock"), 700);
  }

  const handleDelete = (e) => {
    props.dispatch(deleteNode(dock.node));
    setDisable_save(true);
    setError({status: dock_name + " removed", dock: "", int: "", int_mount: "", ext: "", ext_mount: ""});
    setTimeout(()=>props.dispatch(fetchDocks()), 500);
    setTimeout(()=>props.history.push("/dashboard/settings/smartdock"), 700);
  }


  return (
    <div className="main edit">
      <Link to={"/dashboard/settings/smartdock"}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? "Edit Dock " : "Add New"}</h3>
      <div className="button-container delete">{editForm && <button onClick={e => handleDelete()}/>}</div>
      <div className="status">{error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        <div className="label_container">
          <div className="label">Dock Name</div>
          <div className="label">Interior Vision Sensor</div>
          <div className="label">Mount Location<div className="label2">Interior sensor mounted in relation to dock</div></div>
          <div className="label">Exterior Vision Sensor</div>
          <div className="label">Mount Location<div className="label2">Exterior sensor mounted in relation to dock</div></div>
        </div>
        <div className="form_container">
          <div className="fields long_input">
            <input type="text" value={dock_name} placeholder="" onChange={e => updateField("dock" , e)}/>
            <div className="error">{error.dock}</div>
          </div>
          <div className="fields">
            <Select options={camera_options} styles={customStyles} value={int_value} placeholder="" isClearable={true}  onChange={e => updateField("int" , e)}/>
            <div className="error">{error.int}</div>
          </div>
          <div className="fields">
            <Select options={mount_options} styles={customStyles} value={int_mount_value} placeholder="" onChange={e => updateField("int_mount" , e)} />
            <div className="error">{error.int_mount}</div>
          </div>
          <div className="fields">
            <Select options={camera_options} styles={customStyles} value={ext_value} placeholder="" isClearable={true} onChange={e => updateField("ext" , e)}/>
            <div className="error">{error.ext}</div>
          </div>
          <div className="fields">
            <Select options={mount_options} styles={customStyles} value={ext_mount_value} placeholder=""  onChange={e => updateField("ext_mount" , e)}/>
            <div className="error">{error.ext_mount}</div>
          </div>
        </div>
        <div className="info_container">{dock && "System Id"}<div className="sysid">{dock && dock.node}</div></div>
      </div>
      <div style={{display: disable_save ? "none" : "block"}}><Link to={"/dashboard/settings/smartdock"}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" disabled={disable_save} onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  )
}

export default connect(
  state => (
    {
      docks: getDocksArray(state), //docks only
      nodes: getNodesArray(state), //cameras and docks together
      authToken: state.authToken,
      currentSiteId: getCurrentSiteId(state)
   }),
)(EditDock);


//const docks = useSelector(state => getDocksArray(state))
