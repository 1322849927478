import React from 'react';
import DateRangeTime from '../../common/DateRangeTime';
import Upload from './Upload';
import Download from './Download';

import {IMG_CONFIG} from '../2dConfig';

const selected_text = {color: "#1b5faa"}
const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];
const weekNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"] //0-6

function makeDate(from, to) {
  let start = new Date(from)
  let end = new Date(to)
  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  let end_hour = end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
  let end_min = end.getMinutes() < 10 ? "0" +  end.getMinutes() : end.getMinutes()
  let end_date = (end.getMonth() === start.getMonth() && end.getDate() === start.getDate()) ? "" : monthNames[end.getMonth()] + " " + end.getDate() + ", "
  let str = monthNames[start.getMonth()] + " " + start.getDate() + ", " + start_hour + ":" + start_min + " - " +
            end_date + end_hour + ":" + end_min
  return (str)
}

//return dateText, and timeText
//config = {from: intervals[0].from, to: intervals[intervals.length - 1].to, allday: allday, week: weekday, hour: hour};
function makeDateRange(intervals, config) {
  if (config['allday']) { //figure out if it's continuous
    let max = intervals[intervals.length - 1].to - intervals[0].from;
    let total = 0;
    intervals.forEach(i => total += (i.to - i.from));
    total += (intervals.length - 1) * 1000;
    if (total === max) {
      return [makeDate(intervals[0].from, intervals[intervals.length - 1].to), ""]
    }
  }

  let dateText = "";
  let timeText = "";

  let start = new Date(intervals[0].from)
  let end = new Date(intervals[intervals.length - 1].to)
  if (start.getMonth() === end.getMonth()) {
    dateText = monthNames[start.getMonth()] + " " + start.getDate() + " - " + end.getDate();
  } else {
    dateText = monthNames[start.getMonth()] + " " + start.getDate() + " - " + monthNames[end.getMonth()] + " " + end.getDate();
  }

  config['week'].forEach((d, index) => { //do weekdays
    if (d) {
      timeText += weekNames[index] + " ";
    }
  })
  if (config['week'].indexOf(false) < 0) { timeText = "" } //if all week selected

  timeText += config['hour'][0] + ":" + config['hour'][1] + " - " + config['hour'][2] + ":" + config['hour'][3]
  return [dateText, timeText];
}

class WMSControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: "1", //"1",
      interval: 3600000, //1 hour is default
      dateText: makeDate(this.props.time_intervals[0].from, this.props.time_intervals[this.props.time_intervals.length - 1].to),
      weekText: "",
      showCalendar: false,
      showArrows: true,
    };

    this.toggle_hour = this.toggle_hour.bind(this);
    this.back = this.back.bind(this);
    this.forward = this.forward.bind(this);
    this.toggle_calendar = this.toggle_calendar.bind(this);
    this.setCalendarDate = this.setCalendarDate.bind(this);
  }

  reset() {
    let current = Date.now();
    this.props.setTimeIntervals([{from: current - 3600000, to: current}]);
    this.setState({hour: "1", interval: 3600000, weekText: "", dateText: makeDate(current - 3600000, current), showArrows: true});
  }

  componentDidMount() {
    //this.reset(); remove as it makes an additional query
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
      this.reset();
    }
  }

  toggle_calendar(e) {
    this.setState((prevState, props) =>({showCalendar: !prevState.showCalendar}))
  }

  //24 hours - 86400000, 12 hours - 13200000, 6 hours - 21600000, 10 min - 600000, 5 min - 300000, 1 min - 60000
  toggle_hour(e) {
    let to = Date.now();
    let interval = 3600000;

    switch(e.target.dataset.hour) {
      case '24':
        interval = 86400000;
      break;
      case '12':
        interval = 43200000;
      break;
      default:
        interval = 3600000;
      }

      let from = to - interval;
      this.setState({hour: e.target.dataset.hour, interval: interval, weekText: "", dateText: makeDate(from, to), showArrows: true});
      this.props.setTimeIntervals([{from: from, to: to}]);
  }

  setCalendarDate(start, end, time_array, config) {
    //console.log("setCalendarDate - " + start + " " + end);
    let interval = end - start;
    if (time_array.length === 1) { //one time
      this.setState({hour: "0", dateText: makeDate(start, end), weekText: "", interval: interval, showCalendar: false, showArrows: true});
    } else {
      let date = makeDateRange(time_array, config);
      this.setState({hour: "0", dateText: date[0], weekText: date[1], interval: interval, showCalendar: false, showArrows: false});
    }

    //this.props.setTimeIntervals(time_array ? time_array : [{from: start, to: end}]);
    this.props.setTimeIntervals(time_array);
  }

  back(e) {
    let start = this.props.time_intervals[0].from - this.state.interval;
    let end = this.props.time_intervals[this.props.time_intervals.length - 1].to - this.state.interval;
    this.props.setTimeIntervals([{from: start, to: end}]);
    this.setState({weekText: "", dateText: makeDate(start, end)});
  }

  forward(e) {
    let start = this.props.time_intervals[0].from + this.state.interval;
    let end = this.props.time_intervals[this.props.time_intervals.length - 1].to + this.state.interval;
    if (Date.now() < end) {return}

    this.props.setTimeIntervals([{from: start, to: end}]);
    this.setState({weekText: "", dateText: makeDate(start, end)});
  }

  render() {
    return (
      <div className="ControlContainer">
        <div style={this.props.view === 'map' ? {display: "inline-block"} : {display: "none"}}>
          <div className="IconSelectorContainer">
            <img className="MapTypeIcon" alt="heatmap" src={this.props.maptype==='heatmap' ? IMG_CONFIG['heatmap']['active']['img'] : IMG_CONFIG['heatmap']['inactive']['img']} data-maptype="heatmap" onClick={this.props.setMapType} onDoubleClick={this.props.setMapType}/>
            <img className="MapTypeIcon" alt="path" src={this.props.maptype==='path' ? IMG_CONFIG['path']['active']['img'] : IMG_CONFIG['path']['inactive']['img']} data-maptype="path" onClick={this.props.setMapType}/>
          </div>
        </div>
        <div style={this.props.view === 'chart' ? {display: "inline-block"} : {display: "none"}}>
          <div className="IconSelectorContainer">
            {this.props.displayUpload && <Upload/>}
            {this.props.displayDownload && <Download from={this.props.time_intervals[0].from} to={this.props.time_intervals[this.props.time_intervals.length - 1].to} nodes={this.props.nodes} />}
          </div>
        </div>
         <div className="DateSelectorContainer">
          {this.state.showArrows && <i className="fa fa-caret-left BackStyle" onClick={this.back}/>}
          <div className="DateText" onClick={this.toggle_calendar}>
            {this.state.dateText}
            <div className="WeekText">{this.state.weekText}</div>
          </div>
          {
            this.state.showArrows && Date.now() > (this.props.time_intervals[this.props.time_intervals.length - 1].to + this.state.interval) &&
              <i className="fa fa-caret-right" style={{display: "inline-block", marginLeft: "10px", color: "#0068b0"}} onClick={this.forward}/>
          }
          <div className="HourText" data-hour="24" style={this.state.hour === '24' ? selected_text : {}} onClick={this.toggle_hour}>24 HOURS</div>
          <div className="HourText" data-hour="12" style={this.state.hour === '12' ? selected_text : {}} onClick={this.toggle_hour}>12 HOURS</div>
          <div className="HourText" data-hour="1" style={this.state.hour === '1' ? selected_text : {}} onClick={this.toggle_hour}>1 HOUR</div>
          <div>
            <DateRangeTime show={this.state.showCalendar} timeArray={this.props.time_intervals} setShow={this.toggle_calendar} onSetCalendarDate={this.setCalendarDate}/>
          </div>
         </div>
      </div>
    )
  }
}

export default WMSControl;

/*
img className="MapTypeIcon" alt="download" src={IMG_CONFIG['download']['active']['img']}/>
<img className="MapTypeIcon" alt="download" src={IMG_CONFIG['download']['active']['img']}/>

<div style={{marginLeft: "100px"}}>
  <Calendar from={this.props.time_intervals[0].from} to={this.props.to} show={this.state.showCalendar} setShow={this.toggle_calendar} onSetCalendarDate={this.setCalendarDate}/>
  <DateRangeTime show={this.state.showCalendar} from={this.props.time_intervals[0].from} to={this.props.time_intervals[this.props.time_intervals.length - 1].to} setShow={this.toggle_calendar} onFromTo={this.setCalendarDate}/>
</div>

import Calendar from '../../common/Calendar';
{this.props.view === 'chart' ?
  <DateRangeTime show={this.state.showCalendar} timeArray={this.props.time_intervals} setShow={this.toggle_calendar} onSetCalendarDate={this.setCalendarDate}/>
  :
  <Calendar from={this.props.time_intervals[0].from} to={this.props.time_intervals[this.props.time_intervals.length - 1].to} show={this.state.showCalendar} setShow={this.toggle_calendar} onSetCalendarDate={this.setCalendarDate}/>
}
*/
