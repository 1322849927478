import React from 'react';

import RecentCameraEventList from './RecentCameraEventList';

const CamerasPage = (props) => (
  <div className="content-row">
    <div className="dashboard-content cameras-page" id="dashboard-content">
      <RecentCameraEventList heading="Cameras"/>
    </div>
  </div>
);

export default CamerasPage;
