import React, { useState, useEffect, useRef, /* useCallback*/} from 'react';
import { Stage, Layer, Circle, Line, Text, Image} from 'react-konva';

const convertMeterToLoc = (val, origin, pixels_per_meter, height, xyz) => {
  if (xyz === 'x') {
    let newX = (parseFloat(val) + parseFloat(origin.x)) * pixels_per_meter;
    return newX;
  }

  if (xyz === 'y') {
    let newY = height - ((parseFloat(val) + parseFloat(origin.y)) * pixels_per_meter)
    return newY;
  }

  if (xyz === 'z') {
    let newZ = (parseFloat(val) + parseFloat(origin.z)) * pixels_per_meter;
    return newZ;
  }
}

const convertLocToMeter = (val, origin, pixels_per_meter, height, xyz) => {
  if (xyz === 'x') {
    let newX = (val / pixels_per_meter) - origin.x;
    return newX.toFixed(2);
  }

  if (xyz === 'y') {
    let newY = ((height - val) / pixels_per_meter) - origin.y;
    return newY.toFixed(2);
  }

  if (xyz === 'z') {
    let newZ = (val / pixels_per_meter) - origin.z;
    return newZ.toFixed(2);
  }
}

const LpsMapConfig = (props) => {
  const [map, setMap] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [anchors, setAnchors] = useState([]);
  const [anchor_label, setAnchor_label] = useState(null);
  const anchorRefs = useRef({});

  const [zone, setZone] = useState(null); //polygons
  const [zones, setZones] = useState([]); //all zones for display
  const [zone_points, setZone_points] = useState([]); //points for polygons
  const [mid_points, setMid_points] = useState([]);
  const [zone_point_label, setZone_point_label] = useState([]); //label for points
  const zoneRef = useRef(null); //ref for zone for point dragging

  useEffect(()=>{
    let bg = new window.Image();
    bg.src = require("../2dReact/img/" + props.config.map_config.maps[props.config.floor].background_src);
    bg.addEventListener('load', () => setMap(bg));
    updateOrigin();

    return () => {
      if (map) { map.removeEventListener('load', ()=>setMap(null))}
    };
  }, [props.config.map_config.maps[props.config.floor].background_src]);

  useEffect(() => {
    updateAnchor(props.config.anchors);
  }, [props.config.selected_anchor, props.config.anchors, props.config.floor]);

  useEffect(() => { //for zone
    if (props.config.selected_zone === null) {
      setZone(null);
      setZone_points([]);
      setMid_points([]);
      setZone_point_label(null);
    } else {
      //console.log("Updated zone");
      updateZone(props.config.selected_zone);
    }
  }, [props.config.selected_zone]);

  useEffect(()=> {
    updateZones(props.config.zones);
  }, [props.config.zones, props.config.floor]);

 const updateOrigin = () => {
   let newX = convertMeterToLoc(props.config.map_config.maps[props.config.floor].origin.x, {x:0, y:0}, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
   let newY = convertMeterToLoc(props.config.map_config.maps[props.config.floor].origin.y, {x:0, y:0}, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');
   let circle = <Circle
                  key={"Origin"}
                  fill={'#990000'}
                  radius={5}
                  x={newX}
                  y={newY}
                  onMouseOver={(e) => {
                    let text1 = <Text key="text1" x={newX + 10} y={newY - 20} text={'Origin'} fontSize={14} fontFamily={'Lato'} fill={'#990000'}/>
                    let text2 =
                      <Text
                        key="text2"
                        x={newX + 10}
                        y={newY}
                        text={props.config.map_config.maps[props.config.floor].origin.x + ", " + props.config.map_config.maps[props.config.floor].origin.y} fontSize={14} fontFamily={'Lato'} fill={'#990000'}
                      />
                    setAnchor_label([text1, text2]);
                    }
                  }
                  onMouseOut={(e) => {setAnchor_label(null)}}
                />
   setOrigin(circle);
 }

 const updateAnchor = (anchors) => {
   let label = null;
   let a_arr = Object.values(anchors).filter(anchor => anchor.floor === props.config.floor).map( anchor => {
     let newX = convertMeterToLoc(anchor.x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
     let newY = convertMeterToLoc(anchor.y, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');

     if (anchor.node === props.config.selected_anchor) {
       label = {node: anchor.node, name: anchor.name, orig_x: anchor.x, x: newX, orig_y:anchor.y, y: newY, orig_z: anchor.z}
     }

     let circle = <Circle
                     key={anchor.node}
                     node={anchor.node}
                     name={anchor.name}
                     ref={ref => { anchorRefs.current[anchor.node] = ref }}
                     fill={anchor.node === props.config.selected_anchor ? 'red' : "#00bd93" }
                     radius={4}
                     x={newX}
                     y={newY}
                     z={anchor.z}
                     draggable={true}
                     onMouseOver={(e)=>highlightAnchor(e)}
                     onMouseOut={(e)=>unhighlightAnchor(e)}
                     onDragMove={(e)=>dragAnchor(e)}
                     onDragEnd={(e)=>dragEndAnchor(e)}
                     />
     return circle;
   })

   setAnchors(a_arr);

   if (label !== null) {
     let text1 = <Text key="text1" x={label.x + 10} y={label.y - 20} text={label.name} fontSize={14} fontFamily={'Lato'} fill={'#505050'}/>
     let text2 = <Text key="text2" x={label.x + 10} y={label.y} text={label.orig_x + ", " + label.orig_y+ ", " + label.orig_z} fontSize={14} fontFamily={'Lato'} fill={'#505050'}/>
     setAnchor_label([text1, text2]);
   }
 }

  const displayLabel = (e) => {
    let newX = convertLocToMeter(e.target.attrs.x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
    let newY = convertLocToMeter(e.target.attrs.y, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');
    let text1 = <Text key="text1" x={e.target.attrs.x + 10} y={e.target.attrs.y - 20} text={e.target.attrs.name} fontSize={14} fontFamily={'Lato'} fill={'#505050'}/>
    let text2 = <Text key="text2" x={e.target.attrs.x + 10} y={e.target.attrs.y} text={newX + ", " + newY + ", " + e.target.attrs.z} fontSize={14} fontFamily={'Lato'} fill={'#505050'}/>
    setAnchor_label([text1, text2]);
  }

  const highlightAnchor = (e) => {
    if (e.target.attrs.fill !== 'red') {
      //e.target.attrs.fill = "#0392ff";
      anchorRefs.current[e.target.attrs.node].attrs.fill = "#0392ff";
    }

    displayLabel(e);
  }

  const unhighlightAnchor = (e) => {
    if (e.target.attrs.fill !== 'red') {
      anchorRefs.current[e.target.attrs.node].attrs.fill = "#00bd93";
    }
    setAnchor_label(null);
  }

  const dragAnchor = (e) => {
    anchorRefs.current[e.target.attrs.node].attrs.fill = "red";
    anchorRefs.current[e.target.attrs.node].attrs.radius = 5;
    displayLabel(e);
  }

  const dragEndAnchor = (e) => {
    let newX = convertLocToMeter(e.target.attrs.x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
    let newY = convertLocToMeter(e.target.attrs.y, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');
    anchorRefs.current[e.target.attrs.node].attrs.radius = 4;
    props.selectAnchor({node: e.target.attrs.node, name: e.target.attrs.name, x: newX, y:newY, z: e.target.attrs.z});
  }

  /**************************************/
  //ZONES - paint the polygon and points//
  /**************************************/
  const updateZones = (zones) => {
    let zone_arr = [];

    for (const z in zones) {
      let zone = zones[z];
      let points = [];

      if (props.config.floor !== zone.floor) { continue }

      zone.points.forEach ((p, index) => {
        let newX = convertMeterToLoc(p.x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
        let newY = convertMeterToLoc(p.y, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');
        points.push(newX);
        points.push(newY);
      })

      zone_arr.push(<Line
                        key={"zones_" + zone.id}
                        id={zone.id}
                        name={zone.name}
                        color={zone.color}
                        orig_points={zone.points}
                        points={points}
                        stroke={zone.color}
                        fill={zone.color}
                        opacity={.3}
                        strokeWidth={2}
                        closed={true}
                        onClick={(e)=>{props.selectZone({id: e.target.attrs.id, name: e.target.attrs.name, color: e.target.attrs.color, points: e.target.attrs.orig_points})}}
                      />)
      zone_arr.push(<Text
                        key={"zones_test" + zone.id}
                        x={points[0] + 5}
                        y={points[1] - 20}
                        text={zone.name}
                        fontSize={14}
                        fontFamily={'Lato'}
                        fill={zone.color}
                      />)
    }
    setZones(zone_arr);
  }

  const updateZone = (zone) => {
    setZone(null);
    setZone_points([]);
    setZone_point_label(null);

    let points = [];
    let circles = [];
    zone.points.forEach ((p, index) => {
      let newX = convertMeterToLoc(p.x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
      let newY = convertMeterToLoc(p.y, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');

      points.push(newX);
      points.push(newY);
      circles.push(<Circle
                      key={(circles.length + 1).toString()}
                      name={(circles.length + 1).toString()}
                      fill={zone.color}
                      radius={4}
                      x={newX}
                      y={newY}
                      draggable={true}
                      onMouseOver={(e)=>{displayPointLabel(e)}}
                      onDragMove={(e)=>{dragPoint(e)}}
                      onDragend={(e)=>{endDragPoint(e)}}
                      />)
    })
    setZone_points(circles);

    let midpoints = [];
    for (let index = 0; index < circles.length; index += 1) {
      let next = (index === circles.length - 1) ? 0: index + 1;

      let newX = (circles[index].props.x + circles[next].props.x)/2;
      let newY = (circles[index].props.y + circles[next].props.y)/2;

      //am I horizontal or vertical?
      if (Math.abs(circles[next].props.x - circles[index].props.x) > Math.abs(circles[next].props.y - circles[index].props.y)) {
        midpoints.push(<Circle
                          key={index + "_" + next}
                          name={index + "_" + next}
                          move_type={"y"}
                          stroke={circles[index].props.fill}
                          fill={"white"} radius={4}
                          x={newX}
                          y={newY}
                          draggable={true}
                          dragBoundFunc={(pos)=>({x:newX, y: pos.y})}
                          onDragMove={(e)=> onDragMidPoint(e)}
                          onDragend={(e)=>{endDragPoint(e)}}
                        />);
      } else {
        midpoints.push(<Circle
                          key={index + "_" + next}
                          name={index + "_" + next}
                          move_type={"x"}
                          stroke={circles[index].props.fill}
                          fill={"white"} radius={4}
                          x={newX}
                          y={newY}
                          draggable={true}
                          dragBoundFunc={(pos)=>({x:pos.x, y: newY})}
                          onDragMove={(e)=> onDragMidPoint(e)}
                          onDragend={(e)=>{endDragPoint(e)}}
                        />);
      }
    }
    setMid_points(midpoints);

    setZone(<Line
              key={zone.id}
              ref={zoneRef}
              id={zone.id}
              name={zone.name}
              points={points}
              stroke={zone.color}
              fill={zone.color}
              opacity={.65}
              strokeWidth={2}
              closed={true}
              dash={[3, 3]}
              draggable={true}
              onDragMove={()=>{setZone_points([]); setZone_point_label(null)}}
              onDragend={(e)=>{endDragZone(e)}}
              />);
  };

  const displayPointLabel = ((e)=>{
    let newX = convertLocToMeter(e.target.attrs.x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
    let newY = convertLocToMeter(e.target.attrs.y, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');
    let text1 = <Text key="text1" x={e.target.attrs.x + 10} y={e.target.attrs.y - 20} text={"Point: " + e.target.attrs.name} fontSize={14} fontFamily={'Lato'} fill={'#505050'}/>
    let text2 = <Text key="text2" x={e.target.attrs.x + 10} y={e.target.attrs.y} text={newX + ", " + newY} fontSize={14} fontFamily={'Lato'} fill={'#505050'}/>
    setZone_point_label([text1, text2]);
  })

  const endDragZone = (e) => {
    setZone(null);
    let x = null;
    let points = [];
    e.target.attrs.points.forEach((p, index) => {
      if (index % 2 === 0) {
        x = p + e.target.attrs.x;
      } else {
        let y = p + e.target.attrs.y;
        let newX = convertLocToMeter(x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
        let newY = convertLocToMeter(y, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');
        points.push({x: parseFloat(newX), y: parseFloat(newY)});
      }
    })

    props.selectZone({id: e.target.attrs.id, name: e.target.attrs.name, color: e.target.attrs.fill, points: points});
  }

  const dragPoint = (e) => {
    setZone_point_label(null);
    setMid_points([]);
    let points = [...zoneRef.current.attrs.points];
    points[parseInt(e.target.attrs.name * 2, 10) - 2] = e.target.attrs.x;
    points[parseInt(e.target.attrs.name * 2, 10) - 1] = e.target.attrs.y;
    zoneRef.current.attrs.points = points;
  }

  const endDragPoint = (e) => {
    let x = null;
    let points = [];
    zoneRef.current.attrs.points.forEach((p, index) => {
      if (index % 2 === 0) {
        x = p;
      } else {
        let newX = convertLocToMeter(x, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'x');
        let newY = convertLocToMeter(p, props.config.map_config.maps[props.config.floor].origin, props.config.map_config.maps[props.config.floor].small.pixels_per_meter, props.config.map_config.maps[props.config.floor].small.height, 'y');
        points.push({x: parseFloat(newX), y: parseFloat(newY)});
      }
    })

    props.selectZone({id: zoneRef.current.attrs.id, name: zoneRef.current.attrs.name, color: zoneRef.current.attrs.fill, points: points});
  }

  const onDragMidPoint = (e) => {
    setZone_point_label(null);
    setZone_points([]);
    let points = [...zoneRef.current.attrs.points];

    let p = e.target.attrs.name.split("_");
    p[0] = parseInt(p[0], 10) + 1;
    p[1] = parseInt(p[1], 10) + 1;

    if (e.target.attrs.move_type === 'x') {
      points[parseInt(p[0] * 2, 10) - 2] = e.target.attrs.x;
      points[parseInt(p[1] * 2, 10) - 2] = e.target.attrs.x;
    } else {
      points[parseInt(p[0] * 2, 10) - 1] = e.target.attrs.y;
      points[parseInt(p[1] * 2, 10) - 1] = e.target.attrs.y;
    }

    zoneRef.current.attrs.points = points;
  }

  return(
    <div className="lps_selection" style={{overflow: "auto"}}>
    <Stage width={props.config.map_config.maps[props.config.floor].small.width + 100} height={props.config.map_config.maps[props.config.floor].small.height + 100} draggable={true}>
      <Layer>
        <Image
          x={0}
          y={0}
          width = {props.config.map_config.maps[props.config.floor].small.width}
          height= {props.config.map_config.maps[props.config.floor].small.height}
          image={map}
         />
      </Layer>
      <Layer>
        {origin}
        {props.setting === 'anchors' && anchors}
        {props.setting === 'anchors' && anchor_label}
        {props.setting === 'zones' && zones}
        {props.setting === 'zones' && zone}
        {props.setting === 'zones' && mid_points}
      </Layer>
    </Stage>
    </div>
  )
}

export default LpsMapConfig;

/*
{props.setting === 'zones' && zone_points}
{props.setting === 'zones' && zone_point_label}

  setZone(<Line //not sure if I need to render as I only want the points
          key={e.target.attrs.name}
          name={e.target.attrs.name}
          points={e.target.attrs.points}
          stroke={e.target.attrs.stroke}
          fill={e.target.attrs.fill}
          opacity={.6}
          strokeWidth={2}
          closed={true}
          draggable={true}
          dash={[3, 3]}
          onDragMove={()=>{setZone_points([]); setZone_point_label(null)}}
          onDragend={(e)=>{endDragZone(e)}}
          />);

    const endDragZone =(e)=>{
      setZone(null)
      let circles = [];
      let points = [];
      let x = null;
      e.target.attrs.points.forEach((p, index) => {
        if (index % 2 === 0) { //x
          x = p + e.target.attrs.x;
          points.push(x)
        } else { //x
          let y = p + e.target.attrs.y;
          points.push(y)
          circles.push(
            <Circle
              key={(circles.length + 1).toString()}
              name={(circles.length + 1).toString()}
              fill={e.target.attrs.stroke}
              radius={4}
              x={x}
              y={y}
              draggable={true}
              onMouseOver={(e)=>{displayPointLabel(e)}}
              onDragMove={(e)=>{dragPoint(e)}}
              onDragend={(e)=>{endDragPoint(e)}}
            />
          )

          //let newX = convertLocToMeter(x, props.config.map_config.origin, props.config.map_config.small.pixels_per_meter, props.config.map_config.small.height, 'x');
          //let newY = convertLocToMeter(y, props.config.map_config.origin, props.config.map_config.small.pixels_per_meter, props.config.map_config.small.height, 'y');
          //points.push({x: newX, y: newY})
        }
      })

      setZone_points(circles);
      setZone(<Line
                key={e.target.attrs.name}
                ref={zoneRef}
                name={e.target.attrs.name}
                points={points}
                stroke={e.target.attrs.stroke}
                fill={e.target.attrs.fill}
                opacity={.6}
                strokeWidth={2}
                closed={true}
                draggable={true}
                dash={[3, 3]}
                onDragMove={()=>{setZone_points([]); setZone_point_label(null)}}
                onDragend={(e)=>{endDragZone(e)}}
                />);
      //update parent here
      //props.selectZone({id: e.target.attrs.id, name: e.target.attrs.name, color: e.target.attrs.fill, points: points});
    }
*/
