import React from 'react';
//import {Timestamp} from '../Timestamp'
import './AnalyticsPage.css';
import {Line} from 'react-chartjs-2';

/*
https://ledsun.github.io/react-chartjs-example/bar/
borderColor: 'rgba(75,192,192,1)',
*/

class AnalyticsChart extends React.Component {
  render () {
    const data = {
      labels: this.props.labels.reverse(),
      datasets: [
        {
          label: this.props.bay,
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#6fbb1c',
          borderColor:  '#6fbb1c',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: '#6fbb1c',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: '#6fbb1c',
          pointHoverBorderColor: '#6fbb1c',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: this.props.data.reverse(),
          steppedLine: true,
          responsive: true,
          maintainAspectRatio: false,
        }
      ]
    };

    const options = {
      scales: {
          yAxes: [{
              ticks: {
                  display: false
              }
          }],
          xAxes: [{
                type: 'time',
                time: {
                  displayFormats: {
                    hour: 'h:mm a',
                  }
                }
              }],
      }
    };

   return (
     <div>
        <h2>{this.props.bay} ACTIVITY</h2>
        <div className="chart-item"><Line data={data} options={options}/></div>
      </div>
    );
  }//end render
}

export default AnalyticsChart;
