import React from 'react';
import './Lapis.css';

import { fetchLapisData } from '../../modules/api';
import LapisScTable from './LapisScTable';
import LapisScChart from './LapisScChart';

class LapisScPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table_data: [],
      chart_data: {},
      last_update: "",
      selected_logger: null,
    };

    this.selectLogger = this.selectLogger.bind(this);
  }

  componentDidMount() {
    this.fetchLoggerData();
    setInterval(()=>this.fetchLoggerData(),15000); //15sec
  }

  componentDidUpdate(prevProps) {
    //console.log("HERE")
  }


  /*
  {
    "site_id":"b4d8242e-a850-45df-8b2b-84a55e00497b",
    "logger_id":"91ba8126-8ca6-4f5d-b026-969db21cd668",
    "name":"Logger 1",
    "serial_number":"12345679",
    "activated":1658556424705,
    "captured":1658577266997,
    "temperature_log":[],
    "temperature_alarms":[],
    "temp_thresh_hi":9,
    "temp_thresh_lo":-10,
    "avg_temp":7.5,
    "min_temp":4.7,
    "max_temp":9.1,
    "_id":"21ddc21a-9e58-4c00-8dec-c543da6c29f4"
    },
    "temperature_log":[
    [
    1658574617485,
    6.9
    ],
    [
    1658574618545,
    7.2
    ],
*/
  fetchLoggerData() {
    fetchLapisData("logger_data")
    .then(json => {
      //console.log("DATA - " + JSON.stringify(json))
      let data = [];
      let chartdata = {}; //{name: []}
      json.forEach(logger => {
        data.push({
          sensor: logger.name,
          temp: logger.temperature_log[logger.temperature_log.length - 1][1],
          time: (new Date(logger.temperature_log[logger.temperature_log.length - 1][0]).toLocaleString()),
          avg: logger.avg_temp,
          min: logger.min_temp,
          max: logger.max_temp,
          high: logger.temp_thresh_hi,
          low: logger.temp_thresh_lo,
          exceptions: logger.temperature_alarms.length
        })
        chartdata[logger.name] = logger.temperature_log;
      })

      this.setState({last_update: (new Date()).toLocaleString(), table_data: data, chart_data: chartdata});
      if (this.state.selected_logger === null) { this.setState({selected_logger: data[0].sensor})}
    })
  }

  selectLogger(id) {
    console.log(id)
    this.setState({selected_logger: id})
  }

  render() {
    return (
      <div className="lapis-container">
        <div>
          <div className="lapis-header">Cold Storage Monitoring</div>
          <div className="lapis-update">Updated {this.state.last_update}</div>
        </div>
        <div className="grid-container">
          <div className="grid-item card1">
            <LapisScTable
              key="LapisScTable"
              rows={this.state.table_data}
              selected={this.state.selected_logger}
              onSelectLogger={this.selectLogger}
            />
          </div>
          <div className="grid-item card2">
            <LapisScChart
              key="LapisScChart"
              logger={this.state.selected_logger}
              temp_data={this.state.selected_logger ? this.state.chart_data[this.state.selected_logger] : []}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default LapisScPage;
