import React from 'react';
import { Table, Column, Cell} from 'fixed-data-table-2';

const ALERT = { color: "#c50219"};
const SELECT = {backgroundColor: "#CBF6FF"};

const LapisScTable = ({rows, selected, onSelectLogger}) => (
    <div>
      <Table className="lapis-table"
        rowHeight={38}
        headerHeight={38}
        rowsCount={rows.length}
        width={1090}
        height={270}
      >
        <Column columnKey='sensor'
                header={<Cell className="lapis-tableheader">Sensor</Cell>}
                cell={ props => (
                  <Cell
                      className="lapis-tablecell"
                      style={rows[props.rowIndex].sensor === selected  && SELECT}
                      {...props}
                      onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)}
                  >
                      {rows[props.rowIndex].sensor === selected && <div className="fa fa-search lapis-search"/>}
                      {
                        rows[props.rowIndex].exceptions > 0 ?
                          <span style={ALERT}>{rows[props.rowIndex].sensor}<div className="fa fa-warning AlarmIcon"/></span>
                          :
                          <span>{rows[props.rowIndex].sensor}</span>
                      }
                  </Cell>
                )}
                width={118}
        />
        <Column columnKey='temp'
                header={<Cell className="lapis-tableheader">Temp <span>&#176;</span>C</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].temp}
                  </Cell>
                )}
                width={108}
        />
        <Column columnKey='time'
                header={<Cell className="lapis-tableheader">Time</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].time}
                  </Cell>
                )}
                width={168}
        />
        <Column columnKey='avg'
                header={<Cell className="lapis-tableheader" >Avg</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].avg}
                  </Cell>
                )}
                width={108}
        />
        <Column columnKey='min'
                header={<Cell className="lapis-tableheader">Min</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].min}
                  </Cell>
                )}
                width={108}
        />
        <Column columnKey='max'
                header={<Cell className="lapis-tableheader">Max</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].max}
                  </Cell>
                )}
                width={108}
        />
        <Column columnKey='high'
                header={<Cell className="lapis-tableheader">Threshold High</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].high}
                  </Cell>
                )}
                width={118}
        />
        <Column columnKey='low'
                header={<Cell className="lapis-tableheader">Threshold Low</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].low}
                  </Cell>
                )}
                width={118}
        />
        <Column columnKey='exceptions'
                header={<Cell className="lapis-tableheader">Exceptions</Cell>}
                cell={ props => (
                  <Cell className="lapis-tablecell" {...props} onClick={()=>onSelectLogger(rows[props.rowIndex].sensor)} style={rows[props.rowIndex].sensor === selected  && SELECT}>
                    {rows[props.rowIndex].exceptions > 0 ? rows[props.rowIndex].exceptions : "-"}
                  </Cell>
                )}
                width={108}
        />
      </Table>
    </div>
  )

export default LapisScTable;
