import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchMediaBlob } from '../../modules/api';
import NodeOverlay from '../devices/NodeOverlay';
import './HistoryOverlay.css';

const CameraEvent = ({
  node,
  mediaFormat,
  srcUrl,
  mimeType,
  alert,
  alert_text,
  battery,
  status
}) => {
  switch (mediaFormat) {
    case 'image':
      return (
        <Link to={`/dashboard/cameras/${node.node}`} className="event-frame">
          <img className="event-frame-img" alt={srcUrl} src={srcUrl} />
          <NodeOverlay node={node} />
          <div className="alert-container">
            {alert}
            {alert_text}
          </div>
          {battery}
          {status}
        </Link>
      );
    case 'video':
      return (
        <Link to={`/dashboard/cameras/${node.node}`} className="event-frame">
          <video autoPlay loop muted width="100%" height="100%" src={srcUrl} type={mimeType} />
          <NodeOverlay node={node} />
          <div className="alert-container">
            {alert}
            {alert_text}
          </div>
          {battery}
          {status}
        </Link>
      );
    default:
      return (
        <div className="event-frame">
        <img className="event-frame-img" alt={srcUrl} src={srcUrl} />
        <NodeOverlay node={node} />
        </div>
      );
  }
}

class CameraEventContainer extends React.Component {
  constructor(props) {
    super(props);
    let media = {};
    try {
      media = this.props.node.events.camera.media_ready;
    } catch (err) {
      console.warn('No media found', err);
    }

    this.state = {
      mediaFormat: media ? media.format : '',
      key: media ? media.key : '',
      srcUrl: require('../../media/Loading_Img.svg'),
      node: this.props.node,
    }
  }

  componentDidMount() {
    let media = {}
    try {
      media = this.props.node.events.camera.media_ready;
    } catch (err) {
      console.warn('No media found', err);
    }

    if (media) {
      //setTimeout(() => {
        fetchMediaBlob(media.key, this.props.authToken)
          .then(srcUrl => this.setState({ srcUrl }))
      //}, 1000)
    }
  }

  componentWillReceiveProps(nextProps) {
    let media = {};
    let oldMedia = {};

    try {
      media = nextProps.node.events.camera.media_ready;
      oldMedia = this.props.node.events.camera.media_ready;
    } catch (err) {
      console.warn('No media found', err);
    }

    if ((oldMedia && media && oldMedia.key !== media.key) || (!oldMedia && media)) {
      console.log("new media detected");
      this.setState({
        mediaFormat: "image",
        key: media.key,
        srcUrl: require('../../media/Loading_Img.svg'),
        node: nextProps.node,
      })

      //setTimeout(() => {
      fetchMediaBlob(media.key, this.props.authToken)
        .then(srcUrl => {
          //console.log(srcUrl);
          this.setState({ srcUrl, mediaFormat: media.format, key: media.key })
        })
      //}, 1000)
    }
  }

  render() {
    let alert = <div></div>
    let alert_text = <div></div>
    if (this.props.node.update && this.props.node.update.events.camera && this.props.node.update.events.camera.media_ready.type === 'alarm') {
      alert = <div className="alarm-overlay"><i className="fa fa-warning"></i></div>
      alert_text = <div className="alarm-text-overlay" style={{color: "red"}}>Alarm</div>
    } else if (this.props.node.update && this.props.node.update.events.camera  && this.props.node.update.events.camera.media_ready.type === 'action') {
      alert = <div className="action-overlay"><i className="fa fa-adn"></i></div>
      alert_text = <div className="alarm-text-overlay" style={{color: "green"}}>User Capture</div>
    }

    let battery = <div></div>
    if (this.props.node.update && this.props.node.update.events && this.props.node.update.events.battery) {
        if (this.props.node.update.events.battery.voltage < 2.95) {
          battery = <div className="battery-overlay"><img alt="battery" src={require("../2dReact/img/battery_dead.png")}/></div>
        } else if (this.props.node.update.events.battery.voltage < 3.05) {
          battery = <div className="battery-overlay"><img alt="battery" src={require("../2dReact/img/battery_low.png")}/></div>
        } else if (this.props.node.update.events.battery.voltage < 3.25) {
          battery = <div className="battery-overlay"><img alt="battery" src={require("../2dReact/img/battery_half.png")}/></div>
        }
    }

    let status = <div></div>
    if (this.props.node.update && this.props.node.update.events && this.props.node.update.events.system && this.props.node.update.events.system.connection && this.props.node.update.events.system.connection.connected) {
    } else {
      status = <div className="status-overlay">Disconnected</div>
    }

    return <CameraEvent
      node={this.state.node}
      srcUrl={this.state.srcUrl}
      mediaFormat={this.state.mediaFormat}
      alert={alert}
      alert_text={alert_text}
      battery={battery}
      status={status}
    />;
  }
}

window.fetchMediaBlob = fetchMediaBlob

export default connect(
  state => ({ authToken: state.authToken })
)(CameraEventContainer);
