import React from 'react';
import Modal from 'react-modal';

import {IMG_CONFIG} from '../2dConfig';
import { connect } from 'react-redux';
import { postWmsFile } from '../../../modules/api';
import { getCurrentSiteId } from '../../../modules/sites';

//b5fcb9a07a684160b750e07bd5b36eef - uniqlo - strip out 1 line of headers - default
//2a9642e57d38453bb401d2571825af1d - ichikawa japan - strip out 2 lines header + RF filtering

class Upload extends React.Component {
  constructor() {
    super();

    this.state = {
      file_content: [],
      modalIsOpen: false,
      loading: "",
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.postFile = this.postFile.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true, loading: ""});
  }

  closeModal() {
    this.setState({modalIsOpen: false, loading: ""});
  }

  uploadFile(e) {
    let reader = new FileReader();
    const parse = () => {   //console.log(evt.target.result);
      const content = reader.result;
      let array = content.trim().split("\n");

      //ichikawa
      if (this.props.currentSiteId === '2a9642e57d38453bb401d2571825af1d') {
        let pattern = /RF10|RF11/;
        let filtered_content = [];

        array.forEach((line)=> {
          let array2 = line.split(",");
          if (pattern.test(array2[3])) {
            filtered_content.push(line);
          }
        })
        console.log("File Content: " + filtered_content.length);
        this.setState({file_content: filtered_content});
      } else { //default
        array.shift();
        console.log("File Content: " + array.length);
        this.setState({file_content: array});
      }
    };

    reader.onload = parse;

    if (this.props.currentSiteId === 'f064e086e6574b8cb7da0831cb40e05b') { //BLM
      reader.readAsText(e.target.files[0], "shift_jis");
    } else { //default
      reader.readAsText(e.target.files[0]);
    }
  }

  postFile(e) {
    this.setState({loading: "LOADING FILE..."})
    //this.loopFile(0, this.state.file_content);
    this.loopEntireFile(this.state.file_content);
  }

  loopFile(start, content) {
    if (start > content.length) {
      this.setState({loading: "UPLOAD COMPLETE", file_content: [], /* modalIsOpen: false*/})
      return;
    }

    let array = content.slice(start, start + 501);
    postWmsFile(this.props.authToken, this.props.currentSiteId, array.join("\n").trim())
      .then(response => { this.setState({loading: "PROCESSED " + start + " LINES"}); this.loopFile(start + 500, content)})
      .catch(error => {this.setState({loading: "ERROR - " + error.status}); console.log(error.status + " - " + start)})
  }

  loopEntireFile(content) {
    postWmsFile(this.props.authToken, this.props.currentSiteId, content.join("\n").trim())
      .then(response => { this.setState({loading: "PROCESSING " + content.length + " LINES"})})
      .then(() => this.setState({loading: "UPLOAD COMPLETE", file_content: []}))
      .catch(error => {this.setState({loading: "ERROR - " + error.status}); console.log(error.status)})
  }


  render() {
    return (
      <div style={{display: "inline-block"}}>
        <img className="MapTypeIcon" alt="upload" src={IMG_CONFIG['upload']['active']['img']} onClick={this.openModal}/>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="UPLOAD WMS DATA"
          className="Upload"
        >
        <img className="xImg" alt="close" src={require("../img/x.png")} onClick={this.closeModal}/>
        <div className="UploadContainer">
          <div>UPLOAD WMS DATA</div>
          <div className="SelectFileText">Select the file to upload</div>
          <input type="file" name="Choose File" onChange={this.uploadFile} />
          <div>
            <div className="DownloadButton">
              <div className="UploadText" onClick={this.postFile}>UPLOAD</div>
            </div>
            <div className="LoadingStatus">{this.state.loading}</div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({ authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(Upload);
