//GID list for WMS
//export const WMS_TYPES=['Floor 2 Forklift', 'Floor 2 Worker','Floor 1 Forklift', 'Floor 1 Worker'];
/*export const WMS_GROUP_TYPE = {
  '2a9642e57d38453bb401d2571825af1d' : { //Ichikawa
    "Floor 2" : ['Floor 2 Forklift', 'Floor 2 Worker'],
    'Floor 1' : ['Floor 1 Forklift', 'Floor 1 Worker'],
    "Deactivated" : ['Deactivated'],
  },
  'b5fcb9a07a684160b750e07bd5b36eef' : { //Gu Ichihawa
    'Floor 2' : ['Floor 2 Worker']
  },
  'd954674dabd7411e812759b0edf0a4e5' : { //ABInBevTulsa
    'Floor 1' : ['Floor 1 Worker']
  },
  'f064e086e6574b8cb7da0831cb40e05b' : { //BML
    'Floor 1' : ['Floor 1 Worker']
  },
};

export const DEFAULT_GROUP_SELECT = {
  '2a9642e57d38453bb401d2571825af1d': {'Floor 2 Forklift': false, 'Floor 2 Worker': false,'Floor 1 Forklift':false, 'Floor 1 Worker':false, 'Deactivated': false},
  'b5fcb9a07a684160b750e07bd5b36eef': {'Floor 2 Worker': true},
  'd954674dabd7411e812759b0edf0a4e5': {'Floor 1 Worker': true},
  'f064e086e6574b8cb7da0831cb40e05b': {'Floor 1 Worker': true},
};

export const DEFAULT_WORKER_SUBFILTER = {
   '2a9642e57d38453bb401d2571825af1d': {'attached': false, 'unattached': false},
   'b5fcb9a07a684160b750e07bd5b36eef': {'attached': false, 'unattached': false},
   'd954674dabd7411e812759b0edf0a4e5': {'attached': false, 'unattached': false},
   'f064e086e6574b8cb7da0831cb40e05b': {'attached': false, 'unattached': false},
};

export const DEFAULT_LIST_SELECT = {
  '2a9642e57d38453bb401d2571825af1d': {'Floor 2 Forklift': false, 'Floor 2 Worker': false,'Floor 1 Forklift':false, 'Floor 1 Worker':false, 'Deactivated': false},
  'b5fcb9a07a684160b750e07bd5b36eef': {'Floor 2 Worker': true},
  'd954674dabd7411e812759b0edf0a4e5': {'Floor 1 Worker': true},
  'f064e086e6574b8cb7da0831cb40e05b': {'Floor 1 Worker': true},
}
*/

export const WMS_IMG_CONFIG = { //inactive is disconnected
  'Default' : { 'select': require('../img/worker_select.png'), 'unselect': require('../img/worker_unselect.png')},
  'Floor 1 Worker': { 'select': require('../img/worker_select.png'), 'unselect': require('../img/worker_unselect.png')},
  'Floor 1 Forklift': { 'select': require('../img/forklift_select.png'), 'unselect': require('../img/forklift_unselect.png')},
  'Floor 2 Worker': { 'select': require('../img/worker_select.png'), 'unselect': require('../img/worker_unselect.png')},
  'Floor 2 Forklift': { 'select': require('../img/forklift_select.png'), 'unselect': require('../img/forklift_unselect.png')},
  'Deactivated': { 'select': require('../img/worker_select.png'), 'unselect': require('../img/worker_unselect.png')},
};

/*export const GIDS = {
  '2a9642e57d38453bb401d2571825af1d' : { //Ichikawa Japan
  	'36501c57d7764c40b26c190ecaa97565' : {'id' : '36501c57d7764c40b26c190ecaa97565', 'name' : '2F C08', 'type' : 'Floor 2 Forklift', 'order' : 1, 'color' : '#54A238'},
  	'3938b2b9ab484a63b8d944d31472db14' : {'id' : '3938b2b9ab484a63b8d944d31472db14', 'name' : '2F P07', 'type' : 'Floor 2 Forklift', 'order' : 2, 'color' : '#156DFC'},
  	'e8510803a9e6404b840417178c466fa9' : {'id' : 'e8510803a9e6404b840417178c466fa9', 'name' : '2F R02', 'type' : 'Floor 2 Forklift', 'order' : 3, 'color' : '#E743D3'},
  	'6d64b10880234bcabf581454b32e52de' : {'id' : '6d64b10880234bcabf581454b32e52de', 'name' : '2F R03', 'type' : 'Floor 2 Forklift', 'order' : 4, 'color' : '#BD8C4D'},
  	'06011d6698a942c998f8f2ea4d360b78' : {'id' : '06011d6698a942c998f8f2ea4d360b78', 'name' : '2F R04', 'type' : 'Floor 2 Forklift', 'order' : 5, 'color' : '#FF7C43'},
  	'093b3a078b6d47c9ae100230989ad189' : {'id' : '093b3a078b6d47c9ae100230989ad189', 'name' : '2F R57', 'type' : 'Floor 2 Forklift', 'order' : 6, 'color' : '#8AEFFF'},
  	'9004035130' : {'id' : '9004035130', 'name' : '9004035130', 'type' : 'Floor 2 Worker', 'order' : 7, 'color' : '#42B5B4'},
  	'9004035342' : {'id' : '9004035342', 'name' : '9004035342', 'type' : 'Floor 2 Worker', 'order' : 8, 'color' : '#CCC374'},
  	'9004035378' : {'id' : '9004035378', 'name' : '9004035378', 'type' : 'Floor 2 Worker', 'order' : 9, 'color' : '#907212'},
  	'9004035405' : {'id' : '9004035405', 'name' : '9004035405', 'type' : 'Floor 2 Worker', 'order' : 10, 'color' : '#FFB708'},
  	'9004035450' : {'id' : '9004035450', 'name' : '9004035450', 'type' : 'Floor 2 Worker', 'order' : 11, 'color' : '#F52F82'},
  	'9004035452' : {'id' : '9004035452', 'name' : '9004035452', 'type' : 'Floor 2 Worker', 'order' : 12, 'color' : '#3B335C'},
  	'9004035455' : {'id' : '9004035455', 'name' : '9004035455', 'type' : 'Floor 2 Worker', 'order' : 13, 'color' : '#A05195'},
  	'9004035457' : {'id' : '9004035457', 'name' : '9004035457', 'type' : 'Floor 2 Worker', 'order' : 14, 'color' : '#F03F13'},
  	'9004035472' : {'id' : '9004035472', 'name' : '9004035472', 'type' : 'Floor 2 Worker', 'order' : 15, 'color' : '#29EB19'},
  	'9004035473' : {'id' : '9004035473', 'name' : '9004035473', 'type' : 'Floor 2 Worker', 'order' : 16, 'color' : '#F95D6A'},
  	'9004035475' : {'id' : '9004035475', 'name' : '9004035475', 'type' : 'Floor 2 Worker', 'order' : 17, 'color' : '#0080A6'},
  	'9004035477' : {'id' : '9004035477', 'name' : '9004035477', 'type' : 'Floor 2 Worker', 'order' : 18, 'color' : '#8B0AB4'},
  	'9004035478' : {'id' : '9004035478', 'name' : '9004035478', 'type' : 'Floor 2 Worker', 'order' : 19, 'color' : '#B87104'},
  	'9004035479' : {'id' : '9004035479', 'name' : '9004035479', 'type' : 'Floor 2 Worker', 'order' : 20, 'color' : '#96E5FC'},
  	'9004035489' : {'id' : '9004035489', 'name' : '9004035489', 'type' : 'Floor 2 Worker', 'order' : 21, 'color' : '#92D879'},
  	'9004035501' : {'id' : '9004035501', 'name' : '9004035501', 'type' : 'Floor 2 Worker', 'order' : 22, 'color' : '#1A7E6E'},
  	'9004035505' : {'id' : '9004035505', 'name' : '9004035505', 'type' : 'Floor 2 Worker', 'order' : 23, 'color' : '#9B6144'},
  	'9004035513' : {'id' : '9004035513', 'name' : '9004035513', 'type' : 'Floor 2 Worker', 'order' : 24, 'color' : '#AC448A'},
  	'9004035516' : {'id' : '9004035516', 'name' : '9004035516', 'type' : 'Floor 2 Worker', 'order' : 25, 'color' : '#A28ED9'},
  	'9004035517' : {'id' : '9004035517', 'name' : '9004035517', 'type' : 'Floor 2 Worker', 'order' : 26, 'color' : '#F6E800'},
  	'9004035538' : {'id' : '9004035538', 'name' : '9004035538', 'type' : 'Floor 2 Worker', 'order' : 27, 'color' : '#938e85'},
  	'9004035582' : {'id' : '9004035582', 'name' : '9004035582', 'type' : 'Floor 2 Worker', 'order' : 28, 'color' : '#983600'},
  	'9004035602' : {'id' : '9004035602', 'name' : '9004035602', 'type' : 'Floor 2 Worker', 'order' : 29, 'color' : '#52572E'},
  	'9004035800' : {'id' : '9004035800', 'name' : '9004035800', 'type' : 'Floor 2 Worker', 'order' : 30, 'color' : '#A387B6'},
  	'9004035840' : {'id' : '9004035840', 'name' : '9004035840', 'type' : 'Floor 2 Worker', 'order' : 31, 'color' : '#C57777'},
  	'9004035867' : {'id' : '9004035867', 'name' : '9004035867', 'type' : 'Floor 2 Worker', 'order' : 32, 'color' : '#9FB8FD'},
  	'9004035879' : {'id' : '9004035879', 'name' : '9004035879', 'type' : 'Floor 2 Worker', 'order' : 33, 'color' : '#562A64'},
  	'9004035880' : {'id' : '9004035880', 'name' : '9004035880', 'type' : 'Floor 2 Worker', 'order' : 34, 'color' : '#B25F3B'},
  	'9004035882' : {'id' : '9004035882', 'name' : '9004035882', 'type' : 'Floor 2 Worker', 'order' : 35, 'color' : '#FFA8A8'},
  	'9004035989' : {'id' : '9004035989', 'name' : '9004035989', 'type' : 'Floor 2 Worker', 'order' : 36, 'color' : '#776081'},
  	'9004036043' : {'id' : '9004036043', 'name' : '9004036043', 'type' : 'Floor 2 Worker', 'order' : 37, 'color' : '#DF3246'},
  	'9004036048' : {'id' : '9004036048', 'name' : '9004036048', 'type' : 'Floor 2 Worker', 'order' : 38, 'color' : '#C378AC'},
  	'9004036049' : {'id' : '9004036049', 'name' : '9004036049', 'type' : 'Floor 2 Worker', 'order' : 39, 'color' : '#D944D4'},
  	'9004036061' : {'id' : '9004036061', 'name' : '9004036061', 'type' : 'Floor 2 Worker', 'order' : 40, 'color' : '#F71717'},
  	'9004036066' : {'id' : '9004036066', 'name' : '9004036066', 'type' : 'Floor 2 Worker', 'order' : 41, 'color' : '#7B8183'},
  	'9004036067' : {'id' : '9004036067', 'name' : '9004036067', 'type' : 'Floor 2 Worker', 'order' : 42, 'color' : '#0BFCD2'},
  	'9004036107' : {'id' : '9004036107', 'name' : '9004036107', 'type' : 'Floor 2 Worker', 'order' : 43, 'color' : '#638341'},
  	'9004036115' : {'id' : '9004036115', 'name' : '9004036115', 'type' : 'Floor 2 Worker', 'order' : 44, 'color' : '#D2D97F'},
  	'9004036124' : {'id' : '9004036124', 'name' : '9004036124', 'type' : 'Floor 2 Worker', 'order' : 45, 'color' : '#736ADC'},
  	'9004036130' : {'id' : '9004036130', 'name' : '9004036130', 'type' : 'Floor 2 Worker', 'order' : 46, 'color' : '#A5009A'},
  	'9004036192' : {'id' : '9004036192', 'name' : '9004036192', 'type' : 'Floor 2 Worker', 'order' : 47, 'color' : '#33EDB9'},
  	'9004036258' : {'id' : '9004036258', 'name' : '9004036258', 'type' : 'Floor 2 Worker', 'order' : 48, 'color' : '#A34771'},
  	'9004036388' : {'id' : '9004036388', 'name' : '9004036388', 'type' : 'Floor 2 Worker', 'order' : 49, 'color' : '#7E6A43'},
  	'9004036997' : {'id' : '9004036997', 'name' : '9004036997', 'type' : 'Floor 2 Worker', 'order' : 50, 'color' : '#6BC71A'},
  	'9004037017' : {'id' : '9004037017', 'name' : '9004037017', 'type' : 'Floor 2 Worker', 'order' : 51, 'color' : '#BDCD78'},
  	'9004044611' : {'id' : '9004044611', 'name' : '9004044611', 'type' : 'Floor 2 Worker', 'order' : 52, 'color' : '#C9FF92'},
  	'9004044921' : {'id' : '9004044921', 'name' : '9004044921', 'type' : 'Floor 2 Worker', 'order' : 53, 'color' : '#2FEF98'},
  	'9004044924' : {'id' : '9004044924', 'name' : '9004044924', 'type' : 'Floor 2 Worker', 'order' : 54, 'color' : '#39A7AF'},
  	'9004045271' : {'id' : '9004045271', 'name' : '9004045271', 'type' : 'Floor 2 Worker', 'order' : 55, 'color' : '#888574'},
  	'9004045272' : {'id' : '9004045272', 'name' : '9004045272', 'type' : 'Floor 2 Worker', 'order' : 56, 'color' : '#ED95D0'},
  	'9004045273' : {'id' : '9004045273', 'name' : '9004045273', 'type' : 'Floor 2 Worker', 'order' : 57, 'color' : '#B686B4'},
  	'9004045275' : {'id' : '9004045275', 'name' : '9004045275', 'type' : 'Floor 2 Worker', 'order' : 58, 'color' : '#249958'},
  	'9004045276' : {'id' : '9004045276', 'name' : '9004045276', 'type' : 'Floor 2 Worker', 'order' : 59, 'color' : '#76D979'},
  	'9004045911' : {'id' : '9004045911', 'name' : '9004045911', 'type' : 'Floor 2 Worker', 'order' : 60, 'color' : '#D6E538'},
  	'9004046293' : {'id' : '9004046293', 'name' : '9004046293', 'type' : 'Floor 2 Worker', 'order' : 61, 'color' : '#3B35D8'},
  	'9004046295' : {'id' : '9004046295', 'name' : '9004046295', 'type' : 'Floor 2 Worker', 'order' : 62, 'color' : '#CDCE17'},
  	'9004047207' : {'id' : '9004047207', 'name' : '9004047207', 'type' : 'Floor 2 Worker', 'order' : 63, 'color' : '#90C6D2'},
  	'9004047404' : {'id' : '9004047404', 'name' : '9004047404', 'type' : 'Floor 2 Worker', 'order' : 64, 'color' : '#79056E'},
  	'9004047612' : {'id' : '9004047612', 'name' : '9004047612', 'type' : 'Floor 2 Worker', 'order' : 65, 'color' : '#911490'},
  	'9004047614' : {'id' : '9004047614', 'name' : '9004047614', 'type' : 'Floor 2 Worker', 'order' : 66, 'color' : '#E4D010'},
  	'9004047616' : {'id' : '9004047616', 'name' : '9004047616', 'type' : 'Floor 2 Worker', 'order' : 67, 'color' : '#E1642F'},
  	'9004047617' : {'id' : '9004047617', 'name' : '9004047617', 'type' : 'Floor 2 Worker', 'order' : 68, 'color' : '#ED3BAB'},
  	'9004047622' : {'id' : '9004047622', 'name' : '9004047622', 'type' : 'Floor 2 Worker', 'order' : 69, 'color' : '#901547'},
  	'9004049861' : {'id' : '9004049861', 'name' : '9004049861', 'type' : 'Floor 2 Worker', 'order' : 70, 'color' : '#C5275B'},
  	'9004049864' : {'id' : '9004049864', 'name' : '9004049864', 'type' : 'Floor 2 Worker', 'order' : 71, 'color' : '#ACB455'},
  	'9004050367' : {'id' : '9004050367', 'name' : '9004050367', 'type' : 'Floor 2 Worker', 'order' : 72, 'color' : '#1AF0A5'},
  	'9004050368' : {'id' : '9004050368', 'name' : '9004050368', 'type' : 'Floor 2 Worker', 'order' : 73, 'color' : '#F3AC00'},
  	'9004050369' : {'id' : '9004050369', 'name' : '9004050369', 'type' : 'Floor 2 Worker', 'order' : 74, 'color' : '#928703'},
  	'9004050370' : {'id' : '9004050370', 'name' : '9004050370', 'type' : 'Floor 2 Worker', 'order' : 75, 'color' : '#73FFB9'},
  	'9004050377' : {'id' : '9004050377', 'name' : '9004050377', 'type' : 'Floor 2 Worker', 'order' : 76, 'color' : '#BEAD53'},
  	'9004050378' : {'id' : '9004050378', 'name' : '9004050378', 'type' : 'Floor 2 Worker', 'order' : 77, 'color' : '#F8DF6E'},
  	'9004050666' : {'id' : '9004050666', 'name' : '9004050666', 'type' : 'Floor 2 Worker', 'order' : 78, 'color' : '#3CB0C7'},
  	'9004051272' : {'id' : '9004051272', 'name' : '9004051272', 'type' : 'Floor 2 Worker', 'order' : 79, 'color' : '#A266A2'},
  	'9004051419' : {'id' : '9004051419', 'name' : '9004051419', 'type' : 'Floor 2 Worker', 'order' : 80, 'color' : '#E16091'},
  	'9004051422' : {'id' : '9004051422', 'name' : '9004051422', 'type' : 'Floor 2 Worker', 'order' : 81, 'color' : '#BBA3F7'},
  	'9004051424' : {'id' : '9004051424', 'name' : '9004051424', 'type' : 'Floor 2 Worker', 'order' : 82, 'color' : '#006C6E'},
  	'9004051425' : {'id' : '9004051425', 'name' : '9004051425', 'type' : 'Floor 2 Worker', 'order' : 83, 'color' : '#647859'},
  	'9004051429' : {'id' : '9004051429', 'name' : '9004051429', 'type' : 'Floor 2 Worker', 'order' : 84, 'color' : '#AB10D0'},
  	'9004051433' : {'id' : '9004051433', 'name' : '9004051433', 'type' : 'Floor 2 Worker', 'order' : 85, 'color' : '#1C867A'},
  	'9004052286' : {'id' : '9004052286', 'name' : '9004052286', 'type' : 'Floor 2 Worker', 'order' : 86, 'color' : '#3D6E92'},
  	'9004052287' : {'id' : '9004052287', 'name' : '9004052287', 'type' : 'Floor 2 Worker', 'order' : 87, 'color' : '#183655'},
  	'8c1598c58b024b8faa85827102c8cf14' : {'id' : '8c1598c58b024b8faa85827102c8cf14', 'name' : '1F P51', 'type' : 'Floor 1 Forklift', 'order' : 88, 'color' : '#69D50F'},
  	'f8f0d7e9abe046049689533db9e7f86d' : {'id' : 'f8f0d7e9abe046049689533db9e7f86d', 'name' : '1F 52 clumper', 'type' : 'Floor 1 Forklift', 'order' : 89, 'color' : '#F14B76'},
  	'8c4c16061ae544a386eba24b2dce1490' : {'id' : '8c4c16061ae544a386eba24b2dce1490', 'name' : '1F C53', 'type' : 'Floor 1 Forklift', 'order' : 90, 'color' : '#227443'},
  	'376fa298a2df4f3080d1be8538b4fea8' : {'id' : '376fa298a2df4f3080d1be8538b4fea8', 'name' : '1F C54', 'type' : 'Floor 1 Forklift', 'order' : 91, 'color' : '#7D0FBE'},
  	'c5675b46e952408385d0531c915d5f02' : {'id' : 'c5675b46e952408385d0531c915d5f02', 'name' : '1F R55', 'type' : 'Floor 1 Forklift', 'order' : 92, 'color' : '#6BFE4F'},
  	'c3118ac9302743aa9e61092eefe594a2' : {'id' : 'c3118ac9302743aa9e61092eefe594a2', 'name' : '1F R58', 'type' : 'Floor 1 Forklift', 'order' : 93, 'color' : '#A9DEB3'},
  	'7acc1a30f28d4decb739a254a876f67f' : {'id' : '7acc1a30f28d4decb739a254a876f67f', 'name' : '1F R59', 'type' : 'Floor 1 Forklift', 'order' : 94, 'color' : '#FD7215'},
  	'fb75651e336a4473ac8d04dc3c0acbed' : {'id' : 'fb75651e336a4473ac8d04dc3c0acbed', 'name' : '1F R60', 'type' : 'Floor 1 Forklift', 'order' : 95, 'color' : '#7BBD3A'},
  	'839d1e658d0a44688e27c8b3c8ce6782' : {'id' : '839d1e658d0a44688e27c8b3c8ce6782', 'name' : '1F R61', 'type' : 'Floor 1 Forklift', 'order' : 96, 'color' : '#FF8C89'},
  	'1369a635f9eb41e8aefee3f3dc9a4bd4' : {'id' : '1369a635f9eb41e8aefee3f3dc9a4bd4', 'name' : '1F R62', 'type' : 'Floor 1 Forklift', 'order' : 97, 'color' : '#D74E3B'},
  	'4abae8c4d9d14209aa7dd5ee6c93b728' : {'id' : '4abae8c4d9d14209aa7dd5ee6c93b728', 'name' : '1F R63', 'type' : 'Floor 1 Forklift', 'order' : 98, 'color' : '#C17B49'},
  	'bd52dee2ebdc45c9aa68c5a79a55d70c' : {'id' : 'bd52dee2ebdc45c9aa68c5a79a55d70c', 'name' : '1F R64', 'type' : 'Floor 1 Forklift', 'order' : 99, 'color' : '#FAAF7D'},
  	'997f5c4cc95a42ceb613edc34717a963' : {'id' : '997f5c4cc95a42ceb613edc34717a963', 'name' : '1F R65', 'type' : 'Floor 1 Forklift', 'order' : 100, 'color' : '#F24D84'},
  	'9004035328' : {'id' : '9004035328', 'name' : '9004035328', 'type' : 'Floor 1 Worker', 'order' : 101, 'color' : '#C0502B'},
  	'9004035364' : {'id' : '9004035364', 'name' : '9004035364', 'type' : 'Floor 1 Worker', 'order' : 102, 'color' : '#675961'},
  	'9004035448' : {'id' : '9004035448', 'name' : '9004035448', 'type' : 'Floor 1 Worker', 'order' : 103, 'color' : '#EAA6FA'},
  	'9004035449' : {'id' : '9004035449', 'name' : '9004035449', 'type' : 'Floor 1 Worker', 'order' : 104, 'color' : '#025DA3'},
  	'9004035454' : {'id' : '9004035454', 'name' : '9004035454', 'type' : 'Floor 1 Worker', 'order' : 105, 'color' : '#433192'},
  	'9004035456' : {'id' : '9004035456', 'name' : '9004035456', 'type' : 'Floor 1 Worker', 'order' : 106, 'color' : '#D2347C'},
  	'9004035503' : {'id' : '9004035503', 'name' : '9004035503', 'type' : 'Floor 1 Worker', 'order' : 107, 'color' : '#FB962A'},
  	'9004036033' : {'id' : '9004036033', 'name' : '9004036033', 'type' : 'Floor 1 Worker', 'order' : 108, 'color' : '#5A6107'},
  	'9004036034' : {'id' : '9004036034', 'name' : '9004036034', 'type' : 'Floor 1 Worker', 'order' : 109, 'color' : '#F8A68B'},
  	'9004036119' : {'id' : '9004036119', 'name' : '9004036119', 'type' : 'Floor 1 Worker', 'order' : 110, 'color' : '#E2193F'},
  	'9004036190' : {'id' : '9004036190', 'name' : '9004036190', 'type' : 'Floor 1 Worker', 'order' : 111, 'color' : '#511B4A'},
  	'9004036340' : {'id' : '9004036340', 'name' : '9004036340', 'type' : 'Floor 1 Worker', 'order' : 112, 'color' : '#538E7E'},
  	'9004037016' : {'id' : '9004037016', 'name' : '9004037016', 'type' : 'Floor 1 Worker', 'order' : 113, 'color' : '#8F3719'},
  	'9004041481' : {'id' : '9004041481', 'name' : '9004041481', 'type' : 'Floor 1 Worker', 'order' : 114, 'color' : '#004D3B'},
  	'9004041482' : {'id' : '9004041482', 'name' : '9004041482', 'type' : 'Floor 1 Worker', 'order' : 115, 'color' : '#6A32DC'},
  	'9004051426' : {'id' : '9004051426', 'name' : '9004051426', 'type' : 'Floor 1 Worker', 'order' : 116, 'color' : '#E2D164'}
  },
  //Uniqlo
  'b5fcb9a07a684160b750e07bd5b36eef' : {
    '000000': { 'id': '000000', 'name': 'Regular Pick 000', 'type': 'Floor 2 Worker', 'order': 1,'color': '#4B0082'},
    '000001': { 'id': '000001', 'name': 'Regular Pick 001', 'type': 'Floor 2 Worker', 'order': 2,'color': '#BDB76B'},
    '000002': { 'id': '000002', 'name': 'Regular Pick 002', 'type': 'Floor 2 Worker', 'order': 3,'color': '#E0FFFF'},
    '000003': { 'id': '000003', 'name': 'Regular Pick 003', 'type': 'Floor 2 Worker', 'order': 4,'color': '#9932CC'},
    '000004': { 'id': '000004', 'name': 'Regular Pick 004', 'type': 'Floor 2 Worker', 'order': 5,'color': '#B0E0E6'},
    '000005': { 'id': '000005', 'name': 'Regular Pick 005', 'type': 'Floor 2 Worker', 'order': 6,'color': '#FFFFFF'},
    '000006': { 'id': '000006', 'name': 'Regular Pick 006', 'type': 'Floor 2 Worker', 'order': 7,'color': '#2F4F4F'},
    '000007': { 'id': '000007', 'name': 'Regular Pick 007', 'type': 'Floor 2 Worker', 'order': 8,'color': '#FAFAD2'},
    '000008': { 'id': '000008', 'name': 'Regular Pick 008', 'type': 'Floor 2 Worker', 'order': 9,'color': '#C71585'},
    '000009': { 'id': '000009', 'name': 'Regular Pick 009', 'type': 'Floor 2 Worker', 'order': 10,'color': '#556B2F'},
    '000010': { 'id': '000010', 'name': 'Regular Pick 010', 'type': 'Floor 2 Worker', 'order': 11,'color': '#000080'},
    '000011': { 'id': '000011', 'name': 'Regular Pick 011', 'type': 'Floor 2 Worker', 'order': 12,'color': '#FFFACD'},
    '000012': { 'id': '000012', 'name': 'Regular Pick 012', 'type': 'Floor 2 Worker', 'order': 13,'color': '#F0FFFF'},
    '000013': { 'id': '000013', 'name': 'Regular Pick 013', 'type': 'Floor 2 Worker', 'order': 14,'color': '#FFB6C1'},
    '000014': { 'id': '000014', 'name': 'Regular Pick 014', 'type': 'Floor 2 Worker', 'order': 15,'color': '#FAEBD7'},
    '000015': { 'id': '000015', 'name': 'Regular Pick 015', 'type': 'Floor 2 Worker', 'order': 16,'color': '#FFEBCD'},
    '000016': { 'id': '000016', 'name': 'Regular Pick 016', 'type': 'Floor 2 Worker', 'order': 17,'color': '#808000'},
    '000017': { 'id': '000017', 'name': 'Regular Pick 017', 'type': 'Floor 2 Worker', 'order': 18,'color': '#FFE4C4'},
    '000018': { 'id': '000018', 'name': 'Regular Pick 018', 'type': 'Floor 2 Worker', 'order': 19,'color': '#8FBC8F'},
    '000019': { 'id': '000019', 'name': 'Regular Pick 019', 'type': 'Floor 2 Worker', 'order': 20,'color': '#B8860B'},
    '000020': { 'id': '000020', 'name': 'Regular Pick 020', 'type': 'Floor 2 Worker', 'order': 21,'color': '#8B008B'},
    '000021': { 'id': '000021', 'name': 'Regular Pick 021', 'type': 'Floor 2 Worker', 'order': 22,'color': '#008080'},
    '000022': { 'id': '000022', 'name': 'Regular Pick 022', 'type': 'Floor 2 Worker', 'order': 23,'color': '#B22222'},
    '000023': { 'id': '000023', 'name': 'Regular Pick 023', 'type': 'Floor 2 Worker', 'order': 24,'color': '#FFF0F5'},
    '000024': { 'id': '000024', 'name': 'Regular Pick 024', 'type': 'Floor 2 Worker', 'order': 25,'color': '#F8F8FF'},
    '000025': { 'id': '000025', 'name': 'Regular Pick 025', 'type': 'Floor 2 Worker', 'order': 26,'color': '#AFEEEE'},
    '000026': { 'id': '000026', 'name': 'Regular Pick 026', 'type': 'Floor 2 Worker', 'order': 27,'color': '#A0522D'},
    '000027': { 'id': '000027', 'name': 'Regular Pick 027', 'type': 'Floor 2 Worker', 'order': 28,'color': '#696969'},
    '000028': { 'id': '000028', 'name': 'Regular Pick 028', 'type': 'Floor 2 Worker', 'order': 29,'color': '#00FF00'},
    '000029': { 'id': '000029', 'name': 'Regular Pick 029', 'type': 'Floor 2 Worker', 'order': 30,'color': '#5F9EA0'},
    '000030': { 'id': '000030', 'name': 'Regular Pick 030', 'type': 'Floor 2 Worker', 'order': 31,'color': '#008B8B'},
    '000031': { 'id': '000031', 'name': 'Regular Pick 031', 'type': 'Floor 2 Worker', 'order': 32,'color': '#FA8072'},
    '000032': { 'id': '000032', 'name': 'Regular Pick 032', 'type': 'Floor 2 Worker', 'order': 33,'color': '#1E90FF'},
    '000033': { 'id': '000033', 'name': 'Regular Pick 033', 'type': 'Floor 2 Worker', 'order': 34,'color': '#FF0000'},
    '000034': { 'id': '000034', 'name': 'Regular Pick 034', 'type': 'Floor 2 Worker', 'order': 35,'color': '#FF4500'},
    '000035': { 'id': '000035', 'name': 'Regular Pick 035', 'type': 'Floor 2 Worker', 'order': 36,'color': '#F0F8FF'},
    '000036': { 'id': '000036', 'name': 'Regular Pick 036', 'type': 'Floor 2 Worker', 'order': 37,'color': '#0000FF'},
    '000037': { 'id': '000037', 'name': 'Regular Pick 037', 'type': 'Floor 2 Worker', 'order': 38,'color': '#F0E68C'},
    '000038': { 'id': '000038', 'name': 'Regular Pick 038', 'type': 'Floor 2 Worker', 'order': 39,'color': '#DB7093'},
    '000039': { 'id': '000039', 'name': 'Regular Pick 039', 'type': 'Floor 2 Worker', 'order': 40,'color': '#191970'},
    '000040': { 'id': '000040', 'name': 'Regular Pick 040', 'type': 'Floor 2 Worker', 'order': 41,'color': '#E9967A'},
    '000041': { 'id': '000041', 'name': 'Regular Pick 041', 'type': 'Floor 2 Worker', 'order': 42,'color': '#FF7F50'},
    '000042': { 'id': '000042', 'name': 'Regular Pick 042', 'type': 'Floor 2 Worker', 'order': 43,'color': '#FFD700'},
    '000043': { 'id': '000043', 'name': 'Regular Pick 043', 'type': 'Floor 2 Worker', 'order': 44,'color': '#808080'},
    '000044': { 'id': '000044', 'name': 'Regular Pick 044', 'type': 'Floor 2 Worker', 'order': 45,'color': '#FFFF00'},
    '000045': { 'id': '000045', 'name': 'Regular Pick 045', 'type': 'Floor 2 Worker', 'order': 46,'color': '#DA70D6'},
    '000046': { 'id': '000046', 'name': 'Regular Pick 046', 'type': 'Floor 2 Worker', 'order': 47,'color': '#00CED1'},
    '000047': { 'id': '000047', 'name': 'Regular Pick 047', 'type': 'Floor 2 Worker', 'order': 48,'color': '#00BFFF'},
    '000048': { 'id': '000048', 'name': 'Regular Pick 048', 'type': 'Floor 2 Worker', 'order': 49,'color': '#CD853F'},
    '000049': { 'id': '000049', 'name': 'Regular Pick 049', 'type': 'Floor 2 Worker', 'order': 50,'color': '#FF8C00'},
    '000050': { 'id': '000050', 'name': 'Regular Pick 050', 'type': 'Floor 2 Worker', 'order': 51,'color': '#DDA0DD'},
    '000051': { 'id': '000051', 'name': 'Regular Pick 051', 'type': 'Floor 2 Worker', 'order': 52,'color': '#00FFFF'},
    '000052': { 'id': '000052', 'name': 'Regular Pick 052', 'type': 'Floor 2 Worker', 'order': 53,'color': '#228B22'},
    '000053': { 'id': '000053', 'name': 'Regular Pick 053', 'type': 'Floor 2 Worker', 'order': 54,'color': '#D2691E'},
    '000054': { 'id': '000054', 'name': 'Regular Pick 054', 'type': 'Floor 2 Worker', 'order': 55,'color': '#F08080'},
    '000055': { 'id': '000055', 'name': 'Regular Pick 055', 'type': 'Floor 2 Worker', 'order': 56,'color': '#FF1493'},
    '000056': { 'id': '000056', 'name': 'Regular Pick 056', 'type': 'Floor 2 Worker', 'order': 57,'color': '#F5DEB3'},
    '000057': { 'id': '000057', 'name': 'Regular Pick 057', 'type': 'Floor 2 Worker', 'order': 58,'color': '#800080'},
    '000058': { 'id': '000058', 'name': 'Regular Pick 058', 'type': 'Floor 2 Worker', 'order': 59,'color': '#FFFFF0'},
    '000059': { 'id': '000059', 'name': 'Regular Pick 059', 'type': 'Floor 2 Worker', 'order': 60,'color': '#87CEEB'},
    '000060': { 'id': '000060', 'name': 'Regular Pick 060', 'type': 'Floor 2 Worker', 'order': 61,'color': '#0000CD'},
    '000061': { 'id': '000061', 'name': 'Regular Pick 061', 'type': 'Floor 2 Worker', 'order': 62,'color': '#FF69B4'},
    '000062': { 'id': '000062', 'name': 'Regular Pick 062', 'type': 'Floor 2 Worker', 'order': 63,'color': '#D3D3D3'},
    '000063': { 'id': '000063', 'name': 'Regular Pick 063', 'type': 'Floor 2 Worker', 'order': 64,'color': '#BA55D3'},
    '000064': { 'id': '000064', 'name': 'Regular Pick 064', 'type': 'Floor 2 Worker', 'order': 65,'color': '#DAA520'},
    '000065': { 'id': '000065', 'name': 'Regular Pick 065', 'type': 'Floor 2 Worker', 'order': 66,'color': '#FFE4B5'},
    '000066': { 'id': '000066', 'name': 'Regular Pick 066', 'type': 'Floor 2 Worker', 'order': 67,'color': '#4682B4'},
    '000067': { 'id': '000067', 'name': 'Regular Pick 067', 'type': 'Floor 2 Worker', 'order': 68,'color': '#FFC0CB'},
    '000068': { 'id': '000068', 'name': 'Regular Pick 068', 'type': 'Floor 2 Worker', 'order': 69,'color': '#8B4513'},
    '000069': { 'id': '000069', 'name': 'Regular Pick 069', 'type': 'Floor 2 Worker', 'order': 70,'color': '#00008B'},
    '000070': { 'id': '000070', 'name': 'Regular Pick 070', 'type': 'Floor 2 Worker', 'order': 71,'color': '#EEE8AA'},
    '000071': { 'id': '000071', 'name': 'Regular Pick 071', 'type': 'Floor 2 Worker', 'order': 72,'color': '#2E8B57'},
    '000072': { 'id': '000072', 'name': 'Regular Pick 072', 'type': 'Floor 2 Worker', 'order': 73,'color': '#CD5C5C'},
    '000073': { 'id': '000073', 'name': 'Regular Pick 073', 'type': 'Floor 2 Worker', 'order': 74,'color': '#7B68EE'},
    '000074': { 'id': '000074', 'name': 'Regular Pick 074', 'type': 'Floor 2 Worker', 'order': 75,'color': '#F5F5DC'},
    '000075': { 'id': '000075', 'name': 'Regular Pick 075', 'type': 'Floor 2 Worker', 'order': 76,'color': '#98FB98'},
    '000076': { 'id': '000076', 'name': 'Regular Pick 076', 'type': 'Floor 2 Worker', 'order': 77,'color': '#DCDCDC'},
    '000077': { 'id': '000077', 'name': 'Regular Pick 077', 'type': 'Floor 2 Worker', 'order': 78,'color': '#BC8F8F'},
    '000078': { 'id': '000078', 'name': 'Regular Pick 078', 'type': 'Floor 2 Worker', 'order': 79,'color': '#FAF0E6'},
    '000079': { 'id': '000079', 'name': 'Regular Pick 079', 'type': 'Floor 2 Worker', 'order': 80,'color': '#FFFAF0'},
    '000080': { 'id': '000080', 'name': 'Regular Pick 080', 'type': 'Floor 2 Worker', 'order': 81,'color': '#B0C4DE'},
    '000081': { 'id': '000081', 'name': 'Regular Pick 081', 'type': 'Floor 2 Worker', 'order': 82,'color': '#778899'},
    '000082': { 'id': '000082', 'name': 'Regular Pick 082', 'type': 'Floor 2 Worker', 'order': 83,'color': '#FFF5EE'},
    '000083': { 'id': '000083', 'name': 'Regular Pick 083', 'type': 'Floor 2 Worker', 'order': 84,'color': '#FF00FF'},
    '000084': { 'id': '000084', 'name': 'Regular Pick 084', 'type': 'Floor 2 Worker', 'order': 85,'color': '#32CD32'},
    '000085': { 'id': '000085', 'name': 'Regular Pick 085', 'type': 'Floor 2 Worker', 'order': 86,'color': '#FFEFD5'},
    '000086': { 'id': '000086', 'name': 'Regular Pick 086', 'type': 'Floor 2 Worker', 'order': 87,'color': '#8A2BE2'},
    '000087': { 'id': '000087', 'name': 'Regular Pick 087', 'type': 'Floor 2 Worker', 'order': 88,'color': '#FFFFE0'},
    '000088': { 'id': '000088', 'name': 'Regular Pick 088', 'type': 'Floor 2 Worker', 'order': 89,'color': '#F0FFF0'},
    '000089': { 'id': '000089', 'name': 'Regular Pick 089', 'type': 'Floor 2 Worker', 'order': 90,'color': '#7FFFD4'},
    '000090': { 'id': '000090', 'name': 'Regular Pick 090', 'type': 'Floor 2 Worker', 'order': 91,'color': '#FFA07A'},
    '000091': { 'id': '000091', 'name': 'Regular Pick 091', 'type': 'Floor 2 Worker', 'order': 92,'color': '#C0C0C0'},
    '000092': { 'id': '000092', 'name': 'Regular Pick 092', 'type': 'Floor 2 Worker', 'order': 93,'color': '#9370DB'},
    '000093': { 'id': '000093', 'name': 'Regular Pick 093', 'type': 'Floor 2 Worker', 'order': 94,'color': '#3CB371'},
    '000094': { 'id': '000094', 'name': 'Regular Pick 094', 'type': 'Floor 2 Worker', 'order': 95,'color': '#8B0000'},
    '000095': { 'id': '000095', 'name': 'Regular Pick 095', 'type': 'Floor 2 Worker', 'order': 96,'color': '#F5F5F5'},
    '000096': { 'id': '000096', 'name': 'Regular Pick 096', 'type': 'Floor 2 Worker', 'order': 97,'color': '#9966CC'},
    '000097': { 'id': '000097', 'name': 'Regular Pick 097', 'type': 'Floor 2 Worker', 'order': 98,'color': '#FFA500'},
    '000098': { 'id': '000098', 'name': 'Regular Pick 098', 'type': 'Floor 2 Worker', 'order': 99,'color': '#4169E1'},
    '000099': { 'id': '000099', 'name': 'Regular Pick 099', 'type': 'Floor 2 Worker', 'order': 100,'color': '#7FFF00'},
    '000100': { 'id': '000100', 'name': 'Spot Pick 100', 'type': 'Floor 2 Worker', 'order': 101,'color': '#ADD8E6'},
    '000101': { 'id': '000101', 'name': 'Spot Pick 101', 'type': 'Floor 2 Worker', 'order': 102,'color': '#9400D3'},
    '000102': { 'id': '000102', 'name': 'Spot Pick 102', 'type': 'Floor 2 Worker', 'order': 103,'color': '#00FFFF'},
    '000103': { 'id': '000103', 'name': 'Spot Pick 103', 'type': 'Floor 2 Worker', 'order': 104,'color': '#20B2AA'},
    '000104': { 'id': '000104', 'name': 'Spot Pick 104', 'type': 'Floor 2 Worker', 'order': 105,'color': '#90EE90'},
    '000105': { 'id': '000105', 'name': 'Spot Pick 105', 'type': 'Floor 2 Worker', 'order': 106,'color': '#F5FFFA'},
    '000106': { 'id': '000106', 'name': 'Spot Pick 106', 'type': 'Floor 2 Worker', 'order': 107,'color': '#FFE4E1'},
    '000107': { 'id': '000107', 'name': 'Spot Pick 107', 'type': 'Floor 2 Worker', 'order': 108,'color': '#FFA07A'},
    '000108': { 'id': '000108', 'name': 'Spot Pick 108', 'type': 'Floor 2 Worker', 'order': 109,'color': '#006400'},
    '000109': { 'id': '000109', 'name': 'Spot Pick 109', 'type': 'Floor 2 Worker', 'order': 110,'color': '#87CEFA'},
    '000110': { 'id': '000110', 'name': 'Spot Pick 110', 'type': 'Floor 2 Worker', 'order': 111,'color': '#FFDEAD'},
    '000111': { 'id': '000111', 'name': 'Spot Pick 111', 'type': 'Floor 2 Worker', 'order': 112,'color': '#48D1CC'},
    '000112': { 'id': '000112', 'name': 'Spot Pick 112', 'type': 'Floor 2 Worker', 'order': 113,'color': '#00FF7F'},
    '000113': { 'id': '000113', 'name': 'Spot Pick 113', 'type': 'Floor 2 Worker', 'order': 114,'color': '#9ACD32'},
    '000114': { 'id': '000114', 'name': 'Spot Pick 114', 'type': 'Floor 2 Worker', 'order': 115,'color': '#66CDAA'},
    '000115': { 'id': '000115', 'name': 'Spot Pick 115', 'type': 'Floor 2 Worker', 'order': 116,'color': '#008000'},
    '000116': { 'id': '000116', 'name': 'Spot Pick 116', 'type': 'Floor 2 Worker', 'order': 117,'color': '#A52A2A'},
    '000117': { 'id': '000117', 'name': 'Spot Pick 117', 'type': 'Floor 2 Worker', 'order': 118,'color': '#FFDAB9'},
    '000118': { 'id': '000118', 'name': 'Spot Pick 118', 'type': 'Floor 2 Worker', 'order': 119,'color': '#FFFAFA'},
    '000119': { 'id': '000119', 'name': 'Spot Pick 119', 'type': 'Floor 2 Worker', 'order': 120,'color': '#D8BFD8'},
    '000120': { 'id': '000120', 'name': 'Spot Pick 120', 'type': 'Floor 2 Worker', 'order': 121,'color': '#6B8E23'},
    '000251': { 'id': '000251', 'name': 'Hojuu10', 'type': 'Floor 2 Worker', 'order': 252,'color': '#40E0D0'},
    '000252': { 'id': '000252', 'name': 'Hojuu21', 'type': 'Floor 2 Worker', 'order': 253,'color': '#ADFF2F'},
    '000253': { 'id': '000253', 'name': 'Hojuu32', 'type': 'Floor 2 Worker', 'order': 254,'color': '#EE82EE'},
    '000254': { 'id': '000254', 'name': 'Hojuu43', 'type': 'Floor 2 Worker', 'order': 255,'color': '#D2B48C'},
    '000255': { 'id': '000255', 'name': 'Hojuu54', 'type': 'Floor 2 Worker', 'order': 256,'color': '#FFF8DC'},
    '000256': { 'id': '000256', 'name': 'Hojuu65', 'type': 'Floor 2 Worker', 'order': 257,'color': '#A9A9A9'},
    '000257': { 'id': '000257', 'name': 'Hojuu76', 'type': 'Floor 2 Worker', 'order': 258,'color': '#FDF5E6'},
    '000258': { 'id': '000258', 'name': 'Hojuu87', 'type': 'Floor 2 Worker', 'order': 259,'color': '#7CFC00'},
    '000259': { 'id': '000259', 'name': 'Hojuu98', 'type': 'Floor 2 Worker', 'order': 260,'color': '#800000'},
    '000260': { 'id': '000260', 'name': 'Hojuu10*k', 'type': 'Floor 2 Worker', 'order': 261,'color': '#7B68EE'},
    '000261': { 'id': '000261', 'name': 'Manpai1*', 'type': 'Floor 2 Worker', 'order': 262,'color': '#00FA9A'},
    '000262': { 'id': '000262', 'name': 'Manpai2*', 'type': 'Floor 2 Worker', 'order': 263,'color': '#FF00FF'},
    '000263': { 'id': '000263', 'name': 'Manpai3*', 'type': 'Floor 2 Worker', 'order': 264,'color': '#F4A460'},
    '000264': { 'id': '000264', 'name': 'Manpai4*', 'type': 'Floor 2 Worker', 'order': 265,'color': '#DEB887'},
    '000265': { 'id': '000265', 'name': 'Manpai5*', 'type': 'Floor 2 Worker', 'order': 266,'color': '#FF6347'},
    '000266': { 'id': '000266', 'name': 'Manpai6*', 'type': 'Floor 2 Worker', 'order': 267,'color': '#6A5ACD'},
    '000267': { 'id': '000267', 'name': 'Manpai7*', 'type': 'Floor 2 Worker', 'order': 268,'color': '#483D8B'},
    '000268': { 'id': '000268', 'name': 'Manpai8*', 'type': 'Floor 2 Worker', 'order': 269,'color': '#708090'},
    '000269': { 'id': '000269', 'name': 'Manpai9*', 'type': 'Floor 2 Worker', 'order': 270,'color': '#000000'},
    '000270': { 'id': '000270', 'name': 'Manpai10k ', 'type': 'Floor 2 Worker', 'order': 271,'color': '#6495ED'},
    '000271': { 'id': '000271', 'name': 'Hako17', 'type': 'Floor 2 Worker', 'order': 272,'color': '#E6E6FA'},
    '000272': { 'id': '000272', 'name': 'Hako27', 'type': 'Floor 2 Worker', 'order': 273,'color': '#DC143C'},
    '000273': { 'id': '000273', 'name': 'Hako37', 'type': 'Floor 2 Worker', 'order': 274,'color': '#0000FF'},
    '000274': { 'id': '000274', 'name': 'Hako47', 'type': 'Floor 2 Worker', 'order': 275,'color': '#6A5ACD'},
    '000275': { 'id': '000275', 'name': 'Hako57', 'type': 'Floor 2 Worker', 'order': 276,'color': '#2F4F4F'},
    '000276': { 'id': '000276', 'name': 'Hako67', 'type': 'Floor 2 Worker', 'order': 277,'color': '#556B2F'},
    '000277': { 'id': '000277', 'name': 'Hako77', 'type': 'Floor 2 Worker', 'order': 278,'color': '#FFE4B5'},
    '000278': { 'id': '000278', 'name': 'Hako87', 'type': 'Floor 2 Worker', 'order': 279,'color': '#F4A460'},
    '000279': { 'id': '000279', 'name': 'Hako97', 'type': 'Floor 2 Worker', 'order': 280,'color': '#87CEEB'},
    '000280': { 'id': '000280', 'name': 'Hako109*', 'type': 'Floor 2 Worker', 'order': 281,'color': '#5F9EA0'},
    '000281': { 'id': '000281', 'name': 'Hako110*', 'type': 'Floor 2 Worker', 'order': 282,'color': '#00FFFF'},
    '000282': { 'id': '000282', 'name': 'Hako121*', 'type': 'Floor 2 Worker', 'order': 283,'color': '#FF4500'},
    '000283': { 'id': '000283', 'name': 'Hako132*', 'type': 'Floor 2 Worker', 'order': 284,'color': '#F0E68C'},
    '000284': { 'id': '000284', 'name': 'Hako143*', 'type': 'Floor 2 Worker', 'order': 285,'color': '#FF69B4'},
    '000285': { 'id': '000285', 'name': 'Hako154*', 'type': 'Floor 2 Worker', 'order': 286,'color': '#F5DEB3'},
    '000286': { 'id': '000286', 'name': 'Hako165*', 'type': 'Floor 2 Worker', 'order': 287,'color': '#D3D3D3'},
    '000287': { 'id': '000287', 'name': 'Hako176*', 'type': 'Floor 2 Worker', 'order': 288,'color': '#C71585'},
    '000288': { 'id': '000288', 'name': 'Hako187*', 'type': 'Floor 2 Worker', 'order': 289,'color': '#DEB887'},
    '000289': { 'id': '000289', 'name': 'Hako198*', 'type': 'Floor 2 Worker', 'order': 290,'color': '#BA55D3'},
    '000290': { 'id': '000290', 'name': 'Hako209*', 'type': 'Floor 2 Worker', 'order': 291,'color': '#00008B'},
    '000400': {'id': '000400', 'name': 'Beginner pick400', 'type': 'Floor 2 Worker', 'order': 292, 'color': '#9F993D'},
    '000401': {'id': '000401', 'name': 'Beginner pick401', 'type': 'Floor 2 Worker', 'order': 293, 'color': '#036D8C'},
    '000402': {'id': '000402', 'name': 'Beginner pick402', 'type': 'Floor 2 Worker', 'order': 294, 'color': '#B2E7F9'},
    '000403': {'id': '000403', 'name': 'Beginner pick403', 'type': 'Floor 2 Worker', 'order': 295, 'color': '#FB729C'},
    '000404': {'id': '000404', 'name': 'Beginner pick404', 'type': 'Floor 2 Worker', 'order': 296, 'color': '#82EBEE'},
    '000405': {'id': '000405', 'name': 'Beginner pick405', 'type': 'Floor 2 Worker', 'order': 297, 'color': '#CD08FB'},
    '000406': {'id': '000406', 'name': 'Beginner pick406', 'type': 'Floor 2 Worker', 'order': 298, 'color': '#584E41'},
    '000407': {'id': '000407', 'name': 'Beginner pick407', 'type': 'Floor 2 Worker', 'order': 299, 'color': '#95EDF8'},
    '000408': {'id': '000408', 'name': 'Beginner pick408', 'type': 'Floor 2 Worker', 'order': 300, 'color': '#AF8B6F'},
    '000409': {'id': '000409', 'name': 'Beginner pick409', 'type': 'Floor 2 Worker', 'order': 301, 'color': '#58142D'},
    '000410': {'id': '000410', 'name': 'Beginner pick410', 'type': 'Floor 2 Worker', 'order': 302, 'color': '#966BFA'},
    '000411': {'id': '000411', 'name': 'Beginner pick411', 'type': 'Floor 2 Worker', 'order': 303, 'color': '#123A23'},
    '000412': {'id': '000412', 'name': 'Beginner pick412', 'type': 'Floor 2 Worker', 'order': 304, 'color': '#EA4924'},
    '000413': {'id': '000413', 'name': 'Beginner pick413', 'type': 'Floor 2 Worker', 'order': 305, 'color': '#1CACFD'},
    '000414': {'id': '000414', 'name': 'Beginner pick414', 'type': 'Floor 2 Worker', 'order': 306, 'color': '#9D66BE'},
    '000415': {'id': '000415', 'name': 'Beginner pick415', 'type': 'Floor 2 Worker', 'order': 307, 'color': '#3A31DD'},
    '000416': {'id': '000416', 'name': 'Beginner pick416', 'type': 'Floor 2 Worker', 'order': 308, 'color': '#4AD573'},
    '000417': {'id': '000417', 'name': 'Beginner pick417', 'type': 'Floor 2 Worker', 'order': 309, 'color': '#A21D15'},
    '000418': {'id': '000418', 'name': 'Beginner pick418', 'type': 'Floor 2 Worker', 'order': 310, 'color': '#D7A1DA'},
    '000419': {'id': '000419', 'name': 'Beginner pick419', 'type': 'Floor 2 Worker', 'order': 311, 'color': '#1625EB'},
    '000420': {'id': '000420', 'name': 'Beginner pick420', 'type': 'Floor 2 Worker', 'order': 312, 'color': '#4741A0'},
    '000421': {'id': '000421', 'name': 'Beginner pick421', 'type': 'Floor 2 Worker', 'order': 313, 'color': '#816354'},
    '000422': {'id': '000422', 'name': 'Beginner pick422', 'type': 'Floor 2 Worker', 'order': 314, 'color': '#476FCE'},
    '000423': {'id': '000423', 'name': 'Beginner pick423', 'type': 'Floor 2 Worker', 'order': 315, 'color': '#150793'},
    '000424': {'id': '000424', 'name': 'Beginner pick424', 'type': 'Floor 2 Worker', 'order': 316, 'color': '#B8FCCF'},
    '000425': {'id': '000425', 'name': 'Beginner pick425', 'type': 'Floor 2 Worker', 'order': 317, 'color': '#CC1155'},
    '000426': {'id': '000426', 'name': 'Beginner pick426', 'type': 'Floor 2 Worker', 'order': 318, 'color': '#F19277'},
    '000427': {'id': '000427', 'name': 'Beginner pick427', 'type': 'Floor 2 Worker', 'order': 319, 'color': '#81C7CE'},
    '000428': {'id': '000428', 'name': 'Beginner pick428', 'type': 'Floor 2 Worker', 'order': 320, 'color': '#055D5B'},
    '000429': {'id': '000429', 'name': 'Beginner pick429', 'type': 'Floor 2 Worker', 'order': 321, 'color': '#4F970E'},
    '000430': {'id': '000430', 'name': 'Beginner pick430', 'type': 'Floor 2 Worker', 'order': 322, 'color': '#4AC28E'},
  },
  "d954674dabd7411e812759b0edf0a4e5": { //ABInBevTulsa
    'EM61691361' :{ 'id': 'EM61691361', 'name': 'BANU, SALMA', 'type': 'Floor 1 Worker', 'order': 1,'color': '#E46B53'},
    'EM28710646' :{ 'id': 'EM28710646', 'name': 'Blackley, Justin', 'type': 'Floor 1 Worker', 'order': 2,'color': '#AD4D73'},
    'EM31622768' :{ 'id': 'EM31622768', 'name': 'Bolchoz, Nicholas', 'type': 'Floor 1 Worker', 'order': 3,'color': '#CF41AC'},
    'EM16898551' :{ 'id': 'EM16898551', 'name': 'BOT_OTC_EODRecon, BOT_OTC_EODRecon', 'type': 'Floor 1 Worker', 'order': 4,'color': '#566E7B'},
    'EM76338158' :{ 'id': 'EM76338158', 'name': 'Broyles, Thomas', 'type': 'Floor 1 Worker', 'order': 5,'color': '#7594EE'},
    '12345678' :{ 'id': '12345678', 'name': 'Caldwell, Eric', 'type': 'Floor 1 Worker', 'order': 6,'color': '#71D7A9'},
    'EM31804854' :{ 'id': 'EM31804854', 'name': 'Cartwright, Jeff', 'type': 'Floor 1 Worker', 'order': 7,'color': '#649A8B'},
    'EM87013094' :{ 'id': 'EM87013094', 'name': 'Cate, Blake', 'type': 'Floor 1 Worker', 'order': 8,'color': '#E9B923'},
    'EM73106141' :{ 'id': 'EM73106141', 'name': 'Catlin, Mike', 'type': 'Floor 1 Worker', 'order': 9,'color': '#BD5D18'},
    'EM16648401' :{ 'id': 'EM16648401', 'name': 'Cespedes, Sandra', 'type': 'Floor 1 Worker', 'order': 10,'color': '#638C61'},
    'EM87025283' :{ 'id': 'EM87025283', 'name': 'Chakali, Pushpa', 'type': 'Floor 1 Worker', 'order': 11,'color': '#4D3CFF'},
    'EM76274951' :{ 'id': 'EM76274951', 'name': 'Chavez, Brian', 'type': 'Floor 1 Worker', 'order': 12,'color': '#AE596B'},
    'EM86327470' :{ 'id': 'EM86327470', 'name': 'Clark, Christopher', 'type': 'Floor 1 Worker', 'order': 13,'color': '#6BC83B'},
    'EM98369508' :{ 'id': 'EM98369508', 'name': 'Collins, Riley', 'type': 'Floor 1 Worker', 'order': 14,'color': '#016D33'},
    'EM98970332' :{ 'id': 'EM98970332', 'name': 'Cook, Dakota', 'type': 'Floor 1 Worker', 'order': 15,'color': '#22AFC3'},
    'EM92197228' :{ 'id': 'EM92197228', 'name': 'Cooper, Peggy', 'type': 'Floor 1 Worker', 'order': 16,'color': '#AA31E4'},
    '00007591' :{ 'id': '00007591', 'name': 'Cowans, Dejuan', 'type': 'Floor 1 Worker', 'order': 17,'color': '#366AA6'},
    'EM84461727' :{ 'id': 'EM84461727', 'name': 'Croom, Carson', 'type': 'Floor 1 Worker', 'order': 18,'color': '#F99957'},
    'EM69664895' :{ 'id': 'EM69664895', 'name': 'Dutta, Sayan', 'type': 'Floor 1 Worker', 'order': 19,'color': '#B8B590'},
    'EM03520364' :{ 'id': 'EM03520364', 'name': 'Factory, Mark', 'type': 'Floor 1 Worker', 'order': 20,'color': '#A1AC50'},
    'EM00813860' :{ 'id': 'EM00813860', 'name': 'Feathers, Tom', 'type': 'Floor 1 Worker', 'order': 21,'color': '#27173C'},
    '10717406' :{ 'id': '10717406', 'name': 'Feathers, Tom', 'type': 'Floor 1 Worker', 'order': 22,'color': '#349E92'},
    'EM90258517' :{ 'id': 'EM90258517', 'name': 'Felder, Prince', 'type': 'Floor 1 Worker', 'order': 23,'color': '#0A61EE'},
    'EM47869591' :{ 'id': 'EM47869591', 'name': 'Foster, Max', 'type': 'Floor 1 Worker', 'order': 24,'color': '#5608BE'},
    'EM70431340' :{ 'id': 'EM70431340', 'name': 'Frejo, Sammy', 'type': 'Floor 1 Worker', 'order': 25,'color': '#1267A8'},
    'EM75906653' :{ 'id': 'EM75906653', 'name': 'Gass, Vincent', 'type': 'Floor 1 Worker', 'order': 26,'color': '#DA329B'},
    'EM32558693' :{ 'id': 'EM32558693', 'name': 'Grote, Dane', 'type': 'Floor 1 Worker', 'order': 27,'color': '#BDD293'},
    'EM20803852' :{ 'id': 'EM20803852', 'name': 'Halliday, Riley', 'type': 'Floor 1 Worker', 'order': 28,'color': '#10192E'},
    'EM47690743' :{ 'id': 'EM47690743', 'name': 'Hankins, Andrew', 'type': 'Floor 1 Worker', 'order': 29,'color': '#AC010E'},
    '10721026' :{ 'id': '10721026', 'name': 'Hankins, Andrew', 'type': 'Floor 1 Worker', 'order': 30,'color': '#5697CF'},
    'EM44358722' :{ 'id': 'EM44358722', 'name': 'Hellinghausen, Adam', 'type': 'Floor 1 Worker', 'order': 31,'color': '#B702E5'},
    'EM00673275' :{ 'id': 'EM00673275', 'name': 'Hollis, Christopher', 'type': 'Floor 1 Worker', 'order': 32,'color': '#1172A2'},
    'EM70276887' :{ 'id': 'EM70276887', 'name': 'Hutchison, Jacob', 'type': 'Floor 1 Worker', 'order': 33,'color': '#C1498C'},
    'EM23269559' :{ 'id': 'EM23269559', 'name': 'Janasik, Ryan', 'type': 'Floor 1 Worker', 'order': 34,'color': '#9C864A'},
    'EM19017024' :{ 'id': 'EM19017024', 'name': 'Jenner, Michael', 'type': 'Floor 1 Worker', 'order': 35,'color': '#30BDC4'},
    '10706931' :{ 'id': '10706931', 'name': 'Jimenez, Alex', 'type': 'Floor 1 Worker', 'order': 36,'color': '#1C1E40'},
    'EM08602572' :{ 'id': 'EM08602572', 'name': 'Jimenez, Jeffrey', 'type': 'Floor 1 Worker', 'order': 37,'color': '#0952A3'},
    'EM52739588' :{ 'id': 'EM52739588', 'name': 'Johnson, Dirk', 'type': 'Floor 1 Worker', 'order': 38,'color': '#6F5A09'},
    'EM01776614' :{ 'id': 'EM01776614', 'name': 'Jones, Paul', 'type': 'Floor 1 Worker', 'order': 39,'color': '#70105B'},
    'EM21877717' :{ 'id': 'EM21877717', 'name': 'Keamjou, Theodore', 'type': 'Floor 1 Worker', 'order': 40,'color': '#7E8FCC'},
    'EM59680961' :{ 'id': 'EM59680961', 'name': 'Kinder-Vasquez, Jessica', 'type': 'Floor 1 Worker', 'order': 41,'color': '#21357A'},
    '10839056' :{ 'id': '10839056', 'name': 'Koss, Dylan', 'type': 'Floor 1 Worker', 'order': 42,'color': '#F41823'},
    'EM58520729' :{ 'id': 'EM58520729', 'name': 'Krakow, Moranda', 'type': 'Floor 1 Worker', 'order': 43,'color': '#9C3A4F'},
    'EM97985672' :{ 'id': 'EM97985672', 'name': 'Leeds, Randy', 'type': 'Floor 1 Worker', 'order': 44,'color': '#5AF6AE'},
    'EM33758683' :{ 'id': 'EM33758683', 'name': 'Levine, Camden', 'type': 'Floor 1 Worker', 'order': 45,'color': '#42252F'},
    'EM61304569' :{ 'id': 'EM61304569', 'name': 'Lumpkin, Jeremy', 'type': 'Floor 1 Worker', 'order': 46,'color': '#F56EA4'},
    '10721305' :{ 'id': '10721305', 'name': 'Lumpkin, Jeremy', 'type': 'Floor 1 Worker', 'order': 47,'color': '#4BCF7F'},
    'EM64288356' :{ 'id': 'EM64288356', 'name': 'Mendoza, Ramio', 'type': 'Floor 1 Worker', 'order': 48,'color': '#CFA9A4'},
    'EM19837400' :{ 'id': 'EM19837400', 'name': 'Mohn, Jack', 'type': 'Floor 1 Worker', 'order': 49,'color': '#A13C90'},
    'EM33213818' :{ 'id': 'EM33213818', 'name': 'Munnisa, Mahi', 'type': 'Floor 1 Worker', 'order': 50,'color': '#263292'},
    'EM94805701' :{ 'id': 'EM94805701', 'name': 'Neidel, Corey', 'type': 'Floor 1 Worker', 'order': 51,'color': '#7186E6'},
    'EM97486640' :{ 'id': 'EM97486640', 'name': 'Nicholson II, Roger', 'type': 'Floor 1 Worker', 'order': 52,'color': '#449378'},
    'EM35306989' :{ 'id': 'EM35306989', 'name': 'Norber, Jordan', 'type': 'Floor 1 Worker', 'order': 53,'color': '#8153FF'},
    'EM59840077' :{ 'id': 'EM59840077', 'name': 'Odrowski, Brendan', 'type': 'Floor 1 Worker', 'order': 54,'color': '#6DE6AF'},
    'EM91341276' :{ 'id': 'EM91341276', 'name': 'Raglin, Bruce', 'type': 'Floor 1 Worker', 'order': 55,'color': '#ED30B5'},
    'EM76566763' :{ 'id': 'EM76566763', 'name': 'Ratermann, Vicki', 'type': 'Floor 1 Worker', 'order': 56,'color': '#652A99'},
    'EM42637557' :{ 'id': 'EM42637557', 'name': 'Rath, Tapas', 'type': 'Floor 1 Worker', 'order': 57,'color': '#143A64'},
    'EM85363534' :{ 'id': 'EM85363534', 'name': 'Redleaf, Keaton', 'type': 'Floor 1 Worker', 'order': 58,'color': '#AF5873'},
    'EM52343339' :{ 'id': 'EM52343339', 'name': 'Rennert, Jennifer', 'type': 'Floor 1 Worker', 'order': 59,'color': '#E28E9C'},
    'EM51059608' :{ 'id': 'EM51059608', 'name': 'Rice, Craig', 'type': 'Floor 1 Worker', 'order': 60,'color': '#E655F3'},
    'EM74017995' :{ 'id': 'EM74017995', 'name': 'Rodriguez, Augustine', 'type': 'Floor 1 Worker', 'order': 61,'color': '#85C451'},
    '10713741' :{ 'id': '10713741', 'name': 'Rodriguez, Gus', 'type': 'Floor 1 Worker', 'order': 62,'color': '#D999C0'},
    '10712552' :{ 'id': '10712552', 'name': 'RODRIGUEZ, JULIAN', 'type': 'Floor 1 Worker', 'order': 63,'color': '#0333B1'},
    'EM28384670' :{ 'id': 'EM28384670', 'name': 'Salman, Mohammed', 'type': 'Floor 1 Worker', 'order': 64,'color': '#6C763D'},
    'EM81917526' :{ 'id': 'EM81917526', 'name': 'Sanders, Roland', 'type': 'Floor 1 Worker', 'order': 65,'color': '#D50A6A'},
    'EM92968523' :{ 'id': 'EM92968523', 'name': 'shot10, hot', 'type': 'Floor 1 Worker', 'order': 66,'color': '#381BD4'},
    'EM94732856' :{ 'id': 'EM94732856', 'name': 'Shot2, Hot', 'type': 'Floor 1 Worker', 'order': 67,'color': '#67A557'},
    'EM89504837' :{ 'id': 'EM89504837', 'name': 'Shot3, Hot', 'type': 'Floor 1 Worker', 'order': 68,'color': '#B53B05'},
    'EM78497290' :{ 'id': 'EM78497290', 'name': 'shot4, hot', 'type': 'Floor 1 Worker', 'order': 69,'color': '#9AE040'},
    'EM36343793' :{ 'id': 'EM36343793', 'name': 'shot5, hot', 'type': 'Floor 1 Worker', 'order': 70,'color': '#6C4CDC'},
    'EM11427489' :{ 'id': 'EM11427489', 'name': 'shot6, hot', 'type': 'Floor 1 Worker', 'order': 71,'color': '#FE5A81'},
    'EM07867655' :{ 'id': 'EM07867655', 'name': 'shot7, hot', 'type': 'Floor 1 Worker', 'order': 72,'color': '#6A9A3D'},
    'EM47113004' :{ 'id': 'EM47113004', 'name': 'shot8, hot', 'type': 'Floor 1 Worker', 'order': 73,'color': '#746F15'},
    'EM46730019' :{ 'id': 'EM46730019', 'name': 'shot9, hot', 'type': 'Floor 1 Worker', 'order': 74,'color': '#81C2DE'},
    'EM42434086' :{ 'id': 'EM42434086', 'name': 'Smith, Daniel', 'type': 'Floor 1 Worker', 'order': 75,'color': '#6785EA'},
    'EM40283327' :{ 'id': 'EM40283327', 'name': 'SRV_NAZ_ATRWODP1, SRV_NAZ_ATRWODP1', 'type': 'Floor 1 Worker', 'order': 76,'color': '#469827'},
    'EM35393878' :{ 'id': 'EM35393878', 'name': 'SRV_NAZ_ATRWODP2, SRV_NAZ_ATRWODP2', 'type': 'Floor 1 Worker', 'order': 77,'color': '#8DF5E3'},
    'EM43344101' :{ 'id': 'EM43344101', 'name': 'SRV_NAZ_ATRWODP3, SRV_NAZ_ATRWODP3', 'type': 'Floor 1 Worker', 'order': 78,'color': '#C4075B'},
    'EM20427043' :{ 'id': 'EM20427043', 'name': 'Stanton, Kelli', 'type': 'Floor 1 Worker', 'order': 79,'color': '#D2A56C'},
    'EM10448356' :{ 'id': 'EM10448356', 'name': 'SteveVijayan, Tejas', 'type': 'Floor 1 Worker', 'order': 80,'color': '#9FAF6A'},
    'EM70703107' :{ 'id': 'EM70703107', 'name': 'Stuart, Dale', 'type': 'Floor 1 Worker', 'order': 81,'color': '#2214F0'},
    'EM00409646' :{ 'id': 'EM00409646', 'name': 'Stuck, Sandy', 'type': 'Floor 1 Worker', 'order': 82,'color': '#65E4A3'},
    'EM84082922' :{ 'id': 'EM84082922', 'name': 'Supple, Riley', 'type': 'Floor 1 Worker', 'order': 83,'color': '#9D55D8'},
    'EM14672473' :{ 'id': 'EM14672473', 'name': 'Sutton, Cathy', 'type': 'Floor 1 Worker', 'order': 84,'color': '#95566D'},
    'EM95454515' :{ 'id': 'EM95454515', 'name': 'Temple, Michael', 'type': 'Floor 1 Worker', 'order': 85,'color': '#5375EF'},
    'EM52532206' :{ 'id': 'EM52532206', 'name': 'Temple, Nick', 'type': 'Floor 1 Worker', 'order': 86,'color': '#50A9D5'},
    '10204070' :{ 'id': '10204070', 'name': 'TERRELL, WYLAN', 'type': 'Floor 1 Worker', 'order': 87,'color': '#37F5DF'},
    'EM13677804' :{ 'id': 'EM13677804', 'name': 'Terrell, Wylan', 'type': 'Floor 1 Worker', 'order': 88,'color': '#2053FC'},
    '00004825' :{ 'id': '00004825', 'name': 'Todd, Marquise', 'type': 'Floor 1 Worker', 'order': 89,'color': '#B938AF'},
    'EM26941688' :{ 'id': 'EM26941688', 'name': 'Trotta, Elizabeth', 'type': 'Floor 1 Worker', 'order': 90,'color': '#B8AE54'},
    'ABTULVIP' :{ 'id': 'ABTULVIP', 'name': 'Tul, AB', 'type': 'Floor 1 Worker', 'order': 91,'color': '#E188E0'},
    '10720554' :{ 'id': '10720554', 'name': 'Turner, Ryan', 'type': 'Floor 1 Worker', 'order': 92,'color': '#5615EA'},
    '10710957' :{ 'id': '10710957', 'name': 'WANTLAND, RUSSELL', 'type': 'Floor 1 Worker', 'order': 93,'color': '#46DB54'},
    'EM83220259' :{ 'id': 'EM83220259', 'name': 'Whitlatch-Crooks, Nellie', 'type': 'Floor 1 Worker', 'order': 94,'color': '#4A4F49'},
    'EM68788352' :{ 'id': 'EM68788352', 'name': 'WILLIAMS, CARLIS TONY', 'type': 'Floor 1 Worker', 'order': 95,'color': '#C5B676'},
    'EM41916531' :{ 'id': 'EM41916531', 'name': 'WOD Item, BOT_PTP', 'type': 'Floor 1 Worker', 'order': 96,'color': '#9F21C6'},
    'EM39140324' :{ 'id': 'EM39140324', 'name': 'Zavala, Alejandro', 'type': 'Floor 1 Worker', 'order': 97,'color': '#FA6D9D'},
    'EM93993196' :{ 'id': 'EM93993196', 'name': 'Abad, Mignon Anthonette', 'type': 'Floor 1 Worker', 'order': 98,'color': '#AF5A41'},
    'EM18585124' :{ 'id': 'EM18585124', 'name': 'ABNJASPUT, ABNJASPUT', 'type': 'Floor 1 Worker', 'order': 99,'color': '#492919'},
    'ABNJVIPU' :{ 'id': 'ABNJVIPU', 'name': 'ABNJVIPU, ABNJVIPU', 'type': 'Floor 1 Worker', 'order': 100,'color': '#D8B15C'},
    'EM81583985' :{ 'id': 'EM81583985', 'name': 'Acosta, Francis', 'type': 'Floor 1 Worker', 'order': 101,'color': '#D19EF6'},
    'EM85494955' :{ 'id': 'EM85494955', 'name': 'Afza, Ishrath', 'type': 'Floor 1 Worker', 'order': 102,'color': '#073150'},
    'EM44445802' :{ 'id': 'EM44445802', 'name': 'AG, SHASHIDHAR', 'type': 'Floor 1 Worker', 'order': 103,'color': '#7EE5D4'},
    'EM12777999' :{ 'id': 'EM12777999', 'name': 'Ahmed, Mushtaq', 'type': 'Floor 1 Worker', 'order': 104,'color': '#FF8272'},
    '10726222' :{ 'id': '10726222', 'name': 'Akin, Zak', 'type': 'Floor 1 Worker', 'order': 105,'color': '#F7D65A'},
    'Y70953' :{ 'id': 'Y70953', 'name': 'Anderson, Paul', 'type': 'Floor 1 Worker', 'order': 106,'color': '#F10935'},
    'EM60783938' :{ 'id': 'EM60783938', 'name': 'ANDERSON, PAUL', 'type': 'Floor 1 Worker', 'order': 107,'color': '#6E0D7A'},
    'EM10366567' :{ 'id': 'EM10366567', 'name': 'Andrews, Nikolas', 'type': 'Floor 1 Worker', 'order': 108,'color': '#2770FE'},
    'EM54199230' :{ 'id': 'EM54199230', 'name': 'ARMSTRONG, RICHARD', 'type': 'Floor 1 Worker', 'order': 109,'color': '#AC376E'},
    'EM16853726' :{ 'id': 'EM16853726', 'name': 'Aschenbrenner, Daniel', 'type': 'Floor 1 Worker', 'order': 110,'color': '#F9503E'},
    'EM18093147' :{ 'id': 'EM18093147', 'name': 'Baker, Brad', 'type': 'Floor 1 Worker', 'order': 111,'color': '#1ACFE9'},
    'EM20596815' :{ 'id': 'EM20596815', 'name': 'Balzuweit, Evan', 'type': 'Floor 1 Worker', 'order': 112,'color': '#B3CF56'},
    'EM69233776' :{ 'id': 'EM69233776', 'name': 'Bartlett, Chase', 'type': 'Floor 1 Worker', 'order': 113,'color': '#3E9FC1'},
    '305959' :{ 'id': '305959', 'name': 'Bartlett, Eric', 'type': 'Floor 1 Worker', 'order': 114,'color': '#06F8E5'},
    'EM83271692' :{ 'id': 'EM83271692', 'name': 'Basha, Shameer', 'type': 'Floor 1 Worker', 'order': 115,'color': '#BD4FD5'},
    'EM42649694' :{ 'id': 'EM42649694', 'name': 'BIERY IV, RAYMOND', 'type': 'Floor 1 Worker', 'order': 116,'color': '#301296'},
    'EM61424341' :{ 'id': 'EM61424341', 'name': 'Blevins, Kyle', 'type': 'Floor 1 Worker', 'order': 117,'color': '#426ED9'},
    'EM03476866' :{ 'id': 'EM03476866', 'name': 'Bradley, Young', 'type': 'Floor 1 Worker', 'order': 118,'color': '#7A2C39'},
    'Y304643' :{ 'id': 'Y304643', 'name': 'Brown, Brandon', 'type': 'Floor 1 Worker', 'order': 119,'color': '#84A3BA'},
    'EM69090149' :{ 'id': 'EM69090149', 'name': 'Burke, Kim', 'type': 'Floor 1 Worker', 'order': 120,'color': '#45C97C'},
    'EM94157507' :{ 'id': 'EM94157507', 'name': 'CARROTHERS, BRADLEY', 'type': 'Floor 1 Worker', 'order': 121,'color': '#27EB47'},
    'EM99019448' :{ 'id': 'EM99019448', 'name': 'Castillo, Ramiro', 'type': 'Floor 1 Worker', 'order': 122,'color': '#2DC92C'},
    'EM83929292' :{ 'id': 'EM83929292', 'name': 'Chapman, Matthew', 'type': 'Floor 1 Worker', 'order': 123,'color': '#3C444D'},
    'EM96884550' :{ 'id': 'EM96884550', 'name': 'Chavez, Matthew', 'type': 'Floor 1 Worker', 'order': 124,'color': '#28A12A'},
    'EM17455380' :{ 'id': 'EM17455380', 'name': 'Chavez, Zachery', 'type': 'Floor 1 Worker', 'order': 125,'color': '#65055C'},
    'EM07432482' :{ 'id': 'EM07432482', 'name': 'Chockalingam Swamynathan, Karthik', 'type': 'Floor 1 Worker', 'order': 126,'color': '#717BA6'},
    'EM07759553' :{ 'id': 'EM07759553', 'name': 'Chowdhury, Pia Dutta', 'type': 'Floor 1 Worker', 'order': 127,'color': '#074F32'},
    'EM71254774' :{ 'id': 'EM71254774', 'name': 'Chronister, Bev', 'type': 'Floor 1 Worker', 'order': 128,'color': '#4B8F78'},
    'EM17579250' :{ 'id': 'EM17579250', 'name': 'Clark, Deitre', 'type': 'Floor 1 Worker', 'order': 129,'color': '#785095'},
    'EM01690858' :{ 'id': 'EM01690858', 'name': 'Clemson, Christopher', 'type': 'Floor 1 Worker', 'order': 130,'color': '#7F7ADA'},
    'EM03353336' :{ 'id': 'EM03353336', 'name': 'Clinton, Courtney', 'type': 'Floor 1 Worker', 'order': 131,'color': '#C1D66C'},
    'EM46924474' :{ 'id': 'EM46924474', 'name': 'COLLECTPASTDUE_EFT, RPA AUTOMATION', 'type': 'Floor 1 Worker', 'order': 132,'color': '#BACB06'},
    'EM52898084' :{ 'id': 'EM52898084', 'name': 'CollectPastDueEFT, RPA0028', 'type': 'Floor 1 Worker', 'order': 133,'color': '#90B1F6'},
    'EM14511665' :{ 'id': 'EM14511665', 'name': 'Collmann, Zachary', 'type': 'Floor 1 Worker', 'order': 134,'color': '#0BCA55'},
    'EM75204502' :{ 'id': 'EM75204502', 'name': 'Comaid, Elvira', 'type': 'Floor 1 Worker', 'order': 135,'color': '#AD32BD'},
    'EM53181320' :{ 'id': 'EM53181320', 'name': 'Coogan, Shane', 'type': 'Floor 1 Worker', 'order': 136,'color': '#DDCB14'},
    'EM40912656' :{ 'id': 'EM40912656', 'name': 'Crews, Nolen', 'type': 'Floor 1 Worker', 'order': 137,'color': '#1B65E2'},
    'EM89127957' :{ 'id': 'EM89127957', 'name': 'Crews, Nolen', 'type': 'Floor 1 Worker', 'order': 138,'color': '#BB99A8'},
    'EM68946591' :{ 'id': 'EM68946591', 'name': 'Criswell, Carol', 'type': 'Floor 1 Worker', 'order': 139,'color': '#714D90'},
    '701079' :{ 'id': '701079', 'name': 'Crothers, Casey', 'type': 'Floor 1 Worker', 'order': 140,'color': '#A8C535'},
    'EM85886840' :{ 'id': 'EM85886840', 'name': 'Dailey, Alex', 'type': 'Floor 1 Worker', 'order': 141,'color': '#6A3D1B'},
    'EM95056053' :{ 'id': 'EM95056053', 'name': 'Dailey, Katherine', 'type': 'Floor 1 Worker', 'order': 142,'color': '#1A8548'},
    'EM16471587' :{ 'id': 'EM16471587', 'name': 'Dan, Donnovan', 'type': 'Floor 1 Worker', 'order': 143,'color': '#8BF2CA'},
    'EM02350686' :{ 'id': 'EM02350686', 'name': 'Davidson, Bruce', 'type': 'Floor 1 Worker', 'order': 144,'color': '#F4A630'},
    'EM52730101' :{ 'id': 'EM52730101', 'name': 'Degenhardt, Jacob', 'type': 'Floor 1 Worker', 'order': 145,'color': '#1F9615'},
    'Y925438' :{ 'id': 'Y925438', 'name': 'Dezurik, Gunter', 'type': 'Floor 1 Worker', 'order': 146,'color': '#5AC2C3'},
    '305975' :{ 'id': '305975', 'name': 'Dickerson, Ricky', 'type': 'Floor 1 Worker', 'order': 147,'color': '#9984AB'},
    '836118' :{ 'id': '836118', 'name': 'Douglas, Ricky', 'type': 'Floor 1 Worker', 'order': 148,'color': '#5F9FB5'},
    'EM81354582' :{ 'id': 'EM81354582', 'name': 'Fancher, Michael', 'type': 'Floor 1 Worker', 'order': 149,'color': '#AEE6FC'},
    '10703289' :{ 'id': '10703289', 'name': 'Fielding, Matthew', 'type': 'Floor 1 Worker', 'order': 150,'color': '#48E3E8'},
    'EM19213473' :{ 'id': 'EM19213473', 'name': 'Flecke, Matthew', 'type': 'Floor 1 Worker', 'order': 151,'color': '#9B4AC1'},
    '700237' :{ 'id': '700237', 'name': 'Ford, Taylor', 'type': 'Floor 1 Worker', 'order': 152,'color': '#F66A2D'},
    'EM06794988' :{ 'id': 'EM06794988', 'name': 'Ford, Tyler', 'type': 'Floor 1 Worker', 'order': 153,'color': '#A7A973'},
    'EM19677407' :{ 'id': 'EM19677407', 'name': 'FOUST, CODY', 'type': 'Floor 1 Worker', 'order': 154,'color': '#3422BC'},
    'EM21515015' :{ 'id': 'EM21515015', 'name': 'Frasca, Albert', 'type': 'Floor 1 Worker', 'order': 155,'color': '#3CFC87'},
    'EM86068102' :{ 'id': 'EM86068102', 'name': 'Geiler, Julia', 'type': 'Floor 1 Worker', 'order': 156,'color': '#9DD9E2'},
    'EM92409772' :{ 'id': 'EM92409772', 'name': 'Ghataparthy, Ashray', 'type': 'Floor 1 Worker', 'order': 157,'color': '#C133F3'},
    'EM16291446' :{ 'id': 'EM16291446', 'name': 'Gilmore, Margaret', 'type': 'Floor 1 Worker', 'order': 158,'color': '#79B650'},
    'EM73845710' :{ 'id': 'EM73845710', 'name': 'Gobbilla, Hareesh', 'type': 'Floor 1 Worker', 'order': 159,'color': '#BE8E40'},
    '10718128' :{ 'id': '10718128', 'name': 'Gossage, Adam', 'type': 'Floor 1 Worker', 'order': 160,'color': '#C6724F'},
    'EM31058585' :{ 'id': 'EM31058585', 'name': 'Gouda, Tarun', 'type': 'Floor 1 Worker', 'order': 161,'color': '#339F4A'},
    'EM75240703' :{ 'id': 'EM75240703', 'name': 'GRITZ, CHAD', 'type': 'Floor 1 Worker', 'order': 162,'color': '#D8A37F'},
    'EM13073206' :{ 'id': 'EM13073206', 'name': 'Gupta, Rashi', 'type': 'Floor 1 Worker', 'order': 163,'color': '#F89B80'},
    'EM51175059' :{ 'id': 'EM51175059', 'name': 'Halde, Lauren', 'type': 'Floor 1 Worker', 'order': 164,'color': '#A1C9C7'},
    'EM98322482' :{ 'id': 'EM98322482', 'name': 'Hargis, Jennifer', 'type': 'Floor 1 Worker', 'order': 165,'color': '#D035F0'},
    'EM07898405' :{ 'id': 'EM07898405', 'name': 'Harris, Sue', 'type': 'Floor 1 Worker', 'order': 166,'color': '#0EA064'},
    'EM41907966' :{ 'id': 'EM41907966', 'name': 'Hernandez, Suzanne', 'type': 'Floor 1 Worker', 'order': 167,'color': '#2D1519'},
    '10837669' :{ 'id': '10837669', 'name': 'Hibdon, Weston', 'type': 'Floor 1 Worker', 'order': 168,'color': '#2B83ED'},
    'EM93378413' :{ 'id': 'EM93378413', 'name': 'Hicks, Adam', 'type': 'Floor 1 Worker', 'order': 169,'color': '#55F71C'},
    '10725762' :{ 'id': '10725762', 'name': 'Hills, Jeff', 'type': 'Floor 1 Worker', 'order': 170,'color': '#D7875F'},
    'EM24085885' :{ 'id': 'EM24085885', 'name': 'Holley, Tracey', 'type': 'Floor 1 Worker', 'order': 171,'color': '#8CC6B1'},
    'EM73503427' :{ 'id': 'EM73503427', 'name': 'Holzer, Stephanie', 'type': 'Floor 1 Worker', 'order': 172,'color': '#4EEAEB'},
    'EM89992807' :{ 'id': 'EM89992807', 'name': 'Hook, Payton', 'type': 'Floor 1 Worker', 'order': 173,'color': '#D92AA4'},
    '10719802' :{ 'id': '10719802', 'name': 'Horton, Brian', 'type': 'Floor 1 Worker', 'order': 174,'color': '#3B89F0'},
    'EM29862054' :{ 'id': 'EM29862054', 'name': 'House, Jacob', 'type': 'Floor 1 Worker', 'order': 175,'color': '#7F6CCD'},
    'EM91657887' :{ 'id': 'EM91657887', 'name': 'Hunter, Tylar', 'type': 'Floor 1 Worker', 'order': 176,'color': '#3F4586'},
    'EM43668752' :{ 'id': 'EM43668752', 'name': 'Ignalaga, Rey Kent', 'type': 'Floor 1 Worker', 'order': 177,'color': '#936D9F'},
    'EM34193860' :{ 'id': 'EM34193860', 'name': 'Ilagan, Mary Joy', 'type': 'Floor 1 Worker', 'order': 178,'color': '#88A2C4'},
    'EM82120605' :{ 'id': 'EM82120605', 'name': 'Jackson, Bridget', 'type': 'Floor 1 Worker', 'order': 179,'color': '#962138'},
    'EM42460209' :{ 'id': 'EM42460209', 'name': 'Jacob, Daniel', 'type': 'Floor 1 Worker', 'order': 180,'color': '#AA951C'},
    'EM53887821' :{ 'id': 'EM53887821', 'name': 'Jagadeesh, Shruthi', 'type': 'Floor 1 Worker', 'order': 181,'color': '#A9AEBE'},
    'EM73045476' :{ 'id': 'EM73045476', 'name': 'JOHNSON, ADAM', 'type': 'Floor 1 Worker', 'order': 182,'color': '#3605BB'},
    'EM65568951' :{ 'id': 'EM65568951', 'name': 'Johnson, Sam', 'type': 'Floor 1 Worker', 'order': 183,'color': '#0E7035'},
    'EM29867926' :{ 'id': 'EM29867926', 'name': 'Jones, Becky', 'type': 'Floor 1 Worker', 'order': 184,'color': '#E78C98'},
    'EM48779022' :{ 'id': 'EM48779022', 'name': 'JORDAN, WOD JESSICA', 'type': 'Floor 1 Worker', 'order': 185,'color': '#BD85CE'},
    'EM84163104' :{ 'id': 'EM84163104', 'name': 'Junio, Juan Carlo', 'type': 'Floor 1 Worker', 'order': 186,'color': '#D662F4'},
    'EM03440428' :{ 'id': 'EM03440428', 'name': 'Jurkiewicz, Jack', 'type': 'Floor 1 Worker', 'order': 187,'color': '#6266C5'},
    'EM17327254' :{ 'id': 'EM17327254', 'name': 'Kakoti, Jyotikamal', 'type': 'Floor 1 Worker', 'order': 188,'color': '#915DB7'},
    'EM72995042' :{ 'id': 'EM72995042', 'name': 'Kanabur, Swaraj', 'type': 'Floor 1 Worker', 'order': 189,'color': '#03615B'},
    'EM64139397' :{ 'id': 'EM64139397', 'name': 'KANCHAN, Pavithra', 'type': 'Floor 1 Worker', 'order': 190,'color': '#58B82E'},
    'EM46814620' :{ 'id': 'EM46814620', 'name': 'Kandasamy, Ragavarthini', 'type': 'Floor 1 Worker', 'order': 191,'color': '#FEE8E9'},
    '721037' :{ 'id': '721037', 'name': 'Kang, Frank', 'type': 'Floor 1 Worker', 'order': 192,'color': '#D615E8'},
    'EM58548517' :{ 'id': 'EM58548517', 'name': 'Kendrick, Jamie', 'type': 'Floor 1 Worker', 'order': 193,'color': '#BD478D'},
    '012345' :{ 'id': '012345', 'name': 'Kennedy, Steven', 'type': 'Floor 1 Worker', 'order': 194,'color': '#4B538C'},
    'EM12530470' :{ 'id': 'EM12530470', 'name': 'Kessler, Peter', 'type': 'Floor 1 Worker', 'order': 195,'color': '#95B9CB'},
    'EM35253956' :{ 'id': 'EM35253956', 'name': 'Khanolkar, Sunil', 'type': 'Floor 1 Worker', 'order': 196,'color': '#331FDE'},
    'EM50772866' :{ 'id': 'EM50772866', 'name': 'Killin, Chelsea', 'type': 'Floor 1 Worker', 'order': 197,'color': '#7FA189'},
    'EM87205091' :{ 'id': 'EM87205091', 'name': 'KK, Khaleen', 'type': 'Floor 1 Worker', 'order': 198,'color': '#FFEA5C'},
    'EM01654276' :{ 'id': 'EM01654276', 'name': 'Kumar, Dinesh', 'type': 'Floor 1 Worker', 'order': 199,'color': '#A5720C'},
    'EM45370139' :{ 'id': 'EM45370139', 'name': 'Kumar, Nitin', 'type': 'Floor 1 Worker', 'order': 200,'color': '#1370D6'},
    'EM73452549' :{ 'id': 'EM73452549', 'name': 'Kumar U, Anand', 'type': 'Floor 1 Worker', 'order': 201,'color': '#83BA64'},
    'EM61715967' :{ 'id': 'EM61715967', 'name': 'Lam, David', 'type': 'Floor 1 Worker', 'order': 202,'color': '#67A754'},
    'EM50415843' :{ 'id': 'EM50415843', 'name': 'Larkin, William', 'type': 'Floor 1 Worker', 'order': 203,'color': '#1FA133'},
    'EM16230475' :{ 'id': 'EM16230475', 'name': 'Lawrence, Ryan', 'type': 'Floor 1 Worker', 'order': 204,'color': '#0CC9CA'},
    'EM70317229' :{ 'id': 'EM70317229', 'name': 'Leeman, Derek', 'type': 'Floor 1 Worker', 'order': 205,'color': '#E41CC4'},
    'EM23224884' :{ 'id': 'EM23224884', 'name': 'Lester, Megan', 'type': 'Floor 1 Worker', 'order': 206,'color': '#9C680B'},
    'EM23964922' :{ 'id': 'EM23964922', 'name': 'Letcher, Michael', 'type': 'Floor 1 Worker', 'order': 207,'color': '#B5482A'},
    'EM78280027' :{ 'id': 'EM78280027', 'name': 'LeValley, Anthony', 'type': 'Floor 1 Worker', 'order': 208,'color': '#28375A'},
    'EM50474627' :{ 'id': 'EM50474627', 'name': 'Luo, Qihong', 'type': 'Floor 1 Worker', 'order': 209,'color': '#9CA6A4'},
    'EM51507234' :{ 'id': 'EM51507234', 'name': 'Macayaon, Mary Carolyn', 'type': 'Floor 1 Worker', 'order': 210,'color': '#AA05BC'},
    'EM34109765' :{ 'id': 'EM34109765', 'name': 'Manlabus, Milo Jr', 'type': 'Floor 1 Worker', 'order': 211,'color': '#D8F28A'},
    'EM80571792' :{ 'id': 'EM80571792', 'name': 'MARTINEZ-SANCHEZ, ARTURO', 'type': 'Floor 1 Worker', 'order': 212,'color': '#8F3647'},
    'EM13137662' :{ 'id': 'EM13137662', 'name': 'Mary, Emily', 'type': 'Floor 1 Worker', 'order': 213,'color': '#2B3998'},
    'EM08148023' :{ 'id': 'EM08148023', 'name': 'Maslak, Terra', 'type': 'Floor 1 Worker', 'order': 214,'color': '#54F466'},
    'EM27435056' :{ 'id': 'EM27435056', 'name': 'Mazhi, Amal Kumar', 'type': 'Floor 1 Worker', 'order': 215,'color': '#30CB3B'},
    'EM95747238' :{ 'id': 'EM95747238', 'name': 'MCCABE, TRENTON', 'type': 'Floor 1 Worker', 'order': 216,'color': '#7BC4D6'},
    'EM24394074' :{ 'id': 'EM24394074', 'name': 'Meadows, Shaun', 'type': 'Floor 1 Worker', 'order': 217,'color': '#0694A6'},
    'EM51330697' :{ 'id': 'EM51330697', 'name': 'Meiners, Katie', 'type': 'Floor 1 Worker', 'order': 218,'color': '#521142'},
    'EM84170732' :{ 'id': 'EM84170732', 'name': 'Mesnier, Joshua', 'type': 'Floor 1 Worker', 'order': 219,'color': '#57B09C'},
    '10723687' :{ 'id': '10723687', 'name': 'Michael, Michael', 'type': 'Floor 1 Worker', 'order': 220,'color': '#5AEB58'},
    'EM63386320' :{ 'id': 'EM63386320', 'name': 'Miles, Boyd', 'type': 'Floor 1 Worker', 'order': 221,'color': '#7C2B04'},
    'EM78057658' :{ 'id': 'EM78057658', 'name': 'Mogol, Ronald Tom', 'type': 'Floor 1 Worker', 'order': 222,'color': '#8B699A'},
    'EM80918699' :{ 'id': 'EM80918699', 'name': 'MOLLY, MARY', 'type': 'Floor 1 Worker', 'order': 223,'color': '#D2D3B2'},
    'Y717691' :{ 'id': 'Y717691', 'name': 'Moreland, Matt', 'type': 'Floor 1 Worker', 'order': 224,'color': '#532795'},
    'EM82389765' :{ 'id': 'EM82389765', 'name': 'MV, Yathirajan', 'type': 'Floor 1 Worker', 'order': 225,'color': '#65140A'},
    'EM80863361' :{ 'id': 'EM80863361', 'name': 'Naidu, Karthik', 'type': 'Floor 1 Worker', 'order': 226,'color': '#2AA9A8'},
    'EM85042143' :{ 'id': 'EM85042143', 'name': 'Nakro, Hukhonelu', 'type': 'Floor 1 Worker', 'order': 227,'color': '#291311'},
    'EM43820119' :{ 'id': 'EM43820119', 'name': 'Noel, Jose Eduardo', 'type': 'Floor 1 Worker', 'order': 228,'color': '#716E30'},
    'EM62813558' :{ 'id': 'EM62813558', 'name': 'Oliveros-Garcia, Zuleima', 'type': 'Floor 1 Worker', 'order': 229,'color': '#966CD8'},
    'EM12700684' :{ 'id': 'EM12700684', 'name': 'Patil, Ravichandra', 'type': 'Floor 1 Worker', 'order': 230,'color': '#032662'},
    'EM64474268' :{ 'id': 'EM64474268', 'name': 'PDAMBOT, PDAMBOT', 'type': 'Floor 1 Worker', 'order': 231,'color': '#F055F3'},
    '722898' :{ 'id': '722898', 'name': 'Peterson, Zeldon', 'type': 'Floor 1 Worker', 'order': 232,'color': '#FFA943'},
    'EM26039127' :{ 'id': 'EM26039127', 'name': 'Poothodi, Prashobh', 'type': 'Floor 1 Worker', 'order': 233,'color': '#A8F996'},
    'EM55076490' :{ 'id': 'EM55076490', 'name': 'Pyatt, Andrew', 'type': 'Floor 1 Worker', 'order': 234,'color': '#684235'},
    'EM43468886' :{ 'id': 'EM43468886', 'name': 'Raj, Raghavendra', 'type': 'Floor 1 Worker', 'order': 235,'color': '#D5E725'},
    'EM67393022' :{ 'id': 'EM67393022', 'name': 'Ramirez, Gary', 'type': 'Floor 1 Worker', 'order': 236,'color': '#2FA22D'},
    'EM00166388' :{ 'id': 'EM00166388', 'name': 'Rees, Colleen', 'type': 'Floor 1 Worker', 'order': 237,'color': '#118BE3'},
    'EM55145376' :{ 'id': 'EM55145376', 'name': 'Reiss, Drew', 'type': 'Floor 1 Worker', 'order': 238,'color': '#09C762'},
    'RFSSHAMBLI' :{ 'id': 'RFSSHAMBLI', 'name': 'RFSSHAMBLI, RFSSHAMBLI', 'type': 'Floor 1 Worker', 'order': 239,'color': '#85551C'},
    'EM83130566' :{ 'id': 'EM83130566', 'name': 'ROBBINS, JERAMEY', 'type': 'Floor 1 Worker', 'order': 240,'color': '#53659D'},
    'EM42158908' :{ 'id': 'EM42158908', 'name': 'Roberts, Stephen', 'type': 'Floor 1 Worker', 'order': 241,'color': '#2B024C'},
    'MIKE S' :{ 'id': 'MIKE S', 'name': 'S, Mike', 'type': 'Floor 1 Worker', 'order': 242,'color': '#59D422'},
    'EM14061333' :{ 'id': 'EM14061333', 'name': 'S, Murugesan', 'type': 'Floor 1 Worker', 'order': 243,'color': '#6C5809'},
    'EM72613884' :{ 'id': 'EM72613884', 'name': 'Samson, Fidel III', 'type': 'Floor 1 Worker', 'order': 244,'color': '#5D6A58'},
    'EM71175280' :{ 'id': 'EM71175280', 'name': 'Sanders, Chris', 'type': 'Floor 1 Worker', 'order': 245,'color': '#BF3B5F'},
    'EM38009441' :{ 'id': 'EM38009441', 'name': 'SANDS, PATSY', 'type': 'Floor 1 Worker', 'order': 246,'color': '#52865B'},
    'EM17942264' :{ 'id': 'EM17942264', 'name': 'Santos, Shayne Anne', 'type': 'Floor 1 Worker', 'order': 247,'color': '#0C2254'},
    'EM78142662' :{ 'id': 'EM78142662', 'name': 'Schwieger, Tori', 'type': 'Floor 1 Worker', 'order': 248,'color': '#791E7C'},
    'EM19953208' :{ 'id': 'EM19953208', 'name': 'Scott, Michael', 'type': 'Floor 1 Worker', 'order': 249,'color': '#F8E57B'},
    'EM10979097' :{ 'id': 'EM10979097', 'name': 'Shalen, Talia', 'type': 'Floor 1 Worker', 'order': 250,'color': '#77B942'},
    'EM29501021' :{ 'id': 'EM29501021', 'name': 'Shanmugam, Vinmathi', 'type': 'Floor 1 Worker', 'order': 251,'color': '#F80A91'},
    'EM21738486' :{ 'id': 'EM21738486', 'name': 'SHEID, STAN', 'type': 'Floor 1 Worker', 'order': 252,'color': '#F5D017'},
    'EM56192525' :{ 'id': 'EM56192525', 'name': 'Shot, Hot', 'type': 'Floor 1 Worker', 'order': 253,'color': '#8A4C97'},
    'EM71991898' :{ 'id': 'EM71991898', 'name': 'SHROYER, RYAN', 'type': 'Floor 1 Worker', 'order': 254,'color': '#117B11'},
    'EM45341336' :{ 'id': 'EM45341336', 'name': 'Sims, Darrin', 'type': 'Floor 1 Worker', 'order': 255,'color': '#9302BB'},
    'EM95504314' :{ 'id': 'EM95504314', 'name': 'Sisemore, Drew', 'type': 'Floor 1 Worker', 'order': 256,'color': '#506700'},
    'EM03921509' :{ 'id': 'EM03921509', 'name': 'Sivamani, Karthick', 'type': 'Floor 1 Worker', 'order': 257,'color': '#C645A5'},
    'EM45831619' :{ 'id': 'EM45831619', 'name': 'Smith, Douglas', 'type': 'Floor 1 Worker', 'order': 258,'color': '#D633C7'},
    'EM15158667' :{ 'id': 'EM15158667', 'name': 'Smith, Jacob', 'type': 'Floor 1 Worker', 'order': 259,'color': '#4338AE'},
    '2960' :{ 'id': '2960', 'name': 'Smith, Jacob', 'type': 'Floor 1 Worker', 'order': 260,'color': '#722697'},
    'EM06300417' :{ 'id': 'EM06300417', 'name': 'Sparks, Susan', 'type': 'Floor 1 Worker', 'order': 261,'color': '#06DD2E'},
    '10718118' :{ 'id': '10718118', 'name': 'speedy, Fast n Furious', 'type': 'Floor 1 Worker', 'order': 262,'color': '#E128C2'},
    'EM81755004' :{ 'id': 'EM81755004', 'name': 'Stephen, Dallas', 'type': 'Floor 1 Worker', 'order': 263,'color': '#46619C'},
    'EM39229912' :{ 'id': 'EM39229912', 'name': 'STEPP, LOGAN', 'type': 'Floor 1 Worker', 'order': 264,'color': '#7348D2'},
    '00305996' :{ 'id': '00305996', 'name': 'Stewart, Daryl', 'type': 'Floor 1 Worker', 'order': 265,'color': '#94D509'},
    '10725982' :{ 'id': '10725982', 'name': 'Suarez, Luis', 'type': 'Floor 1 Worker', 'order': 266,'color': '#266CD8'},
    'EM87222005' :{ 'id': 'EM87222005', 'name': 'Subramani, Prabhakaran', 'type': 'Floor 1 Worker', 'order': 267,'color': '#13F96F'},
    'EM31224335' :{ 'id': 'EM31224335', 'name': 'SURUVU, LAKSHMI', 'type': 'Floor 1 Worker', 'order': 268,'color': '#177F2F'},
    'EM39135297' :{ 'id': 'EM39135297', 'name': 'Swingler, Brock', 'type': 'Floor 1 Worker', 'order': 269,'color': '#B52C54'},
    'EM60585384' :{ 'id': 'EM60585384', 'name': 'Talukdar, Angshuman', 'type': 'Floor 1 Worker', 'order': 270,'color': '#B15849'},
    'EM28933746' :{ 'id': 'EM28933746', 'name': 'Thompson, Angela', 'type': 'Floor 1 Worker', 'order': 271,'color': '#E077C3'},
    'EM28432817' :{ 'id': 'EM28432817', 'name': 'THORNBRUE, KYLE', 'type': 'Floor 1 Worker', 'order': 272,'color': '#1F6234'},
    '10838650' :{ 'id': '10838650', 'name': 'Tiffany, George', 'type': 'Floor 1 Worker', 'order': 273,'color': '#2CBFAB'},
    'EM63304583' :{ 'id': 'EM63304583', 'name': 'Vadrevu, GopiKrishna', 'type': 'Floor 1 Worker', 'order': 277,'color': '#EA9B55'},
    '722381' :{ 'id': '722381', 'name': 'Valadez, Freddy', 'type': 'Floor 1 Worker', 'order': 278,'color': '#29E315'},
    'EM64922008' :{ 'id': 'EM64922008', 'name': 'Vase, Jennifer', 'type': 'Floor 1 Worker', 'order': 279,'color': '#3F7281'},
    'EM34048824' :{ 'id': 'EM34048824', 'name': 'Vavera, Shane', 'type': 'Floor 1 Worker', 'order': 280,'color': '#01E493'},
    'EM66693104' :{ 'id': 'EM66693104', 'name': 'Venkataramana, Sreeramalu', 'type': 'Floor 1 Worker', 'order': 281,'color': '#DF12DE'},
    'EM72312118' :{ 'id': 'EM72312118', 'name': 'VENKATESH, YAMUNA', 'type': 'Floor 1 Worker', 'order': 282,'color': '#3E4680'},
    'EM41525967' :{ 'id': 'EM41525967', 'name': 'Verma, Gaurav', 'type': 'Floor 1 Worker', 'order': 283,'color': '#A41E62'},
    'EM78137950' :{ 'id': 'EM78137950', 'name': 'Villanueva, Ray', 'type': 'Floor 1 Worker', 'order': 284,'color': '#8E6030'},
    'EM60433690' :{ 'id': 'EM60433690', 'name': 'Visintine, Mike', 'type': 'Floor 1 Worker', 'order': 285,'color': '#5BF8FA'},
    'EM22398245' :{ 'id': 'EM22398245', 'name': 'VRao, Jyothi', 'type': 'Floor 1 Worker', 'order': 286,'color': '#03B243'},
    'EM74882208' :{ 'id': 'EM74882208', 'name': 'VS, Shruthi', 'type': 'Floor 1 Worker', 'order': 287,'color': '#1DB6CB'},
    'EM64133626' :{ 'id': 'EM64133626', 'name': 'Wallace, William', 'type': 'Floor 1 Worker', 'order': 288,'color': '#C3AF74'},
    'EM87396418' :{ 'id': 'EM87396418', 'name': 'Wallis, Matt', 'type': 'Floor 1 Worker', 'order': 289,'color': '#F2FF2A'},
    'EM29852284' :{ 'id': 'EM29852284', 'name': 'Wasser, Hunter', 'type': 'Floor 1 Worker', 'order': 290,'color': '#2F1CAA'},
    'EM67651083' :{ 'id': 'EM67651083', 'name': 'Weicher, Joe', 'type': 'Floor 1 Worker', 'order': 291,'color': '#A817B2'},
    'EM31896892' :{ 'id': 'EM31896892', 'name': 'Weller, Patricia', 'type': 'Floor 1 Worker', 'order': 292,'color': '#35E24A'},
    'EM63109798' :{ 'id': 'EM63109798', 'name': 'Westbrook, Hunter', 'type': 'Floor 1 Worker', 'order': 293,'color': '#894DFF'},
    '723032' :{ 'id': '723032', 'name': 'Wheeler, Daytona', 'type': 'Floor 1 Worker', 'order': 294,'color': '#441178'},
    'EM39625344' :{ 'id': 'EM39625344', 'name': 'Wilson, Veronica', 'type': 'Floor 1 Worker', 'order': 295,'color': '#748098'},
    'EM20769165' :{ 'id': 'EM20769165', 'name': 'Wilzoch, Devin', 'type': 'Floor 1 Worker', 'order': 296,'color': '#2F57DB'},
    'EM92212091' :{ 'id': 'EM92212091', 'name': 'WOD CASH RECON, WOD', 'type': 'Floor 1 Worker', 'order': 297,'color': '#6584CD'},
    'EM00779327' :{ 'id': 'EM00779327', 'name': 'Wolf, Steven', 'type': 'Floor 1 Worker', 'order': 298,'color': '#08CDEE'},
    'EM70543162' :{ 'id': 'EM70543162', 'name': 'Wright, Vicky', 'type': 'Floor 1 Worker', 'order': 299,'color': '#B5E081'},
    'EM93169184' :{ 'id': 'EM93169184', 'name': 'Wyatt, Allen', 'type': 'Floor 1 Worker', 'order': 300,'color': '#5629E9'},
    'Y305953' :{ 'id': 'Y305953', 'name': 'Y305953, Y305953', 'type': 'Floor 1 Worker', 'order': 301,'color': '#85DC3F'},
    'EM95377232' :{ 'id': 'EM95377232', 'name': 'Yap, John Lester', 'type': 'Floor 1 Worker', 'order': 302,'color': '#5EE441'},
    'EM80543887' :{ 'id': 'EM80543887', 'name': 'Yeager, Kathryn', 'type': 'Floor 1 Worker', 'order': 303,'color': '#80D4CD'},
    'ABTULASP' :{ 'id': 'ABTULASP', 'name': 'ABTULASP, ABTULASP', 'type': 'Floor 1 Worker', 'order': 313,'color': '#A99317'},
    'EM83259551' :{ 'id': 'EM83259551', 'name': 'Adams, Chris', 'type': 'Floor 1 Worker', 'order': 314,'color': '#9368CB'},
    'EM49689093' :{ 'id': 'EM49689093', 'name': 'Agarwal, Pooja', 'type': 'Floor 1 Worker', 'order': 315,'color': '#FB9E0D'},
    'EM23428188' :{ 'id': 'EM23428188', 'name': 'Andal, Dialyn', 'type': 'Floor 1 Worker', 'order': 316,'color': '#C8AD2E'},
    'EM45536276' :{ 'id': 'EM45536276', 'name': 'aradhya, sathwik', 'type': 'Floor 1 Worker', 'order': 317,'color': '#666D1C'},
    'EM11626821' :{ 'id': 'EM11626821', 'name': 'Atkinson, Catherine', 'type': 'Floor 1 Worker', 'order': 318,'color': '#713741'},
    'EM97487236' :{ 'id': 'EM97487236', 'name': 'ATRWODPRTBOT, ATRWODPRTBOT', 'type': 'Floor 1 Worker', 'order': 319,'color': '#30B58D'},
    'EM49595775' :{ 'id': 'EM49595775', 'name': 'AUTOMATION, RPA', 'type': 'Floor 1 Worker', 'order': 320,'color': '#A46E3F'},
    'EM16669961' :{ 'id': 'EM16669961', 'name': 'B, Swathi', 'type': 'Floor 1 Worker', 'order': 321,'color': '#64A06A'},
    'EM11833461' :{ 'id': 'EM11833461', 'name': 'Banerjee, Medha', 'type': 'Floor 1 Worker', 'order': 322,'color': '#7D2917'},
    'EM03929241' :{ 'id': 'EM03929241', 'name': 'Bennet, Flanagan', 'type': 'Floor 1 Worker', 'order': 323,'color': '#8D7235'},
    'EM66406554' :{ 'id': 'EM66406554', 'name': 'BOT_StateActivity1, BOT_StateActivity1', 'type': 'Floor 1 Worker', 'order': 324,'color': '#F2BF55'},
    'EM12024509' :{ 'id': 'EM12024509', 'name': 'BOT_WOD2020, BOT_WOD2020', 'type': 'Floor 1 Worker', 'order': 325,'color': '#0CB0E3'},
    'EM36264555' :{ 'id': 'EM36264555', 'name': 'Bowman, stephen', 'type': 'Floor 1 Worker', 'order': 326,'color': '#E7162A'},
    'EM93052573' :{ 'id': 'EM93052573', 'name': 'Brown, Brandon', 'type': 'Floor 1 Worker', 'order': 327,'color': '#3734BB'},
    'EM37228232' :{ 'id': 'EM37228232', 'name': 'Brown, Eric', 'type': 'Floor 1 Worker', 'order': 328,'color': '#DBAA22'},
    'EM74700189' :{ 'id': 'EM74700189', 'name': 'Buchholz, Thomas', 'type': 'Floor 1 Worker', 'order': 329,'color': '#11FDD3'},
    'EM95519870' :{ 'id': 'EM95519870', 'name': 'Bulaun, Rickwin', 'type': 'Floor 1 Worker', 'order': 330,'color': '#46CBCB'},
    'EM49508039' :{ 'id': 'EM49508039', 'name': 'Chandran, Vikram', 'type': 'Floor 1 Worker', 'order': 331,'color': '#6313B9'},
    'EM63155492' :{ 'id': 'EM63155492', 'name': 'Chawla, Shikha', 'type': 'Floor 1 Worker', 'order': 332,'color': '#D824CA'},
    'EM91151127' :{ 'id': 'EM91151127', 'name': 'Chilamkurthy, Lahari', 'type': 'Floor 1 Worker', 'order': 333,'color': '#C58FC6'},
    'EM39955769' :{ 'id': 'EM39955769', 'name': 'Claborn, Justin', 'type': 'Floor 1 Worker', 'order': 334,'color': '#06095C'},
    'EM24912968' :{ 'id': 'EM24912968', 'name': 'CREATION & CHANGES, WODCM', 'type': 'Floor 1 Worker', 'order': 335,'color': '#A3EBF3'},
    'EM26044595' :{ 'id': 'EM26044595', 'name': 'Cruz, Angelo', 'type': 'Floor 1 Worker', 'order': 336,'color': '#6D7AE1'},
    'EM15927458' :{ 'id': 'EM15927458', 'name': 'DailyProofing, ATR.WOD', 'type': 'Floor 1 Worker', 'order': 337,'color': '#9BA17C'},
    'EM60707579' :{ 'id': 'EM60707579', 'name': 'DailyProofing, ATR.WOD', 'type': 'Floor 1 Worker', 'order': 338,'color': '#2C93E4'},
    'EM01200323' :{ 'id': 'EM01200323', 'name': 'Daniels, Taylor', 'type': 'Floor 1 Worker', 'order': 339,'color': '#038FA5'},
    'EM83224720' :{ 'id': 'EM83224720', 'name': 'DeVito, Michael', 'type': 'Floor 1 Worker', 'order': 340,'color': '#1B7D05'},
    'EM64512246' :{ 'id': 'EM64512246', 'name': 'Douglas, Xavier', 'type': 'Floor 1 Worker', 'order': 341,'color': '#E68159'},
    'EM34966091' :{ 'id': 'EM34966091', 'name': 'edwards, ryan', 'type': 'Floor 1 Worker', 'order': 342,'color': '#068DD4'},
    'EM44636169' :{ 'id': 'EM44636169', 'name': 'Elmore, Landon', 'type': 'Floor 1 Worker', 'order': 343,'color': '#98A47B'},
    'EM37228679' :{ 'id': 'EM37228679', 'name': 'Fast Track, RPA AUTOMATION', 'type': 'Floor 1 Worker', 'order': 344,'color': '#962E73'},
    'EM78292909' :{ 'id': 'EM78292909', 'name': 'FieldInitiatedPriceChange, RPA', 'type': 'Floor 1 Worker', 'order': 345,'color': '#1897AA'},
    'EM35255390' :{ 'id': 'EM35255390', 'name': 'G, Sandhya', 'type': 'Floor 1 Worker', 'order': 346,'color': '#1B0477'},
    'EM29511123' :{ 'id': 'EM29511123', 'name': 'Gadingan, Sherwin', 'type': 'Floor 1 Worker', 'order': 347,'color': '#8F3B74'},
    'EM18727964' :{ 'id': 'EM18727964', 'name': 'Galvan, Dulce', 'type': 'Floor 1 Worker', 'order': 348,'color': '#EB75C7'},
    'EM69449926' :{ 'id': 'EM69449926', 'name': 'Gatewood, Kevin', 'type': 'Floor 1 Worker', 'order': 349,'color': '#A5299B'},
    'EM23765815' :{ 'id': 'EM23765815', 'name': 'GCCOTCO, GCCOTCO', 'type': 'Floor 1 Worker', 'order': 350,'color': '#0D2692'},
    'EM52666290' :{ 'id': 'EM52666290', 'name': 'GCCOTCO1, GCCOTCO1', 'type': 'Floor 1 Worker', 'order': 351,'color': '#5D48D4'},
    'EM01446848' :{ 'id': 'EM01446848', 'name': 'GCCOTCO3, GCCOTCO3', 'type': 'Floor 1 Worker', 'order': 352,'color': '#E6A30A'},
    'EM30334338' :{ 'id': 'EM30334338', 'name': 'GCCOTCO4, GCCOTCO4', 'type': 'Floor 1 Worker', 'order': 353,'color': '#EDB41C'},
    'EM39965569' :{ 'id': 'EM39965569', 'name': 'Goud, Manjunath', 'type': 'Floor 1 Worker', 'order': 354,'color': '#899A64'},
    'EM34345568' :{ 'id': 'EM34345568', 'name': 'Guha Thakurta, Adrish', 'type': 'Floor 1 Worker', 'order': 355,'color': '#E77C9B'},
    'EM67166587' :{ 'id': 'EM67166587', 'name': 'Hajeri, Sanju', 'type': 'Floor 1 Worker', 'order': 356,'color': '#E527AC'},
    'EM02062189' :{ 'id': 'EM02062189', 'name': 'Hall, Jason', 'type': 'Floor 1 Worker', 'order': 357,'color': '#C95992'},
    'EM99448191' :{ 'id': 'EM99448191', 'name': 'Highfill, Bo', 'type': 'Floor 1 Worker', 'order': 358,'color': '#4A10C6'},
    'EM77756126' :{ 'id': 'EM77756126', 'name': 'Hiremath, Shivakumar', 'type': 'Floor 1 Worker', 'order': 359,'color': '#DCFF4F'},
    'EM76685017' :{ 'id': 'EM76685017', 'name': 'Ignalaga, Kent', 'type': 'Floor 1 Worker', 'order': 360,'color': '#EE0AFA'},
    'EM94612124' :{ 'id': 'EM94612124', 'name': 'Jaipal, Rajan', 'type': 'Floor 1 Worker', 'order': 361,'color': '#984FD1'},
    'EM11782174' :{ 'id': 'EM11782174', 'name': 'jaks, Noah', 'type': 'Floor 1 Worker', 'order': 362,'color': '#2BF360'},
    'EM47429761' :{ 'id': 'EM47429761', 'name': 'Johnson, Tyler', 'type': 'Floor 1 Worker', 'order': 363,'color': '#EC8D59'},
    'EM57527025' :{ 'id': 'EM57527025', 'name': 'Jonathan, Macinnes', 'type': 'Floor 1 Worker', 'order': 364,'color': '#92984A'},
    'EM68380594' :{ 'id': 'EM68380594', 'name': 'KANNAV, VAIBHAV', 'type': 'Floor 1 Worker', 'order': 365,'color': '#4B9DF0'},
    'EM28751908' :{ 'id': 'EM28751908', 'name': 'KANT, TUSHAR', 'type': 'Floor 1 Worker', 'order': 366,'color': '#1889F7'},
    'EM87561224' :{ 'id': 'EM87561224', 'name': 'Kar, Ankush', 'type': 'Floor 1 Worker', 'order': 367,'color': '#72D4E6'},
    'EM29295912' :{ 'id': 'EM29295912', 'name': 'Karaparambil Latheef, Mumthaz', 'type': 'Floor 1 Worker', 'order': 368,'color': '#94F41C'},
    'EM38827287' :{ 'id': 'EM38827287', 'name': 'Karkala, Sudarshan', 'type': 'Floor 1 Worker', 'order': 369,'color': '#EAE3A4'},
    'EM22558988' :{ 'id': 'EM22558988', 'name': 'Kinayath, Ramesh', 'type': 'Floor 1 Worker', 'order': 370,'color': '#A07344'},
    'EM22902876' :{ 'id': 'EM22902876', 'name': 'Kincade, Keith', 'type': 'Floor 1 Worker', 'order': 371,'color': '#4557C9'},
    'EM91079066' :{ 'id': 'EM91079066', 'name': 'Kotakonda, Kiran', 'type': 'Floor 1 Worker', 'order': 372,'color': '#557BC5'},
    'EM55955604' :{ 'id': 'EM55955604', 'name': 'Kumar, Mohit', 'type': 'Floor 1 Worker', 'order': 373,'color': '#CE2E0B'},
    'EM73821786' :{ 'id': 'EM73821786', 'name': 'KV, Madhumala', 'type': 'Floor 1 Worker', 'order': 374,'color': '#02CAB7'},
    'EM88004624' :{ 'id': 'EM88004624', 'name': 'Lama, Izaz', 'type': 'Floor 1 Worker', 'order': 375,'color': '#B44479'},
    'EM02544334' :{ 'id': 'EM02544334', 'name': 'Logsdon, Allison', 'type': 'Floor 1 Worker', 'order': 376,'color': '#3D1383'},
    'EM41894498' :{ 'id': 'EM41894498', 'name': 'Lotz, Michael', 'type': 'Floor 1 Worker', 'order': 377,'color': '#8CE585'},
    'EM45121347' :{ 'id': 'EM45121347', 'name': 'Luo, Winston', 'type': 'Floor 1 Worker', 'order': 378,'color': '#F21D0D'},
    'EM24129856' :{ 'id': 'EM24129856', 'name': 'M, CHANDRASEKHAR', 'type': 'Floor 1 Worker', 'order': 379,'color': '#74683C'},
    'EM34068380' :{ 'id': 'EM34068380', 'name': 'M, Harish', 'type': 'Floor 1 Worker', 'order': 380,'color': '#CE6736'},
    'EM67307340' :{ 'id': 'EM67307340', 'name': 'Mack, Carson', 'type': 'Floor 1 Worker', 'order': 381,'color': '#6EE796'},
    'EM87152585' :{ 'id': 'EM87152585', 'name': 'Manohara, Usha', 'type': 'Floor 1 Worker', 'order': 382,'color': '#BA3ED3'},
    'EM91795173' :{ 'id': 'EM91795173', 'name': 'mccoy, brian', 'type': 'Floor 1 Worker', 'order': 383,'color': '#DF5047'},
    'EM39020617' :{ 'id': 'EM39020617', 'name': 'McIntosh, Jaslyn', 'type': 'Floor 1 Worker', 'order': 384,'color': '#F0EC36'},
    'EM87581231' :{ 'id': 'EM87581231', 'name': 'Meg, Deters', 'type': 'Floor 1 Worker', 'order': 385,'color': '#F348F1'},
    'EM61286223' :{ 'id': 'EM61286223', 'name': 'Mennemeyer, Abigail', 'type': 'Floor 1 Worker', 'order': 386,'color': '#32EE20'},
    'EM23543789' :{ 'id': 'EM23543789', 'name': 'Miller, Kyle', 'type': 'Floor 1 Worker', 'order': 387,'color': '#27B269'},
    'EM31300052' :{ 'id': 'EM31300052', 'name': 'Morton, Talisa', 'type': 'Floor 1 Worker', 'order': 388,'color': '#9AE252'},
    'EM92854024' :{ 'id': 'EM92854024', 'name': 'Mubarak, Abdul', 'type': 'Floor 1 Worker', 'order': 389,'color': '#0ED751'},
    'EM60644555' :{ 'id': 'EM60644555', 'name': 'nagaraju, girish', 'type': 'Floor 1 Worker', 'order': 390,'color': '#9081DB'},
    'EM00031663' :{ 'id': 'EM00031663', 'name': 'Pasha, Zakir', 'type': 'Floor 1 Worker', 'order': 391,'color': '#69A7B0'},
    'EM62426354' :{ 'id': 'EM62426354', 'name': 'Pattnaik, Prajnya', 'type': 'Floor 1 Worker', 'order': 392,'color': '#8C26B5'},
    'EM95275043' :{ 'id': 'EM95275043', 'name': 'pearson, Shalan', 'type': 'Floor 1 Worker', 'order': 393,'color': '#EDD543'},
    'EM32517360' :{ 'id': 'EM32517360', 'name': 'Pragati, Maity', 'type': 'Floor 1 Worker', 'order': 394,'color': '#8E3258'},
    'EM44576919' :{ 'id': 'EM44576919', 'name': 'RAJ, GAURAV', 'type': 'Floor 1 Worker', 'order': 395,'color': '#642466'},
    'EM41625893' :{ 'id': 'EM41625893', 'name': 'raj, shruthi', 'type': 'Floor 1 Worker', 'order': 396,'color': '#F63131'},
    'EM42329123' :{ 'id': 'EM42329123', 'name': 'Raja, Mahesh', 'type': 'Floor 1 Worker', 'order': 397,'color': '#A8DF27'},
    'EM73439267' :{ 'id': 'EM73439267', 'name': 'Rapley, Keshia', 'type': 'Floor 1 Worker', 'order': 398,'color': '#9FCC61'},
    'EM54887481' :{ 'id': 'EM54887481', 'name': 'Robot, Canada Recons', 'type': 'Floor 1 Worker', 'order': 399,'color': '#4598E3'},
    'EM23416405' :{ 'id': 'EM23416405', 'name': 'rong, Beryl', 'type': 'Floor 1 Worker', 'order': 400,'color': '#2CC1BF'},
    'EM64825593' :{ 'id': 'EM64825593', 'name': 'Saito, Angelo', 'type': 'Floor 1 Worker', 'order': 401,'color': '#775737'},
    'EM69837216' :{ 'id': 'EM69837216', 'name': 'Sanders, Jason', 'type': 'Floor 1 Worker', 'order': 402,'color': '#B5BDF3'},
    'EM33925087' :{ 'id': 'EM33925087', 'name': 'Sariya, Devendra', 'type': 'Floor 1 Worker', 'order': 403,'color': '#70FF56'},
    'EM34342721' :{ 'id': 'EM34342721', 'name': 'Schnieder, Adam', 'type': 'Floor 1 Worker', 'order': 404,'color': '#D97B45'},
    'EM55881930' :{ 'id': 'EM55881930', 'name': 'SHARMA, RUTESH', 'type': 'Floor 1 Worker', 'order': 405,'color': '#6087A0'},
    'EM70905275' :{ 'id': 'EM70905275', 'name': 'shetti, venkatesh', 'type': 'Floor 1 Worker', 'order': 406,'color': '#E518FB'},
    'EM37080384' :{ 'id': 'EM37080384', 'name': 'Silva, Wellder', 'type': 'Floor 1 Worker', 'order': 407,'color': '#0AC6AA'},
    'EM72114362' :{ 'id': 'EM72114362', 'name': 'SIMON, THREASA', 'type': 'Floor 1 Worker', 'order': 408,'color': '#7F0A5D'},
    'EM08908463' :{ 'id': 'EM08908463', 'name': 'Smith, Glanda', 'type': 'Floor 1 Worker', 'order': 409,'color': '#F6BF24'},
    'EM54464938' :{ 'id': 'EM54464938', 'name': 'Sprenger, Catherine', 'type': 'Floor 1 Worker', 'order': 410,'color': '#57EE18'},
    'EM53733941' :{ 'id': 'EM53733941', 'name': 'Thomas, Micah', 'type': 'Floor 1 Worker', 'order': 411,'color': '#C0D53D'},
    'EM63965764' :{ 'id': 'EM63965764', 'name': 'Thornton, Molly', 'type': 'Floor 1 Worker', 'order': 412,'color': '#CA92D5'},
    'EM95172145' :{ 'id': 'EM95172145', 'name': 'Toczylowski, Sam', 'type': 'Floor 1 Worker', 'order': 413,'color': '#6B9F30'},
    'EM95296065' :{ 'id': 'EM95296065', 'name': 'tul, shane', 'type': 'Floor 1 Worker', 'order': 414,'color': '#ADF677'},
    'EM42922037' :{ 'id': 'EM42922037', 'name': 'Ulrich, Daniel', 'type': 'Floor 1 Worker', 'order': 415,'color': '#2AB321'},
    'EM33782742' :{ 'id': 'EM33782742', 'name': 'V, Vishal', 'type': 'Floor 1 Worker', 'order': 416,'color': '#EF509D'},
    'EM38132684' :{ 'id': 'EM38132684', 'name': 'Waite, Rachel', 'type': 'Floor 1 Worker', 'order': 417,'color': '#0C2A14'},
    'EM95890170' :{ 'id': 'EM95890170', 'name': 'White, Kevin', 'type': 'Floor 1 Worker', 'order': 418,'color': '#DF88B9'},
    'EM99108864' :{ 'id': 'EM99108864', 'name': 'William, Poole Jr', 'type': 'Floor 1 Worker', 'order': 419,'color': '#8460CC'},
    'EM33065391' :{ 'id': 'EM33065391', 'name': 'WOD Daily Proofing, ATR', 'type': 'Floor 1 Worker', 'order': 420,'color': '#6EE9B7'},
    'EM54485113' :{ 'id': 'EM54485113', 'name': 'Wuennenberg, Thomas', 'type': 'Floor 1 Worker', 'order': 421,'color': '#8B037D'},
    'BOT_WOD' :{ 'id': 'BOT_WOD', 'name': 'BOT_WOD, BOT_WOD', 'type': 'Floor 1 Worker', 'order': 423,'color': '#048C8E'},
    'CHACRWS' :{ 'id': 'CHACRWS', 'name': 'CHACRWS, CHACRWS', 'type': 'Floor 1 Worker', 'order': 424,'color': '#892745'},
    'MICSCTT' :{ 'id': 'MICSCTT', 'name': 'MICSCTT, MICSCTT', 'type': 'Floor 1 Worker', 'order': 425,'color': '#16A494'},
    'SWFLOKC' :{ 'id': 'SWFLOKC', 'name': 'SWFLOKC, SWFLOKC', 'type': 'Floor 1 Worker', 'order': 426,'color': '#9DB8CD'},
    'EM23628855' :{ 'id': 'EM23628855', 'name': 'Y305953, Y305953', 'type': 'Floor 1 Worker', 'order': 427,'color': '#23F3DF'},
    'Y712402' :{ 'id': 'Y712402', 'name': 'Y712402, Y712402', 'type': 'Floor 1 Worker', 'order': 428,'color': '#86F2BA'},
    'Y915385' :{ 'id': 'Y915385', 'name': 'Y915385, Y915385', 'type': 'Floor 1 Worker', 'order': 429,'color': '#27D9DA'},
    'Y917585' :{ 'id': 'Y917585', 'name': 'Y917585, Y917585', 'type': 'Floor 1 Worker', 'order': 430,'color': '#77E98F'},
    'Y922398' :{ 'id': 'Y922398', 'name': 'Y922398, Y922398', 'type': 'Floor 1 Worker', 'order': 431,'color': '#361855'},
    'Y925378' :{ 'id': 'Y925378', 'name': 'Y925378, Y925378', 'type': 'Floor 1 Worker', 'order': 432,'color': '#6B7287'}
  },
  "f064e086e6574b8cb7da0831cb40e05b" : {
    'WMS Data' : {'id': 'WMS Data', 'name': 'WMS Data', 'type': 'Floor 1 Worker', 'order': 1,'color': '#00008B'},
    '#伊藤　知子' : {'id': '#伊藤　知子', 'name': '#伊藤　知子', 'type': 'Floor 1 Worker', 'order': 2,'color': '#4B0082'},
    '#佐古尾　和宏' : {'id': '#佐古尾　和宏', 'name': '#佐古尾　和宏', 'type': 'Floor 1 Worker', 'order': 3,'color': '#BDB76B'},
    '#奥田　美樹' : {'id': '#奥田　美樹', 'name': '#奥田　美樹', 'type': 'Floor 1 Worker', 'order': 4,'color': '#E0FFFF'},
    '#座馬　純子' : {'id': '#座馬　純子', 'name': '#座馬　純子', 'type': 'Floor 1 Worker', 'order': 5,'color': '#B0E0E6'},
    '#木村　友香' : {'id': '#木村　友香', 'name': '#木村　友香', 'type': 'Floor 1 Worker', 'order': 6,'color': '#9932CC'},
    'Worker #06' : {'id': 'Worker #06', 'name': 'Worker #06', 'type': 'Floor 1 Worker', 'order': 7,'color': '#FFFFFF'},
    'Worker #07' : {'id': 'Worker #07', 'name': 'Worker #07', 'type': 'Floor 1 Worker', 'order': 8,'color': '#2F4F4F'},
    'Worker #08' : {'id': 'Worker #08', 'name': 'Worker #08', 'type': 'Floor 1 Worker', 'order': 9,'color': '#FAFAD2'},
    'Worker #09' : {'id': 'Worker #09', 'name': 'Worker #09', 'type': 'Floor 1 Worker', 'order': 10,'color': '#C71585'},
    'Worker #10' : {'id': 'Worker #10', 'name': 'Worker #10', 'type': 'Floor 1 Worker', 'order': 11,'color': '#556B2F'},
  }, //BML
}
*/
