import React from 'react';

import {IMG_CONFIG} from '../2dConfig';
import {makeYAxis} from '../charts/ChartUtil';
import {HorizontalBar} from 'react-chartjs-2';
import TimeIntervalChart from '../charts/TimeIntervalChart';
//import {FormattedMessage} from 'react-intl';

const COLOR = {'active': '#b3f66d', 'inactive': '#969696', 'picking': '#f4d41f', 'checked_in': '#D4F9F8', '1f_active': '#b3f66d', '2f_active': '#165FC5', '4f_active': '#FF5733'};
const QUERY_LIMIT=86400000;

const options = {
  responsive: false,
  tooltips: {
        mode: 'nearest'
  },
  legend: {
       display: false,
   },
  animation: false,
  scales: {
    yAxes: [{ stacked: true }],
    xAxes: [{
      stacked: true,
      ticks: {
        min: 0,
        max: 100,
        callback: function(value) {
          return value + "%"
        }
      }
    }]
  }
}

class WmsActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1 ? "percent" : "time", //"time" or "percent"
      labels: [], //percent chart
      activity_data: [],  //percent, remove from display for 1f/2f
      inactivity_data: [], // percent
      picking_data: [], //percent,
      f1_activity_data: [], //percent
      f2_activity_data: [], //percent
      f4_activity_data: [], //percent
      y_axis: [], //time_series chart
      time_data: [], //time_series chart
      legend: {picking: false, f1: false, f2: false, f4: false},
    };

    this.setType = this.setType.bind(this);
  }

  componentDidMount() {
    this.makeData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.sorted_nodes) !== JSON.stringify(this.props.sorted_nodes) ||
        JSON.stringify(prevProps.asset_stats) !== JSON.stringify(this.props.asset_stats) ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals) ||
        prevProps.expand !== this.props.expand)
    {
      if (this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1)  {
        this.setState({type: "percent"});
      }
      this.makeData();
    }
  }

  makeData() {
    let labels = this.props.sorted_nodes.map(n => this.props.worker_hash[n].name);
    let activity = [];
    let f1_activity = [];
    let f2_activity = [];
    let f4_activity = [];
    let inactivity = [];
    let picking=[];
    let picking_flg = false;
    let f1_flg = false;
    let f2_flg = false;
    let f4_flg = false;

    let time_series = []; //[[{}, {}, {}], [], etc...]
    this.props.sorted_nodes.forEach(n => {
        if (this.props.asset_stats.hasOwnProperty(n) && this.props.asset_stats[n] !== undefined) {
            //make time series - {activity: "active", start: 1561977955497, duration: 15000}
            let node_time = [];
            node_time.push({start: 0, duration: 1, color: COLOR['inactive']});
            if (this.props.asset_stats[n].hasOwnProperty('activity_intervals')) {
              this.props.asset_stats[n].activity_intervals.forEach( i => {
                let interval =  {start: 0, duration:0, color: COLOR['inactive']}; //default is inactive

                if (i.activity === 'picking') { interval['color'] = COLOR['picking']; }
                if (i.hasOwnProperty('floor') && i.floor === "1") { interval['color'] = COLOR["1f_active"]}
                if (i.hasOwnProperty('floor') && i.floor === "2") { interval['color'] = COLOR["2f_active"]}
                if (i.hasOwnProperty('floor') && i.floor === "4") { interval['color'] = COLOR["4f_active"]}

                interval['start'] = (i.start - this.props.from) / this.props.duration;
                interval['duration'] = i.duration/this.props.duration;

                if (interval['start'] < 0 || interval['duration'] * 100 < .001) { return }
                node_time.push(interval);
              })
            }

            //do checkin time block
            let begin = 0;
            if (this.props.asset_stats[n].hasOwnProperty('activity_intervals') && this.props.asset_stats[n].hasOwnProperty('attachment_intervals')) {
              this.props.asset_stats[n].attachment_intervals.forEach( i => {
                //node_time.push({start: (i.start - this.props.from)/duration , duration: i.duration/duration, color: COLOR['checked_in']});
                node_time.push({start: begin/this.props.duration , duration: (i.start - this.props.from - begin)/this.props.duration, color: COLOR['checked_in']});
                begin = i.start + i.duration - this.props.from;
              })
              node_time.push({start: begin/this.props.duration , duration: (this.props.to - this.props.from - begin)/this.props.duration, color: COLOR['checked_in']});
            }
            time_series.push(node_time);

            /* do percentage now
            "activity_percentage": [
              {
                "activity": "picking",
                "duration": 896000
              },
              {
                "activity": "active",
                "duration": 156882,
                "floor": "2"
              }
            ]
            */
            let pick = 0;
            let active = 0;
            let f1_active = 0;
            let f2_active = 0;
            let f4_active = 0;
            this.props.asset_stats[n].hasOwnProperty('activity_percentage') && this.props.asset_stats[n].activity_percentage.forEach(i => {
              if (i.activity === 'picking') {
                picking_flg = true;
                pick = i.duration;
              } else { //change active - split into floors
                active += i.duration;
                if (i.hasOwnProperty('floor') && i.floor === "1") { f1_flg = true; f1_active = i.duration; }
                if (i.hasOwnProperty('floor') && i.floor === "2") { f2_flg = true; f2_active = i.duration; }
                if (i.hasOwnProperty('floor') && i.floor === "4") { f4_flg = true; f4_active = i.duration; }
              }
            })

            //do percentage calcuation
            active = this.props.asset_stats[n].attached_time ? ((active / this.props.asset_stats[n].attached_time) * 100).toFixed(2) : (active / this.props.duration * 100).toFixed(2);
            f1_active = this.props.asset_stats[n].attached_time ? ((f1_active / this.props.asset_stats[n].attached_time) * 100).toFixed(2) : (f1_active / this.props.duration * 100).toFixed(2);
            f2_active = this.props.asset_stats[n].attached_time ? ((f2_active / this.props.asset_stats[n].attached_time) * 100).toFixed(2) : (f2_active / this.props.duration * 100).toFixed(2);
            f4_active = this.props.asset_stats[n].attached_time ? ((f4_active / this.props.asset_stats[n].attached_time) * 100).toFixed(2) : (f4_active / this.props.duration * 100).toFixed(2);
            pick = this.props.asset_stats[n].attached_time ? ((pick/ this.props.asset_stats[n].attached_time) * 100).toFixed(2) : (pick / this.props.duration * 100).toFixed(2)
            let inactive = (100 - f1_active - f2_active - f4_active - pick).toFixed(2);

            picking.push(pick);
            activity.push(active);
            f1_activity.push(f1_active);
            f2_activity.push(f2_active);
            f4_activity.push(f4_active);
            inactivity.push(inactive);
        } else { //nodes with no activity value
            time_series.push([{start: 0, duration: 1, color: COLOR['inactive']}]);
            picking.push(0);
            activity.push(0);
            f1_activity.push(0);
            f2_activity.push(0);
            f4_activity.push(0);
            inactivity.push(100);
        }
      }
    )

    this.setState({legend: {picking: picking_flg, f1: f1_flg, f2: f2_flg, f4: f4_flg}, labels: labels, picking_data: picking, activity_data: activity, f1_activity_data: f1_activity, f2_activity_data: f2_activity, f4_activity_data: f4_activity, y_axis: makeYAxis(this.props.from, this.props.to), inactivity_data: inactivity, time_data: time_series});
  }

  setType(e) {
    if (this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1) { return; }
    this.setState({type: e.target.dataset.type});
  }

  render() {
    return (
      <div className="SiteChart">
        {this.props.expand ?
          <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
          <img className="ExpandIcon" alt="expand" data-chart="activity" src={require("../img/expand.png")} onClick={this.props.setChartType} />
        }
        <div className="SiteHeader">
        {
          this.props.isLps ?
            "ACTIVITY"
            :
            "WORKER ACTIVITY"
        }
        {
          this.props.displayDownload &&
          <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="activity" data-type={this.state.type} onClick={this.props.setDownloadType}/>
        }
        </div>
        <div id="activity" className="SiteChartContainer">
          { this.state.type === 'time' ?
            <div>
              <img className="ChartOptionsImg" alt="percent_unselect" data-type='percent' src={require("../img/percent_unselect.png")} onClick={this.setType}/>
              <img className="ChartOptionsImg" alt="time_select" src={require("../img/time_select.png")} />
            </div>
             :
            <div>
              <img className="ChartOptionsImg" alt="percent_select" src={require("../img/percent_select.png")} />
              <img className="ChartOptionsImg" alt="time_unselect" data-type='time' src={this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1 ? require("../img/time_disabled.png") : require("../img/time_unselect.png")} onClick={this.setType}/>
            </div>
          }

          <div className="ActivityText">INACTIVITY</div>
          <div className="ActivityLegend" style={{backgroundColor: '#969696'}}/>
          {
            this.state.legend.f1 &&
            <div>
              <div className="ActivityText">1F ACTIVITY</div>
              <div className="ActivityLegend" style={{backgroundColor: '#b3f66d'}}/>
            </div>
          }
          {
            this.state.legend.f2 &&
            <div>
              <div className="ActivityText">2F ACTIVITY</div>
              <div className="ActivityLegend" style={{backgroundColor: '#165FC5'}}/>
            </div>
          }
          {
            this.state.legend.f4 &&
            <div>
              <div className="ActivityText">4F ACTIVITY</div>
              <div className="ActivityLegend" style={{backgroundColor: '#FF5733'}}/>
            </div>
          }
          {
            this.state.legend.picking &&
            <div>
              <div className="ActivityText">PICKING</div>
              <div className="ActivityLegend" style={{backgroundColor: '#f4d41f'}}/>
            </div>
          }
          <div style={{display: this.state.type==='percent' ? "" : "none", marginTop: "30px"}}>
            <HorizontalBar
                key={this.state.labels.length}
                width={980}
                height={this.state.labels.length * 25 + 30}
                data={{labels: this.state.labels,
                      datasets: [
                                 {label: 'PICKING', data: this.state.picking_data, backgroundColor: '#f4d41f' },
                                 {label: '1F ACTIVITY', data: this.state.f1_activity_data, backgroundColor: '#b3f66d' },
                                 {label: '2F ACTIVITY', data: this.state.f2_activity_data, backgroundColor: '#165FC5' },
                                 {label: '4F ACTIVITY', data: this.state.f4_activity_data, backgroundColor: '#FF5733' },
                                 {label: 'INACTIVITY', data: this.state.inactivity_data, backgroundColor: '#969696'},
                                ]
                      }}
                options={options}
              />
          </div>
          <div style={{display: this.state.type==='time' ? "" : "none"}}>
            <TimeIntervalChart labels={this.state.labels} y_axis={this.state.y_axis} data={this.state.time_data}/>
          </div>
        </div>
      </div>
    )
  }
}

export default WmsActivity;

/*
/*<div className="ActivityText">ACTIVITY</div>
<div className="ActivityLegend" style={{backgroundColor: '#b3f66d'}}/>*/

 /*{label: 'ACTIVITY', data: this.state.activity_data, backgroundColor: '#b3f66d' },

*/

/*
<FormattedHTMLMessage id="app.text"
                     defaultMessage="Edit <code>src/App.js</code> and save to reload.<br/>Now with {what}!"
                     description="Welcome header on app main page"
                     values={{ what: 'react-intl' }}/>

   {
     this.props.isLps ?
     <FormattedMessage id="app.activity" defaultMessage="ACTIVITY"/>
       :
     <FormattedMessage id="app.worker.activity.kpi" defaultMessage="WORKER ACTIVITY KPI"/>
   }
*/
