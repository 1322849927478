import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import './css/2dReactChart.css';
import WmsSummary from './wms/WmsSummary';
import WmsActivity from './wms/WmsActivity';
import WmsZone from './wms/WmsZone';
import WmsDistance from './wms/WmsDistance';
import WmsDistanceByZone from './wms/WmsDistanceByZone';
import Metric from './charts/MetricNew';
import DownloadChart from './wms/DownloadChart';

import { fetchSiteDetailJson, postWmsJsonNew} from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';

const LIMIT = 6;
class NavChart2dNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: "",
      sorted_nodes: [],//[id]
      worker_hash: {}, //{id: name}
      zone_config: {},
      tracker_stats: {},
      tracker_stats_filtered: {},
      summary:  {distance: 0, active_time: 0, inactive_time: 0, active_count: 0, worker: {active_count:0, active_time:0, inactive_time: 0, distance: 0}, forklift: {active_count:0, active_time:0, inactive_time: 0, distance: 0}},
      expand: false,
      chartType: null, //chart of expanded - activity
      loading_key: null,
      downloadChartType: "", //metric,
      downloadType: "", //time or percent
      downloadFile: false, //hide or show modal
    };

    this.onSetClose = this.onSetClose.bind(this);
    this.onSetChartType = this.onSetChartType.bind(this);
    this.onSetDownloadType = this.onSetDownloadType.bind(this);
    this.closeDownload = this.closeDownload.bind(this);
    this.debounceRendering = debounce(this.fetchLpsStats, 2000);
  }

  componentDidMount() {
    this.fetchZoneDetail(this.props.siteId);
    this.sortAndFilterNodes(this.props.nodes);
    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=>this.fetchLpsStats(loading_key));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
       this.fetchZoneDetail(this.props.siteId);
    }

    if (Object.keys(prevProps.nodes).length !== Object.keys(this.props.nodes).length) {
      this.sortAndFilterNodes(this.props.nodes);
    }

    if (prevProps.siteId !== this.props.siteId ||
        Object.keys(prevProps.nodes).length !== Object.keys(this.props.nodes).length ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals))
        {
          let loading_key = new Date().getTime();
          this.setState({loading_key: loading_key}, ()=>this.debounceRendering(loading_key));
        }
  }

  fetchZoneDetail(siteId) {
    fetchSiteDetailJson(this.props.authToken, this.props.siteId, {})
     .then(json => {
       if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('lps') && json.state.configs.lps.hasOwnProperty('zones')) {
         this.setState({zone_config: json.state.configs.lps.zones});
       }
     })
  }

  sortAndFilterNodes(nodes) {
    let sorted = [];
    let worker_hash = {}
    for (const n in nodes) {
      sorted.push(nodes[n])
      worker_hash[n] = {id: n, name: nodes[n].name};
    }

    sorted.sort((a,b) => a.name.localeCompare(b.name));
    this.setState({sorted_nodes: sorted.map(n => n.id), worker_hash: worker_hash});
  }

  sortAndFilterStats(stats) {
    let filteredStats = {};
    this.state.sorted_nodes.slice(0, LIMIT).forEach(n => {
      filteredStats[n] = stats[n];
    })
    return filteredStats;
  }

  fetchLpsStats(loading_key) {
    this.setState({
      loading: this.props.time_intervals.length < 10 ?  "Loading..Please wait..." : "Loading...Requests of this size may take up to 2 minutes to process.",
      tracker_stats: {},
      tracker_stats_filtered: {}
    });

    let body = {
                 time_intervals: this.props.time_intervals,
                 assets: Object.keys(this.props.nodes),
                 outputs: {percentage: {}, intervals: {}},
                 use_wms: false,
               };

    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        if (this.state.loading_key === loading_key) {
          let time_by_zone = {}
          for (const z in json.zones) {
            time_by_zone[z] = json.zones[z].time;
          }

          let trackers = {};
          let inactive_time = 0;
          let active_count = 0;
          let distance = 0;
          let active_time = 0;
          this.props.types.forEach(type => {
            if (!json.types.hasOwnProperty(type)) {return;}
            distance += json.types[type].distance;
            active_time += json.types[type].active_time;
            inactive_time += json.types[type].inactive_time;
            active_count += json.types[type].active_count;
            trackers[type] = {...json.types[type]};
          })

          let summary = {
              "distance" : distance,
              "active_time" : active_time,
              "inactive_time": inactive_time,
              "active_count": active_count,
              "time_by_zone": time_by_zone,
              "worker": {active_count:0, active_time:0, inactive_time: 0, distance: 0},
              "forklift": {active_count:0, active_time:0, inactive_time: 0, distance: 0}
            };

          this.setState({summary: {...summary, ...trackers,}, tracker_stats: json.assets, tracker_stats_filtered: this.sortAndFilterStats(json.assets), loading: "", loading_key: null});
        } else {
          console.log("NavChart - there's another process")
        }
      })
      .catch(error => {
        this.setState({loading: "Error - Status " + error.status});
        console.log(error);
      });
  }

  onSetClose(e) {
    if (this.state.chartType !== null) {
      this.setState({chartType: null, expand: false})
    }
  }

  onSetChartType(e) {
    //console.log(e.target.dataset.chart)
    if (this.state.chartType !== e.target.dataset.chart) {
      this.setState({chartType: e.target.dataset.chart, expand: true})
    }
  }

  onSetDownloadType(e) {
    console.log(e.target.dataset.charttype);
    console.log(e.target.dataset.type);
    this.setState({downloadChartType: e.target.dataset.charttype, downloadType: e.target.dataset.type, downloadFile: true});
  }

  closeDownload(e) {
    this.setState({downloadFile: false}); //open and close the modal
  }

  render() {
    return(
      <div>
        <div className="loading">{this.state.loading}</div>
        <div style={{opacity: this.state.loading.length > 0 ? .2 : 1}}>
           <DownloadChart
             siteId={this.props.siteId}
             from={this.props.time_intervals[0].from}
             to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
             duration={this.props.duration}
             chart_type={this.state.downloadChartType}
             type={this.state.downloadType}
             sorted_nodes={this.state.sorted_nodes}
             asset_stats={this.state.tracker_stats}
             zone_config={this.state.zone_config}
             downloadFile={this.state.downloadFile}
             closeDownload={this.closeDownload}
             expand={this.state.expand}
             worker_hash={this.state.worker_hash}
            />
          {!this.state.expand &&
            <div>
              <WmsSummary site_stats={this.state.summary} zone_config={this.state.zone_config}/>
            </div>
          }
          { (!this.state.expand || this.state.chartType === 'activity') &&
             <div>
                 <WmsActivity
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 time_intervals={this.props.time_intervals}
                 duration={this.props.duration}
                 sorted_nodes={this.state.expand? this.state.sorted_nodes : this.state.sorted_nodes.slice(0,LIMIT)}
                 asset_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
                 worker_hash={this.state.worker_hash}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 isLps={true}
                 />
             </div>
          }
          {(!this.state.expand || this.state.chartType === 'zone_time') &&
             <div>
               <WmsZone
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 time_intervals={this.props.time_intervals}
                 duration={this.props.duration}
                 worker_hash={this.state.worker_hash}
                 zone_config={this.state.zone_config}
                 sorted_nodes={this.state.expand? this.state.sorted_nodes : this.state.sorted_nodes.slice(0,LIMIT)}
                 asset_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
            </div>
          }
          { (!this.state.expand || this.state.chartType === 'zone_distance') &&
             <div>
               <WmsDistance
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 worker_hash={this.state.worker_hash}
                 zone_config={this.state.zone_config}
                 sorted_nodes={this.state.expand? this.state.sorted_nodes : this.state.sorted_nodes.slice(0,LIMIT)}
                 asset_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
            </div>
          }
          { (!this.state.expand || this.state.chartType === 'distance_by_zone') &&
             <div>
               <WmsDistanceByZone
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 zone_config={this.state.zone_config}
                 sorted_nodes={this.state.sorted_nodes}
                 asset_stats={this.state.tracker_stats}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
            </div>
          }
          {(!this.state.expand || this.state.chartType === 'metric') &&
             <div>
               <Metric
                 duration={this.props.duration}
                 sorted_nodes={this.state.expand? this.state.sorted_nodes : this.state.sorted_nodes.slice(0,LIMIT)}
                 worker_hash={this.state.worker_hash}
                 tracker_stats={this.state.expand? this.state.tracker_stats : this.state.tracker_stats_filtered}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
              </div>
          }
        </div>
      </div>
    )
  }

}

export default connect(
  state => ({
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  }),
)(NavChart2dNew);
