import React from 'react';
import { Stage, Layer, Image, Circle } from 'react-konva';
import { connect } from 'react-redux';
import { get, throttle } from 'lodash';

import {TRACKER_CONFIG } from '../2dConfig';
import { getTrackersArray } from '../../../modules/nodes';
//import NavFloorControl from '../NavFloorControl';

//const WIDTH_CUTOFF = 525;
const PIXEL_ADJUSTMENT = { //icon scaling
  1: {
    x : 15,
    y: 44,
  },
  .5: {
    x: 7.5,
    y: 22,
  }
}

const METERS_PER_FOOT = 0.3048;
const DEFAULT_HARDWARE = "LETV2"; //LETV1 - thick, LETV2 - thin

class MscsLiveView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: {}, //trackers
      filters: {'LINKED': true, "UNLINKED": false, "WORKERS": true, "FORKLIFTS": true},
      background: null,
      zoom_in: null,
      zoom_out: null,
      tracker_imgs: {},
      zoom: 1,
      scale: .5, //icon
      origin: null,
    };

    this.mapRef = React.createRef();
    this.myRefs = {};
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.drag = this.drag.bind(this);
    this.reset = this.reset.bind(this);
    this.loadImages = this.loadImages.bind(this);
    this.unloadImages = this.unloadImages.bind(this);
    this.setFilters = this.setFilters.bind(this);
    this.throttleLoadNodes = throttle(this.loadNodes, 2000);
    this.throttleRenderNodes = throttle(this.renderNodes, 1000);
  }

  componentDidMount() {
    //icon scale
    //this.setState({scale: this.props.config.mscs[this.props.size].live[this.props.floor].small.width < WIDTH_CUTOFF ? .5 : 1})
    this.loadImages();
    this.makeOrigin();
  }

  componentWillUnmount() {
    this.unloadImages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.size !== this.props.size) {
      this.makeOrigin();
    }
      this.throttleLoadNodes();
  }

  makeOrigin() {
    let newX = this.props.config.mscs[this.props.size].live[this.props.floor].origin.x *  this.props.config.mscs[this.props.size].live[this.props.floor].small.pixels_per_meter;
    let newY = this.props.config.mscs[this.props.size].live[this.props.floor].small.height - (this.props.config.mscs[this.props.size].live[this.props.floor].origin.y * this.props.config.mscs[this.props.size].live[this.props.floor].small.pixels_per_meter);
    this.setState({origin: <Circle radius={2} fill={'red'}  x={newX} y ={newY} key={"Origin"}/>})
  }

  setFilters(id) {
    let filter = id.target.dataset.filter;
    this.setState(
      (prevState, props) => ({
        filters: Object.assign({}, prevState.filters, {[filter]: !prevState.filters[filter]})
      })
    )
  }

  loadNodes() {
    let newNodes = {};
    this.props.nodes.forEach((node) => {
      if (!get(node, "events.tracker.location.meas_id") || !get(node, "events.tracker.location.floor") ||
          (node.events.tracker.location.floor !== this.props.floor)) {
            return;
          }

      let type = node.configs.tracker.tracker_type ? node.configs.tracker.tracker_type : 'worker';

      //do linked,unlinked,worker, forklift filters
      if (!this.state.filters['WORKERS'] && type === 'worker') {
        return;
      }

      if (!this.state.filters['FORKLIFTS'] && type === 'forklift') {
        return;
      }

      /*
      asset: node.events.tracker.attachment && node.events.tracker.attachment.attached && (
        this.state.gids.hasOwnProperty(node.events.tracker.attachment.asset) ?
         this.state.gids[node.events.tracker.attachment.asset].name
         :
         node.events.tracker.attachment.asset
      */

      if (type === 'worker' && !this.state.filters['LINKED'] && node.events.tracker.hasOwnProperty('attachment') && node.events.tracker.attachment.attached) {
        return;
      }

      if (type === 'worker' && !this.state.filters['UNLINKED'] && node.events.tracker.hasOwnProperty('attachment') && !node.events.tracker.attachment.attached) {
        return;
      }

      let nodeId = node.node;
      let group = node.hasOwnProperty('tags') && node.tags.hasOwnProperty('group') ? node.tags.group : "";
      let connected = node.hasOwnProperty('events') && node.events.hasOwnProperty('system') && node.events.system.hasOwnProperty('connection') ? node.events.system.connection.connected : "";
      //https://dfsniot.atlassian.net/browse/LPS-221 - If there is no system:connection event in the API, mark the device as "connected" in the UI
      if (!node.events.hasOwnProperty('system') || !node.events.system.hasOwnProperty('connection')) {
        connected = true;
      }

      //https://dfsniot.atlassian.net/browse/LPS-590 - disgard all teh disconnected trackers. 
      if (!connected) { return; }

      const theLoc = node.events.tracker.location;
      let x = theLoc.hasOwnProperty('x') ? theLoc.x * METERS_PER_FOOT : theLoc.filtered[0] * METERS_PER_FOOT;
      let y = theLoc.hasOwnProperty('y') ? theLoc.y * METERS_PER_FOOT : theLoc.filtered[1] * METERS_PER_FOOT;
      let newX = (x + this.props.config.mscs[this.props.size].live[this.props.floor].origin.x) * this.props.config.mscs[this.props.size].live[this.props.floor].small.pixels_per_meter - PIXEL_ADJUSTMENT[this.state.scale].x;
      let newY = this.props.config.mscs[this.props.size].live[this.props.floor].small.height - ((y + this.props.config.mscs[this.props.size].live[this.props.floor].origin.y) * this.props.config.mscs[this.props.size].live[this.props.floor].small.pixels_per_meter) - PIXEL_ADJUSTMENT[this.state.scale].y;

      if (this.myRefs[nodeId] && this.state.nodes[nodeId] && (node.x !== this.state.nodes[nodeId].x || node.y !== this.state.nodes[nodeId].y)) {
          this.myRefs[nodeId].to({
              x: newX,
              y: newY,
              duration: 4,
          });
      }

      newNodes[nodeId] = {
        name: node.name,
        id: node.node,
        nodeId: node.node,
        x: x,
        y: y,
        new_x: newX,
        new_y: newY,
        selected: false,
        type: type,
        timestamp: theLoc.timestamp,
        meas_id: parseInt(theLoc.meas_id, 16),
        connected: connected,
        voltage: node.events.hasOwnProperty('battery') && node.events.battery.voltage,
        group: group,
        hardware: node.events.hasOwnProperty('system') && node.events.system.hardware_version ? node.events.system.hardware_version : DEFAULT_HARDWARE,
        charge: node.events.hasOwnProperty('battery') && node.events.battery.charge,
        img_key: this.state.tracker_imgs.hasOwnProperty(group + type) ? group + type : type //image defaults to node type if can't find group
      };
    })
    //console.log(newNodes);
    this.setState({nodes: newNodes});
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("../img/" + this.props.config.mscs[this.props.size].live[this.props.floor].background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));

    let tracker_imgs = {...this.state.tracker_imgs};
    let tracker_config = {...TRACKER_CONFIG};
    for (const type in tracker_config) {
      tracker_imgs[type] = {
                            active: null,
                            active_sel: null,
                            inactive: null,
                            inactive_sel: null,
                            idle: null,
                            idle_sel: null,
                            low_battery: null,
                            low_battery_sel: null,
                            dead_battery: null,
                            dead_battery_sel: null,
                            dead_disconnected_bat: null,
                            dead_disconnected_bat_sel: null,
                           };

      let temp = new window.Image();
      temp.src = tracker_config[type].active;
      temp.addEventListener('load', ()=>{tracker_imgs[type].active = temp});

      let temp2 = new window.Image();
      temp2.src = tracker_config[type].inactive;
      temp2.addEventListener('load', ()=>{tracker_imgs[type].inactive = temp2});

      let temp3 = new window.Image();
      temp3.src = tracker_config[type].idle;
      temp3.addEventListener('load', ()=>{tracker_imgs[type].idle = temp3});

      let temp5 = new window.Image();
      temp5.src = tracker_config[type].active_sel;
      temp5.addEventListener('load', ()=>{tracker_imgs[type].active_sel = temp5});

      let temp6 = new window.Image();
      temp6.src = tracker_config[type].inactive_sel;
      temp6.addEventListener('load', ()=>{tracker_imgs[type].inactive_sel = temp6});

      let temp7 = new window.Image();
      temp7.src = tracker_config[type].idle_sel;
      temp7.addEventListener('load', ()=>{tracker_imgs[type].idle_sel = temp7});

      let temp9 = new window.Image();
      temp9.src = tracker_config[type].dead_battery;
      temp9.addEventListener('load', ()=>{tracker_imgs[type].dead_battery = temp9});

      let temp10 = new window.Image();
      temp10.src = tracker_config[type].dead_battery_sel;
      temp10.addEventListener('load', ()=>{tracker_imgs[type].dead_battery_sel = temp10});

      let temp11 = new window.Image();
      temp11.src = tracker_config[type].dead_disconnected_bat;
      temp11.addEventListener('load', ()=>{tracker_imgs[type].dead_disconnected_bat= temp11});

      let temp12 = new window.Image();
      temp12.src = tracker_config[type].dead_disconnected_bat_sel;
      temp12.addEventListener('load', ()=>{tracker_imgs[type].dead_disconnected_bat_sel= temp12});
    }

    this.setState({tracker_imgs: tracker_imgs}/*, ()=> console.log(this.state.tracker_imgs)*/);

    let zoom_in = new window.Image();
    zoom_in.src = require('../img/zoom_in.png');
    zoom_in.addEventListener('load', ()=> this.setState({zoom_in: zoom_in}));

    let zoom_out = new window.Image();
    zoom_out.src = require('../img/zoom_out.png');
    zoom_out.addEventListener('load', ()=> this.setState({zoom_out: zoom_out}));

    let reset = new window.Image();
    reset.src = require('../img/reset.png');
    reset.addEventListener('load', ()=> this.setState({reset: reset}));
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));

    let temp = {...this.state.tracker_imgs};
    for (const t in temp) {
      temp[t].active.removeEventListener('load', ()=>temp[t].active = null);
      temp[t].inactive.removeEventListener('load', ()=>temp[t].inactive = null);
      temp[t].idle.removeEventListener('load', ()=>temp[t].idle = null);
      temp[t].active_sel.removeEventListener('load', ()=>temp[t].active = null);
      temp[t].inactive_sel.removeEventListener('load', ()=>temp[t].inactive = null);
      temp[t].idle_sel.removeEventListener('load', ()=>temp[t].idle = null);
      temp[t].dead_battery.removeEventListener('load', ()=>temp[t].dead_battery = null);
      temp[t].dead_battery_sel.removeEventListener('load', ()=>temp[t].dead_battery_sel = null);
      temp[t].dead_disconnected_bat.removeEventListener('load', ()=>temp[t].dead_disconnected_bat = null);
      temp[t].dead_disconnected_bat_sel.removeEventListener('load', ()=>temp[t].dead_disconnected_bat_sel = null);
    }
    this.setState({tracker_imgs: temp});

    this.state.zoom_in.removeEventListener('load', ()=>this.setState({zoom_in: null}));
    this.state.zoom_out.removeEventListener('load', ()=>this.setState({zoom_out: null}));
    this.state.reset.removeEventListener('load', ()=>this.setState({reset: null}));
  }

  zoomIn(e) {
    if (this.state.zoom >= 2) { return }
    let layer = this.mapRef.current;
    this.setState((prevState, props) => ({zoom: prevState.zoom + .1}))
    layer.scale({x: this.state.zoom , y: this.state.zoom})
    layer.draw();
  }

  zoomOut(e) {
    if (this.state.zoom <= .75) { return }
    let layer = this.mapRef.current;
    this.setState((prevState, props) => ({zoom: prevState.zoom - .1}))
    layer.scale({x: this.state.zoom, y: this.state.zoom})
    layer.draw();
  }

  reset(e) {
    let map = this.mapRef.current;
    map.scale({x: 1, y: 1});
    map.setPosition({x: 0, y: 0});
    map.draw();
    this.setState({zoom: 1});
  }

  drag(e) {
    let layer = this.mapRef.current;
    layer.startDrag();
  }

  renderNodes() {
    let showNodes = [];
    for (const nodeId in this.state.nodes) {
      const n = this.state.nodes[nodeId];
      let thisNode = "";

      if (n.connected) {
        let img = this.state.tracker_imgs[n.img_key].active;
        thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} />

        if (n.timestamp <= Date.now() - 600000) { //idle = 10 min
          let img = this.state.tracker_imgs[n.img_key].idle;
          thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} />
        }

        if ((n.hardware === 'LEVT1' && n.voltage < 3.1) || (n.hardware === 'LEVT2' && n.charge < 10)) {
          let img = this.state.tracker_imgs[n.img_key].dead_battery;
          thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} />
        }
      }

      if (!n.connected) {
        let img = this.state.tracker_imgs[n.img_key].inactive;
        thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} />

        if ((n.hardware === 'LEVT1' && n.voltage < 3.1) || (n.hardware === 'LEVT2' && n.charge < 10)) {
          let img = this.state.tracker_imgs[n.img_key].dead_disconnected_bat;
          thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} />
        }
      }


      showNodes.push(thisNode);
    }

    return [showNodes];
  }

  render() {
    return (
      <div className="mscsLiveView">
        Live View
        <div className="buttongroup">
        <button className={this.state.filters['LINKED'] ? "active" : ""} data-filter="LINKED" onClick={this.setFilters}>LINKED</button>
        <button className={this.state.filters['UNLINKED'] ? "active" : ""} data-filter="UNLINKED" onClick={this.setFilters}>UNLINKED</button>
        <button className={this.state.filters['WORKERS'] ? "active" : ""}  data-filter="WORKERS" onClick={this.setFilters}>WORKERS</button>
        <button className={this.state.filters['FORKLIFTS'] ? "active" : ""} data-filter="FORKLIFTS" onClick={this.setFilters}>FORKLIFTS</button>
        </div>
        <div className="mscsMap">
        <Stage width={this.props.config.mscs[this.props.size].live[this.props.floor].small.width} height={this.props.config.mscs[this.props.size].live[this.props.floor].small.height}>
          <Layer ref={this.mapRef} onDblClick={this.zoomIn} onClick={this.reset} draggable={true}>
            <Image
              x={0}
              y={0}
              width = {this.props.config.mscs[this.props.size].live[this.props.floor].small.width}
              height= {this.props.config.mscs[this.props.size].live[this.props.floor].small.height}
              image={this.state.background}
            />
            {this.throttleRenderNodes()}
            {this.state.origin}
          </Layer>
          <Layer>
            <Image image={this.state.reset} x={this.props.config.mscs[this.props.size].live[this.props.floor].small.width - 35} y={5} width={30} height={30} key={'resetImg'} onClick={this.reset}/>
            <Image image={this.state.zoom_in} x={this.props.config.mscs[this.props.size].live[this.props.floor].small.width - 35} y={40} width={30} height={30} key={'zoomInImg'} onClick={this.zoomIn}/>
            <Image image={this.state.zoom_out} x={this.props.config.mscs[this.props.size].live[this.props.floor].small.width - 35} y={75} width={30} height={30} key={'zoomOutImg'} onClick={this.zoomOut}/>
          </Layer>
        </Stage>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    nodes: getTrackersArray(state),
  }),
)(MscsLiveView);
