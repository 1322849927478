import React from 'react';
import { connect } from 'react-redux';
import { fetchCustomerUsers, deleteCustomerUser, deleteSiteUser } from '../../../modules/users';

import { Link } from 'react-router-dom';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Select from 'react-select';
import Sort from '../../common/Sort';
import Filter from '../../common/Filter';
import WarnModal from '../../common/WarnModal';
import { checkSiteACL, ROLES_LOOKUP } from '../Config';

const style = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: base => ({
    ...base,
    maxHeight: "38px",
  }),
  valueContainer: base =>({
    ...base,
    maxHeight: "38px",
  }),
  indicatorsContainer: base => ({
    ...base,
  }),
  singleValue: base => ({
    ...base,
  })
};

const SelectOptions = (props) => (
  <div className="select_option">
   < Select
      options={props.options}
      styles={style}
      value={props.value}
      placeholder=""
      isClearable={false}
      onChange={e => props.selectSite(e)}
    />
  </div>
)

const UserTable = (
    {origData, data, setTableData, sortedColumnIndex, setSortedData, acl, customer, sites, selectedSite, selectSite, deleteUser}
  ) => (
  <div className="main user_management">
    <h3>{customer && customer.label} - Users</h3>
    {
      customer && checkSiteACL(customer.value, null, acl, 'Add User') &&
      <div className="button-container"><Link to={`/dashboard/management/users/${customer.value}/new`}>ADD NEW</Link></div>
    }
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <SelectOptions options={sites} selectSite={selectSite} value={selectedSite}/>
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>Username<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {
              customer && checkSiteACL(customer.value, null, acl, 'Edit User') &&
              <Link to={`/dashboard/management/users/${customer.value}/${selectedSite.value}/${data[props.rowIndex].id}`}>{data[props.rowIndex].username}</Link>
            }
          </Cell>
        )}
        width={275}
      />
      <Column
        header={<Cell>Site Access<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {
              data[props.rowIndex].site_access
            }
          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell>Role<Sort thisColumnIndex={2} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {
              data[props.rowIndex].role
            }
          </Cell>
        )}
        width={285}
      />
      <Column
        header={<Cell></Cell>}
        cell={props => (
          <Cell {...props}>

          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell></Cell>}
        cell={props => (
          <Cell {...props}>
            {
              customer && checkSiteACL(customer.value, null, acl, 'Delete User') && selectedSite.value !== 'All Users' &&
              <
                WarnModal
                html= <i className="fa fa-minus-circle delete"></i>
                warn={`Removing a user will only revoke this user’s ability to access ${data[props.rowIndex].site_access}. Do you want to continue?`}
                action={()=>deleteUser(data[props.rowIndex].id)}
              />
            }
          </Cell>
        )}
        width={60}
      />
    </Table>
  </div>
);

class UsersCustomerAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: {}, //raw users data
      sites: [], //[{label: value}, {}]
      selectedSite: {label: 'All Users', value: 'All Users'},
      origData: [],
      tableData: [],
      sortedColumnIndex: 0,
    };

    this.setTableData = this.setTableData.bind(this);
    this.setSortedData = this.setSortedData.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    console.log("I am a customer admin")

    if (this.props.customer) {
      this.fetchUserData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
        this.fetchUserData();
    }
  }

  fetchUserData() {
    //make site dropdown
    let filter = this.props.sites.filter( site => site.customer === this.props.customer.value)
                  .map(s => ({label: s.site_name, value: s.site}));
    filter.unshift({label: 'All Customer Sites', value: '*'}); //only if you are customer admin and above
    filter.unshift({label: 'All Users', value: 'All Users'}) //summary fiew
    this.setState({sites: filter, selectedSite: {label: 'All Users', value: 'All Users'}})

    fetchCustomerUsers(this.props.authToken, this.props.customer.value)
      .then(json => {
        //console.log("USERS - " + JSON.stringify(json));
        this.setState({users: json.users}, ()=> this.makeTableData());
      })
      .catch(err => console.error(err));
  }

  makeTableData() {
    let data = [];
    for (const u in this.state.users) {
      let user_data = this.state.users[u];
      if (this.state.selectedSite.value === 'All Users')   {
        //summary view - how many unique sites and how many unique roles?
        let unique_sites = {};
        let unique_roles = {};
        user_data.acl.forEach(acl => {
          unique_sites[acl.site] = "";
          unique_roles[acl.role] = "";
        });

        let site_access = Object.keys(unique_sites).length > 1 ? Object.keys(unique_sites).length + " SITES" : Object.keys(unique_sites).length + " SITE";
        if (Object.keys(unique_sites).indexOf('*') > -1) { site_access = 'ALL SITES'}

        data.push({
            username: user_data.username,
            site_access: site_access,
            role: Object.keys(unique_roles).length > 1 ? Object.keys(unique_roles).length + " ROLES" : Object.keys(unique_roles).length + " ROLE",
            id: u,
            email: user_data.email,
            site_id: null,
        })
      } else {
        //site view, wildcard is a site, assume always 1 role per site, will always take the last one
        user_data.acl.forEach(acl => {
          if (acl.site === this.state.selectedSite.value) {
            data.push({
                username: user_data.username,
                site_access: acl.site_name === 'wildcard' ? "All Sites" : acl.site_name,
                role: ROLES_LOOKUP[acl.role],
                id: u,
                email: user_data.email,
                site_id: acl.site,
            })
          }
        })
      }
    }
    this.setState({origData: data, tableData: data});
  }

  setTableData(data) {
    this.setState({tableData: data});
  }

  setSortedData(index, data) {
    this.setState({tableData: data, sortedColumnIndex: index});
  }

  //if wild card - customer end point. if site specific then site -
  deleteUser(id) {
    //console.log("DELETE - " + JSON.stringify(id) + " " + this.state.selectedSite.value);
    let deleteUser =  this.state.selectedSite.value === '*' ?
      deleteCustomerUser(this.props.authToken, this.props.customer.value, id)
      :
      deleteSiteUser(this.props.authToken, this.state.selectedSite.value, id);

    deleteUser.then(json => console.log(json))
      .then(()=> setTimeout(() => this.fetchUserData(), 500))
      .catch(err => {
        console.error(err);
        setTimeout(() => this.fetchUserData(), 500)
      });
  }


  render() {
    return(
          <UserTable
            origData={this.state.origData}
            data={this.state.tableData}
            setTableData={this.setTableData}
            sortedColumnIndex={this.state.sortedColumnIndex}
            setSortedData={this.setSortedData}
            acl={this.props.acl}
            customer={this.props.customer}
            sites={this.state.sites}
            selectedSite={this.state.selectedSite}
            selectSite={(e)=>this.setState({selectedSite: e}, ()=>this.makeTableData())}
            deleteUser={this.deleteUser}
          />
    )
  }
}

export default connect(
  state => ({
    authToken: state.authToken,
    acl: state.user.acl,
    sites: Object.values(state.sites.sites),
  }),
)(UsersCustomerAdmin);

//customer user - site is a wild card - *, "wildcard"
//locix user - customer is a wild card - *, "wildcard" haven't seen the example
/*
"631e7119154548d6bf3b8eb937b89122":{
  "username":"customer-user-custtest",
  "email":"matt-test",
  "acl":[
  {
  "site":"*",
  "site_name":"wildcard",
  "customer":"1cf900025f6c4bba9aa7b76a09dfbbe0",
  "customer_name":"MSCS",
  "role":"customer-user"
  }
  ]
  },
  //parse data, sort by user, # of sites, wildercard is a site
  <i className="fa fa-minus-circle" style={{color: "red"}} onClick={()=>deleteUser(data[props.rowIndex].id)}></i>
*/
