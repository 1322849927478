import React from 'react';
import { Stage, Layer, Line, Rect, Text, Image, Circle, Label, Tag} from 'react-konva';
//import Konva from 'konva';
import { throttle } from 'lodash';


import { TRACKER_CONFIG, /*IMG_CONFIG*/ } from './2dConfig';
//import { getRandomColor } from '../common/Util';

const WIDTH_CUTOFF = 525;
const PIXEL_ADJUSTMENT = { //icon scaling
  1: {
    x : 15,
    y: 44,
  },
  .5: {
    x: 7.5,
    y: 22,
  }
}

const ZONE_ADJUSTMENT = {
  1: {
    font : 22,
    stroke: 5,
  },
  .5: {
    font: 12,
    stroke: 3,
  }
}

class NavMap2d extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      //x_img: null,
      //battery_low_img: null,
      //battery_half_img: null,
      //battery_dead_img: null,
      //refresh_img: null,
      //refresh_inactive_img: null,
      //show_path_img: null,
      //hide_path_img: null,
      zoom_in: null,
      zoom_out: null,
      reset: null,
      nodes: {},
      showAnchors: [],
      node_info: {},
      anchor_info: {}, //{anchorid: false}
      showGTs: [],
      zoom: 1,
      zones: [],
      tracker_imgs: {},
      //show_path: false,
      //paths: {},
      scale: 1, //icon scaling
    };

    this.mapRef = React.createRef();
    //this.myRefs = React.createRef({});
    this.myRefs = {};
    this.loadImages = this.loadImages.bind(this);
    this.unloadImages = this.unloadImages.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.drag = this.drag.bind(this);
    this.reset = this.reset.bind(this);
    this.setAnchorCard = this.setAnchorCard.bind(this);
    this.throttleLoadNodes = throttle(this.loadNodes, 1000);
    this.throttleLoadCards = throttle(this.loadCards, 500);
    this.throttleRenderMap = throttle(this.renderMap, 1000);
    //this.showPath = this.showPath.bind(this);
  }

  componentDidMount() {
    //cal icon scale
    this.setState({scale: this.props.width < WIDTH_CUTOFF ? .5 : 1}, ()=>this.loadZones())
    this.loadImages();

    //make origin
    let newX = this.props.origin.x * this.props.pixels_per_meter;
    let newY = this.props.height - (this.props.origin.y * this.props.pixels_per_meter);
    this.setState({origin: <Circle radius={2} fill={'red'}  x={newX} y ={newY} key={"Origin"}/>})

    //if KH then show small anchor
    newX = (232.626408 + this.props.origin.x) * this.props.pixels_per_meter;
    newY = this.props.height - ((127.013208 + this.props.origin.y) * this.props.pixels_per_meter);
    this.setState({KH: <Circle radius={1} fill={'red'}  x={newX} y ={newY} key={"ANCHOR"}/>})
  }

  componentWillUnmount() {
    console.log("Unmounting Map 2d")
    this.unloadImages();
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.zones).length !== Object.keys(this.props.zones).length) {
      this.loadZones();
    }

    if (Object.keys(prevProps.anchors).length !== Object.keys(this.props.anchors).length) {
      let showAnchors = [];
      let anchorInfo = {}; //{id: false}
      for (const anchorId in this.props.anchors) {
        const anchor = this.props.anchors[anchorId];
        let newX = (anchor.x + this.props.origin.x) * this.props.pixels_per_meter;
        let newY = this.props.height - ((anchor.y + this.props.origin.y) * this.props.pixels_per_meter);
        showAnchors.push(<Rect fill={this.props.isAdmin && !anchor.connected ? '#CA0000' : '#00bd93'} width={8} height={8} x={newX} y ={newY - 5} key={anchorId} anchor={anchorId} onClick={this.setAnchorCard}/>);
        anchorInfo[anchorId] = false;
      }

      this.setState({showAnchors: showAnchors, anchor_info: anchorInfo });
    }

    if (Object.keys(prevProps.gts).length !== Object.keys(this.props.gts).length) {
      let allGTs = [];
      for (const gt in this.props.gts) {
        const g = this.props.gts[gt];
        let newX = (g.x + this.props.origin.x) * this.props.pixels_per_meter;
        let newY = this.props.height - ((g.y + this.props.origin.y) * this.props.pixels_per_meter);
        allGTs.push(<Circle radius={4} fill={"#800080"} x={newX} y={newY} key={gt}/>);
        allGTs.push(<Label key={gt + "_label"} x={newX - 10} y={newY - 25}>
                      <Tag key={gt + "_tag"} fill={'white'} stroke={'rgba(0, 0, 0, 0.15'}/>
                      <Text key={gt + "_info"} text={gt} fontSize={12} fontFamily={'Lato'} fill={'#0068b0'} padding={3}/>
                    </Label>)
      }

      this.setState({showGTs: allGTs})
    }

    /*if (JSON.stringify(Object.values(prevProps.cards)) !== JSON.stringify(Object.values(this.props.cards))) {
        this.loadCards();
      }*/

    this.throttleLoadNodes();
    this.throttleLoadCards();
  }

  /*
  let newAnchorInfo = Object.assign({}, this.state.anchor_info);
  newAnchorInfo[e.target.attrs.anchor] = !this.state.anchor_info[e.target.attrs.anchor];
  console.log(newAnchorInfo)
  this.setState({anchor_info: newAnchorInfo})
  */
  setAnchorCard(e) {
    //console.log(e.target.attrs.anchor);
    this.setState((prevState, props) => ({anchor_info: Object.assign({}, prevState.anchor_info, {[e.target.attrs.anchor]: !prevState.anchor_info[e.target.attrs.anchor]})}), /*() => console.log(this.state.anchor_info)*/);
  }

  loadCards() {
      //console.log("2d component update");
      let show = {...this.state.node_info};

      for (const nodeId in this.props.cards) {
        if (!this.myRefs[nodeId]) {continue}
        if (this.props.cards[nodeId]) {
          show[nodeId] = this.state.nodes[nodeId]
          /*this.myRefs[nodeId].cache();
          this.myRefs[nodeId].filters([Konva.Filters.Brighten]);
          this.myRefs[nodeId].brightness(.25);*/
        } else if (show[nodeId]) {
          /*this.myRefs[nodeId].clearCache();*/
          delete show[nodeId];
        }
      }

      this.setState({node_info: show})
  }

  loadNodes() {
    let newNodes = {};

    /*let paths = { ...this.state.paths };
      Object.keys(paths).forEach( n => {
      if (!this.props.nodes.hasOwnProperty(n)) {
        delete paths[n];
      }
    })*/

    for (const nodeId in this.props.nodes) {
      const node = this.props.nodes[nodeId];
      //console.log(JSON.stringify(node));
      let newX = (node.x + this.props.origin.x) * this.props.pixels_per_meter - PIXEL_ADJUSTMENT[this.state.scale].x;
      let newY = this.props.height - ((node.y + this.props.origin.y) * this.props.pixels_per_meter) - PIXEL_ADJUSTMENT[this.state.scale].y;

      /*do showPath
        if (!paths.hasOwnProperty(nodeId)) {
        paths[nodeId] = {color: node.color ? "#" + node.color : getRandomColor(), points: [newX + PIXEL_ADJUSTMENT_X, newY + PIXEL_ADJUSTMENT_Y]}
      }*/

      if (this.myRefs[nodeId] && this.state.nodes[nodeId] && (node.x !== this.state.nodes[nodeId].x || node.y !== this.state.nodes[nodeId].y)) {
          this.myRefs[nodeId].to({
              x: newX,
              y: newY,
              duration: .5, // 4 or .5 default
          });

          if (this.myRefs[nodeId + "_label"]) {
            this.myRefs[nodeId + "_label"].to({
                x: newX,
                y: newY - 45,
                duration: .5, //4 or .5 default
            });
          }
      /*
        //paths[nodeId].points.push(newX + PIXEL_ADJUSTMENT_X );
        //paths[nodeId].points.push(newY + PIXEL_ADJUSTMENT_Y);
      */
      }

      newNodes[nodeId] = { name: node.name, id: nodeId, nodeId: nodeId, x: node.x, y: node.y, z: node.z,
                           new_x: newX, new_y: newY,
                           selected: false, type: node.type, timestamp: node.timestamp, meas_id: node.meas_id,
                           connected: node.connected, voltage: node.voltage,
                           tdoa_master: node.tdoa_master, addr: node.addr, tdoa_npkt: node.tdoa_npkt,
                           tdoa_ftm: node.tdoa_ftm,
                           tdoa_tracking_mode: node.tdoa_tracking_mode,
                           group: node.group,
                           hardware: node.hardware_version,
                           charge: node.charge,
                           asset: node.asset ? "/" + node.asset : "",
                           img_key: this.state.tracker_imgs.hasOwnProperty(node.group + node.type) ? node.group + node.type : node.type //image defaults to node type if can't find group
                         };
    }
    this.setState({nodes: newNodes, /*paths: paths*/});
  }

  loadZones() {
    let zones = [];
    for (const z in this.props.zones) {
      let zone = this.props.zones[z];
      zones.push(<Text x={zone.x} y ={zone.y} key={zone.name + "_name"} text={zone.name} fontSize={ZONE_ADJUSTMENT[this.state.scale].font} fontFamily={'Lato'} fill={zone.color}/>)
      zones.push(<Line key={zone.name} points={zone.points} stroke={zone.color} strokeWidth={ZONE_ADJUSTMENT[this.state.scale].stroke} closed={true} />)
    }
    this.setState({zones: zones});
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("./img/" + this.props.background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));

    let tracker_imgs = {...this.state.tracker_imgs};
    let tracker_config = {...TRACKER_CONFIG};
    for (const type in tracker_config) {
      tracker_imgs[type] = {
                            active: null,
                            active_sel: null,
                            inactive: null,
                            inactive_sel: null,
                            idle: null,
                            idle_sel: null,
                            low_battery: null,
                            low_battery_sel: null,
                            dead_battery: null,
                            dead_battery_sel: null,
                            dead_disconnected_bat: null,
                            dead_disconnected_bat_sel: null,
                           };

      let temp = new window.Image();
      temp.src = tracker_config[type].active;
      temp.addEventListener('load', ()=>{tracker_imgs[type].active = temp});

      let temp2 = new window.Image();
      temp2.src = tracker_config[type].inactive;
      temp2.addEventListener('load', ()=>{tracker_imgs[type].inactive = temp2});

      let temp3 = new window.Image();
      temp3.src = tracker_config[type].idle;
      temp3.addEventListener('load', ()=>{tracker_imgs[type].idle = temp3});

      /*
      let temp4 = new window.Image();
      temp4.src = tracker_config[type].low_battery;
      temp4.addEventListener('load', ()=>{tracker_imgs[type].low_battery = temp4});
      */

      let temp5 = new window.Image();
      temp5.src = tracker_config[type].active_sel;
      temp5.addEventListener('load', ()=>{tracker_imgs[type].active_sel = temp5});

      let temp6 = new window.Image();
      temp6.src = tracker_config[type].inactive_sel;
      temp6.addEventListener('load', ()=>{tracker_imgs[type].inactive_sel = temp6});

      let temp7 = new window.Image();
      temp7.src = tracker_config[type].idle_sel;
      temp7.addEventListener('load', ()=>{tracker_imgs[type].idle_sel = temp7});

      /*
      let temp8 = new window.Image();
      temp8.src = tracker_config[type].low_battery_sel;
      temp8.addEventListener('load', ()=>{tracker_imgs[type].low_battery_sel = temp8});
      */

      let temp9 = new window.Image();
      temp9.src = tracker_config[type].dead_battery;
      temp9.addEventListener('load', ()=>{tracker_imgs[type].dead_battery = temp9});

      let temp10 = new window.Image();
      temp10.src = tracker_config[type].dead_battery_sel;
      temp10.addEventListener('load', ()=>{tracker_imgs[type].dead_battery_sel = temp10});

      let temp11 = new window.Image();
      temp11.src = tracker_config[type].dead_disconnected_bat;
      temp11.addEventListener('load', ()=>{tracker_imgs[type].dead_disconnected_bat= temp11});

      let temp12 = new window.Image();
      temp12.src = tracker_config[type].dead_disconnected_bat_sel;
      temp12.addEventListener('load', ()=>{tracker_imgs[type].dead_disconnected_bat_sel= temp12});
    }

    this.setState({tracker_imgs: tracker_imgs});

    /*let x = new window.Image();
    x.src = require("./img/x.png");
    bg.addEventListener('load', () => this.setState({x_img: x}));

    let refresh = new window.Image();
    refresh.src = require("./img/refresh.png");
    refresh.addEventListener('load', ()=>this.setState({refresh_img: refresh}));

    let refresh_inactive = new window.Image();
    refresh_inactive.src = require("./img/refresh_inactive.png");
    refresh_inactive.addEventListener('load', ()=>this.setState({refresh_inactive_img: refresh_inactive}));

    let battery_low = new window.Image();
    battery_low.src = require("./img/battery_low.png");
    battery_low.addEventListener('load', ()=> this.setState({battery_low_img: battery_low}));

    let battery_half = new window.Image();
    battery_half.src = require("./img/battery_half.png");
    battery_half.addEventListener('load', ()=>this.setState({battery_half_img: battery_half}));

    let battery_dead = new window.Image();
    battery_dead.src = require('./img/battery_dead.png');
    battery_dead.addEventListener('load', ()=>this.setState({battery_dead_img: battery_dead}));
    */

    let zoom_in = new window.Image();
    zoom_in.src = require('./img/zoom_in.png');
    zoom_in.addEventListener('load', ()=> this.setState({zoom_in: zoom_in}));

    let zoom_out = new window.Image();
    zoom_out.src = require('./img/zoom_out.png');
    zoom_out.addEventListener('load', ()=> this.setState({zoom_out: zoom_out}));

    let reset = new window.Image();
    reset.src = require('./img/reset.png');
    reset.addEventListener('load', ()=> this.setState({reset: reset}));

    /*let show_path = new window.Image();
    show_path.src =IMG_CONFIG['show_path'].active.img;
    show_path.addEventListener('load', ()=> this.setState({show_path_img: show_path}));

    let hide_path = new window.Image();
    hide_path.src = IMG_CONFIG['show_path'].inactive.img;
    hide_path.addEventListener('load', ()=>this.setState({hide_path_img: hide_path}));*/
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));

    let temp = {...this.state.tracker_imgs};
    for (const t in temp) {
      temp[t].active.removeEventListener('load', ()=>temp[t].active = null);
      temp[t].inactive.removeEventListener('load', ()=>temp[t].inactive = null);
      temp[t].idle.removeEventListener('load', ()=>temp[t].idle = null);
      //temp[t].low_battery.removeEventListener('load', ()=>temp[t].low_battery = null);
      temp[t].active_sel.removeEventListener('load', ()=>temp[t].active = null);
      temp[t].inactive_sel.removeEventListener('load', ()=>temp[t].inactive = null);
      temp[t].idle_sel.removeEventListener('load', ()=>temp[t].idle = null);
      //temp[t].low_battery_sel.removeEventListener('load', ()=>temp[t].low_battery = null);
      temp[t].dead_battery.removeEventListener('load', ()=>temp[t].dead_battery = null);
      temp[t].dead_battery_sel.removeEventListener('load', ()=>temp[t].dead_battery_sel = null);
      temp[t].dead_disconnected_bat.removeEventListener('load', ()=>temp[t].dead_disconnected_bat = null);
      temp[t].dead_disconnected_bat_sel.removeEventListener('load', ()=>temp[t].dead_disconnected_bat_sel = null);
    }
    this.setState({tracker_imgs: temp});

    /*this.state.x_img.removeEventListener('load', () => this.setState({x_img: null}));
    this.state.refresh_img.removeEventListener('load', ()=>this.setState({refresh_img: null}));
    this.state.refresh_inactive_img.removeEventListener('load', ()=>this.setState({refresh_inactive_img: null}));
    this.state.battery_low_img.removeEventListener('load', ()=>this.setState({battery_low_img: null}));
    this.state.battery_half_img.removeEventListener('load', ()=>this.setState({battery_half_img: null}));
    this.state.battery_dead_img.removeEventListener('load', ()=>this.setState({battery_dead_img: null}));
    */

    this.state.zoom_in.removeEventListener('load', ()=>this.setState({zoom_in: null}));
    this.state.zoom_out.removeEventListener('load', ()=>this.setState({zoom_out: null}));
    this.state.reset.removeEventListener('load', ()=>this.setState({reset: null}));
    /*this.state.show_path_img.removeEventListener('load', ()=>this.setState({show_path_img: null}));
    this.state.hide_path_img.removeEventListener('load', ()=> this.setState({hide_path_img: null}));*/
  }

  zoomIn(e) {
    if (this.state.zoom >= 2) { return }
    let layer = this.mapRef.current;
    this.setState((prevState, props) => ({zoom: prevState.zoom + .1}))
    layer.scale({x: this.state.zoom , y: this.state.zoom})
    layer.draw();
  }

  zoomOut(e) {
    if (this.state.zoom <= .5) { return }
    let layer = this.mapRef.current;
    this.setState((prevState, props) => ({zoom: prevState.zoom - .1}))
    layer.scale({x: this.state.zoom, y: this.state.zoom})
    layer.draw();
  }

  reset(e) {
    let map = this.mapRef.current;
    map.scale({x: 1, y: 1});
    map.setPosition({x: 0, y: 0});
    map.draw();
    this.setState({zoom: 1});
  }

  drag(e) {
    let layer = this.mapRef.current;
    layer.startDrag();
  }

  /*showPath(e) {
    this.setState((prevState, props) => ({show_path: !prevState.show_path}));
  }*/

  renderNodes() {
    let showNodes = [];
    for (const nodeId in this.state.nodes) {
      const n = this.state.nodes[nodeId];
      let thisNode = "";

      if (n.connected) {
        let img = this.state.node_info.hasOwnProperty(n.nodeId) ? this.state.tracker_imgs[n.img_key].active_sel : this.state.tracker_imgs[n.img_key].active
        thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} onClick={this.props.onShowCard}/>

        if (n.timestamp <= Date.now() - 600000) { //idle = 10 min
          let img = this.state.node_info.hasOwnProperty(n.nodeId) ? this.state.tracker_imgs[n.img_key].idle_sel : this.state.tracker_imgs[n.img_key].idle
          thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} onClick={this.props.onShowCard}/>
        }

        if ((n.hardware === 'LEVT1' && n.voltage < 3.1) || (n.hardware === 'LEVT2' && n.charge < 10)) {
          let img = this.state.node_info.hasOwnProperty(n.nodeId) ? this.state.tracker_imgs[n.img_key].dead_battery_sel : this.state.tracker_imgs[n.img_key].dead_battery
          thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} onClick={this.props.onShowCard}/>
        }
      }

      if (!n.connected) {
        let img = this.state.node_info.hasOwnProperty(n.nodeId) ? this.state.tracker_imgs[n.img_key].inactive_sel : this.state.tracker_imgs[n.img_key].inactive
        thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} onClick={this.props.onShowCard}/>

        if ((n.hardware === 'LEVT1' && n.voltage < 3.1) || (n.hardware === 'LEVT2' && n.charge < 10)) {
          let img = this.state.node_info.hasOwnProperty(n.nodeId) ? this.state.tracker_imgs[n.img_key].dead_disconnected_bat_sel : this.state.tracker_imgs[n.img_key].dead_disconnected_bat
          thisNode = <Image scaleX={this.state.scale} scaleY={this.state.scale} image={img} x={n.new_x} y ={n.new_y} key={n.nodeId} ref={ref => this.myRefs[nodeId] = ref} node={n} onClick={this.props.onShowCard}/>
        }
      }


      showNodes.push(thisNode);
      //showNodes.push(<Circle radius={2} fill={'red'}  x={n.new_x + PIXEL_ADJUSTMENT_X } y ={n.new_y + PIXEL_ADJUSTMENT_Y}/>)
    }

    let showNodeInfo = [];
    for (const nodeId in this.state.node_info) {
      const n = this.state.node_info[nodeId];

      /*let offset_x = 0
      if (this.props.width - n.new_x <= 130 ) {
        offset_x = (130 - (this.props.width - n.new_x)) * -1 //left
      } else if (n.new_x <= 130) {
        offset_x = 100 - n.new_x //right
      }

      let offset_y = 0
      if (n.new_y <= 70) { offset_y = 120 } //top

      let rect =  <Rect x={n.new_x - 100 + offset_x}  y ={n.new_y - 80 + offset_y} width={230} height={70}  onClick={this.props.onShowCard}  key={n.nodeId + "_box"} node={n} fill={'#ffffff'} stroke={'rgba(0, 0, 0, 0.25'}/>
      let thisNodeInfo = n.connected || n.type === 'phone'? <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 65 + offset_y} key={n.nodeId + "_info"} text={n.name} fontSize={16} fontFamily={'Lato'} fill={'#0068b0'}/> :
                                       <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 65 + offset_y} key={n.nodeId + "_info"} text={n.name} fontSize={16} fontFamily={'Lato'} fill={'#797979'}/>
      let thisNodeInfo1 = n.connected ?
                            <Image image={this.state.refresh_img} x={n.new_x - 85 + (n.name.length * 8) + 10 + offset_x} y ={n.new_y - 64 + offset_y} key={n.nodeId + "_refresh"} width={12} height={12} onClick={() => {this.props.onLocate(n.nodeId)}}/> :
                            <Image image={this.state.refresh_inactive_img} x={n.new_x - 85 + (n.name.length * 8) + 10 + offset_x} y ={n.new_y - 65 + offset_y} key={n.nodeId + "_refresh"} width={12} height={12}/>
      if (n.type === 'phone' || n.type === 'tdoa_tracker') {
        thisNodeInfo1 = <Image image={this.state.refresh_img} x={n.new_x - 85 + (n.name.length * 8) + 10 + offset_x} y ={n.new_y - 64 + offset_y} key={n.nodeId + "_refresh"} width={12} height={12} onClick={() => {this.props.onTDOA(n)}}/>
      }
      let thisNodeInfo1_1 = null
      let thisNodeInfo3 = n.connected || n.type === 'phone'? <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 35 + offset_y} key={n.nodeId + "_info3"} text={"LAST LOCATE"} fontSize={10} fontFamily={'Lato'} fill={'#656565'}/> :
                                       <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 35 + offset_y} key={n.nodeId + "_info3"} text={"DISCONNECTED"} fontSize={10} fontFamily={'Lato'} fill={'#ca0000'}/>
      let thisNodeInfo5 = n.connected || n.type === 'phone'?
                            <Text x={n.new_x + 35 + offset_x} y ={n.new_y - 37 + offset_y} key={n.nodeId + "_info5"} text={n.timestamp ? formatTime(n.timestamp) : formatTime(n.meas_id)} fontSize={14} fontFamily={'Lato'} fill={'#132c4f'}/> :
                            <Text x={n.new_x + 35 + offset_x} y ={n.new_y - 37 + offset_y} key={n.nodeId + "_info5"} text={n.timestamp ? formatTime(n.timestamp) : formatTime(n.meas_id)} fontSize={14} fontFamily={'Lato'} fill={'#ca0000'}/>
      let thisNodeInfo8 = <Image image={this.state.x_img} x={n.new_x + 110 + offset_x} y ={n.new_y - 70 + offset_y} node={n} key={n.nodeId + "_info8"} onClick={this.props.onShowCard}/>

      if (n.type !== 'phone') { //battery
        if (!n.connected && n.voltage < 3.1) {
          thisNodeInfo1_1 = <Image image={this.state.battery_dead_img} x={n.new_x - 85 + (n.name.length * 8) + 30 + offset_x} y ={n.new_y - 64 + offset_y} key={n.nodeId + "_battery"} width={20} height={10}/>
        } else if (n.voltage < 3.15) {
          thisNodeInfo1_1 = <Image image={this.state.battery_half_img} x={n.new_x - 85 + (n.name.length * 8) + 30 + offset_x} y ={n.new_y - 64 + offset_y} key={n.nodeId + "_battery"} width={20} height={10}/>
        } else if (n.voltage < 3.273) {
          thisNodeInfo1_1 = <Image image={this.state.battery_low_img} x={n.new_x - 85 + (n.name.length * 8) + 30 + offset_x} y ={n.new_y - 64 + offset_y} key={n.nodeId + "_battery"} width={20} height={10}/>
        }
      }

      showNodeInfo.push(rect, thisNodeInfo, thisNodeInfo1, thisNodeInfo1_1,thisNodeInfo3, thisNodeInfo5, thisNodeInfo8,);


      if (this.myRefs[nodeId + "_label"]) {
        this.myRefs[nodeId + "_label"].to({
            x: n.new_x,
            y: n.new_y - 30,
            duration: .25,
        });
      }
      */

      let thisNodeInfo = <Label key={n.nodeId + "_label"} x={n.new_x } y ={n.new_y - 45} ref={ref => this.myRefs[nodeId + "_label"] = ref}>
                            <Tag key={n.nodeId + "_tag"} fill={'white'} stroke={'rgba(0, 0, 0, 0.25'}/>
                            <Text key={n.nodeId + "_info"} text={n.name + n.asset + "\nx:" + n.x.toFixed(2) + "m  y:" + n.y.toFixed(2) + "m z:" + n.z.toFixed(2) + "m"} fontSize={13} fontFamily={'Lato'} fill={'#0068b0'} padding={7} node={n} onClick={this.props.onShowCard}/>
                          </Label>
      showNodeInfo.push(thisNodeInfo);
    }

    if (this.state.showAnchors.length > 0 && Object.values(this.state.anchor_info).indexOf(true) > -1) {
      this.state.showAnchors.forEach(a => {
        if(this.state.anchor_info[a.props.anchor]) {
          let thisNodeInfo = <Label key={a.props.anchor + "_label"} x={a.props.x - 5} y={a.props.y - 30}>
                                <Tag key={a.props.anchor + "_tag"} fill={'white'} stroke={'rgba(0, 0, 0, 0.25'}/>
                                <Text key={a.props.anchor + "_info"} text={a.props.anchor} fontSize={12} fontFamily={'Lato'} fill={'#0068b0'} padding={7} anchor={a.props.anchor} onClick={this.setAnchorCard} />
                              </Label>
          showNodeInfo.push(thisNodeInfo);
        }
      })
    }

    return [showNodes, showNodeInfo];
  }

  renderMap() {
    return (
      <div className="NavMap2D">
        <Stage width={1055} height={this.props.height < 710 ? 710 : this.props.height}>
          <Layer ref={this.mapRef} onDblClick={this.zoomIn} draggable={true} onDragEnd={(e)=>{console.log("Live Map - drag end")}}/*onMouseDown={this.drag}*/ y={this.props.height >= 700 ? 0 : (700-this.props.height)/2}>
            <Image
              x={0}
              y={0}
              width = {this.props.width}
              height= {this.props.height}
              image={this.state.background}
            />
            {this.props.showZone && this.state.zones}
            {this.state.showAnchors}
            {this.state.showGTs}
            {this.renderNodes()}
            {this.state.origin}
            {this.props.siteId === '96cc946ca4ba419590dd3e5767e3e76b' && this.state.KH}
          </Layer>
          <Layer>
            <Image image={this.state.reset} x={1015} y={5} width={30} height={30} key={'resetImg'} onClick={this.reset}/>
            <Image image={this.state.zoom_in} x={1015} y={40} width={30} height={30} key={'zoomInImg'} onClick={this.zoomIn}/>
            <Image image={this.state.zoom_out} x={1015} y={75} width={30} height={30} key={'zoomOutImg'} onClick={this.zoomOut}/>
          </Layer>
        </Stage>
      </div>
    )
  }

  render() {
    return(this.throttleRenderMap());
  }
}

export default NavMap2d;

/*
  let showPaths = [];
  for (const nodeId in this.state.paths) {
    const p = this.state.paths[nodeId];
    showPaths.push(<Line key={nodeId + "_path"} points={p.points} stroke={p.color} strokeWidth={2}/>)
  }

  {this.state.show_path && showPaths}
  <Image image={this.state.show_path ? this.state.show_path_img : this.state.hide_path_img} x={15} y={5} width={30} height={30} key={'hidePathImg'} onClick={this.showPath}/>

  function formatTime(m) {
    if (m) {
      let d = new Date(m);

      let dateStr = "";
      let today = new Date();
      let midNight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      let diff = d.getTime() - midNight.getTime();

      if (diff < 0 ) {
        dateStr = (d.getMonth() + 1) + "/" + d.getDate() + ", ";
      }

      let hour = d.getHours().toString();
      let min = d.getMinutes().toString();;
      let sec = d.getSeconds().toString();;

      if (hour.length === 1 ) { hour = "0" + hour }
      if (min.length === 1) { min = "0" + min }
      if (sec.length === 1) {sec = "0" + sec}

      return dateStr + hour + ":" + min + ":" + sec;
    } else {
      return "00:00:00";
    }
  }
*/
