import React from 'react';
import {Line} from 'react-chartjs-2';

//Make Stateless Functional Component
const LapisScChart = ({ logger, temp_data}) => {

  let labels = []
  let values = []
  temp_data.forEach(d => {
      labels.push(d[0]);
      values.push(d[1]);
  })

  let data = {
    labels: labels,
    datasets: []
  };

  data.datasets.push ({
      label: logger,
      fill: false,
      lineTension: 0.1,
      borderWidth: 1,
      backgroundColor: 	"#00BFFF", //"#5F9EA0"
      borderColor: "#00BFFF",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: "rgba(95, 158, 160, .5)",
      pointBackgroundColor: "rgba(95, 158, 160, .5)",
      pointBorderWidth: 0,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(95, 158, 160, .5)",
      pointHoverBorderColor: "rgba(95, 158, 160, .5)",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      data: values,
      steppedLine: false,
    }
  )

  const options = {
    maintainAspectRatio: false,
    legend: {position: 'top'},
    hover: {mode: null},
    tooltips: {
        enabled: false
    },
    scales: {
        xAxes: [{
              type: 'time',
              time: {
                stepSize: 5,
                displayFormats: {
                  hour: 'HH:mm',
                }
              }
            }],
    }
  };

  return (
      <div className="lapis-chart">
        Cold Storage Avg Temp<span>&#176;</span>C
        <Line data={data} options={options}/>
      </div>
   );
}

export default LapisScChart;
