import React from 'react';
import { connect } from 'react-redux';
import { triggerCamera } from '../../modules/nodes';
import './AnalyticsPage.css';


const RefreshImage = ({ triggerCamera }) => (
  <button className="refresh-image-box" onClick={triggerCamera}>
  <div className="refresh-image-icon"><div className="fa fa-camera"></div></div>
  <div className="refresh-image-text">REFRESH IMAGE</div>
  </button>
);

export default connect(
  null,
  (dispatch, ownProps) => ({ triggerCamera: (e) => {
    e.preventDefault();
    console.log("Refresh Image " + ownProps.id);
    dispatch(triggerCamera(ownProps.id, 'image'))
  }})
)(RefreshImage);
