import React from 'react';

import { WAREHOUSE, TRACKER_CONFIG, IMG_CONFIG} from './2dConfig';
import { Tooltip } from 'react-tippy';

import NavType from './NavType';
import NavTypeTdoa from './NavTypeTdoa';

class NavList2d extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: [],
      search: null,
      types: [],
      groups: [],
      type_config: {}, //key: group_type, or type
      attachment_config: {attached: true, unattached: false}, //group, has attachments
    };

    this.toggle_all = this.toggle_all.bind(this);
    this.toggle_group = this.toggle_group.bind(this);
    this.toggle_attachment_filter = this.toggle_attachment_filter.bind(this);
    this.filter = this.filter.bind(this);
  }

  //{type: {display_all: true, tdoa: true/false, select_img: "", unselect_img: ""} }
  componentDidMount() {
    this.make_group_type_config();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
      this.make_group_type_config();
    }

    if ((prevProps.nodes === undefined && this.props.nodes) || (Object.keys(prevProps.nodes).length !== Object.keys(this.props.nodes).length)) {
      if (WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('wms')) {
        this.filter_node_selection();
      }
    }

    if (JSON.stringify(prevProps.nodes) !== JSON.stringify(this.props.nodes)) {
      if (this.state.search &&  this.state.search !== "") {
        console.log("need to search")
        let re =  new RegExp(this.state.search, "i")
        let found = this.props.nodes.filter(n => n.name.match(re))
        this.setState({nodes: found});
      } else {
        this.setState({nodes: this.props.nodes});
      }
    }
  }

  //if site is wms, added attached worker filter
  //need to calculate display all state
  make_group_type_config() {
    let groups = WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].group_type : WAREHOUSE['default'].group_type;
    let types = WAREHOUSE.hasOwnProperty(this.props.siteId) ? WAREHOUSE[this.props.siteId].tracker_type : WAREHOUSE['default'].tracker_type;
    let tracker_config = {...TRACKER_CONFIG};

    //groups - wms attachments
    let type_config = {};
    groups.forEach(group => {
      types.forEach(type => {
        //let display_all = type === 'forklift' ? true : false;
        let display_all = WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('group_tracker_select') ?  WAREHOUSE[this.props.siteId]['group_tracker_select'][group + "_" + type] : true;
        type_config[group + "_" + type] = {group: group, type: type, display_all: display_all, tdoa: tracker_config[type].tdoa, select_img: tracker_config[type].select, unselect_img: tracker_config[type].unselect};
      });
    });

    //keep group and type seperate
    if (groups.length === 0) {
      types.forEach(type => {
        type_config[type] = {type: type, display_all: true, tdoa: tracker_config[type].tdoa, select_img: tracker_config[type].select, unselect_img: tracker_config[type].unselect};
      });
    }

    this.setState({groups: groups, types: types, type_config: type_config});
  }

  filter(e) {
    let re =  new RegExp(e.target.value, "i")
    let found = this.props.nodes.filter(n => n.name.match(re))

    this.setState({search: e.target.value, nodes: found});
  }

  toggle_all(e) { //node type
    let flag = false
    if (e.target.dataset.checked === "true") {
      flag = true
    }

    let type_config = {...this.state.type_config};
    type_config[e.target.dataset.type].display_all = flag;

    /*
    this.setState({type_config: type_config});
    this.props.onSelectAll(e)
    */

    if (WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('wms') && e.target.dataset.type === 'worker' && flag === true) {
      //if worker is true and site is wms,  need to filter attached/unattached
      this.setState({type_config: type_config}, ()=>this.filter_node_selection());
    } else {
      this.setState({type_config: type_config});
      this.props.onSelectAll(e)
    }
  }

  toggle_group(e) {
    /*console.log(e.target.dataset.group)
    console.log(e.target.dataset.type)*/
    let flag = false
    if (e.target.dataset.checked === "true") {
      flag = true
    }

    let type_config = {...this.state.type_config};
    type_config[e.target.dataset.group + "_" + e.target.dataset.type].display_all = flag;
    if (WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('wms') && e.target.dataset.type === 'worker' && flag === true) {
      //if worker is true and site is wms,  need to filter attached/unattached
      this.setState({type_config: type_config}, ()=>this.filter_node_selection());
    } else {
      this.setState({type_config: type_config});
      this.props.onSelectAll(e)
    }
  }

  toggle_attachment_filter(e) {
    let config = {...this.state.attachment_config}
    config[e.target.dataset.filter] = JSON.parse(e.target.dataset.filterby);
    this.setState({attachment_config: config}, ()=>this.filter_node_selection());
  }

  filter_node_selection() { //for all workers, attached and/or unattached [[id, true/false]]
    //find group that has all on
    let selected_groups = [];
    let selected_types = [];

    for (const g_type in this.state.type_config) {
      if (g_type.indexOf("_") > 0) {
        let group = g_type.split("_")[0];
        let type = g_type.split("_")[1];

        if (type !== 'worker') { continue;}
        if (!this.state.type_config[g_type].display_all) { continue; }
        selected_groups.push(group);
      } else { //type only
        if (g_type !== 'worker') { continue;}
        if (!this.state.type_config[g_type].display_all) { continue; }
        selected_types.push(g_type);
      }
    }

    /*console.log(this.state.type_config)
    console.log(JSON.stringify(selected_groups));*/

    let result = []
    this.props.nodes.forEach(node => { //refine all selection with attachment/unattached filters
      if (node.type !== 'worker') { return; }
      result.push([node.id, false]);

      if (selected_groups.includes(node.group) || selected_types.includes(node.type)) {
        if (!this.state.attachment_config.attached && !this.state.attachment_config.unattached) {
          result.push([node.id, true]);
          return;
        }

        if (node.asset) { //attached
          result.push([node.id, this.state.attachment_config.attached]);
        } else {
          result.push([node.id, this.state.attachment_config.unattached]);
        }
      }
    })

    this.props.onSelectIds(result);
  }

  render() {
    return (
      <div style={this.props.display ? {display: "inline-block" } : {display: "none"}}>
        <div className="NavList2D">
        <div className="AvailableDevices">&nbsp;</div>
        <div className="AnalyticsIconContainer">
          <Tooltip title={this.props.view === 'map' ? IMG_CONFIG['map']['active']['tooltip'] : IMG_CONFIG['map']['inactive']['tooltip']} theme="transparent">
            <img className="AnalyticsIcon" alt="maps" data-view="map" src={this.props.view === 'map' ? IMG_CONFIG['map']['active']['img'] : IMG_CONFIG['map']['inactive']['img']} onClick={this.props.setView}/>
          </Tooltip>
          <Tooltip title={this.props.view === 'chart' ? IMG_CONFIG['analytics']['active']['tooltip'] : IMG_CONFIG['analytics']['inactive']['tooltip']} theme="transparent">
            <img className="AnalyticsIcon" alt="analytics" data-view="chart" src={this.props.view === 'chart' ? IMG_CONFIG['analytics']['active']['img'] : IMG_CONFIG['analytics']['inactive']['img']} onClick={this.props.setView}/>
          </Tooltip>
        </div>
          <div className="BlueLine"/>
          <div>
            <input type="text" className="SearchNodesBox" onChange={this.filter}/>
            <div className="fa fa-search SearchNodesIcon"/>
          </div>
          <div id="floor">
          { //attached and unattached filter buttons
            WAREHOUSE.hasOwnProperty(this.props.siteId) && WAREHOUSE[this.props.siteId].hasOwnProperty('wms') && WAREHOUSE[this.props.siteId]['wms'] &&
            <div className="Filter">
              { this.state.attachment_config['attached'] ?
                <Tooltip title={IMG_CONFIG["checked_out"].active.tooltip} theme="transparent">
                  <img key="checked_out" className="SelectGroupIcon" alt="select" data-filter="attached" data-filterby={false} src={IMG_CONFIG["checked_out"].active.img} onClick={this.toggle_attachment_filter}/>
                </Tooltip>
                :
                <Tooltip title={IMG_CONFIG["checked_out"].inactive.tooltip} theme="transparent">
                  <img key="checked_out" className="SelectGroupIcon" alt="select" data-filter="attached" data-filterby={true} src={IMG_CONFIG["checked_out"].inactive.img} onClick={this.toggle_attachment_filter}/>
                </Tooltip>
              }
              {
                this.state.attachment_config['unattached'] ?
                <Tooltip title={IMG_CONFIG["checked_in"].active.tooltip} theme="transparent">
                  <img key="chcked_in" className="SelectGroupIcon" alt="select" data-filter="unattached" data-filterby={false} src={IMG_CONFIG["checked_in"].active.img} onClick={this.toggle_attachment_filter}/>
                </Tooltip>
                :
                <Tooltip title={IMG_CONFIG["checked_in"].inactive.tooltip} theme="transparent">
                  <img key="chcked_in" className="SelectGroupIcon" alt="select" data-filter="unattached" data-filterby={true} src={IMG_CONFIG["checked_in"].inactive.img} onClick={this.toggle_attachment_filter}/>
                </Tooltip>
              }
            </div>
          }
          { //groups and types
            this.state.groups.map(group => {
              let floor = <div className="Group" key={group}>{group}</div>
              let types = this.state.types.map(type =>
               <Tooltip key={type + "_tooltip"} title={this.state.type_config[group + "_" + type].display_all ? IMG_CONFIG[type]['active']['tooltip'] : IMG_CONFIG[type]['inactive']['tooltip']} theme="transparent">
               {
                 this.state.type_config[group + "_" + type].display_all ?
                  <img key={group + "_" + type+ "_select"} className="SelectGroupIcon" data-checked="false" data-group={group} data-type={type} alt="select" src={this.state.type_config[group + "_" + type].select_img} onClick={this.toggle_group}/> :
                  <img key={group + "_" + type + "_unselect"} className="SelectGroupIcon" data-checked="true" data-group={group} data-type={type} alt="unselect" src={this.state.type_config[group + "_" + type].unselect_img} onClick={this.toggle_group}/>
               }
               </Tooltip>
             )
             return ([floor, types])
            }
           )
          }
          </div>
          <div id="type">
          { //type only
            this.state.groups.length === 0 &&
             this.state.types.map(type =>
             <Tooltip key={type + "_tooltip"} title={this.state.type_config[type].display_all ? IMG_CONFIG[type]['active']['tooltip'] : IMG_CONFIG[type]['inactive']['tooltip']} theme="transparent">
             {
               this.state.type_config[type].display_all ?
                <img key={type + "_select"} className="SelectIcon" data-checked="false"  data-type={type} alt="select" src={this.state.type_config[type].select_img} onClick={this.toggle_all}/> :
                <img key={type + "_unselect"} className="SelectIcon" data-checked="true" data-type={type} alt="unselect" src={this.state.type_config[type].unselect_img} onClick={this.toggle_all}/>
             }
             </Tooltip>
            )
          }
          </div>
          <div className="Line2"/>
          { //floor group
            this.state.groups.map(group =>
              this.state.types.map(type =>
                this.state.type_config[group + "_" + type].tdoa ?
                <NavTypeTdoa key={group + "_" + type + "_type"}
                         type={type}
                         group={group}
                         nodes={this.state.nodes.filter(n => n.type === type && n.group === group)}
                         showNodes={this.props.showNodes}
                         onSelect={this.props.onSelect}
                         onTDOA={this.props.onTDOA}
                         onShowCard={this.props.onShowCard}
                         display_all_nodes={this.state.type_config[group + "_" + type].display_all}
                 />
                 :
                <NavType key={group + "_" + type + "_type"}
                         type={type}
                         group={group}
                         nodes={this.state.nodes.filter(n => n.type === type && n.group === group)}
                         showNodes={this.props.showNodes}
                         onSelect={this.props.onSelect}
                         onLocate={this.props.onLocate}
                         onUnlink={this.props.onUnlink}
                         isAdmin={this.props.isAdmin}
                         onShowCard={this.props.onShowCard}
                         display_all_nodes={this.state.type_config[group + "_" + type].display_all}
                 />
              )
            )
          }
          { //type only
            this.state.groups.length === 0 &&
            this.state.types.map(type => (
             this.state.type_config[type].tdoa ?
             <NavTypeTdoa key={type + "_type"}
                      type={type}
                      nodes={this.state.nodes.filter(n => n.type === type)}
                      showNodes={this.props.showNodes}
                      onSelect={this.props.onSelect}
                      onTDOA={this.props.onTDOA}
                      onShowCard={this.props.onShowCard}
                      display_all_nodes={this.state.type_config[type].display_all}
              />
              :
             <NavType key={type + "_type"}
                      type={type}
                      nodes={this.state.nodes.filter(n => n.type === type)}
                      showNodes={this.props.showNodes}
                      onSelect={this.props.onSelect}
                      onLocate={this.props.onLocate}
                      onUnlink={this.props.onUnlink}
                      isAdmin={this.props.isAdmin}
                      onShowCard={this.props.onShowCard}
                      display_all_nodes={this.state.type_config[type].display_all}
              />
            )
          )}
        </div>
      </div>
    )
  }
}

export default NavList2d;

/*
{this.state.workers.map(n=><NavNode key={n.id} node={n} onSelect={this.props.onSelect} onLocate={this.props.onLocate} checked={this.props.showNodes[n.id].show} disable={false} onShowCard={this.props.onShowCard}/>)}
{this.state.nWorkers.map(n=><NavNode key={n.id} node={n} onLocate={this.props.onLocate} checked={false} disable={true}/>)}

{this.state.types.maps(type => this.state.tracker_config[type].display_all ?
    <img className="SelectIcon" data-checked="false" data-type={type} alt="select" src={require(this.state.tracker_config[type].select_img)} onClick={this.toggle_all_forklift}/> :
    <img className="SelectIcon" data-checked="true" data-type={type} alt="unselect" src={require(this.state.tracker_config[type].unselect_img)} onClick={this.toggle_all_forklift}/>
  )}


{
  this.state.display_all_forklift ?
    <img className="SelectIcon" data-checked="false" data-type="forklift" alt="forklift_select" src={require("./img/forklift_select.png")} onClick={this.toggle_all_forklift}/> :
    <img className="SelectIcon" data-checked="true" data-type="forklift" alt="forklift_unselect" src={require("./img/forklift_unselect.png")} onClick={this.toggle_all_forklift}/>
}
{
  this.state.display_all_workers ?
    <img className="SelectIcon" data-checked="false" data-type="worker" alt="worker_select" src={require("./img/worker_select.png")} onClick={this.toggle_all_workers}/> :
    <img className="SelectIcon" data-checked="true" data-type="worker" alt="worker_unselect" src={require("./img/worker_unselect.png")} onClick={this.toggle_all_workers}/>
}



//SearchBox - onChange={this.setFilterData}
//<input type="checkbox" className="CheckBoxAll" data-type="forklift" defaultChecked={this.state.display_all_forklift} onChange={this.toggle_all_forklift}/>
//<input type="checkbox" className="CheckBoxAll" data-type="worker" defaultChecked={this.state.display_all_workers} onChange={this.toggle_all_workers}/>
/*
<div style={this.props.maptype === 'realtime' || this.props.maptype.startsWith("path") ? {} : {display: "none"}}>
  <input type="checkbox" className="CheckBoxAll"  defaultChecked={this.props.displayAnchors} onChange={this.props.showAnchors}/>
  <div className="CheckBoxAllText">SHOW ANCHORS</div>
  <div className="Line2"/>
</div>

this.state.type_config[type].display_all ?
 <img key={type + "_select"} className="SelectIcon" data-checked="false" data-type={type} alt="select" src={require("./img/" + this.state.type_config[type].select_img)} onClick={this.toggle_all}/> :
 <img key={type + "_unselect"} className="SelectIcon" data-checked="true" data-type={type} alt="unselect" src={require("./img/" + this.state.type_config[type].unselect_img)} onClick={this.toggle_all}/>

 <img
    key={type} className="SelectIcon"
    data-checked={!this.state.type_config[type].display_all} data-type={type} alt="select"
    src={this.state.type_config[type].display_all ? require("./img/" + this.state.type_config[type].select_img) : require("./img/" + this.state.type_config[type].unselect_img)}
    onClick={this.toggle_all}
 />
*/
