import React, { useState, useEffect } from 'react';

const LpsMenuZone = (props) => {
  const [openZone, setOpenZone] = useState(false);
  const [zoneConfig, setZoneConfig] = useState(props.zone);

  useEffect(() => {
    if (props.zone && props.selected_zone && props.zone.id === props.selected_zone.id) {
      setOpenZone(true);
      setZoneConfig(props.selected_zone);
    } else {
      setOpenZone(false);
      setZoneConfig(props.zone);
    }
  }, [props.selected_zone])

  const editZone = (e) => {
    let temp = !openZone;
    setOpenZone(temp);

    if (temp) {
      props.selectZone(zoneConfig);
    } else {
      props.selectZone(null);
    }
  }

  const updateField = (field, e) => {
    if (e.target.value.trim() === '') { return; }

    let config = {...zoneConfig};
    config[field] = e.target.value;
    setZoneConfig(config);
    props.selectZone(config);
  }

  const saveZone = (e) => {
    e.stopPropagation();
    props.saveZone(zoneConfig);
    setOpenZone(false);
  }

  const deleteZone = (e) => {
    e.stopPropagation();
    props.deleteZone(zoneConfig);
    setOpenZone(false);
  }

  return (
    <div>
      <div className="lps_menu_anchor" style={{background: openZone ? "white" : "", color: openZone ? "#1b5faa" : ""}}>
        {props.zone.name}
        <img className="edit" alt="Edit Anchor" src={openZone ? require('../2dReact/img/x.png') : require('../2dReact/img/edit.png')} data-node={props.zone.id} onClick={(e)=>{editZone(e)}}/>
      </div>
      <div style={{display: openZone ? "block" : "none"}}>
        <div className="lps_line"/>
        <div className="anchor_param">NAME<input type="text" value={zoneConfig.name} onChange={(e)=>{updateField('name', e)}}/></div>
        <div className="anchor_param short">COLOR
          <input type="text" value={zoneConfig.color} onChange={(e)=>{updateField('color', e)}}/>
          <div className="color" style={{backgroundColor: zoneConfig.color}}/>
        </div>
        <div className="anchor_param">
          <button onClick={saveZone}>SAVE</button>
          <div className="delete" style={{display: "inline-block"}}><button onClick={deleteZone}/></div>
        </div>
      </div>
      <div className="lps_line"/>
    </div>
  )
}

export default LpsMenuZone;
