import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';

import { createLapisTracker, fetchLapisTracker, fetchLapisData } from '../../modules/api';

const LapisEditDevice = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [config, setConfig] = useState({site_id: "b4d8242e-a850-45df-8b2b-84a55e00497b", id: "", name: "", serial_number: "", sigfox_id: "", sigfox_pac: ""});
  const [error, setError] = useState({site_id: "b4d8242e-a850-45df-8b2b-84a55e00497b", id: "", name: "", serial_number: "", sigfox_id: "", sigfox_pac: ""});
  const [fields, setFields] = useState({});
  const [info, setInfo] = useState({});
  const [disable_save, setDisable_save] = useState(false);

  useEffect(()=> {
    if (props.match.params.id === "undefined" ) { return; }
    if (props.match.params.id === id) { return; }
    console.log("useEffect");
    switch(props.match.params.id) {
      case 'new':
        resetForm(null);
        break;
      default:
        resetForm(props.match.params.id)
    }
  }, [props.match.params.id]);

  const resetForm = (id) => {
    if (id) {
      console.log(id);
      setId(props.match.params.id);

      fetchLapisTracker(id)
        .then(json => {
          //console.log(json)
          setEditForm(true);
          setConfig({
            site_id: "b4d8242e-a850-45df-8b2b-84a55e00497b",
            id: json._id,
            name: json.name,
            serial_number: json.serial_number,
            sigfox_id: json.sf_id,
            sigfox_pac: json.sf_pac
          });

          setFields({
                "name": {"type": "text", "value": json.name, "error": ""},
                "sigfox_id": {"type": "text", "value": json.sf_id, "error": ""},
                "sigfox_pac": {"type": "text", "value": json.sf_pac, "error": ""},
            });

          setInfo({ "System ID": json._id });
        })

      /*
      fetchLapisData("trackers")
        .then(json => {
          json.forEach(tk => {
            if (tk._id === id) {
              //console.log(JSON.stringify(tk))
              setEditForm(true);
              setConfig({
                site_id: "b4d8242e-a850-45df-8b2b-84a55e00497b",
                id: tk._id,
                name: tk.name,
                serial_number: tk.serial_number,
                sigfox_id: tk.sf_id,
                sigfox_pac: tk.sf_pac
              });

              setFields({
                    "name": {"type": "text", "value": tk.name, "error": ""},
                    "sigfox_id": {"type": "text", "value": tk.sf_id, "error": ""},
                    "sigfox_pac": {"type": "text", "value": tk.sf_pac, "error": ""},
                });

              setInfo({ "System ID": tk._id });
            }
          })
        })
      */

    } else {
      console.log("set newForm")
      setId('new');
      setEditForm(false);
      setConfig({site_id: "b4d8242e-a850-45df-8b2b-84a55e00497b", id: "", name: "", serial_number: "", sigfox_id: "", sigfox_pac: ""});
      setFields({});
      setInfo({});
      setDisable_save(false);
    }

    setError({anchor: "", x: "", y: "", z: "", status: "" });
  }

  const validateFields = () => {
    let validate = true;
    let errorNew = {...error};

    if (config.sigfox_id.trim() === '') {
      validate = false;
      errorNew['sigfox_id'] = 'Device ID has to be not empty';
    }

    if (config.sigfox_pac.trim() === '') {
      validate = false;
      errorNew['sigfox_pac'] = 'PAC code has to be not empty';
    }

    setError(errorNew);
    return validate;
  };

  const handleSubmit = (e)=> {
    if (!validateFields()) { return; }

    console.log("Submit");
    if (editForm) {
      console.log("Update Node");
    } else {

      console.log("Save Node....");

      let body = {
          site_id: config.site_id,
          name: config.name,
          serial_number: config.serial_number,
          sigfox_id: config.sigfox_id,
          sigfox_pac: config.sigfox_pac,
      };

      console.log(JSON.stringify(body))
      createLapisTracker(body)
        .then(json => console.log(json));
    }

    setError(Object.assign({}, error, {status: "Saving device..."}))
    setTimeout(()=>props.history.push("/dashboard/lapis/setting"), 700);
  };

  const updateField = (field, e) => {
    setConfig(Object.assign({}, config, {[field]: e.target.value}));
  }

  return(
    <div className="main edit">
      <Link to={"/dashboard/lapis/setting"}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? "Device " : "Add New Device"}</h3>
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        { editForm ?
          <div key="edit">
          <LabelContainer labels= {{"Device Name": {"help": "", "desc": ""}, "Device ID": {"help": "", "desc": ""}, "PAC Code": {"help": "", "desc": ""}}}/>
          <FormContainer
            fields = {fields}
            updateField={updateField}
          />
          <InfoContainer info={info} />
          </div>
          :
          <div key="save">
            <LabelContainer labels= {{"Device Name": {"help": "", "desc": ""}, "Device ID": {"help": "", "desc": ""}, "PAC Code": {"help": "", "desc": ""}}}/>
            <FormContainer
              fields = {{
                  "name": {"value": config.name, "placeholder": "", "error": error.name},
                  "sigfox_id": {"value": config.sigfox_id, "placeholder": "", "error": error.sigfox_id},
                  "sigfox_pac": {"value": config.sigfox_pac, "placeholder": "", "error": error.sigfox_pac},
              }}
              updateField={updateField}
            />
          </div>
        }
      </div>

      <div style={{display: disable_save ? "none" : "block"}}>
          {
            !editForm &&
            <div>
               <Link to={"/dashboard/lapis/setting"}><button className="cancel">Cancel</button></Link>
               <span id="save"><button className="save" disabled={disable_save} onClick={e => handleSubmit()}>Save</button></span>
               <div className="lapis-message">Devices will not appear until they've been associated</div>
            </div>
         }
      </div>
    </div>
  );
}

export default LapisEditDevice;

/*Create tracker
POST: https://api.np.locix.com/stt/test/1.0/trackers/
{
  "site_id": "b4d8242e-a850-45df-8b2b-84a55e00497b",
  "name": "New Tracker",
  "serial_number": "123456",
  "sigfox_id": "FFFFFF",
  "sigfox_pac": "100E36B288DF308B"
}
{
  "id": "a02dfc4a-97b1-4529-9e1e-557c8faf2b70"
}
*/
