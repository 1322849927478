/*
export const API_ROOT = 'https://prodk.api.locix.com/pipeline/r/api/v2/client/';
export const WS_ROOT = 'wss://prodk.api.locix.com/pipeline/ws/updates/v2/sites/';
*/

//Zainar Cloud - 1/24/23
export const API_ROOT = process.env.REACT_APP_API_ROOT || 'https://api.cloud.zainartech.com/pipeline/r/api/v2/client/';
export const WS_ROOT = process.env.REACT_APP_WS_ROOT || 'wss://api.cloud.zainartech.com/pipeline/ws/updates/v2/sites/';

/*
Zainar Super User
User: test
Password: %%##toto3

Zainar customer User
User: zainar
Password: zainar123!@#
*/
