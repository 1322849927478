const THREE = require('three');
require('./ColladaLoader2.js');
const EventEmitter = require('events');

const COLOR_NODE_SELECTED = 0xff0000;
const COLOR_NODE_UNSELECTED = 0x6080ff;
const COLOR_ANCHOR = 0x40ff40;
const MOUSE_WHEEL_TRANSLATE_FACTOR = 0.1;

class World extends EventEmitter {
  constructor(colladaSite) {
    super();
    this._scene = new THREE.Scene();

    this._camera = new THREE.PerspectiveCamera( 30, 1, 0.1, 1000);

    this._renderer = new THREE.WebGLRenderer({alpha:true, antialias: true});
    this._renderer.shadowMap.enabled = true;
    this._renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this._container = undefined;

    this._ambient = new THREE.AmbientLight(0xffffff, 0.5);
    this._scene.add(this._ambient);

    this._nodes = {}
    this._floorplan = undefined;
    this._animations = [];
    this._animateThis = () => { this._animate(); }

    this._mouseDown = false;
    this._downEvent = undefined;
    this._downButton = undefined;
    this._downCameraYaw = undefined;
    this._downCameraPitch = undefined;

    // Load the site floorplan model.
    const loader = new THREE.ColladaLoader();
    loader.load(
        colladaSite,
        (collada) => {
          this._floorplan = collada.scene;
          this._receiveShadows(this._floorplan);
          this._scene.add(this._floorplan);

          // Position the lighting and the camera based on the dimensions
          // of the floorplan model.
          const box = new THREE.Box3().setFromObject(this._floorplan);
          const center = box.getCenter();
          const size = box.getSize();

          // Use an orthographic light with shadows straight down.
          this._light = new THREE.DirectionalLight(0xffffff, 0.5, 100);
          this._light.castShadow = true;
          this._light.position.set(center.x, center.y + 5 * size.y, center.z);
          this._light.target.position.copy(center);
          this._light.shadow.camera.left = -size.x
          this._light.shadow.camera.bottom = -size.z;
          this._light.shadow.camera.right = size.x;
          this._light.shadow.camera.top = size.z;
          this._light.shadow.mapSize.width = 1024;
          this._light.shadow.mapSize.height = 1024;
          this._light.shadow.camera.near = 0.5;
          this._light.shadow.camera.far = 500;
          this._scene.add(this._light);
          this._scene.add(this._light.target);

          // Position the camera.
          this._camera.position = this._camera.position.set(center.x,
              center.y + Math.sqrt(size.x * size.x + size.z * size.z) / 2 * 1.5,
              center.z + size.z) * 1.5;
          this._camera.lookAt(center);

          //this._scene.add(new THREE.CameraHelper(this._light.shadow.camera));

          this._render();
        });

    // Use this raycaster for click selection of nodes and to find the center
    // of rotation when mouse dragging.
    this._raycaster = new THREE.Raycaster();

    this._mouse = new THREE.Vector2();
    this._mouseDown = false;
    this._downEvent = undefined;

    window.addEventListener('resize', () => this._onWindowResize());
  }

  attachToElement(element) {
    this._container = element;
    this._container.appendChild(this._renderer.domElement);
    this._onWindowResize();
    this._container.addEventListener('mousedown',
        (event) => this._onMouseDown(event));
    this._container.addEventListener('mouseup',
        (event) => this._onMouseUp(event));
    this._container.addEventListener('mouseleave',
        (event) => this._onMouseUp(event));
    this._container.addEventListener('mousemove',
        (event) => this._onMouseMove(event));
    this._container.addEventListener('mousewheel',
        (event) => this._onMouseWheel(event));
    this._container.oncontextmenu = () => { return false; };
  }

  _receiveShadows(obj) {
    obj.receiveShadow = true;
    for (let child of obj.children) {
      this._receiveShadows(child);
    }
  }

  _onWindowResize() {
    this._camera.aspect =
        this._container.clientWidth / this._container.clientHeight;
    this._camera.updateProjectionMatrix();
    this._renderer.setSize(this._container.clientWidth,
        this._container.clientHeight);
    this._render();
  }

  _getCanvasCoords(event) {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return {x: x, y: y};
  }

  _getRotationPoint() {
    const rayOrigin = this._camera.position;
    const rayDirection = this._camera.getWorldDirection();
    const ray = new THREE.Ray(rayOrigin, rayDirection);
    const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
    return ray.intersectPlane(plane);
  }

  _onMouseDown(event) {
    event.preventDefault();
    this._mouseDown = true;
    this._downEvent = event;
    this._downButton = event.button;
    this._downCameraYaw = this._cameraYaw;
    this._downCameraPitch = this._cameraPitch;
    const point = this._getRotationPoint();
    this._downCenter = point;
    const dx = (this._camera.position.x - point.x);
    const dy = (this._camera.position.y - point.y);
    const dz = (this._camera.position.z - point.z);
    this._downRadius = Math.sqrt(dx * dx + dy * dy + dz * dz);
    this._downTheta = Math.atan2(dz, dx);
    const d = Math.sqrt(dx * dx + dz * dz);
    this._downPhi = Math.atan2(dy, d);
    this._findNodes(event);
    this._render();
  }

  _onMouseUp(event) {
    event.preventDefault();
    this._mouseDown = false;
    this._render();
  }

  _onMouseMove(event) {
    event.preventDefault();
    if (this._mouseDown) {
    console.log(this._downEvent.button);
      const deltaX = event.clientX - this._downEvent.clientX;
      const deltaY = event.clientY - this._downEvent.clientY;
      if (this._downButton === 0) {
        // Move around on the surface of a fixed radius sphere and always looks
        // at the center.
        const sin = Math.sin;
        const cos = Math.cos;
        const lim = Math.PI / 2 - .01;
        const r = this._downRadius;
        const c = this._downCenter;
        const theta = this._downTheta + deltaX / 300;
        const phid = this._downPhi + deltaY / 300;
        const phi = Math.max(Math.min(lim, phid), -lim);
        const d = r * cos(phi);
        const y = r * sin(phi);
        const x = d * cos(theta);
        const z = d * sin(theta);
        this._camera.position.set(c.x + x, c.y + y, c.z + z);
        this._camera.lookAt(c);
      } else if (this._downButton === 2) {
        this._camera.translateX(-deltaX / 50);
        this._camera.translateY(deltaY / 50);
        this._downEvent = event;
      }
      this._render();
    }
  }

  _onMouseWheel(event) {
    event.preventDefault();
    this._camera.translateZ(event.deltaY * MOUSE_WHEEL_TRANSLATE_FACTOR);
    this._render();
  }

  _findNodes(event) {
    const coords = this._getCanvasCoords(event);
    this._mouse.x = (coords.x / this._container.clientWidth) * 2 - 1;
    this._mouse.y = -(coords.y / this._container.clientHeight) * 2 + 1;
    this._raycaster.setFromCamera(this._mouse, this._camera);
    const intersects =
      this._raycaster.intersectObjects(this._scene.children, true);
    for (const intersect of intersects) {
      const obj = intersect.object;
      if (obj.userData.type && obj.userData.type === 'node') {
        const id = intersect.object.userData.id;
        this.emit('nodeClick', id, event);
        break;
      }
    }
  }

  _deselectAll() {
    for (const id in this._selection) {
      Node.prototype.deselect.call(this._selection[id]);
    }
    this._selection = {}
  }

  addNode(id, x, y, z, selected=false, anchor=false) {
    const node = new Node(this, id, x, z, -y, selected, anchor);
    this._nodes[id] = node;
  }

  removeNode(id) {
    const node = this._nodes[id];
    if (node) {
      node.remove();
    }
    delete this._nodes[id];
  }

  moveNode(id, x, y, z) {
    const node = this._nodes[id];
    if (node) {
      node.move(x, z, -y);
    }
  }

  selectNode(id, select) {
    const node = this._nodes[id];
    if (node) {
      if (select) {
        node.select();
      } else {
        node.deselect();
      }
    }
  }

  _addAnimation(animation) {
    this._animations.push(animation);
    this._startAnimationLoop();
  }

  _cancelAnimation(animation) {
    for (let i = 0; i < this._animations.length; ++i) {
      if (this._animations[i] === animation) {
        animation(0);
        this._animations.splice(i, 1);
        return;
      }
    }
  }

  _startAnimationLoop() {
    if (!this._animationLoop) {
      this._animationLoop = true;
      this._animate();
    }
  }

  _animate() {
    const now = Date.now();
    let activeAnimations = [];
    for (let animation of this._animations) {
      if (animation(now)) {
        activeAnimations.push(animation);
      }
    }
    this._renderer.render(this._scene, this._camera);
    if (activeAnimations.length > 0) {
      requestAnimationFrame(this._animateThis);
    } else {
      this._animationLoop = false;
    }
    this._animations = activeAnimations;
  }

  _render() {
    if (!this._animationLoop) {
      this._renderer.render(this._scene, this._camera);
    }
  }
}

class Node {
  constructor(world, id, x, y, z, selected, anchor) {
    this._world = world;
    if (anchor) {
      const igeometry = new THREE.BoxBufferGeometry(0.125, 0.125, 0.125,
          32, 32, 32);
      //const igeometry = new THREE.TorusBufferGeometry(0.125, 0.0625, 32, 32);
      const imaterial = new THREE.MeshStandardMaterial({
          color: COLOR_ANCHOR});
      this._inner = new THREE.Mesh(igeometry, imaterial);
      this._inner.userData.type = 'anchor';
    } else {
      const igeometry = new THREE.SphereBufferGeometry(0.125, 32, 32);
      const imaterial = new THREE.MeshStandardMaterial({
          color: COLOR_NODE_UNSELECTED});
      this._inner = new THREE.Mesh(igeometry, imaterial);
      this._inner.userData.type = 'node';
    }
    this._inner.castShadow = true;
    this._inner.userData.id = id;
    this._group = new THREE.Group();
    this._group.add(this._inner);

    this._nodeMesh = this._group;
    this._nodeMesh.castShadow = true;
    this._position = new THREE.Vector3(x, y, z);
    this._nodeMesh.position.copy(this._position);
    this._world._scene.add(this._nodeMesh);
    this._moveAnimation = null;
    this._selectAnimation = null;
    this._selected = false;
    if (selected) {
      this.select();
    }
    this._world._render();
  }

  select() {
    this._selected = true;
    if (this._selectAnimation) {
      this._world._cancelAnimation(this._selectAnimation);
    }
    this._inner.material.color.set(COLOR_NODE_SELECTED);
    const start = Date.now();
    this._selectAnimation = (now) =>
      this._selectAnimationUpdate(start, 1, 1.5, now);
    this._world._addAnimation(this._selectAnimation);
  }

  deselect() {
    this._selected = false;
    if (this._selectAnimation) {
      this._world._cancelAnimation(this._selectAnimation);
    }
    this._inner.material.color.set(COLOR_NODE_UNSELECTED);
    const start = Date.now();
    this._selectAnimation = (now) =>
      this._selectAnimationUpdate(start, 1.5, 1, now);
    this._world._addAnimation(this._selectAnimation);
  }

  _selectAnimationUpdate(start, scaleStart, scaleEnd, now) {
    const t = (now - start) / 200;
    if (now !== 0 && t <= 1) {
      const scale = (1 - t) * scaleStart + t * scaleEnd;
      this._inner.scale.set(scale, scale, scale);
      return true;
    }
    return false;
  }

  move(x, y, z) {
    if (this._moveAnimation) {
      this._world._cancelAnimation(this._moveAnimation);
    }
    const targetPosition = new THREE.Vector3(x, y, z);
    const startTimestamp = Date.now();
    this._moveAnimation = (now) => {
      const t = (now - startTimestamp) / 1000;
      if (now !== 0 && t <= 1) {
        const p = 2 * t - (t * t);
        this._nodeMesh.position.set(
          this._position.x * (1.0 - p) + targetPosition.x * p,
          this._position.y * (1.0 - p) + targetPosition.y * p,
          this._position.z * (1.0 - p) + targetPosition.z * p);
        return true;
      } else {
        this._position = targetPosition;
        this._nodeMesh.position.copy(this._position);
        this._moveAnimation = null;
        return false;
      }
    };
    this._world._addAnimation(this._moveAnimation);
  }

  remove() {
    const startTimestamp = Date.now();
    // Remove its node type so it can't be clicked on while it's fading away.
    delete this._inner.userData.type;
    this._inner.material.transparent = true;
    this._inner.castShadow = false;
    this._world._addAnimation((now) => {
      const t = (now - startTimestamp) / 1000;
      if (now !== 0 && t <= 1) {
        this._inner.material.opacity = 1 - t;
        return true;
      } else {
        this._world._scene.remove(this._nodeMesh);
        return false;
      }
    });
  }
}

//module.exports = World;
export default World;
