import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';

import {IMG_CONFIG} from '../2dConfig';
import {makeYAxis} from './ChartUtil';
//import Legend from './Legend';
//import ZoneTimeBarChart from './ZoneTimeBarChartNew';
import TimeIntervalChart from './TimeIntervalChart';

const NOZONE_COLOR = "#FFFFFF";
const options = {
  'default' : { //minutes
    animation: false,
    legend: {
         display: false,
     },
    tooltips: {
          mode: 'nearest'
    },
    scales: {
      yAxes: [{
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
        ticks: {
          min: 0,
          callback: function(value) {
            return value + " min"
          }
        }
      }]
    }
  },
  'percent': {
      responsive: false,
      tooltips: {
            mode: 'nearest'
      },
      animation: false,
      legend: {
           display: false,
       },
      scales: {
        yAxes: [{
          stacked: true,
        }],
        xAxes: [{
          stacked: true,
          ticks: {
            min: 0,
            max: 100,
            callback: function(value) {
              return value + "%"
            }
          }
        }]
      }
    },
}

class ZoneTime extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "time", // "percent", "time"
      show_legend: false,
      labels: [],
      data: [], //percent
      time_data: [], //time chart - retired don't use
      y_axis: [], //time chart
      progression_data: [], //time progression data [{start, duration, color}]
    };

    this.setType = this.setType.bind(this);
    this.setLegend= this.setLegend.bind(this);
  }

  componentDidMount() {
      this.makeData();
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.zone_config).length !== Object.keys(this.props.zone_config).length ||
        prevProps.sorted_nodes.length !== this.props.sorted_nodes.length ||
        Object.keys(prevProps.tracker_stats).length !== Object.keys(this.props.tracker_stats).length ||
        prevProps.from !== this.props.from || prevProps.to !== this.props.to ||
        prevProps.expand !== this.props.expand)
    {
      this.makeData();
    }
  }

  makeData() {
    //make labels, data
    let labels = this.props.sorted_nodes.map(n => n.name);
    let data = [];
    let duration = (this.props.to - this.props.from) / 60000; //in minutes
    let data2 = []; //for time

    //make percentage
    Object.keys(this.props.zone_config).forEach(z => {
      let zoneData = {label: "", data: [], backgroundColor: ""};
      let zoneData2 = {label: "", data: [], backgroundColor: ""};

      zoneData['label'] = this.props.zone_config[z].name;
      zoneData['backgroundColor'] = "#" + this.props.zone_config[z].color;

      zoneData2['label'] = this.props.zone_config[z].name;
      zoneData2['backgroundColor'] = "#" + this.props.zone_config[z].color;

      let array = []; //make data array [tracker1,tracker2, etc. ]
      let array2 = []; //make time array

      this.props.sorted_nodes.forEach(n => {
        if (this.props.tracker_stats.hasOwnProperty(n.id) && this.props.tracker_stats[n.id].hasOwnProperty('zone_stats')) {
          let zone_stats = this.props.tracker_stats[n.id].zone_stats;
          if (zone_stats.hasOwnProperty(z) && zone_stats[z].time > .001) {
            array.push((zone_stats[z].time * 100).toFixed(2));
            array2.push((zone_stats[z].time * duration).toFixed(2));
          } else {
            array.push(0);
            array2.push(0);
          }
        }
      })

      zoneData['data'] = array;
      data.push(zoneData);

      zoneData2['data'] = array2;
      data2.push(zoneData2);
    }) //end object keys

    this.setState({labels: labels, data: data, time_data: data2}, this.makeTimeProgressionData());
  }

  makeTimeProgressionData() {
    let all_data = [];
    this.props.sorted_nodes.forEach(n => {
      if (this.props.tracker_stats.hasOwnProperty(n.id) && this.props.tracker_stats[n.id].hasOwnProperty('zone_intervals')) {
        let data = [];
        data.push({start: 0, duration: 1, color: NOZONE_COLOR});
        this.props.tracker_stats[n.id].zone_intervals.forEach(i => {
          if (i['start'] < 0) { return }
          if (i['duration'] * 100 < .1) { return }
          if (i['zones'].length === 0 || !this.props.zone_config.hasOwnProperty(i['zones'][0])) { return }

          let interval = {start: i.start, duration: i.duration, color: ("#" + this.props.zone_config[i['zones'][0]].color), tooltip:{id: n.name, props: {'Zone': this.props.zone_config[i['zones'][0]].name}}};
          data.push(interval);
        })

        all_data.push(data);
      }
    });
    this.setState({y_axis: makeYAxis(this.props.from, this.props.to), progression_data: all_data})
  }

  setType(e) {
    this.setState({type: e.target.dataset.type});
  }

  setLegend(e) {
    this.setState((prevState, props) => ({show_legend: !prevState.show_legend}))
  }

  render() {
    return (
      <div className="SiteChart">
        {this.props.expand ?
          <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
          <img className="ExpandIcon" alt="expand" data-chart="zone_time" src={require("../img/expand.png")} onClick={this.props.setChartType} />
        }
        <div className="SiteHeader">TIME SPENT IN ZONES
          {
            this.props.displayDownload &&
            <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="lps_zonetime" data-type={this.state.type} onClick={this.props.setDownloadType}/>
          }
        </div>
        <div id="lps_zonetime" className="SiteChartContainer">
        { this.state.type === 'time' ?
          <div>
            <img className="ChartOptionsImg" alt="percent_unselect" data-type='percent' src={require("../img/percent_unselect.png")} onClick={this.setType}/>
            <img className="ChartOptionsImg" alt="time_select" src={require("../img/time_select.png")} />
          </div>
           :
          <div>
            <img className="ChartOptionsImg" alt="percent_select" src={require("../img/percent_select.png")} />
            <img className="ChartOptionsImg" alt="time_unselect" data-type='time' src={require("../img/time_unselect.png")} onClick={this.setType}/>
          </div>
        }
          <div style={{display: this.state.type==='percent' ? "" : "none", marginTop: "30px"}}>
          <HorizontalBar
              key={this.state.labels.length}
              width={980}
              height={this.state.labels.length * 25 + 30}
              data={{labels: this.state.labels, datasets: this.state.data}}
              options={options["percent"]} />
          </div>
          <div style={{display: this.state.type==='time' ? "" : "none"}}>
            <TimeIntervalChart labels={this.state.labels} y_axis={this.state.y_axis} data={this.state.progression_data}/>
          </div>
        </div>
      </div>
    )
  }
}

export default ZoneTime;

/*
<ZoneTimeBarChart labels={this.state.labels} dataSet={this.state.time_data} type={'min'}/>
<TimeIntervalChart labels={this.state.labels} y_axis={this.state.y_axis} data={this.state.time_data}/>

//buttons for charts
{ this.state.type === 'time' ?
  <div>
    <img className="ChartOptionsImg" alt="percent_unselect" data-type='percent' src={require("../img/percent_unselect.png")} onClick={this.setType}/>
    <img className="ChartOptionsImg" alt="time_select" src={require("../img/time_select.png")} />
  </div>
   :
  <div>
    <img className="ChartOptionsImg" alt="percent_select" src={require("../img/percent_select.png")} />
    <img className="ChartOptionsImg" alt="time_unselect" data-type='time' src={require("../img/time_unselect.png")} onClick={this.setType}/>
  </div>
}

 <ZoneTime zone_src={this.props.zone_src} zone_config={this.state.zone_config} sorted_nodes={this.state.sortedNodes} tracker_stats={this.state.tracker_stats} expand={this.state.expand} setClose={this.onSetClose} setChartType={this.onSetChartType}/>}

 "ea6c6b7b877e495a8088fa4e91ac575a":{
 "distance":3850.64567838468,
 "active_time":0.3527544444444446,
 "zone_stats":{
 "10":{
 "distance":525.6226569899189,
 "active_time":0.3477719444444444
 },
 "16":{
 "distance":7.401763616868617,
 "active_time":0.0008022222222222222
 },
 "24":{
 "distance":155.26782346175702,
 "active_time":0.026063888888888886
 }
 },

 /*
 labels: labels, //['Worker1', 'Worker2', 'Worker3', 'Worker3', 'Worker4', 'Worker5'],
 datasets: [
    {
      label: 'ACTIVITY', zone1
      data: active_data, //[0,67.8,0,67.8,67.8,0], //worker1,2,3,4,
      backgroundColor: '#b3f66d', //green zonecolor
    },
    {
      label: 'INACTIVITY', zone2
      data: inactive_data, //[20.7,0,0,0,0,20.7],
      backgroundColor: '#6a6a6a', //black
    },
  ]

  <div style={{display: this.state.show_legend ? "" : "none"}}>
    <Legend zone_src={this.props.zone_src} zone_config={this.props.zone_config}/>
  </div>

  {
    this.state.show_legend ?
    <div>
      <img className="LegendOptionsImg" alt="hide_legend" src={require("../img/hide_legend.png")} onClick={this.setLegend}/>
      <div className="LegendOptionsText">HIDE LEGEND</div>
    </div>
    :
    <div>
      <img className="LegendOptionsImg" alt="show_legend" src={require("../img/show_legend.png")} onClick={this.setLegend}/>
      <div className="LegendOptionsText">SHOW LEGEND</div>
    </div>
  }
  */
