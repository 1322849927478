import React from 'react';
import { Tooltip } from 'react-tippy';

const LabelContainer = (props) => {
  let labels = []
  for (const l in props.labels) {
    labels.push(
      <div className="label" key={l}>
        {props.labels[l].label ? props.labels[l].label : l}
        {props.labels[l].help &&
          <Tooltip title={props.labels[l].help} theme="transparent">
            <img alt="tip" className="help" src={require("../../../media/help.png")}/>
          </Tooltip>
        }
        <div className="label2">{props.labels[l].desc}</div>
      </div>
    )
  }

  return (
    <div className="label_container">
    {labels}
    </div>
  )
}

export default LabelContainer;

//props.labels = {"key": {"label": "", "desc": "", "help": ""}}
