import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
//import NodeCreateContainer from '../settings/components/node_create_container';
import EditNode from './EditNode';
//import EditAnchor from './EditAnchor';
import EditTracker from './EditTracker';
import Sort from '../common/Sort';
import Filter from '../common/Filter';

function editScreen(data, props) {
  if (data[props.rowIndex].node_type === 'tracker') {
    return (<EditTracker key={data[props.rowIndex].node} node={data[props.rowIndex]} displayname={data[props.rowIndex].name}/>);
  } else if (data[props.rowIndex].node_type === 'anchor') {
    //return (<EditAnchor key={data[props.rowIndex].node} node={data[props.rowIndex]} displayname={data[props.rowIndex].name}/>);
    return <Link to={`/dashboard/settings/devices/editanchor/${data[props.rowIndex].node}`}>{data[props.rowIndex].name}</Link>
  } else {
    return (<EditNode key={data[props.rowIndex].node} node={data[props.rowIndex]} displayname={data[props.rowIndex].name}/>);
  }
}

const AnchorTable = ({origData, type, data, error, setTableData, sortedColumnIndex, setSortedData}) => (
  <div className="main">
    <h3>ANCHORS</h3>
    <div className="button-container"><Link to={"/dashboard/settings/devices/editanchor/new"}>ADD NEW</Link></div>
    <span id="error">{error}</span>
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>NAME<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {editScreen(data, props)}
          </Cell>
        )}
        width={215}
      />
      <Column
        header={<Cell>HARDWARE ID<Sort thisColumnIndex={4} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].hardware_id}
          </Cell>
        )}
        width={245}
      />
      <Column
        header={<Cell>VERSION</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].version}
          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell>WIRELESS ADDRESS</Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].configs.anchor.lps_addr}
          </Cell>
        )}
        width={200}
      />
      <Column
        header={<Cell>STATUS<Sort thisColumnIndex={8} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props} style={{color: data[props.rowIndex].connection ? "" : "#ff0000"}}>
            {!data[props.rowIndex].connection ? `Disconnected` : `Connected`}
          </Cell>
        )}
        width={190}
      />
    </Table>
  </div>
);
export default AnchorTable;
