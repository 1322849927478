import React, {useState, useEffect}  from 'react';
import { getRandomColor } from '../../common/Util';

const ColorPicker = (props) => {
  const [color, setColor] = useState(props.value);

  useEffect(() => {
      setColor(props.value);
    }, [props.value])

  const changeColor = () => {
    let ctmp = getRandomColor().substr(1);
    setColor(ctmp);
    props.updateField(props.field, ctmp, 'color_picker');
  }

  const changeColorValue = (e) => {
    setColor(e.target.value);
    props.updateField(props.field, e.target.value, 'color_picker');
  }

  return (
    <div>
      <div className="color" style={{backgroundColor: "#" + color}} onClick={changeColor}/>
      <input type="text"
        value={color ? color : ""}
        onChange={ e => changeColorValue(e)}
      />
    </div>
  )
}

export default ColorPicker;
