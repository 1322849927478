import React from 'react';
import { Link } from 'react-router-dom';
import TruckCameraEvent from '../analytics/TruckCameraEvent';
import {formatTime12Hours, convertToHourMin, cal_full_int_activity, calDockStatus} from './Utility';

const alert = {color: "#c85000" };

function imageHeader(status) {
  let camera_status = "";
  let image = "";

  if (status.endsWith("LOW_BATTERY")) {
    camera_status = <div className="ImgHeader">LOW BATTERY</div>
    image = <img className="BatteryIcon" src={require("./img/low-battery.png")} alt="Low Battery"/>
  }

  return (<div style={{display: "inline-block"}}>{camera_status}{image}</div>)
}

function cameraImage(status, id) {
  let image = <TruckCameraEvent node={id} />

  if (status === 'OFFLINE') {
    image = <img src={require("./img/camera-offline.png")} alt="Offline Camera"/>
  }

  if (status === "OFFLINE_DEAD_BATTERY") {
    image = <img src={require("./img/camera-offline-dead-battery.png")} alt="Dead Battery Camera"/>
  }

  return image;
}

function dockStatus(status) {
  if (status.startsWith("OFFLINE")) {
    return <img className="DockStatusImg" src={require("./img/offline.png")} alt="offline"/>
  }

  if(status.startsWith("OCCUPIED")) {
    return <img className="DockStatusImg" src={require("./img/occupied.png")} alt="occupied"/>
  }

  if (status.startsWith("AVAILABLE")) {
    return <img className="DockStatusImg" src={require("./img/available.png")} alt="available"/>
  }
}

function dockDetail(dock, status, history) {
  if (status.startsWith("OFFLINE")) { return <div></div> }

  if (status.startsWith("OCCUPIED")) {
  let endTime = Date.now();
  let int_result = cal_full_int_activity(history, dock.events.dock.occupancy_change.timestamp, endTime);

  return (
    <div>
      <div className="DockDetail">ARRIVAL TIME
        <div className="DockDetailAttr">{formatTime12Hours(dock.events.dock.occupancy_change.timestamp)}</div>
      </div>
      <div className="DockDetail">TIME ELAPSED
        <div className="DockDetailAttr">{convertToHourMin(endTime - dock.events.dock.occupancy_change.timestamp)}</div>
      </div>
      <div className="DockDetail">INTERIOR ACTIVITY
        <div className="DockDetailAttr">
          {int_result.activity_percent}%
        </div>
     </div>
     <div style={int_result.last_inactivity === 0 ? {display: "none"} : {}}>
     <div style={int_result.departure_gap_alarm ? alert: {}}>
      <div className="DockDetail">LAST ACTIVITY
        <div className="DockDetailAttr">
          {int_result.departure_gap_alarm ? <div className="fa fa-warning GapAlarmIcon"/>: ""}
          {formatTime12Hours(int_result.last_inactivity)}
        </div>
      </div>
     </div>
     </div>
    </div>
    )
  }

  if (status.startsWith("AVAILABLE")) {
    return (
      <div>
        <div className="DockDetail">TIME ELAPSED
          <div className="DockDetailAttr">{convertToHourMin(Date.now() - dock.events.dock.occupancy_change.timestamp)}</div>
        </div>
        <div className="DockDetail">LAST DEPARTURE
          <div className="DockDetailAttr">{formatTime12Hours(dock.events.dock.occupancy_change.timestamp)}</div>
        </div>
      </div>
    )
  }
}

const SmartDockDetail = ({dock, cameras, showThisDock, refresh, history}) => {
  if (dock === undefined || dock === null || history === undefined || history === null || cameras === undefined ||
      Object.keys(cameras).length === 0) {
        return <div></div>
  }

  let ext_status = calDockStatus(dock, cameras[dock.events.dock.occupancy.camera_id]);
  let int_status = calDockStatus(dock, cameras[dock.events.dock.activity.camera_id]);
  return (
    <div className={showThisDock ? "DockDetailContainer" : "DockDetailCard"}>
      {showThisDock ? "" : <Link to={`/dashboard/smart_dock`}><img className="Back" src={require("./img/back.png")} alt="back"/></Link>}
      {ext_status.startsWith('OCCUPIED_DELAY') ? <div className="DockAlertIcon"><div className="fa fa-warning" /></div> : ""}
      <Link className="DockDetailHeader" style={showThisDock && {color: "#1b5faa"}} to={`/dashboard/dock_detail/${dock.node}`}>{dock.name}</Link>
      {
        ext_status.startsWith("OFFLINE") ?
          <img className="DockDetailRefresh" src={require("./img/refresh_disabled.png")} alt="Refresh-disabled"/>
            :
          <img className="DockDetailRefresh" src={require("./img/refresh.png")} alt="Refresh" data-dock={[dock.node, dock.events.dock.occupancy.camera_id, dock.events.dock.activity.camera_id]} onClick={refresh}/>
      }
      {showThisDock ? <img className="DockDetailClose" src={require("./img/close.png")} alt="Close" data-dock={dock.node} onClick={showThisDock}/> : ""}
      <div>
        {imageHeader(ext_status)}
        <div className="ExtCamera">{cameraImage(ext_status, cameras[dock.events.dock.occupancy.camera_id])}</div>
        <div className="ExtName">{cameras[dock.events.dock.occupancy.camera_id].name}
        <div className="ExtDate">{new Date(cameras[dock.events.dock.occupancy.camera_id].events.camera.media_ready.timestamp).toLocaleString()}</div></div>
        {imageHeader(int_status)}
        <div className="ExtCamera">{cameraImage(ext_status, cameras[dock.events.dock.activity.camera_id])}</div>
        <div className="ExtName IntName">{cameras[dock.events.dock.activity.camera_id].name}
        <div className="ExtDate">{new Date(cameras[dock.events.dock.activity.camera_id].events.camera.media_ready.timestamp).toLocaleString()}</div></div>
        <div className="DetailContainer">
          {dockStatus(ext_status)}
          {dockDetail(dock, ext_status, history)}
        </div>
      </div>
    </div>
  )
};

export default SmartDockDetail;
