import React from 'react';
import { connect } from 'react-redux';
import { NavMap } from './NavMap.js';
import { NavNodeList } from './NavNodeList.js';

import { getNodesArray } from '../modules/nodes';
//import { getCurrentSiteId, getSitesArray } from '../modules/sites';
import { getCurrentSiteId} from '../modules/sites';
import { fetchSiteJson } from '../modules/api';

const METERS_PER_FOOT = 0.3048;

class TrackAssetsPage extends React.Component {
  constructor(props) {
    super(props);

    this.onNodeSelect = this.onNodeSelect.bind(this);
    this.onTrackNode = this.onTrackNode.bind(this);
    this.doLocateNode = this.doLocateNode.bind(this);
    this.doTdoaNode = this.doTdoaNode.bind(this);

    this.state = {
      worldNodes: {},
      worldAnchors: {},
      listNodes: [],
      selectedNode: null,
    };
  }

  componentDidMount() {
    console.warn(this.props);
    this.newNodes(this.props.nodes);
    if (this.props.eventManager && !this.props.eventManager.listeners.hasOwnProperty('location_location')) {
      console.log(this.props.eventManager);
      this.props.eventManager.addEventListener('location_location', this);
    }
    // Load in all nodes for the site
    // Add list of nodes to this.state.nodes
    // this.onNewSession(this.props.session);
  }

  componentWillReceiveProps(nextProps) {
    this.newNodes(nextProps.nodes);
    //console.log("TrackAssetsPage receiving props", this.props, nextProps);
    if (nextProps.eventManager && !nextProps.eventManager.listeners.hasOwnProperty('location_location')) {
      nextProps.eventManager.addEventListener('location_location', this);
    }
    // this.onNewSession(this.props.session);
  }

  onNewSession(session) {
    // if (session && session.siteId) {
    //   this.fetchNodes(session);
    //   session.eventManager.addEventListener('location_location', this);
    // }
  }

  fetchNodes(session) {
    session.fetchSiteJson('nodes')
      .then(json => this.newNodes(json.nodes))
      .catch(this.onError);
  }

  onNodeSelect(source, id) {
    // Update the state so it will render as selected
    this.setState(function (prevState, props) {
      const newNodeCollection = {};
      const node = this.state.worldNodes[id];

      // If node state still says selected=false,
      // then this click is selecting it, otherwise it's de-selecting
      const selecting = (node.selected === false);

      for (const nodeId in prevState.worldNodes) {
        newNodeCollection[nodeId] = Object.assign({}, prevState.worldNodes[nodeId]);
        if (nodeId === id) {
          newNodeCollection[nodeId].selected = selecting;
        } else {
          newNodeCollection[nodeId].selected = false;
        }
      }

      const newNodeList = [];

      prevState.listNodes.forEach((node) => {
        const newNode = Object.assign({}, node);
        if (newNode.nodeId === id) {
          newNode.selected = !node.selected;
        } else {
          newNode.selected = false;
        }
        newNodeList.push(newNode);
      });

      return {
        worldNodes: newNodeCollection,
        listNodes: newNodeList,
        selectedNode: selecting ? id : null,
      };
    });
  }

  newNodes(nodes) {
    this.setState((prevState, props) => {
      const newNodeCollection = {};
      const newAnchorCollection = {};

      const nodeList = [];
      nodes.forEach((node) => {
        const nodeId = node.node;
        //console.log('NODE VALUES: ', JSON.stringify(node));
        if (node.node_type === 'tracker') {
            if (node.events.hasOwnProperty('tracker') && node.events.tracker.hasOwnProperty('location') &&
                node.events.tracker.location.hasOwnProperty('meas_id')) {
              //let keys = Object.keys(node.events.tracker.location);
              //let last_key = keys[keys.length - 1];
              //const theLoc = node.events.tracker.location[last_key].filtered;
              const theLoc = node.events.tracker.location.filtered;

              newNodeCollection[nodeId] = {
                selected: this.state.selectedNode === nodeId ? true : false,
                x: theLoc[0] * METERS_PER_FOOT,
                y: theLoc[1] * METERS_PER_FOOT,
                z: theLoc[2] * METERS_PER_FOOT,
                timestamp: parseInt(theLoc.meas_id, 10),
                tracked: false,
              };
            }
          nodeList.push(node);
        }

        if (node.node_type === 'anchor') {
          if (node.configs.anchor.hasOwnProperty('x') && node.configs.anchor.hasOwnProperty('y') && node.configs.anchor.hasOwnProperty('z')) {
            newAnchorCollection[node.name] = {
              x: node.configs.anchor.x / 100 * METERS_PER_FOOT,
              y: node.configs.anchor.y / 100 * METERS_PER_FOOT,
              z: node.configs.anchor.z / 100 * METERS_PER_FOOT,
            }
          }
        }
      });

      return {
        worldNodes: newNodeCollection,
        worldAnchors: newAnchorCollection,
        listNodes: nodeList,
      };
    });
  }

  doLocateNode(nodeId) {
    /*
    let locatePath = `nodes/${nodeId}`;

    let init = {
      method: 'put',
      body: JSON.stringify({
        "node": {
          "configs": {
            "motion": {
              "motion_sensitivity": 205
            }
          }
        }
      }),
    };
    */

    //if(this.props.siteId === 'b07132fedda94bab97c045983e9d5a4d') {
      let locatePath = `nodes/${nodeId}/action`;
      let init = {};
      //interfaces/location/actions/locate`;
      init.method = 'post';
      init.body = JSON.stringify({
        "tracker": {
          "locate": {"ref_id" : 0}
        }
      });

    fetchSiteJson(locatePath, this.props.authToken, this.props.siteId, init)
      .then(json => this.onLocateResponse(json))
      .catch(this.onLocateError);
  }

  doTdoaNode(node) {
    let locatePath = `nodes/${node.configs.tracker.tdoa_master}/action`;
    let init = {};
    let body = {
                "anchor": {
                  "inject": {"command" :  "tdoa " + node.configs.tracker.addr}
                }
              };

    if (node.configs.tracker.tdoa_ftm && node.configs.tracker.tdoa_ftm === 1) {
      body = {
        "anchor": {
          "inject": { "command": "tdoa_ftm " + node.configs.tracker.addr }
        }
      };
    }

  console.log("tdoa_ftm " + node.configs.tracker.tdoa_ftm + " " + JSON.stringify(body));
  init.method = 'post';
  init.body = JSON.stringify(body);

  fetchSiteJson(locatePath, this.props.authToken, this.props.siteId, init)
    .then(json => console.log("TDOA Response: " + JSON.stringify(json)))
    .catch(error => console.log("TDOA Error: ") + error);
  }

  onLocateResponse(jsonResponse) {
    console.log('Locate response: ', jsonResponse);
  }

  onLocateError(error) {
    console.log('Locate error: ', error);
  }

  onTrackNode(nodeId, tracking) {
    console.log("onTrack Method - " + nodeId + " " + tracking);

    let track_num = 0;
    if (tracking) { track_num = 2 }

    let trackPath = `nodes/${nodeId}`;
    let body = {
        "node": {
          "configs": { "tracker": {"tracking_mode": track_num} }
        }
    }

    let init = {};
    init.method = 'put';
    init.body = JSON.stringify(body);

    fetchSiteJson(trackPath, this.props.authToken, this.props.siteId, init)
      .then(json => console.log('Track response: ', json))
      .catch(error => console.log('Track error: ', error));

    this.setState((prevState, props) => {
      const newNodeCollection = {};
      for (const nodeId in prevState.worldNodes) {
        newNodeCollection[nodeId] = prevState.worldNodes[nodeId];
      }
      newNodeCollection[nodeId].tracked = tracking;
      return {
        worldNodes: newNodeCollection,
      };
    });
  }

  receiveWsEvent(event) {
    console.log('TrackAssetsPage received event: ', event);
    // Only care about updating positions of nodes in this map.

    if (this.state.worldNodes[event.nodeId].tracked) {
      this.doLocateNode(event.nodeId);
    }

    const eventLoc = event.location_location;
    this.setState((prevState, props) => {
      const newNodeCollection = {};
      for (const nodeId in prevState.worldNodes) {
        newNodeCollection[nodeId] = Object.assign({}, prevState.worldNodes[nodeId]);
      }
      if (event.nodeId in prevState.worldNodes) {
        newNodeCollection[event.nodeId].x = eventLoc.location.x * METERS_PER_FOOT;
        newNodeCollection[event.nodeId].y = eventLoc.location.y * METERS_PER_FOOT;
        newNodeCollection[event.nodeId].z = eventLoc.location.z * METERS_PER_FOOT;
        newNodeCollection[event.nodeId].timestamp = eventLoc.timestamp;
        newNodeCollection[event.nodeId].selected = prevState.worldNodes[event.nodeId].selected;
      } else {
        newNodeCollection[event.nodeId] = {
          x: eventLoc.location.x * METERS_PER_FOOT,
          y: eventLoc.location.y * METERS_PER_FOOT,
          z: eventLoc.location.z * METERS_PER_FOOT,
          selected: false,
          timestamp: eventLoc.timestamp,
        };
      }
      return {
        worldNodes: newNodeCollection,
        listNodes: prevState.listNodes,
      };
    });
  }

  render() {
    return (<div className="dashboard-content track-assets-page" id="dashboard-content">
      <NavMap
        siteId={this.props.siteId}
        nodes={this.state.worldNodes}
        anchors={this.state.worldAnchors}
        onNodeSelect={this.onNodeSelect}
        doLocateNode={this.doLocateNode}
      />
      <NavNodeList
        nodes={this.state.listNodes}
        onNodeSelect={this.onNodeSelect}
        doLocateNode={this.doLocateNode}
        onTrackNode={this.onTrackNode}
        doTdoaNode={this.doTdoaNode}
      />
      <div className="clear-selection"><button onClick={(e)=>{this.setState({selectedNode: null}, ()=>this.newNodes(this.props.nodes))}}>CLEAR SELECTION</button></div>
      </div>);
  }
}

export default connect(
  state => ({
    nodes: getNodesArray(state),
    eventManager: state.eventManager,
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  }),
)(TrackAssetsPage);
