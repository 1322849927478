import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Sort from '../common/Sort';
import Filter from '../common/Filter';
import Download from '../common/Download';
import DateRange from '../common/DateRange';

const WorkersTable = ({
  from,
  to,
  loading,
  origData,
  data,
  fileContent,
  error,
  setTableData,
  sortedColumnIndex,
  setSortedData,
  setFromTo
}) => (
  <div className="main">
    <h3>WORKER LOG</h3>
    <span>{error}</span>
    <div className="button-container">
      <Download from={from} to={to} file_content={fileContent}/>
    </div>
    <div className="line"/>
    <div>
      <div style={{display: "inline-block"}}><DateRange from={from} to={to} setFromTo={setFromTo}/></div>
      <div style={{marginLeft: "10px", display: "inline-block"}}><Filter data={origData} setNewData={setTableData} /></div>
      <Table
        rowsCount={data.length}
        rowHeight={45}
        headerHeight={35}
        width={1075}
        height={500}
      >
        <Column
          header={<Cell>Date<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
          cell={props => (
            <Cell {...props}>
              {data[props.rowIndex].date}
            </Cell>
          )}
          width={215}
        />
        <Column
          header={<Cell>Time<Sort thisColumnIndex={1} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
          cell={props => (
            <Cell {...props}>
              {data[props.rowIndex].time}
            </Cell>
          )}
          width={215}
        />
        <Column
          header={<Cell>Tracker<Sort thisColumnIndex={3} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
          cell={props => (
            <Cell {...props}>
            {data[props.rowIndex].tracker}
            </Cell>
          )}
          width={240}
        />
        <Column
          header={<Cell>GID<Sort thisColumnIndex={4} thisDataType={"number"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
          cell={props => (
            <Cell {...props}>
              {data[props.rowIndex].gid}
            </Cell>
          )}
          width={240}
        />
        <Column
          header={<Cell>CHECK IN/OUT<Sort thisColumnIndex={5} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
          cell={props => (
            <Cell {...props}>
              {data[props.rowIndex].attachment}
            </Cell>
          )}
          width={145}
        />
      </Table>
    </div>
  </div>
);

export default WorkersTable;
