import React from 'react';
import { Table, Column, Cell} from 'fixed-data-table-2';
import { Link } from 'react-router-dom';

const delay = {color: "#c85000", display: "inline-block"};

function dockStatus(status) {
  if (status === 'Occupied') {
    return <div className="Status Occupied">Occupied</div>
  } else if (status === 'Available') {
    return <div className="Status Available">Available</div>
  } else if (status === 'Offline') {
    return <div className="Status Offline">OFFLINE</div>
  }
}

const SmartDockTable = ({rows, sortAsc, changeSort}) => (
  <Table
    className="DocksTable"
    rowHeight={50}
    headerHeight={40}
    rowsCount={rows.length}
    width={1336}
    height={1000}
  >
    <Column columnKey='status'
            header={<Cell className="StatusTableHeader">Status</Cell>}
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                {dockStatus(rows[props.rowIndex].status)}
              </Cell>
            )}
            width={160}
    />
    <Column columnKey='DockName'
            header={<Cell className="StatusTableHeader">Dock Name
                      {sortAsc ?
                        <div className="fa fa-caret-down SortIcon" data-sort="sortDesc" onClick={changeSort}/>
                        :
                        <div className="fa fa-caret-up SortIcon" data-sort="sortAsc" onClick={changeSort}/>
                      }
                    </Cell>
                    }
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                <Link className="DockName" to={`/dashboard/dock_detail/${rows[props.rowIndex].id}`}>{rows[props.rowIndex].dock_name}</Link>
                {rows[props.rowIndex].battery === "low" ? <img className="DockBattery" src={require("./img/low-battery.png")} alt="Low Battery"/> : ""}
                {rows[props.rowIndex].battery === "high" ? <img className="DockBattery" src={require("./img/offline-battery.png")} alt="Offline Battery"/> : ""}
              </Cell>
            )}
            width={160}
    />
    <Column columnKey='TimeAvailable'
            header={<Cell className="StatusTableHeader">Available Time</Cell>}
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                {rows[props.rowIndex].warning ? (<div style={delay}> {rows[props.rowIndex].time_available} </div>) : rows[props.rowIndex].time_available}
              </Cell>
            )}
            width={160}
    />
    <Column columnKey='AvgDwellTime'
            header={<Cell className="StatusTableHeader">Dwell Time</Cell>}
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                {rows[props.rowIndex].warning ? (<div style={delay}> {rows[props.rowIndex].dwell_time} </div>) : rows[props.rowIndex].dwell_time}
                {rows[props.rowIndex].warning ? <div className="AlarmIcon"><div className="fa fa-warning"/></div> : ""}
              </Cell>
            )}
            width={160}
    />
    <Column columnKey='Actions'
            header={<Cell className="StatusTableHeader">Interior Activity</Cell>}
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                {rows[props.rowIndex].warning ? (<div style={delay}> {rows[props.rowIndex].interior} </div>) : rows[props.rowIndex].interior}
              </Cell>
            )}
            width={196}
    />
    <Column columnKey='DwellTime'
            header={<Cell className="StatusTableHeader"># of Trucks</Cell>}
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                {rows[props.rowIndex].warning ? (<div style={delay}> {rows[props.rowIndex].trucks} </div>) : rows[props.rowIndex].trucks}
              </Cell>
            )}
            width={160}
    />
    <Column columnKey='Utlization'
            header={<Cell className="StatusTableHeader">AVG Dwell Time</Cell>}
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                {rows[props.rowIndex].warning ? (<div style={delay}> {rows[props.rowIndex].avg_dwell_time} </div>) : rows[props.rowIndex].avg_dwell_time}
              </Cell>
            )}
            width={160}
    />
    <Column columnKey='LoadUnload'
            header={<Cell className="StatusTableHeader">Avg Utilization</Cell>}
            cell={ props => (
              <Cell className="StatusTableCell" {...props}>
                {rows[props.rowIndex].warning ? (<div style={delay}> {rows[props.rowIndex].utilization}% </div>) : (<div>{rows[props.rowIndex].utilization}%</div>)}
              </Cell>
            )}
            width={180}
    />
    </Table>
);

class SmartDockTableContainer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        orig: [],
        data: [],
        sortAsc: true,
        display: false,
        placeHolder: "STATUS",
        searchTerm1: "", //text input
        searchTerm2: "", //status
      }

      this.showMenu = this.showMenu.bind(this);
      this.setFilterData = this.setFilterData.bind(this);
      this.filterData = this.filterData.bind(this);
      this.sortData = this.sortData.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows) ||
          JSON.stringify(prevProps.stats) !== JSON.stringify(this.props.stats)) {
        let rows = []
        this.props.rows.forEach(r => {
          let row = {...r};
          if (this.props.stats && this.props.stats.hasOwnProperty(r.id)) {
            row.trucks = this.props.stats[r.id].dwell;
            row.avg_dwell_time = this.props.stats[r.id].avg_dwell;
            row.utilization = this.props.stats[r.id].utilization;
          }
          rows.push(row);
        });

        let newRows = [...rows];
        //sort if sortDesc
        if (!this.state.sortAsc) {
          newRows.sort((a,b)=> b.dock_name.localeCompare(a.dock_name));
        }

        //apply filter
        if (this.state.searchTerm1.length !== 0 || this.state.searchTerm2.length !== 0) {
          let re = new RegExp(this.state.searchTerm1, "i");
          let re2 = new RegExp(this.state.searchTerm2, "i");
          newRows = newRows.filter(entry => (entry.dock_name.match(re) || entry.interior.match(re) ) && entry.status.match(re2));
        }

        this.setState({orig: rows, data: newRows});
      }
    }

    showMenu() {
      this.setState((prevState) => ({display: !prevState.display}));
    }

    setFilterData(e) {
      if (e.type === 'change') {
          if (e.target.value.length > 0) {
            this.setState({searchTerm1: e.target.value}, ()=>this.filterData());
          } else {
            this.setState({searchTerm1: ""}, ()=>this.filterData());
          }
      }

      if (e.target.dataset.status) {
        if (e.target.dataset.status === 'All') {
          this.setState({display: false, searchTerm2: "", placeHolder: "STATUS"}, ()=>this.filterData());
        } else {
          this.setState({display: false, searchTerm2: e.target.dataset.status, placeHolder: e.target.dataset.status}, ()=>this.filterData());
        }
      }
    }

    filterData() {
      let data = [...this.state.orig];
      let re =  new RegExp(this.state.searchTerm1, "i")
      let re2 = new RegExp(this.state.searchTerm2, "i");
      let found = data.filter(entry => (entry.interior.match(re) || entry.dock_name.match(re)) && entry.status.match(re2));

      if (!this.state.sortAsc) {
        found.sort((a,b)=> b.dock_name.localeCompare(a.dock_name));
      }
      this.setState({data:found});
    }

    sortData(e) {
      let data = [...this.state.data];
      if (e.target.dataset.sort === 'sortDesc') {
        data.sort((a,b)=> b.dock_name.localeCompare(a.dock_name));
        this.setState({sortAsc: false, data: data})
      } else {
        data.sort((a,b)=> a.dock_name.localeCompare(b.dock_name));
        this.setState({sortAsc: true, data: data})
      }

    }

    render() {
      return (
        <div className="DockTableContainer">
          <input className="DockSearch" type="text" placeholder="SEARCH" onChange={this.setFilterData}/>
          <div className="fa fa-search SearchIcon"/>
          <input type="text" className="DropDown" placeholder={this.state.placeHolder} onClick={this.showMenu}/>
          <div className="fa fa-caret-down DropdownIcon"/>
          <div className="DropDownMenu" style={{display:this.state.display ? "block" : "none"}}>
            <div className="MenuItem" data-status="All" onClick={this.setFilterData}>ALL DOCKS</div>
            <div className="MenuItem" data-status="OCCUPIED" onClick={this.setFilterData}>OCCUPIED</div>
            <div className="MenuItem" data-status="AVAILABLE" onClick={this.setFilterData}>AVAILABLE</div>
            <div className="MenuItem" data-status="OFFLINE" onClick={this.setFilterData}>OFFLINE</div>
          </div>

          <SmartDockTable rows={this.state.data} sortAsc={this.state.sortAsc} changeSort={this.sortData}/>
        </div>
      )
    }
}
export default SmartDockTableContainer;

/*
<div className="Last24">LAST 24 HOURS</div>
*/
