import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { createHub, updateHub, fetchHubs } from '../../modules/hubs';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';
import {isEmpty,isHex, isGreaterEqual, isLessEqual} from '../settings/form/ErrorChecking';

const CONFIG = {hub: "", hardware_id: "", event_offset: "", channel: "", validate: true};
const URL = "/dashboard/settings/hubs";

const EditHubNew = (props) => {
  const [id, setId] = useState(null);
  const [editForm, setEditForm] = useState(props.match.params.id !== 'new');
  const [hub, setHub] = useState(null);
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [channels, setChannels] = useState({});

  useEffect(()=> {
    if (props.match.params.id === "undefined" ) { return; }
    if (props.match.params.id === id ) { return; }

    console.log("useEffect");
    switch(props.match.params.id) {
      case 'new':
        resetForm(null);
        break;
      default:
        resetForm(props.hubsHash[props.match.params.id]);
    }
  });

  const resetForm = (hub) => {
    if (hub) {
      console.log("set editForm " + JSON.stringify(hub));
      setId(props.match.params.id);
      setEditForm(true);
      setHub(hub);
      setConfig({
        hub: hub.name,
        hardware_id: hub.hardware_id,
        event_offset: hub.events && hub.events.hub && hub.events.hub.periodic_command_offset_seconds ? hub.events.hub.periodic_command_offset_seconds: -1,
        channel: hub.events && hub.events.radio && hub.events.radio.initialized && hub.events.radio.initialized.channel ? hub.events.radio.initialized.channel : -1,
      })

      let options = {[-1] : {value: -1, label: -1}};
      if (hub.events && hub.events.radio && hub.events.radio.valid_channels) {
        hub.events && hub.events.radio && hub.events.radio.valid_channels.forEach(c => {
          options = Object.assign({}, options, {[c]: {value: c, label: c}})
        })
      }
      setChannels(options);
    } else {
      console.log("set newForm")
      setId('new');
      setEditForm(false);
      setHub(null);
      setConfig(CONFIG);
      setChannels({});
    }
    setError(CONFIG);
  }

  const handleDelete = (e) => {
    setTimeout(()=>props.history.push(URL), 700);
  };

  const handleRestart = (e) => {
   let err = {...error};
   e === 'success' ?  err['status'] = hub.name +  " restarted " :  err['status'] = "Error restarting " + hub.name;
   setError(err);
  }

  const validateFields = () => {
    let errorNew = {...CONFIG};
    isEmpty("hub", config.hub, errorNew);
    if (!editForm) {
      isEmpty("hardware_id", config.hardware_id, errorNew);
      isHex("hardware_id", config.hardware_id, errorNew);
    } else {
      isEmpty("event_offset", config.event_offset, errorNew);
      isGreaterEqual("event_offset", config.event_offset, errorNew, -1);
      isLessEqual("event_offset", config.event_offset, errorNew, 60);
    }

    setError(errorNew);
    return errorNew.validate;
  };

  const handleSubmit = (e)=> {
    if (!validateFields()) {
      return;
   }

    console.log("submit");
    let errorNew = {...error}
    errorNew.status = "Saving " + config.hub;
    setError(errorNew);

    if (editForm) {
      let body =   {
        node: {
          name: config.hub,
          configs: {
                      radio: { channel: parseInt(config.channel, 10)},
                      hub: { periodic_command_offset_seconds: parseInt(config.event_offset, 10)},
                    },
        }
      };

      props.dispatch(updateHub(hub.id,body));
    } else {
      let node = {
        node: {
          name: config.hub,
          hardware_id: config.hardware_id,
          node_type: 'hub',
        }
      };
      props.dispatch(createHub(node));
    }
    setTimeout(()=>props.dispatch(fetchHubs()), 500);
    setTimeout(()=>props.history.push(URL), 700);
  };

  const updateField = (field, e, type) => {
    let configNew = {...config};
    if (type && type === 'select') {
      configNew[field] = e.value;
    } else {
      configNew[field]  = e.target.value;
    }
    setConfig(configNew);
  }

  return(
    <div className="main edit">
      <Link to={URL}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>{editForm ? "Edit Hub" : "Add New Hub"}</h3>
      {editForm &&
        <HeaderContainer
          node = {hub && hub.id}
          name = {hub && hub.name}
          type = {hub && hub.node_type}
          status={hub && hub.events && hub.events.system && hub.events.system.connection && hub.events.system.connection.connected ? "connected" : "disconnected"}
          handleDelete={handleDelete}
          handleRestart={handleRestart}
        />
      }
      <div className="status">{error && error.status}</div>
      <div className="line"/>

      <div className="edit_container">
        { editForm ?
          <div key="edit">
            <LabelContainer
              labels = {
                {
                  "Name": {},
                  "Event Offset": {"help" : "The event offset is a randomized parameter that helps space out recurring events (i.e., autocapture) to balance network utilization"},
                  "Channel": {"help": "The frequency at which the hub network operates. Changing the channel can help improve the performance by reducing interference in certain environments"},
                }
            }/>
            <FormContainer
              fields = {{
                  "hub": {"value": config['hub'] ? config.hub : "", "placeholder": "", "error": error.hub},
                  "event_offset" :  {"type": "short_input", "text": "seconds", "value": config['event_offset'] ? config.event_offset : "", "placeholder": "", "error": error.event_offset},
                  "channel" :  {"type": "select",
                             "options": Object.values(channels),
                             "value": config['channel'] ? channels[config.channel] : "",
                             "placeholder": "",
                             "error": error.channel,
                            },
              }}
              updateField={updateField}
            />
            <InfoContainer
              info={{
                      "Hardware ID(Mac Address)": hub && hub.hardware_id,
                      "System Id": hub && hub.id,
                      "Firmware Version(CC1310)": hub && hub.events && hub.events.system && hub.events.system.versions && hub.events.system.versions.radio_firmware,
                      "Firmware Version(EMMC)": get(hub, 'events.system.versions.image') || get(hub, 'events.system.versions.hub'), 
                      "IP Address": hub && hub.events && hub.events.system && hub.events.system.metrics && hub.events.system.metrics.network_interfaces && hub.events.system.metrics.network_interfaces.eth0 && hub.events.system.metrics.network_interfaces.eth0.address,
                    }}
            />
          </div>
          :
          <div key="save">
            <LabelContainer labels= {{"Name": {"help": "", "desc": ""}, "Hardware ID": {"help": "", "desc": ""}}}/>
            <FormContainer
              fields = {{
                  "hub": {"value": config.hub, "placeholder": "", "error": error.hub},
                  "hardware_id": {"value": config.hardware_id, "placeholder": "", "error": error.hardware_id},
              }}
              updateField={updateField}
            />
          </div>
        }
      </div>
      <div>
          <Link to={URL}><button className="cancel">Cancel</button></Link>
          <span id="save"><button className="save" onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({ hubsHash: state.hubs.byId}),
)(EditHubNew);
