import React, {useState} from 'react';
import Modal from 'react-modal';

const WarnModal = (props) => {
  const [showModal, setShowModal] = useState(false);

  const setShow = (e) => {
    e.stopPropagation();
    let show = !showModal;
    setShowModal(show);
  }

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
  }

  const executeAction = (e) => {
    props.action();
    closeModal(e);
  }

  return(
    <div className={props.button}>
      {props.button && <button onClick={setShow}/> }
      {props.html && <div onClick={setShow}>{props.html}</div>}
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        contentLabel="Warn"
        className="Warn"
      >
        <div className="WarnContainer">
          <h3>WARNING</h3>
          <div>{props.warn}</div>
          <button className="cancel" onClick={closeModal}>CANCEL</button>
          <button className="continue" onClick={executeAction}>CONTINUE</button>
        </div>
      </Modal>
    </div>
  )
}

export default WarnModal;
