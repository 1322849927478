import React from 'react';
import { connect } from 'react-redux';

import { getNodesArray, triggerCamera } from '../../modules/nodes';
import { getDocksArray } from '../../modules/docks';
import { fetchObjectHistory } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import { calDockStatus, convertToHourMin, calculate_dwells, calBizType, calBizFromTo} from './Utility';

import { getCookie, setCookie } from '../common/Util';
import SmartDocks from './SmartDocks';
import SmartDockDetail from './SmartDockDetail';
import SmartDockOverview from './SmartDockOverview';
import SmartDockTable from './SmartDockTable';
import SmartDockInterval from './SmartDockInterval';

class SmartDockPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      docks: {},
      cameras: {},
      from: Date.now() - 86400000,
      to: Date.now(),
      rows: [],
      history: {}, // metric change - {node: history, node1: history1} (asc)
      last_occupancy_history: {},
      stats: {},
      docksAverage: 0,
      dockOverview: {},
      showDetail : {
        show: false,
        dockId: null,
        posX: 0,
      },
    }

    this.loadDocks = this.loadDocks.bind(this);
    this.onShowThisDock = this.onShowThisDock.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
    this.setFromTo = this.setFromTo.bind(this);
  }

  componentDidMount() {
    //console.log("component did mount");
    let day = getCookie('smartdock_select') ? getCookie('smartdock_select') : 7;
    setCookie('smartdock_select', day, 30);
    //this.setState({from: Date.now() - 86400000 * day, to: Date.now()}, ()=>this.loadDocks());

    let fromTo = calBizFromTo(Date.now(), calBizType(day));
    this.setState({from: fromTo[0], to: fromTo[1]}, ()=>this.loadDocks());
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.docks) !== JSON.stringify(this.props.docks)) {
      console.log("smartdock page update")
      this.loadDocks();
    }
  }

  loadDocks() {
    let cameras = {};
    let docks = {};
    this.props.nodes.forEach (n => cameras[n.node] = n);
    this.props.docks.forEach (d => docks[d.node] = d);

    let rows = [];
    let dockOverview = { "available" : 0, "occupied" : 0, "warning" : 0, "active" : 0,};

    Object.values(docks).sort((a,b) => a.name.localeCompare(b.name)).forEach(
      dock => {
        if (!dock.events.dock || !dock.events.dock.occupancy_change) { return; }
        if (!cameras.hasOwnProperty(dock.events.dock.occupancy.camera_id)) {return;}

        let dockInfo = {id: dock.node, dock_name: dock.name, trucks: "-" , avg_dwell_time: "-", utilization: "-", warning: false, battery: ""};
        let status = calDockStatus(dock, cameras[dock.events.dock.occupancy.camera_id]);

        if (status.startsWith("OCCUPIED")) {
          dockInfo['status'] = 'Occupied';
          dockInfo['dwell_time'] = convertToHourMin(Date.now() - dock.events.dock.occupancy_change.timestamp);
          dockInfo['time_available'] = "-";
          dockOverview['occupied'] += 1;

          if (status.startsWith('OCCUPIED_DELAY')) {
             dockInfo['warning'] = true;
             dockOverview['warning'] += 1;
         }
        }

        if (status.startsWith("AVAILABLE")) {
          dockInfo['status'] = 'Available';
          dockInfo['dwell_time'] = "-";
          dockInfo['time_available'] = convertToHourMin(Date.now() - dock.events.dock.occupancy_change.timestamp);
          dockOverview['available'] += 1;
        }

        if (dock.events.dock.activity.state === 1) {
          dockInfo['interior'] = 'Active';
          dockOverview['active'] += 1;
        } else {
          dockInfo['interior'] = "Inactive";
        }

        if (status.startsWith("OFFLINE")) {
          status === "OFFLINE_DEAD_BATTERY" ?
            dockInfo = {status: "Offline", dock_name: dock.name, trucks: "-" , avg_dwell_time: "-", utilization: "-", dwell_time: "-", time_available: "-", interior: "-", battery: "offline"}
            :
            dockInfo = {status: "Offline", dock_name: dock.name, trucks: "-" , avg_dwell_time: "-", utilization: "-", dwell_time: "-", time_available: "-", interior: "-", battery: ""}
        }

        if (status.endsWith("LOW_BATTERY")) { dockInfo["battery"] = "low" }
        rows.push(dockInfo);
      }
    );

    this.setState({docks: docks, cameras: cameras, dockOverview: dockOverview, rows: rows}, ()=>this.fetchActivityHistory());
  }

  fetchActivityHistory() {
    let history = {};

    Promise.all(Object.keys(this.state.docks).map((d) =>
      fetchObjectHistory(d, this.props.authToken, this.props.currentSiteId, {event: "dock:activity_change", from: this.state.from, to: this.state.to, limit: 100000}))
    )
      .then(json => json.forEach(dock => history[dock.id] = dock.history))
      .then(()=> this.fetchOccupancyHistory(history));
  }

  fetchOccupancyHistory(prev_history) {
    let history = {}; //{id: history}

    Promise.all(Object.keys(this.state.docks).map((d) =>
      fetchObjectHistory(d, this.props.authToken, this.props.currentSiteId, {event: "dock:occupancy_change", from: this.state.from, to: this.state.to, limit: 100000}))
    )
      .then(json => json.forEach(dock => history[dock.id] = prev_history[dock.id].concat(dock.history).sort((a,b) => a.timestamp - b.timestamp)))
      .then(()=> this.setState({ history: history }))
      .then(()=> this.fetchPreviousOccupancy(Object.keys(this.state.docks), this.state.from))
  }

  /*fetchHistory() {
    let history = {};
    let nodeIds = Object.keys(this.state.docks);
    let to = this.state.to;
    let from = this.state.from;

    Promise.all(nodeIds.map((d) =>
      fetchObjectHistory(d, this.props.authToken, this.props.currentSiteId, {event: "dock:metric_change", from: from, to: to, limit: 100000})))
        .then(json => json.forEach(dock => history[dock.id] = dock.history.sort((a,b) => a.timestamp - b.timestamp))) //ascending
        .then(()=> this.setState({from: from, to: to, history: history}))
        .then(()=> this.fetchPreviousOccupancy(nodeIds, from))
  }*/

  fetchPreviousOccupancy(nodeIds, from) {
    let prevState = {};
    Promise.all(nodeIds.map((d) =>
      fetchObjectHistory(d, this.props.authToken, this.props.currentSiteId, {event: "dock:occupancy", to: from, limit: 1})))
      .then(json => json.forEach(dock => prevState[dock.id] = dock.history))
      .then(()=> this.setState({last_occupancy_history: prevState}))
      .then(()=> this.calculate());
      //.then(()=> console.log(this.state.last_occupancy_history))
  }

  calculate() {
    let stats = {};
    let total_dwell_time = 0;
    let total_dwells = 0;

    //stats[dock] = {"dwell": 0, "total_dwell_time": 0, "avg_dwell_raw": 0, "avg_dwell": 0, "utilization": 0};
    for (const dock in this.state.history) {
        stats[dock] = calculate_dwells(this.state.history[dock], this.state.from, this.state.to, this.state.last_occupancy_history[dock]);
        total_dwell_time += stats[dock].total_dwell_time;
        total_dwells += stats[dock].dwell;
    }

    this.setState({stats: stats, "docksAverage": Math.round((total_dwell_time/total_dwells)/60000)});
  }

  onShowThisDock(e) {
    let dockid = e.target.dataset.dock;
    let clientx  = e.clientX;
    //console.log("show this  dock - " + dockid);

    this.setState((prevState, props) =>
                    ({
                      showDetail: {
                          show: this.state.showDetail.dockId === dockid ? !prevState.showDetail.show : true,
                          dockId: this.state.showDetail.dockId === dockid ? null : dockid,
                          posX: clientx - 70,
                        }
                    })
                  );
  }

  onRefresh(e) {
    //console.log("dock - " + e.target.dataset.dock.split(",")[0]);
    console.log("trigger camera " + e.target.dataset.dock.split(",")[1]);
    console.log("trigger camera " + e.target.dataset.dock.split(",")[2]);
    this.props.dispatch(triggerCamera(e.target.dataset.dock.split(",")[1], 'image'));
    this.props.dispatch(triggerCamera(e.target.dataset.dock.split(",")[2], 'image'))
  }

  setFromTo(from, to) {
    console.log(from + " " + to);
    this.setState({from: from, to: to}, ()=>this.loadDocks());
  }

  render() {
    return(
      <div>
        <SmartDocks docks={this.state.docks} cameras={this.state.cameras} showThisDock={this.onShowThisDock} takePicture={this.onRefresh}/>
        <div style={this.state.showDetail.show? {display: "block"} : {display: "none"}}>
          <div className="Triangle" style={{marginLeft: this.state.showDetail.posX}}/>
            <SmartDockDetail
              key={this.state.showDetail.dockId}
              dock={this.state.docks[this.state.showDetail.dockId]}
              cameras={this.state.cameras}
              showThisDock={this.onShowThisDock}
              refresh={this.onRefresh}
              history={this.state.history[this.state.showDetail.dockId]}
            />
          </div>
          <SmartDockInterval
            start={this.state.from}
            end={this.state.to}
            setFromTo={this.setFromTo}
            docks={this.state.docks}
            history={this.state.history}
            last_occupancy={this.state.last_occupancy_history}
            site={this.props.currentSite}
            acl={this.props.acl}
          />
        <div className="SmartDockPage">
          <SmartDockOverview available={this.state.dockOverview.available}
            occupied={this.state.dockOverview.occupied}
            average={this.state.docksAverage}
            warning={this.state.dockOverview.warning}
            active={this.state.dockOverview.active}
          />
          <SmartDockTable rows={this.state.rows} stats={this.state.stats}/>
        </div>
      </div>
    )
  }
}

export default connect(
  state => (
    {
      docks: getDocksArray(state), //docks only
      nodes: getNodesArray(state), //cameras and docks together
      authToken: state.authToken,
      currentSiteId: getCurrentSiteId(state),
      currentSite: state.sites.currentSite,
      acl: state.user.acl,
   }),
)(SmartDockPage);

//<SmartDockInterval start={this.state.from} end={this.state.to} setFromTo={this.setFromTo}/>
