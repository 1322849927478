import omit from 'lodash/omit';
import merge from 'lodash/merge';
import { API_ROOT } from '../DashboardConfig';

const LOAD = 'hubs/LOAD';
const ADD = 'hubs/ADD';
const ERROR = 'hubs/ERROR';
const REMOVE = 'hubs/REMOVE';
const UPDATE = 'hubs/UPDATE';
const REFRESH = 'hubs/REFRESH';

const initialState = {
  allIds: [],
  byId: {},
  status: 'pending',
  error: '',
};


export default function reducer(state = initialState, action) {
	//console.log(action.type);
  switch (action.type) {
    case LOAD:
      return Object.assign({}, state, action.payload, { status: 'success',/* error: '' */});
    case ADD:
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.payload]: {name: '', hardware_id: ''},
        }),
        allIds: [...state.allIds, action.payload],
        error: '',
      });
    case ERROR:
      return Object.assign({}, state, { error: action.payload });
    case REMOVE: {
      return Object.assign({}, state, {
        byId: omit(state.byId, action.payload),
        allIds: state.allIds.filter(id => id !== action.payload),
      });
    }
    case UPDATE: {
      return merge({}, state, {
        byId: { [action.payload.id]: action.payload.state },
      });
    }
    case REFRESH: {
      return merge({}, state, {
        byId: { [action.payload.id]: action.payload.update },
      });
    }
    case 'LOGOUT':
      return initialState;
    default:
      //console.log(JSON.stringify(state));
      return state;
  }
}

export function loadHubs(hubs) {
  let hubKeys = Object.keys(hubs);
  let hubData = Object.values(hubs);

  for(let i=0; i<=hubData.length-1; i++){
    hubData[i].id = hubKeys[i];
  }
  let filteredHubs = hubData.filter(h => h.node_type === 'hub');
  let allIds = [];
  let byId = {};
  filteredHubs.forEach(h => {
    byId[h.id] = h;
    allIds.push(h.id);
  })
  return { type: LOAD, payload: { byId, allIds } };
}

export function addHub(id) {
  return { type: ADD, payload: id };
}

export function hubError(message) {
  return { type: ERROR, payload: message };
}

export function removeHub(id) {
  return { type: REMOVE, payload: id };
}

export function editHub(hub) {
  return { type: UPDATE, payload: hub };
}

export function refreshHub(hub) {
  return { type: REFRESH, payload: hub};
}

export function logoutHub() {
   return { type: 'LOGOUT', payload: ""};
}

export function getHubsForTable(state) {
  return state.hubs.allIds.map(id => {
    const hubData = state.hubs.byId[id];

    // Check for event types to fill the table
    if (hubData.events) {
      //console.log("Updating getHubsForTable " + new Date());
      //console.log(JSON.stringify(hubData));

      // Check for system events to determine if IP Adress exists
      hubData.ipAddress = (hubData.events.system && hubData.events.system.metrics && hubData.events.system.metrics.network_interfaces && hubData.events.system.metrics.network_interfaces.eth0) ? hubData.events.system.metrics.network_interfaces.eth0.address : '';

      // Check for radio events to determine if channel exists
      hubData.channel = (hubData.events.radio && hubData.events.radio.initialized) ? hubData.events.radio.initialized.channel : '';
      //hubData.channel = (hubData.configs.radio && hubData.configs.radio.channel) ? hubData.configs.radio.channel : hubData.events.radio.initialized.channel;
      hubData.channel_list = (hubData.events.radio && hubData.events.radio.valid_channels) ? hubData.events.radio.valid_channels : [];
      hubData.connection = (hubData.events.system && hubData.events.system.connection) ? hubData.events.system.connection.connected : '';
      hubData.autocaptureoffset = (hubData.events.hub && hubData.events.hub.hasOwnProperty('periodic_command_offset_seconds')) ? hubData.events.hub.periodic_command_offset_seconds : '-1';

      /*
      console.log('events ' + hubData.events);
	    console.log('ipddress ' + hubData.ipAddress);
	    console.log('channel ' + hubData.channel);
	    console.log('connection ' + hubData.connection); */
    }

    return hubData;
  })
}

export function fetchHubs() {
  return (dispatch, getState) => {
    const { authToken, sites } = getState();

    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}?detailed`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };

    fetch(url, init)
      .then(resp =>
        {if (resp.status === 401) {
          console.log('hub - 401 error');
          dispatch(logoutHub());
          } else {
            console.log("hub " + resp.status)
            return resp;
          }
        })
      .then(resp => resp.json())
      .then(json => dispatch(loadHubs(json.state.nodes)))
      .catch(err => {
        console.log('HUB Fetch Error :', err);
        //dispatch(logoutHub());
      });
  };
}

export function createHub(hub) {
  return (dispatch, getState) => {
    const { authToken, sites } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}/nodes`;
    const init = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(hub),
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        if (json.message) {
          dispatch(hubError(json.message))
        } else {
          console.log(json);
          dispatch(hubError(''))
          dispatch(addHub(json.id));
          dispatch(fetchHub(json.id));
        }
      });
  };
}

export function updateHub(id, hubUpdates) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
      body: JSON.stringify(hubUpdates),
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then((json) => {
        if (json.message) {
          dispatch(hubError(json.message));
        } else {
          dispatch(hubError(''))
          dispatch(fetchHub(id));
        }
      });
  };
}
export function deleteHub(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then((resp) => {
        if (resp.ok) { dispatch(removeHub(id)); }
      })
  };
}

export function fetchHub(id) {
  return (dispatch, getState) => {
    const { authToken } = getState();
    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}nodes/${id}`;
    const init = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      .then(resp => resp.json())
      .then(json => {
        if (json.message) {
          dispatch(hubError(json.message));
        } else {
          dispatch(editHub(json));
        }
      });
  };
}
