import React from 'react';
import Modal from 'react-modal';

import {setCookie, getCookie } from '../common/Util';

class EditHeatmapCriteria extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      maxValue: getCookie("HMMax"),
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setMaxValue = this.setMaxValue.bind(this);
  }

  //do cookies
  componentDidMount() {
    this.setState({ modalIsOpen: this.props.openModal});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openModal !== this.props.openModal) {
      this.setState({ modalIsOpen: this.props.openModal});
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.props.onOpenModal();
  }

  setMaxValue(e) {
    //console.log(e.target.value);
    e.target.value = e.target.value.replace(/[^0-9.]/, '');
    setCookie("HMMax", e.target.value, 1);
    this.setState({maxValue: e.target.value});
  }

  render() {
    return (
      <div style={{display: "inline-block"}}>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Edit Heatmap Criteria"
          className="EditCriteria"
        >
        <img className="xImg" alt="close" src={require("./img/x.png")} onClick={this.closeModal}/>
        <div className="UploadContainer">
            <div style={{display: "inline-block"}}>ACTIVITY HEATMAP (HOUR)</div>
            <div style={{marginTop: '10px'}}>
              <input style={{marginLeft: "255px", marginRight: "3px", outline: "none"}} type="text" size="4" value={this.state.maxValue} onChange={this.setMaxValue}/>h
              <img className="Legend" alt="legend" src={require('./img/gradient.png')}/>
            </div>
            <div className="ok" onClick={()=>{this.props.onSetMax(this.state.maxValue); this.props.onOpenModal()}}>OK</div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EditHeatmapCriteria;
