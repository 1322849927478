import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SettingsSidebar from './components/SettingsSidebar';
import SiteSettingsContainer from '../site_settings/SiteSettings';
import { fetchSites } from '../../modules/sites';
import DevicesTableContainer from '../devices/DevicesTableContainer';
import { fetchNodes } from '../../modules/nodes';
import HubsTableContainer from '../hubs/HubsTableContainer';
import { fetchHubs } from '../../modules/hubs';
import ConstellationsTableContainer from '../constellations/ConstellationsTable';
import { fetchConstellations } from '../../modules/constellations';
//import DeviceTables from '../devices/DeviceTables';
import { fetchPendingNodes } from '../../modules/pendingNodes';
import DocksTableContainer from '../docks/DocksTableContainer';
import EditDock from '../docks/EditDock';
import LpsSettings from '../lps/LpsSettings';
import WorkersTableContainer from '../lps/WorkersTableContainer';
import GIDsTableContainer from '../lps/GIDsTableContainer';
import EditAnchorNew from '../devices/EditAnchorNew';
import EditTrackerNew from '../devices/EditTrackerNew';
import EditHubNew from '../hubs/EditHubNew';
import EditConstellationNew from "../constellations/EditConstellationNew";
import EditNodeNew from '../devices/EditNodeNew';
import EditGIDNew from '../lps/EditGID';
import ConfigsTableContainer from '../devices/ConfigsTableContainer';
import EditAnchorConfig from '../devices/EditAnchorConfig';
import EditTrackerConfig from '../devices/EditTrackerConfig';

import './Settings.css';
import './ReactModal.css';


class Settings extends React.Component {
  componentDidMount() {
    // this.props.fetchSites();
    // this.props.fetchNodes();
    // this.props.fetchPendingNodes();
    // this.props.fetchHubs();
    // this.props.fetchConstellations();
  }

  //<Route path="/dashboard/settings/devices" component={DeviceTables} /> retired
  //<Route path="/dashboard/settings/smartlps/workerlog" component={WorkerLogContainer} />
  render() {
    return (
      <div className="settings">
        <SettingsSidebar site={this.props.site} acl={this.props.acl}/>
        <Switch>
          <Route exact path="/dashboard/settings" component={SiteSettingsContainer} />
          <Route path="/dashboard/settings/site" component={SiteSettingsContainer} />
          <Route path="/dashboard/settings/devices/camera" component={DevicesTableContainer} />
          <Route path="/dashboard/settings/devices/edit_node/:id" component={EditNodeNew} />
          <Route path="/dashboard/settings/devices/anchor" component={DevicesTableContainer} />
          <Route path="/dashboard/settings/devices/editanchor/:id" component={EditAnchorNew} />
          <Route path="/dashboard/settings/devices/tracker" component={DevicesTableContainer} />
          <Route path="/dashboard/settings/devices/edit_tracker/:id" component={EditTrackerNew} />
          <Route path="/dashboard/settings/hubs" component={HubsTableContainer} />
          <Route path="/dashboard/settings/devices/edit_hub/:id" component={EditHubNew} />
          <Route path="/dashboard/settings/constellations" component={ConstellationsTableContainer} />
          <Route path="/dashboard/settings/editconstellation/:id" component={EditConstellationNew} />
          <Route path="/dashboard/settings/smartdock" component={DocksTableContainer} />
          <Route path="/dashboard/settings/editdock/:id" component={EditDock} />
          <Route path="/dashboard/settings/smartlps/config" component={LpsSettings} />
          <Route path="/dashboard/settings/smartlps/workers" component={WorkersTableContainer} />
          <Route path="/dashboard/settings/smartlps/gids" component={GIDsTableContainer} />
          <Route path="/dashboard/settings/smartlps/edit_gid/:id" component={EditGIDNew} />
          <Route path="/dashboard/settings/devices/anchor_config" component={ConfigsTableContainer} />
          <Route path="/dashboard/settings/devices/tracker_config" component={ConfigsTableContainer} />
          <Route path="/dashboard/settings/devices/edit_anchor_config/:id" component={EditAnchorConfig} />
          <Route path="/dashboard/settings/devices/edit_tracker_config/:id" component={EditTrackerConfig} />
        </Switch>
      </div>
    );
  }
}

const SettingsContainer = connect(
  state => ({
    acl: state.user.acl,
    site: state.sites.currentSite,
  }),
  dispatch => bindActionCreators({
    fetchSites,
    fetchNodes,
    fetchPendingNodes,
    fetchHubs,
    fetchConstellations,
  }, dispatch),
)(Settings);

export default SettingsContainer;
