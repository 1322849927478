import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
//import HubCreateContainer from '../settings/components/hub_create_container';
//import EditHub from './EditHub';
import Sort from '../common/Sort';
import Filter from '../common/Filter';

const LINK = "/dashboard/settings/devices/edit_hub/";

const HubsTable = ({
  origData,
  data,
  error,
  setTableData,
  sortedColumnIndex,
  setSortedData
}) => (
  <div className="main">
    <h3>HUBS</h3>
    <div className="button-container"><Link to={LINK + "new"}>ADD NEW</Link></div>
    <span id="error">{error}</span>
    <div className="line"/>
    <Filter data={origData} setNewData={setTableData} />
    <Table
      rowsCount={data.length}
      rowHeight={45}
      headerHeight={35}
      width={1075}
      height={500}
    >
      <Column
        header={<Cell>NAME<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
          <Link to={LINK + data[props.rowIndex].id}>{data[props.rowIndex].name ? data[props.rowIndex].name : data[props.rowIndex].id}</Link>
          </Cell>
        )}
        width={215}
      />
      <Column
        header={<Cell>HARDWARE ID<Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].hardware_id}
          </Cell>
        )}
        width={205}
      />
      <Column
        header={<Cell>VERSION</Cell>}
        cell={props => (
          <Cell {...props}>
            { get(data[props.rowIndex], 'events.system.versions.image') || get(data[props.rowIndex], 'events.system.versions.hub') }
          </Cell>
        )}
        width={165}
      />
      <Column
        header={<Cell>CHANNEL<Sort thisColumnIndex={9} thisDataType={"integer"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].channel}
          </Cell>
        )}
        width={145}
      />
      <Column
        header={<Cell>IP ADDRESS<Sort thisColumnIndex={8} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props}>
            {data[props.rowIndex].ipAddress}
          </Cell>
        )}
        width={185}
      />
      <Column
        header={<Cell>STATUS<Sort thisColumnIndex={11} thisDataType={"string"} columnIndexToSort={sortedColumnIndex} data={data} setSortedData={setSortedData}/></Cell>}
        cell={props => (
          <Cell {...props} style={{color: data[props.rowIndex].connection ? "" : "#ff0000"}}>
            {!data[props.rowIndex].connection ? 'Disconnected' : 'Connected'}
          </Cell>
        )}
        width={135}
      />
    </Table>
  </div>
);

export default HubsTable;

//  <HubCreateContainer />
//   <EditHub hub={data[props.rowIndex]} />
