import { WS_ROOT } from './DashboardConfig.js';
import { throttle } from 'lodash';

export class LocixEventManager {
    constructor(siteId, customerId, authToken, topicList) {
        this.wsUrl = WS_ROOT;
        this.autoReconnectInterval = 2 * 1000;
        this.listeners = {};
        this.siteId = siteId;
        this.customerId = customerId;
        this.token = authToken;
        this.topicList = topicList;
        this.wsConnected = false;
        this.ws = null;
        this.onWsOpen = this.onWsOpen.bind(this);
        this.onWsClose = this.onWsClose.bind(this);
        this.onWsError = this.onWsError.bind(this);
        this.reconnect = this.reconnect.bind(this);
        this.connect = this.connect.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.messageReceived = this.messageReceived.bind(this);
        this.onWsMessageReceived = throttle(this.messageReceived , 100);

        var that = this;
        window.onbeforeunload = function() {
            if (that.ws) {
                that.ws.onclose = function () {}; // disable onclose handler first
                that.ws.close();
            }
        };
    }

    connect() {
        const subs = "&event=tracker:location&event=tracker:attachment&event=camera:media_ready&event=battery:voltage&event=system:connection" +
                     "&event=dock:occupancy_change&event=dock:activity_change" +
                     "&event=environmental:temperature&event=environmental:humidity";
        console.log(subs)
        this.ws = new WebSocket(this.wsUrl + `${this.siteId}?token=${this.token}` + subs);
        this.ws.onopen = this.onWsOpen;
        this.ws.onmessage = this.onWsMessageReceived;
        this.ws.onclose = this.onWsClose;
        this.ws.onerror = this.onWsError;
    }

    disconnect() {
        if (this.ws && this.wsConnected) {
            try {
                this.ws.close(3155, "Locix logout or site change");
            } catch(e) {
                console.error("Problem trying to close websocket.", e);
            }
        }
    }

    reconnect(e) {
        console.log('LocixEventManager: retry in ', this.autoReconnectInterval, 'ms, ', e, this.ws);
        // Dave: FIXME: The following line blows up with 'removeAllListeners() is not a function' because this.ws is a blob
        // this.ws.removeAllListeners();
        setTimeout(() => {
          // Dave: Please use spaces for indenting, not tabs:
      	  console.log("LocixEventManager: reconnecting...");
      		this.connect();
      	}, this.autoReconnectInterval);
    }

    onWsOpen() {
        this.wsConnected = true;
    }

    onWsClose(e) {
       console.log('LocixEventManager - onWsClose', e, e.reason);
       this.ws.close();

       switch (e.code) {
         case 3155:
             console.log("LocixEventManager: Logged out or switched site - " + e.code);
             this.wsConnected = false;
             break;
         case 4000:
             console.log("LocixEventManager: No authorization token provided - " + e.code)
             this.wsConnected = false;
             break;
         case 4001:
            console.log("LocixEventManager: Bad authorization token provided - " + e.code)
            this.wsConnected = false;
            break;
         case 4002:
            console.log("LocixEventManager: Authentication token expired - " + e.code)
            this.wsConnected = false;
            this.ws = null;

            let eventType = 'expired_token';
            if (this.listeners.hasOwnProperty(eventType)) {
                var listenerList = this.listeners[eventType];
                listenerList.forEach(function(listener){
                    listener.receiveWsEvent(this);
                });
            }
            break;
         case 4003:
            console.log("LocixEventManager: One and only one site parameter required  - " + e.code)
            this.wsConnected = false;
            break;
         case 4004:
            console.log("LocixEventManager: Access to site denied - " + e.code)
            this.wsConnected = false;
            break;
         default:
            console.log("LocixEventManager: abnormal closure");
            this.wsConnected = false;
            this.reconnect(e);
            break;
        }
    }

    onWsError(e) {
      console.log("LocixEventManager - onWsError - " + JSON.stringify(e))
      switch (e.code){
        case 'ECONNREFUSED':
          this.reconnect(e);
          console.error(e);
          break;
        default:
          console.error(e);
          this.wsConnected = true;
          break;
      }
    }

   messageReceived(messageEvent) {
      //console.log("Throttled New websocket message: - this is my data " + messageEvent.data);

      var eventObj = null;
      try {
          eventObj = JSON.parse(messageEvent.data);
      } catch(e) {
          console.log(messageEvent.data);
          return;
      }

      /*if (eventObj.source_type === 'location_updater' && eventObj.update.events.tracker.location.timestamp < (new Date().getTime() - 900000)) {
        console.log("Event is in the past - Node: " + eventObj.id + " Meas Id:" + eventObj.update.events.tracker.location.meas_id +
          " Time: " + eventObj.update.events.tracker.location.timestamp);
        //console.log(new Date(eventObj.update.events.tracker.location.timestamp).toString())
        return;
      };*/

      // [
      //     {
      //         id: "e0f5b37c85234226ab3ea4e04318132b",
      //         type: "node",
      //         timestamp: 1533884751023,
      //         source_id: "532e8f387c9d4e0d9299d99356c7802e",
      //         source_type: "node",
      //         action_id: "07:05:44",
      //         update: {
      //             events: {
      //                 camera: {
      //                     media: {
      //                         id: "07:05:44",
      //                         type: "auto",
      //                         format: "image",
      //                         timestamp: 1533884744361,
      //                         size: 65196,
      //                         key: "2eaaa3a70542413ba97cf0ac78967d2c/08375a223e874966b312ae6b2657e18e/e0f5b37c85234226ab3ea4e04318132b/image/1533884750526-f824fdba0ef340d28f7b002cee44c63e"
      //                     }
      //                 }
      //             }
      //         }
      //     }
      // ]

      // var eventType = eventObj.event_id;
      let eventType = 'media_ready';
      if (this.listeners.hasOwnProperty(eventType)) {
          var listenerList = this.listeners[eventType];
          listenerList.forEach(function(listener){
              //console.log("Sending event to listener: ", eventObj);
              listener.receiveWsEvent(eventObj);
          });
      }

      eventType = 'hub';
      if (this.listeners.hasOwnProperty(eventType)) {
          listenerList = this.listeners[eventType];
          listenerList.forEach(function(listener) {
              listener.receiveWsEvent(eventObj);
          });
      }
    }

    addEventListener(eventType, listener) {
        if (!this.listeners.hasOwnProperty(eventType)) {
            this.listeners[eventType] = [];
        }
        this.listeners[eventType].push(listener);
    }
}

window.LocixEventManager = LocixEventManager;


/*
 new websocket error codes -
 private enum class Error(val error: Int) {
   NO_TOKEN(4000),
   BAD_TOKEN(4001),
   EXPIRED_TOKEN(4002),
   BAD_PARAMS(4003),
   ACCESS_DENIED(4004)
 }

 private val errorMessages = mapOf(
     Error.NO_TOKEN to "no authorization token provided",
     Error.BAD_TOKEN to "bad authorization token provided",
     Error.EXPIRED_TOKEN to "authentication token expired",
     Error.BAD_PARAMS to "one and only one site parameter required",
     Error.ACCESS_DENIED to "access to site denied"
)*/
