import React from 'react';
import { connect } from 'react-redux';

import LpsMenu from  './LpsMenu';
import LpsMapConfig from './LpsMapConfigNew';
import { WAREHOUSE } from '../2dReact/2dConfig.js';

import { getRandomColor } from '../common/Util';
import { getCurrentSiteId, getCurrentSite } from '../../modules/sites';
import { fetchSiteDetailJson, fetchSiteJson } from '../../modules/api';
import { getNodesArray, updateNode, deleteNode } from '../../modules/nodes';

const METERS_PER_FOOT = 0.3048; //configure all to meters
const DEFAULT_FLOOR = "1";

class LpsSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        setting: 'zones', //origin, scale, lps_area, zones, anchors
        floor: WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId].map_order[0] : WAREHOUSE['default'].map_order[0],
        zones: {}, //id => {zone}
        anchors: {},
        selected_anchor: null, //node id
        saved_anchor: null, //node id
        selected_zone: null, //{id: ,zone}
        map_config: WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId] : WAREHOUSE['default'],
    }

    this.setSetting = this.setSetting.bind(this);
    this.selectAnchor = this.selectAnchor.bind(this);
    this.saveAnchor = this.saveAnchor.bind(this);
    this.deleteAnchor = this.deleteAnchor.bind(this);
    this.selectZone = this.selectZone.bind(this);
    this.saveZone = this.saveZone.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.addNewZone = this.addNewZone.bind(this);
    this.selectFloor = this.selectFloor.bind(this);
  }

  componentDidMount() {
    this.getAnchors(this.props.nodes);
    this.getZones();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.siteId !== this.props.siteId) {
      this.setState (
        { map_config: WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId] : WAREHOUSE['default'],
          zones: {},
          anchors: {},
          selected_anchor: null,
          saved_anchor: null,
          selected_zone: null,
          floor:  WAREHOUSE[this.props.siteId] ? WAREHOUSE[this.props.siteId].map_order[0] : WAREHOUSE['default'].map_order[0],
        }, ()=>{this.getZones()}
      );
    }

    if (prevProps.nodes.length !== this.props.nodes.length) {
      this.getAnchors(this.props.nodes);
    }
  }

  getAnchors(nodes) {
    let anchors = {};
    nodes.forEach(n => {
      if (n.node_type === 'anchor' && n.configs.anchor.hasOwnProperty('x')) {
        if (isNaN(n.configs.anchor.x) || isNaN(n.configs.anchor.y)) { return; }

        let anchor = {
          node: n.node,
          name: n.name,
          x: (n.configs.anchor.x / 100 * METERS_PER_FOOT).toFixed(2),
          y: (n.configs.anchor.y / 100 * METERS_PER_FOOT).toFixed(2),
          z: (n.configs.anchor.z / 100 * METERS_PER_FOOT).toFixed(2),
          floor: n.configs.anchor.hasOwnProperty("floor") ? n.configs.anchor.floor : DEFAULT_FLOOR,
        }
        anchors[n.node] = anchor;
      }
    })
    this.setState({anchors: anchors})
  }

  getZones() {
    fetchSiteDetailJson(this.props.authToken, this.props.siteId)
    .then(json => {
      if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('lps') && json.state.configs.lps.hasOwnProperty('zones')) {
        let zones = {};
        for (const z in json.state.configs.lps.zones) {
          zones[z] = json.state.configs.lps.zones[z];
          zones[z]['floor'] = json.state.configs.lps.zones[z].hasOwnProperty('floor') ? json.state.configs.lps.zones[z].floor : DEFAULT_FLOOR;
          zones[z]['id'] = z;

          if (!zones[z].color.startsWith("#")) {
            zones[z]['color'] = "#" + zones[z]['color'];
          }

          let points = [];
          zones[z]['points'].forEach( p => {
            points.push({x: p.x , y: p.y });
          })
          zones[z]['points'] = points;
        }
        this.setState({zones: zones}/*, ()=> console.log(JSON.stringify(this.state.zones))*/);
      }
    })
    .catch(error => console.log(error))
  }

  setSetting(e) { //set config setting
    this.setState({ setting : e.target.dataset.setting});
  }

  selectFloor(e) {
    this.setState({ floor: e.target.dataset.source});
  }

  selectAnchor(node) {
    if (node === null) { //temp fix
      this.setState({selected_anchor: null})
      return;
    }

    let anchors = {...this.state.anchors};
    anchors[node.node]['name'] = node.name;
    anchors[node.node]['x'] = node.x;
    anchors[node.node]['y'] = node.y;
    anchors[node.node]['z'] = node.z;
    this.setState({anchors: anchors, selected_anchor: node.node});
  }

  saveAnchor(anchor){
    let body = {
      node: {
        configs: {
          anchor: {
            x : parseInt((anchor.x / METERS_PER_FOOT) * 100, 10),
            y : parseInt((anchor.y / METERS_PER_FOOT) * 100, 10),
            z : parseInt((anchor.z / METERS_PER_FOOT) * 100, 10),
          }
        },
        name: anchor.name,
      }
    };

    this.props.dispatch(updateNode(anchor.node, body));
    this.setState({selected_anchor: null, saved_anchor: anchor.node});
    //console.log("Anchor saved " + JSON.stringify(anchor));
  }

  deleteAnchor(node) {
    let anchors = {...this.state.anchors};
    delete anchors[node.node];
    this.setState({anchors: anchors, selected_anchor: null});

    this.props.dispatch(deleteNode(node.node));
    this.setState({selected_anchor: null});
    //console.log("Deleted anchor - " + node.node)
  }

  selectZone(zone) { //update selected zone
    //console.log(JSON.stringify(zone));
    this.setState({selected_zone: zone})
  }

  //create new zone and add to zones and select_zone
  addNewZone() {
    let width = this.state.map_config.maps[this.state.floor].small.width / this.state.map_config.maps[this.state.floor].small.pixels_per_meter;
    let height = this.state.map_config.maps[this.state.floor].small.height / this.state.map_config.maps[this.state.floor].small.pixels_per_meter;

    //if the origin is off the map - need to account for the negative space.
    let adjX = this.state.map_config.maps[this.state.floor].origin.x < 0 ? Math.abs(this.state.map_config.maps[this.state.floor].origin.x) : 0;
    let adjY = this.state.map_config.maps[this.state.floor].origin.y < 0 ? Math.abs(this.state.map_config.maps[this.state.floor].origin.y) : 0;

    let newZone = {
        id: Object.keys(this.state.zones).length === 0 ? 1 : Math.max(...Object.keys(this.state.zones)) + 1,
        name: "1",
        color: getRandomColor(), //"#ff0000", //"#b3b3b3",
        points:[{x:(width*.25 + adjX), y:(height*.25 + adjY)},{x:(width*.5 + adjX),y:(height*.25 + adjY)},{x:(width*.5 + adjX),y:(height*.5 + adjY)},{x:(width*.25+adjX),y:(height*.5 + adjY)}],
        floor: this.state.floor,
    }
    let zones = {...this.state.zones}
    zones[newZone.id] = newZone;
    this.setState({zones: zones, selected_zone: newZone})
  }

  saveZone(zone) {
    //console.log("Save Zone")
    let zones = {...this.state.zones};
    zones[zone.id]['name'] = this.state.selected_zone.name;
    zones[zone.id]['color'] = this.state.selected_zone.color;
    zones[zone.id]['points'] = [...this.state.selected_zone.points];
    this.setState({zones: zones, selected_zone: null}, ()=>this.saveZoneApi());
  }

  saveZoneApi() {
    let path = 'sites/' + this.props.siteId;
    let body = {site: {configs: {lps: {zones:	{}}}}};
    body['site']['configs']['lps']['zones'] = this.convertZonesBack();
    let init = {
     method: "put",
     body: JSON.stringify(body)
   }

   fetchSiteJson(path, this.props.authToken, this.props.siteId, init);
 }

 convertZonesBack() {
   let newZones = {};
   for (const z in this.state.zones) {
     let zone = this.state.zones[z];
     let newPoints = zone.points.map(p => (
       {
         x: parseFloat(p.x),
         y: parseFloat(p.y),
       }
     ));

     newZones[z] = {name: zone.name, floor: zone.floor, color: zone.color.replace("#", ''), points: newPoints}
   }
   return newZones;
 }

 deleteZone(zone) {
   //console.log("delete zone");
   let zones = {...this.state.zones};
   delete zones[zone.id];
   this.setState({zones: zones, selected_zone: null}, ()=>this.deleteZoneApi(zone.id));
 }

 deleteZoneApi(id) {
   let path = 'sites/' + this.props.siteId;
   let body = {site: {configs: {lps: {zones: {}}}}}
   body['site']['configs']['lps']['zones'][id] = null;
   let init = {
    method: "put",
    body: JSON.stringify(body)
  }
  fetchSiteJson(path, this.props.authToken, this.props.siteId, init);
}

  render() {
    return (
      <div className="main">
        <h3>Zones</h3>
        <div className="line" style={{marginTop: "10px"}}/>
        <div className="lps">
          <LpsMenu
            key={this.props.siteId + "_menu"}
            setting={this.state.setting}
            setSetting={this.setSetting}
            zones={this.state.zones}
            anchors={this.state.anchors}
            selected_anchor={this.state.selected_anchor}
            selectAnchor={this.selectAnchor}
            saveAnchor={this.saveAnchor}
            deleteAnchor={this.deleteAnchor}
            selectZone={this.selectZone}
            selected_zone={this.state.selected_zone}
            saveZone={this.saveZone}
            deleteZone={this.deleteZone}
            addNewZone={this.addNewZone}
            floor_order={this.state.map_config.map_order}
            floor={this.state.floor}
            selectFloor={this.selectFloor}
          />
          {this.state.map_config &&
            <LpsMapConfig
              key={this.props.siteId + "_map"}
              setting={this.state.setting}
              config={this.state}
              selectAnchor={this.selectAnchor}
              selectZone={this.selectZone}
              zones={this.state.zones}
              />
          }
        </div>
      </div>
    )
  }
}

export default connect (
  state => ({
    nodes: getNodesArray(state),
    siteId: getCurrentSiteId(state),
    site: getCurrentSite(state),
    authToken: state.authToken,
  })
) (LpsSettings);
