import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';

import { getCurrentSiteId } from '../../../modules/sites';
import { fetchSiteDetailJson, /*postWmsJson,*/ postWmsJsonNew } from '../../../modules/api';

import WmsSummary from './WmsSummary';
import WmsMetric from './WmsMetric';
import Racks from './Racks';
import WmsDistance from './WmsDistance';
import WmsActivity from './WmsActivity';
import WmsZone from './WmsZone';
import WmsDistanceByZone from './WmsDistanceByZone';
import DownloadChart from './DownloadChart';

const LIMIT = 6;

class WMSCharts extends React.Component {
  constructor(props) {
    super(props);

    this.state={
      loading: "",
      zone_config: {},
      summary: {distance: 0, active_time: 0, inactive_time: 0, active_count: 0, worker: {active_count:0, active_time:0, inactive_time: 0, distance: 0}},
      asset_stats: {},
      asset_stats_filtered: {},
      rack_stats: {},
      //rack_stats_filtered: {},
      expand: false,
      expanded_chart: null,
      chartType: null,
      downloadChartType: "", //metric,
      downloadType: "", //time or percent
      downloadFile: false,
      loading_key: null,
    };

    this.onSetClose = this.onSetClose.bind(this);
    this.onSetChartType = this.onSetChartType.bind(this);
    this.onSetDownloadType = this.onSetDownloadType.bind(this);
    this.closeDownload = this.closeDownload.bind(this);
    this.debounceRendering = debounce(this.queryWmsStats, 2000);
  }

  componentDidMount() {
    this.fetchZoneDetail();
    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=>this.queryWmsStats(loading_key));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodes.length !== this.props.nodes.length ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals)
       ){
        let loading_key = new Date().getTime();
        this.setState({loading_key: loading_key}, ()=>this.debounceRendering(loading_key));
    }
  }

  fetchZoneDetail(siteId) {
    fetchSiteDetailJson(this.props.authToken, this.props.siteId, {})
     .then(json => {
       if (json.state.hasOwnProperty('configs') && json.state.configs.hasOwnProperty('lps') && json.state.configs.lps.hasOwnProperty('zones')) {
         this.setState({zone_config: json.state.configs.lps.zones});
       }
     })
  }

  queryWmsStats(loading_key) {
    this.setState({loading: this.props.time_intervals.length < 10 ?  "Loading..Please wait..." : "Loading...Requests of this size may take up to 2 minutes to process."});
    let body = {time_intervals: this.props.time_intervals, assets: this.props.nodes, outputs: { percentage: {}, intervals: {}}};
    //let body = {from: this.props.time_intervals[0].from, to: this.props.time_intervals[this.props.time_intervals.length - 1].to, assets: this.props.nodes, outputs: {intervals: {}}};
    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        if (loading_key === this.state.loading_key) {
          this.parseWmsStats(json, loading_key)
        } else {
          console.log("Another process is going 1")
        }
      })
      .catch(error => {
        console.log(error);
        this.setState(
          {loading: "Error - " + error.status,
           loading_key: null,
           asset_stats: {},
           asset_stats_filtered: {},
           rack_stats: {},
           //rack_stats_filtered: {}
          })
        })
  }

  parseWmsStats(json, loading_key) {
    //console.log(JSON.stringify(json.assets));
    //summary
    let time_by_zone = {}
    for (const z in json.zones) {
      time_by_zone[z] = json.zones[z].time;
    }

    let summary = {
      "distance" : json.distance,
      "active_time" : json.active_time,
      "inactive_time": json.types.hasOwnProperty('picker') ? json.types.picker.inactive_time : 0,
      "active_count": json.types.hasOwnProperty('picker') ? json.types.picker.active_count: 0,
      "worker": {...json.types.picker},
      "time_by_zone": time_by_zone,
     }

     //aseset_stats
     let asset_stats_filtered = {};
     this.props.nodes.slice(0, LIMIT).forEach(n => {
       asset_stats_filtered[n] = json.assets[n]
     })

     //racks
     /*let racks_filtered = {}
     Object.keys(json.racks).slice(0, LIMIT).forEach(r => {
       racks_filtered[r] = json.racks[r];
     })*/

     if (loading_key === this.state.loading_key) {
       this.setState({summary: summary,
                  asset_stats: json.assets, asset_stats_filtered: asset_stats_filtered,
                  rack_stats: json.racks, /*rack_stats_filtered: racks_filtered,*/
                  loading: "", loading_key: null});
     } else {
       console.log("Another process is going 2")
     }
  }

  onSetClose(e) {
    if (this.state.chartType !== null) {
      this.setState({chartType: null, expand: false})
    }
  }

  onSetChartType(e) {
    console.log(e.target.dataset.chart)
    if (this.state.chartType !== e.target.dataset.chart) {
      this.setState({chartType: e.target.dataset.chart, expand: true})
    }
  }

  onSetDownloadType(e) {
    /*
    console.log(e.target.dataset.charttype);
    console.log(e.target.dataset.type);
    */
    this.setState({downloadChartType: e.target.dataset.charttype, downloadType: e.target.dataset.type, downloadFile: true});
  }

  closeDownload(e) {
    this.setState({downloadFile: false});
  }

  render() {
    return (
      <div>
        <div className="loading">{this.state.loading}</div>
        <div style={{opacity: this.state.loading.length > 0 ? .2 : 1}}>
          <DownloadChart
            siteId={this.props.siteId}
            from={this.props.time_intervals[0].from}
            to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
            duration={this.props.duration}
            chart_type={this.state.downloadChartType}
            type={this.state.downloadType}
            sorted_nodes={this.props.nodes}
            asset_stats={this.state.asset_stats}
            zone_config={this.state.zone_config}
            rack_stats={this.state.rack_stats}
            downloadFile={this.state.downloadFile}
            closeDownload={this.closeDownload}
            expand={this.state.expand}
            worker_hash={this.props.worker_hash}
            origin={this.props.picking_origin}
            racks={this.props.racks}
            coordinates={this.props.coordinates}
           />
          {
            !this.state.expand &&
              <div><WmsSummary site_stats={this.state.summary} zone_config={this.state.zone_config}/></div>
          }
          { (!this.state.expand || this.state.chartType === 'metric') &&
              <div>
                <WmsMetric
                  from={this.props.time_intervals[0].from}
                  to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                  sorted_nodes={this.state.expand? this.props.nodes : this.props.nodes.slice(0,LIMIT)}
                  asset_stats={this.state.expand? this.state.asset_stats : this.state.asset_stats_filtered}
                  worker_hash={this.props.worker_hash}
                  expand={this.state.expand}
                  setClose={this.onSetClose}
                  setChartType={this.onSetChartType}
                  setDownloadType={this.onSetDownloadType}
                  displayDownload={this.props.displayDownload}
                />
               </div>
          }
          { (!this.state.expand || this.state.chartType === 'activity') &&
             <div>
               <WmsActivity
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 time_intervals={this.props.time_intervals}
                 duration={this.props.duration}
                 worker_hash={this.props.worker_hash}
                 sorted_nodes={this.state.expand? this.props.nodes : this.props.nodes.slice(0,LIMIT)}
                 asset_stats={this.state.expand? this.state.asset_stats : this.state.asset_stats_filtered}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
             </div>
          }
          {(!this.state.expand || this.state.chartType === 'zone_time') &&
             <div>
               <WmsZone
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 time_intervals={this.props.time_intervals}
                 duration={this.props.duration}
                 worker_hash={this.props.worker_hash}
                 zone_config={this.state.zone_config}
                 sorted_nodes={this.state.expand? this.props.nodes : this.props.nodes.slice(0,LIMIT)}
                 asset_stats={this.state.expand? this.state.asset_stats : this.state.asset_stats_filtered}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
            </div>
          }
          { (!this.state.expand || this.state.chartType === 'zone_distance') &&
             <div>
               <WmsDistance
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 worker_hash={this.props.worker_hash}
                 zone_config={this.state.zone_config}
                 sorted_nodes={this.state.expand? this.props.nodes : this.props.nodes.slice(0,LIMIT)}
                 asset_stats={this.state.expand? this.state.asset_stats : this.state.asset_stats_filtered}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
            </div>
          }
          { (!this.state.expand || this.state.chartType === 'distance_by_zone') &&
             <div>
               <WmsDistanceByZone
                 from={this.props.time_intervals[0].from}
                 to={this.props.time_intervals[this.props.time_intervals.length - 1].to}
                 zone_config={this.state.zone_config}
                 sorted_nodes={this.props.nodes}
                 asset_stats={this.state.asset_stats}
                 expand={this.state.expand}
                 setClose={this.onSetClose}
                 setChartType={this.onSetChartType}
                 setDownloadType={this.onSetDownloadType}
                 displayDownload={this.props.displayDownload}
                 />
            </div>
          }
           <div style={{display: this.state.expand && this.state.chartType !== 'racks' ? "none" : ""}}>
             <Racks
               //rack_stats={this.state.expand? this.state.rack_stats : this.state.rack_stats_filtered}
               siteId={this.props.siteId}
               rack_stats={this.state.rack_stats}
               expand={this.state.expand}
               setClose={this.onSetClose}
               setChartType={this.onSetChartType}
               setDownloadType={this.onSetDownloadType}
               displayDownload={this.props.displayDownload}
               origin={this.props.picking_origin}
               racks={this.props.racks}
               coordinates={this.props.coordinates}
               />
            </div>
          </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    siteId: getCurrentSiteId(state),
    authToken: state.authToken,
  }),
)(WMSCharts);
