import React from 'react';
import { connect } from 'react-redux';
import CameraEventList from '../events/CameraEventList';
import ConstellationHeading from './ConstellationHeading';
import { getRecentConstellationNodeEvents } from '../../modules/constellations';
import './ConstellationSummary.css';

const ConstellationSummary = ({ constellation, nodes, constellationId }) => (
  <div>
    <CameraEventList
      heading={<ConstellationHeading constellation={constellation} />}
      nodes={nodes}
    />
  </div>
);

export default connect(
  (state, ownProps) => ({
    nodes: getRecentConstellationNodeEvents(state, ownProps.constellation.constellation),
    constellationId: ownProps.constellation.constellation
  })
)(ConstellationSummary);
