import React from 'react';
import Modal from 'react-modal';

import {IMG_CONFIG} from '../2dConfig';
import { connect } from 'react-redux';
import { downloadWmsFile } from '../../../modules/api';
import { getCurrentSiteId } from '../../../modules/sites';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

//Summary_Sept18_0000_Sept19_0000.csv
function makeFileName(from, to) {
  let start = new Date(from)
  let end = new Date(to)

  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  let end_hour = end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
  let end_min = end.getMinutes() < 10 ? "0" +  end.getMinutes() : end.getMinutes()

  let str = "Summary_" + monthNames[start.getMonth()] + start.getDate() + "_" + start_hour + start_min + "_" +
            monthNames[end.getMonth()] + end.getDate() + "_" + end_hour + end_min + ".csv";
  return (str)
}

function makeTextFile (text, link) {
   var textFile;
   var data = new Blob([text], {type: 'text/plain'});
   if (link !== null) {
     window.URL.revokeObjectURL(link);
   }

   textFile = window.URL.createObjectURL(data);
   return textFile;
 };

 const makeLpsFile = (data) => {
   let newData = data.split("\n").map(line => (
      line.split(",").slice(0, 11).join(",")
   ))

   return newData.join("\n");
 }

//reformat file to make each zone it's own line
const makeExportFile = (isLps, data) => {
  let newData = [];

  data.split("\n").forEach(line => {
    let cols = line.split("\",\"");
    if (cols[5] && cols[5].indexOf(",") > 0) {
      cols[5].split(',').forEach(zone => {
        newData.push(line.replace(cols[5], zone));
      })
    } else {
      newData.push(line);
    }
  })

  let finalData = [...newData];
  if (isLps) {
    finalData = newData.map(line => line.split(",").slice(0, 11).join(","));
  }
  return finalData.join("\n");
}

class Download extends React.Component {
  constructor() {
    super();

    this.state = {
      file_name: "",
      message: "",
      file_content: "",
      modalIsOpen: false,
      link: null,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateFileName = this.updateFileName.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true, file_name: makeFileName(this.props.from, this.props.to), file_content: "", message: "", link: null});
  }

  closeModal() {
    this.setState({modalIsOpen: false, file_name: "", file_content: "", message: "", link: null});
  }

  updateFileName(e) {
    this.setState({file_name: e.target.value});
  }

  downloadFile(e) {
    //error checking
    /*if (this.props.nodes.length === 0) { //removed this error checking
      this.setState({message: "No workers selected"});
      return;
    }*/

    if (this.props.to - this.props.from > 604800000) {
      this.setState({message: "Interval needs to be < 7 days"});
      return;
    }

    if (this.state.file_name.trim().length === 0) {
      this.setState({message: "Empty filename"});
      return;
    }

    this.setState({message: "Creating file..."});

    let body = {from: this.props.from, to: this.props.to};
    if (new Date().getTimezoneOffset()/60 === -9) { //check for JST timezone
      console.log("JST timezone");
      body = Object.assign({}, body, {time_zone: 'JST'});
    }

    downloadWmsFile(this.props.authToken, this.props.currentSiteId, body)
      .then(res => res.text())
      //.then(text => this.props.isLps ? makeLpsFile(text) : text)
      .then(text => makeExportFile(this.props.isLps, text))
      .then(text => { this.setState({file_content: text})})
      .then(() => {
        let link = makeTextFile(this.state.file_content, this.state.link);
        this.setState({message: "", link: link});
      })
  }

  render() {
    return (
      <div style={{display: "inline-block"}}>
        <img className="MapTypeIcon" alt="download" src={IMG_CONFIG['download']['active']['img']} onClick={this.openModal}/>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Download WMS DATA"
          className="Upload"
        >
        <img className="xImg" alt="close" src={require("../img/x.png")} onClick={this.closeModal}/>
        <div className="UploadContainer">
          <div>Download {this.props.isLps ? "LPS" : "WMS"} DATA</div>
          <div className="SelectFileText">Filename</div>
          <input type="text" size="40" placeholder={this.state.file_name} name="Choose File" onChange={this.updateFileName} />
          <div>
            <div className="DownloadButton">
              <div className="UploadText" onClick={this.downloadFile}>CREATE</div>
            </div>
            <div className="LoadingStatus">{this.state.message}</div>
            <div className="LoadingStatus" style={{display: this.state.link ? "inline-block" : "none"}}><a href={this.state.link} download={this.state.file_name}>Download File</a></div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  state => ({ authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(Download);
