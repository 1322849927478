import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchCustomerUsers, addCustomerUser, deleteCustomerUser, fetchSiteUsers, addSiteUser, deleteSiteUser } from '../../../modules/users';
import { myHighestRole, makeSiteMenu, makeUserRoleMenu, ROLES_LOOKUP, ROLE_DESC } from '../Config';

import InfoContainer from '../../settings/form/InfoContainer';
import FormContainer from '../../settings/form/FormContainer';
import LabelContainer from '../../settings/form/LabelContainer';
import HelpModal from '../../common/HelpModal';
import { isEmpty } from '../../settings/form/ErrorChecking';

const CONFIG = { site: null, role: null, validate: true};
const CUSTOMER_ADMIN_URL = "/dashboard/management/users/customeradmin";
const SITE_ADMIN_URL = "/dashboard/management/users/siteadmin";

const EditUser= (props) => {
  const [user, setUser] = useState(null);
  const [config, setConfig] = useState(CONFIG);
  const [error, setError] = useState(CONFIG);
  const [help, setHelp] =  useState(CONFIG);
  const [siteMenu, setSiteMenu] = useState([]);
  const [roleMenu, setRoleMenu] = useState([]);
  const [url, setUrl] = useState("");
  const [myRole, setMyRole] = useState(null);
  const [labels, setLabels] = useState({});
  const [fields, setFields] = useState({});

  useEffect(() => {
    //console.log("use effect 1");
    if (url === "") {
      let myRole = myHighestRole(props.acl);
      myRole === 'site-admin' ? setUrl(SITE_ADMIN_URL) : setUrl(CUSTOMER_ADMIN_URL);
      setMyRole(myRole);
    }
  }, [props.acl, url])

  useEffect(() => {
    //console.log("use effect 2");
    if (siteMenu.length === 0 && myRole) {
      let filter = Object.values(props.sites).filter( site => site.customer === props.match.params.customer);
      setSiteMenu(makeSiteMenu(filter, myRole));
    }
  }, [props.match.params.customer, props.sites, myRole, siteMenu.length])

  useEffect(() => {
    //console.log("use effect 3");
    if (roleMenu.length === 0 && myRole && siteMenu.length > 0) {
      setRoleMenu(makeUserRoleMenu(siteMenu[0].value, myRole));
    }
  }, [myRole, roleMenu.length, siteMenu])

  useEffect (()=> {
    //[user, myRole, props.authToken, props.match.params.site, props.match.params.customer, props.match.params.id]
    //console.log("use effect 4")
    //find the user and his acl
    //console.log("custoemr - " + props.match.params.customer);
    //console.log("site - " + props.match.params.site);
    //console.log("user - " + props.match.params.id);

    // {"username":"site-viewer-sitetest","email":"matt-test",
    //"acl":[{"site":"2a9642e57d38453bb401d2571825af1d",
    //"site_name":"Ichikawa-Jpn",
    //"customer":"1cf900025f6c4bba9aa7b76a09dfbbe0",
    //"customer_name":"MSCS","role":"site-viewer"}]}

    if (user === null) {
      console.log("use effect 4")
      setConfig(CONFIG);
      setError(CONFIG);
      setHelp(CONFIG);

      let fetchUser = myRole === 'site-admin' ?
        fetchSiteUsers(props.authToken, props.match.params.site)
          :
        fetchCustomerUsers(props.authToken, props.match.params.customer);

      fetchUser
        .then(json => {
          //console.log("USERS - " + JSON.stringify(json));
          let user = json.users[props.match.params.id]

          //make labels and fields
          let labels = {"username": {"help": "", "desc": ""}};
          let fields = {"email": {"type": "text", "value": user && user.username, "error": ""}};

          user.acl && user.acl.forEach((acl, index) => {
            //labels
            labels["Site " + (index + 1)] = {"help": "", "desc": ""}
            labels["User Type " + (index + 1)] = {"help": "", "desc": ""};

            //fields
            let site_name = acl.site === '*' ? "All Sites" : acl.site_name;
            fields["Site " + (index + 1)] =
              { "type": "delete_button",
                "text" : site_name,
                "value": acl.site,
                "warn": `Removing a user will only revoke this user’s ability to access ${site_name}. Do you want to continue?`,
                "error": ""
              };
            fields["User Type " + (index + 1)]= {"type": "text", "value": ROLES_LOOKUP[acl.role], "help": ROLE_DESC[acl.role]}
          })

          labels["New Site"] = {"help": "", "desc": ""}
          labels["New User Type"] = {"help": "", "desc": ""};

          setUser(user);
          setLabels(labels);
          setFields(fields);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [user, myRole, props.authToken, props.match.params.site, props.match.params.customer, props.match.params.id]);

  const validateFields = () => {
    let errorNew = {...CONFIG};
    isEmpty("site", config.site, errorNew);
    isEmpty("role", config.role, errorNew);
    setError(errorNew);
    return errorNew.validate;
  };

  const updateField = (field, e, type) => {
   //  console.log(field + " " + e + " " + type);
    let configNew = {...config};
    if (type && type === 'select') {
      if (!e) { return }
      configNew[field] = e
    }

    if (type ==='long_button'){
      //Add Site Access Button
      if (!validateFields()) {
        return;
     }

     let body = {
       user: {
         name: user.email,
         role: config.role.value,
       }
     }

     let addFunction = config.site.value === '*' ?
      addCustomerUser(props.authToken, props.match.params.customer, body)
      :
      addSiteUser(props.authToken, config.site.value, body);

      addFunction
        .then(json => console.log(json))
        .then(()=> setUser(null))
        .catch(err => {
          console.error(err);
          setUser(null);
        });
    }

    if (type === 'delete_button') {
      //is this the last acl?
      let last_one = user.acl.length === 1 ? true : false;

      //if site is * = delete customer if site is id then delete sites, set user to null
      let deleteFunction = e === '*' ?
        deleteCustomerUser(props.authToken, props.match.params.customer, props.match.params.id)
        :
        deleteSiteUser(props.authToken, e, props.match.params.id);

        deleteFunction
          .then(json => console.log(json))
          .then(()=> last_one ? setTimeout(()=>props.history.push(url), 1000) : setUser(null))
          .catch(err => {
            console.error(err);
            last_one ? setTimeout(()=>props.history.push(url), 1000) : setUser(null);
          });
    }

   if (field === 'site') { //if new site is selected, update role menu
      setRoleMenu(makeUserRoleMenu(e.value, myRole));
    }

    if (field === 'role') { //if new role is selected, update help text
      let helpNew = {...help};
      helpNew[field] = ROLE_DESC[e.value];
      setHelp(helpNew);
    }

    setConfig(configNew);
  }

  const NEW_FIELDS = {
    "site" : {"type": "select", options: siteMenu, value: config.site, placeholder: "", error: error.site, help: help.site},
    "role" : {"type": "select", options: roleMenu, value: config.role, placeholder: "", error: error.role, help: help.role},
    "Add Site Access" : {type: "long_button", label: "Add Site Access", value: "", error: ""},
  }

  return (
    <div className="main edit">
      <Link to={url}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>Edit User</h3>
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
      <div key="save">
        <LabelContainer labels= {labels}/>
        <FormContainer
          fields = {{...fields, ...NEW_FIELDS}}
          updateField={updateField}
        />
        <InfoContainer
          info={{"Site Access" : user
                  && user.acl.map(acl =>
                    <div key={acl.site_name}>
                      <div className="field_prop">{acl.site_name === 'wildcard' ? "All Sites" : acl.site_name}</div>
                        :
                        <div className="field_value">{ROLES_LOOKUP[acl.role]}</div>
                    </div>
                  )
                }}
          help=< HelpModal
                  button="help"
                  help_content=<img alt="site" src={require(myRole === 'site-admin' ? "../img/site-admin-table.png" : "../img/cust-admin-table.png")}/>
                />
        />
      </div>
      </div>
      <div>
          <Link to={url}><button className="cancel">Cancel</button></Link>
          <span id="save"><Link to={url}><button className="save">Done</button></Link></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
    authToken: state.authToken,
    acl: state.user.acl, //my acl
    sites: state.sites.sites,
    customers: state.sites.customers,
  })
)(EditUser);

/*
  const LABELS = {
    "Email": {"help": "", "desc": ""},
    "Site": {"help": "", "desc": ""},
    "User Type": {"help": "", "desc": ""},
    "Site 2": {"help": "", "desc": ""},
    "User Type 2": {"help": "", "desc": ""},
    "Site 3": {"help": "", "desc": ""},
    "User Type 3": {"help": "", "desc": ""},
  }

  const FIELDS = {
    "email": {"value": user && user.username, "placeholder": "", "error": error.email},
    "site 1" : {"type": "delete_button", "value": "site 1", "warn": "this is my warning", "error": "" },
    "role" : {"type": "text", "value": "role 1", "error": error.site },
    "Site 2" : {"type": "delete_button", "value": "site 2", "warn": "this is my warning", "error": "" },
    "Role 2" : {"type": "text", "value": "role 2", "error": error.site },
    "Site 3" : {"type": "select", "options": siteMenu, placeholder: "", "error": "" },
    "Role 3" : {"type": "select", "options": roleMenu, placeholder: "", "error": "" },
    "Add" :  {"type": "long_button", "label": "Add Site Access", "value": "", "error": ""},
  }
  */
