import React, { Component } from 'react';
import merge from 'lodash/merge';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { updateNode, deleteNode } from '../../modules/nodes';

const errorStyle={color: "red"}

const EditTracker = ({
  handleOpenModal,
  handleCloseModal,
  handleSubmit,
  handleDelete,
  updateField,
  updateConfigField,
  showModal,
  name,
  configs,
  pending,
  errors,
  node,
  displayname
}) => (
  <div>
    <a onClick={handleOpenModal}>{displayname.toUpperCase()}</a>
    <Modal isOpen={showModal} onRequestClose={handleCloseModal} ariaHideApp={false}>
      <h3>Edit Tracker</h3>
      <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="system_id">
           SYSTEM ID
          <input
            id="system_id"
            type="text"
            value={node.node}
            disabled
          />
        </label>
      </div>
      <div className="form-group">
        <label htmlFor="hardware_id">
           HARDWARE ID
          <input
            id="hardware_id"
            type="text"
            value={node.hardware_id}
            disabled
          />
        </label>
      </div>
        <div className="form-group">
          <label htmlFor="name">
            NAME
            <span style={errorStyle}>{errors.name}</span>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => { updateField('name', e.target.value); }}
            />
            </label>
        </div>
        <div className="form-group">
          <label htmlFor="addr">
            LPS MAC ADDRESS<small>Read-only</small>
            <span style={errorStyle}>{errors.lps_mac_address}</span>
            <input
              id="addr"
              type="text"
              value={configs.tracker.addr}
              disabled
              //onChange={(e) => { updateConfigField('tracker',  e.target.value, 'addr') }}
            />
              </label>
        </div>
        <div className="form-group">
          <label htmlFor="track_interval">
            TRACK INTERVAL
            <small>Interval between locates when tracking
             (milliseconds)</small>
              <span style={errorStyle}>{errors.track_interval}</span>
            <input
              id="track_backoff"
              type="number"
              value={configs.tracker.track_backoff === null ? '' : configs.tracker.track_backoff}
              onChange={(e) => { updateConfigField('tracker',  parseInt(e.target.value, 10), 'track_backoff') }}
            />
            </label>
            </div>
        <div className="form-group">
          <label htmlFor="locate_on_stop">
            LOCATE ON STOP
            <small>Locate device when motion stops
            (0=off, 1=on)</small>
            <span style={errorStyle}>{errors.locate_on_stop}</span>
            <input
              id="locate_mode"
              type="number"
              value={configs.tracker.locate_mode === null ? '' : configs.tracker.locate_mode}
              onChange={(e) => { updateConfigField('tracker',  parseInt(e.target.value, 10), 'locate_mode') }}
            />
            </label>
        </div>

        <div className="form-group">
          <label htmlFor="tracking_mode">
            TRACKING MODE
            <small>(0=off,1=always,2=track on motion)</small>
              <span style={errorStyle}>{errors.tracking_mode}</span>
            <input
              id="tracking_mode"
              type="number"
              value={configs.tracker.tracking_mode === null ? '' : configs.tracker.tracking_mode}
              onChange={(e) => { updateConfigField('tracker',  parseInt(e.target.value, 10), 'tracking_mode') }}
            />
            </label>
        </div>

        <div className="form-group">
          <label htmlFor="tdoa_tracking_mode">
            TDOA TRACKING MODE
            <small>(0=off,1=on)</small>
              <span style={errorStyle}>{errors.tdoa_tracking_mode}</span>
            <input
              id="tdoa_tracking_mode"
              type="number"
              value={configs.tracker.tdoa_tracking_mode === null ? '' : configs.tracker.tdoa_tracking_mode}
              onChange={(e) => { updateConfigField('tracker',  parseInt(e.target.value, 10), 'tdoa_tracking_mode') }}
            />
            </label>
        </div>

        <div className="form-group">
          <label htmlFor="lps_control">
            LPS CONTROL - POWER ENABLE
            <small>(0,1)</small>
              <span style={errorStyle}>{errors.lps_control}</span>
            <input
              id="lps_mode"
              type="number"
              value={configs.lps_control.power_enable === null ? '' : configs.lps_control.power_enable}
              onChange={(e) => { updateConfigField('lps_control',  parseInt(e.target.value, 10), 'power_enable') }}
            />
            </label>
        </div>
        <div className="form-group">
          <label htmlFor="timestamp">
            Calibration Timestamp
            <input
              id="timestamp"
              type="text"
              value={configs.lps_control.cal ? new Date(configs.lps_control.cal.timestamp).toString() : "Not Calibrated"}
              disabled
            />
          </label>
        </div>
        <div className="button-group">
          <button
            type="button"
            onClick={handleCloseModal}
            className="cancel-button"
          >
            CANCEL
          </button>
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={pending}
          >
            SAVE
          </button>
          <button
            onClick={handleDelete}
            disabled={pending}
            className="delete-button left"
          >
            DELETE
          </button>
        </div>
      </form>
    </Modal>
  </div>
);

EditTracker.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  updateConfigField: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  errors: PropTypes.shape({ name: PropTypes.string }).isRequired,
  displayname: PropTypes.string.isRequired,
};

EditTracker.defaultProps = {
};

class NodeEditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      name: this.props.node.name,
      hardware_id: this.props.node.hardware_id,
      errors: {
        name: '', lps_mac_address: '', track_interval: '', locate_on_stop: '', tracking_mode: '',
      },
      configs: {
        tracker: {
          addr: this.props.node.configs.tracker.lps_addr,
          track_backoff: this.props.node.configs.tracker.track_backoff,
          locate_mode: this.props.node.configs.tracker.locate_mode,
          tracking_mode: this.props.node.configs.tracker.tracking_mode,
          tdoa_tracking_mode: this.props.node.configs.tracker.tdoa_tracking_mode,
        },
        lps_control: {
          cal: this.props.node.configs.lps_control.cal,
          power_enable: this.props.node.configs.lps_control.power_enable,
        }
      },
      pending: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.updateField = this.updateField.bind(this);
    this.updateConfigField = this.updateConfigField.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node.node !== this.props.node.node ) {
      this.setState(
        {
          showModal: false,
          name: this.props.node.name,
          hardware_id: this.props.node.hardware_id,
          errors: {
            name: '', lps_mac_address: '', track_interval: '', locate_on_stop: '', tracking_mode: '',
          },
          configs: {
            tracker: {
              addr: this.props.node.configs.tracker.lps_addr,
              track_backoff: this.props.node.configs.tracker.track_backoff,
              locate_mode: this.props.node.configs.tracker.locate_mode,
              tracking_mode: this.props.node.configs.tracker.tracking_mode,
              tdoa_tracking_mode: this.props.node.configs.tracker.tdoa_tracking_mode,
            },
            lps_control: {
              cal: this.props.node.configs.lps_control.cal,
              power_enable: this.props.node.configs.lps_control.power_enable,
            }
          },
          pending: false,
        }
      );
    }
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.validateFields()) {
      this.setState({ errors: { name: '', lps_mac_address: '', track_interval: '', locate_on_stop: '', tracking_mode: '', tdoa_tracking_mode: ''}});
      this.handleCloseModal();
      this.props.dispatch(updateNode(this.props.node.node, {
        node: {
          configs: this.state.configs,
          name: this.state.name,
        }
      }));
    } else {
      this.setState({ errors: { name: 'Required', lps_mac_address: '', track_interval: 'Needs to be an integer and >= 2000', locate_on_stop: 'Needs to be (0,1)', tracking_mode: 'Needs to be (0,1,2)',
                      tdoa_tracking_mode: "Needs to be (0,1)", lps_control: "Needs to be (0,1)"
                    } });
    }
    // this.setState({ pending: true });
  }

  handleDelete(event) {
    event.preventDefault();
    this.handleCloseModal();
    this.props.dispatch(deleteNode(this.props.node.node));
  }

  validateFields() {
    return (this.state.name !== '' &&
            (this.state.configs.tracker.track_backoff !== null ? this.state.configs.tracker.track_backoff >= 2000 : true)
            &&
            (this.state.configs.tracker.locate_mode !== null ? (this.state.configs.tracker.locate_mode >= 0 &&  this.state.configs.tracker.locate_mode <= 1) : true)
            && (this.state.configs.tracker.tracking_mode !== null ?
              (this.state.configs.tracker.tracking_mode >= 0 && this.state.configs.tracker.tracking_mode <= 2): true)
            && (this.state.configs.lps_control.power_enable >= 0 && this.state.configs.lps_control.power_enable <= 1)
            && (this.state.configs.tracker.tdoa_tracking_mode >= 0 && this.state.configs.tracker.tdoa_tracking_mode <= 1)
          )
  }

  updateField(name, value) {
    this.setState({ [name]: value });
  }

  updateConfigField(name, value, attr) {
    this.setState({
      configs: merge(
        {},
        this.state.configs,
        { [name]: { [attr]: value } },
      ),
    });
  }

  render() {
   //console.log(JSON.stringify(this.state.configs))
    return (
      <EditTracker
        node={this.props.node}
        handleOpenModal={this.handleOpenModal}
        handleCloseModal={this.handleCloseModal}
        handleSubmit={this.handleSubmit}
        handleDelete={this.handleDelete}
        updateField={this.updateField}
        updateConfigField={this.updateConfigField}
        showModal={this.state.showModal}
        configs={this.state.configs}
        name={this.state.name}
        pending={this.state.pending}
        errors={this.state.errors}
        displayname={this.props.displayname}
      />
    );
  }
}

export default connect(state => ({
  nodes: state.nodes,
}))(NodeEditContainer);

/*
<div className="form-group">
  <label htmlFor="calibration">
    Calibration
    <textarea
      id="calibration"
      type="text"
      value={configs.lps_control.cal ? JSON.stringify(configs.lps_control.cal) : ""}
      disabled
    />
  </label>
</div>
*/
