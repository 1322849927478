import React, {useState, useEffect} from 'react';
import DatePicker from 'react-datepicker';

import './Common.css';
require("react-datepicker/dist/react-datepicker-cssmodules.css");

const style = {
  margin: "0px 0px 10px 0px",
  width: "280px",
  height: "10px",
  borderRadius: "2px",
  border: "solid 0.5px #979797",
  paddingLeft: "30px",
  textAlign: "left",
  outline: "none",
  cursor: "pointer",
};

function makeDate(time) {
  let start = new Date(time)
  return ((start.getMonth() + 1) + "/" + start.getDate() + "/" + start.getFullYear())
}

const DateRange = ({from, to, setFromTo}) => {
  const [show, setShow] = useState(false);
  const [dateString, setDateString] = useState(makeDate(from) + " - " + makeDate(to));
  const [startDate, setStartDate] = useState(from); //milliseconds
  const [endDate, setEndDate] = useState(to);

  useEffect(() => {
    if (show) {
      setStartDate(from);
      setEndDate(to);
    }

    if (!show) {
      let st = new Date(startDate).getTime();
      let end = new Date(endDate).setHours(23, 59,59);
      setFromTo(st, end);
      setDateString(makeDate(st) + " - " + makeDate(end));
    }
  }, [show]);

  const onChangeThis = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (end !== null) {
      setShow(false);
    }
  };

  return (
    <div className="common">
      <input type="text" style={style} placeholder={dateString} onClick={()=>{setShow(!show)}}/>
      <img className="searchIconStyle calendarIconStyle" alt="calendar" src={require("./img/calendar.png")}/>
      <div className="cal" style={{display: show ? "block" : "none"}}>
      <DatePicker
        onChange={onChangeThis}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        />
      </div>
    </div>
  )
}

export default DateRange;
