import React, {useState, useEffect, /*useMemo*/ } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchObjectHistory } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import { getDocksArray } from '../../modules/docks';
import { getNodesArray } from '../../modules/nodes';
import { formatTime12Hours, convertToHourMin, cal_full_int_activity, /*make_occupancy_data, make_activity_data*/ } from './Utility';

import TruckCameraEvent from '../analytics/TruckCameraEvent';
import DockDetailChart from './DockDetailChart';

function createCameraView(camera, cameraEvents) {
  return (
    cameraEvents.map ((h, index) =>
    <div key={h.update.events.camera.media_ready.timestamp + "_key"} style={{display: "inline-block"}}>
      <TruckCameraEvent key={h.update.events.camera.media_ready.timestamp} node={Object.assign({node: camera.node}, {events: h.update.events})}/>
      <div className="ExtName SummaryCameraName">
        {camera.name}
        <div className="ExtDate">{new Date(h.update.events.camera.media_ready.timestamp).toLocaleString()}</div>
      </div>
    </div>
    )
  )
}

const SummaryDetail = (props) => {
  const [from] = useState(parseInt(props.match.params.from, 10));
  const [to] = useState(parseInt(props.match.params.to, 10) + 30000);
  const [dock, setDock] = useState();
  const [extCamera, setExtCamera] = useState();
  const [intCamera, setIntCamera] = useState();
  const [extCameraEvents, setExtCameraEvents] = useState([]);
  const [intCameraEvents, setIntCameraEvents] = useState([]);
  const [dockStats, setDockStats] = useState();
  const [activityData, setActivityData] = useState([]);
  const [cameraData, setCameraData] = useState([]);
  const [intCameraData, setIntCameraData] = useState([]);
  const [extImagePosX, setExtImagePosX] = useState("0px")
  const [intImagePosX, setIntImagePosX] = useState("0px")

  useEffect(()=> {
    setDockInfo();
    setCameraHistory();
    setDockHistory();
  }, [props.match.params.dock, props.match.params.from, props.match.params.to, Object.keys(props.docks), Object.keys(props.nodes)]);

  const setDockInfo = (()=> {
    setDock(props.docks.filter(dock => (dock.node === props.match.params.dock))[0]);
    setExtCamera(props.nodes.filter(node => (dock && node.node_type === 'camera' && node.node === dock.events.dock.occupancy.camera_id))[0]);
    setIntCamera(props.nodes.filter(node => (dock && node.node_type === 'camera' && node.node === dock.events.dock.activity.camera_id))[0]);
  });

  const setCameraHistory = (() => {
    if (extCamera !== undefined && extCameraEvents.length === 0) {
      fetchObjectHistory(extCamera.node, props.authToken, props.currentSiteId, {event: "camera:media_ready", from: from, to: to})
        .then(json => json.history.sort((a,b)=> a.timestamp - b.timestamp))
        .then(json => {setExtCameraEvents(json); return json})
        .then(json => {
          let data = [];
          json.forEach( h => {
            data.push({'x': h.timestamp, "y": .25});
            data.push({'x': h.timestamp + 1, "y": 0});
          });
          setCameraData(data);
        })
    }

    if (intCamera !== undefined && intCameraEvents.length === 0) {
      fetchObjectHistory(intCamera.node, props.authToken, props.currentSiteId, {event: "camera:media_ready", from: from, to: to})
        .then(json => json.history.sort((a,b)=> a.timestamp - b.timestamp))
        .then(json => {setIntCameraEvents(json); return json})
        .then(json => {
          let data = [];
          json.forEach( h => {
            data.push({'x': h.timestamp, "y": .25});
            data.push({'x': h.timestamp + 1, "y": 0});
          });
          setIntCameraData(data);
        });
    }
  });


  const setDockHistory = (() => {
    if (dockStats === undefined) {
     fetchObjectHistory(props.match.params.dock, props.authToken, props.currentSiteId, {event: "dock:activity", from:  from, to: to, limit: 100000})
      .then(json => json.history.sort((a,b) => a.timestamp - b.timestamp))
      .then(json => {
        let data = [];
        json.forEach(h => {
          data.push({"x": h.timestamp, "y": h.update.events.dock.activity.motion/8});
        })
        setActivityData(data);
      })

      fetchObjectHistory(props.match.params.dock, props.authToken, props.currentSiteId, {event: "dock:activity_change", from: from, to: to})
      .then(json => json.history.sort((a,b)=> a.timestamp - b.timestamp))
      .then(json => {
        setDockStats(cal_full_int_activity(json, from, to));
      })
    }
  });

  const slideEvent = ((e) => {
    //console.log(e.target.value);
    let cur = from + (to - from) * (e.target.value);
    for (let i = 0; i < extCameraEvents.length; i++) {
      if ((cur > extCameraEvents[i].timestamp && i === extCameraEvents.length - 1) || (cur > extCameraEvents[i].timestamp && cur < extCameraEvents[i + 1].timestamp)) {
        setExtImagePosX((i * -480) + "px");
        break;
      }
    }

    for (let j = 0; j < intCameraEvents.length; j++) {
      if ((cur > intCameraEvents[j].timestamp && j === intCameraEvents.length - 1) || (cur > intCameraEvents[j].timestamp && cur < intCameraEvents[j + 1].timestamp)) {
        setIntImagePosX((j * -480) + "px");
        break;
      }
    }
  });

  const playBack = ((e)=>{
    for (let i = 0; i < extCameraEvents.length; i++) {
      setTimeout(()=> setExtImagePosX((i * -480) + "px"), i * 100);
    }

    for (let j = 0; j < intCameraEvents.length; j++) {
      setTimeout(()=> setIntImagePosX((j * -480) + "px"), j * 100);
    }
  });

  return (
    <div className="DockDetailCard">
      <Link to={`/dashboard/dock_detail/${props.match.params.dock}`}><img className="Back" src={require("./img/back.png")} alt="back"/></Link>
      <Link className="DockDetailHeader" to={`/dashboard/dock_detail/${props.match.params.dock}`}>{dock&& dock.name} - STAY SUMMARY</Link>
      <div className="fa fa-play-circle PlayButton" onClick={(e)=>{playBack(e)}}/>
      <div>
        {extCamera && extCameraEvents &&
            <div className="SummarySlide">
              <div className="SummaryCameraContainer" style={{width: 480 * extCameraEvents.length, transform: `translateX(${extImagePosX})`}}>
                {createCameraView(extCamera, extCameraEvents)}
              </div>
            </div>
        }
        {intCamera && intCameraEvents &&
            <div className="SummarySlide">
              <div className="SummaryCameraContainer" style={{width: 480 * intCameraEvents.length, transform: `translateX(${intImagePosX})`}}>
                {createCameraView(intCamera, intCameraEvents)}
              </div>
            </div>
        }
        <div className="DetailContainer" style={{marginTop: "0px"}}>
            <div className="DockDetail">TRUCK #
              <div className="DockDetailAttr">{props.match.params.index}</div>
            </div>
            <div className="DockDetail">ARRIVAL TIME
              <div className="DockDetailAttr">{formatTime12Hours(from)}</div>
            </div>
            <div className="DockDetail">DEPARTURE TIME
              <div className="DockDetailAttr">{formatTime12Hours(to-30000)}</div>
            </div>
            <div className="DockDetail">DWELL TIME
              <div className="DockDetailAttr">
                {convertToHourMin(to - from)}
              </div>
            </div>
            <div className="DockDetail">EFFICIENCY
              <div className="DockDetailAttr">
               {dockStats && dockStats.activity_percent}%
               </div>
            </div>
            <div className="DockDetail">ARRIVAL GAP
              <div className="DockDetailAttr">
              {dockStats && convertToHourMin(dockStats.arrival_gap_raw)}
              </div>
            </div>
           <div className="DockDetail">DEPARTURE GAP
              <div className="DockDetailAttr">
              {dockStats && convertToHourMin(dockStats.departure_gap_raw)}
              </div>
           </div>
        </div>
      </div>

      <div className="RangeSliderContainer DockDetailRangeContainer">
        <input className="RangeSlider" type="range" step=".01" defaultValue="0" min="0" max="1" onKeyUp={(e)=>{slideEvent(e)}} onChange={(e)=>{slideEvent(e)}}/>
      </div>
      <DockDetailChart dock={dock}
                       labels={[]}
                       ext_camera_events={cameraData}
                       int_camera_events={intCameraData}
                       interior_motion={activityData}
                       min={from}
                       max={to}
                       />

    </div>
  )
};

export default connect(
  state => (
    {
      docks: getDocksArray(state), //docks only
      nodes: getNodesArray(state), //cameras and docks together
      authToken: state.authToken,
      currentSiteId: getCurrentSiteId(state)
   }),
)(SummaryDetail);
