import React from 'react';
import {HorizontalBar} from 'react-chartjs-2';

import {makeYAxis} from '../charts/ChartUtil';
//import Legend from '../charts/Legend';
import TimeIntervalChart from '../charts/TimeIntervalChart';

import {IMG_CONFIG} from '../2dConfig';

const NOZONE_COLOR = "#FFFFFF";
//const LEGEND="Ichikawa_legend.png";
const QUERY_LIMIT=86400000;

const options = {
  'default' : { //minutes
    animation: false,
    legend: {
         display: false,
     },
    tooltips: {
          mode: 'nearest'
    },
    scales: {
      yAxes: [{
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
        ticks: {
          min: 0,
          callback: function(value) {
            return value + " min"
          }
        }
      }]
    }
  },
  'percent': {
      responsive: false,
      tooltips: {
            mode: 'nearest'
      },
      animation: false,
      legend: {
           display: false,
       },
      scales: {
        yAxes: [{
          stacked: true,
        }],
        xAxes: [{
          stacked: true,
          ticks: {
            min: 0,
            max: 100,
            callback: function(value) {
              return value + "%"
            }
          }
        }]
      }
    },
}

class WmsZone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1 ? "percent" : "time", //"time" or "percent"
      show_legend: false,
      labels: [],
      data: [], //percent
      y_axis: [], //time chart
      progression_data: [], //time progression data [{start, duration, color}]
    };

    this.setType = this.setType.bind(this);
    this.setLegend= this.setLegend.bind(this);
  }

  componentDidMount() {
      this.makeData();
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.zone_config).length !== Object.keys(this.props.zone_config).length ||
        JSON.stringify(prevProps.sorted_nodes) !== JSON.stringify(this.props.sorted_nodes) ||
        JSON.stringify(prevProps.asset_stats) !== JSON.stringify(this.props.asset_stats) ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals) ||
        prevProps.expand !== this.props.expand)
    {
      if (this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1)  {
        this.setState({type: "percent"});
      }
      this.makeData();
    }
  }

  makeData() {
    //make labels, data
    let labels = this.props.sorted_nodes.map(n => this.props.worker_hash[n].name);
    let data = [];

    //make percentage
    Object.keys(this.props.zone_config).forEach(z => {
      let zoneData = {label: "", data: [], backgroundColor: ""};

      zoneData['label'] = this.props.zone_config[z].name;
      zoneData['backgroundColor'] = "#" + this.props.zone_config[z].color;

      let array = []; //make data array [tracker1,tracker2, etc. ]
      this.props.sorted_nodes.forEach(n => {
        if (this.props.asset_stats.hasOwnProperty(n) && this.props.asset_stats[n] !== undefined && this.props.asset_stats[n].hasOwnProperty('zones')) {
          let zone_stats = this.props.asset_stats[n].zones;
          let duration = this.props.asset_stats[n].attached_time ? this.props.asset_stats[n].attached_time : this.props.duration;

          if (zone_stats.hasOwnProperty(z) && zone_stats[z].time/duration > .001 && duration) {
            array.push((zone_stats[z].time/duration * 100).toFixed(2));
          } else {
            array.push(0);
          }
        } else {
            array.push(0);
        }
      }
    )

      zoneData['data'] = array;
      data.push(zoneData);
    }) //end object keys

    this.setState({labels: labels, data: data}, this.makeTimeProgressionData());
  }

  makeTimeProgressionData() {
    if (this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1) { return; }
    
    console.log("make time progression zone data")
    let all_data = [];

    this.props.sorted_nodes.forEach(n => {
      let data = [];
      data.push({start: 0, duration: 1, color: NOZONE_COLOR});

      if (this.props.asset_stats && this.props.asset_stats.hasOwnProperty(n) && this.props.asset_stats[n] !== undefined) {
        if (this.props.asset_stats[n].hasOwnProperty('zone_intervals')) {
          this.props.asset_stats[n].zone_intervals.forEach(i => {
            if (i['zones'].length === 0 || !this.props.zone_config.hasOwnProperty(i['zones'][0])) { return }

            let start = (i['start'] - this.props.from) / this.props.duration;
            let dur = i['duration'] / this.props.duration;

            if (start < 0) { return }
            if (dur * 100 < .01) { return }

            let interval = {start: start, duration: dur, color: ("#" + this.props.zone_config[i['zones'][0]].color), tooltip:{id: this.props.worker_hash[n].name, props: {'Zone': this.props.zone_config[i['zones'][0]].name}}};
            data.push(interval);
          })
        }

        //do checkin time block
        let begin = 0;
        if (this.props.asset_stats[n].hasOwnProperty('attachment_intervals')) {
          this.props.asset_stats[n].attachment_intervals.forEach( i => {
            //node_time.push({start: (i.start - this.props.from)/duration , duration: i.duration/duration, color: COLOR['checked_in']});
            data.push({start: begin/this.props.duration , duration: (i.start - this.props.from - begin)/this.props.duration, color: '#D4F9F8'});
            begin = i.start + i.duration - this.props.from;
          })
          data.push({start: begin/this.props.duration , duration: (this.props.to - this.props.from - begin)/this.props.duration, color: '#D4F9F8'});
        }
      }
      all_data.push(data);
    });

    this.setState({y_axis: makeYAxis(this.props.from, this.props.to), progression_data: all_data})
  }

  setType(e) {
    if (this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1) { return; }
    this.setState({type: e.target.dataset.type});
  }

  setLegend(e) {
    this.setState((prevState, props) => ({show_legend: !prevState.show_legend}))
  }

  render() {
    return (
      <div className="SiteChart">
      {this.props.expand ?
        <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
        <img className="ExpandIcon" alt="expand" data-chart="zone_time" src={require("../img/expand.png")} onClick={this.props.setChartType} />
      }
        <div className="SiteHeader">TIME SPENT IN ZONES
        {
          this.props.displayDownload &&
          <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="zone" data-type={this.state.type} onClick={this.props.setDownloadType}/>
        }
        </div>
        <div id="zone" className="SiteChartContainer">
        { this.state.type === 'time' ?
          <div>
            <img className="ChartOptionsImg" alt="percent_unselect" data-type='percent' src={require("../img/percent_unselect.png")} onClick={this.setType}/>
            <img className="ChartOptionsImg" alt="time_select" src={require("../img/time_select.png")} />
          </div>
           :
          <div>
            <img className="ChartOptionsImg" alt="percent_select" src={require("../img/percent_select.png")} />
            <img className="ChartOptionsImg" alt="time_unselect" data-type='time' src={this.props.duration > QUERY_LIMIT || this.props.time_intervals.length > 1 ? require("../img/time_disabled.png") : require("../img/time_unselect.png")} onClick={this.setType}/>
          </div>
        }
          <div style={{display: this.state.type==='percent' ? "" : "none", marginTop: "30px"}}>
          <HorizontalBar
              key={this.state.labels.length}
              width={980}
              height={this.state.labels.length * 25 + 30}
              data={{labels: this.state.labels, datasets: this.state.data}}
              options={options["percent"]} />
          </div>
          <div style={{display: this.state.type==='time' ? "" : "none"}}>
            <TimeIntervalChart labels={this.state.labels} y_axis={this.state.y_axis} data={this.state.progression_data}/>
          </div>
        </div>
      </div>
    )
  }
}

export default WmsZone;

/*
<div style={{display: this.state.show_legend ? "" : "none"}}>
  <Legend zone_src={LEGEND} zone_config={this.props.zone_config}/>
</div>

{
  this.state.show_legend ?
  <div>
    <img className="LegendOptionsImg" alt="hide_legend" src={require("../img/hide_legend.png")} onClick={this.setLegend}/>
    <div className="LegendOptionsText">HIDE LEGEND</div>
  </div>
  :
  <div>
    <img className="LegendOptionsImg" alt="show_legend" src={require("../img/show_legend.png")} onClick={this.setLegend}/>
    <div className="LegendOptionsText">SHOW LEGEND</div>
  </div>
}

*/
