import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
//import ActivityChart from '../ActivityChart';
import PastEventsList from '../events/PastEventsList';
import ConstellationSummary from './ConstellationSummary';
import './DetailsPage.css';
import { getCurrentSiteId } from '../../modules/sites';

const ConstellationDetailPage = ({ match, constellation, nodes, authToken, siteId }) => {
  if (constellation) {
   let list = Object.keys(nodes).map((n) => nodes[n]);
   let filteredList = list.filter((n)=> n.constellation === constellation.constellation);

    return (
      <div className="content-row">
        <div className="dashboard-content constellation-detail-page">
          <div className="heading">
            <ConstellationSummary constellation={constellation} />
          </div>
          <div className="recent-events">
            <h2>Past Events</h2>
            <PastEventsList constellation={constellation} nodes={filteredList} authToken={authToken} siteId={siteId} />
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/dashboard" />
  }
}

//<PastEventsList constellation={constellation} nodes={nodes} authToken={authToken} siteId={siteId} />

export default connect(
  (state, ownProps) => ({
    constellation: state.constellations.byId[ownProps.match.params.constellation],
    nodes: state.nodes.byId,
    authToken: state.authToken,
    siteId: getCurrentSiteId(state),
  })
)(ConstellationDetailPage);

/*
//hide activity chart for now
<ActivityChart
  chartWidth={1000}
  chartHeight={275}
  defaultRange="last_24h"
  filterType="Constellation"
  filterId={match.params.constellation}
/>
*/
