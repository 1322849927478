import React from 'react';
import { IMG_CONFIG } from '../2dConfig';

const RackLegend = ({p_legend, setShowRack, show_rack, openModal}) => (
    <div className="HeatmapLegendContainer">
      <div className="LegendTitle">LOCATION VISITS</div>
      <img className="LegendIcon2"
           alt="display_rack"
           src={show_rack ? IMG_CONFIG['toggle_on']['active']['img'] : IMG_CONFIG['toggle_on']['inactive']['img']}
           onClick={setShowRack}
      />
      <img className="LegendIcon"
           alt="edit_rack"
           src={require('../img/edit.png')}
           onClick={openModal}
      />
      <img className="Legend" alt="legend" src={require('../img/rack_gradient.png')}/>
      <div style={{display: "block"}}>
        <div className="Unit" style={{marginLeft: 0}}>{p_legend[0] > 0 ? 1 : 0}</div>
        <div className="Unit" style={{marginLeft: 65}}>{p_legend[0]}</div>
        <div className="Unit" style={{marginLeft: 140}}>{p_legend[1]}</div>
        <div className="Unit" style={{marginLeft: 210}}>{p_legend[2]}</div>
        <div className="Unit" style={{marginLeft: p_legend[3] < 100 ? 270 : 265}}>{p_legend[3]}</div>
      </div>
    </div>
);

export default RackLegend;
