import React from 'react';
import { connect } from 'react-redux';
import { fetchMediaBlob } from '../../modules/api';
import './AnalyticsPage.css';

const TruckCameraEvent = ({node, mediaFormat, srcUrl, mimeType }) => {
  switch (mediaFormat) {
    case 'image':
      return ( <img alt={srcUrl} src={srcUrl} />);
    case 'video':
      return ( <video autoPlay loop muted width="100%" height="100%" src={srcUrl} type={mimeType} />);
    default:
      return (<div></div>);
  }
}

class TruckCameraEventContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mediaFormat: 'image',
      key: null,
      srcUrl: require('../../media/Loading_Img.svg'),
      node: this.props.node,
    }
  }

  componentDidMount() {
    this.loadMedia();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.node.node !== this.props.node.node ||
        prevProps.node.events.camera.media_ready.key !==
        this.props.node.events.camera.media_ready.key) {
        this.loadMedia();
    }
  }

  loadMedia() {
    this.setState({mediaFormat: 'image', srcUrl: require('../../media/Loading_Img.svg')});

    let media = this.props.node.events.camera.media_ready;
    fetchMediaBlob(media.key, this.props.authToken)
      .then(srcUrl =>
        this.setState({
           srcUrl,
           mediaFormat: media.format,
           key: media.key,
           node: this.props.node}))
      .catch(err => console.log(err))
  }

  render() {
    return <TruckCameraEvent
      node={this.state.node}
      srcUrl={this.state.srcUrl}
      mediaFormat={this.state.mediaFormat}
    />;
  }
}

export default connect(
  state => ({ authToken: state.authToken })
)(TruckCameraEventContainer);
