//methods to check errors
export function isEmpty(field, value, error) {
  if (!value) {
    error[field] = "Field cannot be empty";
    error['validate'] = false;
  }
  return;
}

export function isEmail(field, value, error) {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
    return;
  } else {
    error[field] = "Field has to be email";
    error['validate'] = false;
    return;
  }
}

export function isSelectEmpty(field, value, error) {
  if (!value) {
    error[field] = "Field cannot be empty";
    error['validate'] = false;
  }
  return;
}

export function isUnique(field, value, error, array) {
  if (array.includes(value.trim())) {
    error[field] = "Field has to be unique";
    error['validate'] = false;
  }
  return;
}

export function isAtleastHex(field, value, error) {
  if (value.trim().length < 12) {
    error[field] = "Field has to be at least 12 digit hex number";
    error['validate'] = false;
  }

  if (!/^[A-Fa-f0-9]+$/.test(value)) {
    error[field] = "Field has to be at least 12 digit hex number";
    error['validate'] = false;
  }

  return;
}

export function isHex(field, value, error) {
  if (value.trim().length !== 12) {
    error[field] = "Field has to be 12 digit hex number";
    error['validate'] = false;
  }

  if (!/^[A-Fa-f0-9]+$/.test(value)) {
    error[field] = "Field has to be 12 digit hex number";
    error['validate'] = false;
  }

  return;
}

export function isLongHex(field, value, error) {
  if (value.trim().length !== 16) {
    error[field] = "Field has to be 16 digit hex number";
    error['validate'] = false;
  }

  if (!/^[A-Fa-f0-9]+$/.test(value)) {
    error[field] = "Field has to be 16 digit hex number";
    error['validate'] = false;
  }

  return;
}

export function isNumber(field, value, error) {
  if (isNaN(value)) {
    error[field] = "Field has to be a number";
    error['validate'] = false;
  }
  return;
}

export function isGreaterEqual(field, value, error, val) {
  if (value < val) {
    error[field] = "Field has to be greater or equal than " + val;
    error['validate'] = false;
  }
  return;
}

export function isLessEqual(field, value, error, val) {
  if (value > val) {
    error[field] = "Field has to be less or equal than " + val;
    error['validate'] = false;
  }
  return;
}

export function isNotSame(field, value1, value2, error) {
  if (value1 !== value2) {
    error[field] = "Password does not match";
    error['validate'] = false;
  }
  return;
}

export function isNotPassword(field, value, error) {
  if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/.test(value)) {
    return;
  }

  if (value.length < 8) {
    error[field] = "Password has to have at least 8 characters";
    error['validate'] = false;
    return;
  }

  if (!/(?=.*[A-Z])/.test(value)) {
    error[field] = "Password has to have at least one uppercase letter (A-Z)";
    error['validate'] = false;
    return;
  }

  if (!/(?=.*[a-z])/.test(value)) {
    error[field] = "Password has to have at least one lowercase letter (a-z)";
    error['validate'] = false;
    return;
  }

  if (!/(?=.*\d)/.test(value)) {
    error[field] = "Password has to have at least one number (0-9)";
    error['validate'] = false;
    return;
  }

  if (!/(?=.*[^a-zA-Z0-9])/.test(value)) {
    error[field] = "Password has to have at least one special character (e.g. !@#$)";
    error['validate'] = false;
    return;
  }

  return;
}
