import React from 'react';
import Modal from 'react-modal';
import html2canvas from 'html2canvas';

import { makeMetricReport, makePickingReport, makeDistanceReport,
         makeActivityPercentReport, makeActivityTimeReport,
         makeZonePercentReport, makeZoneTimeReport,
         makeLpsActivityPercentReport, makeLpsActivityTimeReport,
         makeLpsZonetimePercentReport, makeLpsZonetimeTimeReport,
         makeLpsZonedistanceReport, /*makeLpsMetricReport,*/ makeLpsMetricReportNew,
         makeZoneDistanceReport
       } from './ReportGenerator';

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

function makeFileName(from, to, chart_type, type) {
  let start = new Date(from)
  let end = new Date(to)

  let start_hour = start.getHours() < 10 ? "0" + start.getHours() : start.getHours()
  let start_min = start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes()
  let end_hour = end.getHours() < 10 ? "0" + end.getHours() : end.getHours()
  let end_min = end.getMinutes() < 10 ? "0" +  end.getMinutes() : end.getMinutes()

  let str = chart_type.toUpperCase() + "_" + monthNames[start.getMonth()] + start.getDate() + "_" + start_hour + start_min + "_" +
            monthNames[end.getMonth()] + end.getDate() + "_" + end_hour + end_min + "." + type;
  return (str)
}

function makeTextFile (text, link) {
   var textFile;
   var data = new Blob([text], {type: 'text/plain'});
   if (link !== null) {
     window.URL.revokeObjectURL(link);
   }

   textFile = window.URL.createObjectURL(data);
   return textFile;
 };

class DownloadChart extends React.Component {
  constructor() {
    super();

    this.state = {
      file_type: "csv", //csv,png
      file_name: "",
      message: "",
      file_content: "",
      modalIsOpen: false,
      link: null,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateFileName = this.updateFileName.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.downloadImgFile = this.downloadImgFile.bind(this);
    this.setType = this.setType.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.downloadFile !== this.props.downloadFile && this.props.downloadFile === true) {
      this.openModal();
    }
  }

  openModal() {
    this.setState({modalIsOpen: true, file_name: makeFileName(this.props.from, this.props.to, this.props.chart_type, this.state.file_type), file_content: "", message: "", link: null});
  }

  closeModal() {
    this.setState({modalIsOpen: false, file_type: "csv", file_name: "", file_content: "", message: "", link: null});
    this.props.closeDownload();
  }

  updateFileName(e) {
    this.setState({file_name: e.target.value});
  }

  setType(e) {
    this.setState({link: null, message: "", file_type: e.target.dataset.type, file_name: makeFileName(this.props.from, this.props.to, this.props.chart_type, e.target.dataset.type)});
  }

  downloadImgFile(e) {
    this.setState({message: "Creating graph..."});
    const input = document.getElementById(this.props.chart_type);
    //console.log(input);

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        //console.log(imgData);

        setTimeout(function(){
             this.setState({message: "", link: imgData});
        }.bind(this), 1000);
      });
  }

  downloadFile(e) {
    if (this.state.file_name.trim().length === 0) {
      this.setState({message: "Empty filename"});
      return;
    }

    if (this.props.sorted_nodes.length === 0) {
      this.setState({message: "No workers selected"})
      return;
    }

    this.setState({message: "Creating file..."});

    let content = "";
    console.log(this.props.chart_type + " - " + this.props.type);
    if (this.props.chart_type === 'metric') {
      content = makeMetricReport(this.props.from, this.props.to, this.props.sorted_nodes, this.props.asset_stats, this.props.worker_hash);
    }

    if (this.props.chart_type === 'activity') {
      if (this.props.type === 'percent') {
        content = makeActivityPercentReport(this.props.from, this.props.to, this.props.duration, this.props.sorted_nodes, this.props.asset_stats, this.props.worker_hash);
      } else {
        content = makeActivityTimeReport(this.props.sorted_nodes, this.props.asset_stats, this.props.worker_hash);
      }
    }

    if (this.props.chart_type === 'zone') {
      if (this.props.type === 'percent') {
        content = makeZonePercentReport(this.props.from, this.props.to, this.props.duration, this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats, this.props.worker_hash);
      } else {
        content = makeZoneTimeReport(this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats, this.props.worker_hash);
      }
    }

    if (this.props.chart_type === 'distance') {
      content = makeDistanceReport(this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats, this.props.worker_hash);
    }

    if (this.props.chart_type === 'picking') {
      content = makePickingReport(this.props.rack_stats, this.props.origin, this.props.racks, this.props.coordinates);
    }

    if (this.props.chart_type === 'lps_activity') {
      if (this.props.type === 'percent') {
        content = makeLpsActivityPercentReport(this.props.sorted_nodes, this.props.asset_stats);
      } else {
        content = makeLpsActivityTimeReport(this.props.from, this.props.to, this.props.sorted_nodes, this.props.asset_stats);
      }
    }

    if (this.props.chart_type === 'lps_zonetime') {
      if (this.props.type === 'percent') {
        content = makeLpsZonetimePercentReport(this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats);
      } else {
        content = makeLpsZonetimeTimeReport(this.props.from, this.props.to, this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats);
      }
    }

    if (this.props.chart_type === 'lps_zonedistance') {
      content = makeLpsZonedistanceReport(this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats);
    }

    if (this.props.chart_type === 'lps_metric') {
      //content = makeLpsMetricReport(this.props.from, this.props.to, this.props.sorted_nodes, this.props.asset_stats);
      content = makeLpsMetricReportNew(this.props.duration, this.props.sorted_nodes, this.props.asset_stats, this.props.worker_hash);
    }

    if (this.props.chart_type === 'distance_by_zone') {
      console.log("I am here")
      //content = makeLpsZonedistanceReport(this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats);
      content = makeZoneDistanceReport(this.props.sorted_nodes, this.props.zone_config, this.props.asset_stats);
    }

    this.setState({file_content: content}, () => {
      let link = makeTextFile(this.state.file_content, this.state.link);
      this.setState({message: "", link: link});
    });
  }

  render() {
    return (
      <div className="ChartDownload">
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Download Chart Data"
          className="ChartDownloadModal"
        >
        <img className="xImg" alt="close" src={require("../img/x.png")} onClick={this.closeModal}/>
        <div className="UploadContainer" >
          <div>Download {this.props.chart_type.toUpperCase()} Data</div>
          <div>
            <div className="FileType"><img alt="csv" src={this.state.file_type === 'csv'? require("../img/csv_active.png"): require("../img/csv_inactive.png")} data-type="csv" onClick={this.setType}/></div>
            <div className="SelectType" style={ this.state.file_type === 'csv' ? {color: "#404040"} :{color: "#979797"}}>DATA</div>
            {
              this.props.expand &&
              <div className="FileType"><img alt="pdf" src={this.state.file_type === "png" ? require("../img/pdf_active.png"): require("../img/pdf_inactive.png")} data-type="png" onClick={this.setType}/></div>
            }
            { this.props.expand &&
              <div className="SelectType">GRAPH</div>
            }
          </div>

          <input type="text" size="40" placeholder={this.state.file_name} name="Choose File" onChange={this.updateFileName} />
          <div>
            <div className="DownloadButton" style={{marginTop: "25px"}}>
              <div className="UploadText" onClick={this.state.file_type === 'csv' ? this.downloadFile : this.downloadImgFile}>CREATE</div>
            </div>
            <div className="LoadingStatus">{this.state.message}</div>
            <div className="LoadingStatus" style={{display: this.state.link ? "inline-block" : "none"}}><a href={this.state.link} download={this.state.file_name}>Download File</a></div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DownloadChart;

//<img alt="download" src={IMG_CONFIG['download']['active']['img']} onClick={this.openModal}/>
/*let body = {from: this.props.from, to: this.props.to};
  if (new Date().getTimezoneOffset()/60 === -9) { //check for JST timezone
  console.log("JST timezone");
  body = Object.assign({}, body, {time_zone: 'JST'});
}*/

/*downloadWmsFile(this.props.authToken, this.props.currentSiteId, body)
  .then(res => res.text())
  .then(text => {this.setState({file_content: text})})
  .then(() => {
    let link = makeTextFile(this.state.file_content, this.state.link);
    this.setState({message: "", link: link});
  })

  /*.then((canvas) => {
    const imgData = canvas.toDataURL('image/png');

    //const pdf = new jsPDF("landscape", 'in', 'letter');
    const pdf = new jsPDF("l", 'mm', 'a4');
    var width = document.getElementById(this.props.chart_type).style.width;
    var height = document.getElementById(this.props.chart_type).style.height;
    pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
    // pdf.output('dataurlnewwindow');
    let file_name = this.state.file_name;
    this.setState({message: "Creating PDF..."});
    setTimeout(function(){
      pdf.save(file_name);
    }, 10000, file_name);
    //pdf.save(this.state.file_name);
  });
*/
