/* vim: set fileencoding=utf-8 */

import React from 'react';

/**
 * Renders a <time> tag, containing a local-formatted time, for a timestamp.
 */
export class Timestamp extends React.Component {
    render() {
        let date = new Date(this.props.millis || (this.props.seconds * 1000) ||
                0);
        return <time key="time" dateTime={ date.toISOString() }>{
            date.toLocaleString()
            }</time>;
    }
}


