import React from 'react';
//import Legend from './Legend';
//import {HorizontalBar} from 'react-chartjs-2';
//import ZoneTimeBarChart from './ZoneTimeBarChartNew';
import TimeIntervalChart from './TimeIntervalChart';
import {makeDistanceXAxis} from './ChartUtil';
import {IMG_CONFIG} from '../2dConfig';

const METERS_PER_FOOT = 0.3048;
/*const options = {
  'distance': {
    tooltips: {
          mode: 'nearest'
    },
    animation: false,
    legend: {
         display: false,
     },
    scales: {
      yAxes: [{
        stacked: true,
      }],
      xAxes: [{
        stacked: true,
        ticks: {
          min: 0,
          callback: function(value) {
            return value + " M"
          }
        }
      }]
    }
  }
}*/

class ZoneDistance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_legend: false,
      labels: [],
      data: [], //chartjs
      x_axis: [], //sorted distance
      sorted_data: [], //sorted distance
    };

    this.setLegend= this.setLegend.bind(this);
  }

  componentDidMount() {
      //this.makeData();
      this.makeSortedData();
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.zone_config).length !== Object.keys(this.props.zone_config).length ||
        prevProps.sorted_nodes.length !== this.props.sorted_nodes.length ||
        Object.keys(prevProps.tracker_stats).length !== Object.keys(this.props.tracker_stats).length ||
        prevProps.from !== this.props.from || prevProps.to !== this.props.to ||
        prevProps.expand !== this.props.expand)
    {
      //this.makeData();
      this.makeSortedData();
    }
  }

  componentWillUnmount() {
    //console.log("unmounted")
  }

  makeData() { //chartjs
    let labels = this.props.sorted_nodes.map(n => n.name);
    let data = [];

    Object.keys(this.props.zone_config).forEach(z => {
      let zoneData = {label: "", data: [], backgroundColor: ""};

      zoneData['label'] = this.props.zone_config[z].name;
      zoneData['backgroundColor'] = "#" + this.props.zone_config[z].color;

      let array = []; //make data array [tracker1,tracker2, etc. ]
      this.props.sorted_nodes.forEach(n => {
          if (this.props.tracker_stats.hasOwnProperty(n.id) && this.props.tracker_stats[n.id].hasOwnProperty('zone_stats')) {
            let zone_stats = this.props.tracker_stats[n.id].zone_stats;
            if (zone_stats.hasOwnProperty(z)) {
              array.push((zone_stats[z].distance * METERS_PER_FOOT).toFixed(2));
            } else {
              array.push(0);
            }
          }
        }
      )
        zoneData['data'] = array;
        data.push(zoneData);
      }
    ) //end object keys
    this.setState({labels: labels, data: data}, /*()=>console.log(data)*/);
  }

  makeSortedData() {
    let labels = this.props.sorted_nodes.map(n => n.name);
    let all_data = [];
    let max = 0;

    this.props.sorted_nodes.forEach(n => {
      let data = [];
      if (this.props.tracker_stats.hasOwnProperty(n.id) && this.props.tracker_stats[n.id].hasOwnProperty('zone_stats')) {
        let zone_stats = this.props.tracker_stats[n.id].zone_stats;
        let total_distance = 0;
        for (const z in zone_stats) {
          let props = {}
          let distance = (zone_stats[z].distance * METERS_PER_FOOT).toFixed(2);
          let name = this.props.zone_config !== undefined && this.props.zone_config.hasOwnProperty(z) ? this.props.zone_config[z].name : "";
          let color = this.props.zone_config !== undefined && this.props.zone_config.hasOwnProperty(z) ? "#" + this.props.zone_config[z].color : "";
          props[name] = distance;
          let interval = {start: 0, duration: 0, distance: distance, color: color, tooltip: {id: n.name, props: props}};

          total_distance += parseInt(distance, 10);
          data.push(interval);
        }

        if (max < total_distance) { max = total_distance; }
        all_data.push(data.sort((a, b)=>(parseFloat(b.distance) - parseFloat(a.distance))));
      }
    });

    max = max + 20;
    for (let node of all_data) {
      let start = 0;
      for (let zone of node) {
          let distance = parseFloat(zone.distance) / max;
          zone.start = start;
          zone.duration = distance;
          start += distance;
      }
    }

    this.setState({labels: labels, x_axis: makeDistanceXAxis(max), sorted_data: all_data});
  }

  setLegend(e) {
    this.setState((prevState, props) => ({show_legend: !prevState.show_legend}))
  }

  render() {
    return (
      <div className="SiteChart">
      {this.props.expand ?
        <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
        <img className="ExpandIcon" alt="expand" data-chart="zone_distance" src={require("../img/expand.png")} onClick={this.props.setChartType} />
      }
        <div className="SiteHeader">DISTANCE TRAVELLED PER ZONE
          {
            this.props.displayDownload &&
            <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="lps_zonedistance" data-type={this.state.type} onClick={this.props.setDownloadType}/>
          }
        </div>
        <div id="lps_zonedistance" className="SiteChartContainer">
        <TimeIntervalChart labels={this.state.labels} y_axis={this.state.x_axis} data={this.state.sorted_data}/>
        </div>
      </div>
    )
  }
}

export default ZoneDistance;

/*
<HorizontalBar
    key={this.state.labels.length}
    width={250}
    height={this.state.labels.length > 6 ? 250 : 50}
    data={{labels: this.state.labels, datasets: this.state.data}}
    options={options["distance"]} />

    <div style={{display: this.state.show_legend ? "" : "none"}}>
      <Legend zone_src={this.props.zone_src} zone_config={this.props.zone_config}/>
    </div>

    {
      this.state.show_legend ?
      <div>
        <img className="LegendOptionsImg" alt="hide_legend" src={require("../img/hide_legend.png")} onClick={this.setLegend}/>
        <div className="LegendOptionsText">HIDE LEGEND</div>
      </div>
      :
      <div>
        <img className="LegendOptionsImg" alt="show_legend" src={require("../img/show_legend.png")} onClick={this.setLegend}/>
        <div className="LegendOptionsText">SHOW LEGEND</div>
      </div>
    }
*/
