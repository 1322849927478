import React from 'react';
import { Table, Column, ColumnGroup, Cell} from 'fixed-data-table-2';
import { formatTime, addCommas, convertSiteTime, convertSiteTimeMinSec } from '../charts/ChartUtil';
import Sort from '../../common/Sort';
import Select from 'react-select';

import { IMG_CONFIG, HEADER_CONFIG } from '../2dConfig';
import { Tooltip } from 'react-tippy';

const METERS_PER_FOOT = 0.3048;
const QUERY_LIMIT=86400000; //24 hours
const HOUR_LIMIT=3600000; //1 hour

const tableOptions = [
  {value: 'Picking Time', label: 'Picking Time'},
  {value: 'Working Hours', label: 'Working Hours'},
  {value: 'Items Picked', label: 'Items Picked'},
  {value: 'Locations Visited', label: 'Locations Visited'},
  {value: 'Location/Hr', label: 'Location/Hr'}, //picking hours
  {value: 'Time Per Visit', label: 'Time Per Visit'},
  {value: 'Picking Lines', label: 'Picking Lines'},
  {value: 'Time/Line', label: 'Time/Line'},
  {value: 'Distance', label: 'Distance'},
  {value: 'Active Time', label: 'Active Time'},
  {value: 'Activity', label: 'Activity'},
  {value: 'Tracker Check Out', label: 'Tracker Check Out'},
  {value: 'Tracker Check In', label: 'Tracker Check In'},
  {value: 'Tracker Working Hours', label: 'Tracker Working Hours'},
  {value: 'Distance/Pick', label: 'Distance/Pick'},
];

const defaultTableOptions = [
  {value: 'Picking Time', label: 'Picking Time'},
  {value: 'Items Picked', label: 'Items Picked'},
  {value: 'Time Per Visit', label: 'Time Per Visit'},
  {value: 'Time/Line', label: 'Time/Line'},
  {value: 'Distance', label: 'Distance'},
  {value: 'Active Time', label: 'Active Time'},
  {value: 'Activity', label: 'Activity'},
  {value: 'Tracker Working Hours', label: 'Tracker Working Hours'},
];

const customStyles = {
  option: (provided, state) => ({
   ...provided,
   color: "#2d2d2d",
   width: "32rem",
   fontSize: "0.75rem",
   padding: 8,
  }),
  menu: (base) =>({
    ...base,
    width: "32.8rem",
    marginLeft: "45%",
    marginTop: "6%",
    border: "solid 1px #1b5faa",
    borderRadius: "0",
  }),
  control: base => ({
    ...base,
    float: "right",
    marginBottom: ".8rem",
    //width: "32.5rem",
    width: "55%",
    border: "solid 1px #1b5faa",
    borderRadius: "0",
    fontSize: "0.875rem",
    color: "#2d2d2d",
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#1b5faa",
  }),
  clearIndicator: base => ({
    ...base,
    color: "#1b5faa",
  })
};


//rows[props.rowIndex]
class WmsMetric extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      sortedColumnIndex: 0,
      tableColumns: defaultTableOptions.map(opt => opt.value),
    };

    this.setSortedData = this.setSortedData.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.makeTableData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.sorted_nodes) !== JSON.stringify(this.props.sorted_nodes) ||
        JSON.stringify(prevProps.asset_stats) !== JSON.stringify(this.props.asset_stats) ||
        prevProps.from !== this.props.from || prevProps.to !== this.props.to ||
        prevProps.expand !== this.props.expand)
    {
      //console.log(this.props.asset_stats)
      this.makeTableData();
    }
  }

/*
"9004035342": {
            "name": "9004035342",
            "type": "picker",
            "distance": 32417.401446106127,
            "active_time": 10666531,
            "picking_time": 6737000,
            "working_time": 28773000,
            "picked_items": 734,

"9004035538":{
"name":"9004035538",
"type":"picker",
"picking_time":0,
"working_time":0,
"picked_items":0,
"picking_assignments":0,
"picking_lines":0
*/
//tracker, distance, items picked, picking time, working hours, activetime, activity %
  makeTableData() {
    //console.log("make metric table")
    let data = [];
    this.props.sorted_nodes.forEach(n => {
      let d = { tracker: null,
                picking_time:null,
                working_time: null,
                items_picked: null,
                picking_assignments: null,
                loc_per_hour: null, //picking hours
                time_per_visit: null,
                picking_lines: null,
                time_per_line: null,
                distance: null,
                active_time: null,
                activity: null,
                tracker_check_out: null,
                tracker_check_in: null,
                tracker_working_hours: null,
                distance_per_pick: null,
                distance_per_loc: null,
              };

      d['tracker'] = this.props.worker_hash[n].name;
      if (this.props.asset_stats && this.props.asset_stats.hasOwnProperty(n) && this.props.asset_stats[n] !== undefined) {
        let stats = this.props.asset_stats[n];
        d['picking_time'] = stats.picking_time;
        d['working_time'] = stats.working_time;
        d['items_picked'] = stats.picked_items;
        d['picking_assignments'] = stats.picking_assignments;
        d['loc_per_hour'] = stats.picking_assignments && (stats.picking_time >= HOUR_LIMIT) && parseInt(stats.picking_assignments/(stats.picking_time/HOUR_LIMIT));
        d['time_per_visit'] = stats.picking_assignments &&(stats.picking_time / stats.picking_assignments).toFixed(2);
        d['distance'] = stats.distance && (stats.distance * METERS_PER_FOOT).toFixed(2);
        d['active_time'] = stats.active_time;
        d['activity'] = stats.active_time && stats.attached_time && (stats.active_time / stats.attached_time * 100).toFixed(2);
        d['picking_lines'] = stats.picking_lines;
        d['time_per_line'] = stats.picking_lines && (stats.working_time / stats.picking_lines).toFixed(2);
        d['tracker_working_hours'] = stats.attached_time;
        d['distance_per_pick'] = stats.distance && stats.picked_items &&  ((stats.distance * METERS_PER_FOOT) / stats.picked_items).toFixed(2);

        if (stats.attachment_intervals && stats.attachment_intervals.length > 0) {
          let start = new Date().getTime();
          let end = 0;
          stats.attachment_intervals.forEach(a => {
            if (a.start < start) {
              start = a.start;
            }

            if ((a.start + a.duration) > end) {
              end = a.start + a.duration;
            }
          })
          d['tracker_check_out'] = start;
          //d['tracker_check_in'] = end;
          d['tracker_check_in'] = end >= (this.props.to - 5000) ? null: end;
        }
      }

      //console.log(JSON.stringify(d.tracker_check_in))
      data.push(d);
    })
    this.setState({tableData: data});
  }

  setSortedData(columnIndex, sortedData) {
    //console.log(columnIndex + " - " + JSON.stringify(sortedData));
    this.setState({sortedColumnIndex: columnIndex, tableData: sortedData});
  }

  handleOnChange(e) {
    if (e) {
      this.setState({tableColumns: e.map(n => n.value)});
    } else {
      this.setState({tableColumns: []});
    }
  }

  render() {
    return (
      <div className="SiteChart">
      {this.props.expand ?
        <img className="ExpandIcon" alt="Close" src={require("../img/close.png")} onClick={this.props.setClose} /> :
        <img className="ExpandIcon" alt="expand" data-chart="metric" src={require("../img/expand.png")} onClick={this.props.setChartType} />
      }
        <div className="SiteHeader">METRICS
        { this.props.displayDownload &&
          <img alt="download" src={IMG_CONFIG['download']['active']['img']} data-charttype="metric" data-type="" onClick={this.props.setDownloadType}/>
        }
        </div>
        <div id="metric" className="SiteChartContainer">
        <Select
          isMulti
          defaultValue={defaultTableOptions}
          options={tableOptions}
          styles={customStyles}
          onChange={this.handleOnChange}
        />
          <Table className="MetricTable"
            groupHeaderHeight={30}
            rowHeight={35}
            headerHeight={52}
            rowsCount={Object.keys(this.props.sorted_nodes).length}
            width={995}
            height={Object.keys(this.props.sorted_nodes).length * 35 + 95}
          >
            <ColumnGroup header={<Cell className="ColumnGroup1"></Cell>}>
            <Column columnKey='tracker'
                    header={<Cell className="MetricHeader">TRACKER <Sort thisColumnIndex={0} thisDataType={"string"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].tracker ? this.state.tableData[props.rowIndex].tracker : "-"}
                      </Cell>
                    )}
                    width={106}
            />
            </ColumnGroup>
            <ColumnGroup header={<Cell className="ColumnGroup2">WMS</Cell>}>
            <Column columnKey='picking_time'
                    header={<Cell className="MetricHeader"><Tooltip title={HEADER_CONFIG['picking_time']['tooltip']} theme="transparent">PICKING TIME</Tooltip><Sort thisColumnIndex={1} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].picking_time ? convertSiteTime(this.state.tableData[props.rowIndex].picking_time) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Picking Time') ? 106: 0}
            />
            <Column columnKey='working_hours'
                    header={<Cell className="MetricHeader"><Tooltip title={HEADER_CONFIG['working_hours']['tooltip']} theme="transparent">WORKING HOURS</Tooltip><Sort thisColumnIndex={2} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].working_time ? convertSiteTime(this.state.tableData[props.rowIndex].working_time) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Working Hours') ? 145 : 0}
            />
            <Column columnKey='items_picked'
                    header={<Cell className="MetricHeader"><Tooltip title={HEADER_CONFIG['items_picked']['tooltip']} theme="transparent">ITEMS PICKED</Tooltip><Sort thisColumnIndex={3} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].items_picked ? this.state.tableData[props.rowIndex].items_picked : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Items Picked') ? 106 : 0}
            />
            <Column columnKey='visit_count'
                    header={<Cell className="MetricHeader"><Tooltip title={HEADER_CONFIG['locations_visited']['tooltip']} theme="transparent">LOCATIONS VISITED</Tooltip><Sort thisColumnIndex={4} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].picking_assignments ? this.state.tableData[props.rowIndex].picking_assignments : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Locations Visited') ? 106 : 0}
            />
            <Column columnKey='loc_per_hour'
                    header={<Cell className="MetricHeader">LOCATION / PICKING HR<Sort thisColumnIndex={14} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].loc_per_hour ? this.state.tableData[props.rowIndex].loc_per_hour : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Location/Hr') ? 106 : 0}
            />
            <Column columnKey='time_per_visit'
                    header={<Cell className="MetricHeader"><Tooltip title={HEADER_CONFIG['time_per_visit']['tooltip']} theme="transparent">TIME PER VISIT</Tooltip><Sort thisColumnIndex={5} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].time_per_visit ? convertSiteTime(this.state.tableData[props.rowIndex].time_per_visit) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Time Per Visit') ? 106 : 0}
            />
            <Column columnKey='picking_lines'
                    header={<Cell className="MetricHeader">PICKING LINES<Sort thisColumnIndex={6} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].picking_lines ? this.state.tableData[props.rowIndex].picking_lines : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Picking Lines') ? 106 : 0}
            />
            <Column columnKey='time_per_line'
                    header={<Cell className="MetricHeader">TIME/LINE<Sort thisColumnIndex={7} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].time_per_line ? convertSiteTimeMinSec(this.state.tableData[props.rowIndex].time_per_line) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Time/Line') ? 106 : 0}
            />
            </ColumnGroup>
            <ColumnGroup header={<Cell className="ColumnGroup3">LPS</Cell>}>
            <Column columnKey='distance'
                    header={<Cell className="MetricHeader">DISTANCE (M)<Sort thisColumnIndex={8} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell3" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].distance ? addCommas(this.state.tableData[props.rowIndex].distance) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Distance') ? 106 : 0}
            />
            <Column columnKey='active_time'
                    header={<Cell className="MetricHeader">ACTIVE TIME<Sort thisColumnIndex={9} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell3" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].active_time? convertSiteTime(this.state.tableData[props.rowIndex].active_time) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Active Time') ? 106 : 0 }
            />
            <Column columnKey='activity'
                    header={<Cell className="MetricHeader">ACTIVITY (%)<Sort thisColumnIndex={10} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell3" {...props}>
                        {this.state.tableData[props.rowIndex]  && this.state.tableData[props.rowIndex].activity? this.state.tableData[props.rowIndex].activity : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Activity') ? 106 : 0}
            />
            <Column columnKey='tracker_check_out'
                    header={<Cell className="MetricHeader">TRACKER CHECK OUT<Sort thisColumnIndex={11} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell3" {...props}>
                        {this.state.tableData[props.rowIndex]  && this.state.tableData[props.rowIndex].tracker_check_out? formatTime(this.state.tableData[props.rowIndex].tracker_check_out) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Tracker Check Out') && this.props.to - this.props.from < QUERY_LIMIT ? 106 : 0}
            />
            <Column columnKey='tracker_check_in'
                    header={<Cell className="MetricHeader">TRACKER CHECK IN<Sort thisColumnIndex={12} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell3" {...props}>
                        {this.state.tableData[props.rowIndex]  && this.state.tableData[props.rowIndex].tracker_check_in? formatTime(this.state.tableData[props.rowIndex].tracker_check_in): "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Tracker Check In') && this.props.to - this.props.from < QUERY_LIMIT ? 106 : 0 }
            />
            <Column columnKey='tracker_working_hours'
                    header={<Cell className="MetricHeader">TRACKER WORKING HOURS<Sort thisColumnIndex={13} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell3" {...props}>
                        {(this.state.tableData[props.rowIndex]  && this.state.tableData[props.rowIndex].tracker_working_hours) ? convertSiteTime(this.state.tableData[props.rowIndex].tracker_working_hours) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Tracker Working Hours') ? 106 : 0}
            />
            </ColumnGroup>
            <ColumnGroup header={<Cell className="ColumnGroup2">LPS/WMS</Cell>}>
            <Column columnKey='distance_per_pick'
                    header={<Cell className="MetricHeader">DISTANCE / PICK (M)<Sort thisColumnIndex={14} thisDataType={"float"} columnIndexToSort={this.state.sortedColumnIndex} setSortedData={this.setSortedData} data={this.state.tableData} /></Cell>}
                    cell={ props => (
                      <Cell className="MetricCell2" {...props}>
                        {this.state.tableData[props.rowIndex] && this.state.tableData[props.rowIndex].distance_per_pick ? addCommas(this.state.tableData[props.rowIndex].distance_per_pick) : "-"}
                      </Cell>
                    )}
                    width={this.state.tableColumns.includes('Distance/Pick') ? 106 : 0}
            />
            </ColumnGroup>
          </Table>
        </div>
      </div>
    )
  }
}

export default WmsMetric;
