import React from 'react';

import { Bar} from 'react-chartjs-2';
import {FormattedMessage} from 'react-intl';

const options = {
  responsive: true,
  tooltips: {
        mode: 'nearest'
  },
  legend: {
       display: false,
   },
  animation: false,
  scales: {
    barThickness: 1,
    yAxes: [{
      stacked: true,
      ticks: {
        callback: function(value) {
          return value + " m"
        }
      }
   }],
    xAxes: [{
      stacked: true,
    }]
  }
}

class MscsFlChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    return (
    <div className="mscsChart">
    <FormattedMessage id="mscs.flchart" defaultMessage="Forklift Mileage (m)"/>
    <div className="container">
        <Bar
            data={{
                  labels: this.props.labels,
                  datasets: this.props.datasets,
                  }}
            options={options}
          />
      </div>
    </div>
    )
  }
}

export default MscsFlChart;
