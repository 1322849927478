import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { editName } from '../../modules/sites';
import { VERSION } from '../../DashboardVersion.js';

const SiteSettings = ({ currentSite, onChange }) => (
  <div className="main">
    <h3>GENERAL</h3>
    <div className="line" style={{marginTop:"25px"}}/>
    <div>
      <label htmlFor="name">SITE NAME</label>
      <input
        type="text"
        id="name"
        value={currentSite.site_name}
        onChange={onChange}
        disabled
      />
      </div>
      <div style={{marginTop: "10px"}}>
      <label htmlFor="id">SYSTEM ID</label>
    	<input type="text"
    	 id="system id"
    	 value={currentSite.site}
    	 onChange={onChange}
    	 disabled
        />
    </div>
    <div style={{marginTop: "10px"}}>
    <label htmlFor="id">DASHBOARD VERSION</label>
    <input type="text"
     id="version id"
     value={VERSION}
     disabled
      />
  </div>
  </div>
);

SiteSettings.propTypes = {
  currentSite: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    pending: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentSite: state.sites.currentSite,
});
const mapDispatchToProps = dispatch => ({
  onChange: e => dispatch(editName(e.target.value)),
});
const SiteSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SiteSettings);

export default SiteSettingsContainer;
