import React from 'react';
import Modal from 'react-modal';
import { IMG_CONFIG } from '../2dConfig';

const map = {
  'quantity' : 'QUANTITY',
  'percent' : 'PERCENTAGE OF MAX',
  'percentile': 'PERCENTILE',
}

class EditRackCriteria extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false,
      percent_or_quantity: "percent", //quantity, percentile
      rack_criteria: {
        0: {from: 0, to: 0, color: "blue"},
        1: {from: 0, to: 0, color: "green"},
        2: {from: 0, to: 0, color: "yellow"},
        3: {from: 0, to: 0, color: "red"}
      },
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateCriteria = this.updateCriteria.bind(this);
  }

  componentDidMount() {
    this.setState({percent_or_quantity: this.props.percent_or_quantity,
                   rack_criteria: {...this.props.rack_criteria},
                  });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.openModal !== this.props.openModal) {
        this.setState({modalIsOpen: this.props.openModal})
    }

    if (prevProps.percent_or_quantity !== this.props.percent_or_quantity) {
      this.setState({percent_or_quantity: this.props.percent_or_quantity})
    }

    if (JSON.stringify(prevProps.rack_criteria) !== JSON.stringify(this.props.rack_criteria)) {
      this.setState({rack_criteria: {...this.props.rack_criteria}});
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  updateCriteria(e) {
    e.target.value = e.target.value.replace(/[^0-9]+/, '');
    if (e.target.value==='') { return; }
    let temp = e.target.dataset.source.split("-");
    let criteria = {...this.state.rack_criteria};
    criteria[temp[0]][temp[1]] = parseInt(e.target.value, 10);
    this.setState({rack_criteria: criteria});
  }


  render() {
    return (
      <div style={{display: "inline-block"}}>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          contentLabel="Edit Rack Criteria"
          className="EditCriteria"
        >
        <img className="xImg" alt="close" src={require("../img/x.png")} onClick={this.closeModal}/>
        <div className="UploadContainer">
            <div style={{display: "inline-block"}}>RACK HEATMAP</div>
            <img className="RackModalIcon" alt="percentile"
              src={this.state.percent_or_quantity === 'percentile' ? IMG_CONFIG['legend_percentile'].active.img : IMG_CONFIG['legend_percentile'].inactive.img }
              onClick={()=>{this.setState({percent_or_quantity: "percentile"})}}
            />
            <img className="RackModalIcon" alt="percent"
              src={this.state.percent_or_quantity === 'percent' ? IMG_CONFIG['legend_percent'].active.img : IMG_CONFIG['legend_percent'].inactive.img }
              onClick={()=>{this.setState({percent_or_quantity: "percent"})}}
            />
            <img className="RackModalIcon" alt="quantity"
              src={this.state.percent_or_quantity === 'quantity' ? IMG_CONFIG['legend_quantity'].active.img : IMG_CONFIG['legend_quantity'].inactive.img }
              onClick={()=>{this.setState({percent_or_quantity: "quantity"})}}
            />
            <div className="CriteriaType">{map[this.state.percent_or_quantity]}</div>
            <div style={{marginTop: '10px'}}>
              <input style={{marginLeft: "60px", outline:"none"}} type="text" size="3" data-source="0-to" value={this.state.rack_criteria["0"].to} onChange={this.updateCriteria}/>
              <input style={{marginLeft: "45px", outline:"none"}} type="text" size="3" data-source="1-to" value={this.state.rack_criteria["1"].to} onChange={this.updateCriteria}/>
              <input style={{marginLeft: "45px", outline:"none"}} type="text" size="3" data-source="2-to" value={this.state.rack_criteria["2"].to} onChange={this.updateCriteria}/>
              <img className="Legend" alt="legend" src={require('../img/rack_gradient.png')}/>
            </div>
            <div className="ok" onClick={()=>{this.props.setCriteria(this.state.percent_or_quantity, this.state.rack_criteria); this.closeModal()}}>OK</div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EditRackCriteria;
