import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { getNodesHash, updateNode, fetchNode, fetchNodes } from '../../modules/nodes';
import { checkACL } from '../acl/Config';
import { isNumber, isEmpty } from '../settings/form/ErrorChecking';

import HeaderContainer from '../settings/form/HeaderContainer';
import InfoContainer from '../settings/form/InfoContainer';
import FormContainer from '../settings/form/FormContainer';
import LabelContainer from '../settings/form/LabelContainer';

const URL = "/dashboard/settings/devices/anchor_config";
const ANCHOR_PROPS = {
  anchor: "", hardware_id: "", nid: "", channel: "", mcs: "", nss: "", obw: "", payload_length: "", tx_power: "", mode: "", npkt: "",
  anch_alarm: "", anch_heartbeat: "", burst_timeout: "", loc_min_anch: "", loc_max_anch: "", addr: "", name: "", validate: true};

const EditAnchorConfig = (props) => {
  const [id, setId] = useState(null);
  const [anchor, setAnchor] = useState(null);
  const [config, setConfig] = useState(ANCHOR_PROPS);
  const [error, setError] = useState(ANCHOR_PROPS);
  const [disable_save, setDisable_save] = useState(false);

  useEffect(()=> {
    if (props.match.params.id === "undefined" ) { return; }
    if (props.match.params.id === id) { return; }

    console.log("useEffect");
    resetForm(props.nodesHash[props.match.params.id])
  });

  const resetForm = (anchor) => {
    if (anchor) {
      console.log("set editForm " + anchor.hardware_id);
      setId(props.match.params.id);
      setAnchor(anchor);
      setConfig({
        anchor:  anchor.name,
        hardware_id: anchor.hardware_id,
        nid: anchor.configs.anchor.nid,
        channel: anchor.configs.anchor.channel,
        mcs: anchor.configs.anchor.mcs,
        nss: anchor.configs.anchor.nss,
        obw: anchor.configs.anchor.obw,
        payload_length: anchor.configs.anchor.payload_length,
        tx_power: anchor.configs.anchor.tx_power,
        mode: anchor.configs.anchor.mode,
        npkt: anchor.configs.anchor.npkt,
        anch_alarm: anchor.configs.anchor.anch_alarm,
        anch_heartbeat: anchor.configs.anchor.anch_heartbeat,
        burst_timeout: anchor.configs.anchor.anch_heartbeat,
        loc_min_anch: anchor.configs.anchor.loc_min_anch,
        loc_max_anch: anchor.configs.anchor.loc_max_anch,
        addr: anchor.configs.anchor.addr,
        name: anchor.configs.anchor.name,
      });
    }
    setError(ANCHOR_PROPS);
  }

  const validateFields = () => {
    let errorNew = {...ANCHOR_PROPS};
    isNumber("nid", config.nid, errorNew);
    isNumber("channel", config.channel, errorNew);
    isNumber("mcs", config.mcs, errorNew);
    isNumber("nss", config.nss, errorNew);
    isNumber("obw", config.obw, errorNew);
    isNumber("payload_length", config.payload_length, errorNew);
    isNumber("tx_power", config.tx_power, errorNew);
    isNumber("npkt", config.npkt, errorNew);
    isNumber("anch_alarm", config.anch_alarm, errorNew);
    isNumber("anch_heartbeat", config.anch_heartbeat, errorNew);
    isNumber("burst_timeout", config.burst_timeout, errorNew);
    isNumber("loc_min_anch", config.loc_min_anch, errorNew);
    isNumber("loc_max_anch", config.loc_max_anch, errorNew);
    isEmpty("mode", config.mode, errorNew);
    isEmpty("addr", config.addr, errorNew);
    isEmpty("name", config.name, errorNew);
    setError(errorNew);

    return errorNew.validate
  };

  const handleSubmit = (e)=> {
    if (!validateFields()) { return; }

    console.log("submit");
    setDisable_save(true);

    let node = {
      node: {
        name: config.anchor,
        configs: {
          anchor: {
            nid: config.nid,
            channel: config.channel,
            mcs: config.mcs,
            nss: config.nss,
            obw: config.obw,
            payload_length: config.payload_length,
            tx_power: config.tx_power,
            mode: config.mode,
            npkt: config.npkt,
            anch_alarm: config.anch_alarm,
            anch_heartbeat: config.anch_heartbeat,
            burst_timeout: config.burst_timeout,
            loc_min_anch: config.loc_min_anch,
            loc_max_anch: config.loc_max_anch,
            addr: config.addr,
            name: config.name
          }
        }
      }
    };

    props.dispatch(updateNode(anchor.node, node));
    setError(Object.assign({}, error, {status: "Saving anchor configs... "}))
    setTimeout(()=>props.dispatch(fetchNodes()), 500);
    setTimeout(()=>props.history.push(URL), 700);
  };

  const updateField = (field, e) => {
    setConfig(Object.assign({}, config, {[field]: e.target.value}));
  }

  const handleCalibrate = (e) => {
    let err = {...error};
    err['status'] = "Anchor - " + anchor.name +  " calibrating...";
    setError(err)

    setTimeout(() => {
      props.dispatch(fetchNode(anchor.node));
      err['status'] = "Anchor - " + anchor.name + " calibrated";
      setError(err)
    }, 10000);
  }

  const INFO1 = {
    "Device Name" : get(anchor, "name"),
    "Hardware ID(Mac Address)": get(anchor, 'hardware_id'),
    "System Id": get(anchor, 'node'),
    "Internal Mac Address":get(anchor, 'configs.anchor.addr'),
    "IP Address": get(anchor, 'events.system.metrics.network_interfaces.eth0.address'),
    "Firmware Version": get(anchor, 'events.system.versions.radio_firmware'),
  }

  const INFO2 = {
    "Calibration Timestamp": anchor && anchor.configs.lps_control && anchor.configs.lps_control.cal ? new Date(anchor.configs.lps_control.cal.timestamp).toString() : "Not Calibrated",
  }

  return(
    <div className="main edit">
      <Link to={"/dashboard/settings/devices/anchor_config"}><i className="fa fa-chevron-left fa-lg back"/></Link>
      <h3>Edit Anchor Config</h3>
      <HeaderContainer
        node = {anchor && anchor.node}
        name = {anchor && anchor.name}
        handleCalibrate = {handleCalibrate}
        type = "anchor"
      />
      <div className="status">{error && error.status}</div>
      <div className="line"/>
      <div className="edit_container">
        <div key="edit">
          <LabelContainer
            labels = {
              {
                "internal_mac" : {"help": "addr", "desc": ""},
                "nid" : {"help": "nid", "desc": ""},
                "channel" : {"help": "channel", "desc": ""},
                "mcs" : {"help": "mcs", "desc": ""},
                "nss" : {"help": "nss", "desc": ""},
                "obw" : {"help": "obw", "desc": ""},
                "payload_length" : {"help": "payload", "desc": ""},
                "tx_power" : {"help": "tx_power", "desc": ""},
                "mode" : {"help": "mode", "desc": ""},
                "npkt" : {"help": "npkt", "desc": ""},
                "anch_alarm" : {"help": "anch alarm", "desc": ""},
                "anch_heartbeat" : {"help": "anch heartbeat", "desc": ""},
                "burst_timeout" : {"help": "burst timeout", "desc": ""},
                "loc_min_anch" : {"help": "min anch needed for anchor locate", "desc": ""},
                "loc_max_anch" : {"help": "max anch needed for anchor locate", "desc": ""},
                "internal_name" : {"help": "internal tracker name", "desc": ""}
              }
          }/>
          <FormContainer
            fields = {{
                "addr" : {"value": config.addr, "placeholder": "", "error": error.addr},
                "nid" : {"value": config.nid, "placeholder": "", "error": error.nid},
                "channel" : {"value": config.channel, "placeholder": "", "error": error.channel},
                "mcs" : {"value": config.mcs, "placeholder": "", "error": error.mcs},
                "nss" : {"value": config.nss, "placeholder": "", "error": error.nss},
                "obw" : {"value": config.obw, "placeholder": "", "error": error.obw},
                "payload_length" : {"value": config.payload_length, "placeholder": "", "error": error.payload_length},
                "tx_power" : {"value": config.tx_power, "placeholder": "", "error": error.tx_power},
                "mode" : {"value": config.mode, "placeholder": "", "error": error.mode},
                "npkt" : {"value": config.npkt, "placeholder": "", "error": error.npkt},
                "anch_alarm" : {"value": config.anch_alarm, "placeholder": "", "error": error.anch_alarm},
                "anch_heartbeat" : {"value": config.anch_heartbeat, "placeholder": "", "error": error.anch_heartbeat},
                "burst_timeout" : {"value": config.burst_timeout, "placeholder": "", "error": error.burst_timeout},
                "loc_min_anch" : {"value": config.loc_min_anch, "placeholder": "", "error": error.loc_min_anch},
                "loc_max_anch" : {"value": config.loc_max_anch, "placeholder": "", "error": error.loc_max_anch},
                "name" : {"value": config.name, "placeholder": "", "error": error.name}
            }}
            updateField={updateField}
          />
          <InfoContainer
            info={checkACL(props.acl, 'Calibration Field') ? {...INFO1, ...INFO2} : INFO1}
          />
        </div>
      </div>
      <div>
        <Link to={URL}><button className="cancel">Cancel</button></Link>
        <span id="save"><button className="save" disabled={disable_save} onClick={e => handleSubmit()}>Save</button></span>
      </div>
    </div>
  );
}

export default connect (
  state => ({
      nodesHash: getNodesHash(state),
      acl: state.user.acl,
    }),
)(EditAnchorConfig);
