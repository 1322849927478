import React from 'react';
import { Stage, Layer, Circle, Rect, Image} from 'react-konva';
//import { ORIGIN, COORDINATES } from './RackConfig.js';
import { WAREHOUSE } from '../2dConfig.js';
import ToolTip from '../../common/ToolTip';

class WmsRacks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      racks: [],
      tooltip_props: {id:null, props:null, x:null, y:null},
      tooltip_show: true,
    };

    this.makeToolTip = this.makeToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
  }

  componentDidMount() {
    this.loadImages();
    this.makeRacks();
  }

  componentWillUnmount() {
    this.unloadImages();
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("../img/" + this.props.background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));
  }

  makeRacks() {
    /*let all_racks = RACKS[this.props.siteId][this.props.floor];
    Object.keys(all_racks).forEach( r => {
        console.log(r + " - " + all_racks[r] + JSON.stringify(COORDINATES[this.props.siteId][this.props.floor][all_racks[r]]));
      }
    )*/

    let origin = WAREHOUSE[this.props.siteId].maps[this.props.floor].origin; // in meters
    let coords = this.props.coordinates[this.props.floor];
    let racks = [];
    for (const r in coords) {
      let rr = coords[r];
      let newX =  (rr.x + origin.x) * this.props.pixels_per_meter;
      let newY = this.props.height - ((rr.y + origin.y) * this.props.pixels_per_meter);
      let width = rr.width * this.props.pixels_per_meter;
      let height = rr.height * this.props.pixels_per_meter;
      let color = racks.length % 2 === 0 ? "#95B2DD" : "black";
      let rack_info = {id: r, props: {x: rr.x.toFixed(2), y: rr.y.toFixed(2)}};

      racks.push(<Rect key={r} rack_info={rack_info} x={newX} y={newY} width={width} height={height} fill={color} onMouseOver={this.makeToolTip} onMouseOut={this.closeToolTip}/>);
    }

    this.setState({racks: racks}, ()=>console.log("Floor - " + this.props.floor + " Racks - " + racks.length));
  }

  closeToolTip(e) {
    this.setState({tooltip_show: false});
  }

  makeToolTip(e) {
    /*if (this.state.tooltip_show) {
      this.setState({tooltip_show: false});
      return;
    }*/

    let tooltip = {
      id: e.target.attrs.rack_info.id,
      x: this.refs['stage'].getPointerPosition().x,
      y: this.refs['stage'].getPointerPosition().y,
      props: e.target.attrs.rack_info.props,
    }

    this.setState({tooltip_props: tooltip, tooltip_show: true});
  }

  render() {
    return (
      <div className="NavMap2D">
        <Stage ref="stage" width={this.props.width} height={this.props.height} >
          <Layer>
              <Image
                x={0}
                y={0}
                width = {this.props.width}
                height= {this.props.height}
                image={this.state.background}
              />
              {this.state.racks}
              <Circle
                radius={3}
                fill={'red'}
                x={(this.props.picking_origin[this.props.floor].x + WAREHOUSE[this.props.siteId].maps[this.props.floor].origin.x) * this.props.pixels_per_meter}
                y ={this.props.height - (this.props.picking_origin[this.props.floor].y + WAREHOUSE[this.props.siteId].maps[this.props.floor].origin.y) * this.props.pixels_per_meter}
                key={"Origin"}
              />
            </Layer>
          <ToolTip tooltip_props={this.state.tooltip_props} tooltip_show={this.state.tooltip_show}/>
        </Stage>
      </div>
    )
  }
}

export default WmsRacks;
