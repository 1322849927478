import React from 'react';
import { Stage, Layer, Group, Text, Image, Line, Label, Tag} from 'react-konva';
import Konva from 'konva';
import { debounce } from 'lodash';

import { connect } from 'react-redux';
import { postWmsJsonNew } from '../../../modules/api';
import { getCurrentSiteId } from '../../../modules/sites';

const METERS_PER_FOOT = 0.3048;

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

class WmsPathmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      x_img: null,
      nodes: {}, //nodeid = selected, x: 0, y:0
      paths: {},
      zoom: 1,
      loading: false,
      zone_bg: null,
      zoom_in: null,
      zoom_out: null,
      reset: null,
      loading_key: null,
      oobs: [],
    };

    this.setShowCard = this.setShowCard.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.drag = this.drag.bind(this);
    this.reset = this.reset.bind(this);
    this.debounceRendering = debounce(this.make_nodes, 2000);
  }

  componentDidMount() {
    this.loadImages();
    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=>this.make_nodes(loading_key));
  }

  componentWillUnmount() {
    this.unloadImages();
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("../img/" + this.props.background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));

    if(this.props.zone_src) {
      let zbg = new window.Image();
      zbg.src = require("../img/" + this.props.zone_src);
      zbg.addEventListener('load', () => this.setState({zone_bg: zbg}));
    }

    let x = new window.Image();
    x.src = require("../img/x.png");
    bg.addEventListener('load', () => this.setState({x_img: x}));

    let zoom_in = new window.Image();
    zoom_in.src = require('../img/zoom_in.png');
    zoom_in.addEventListener('load', ()=> this.setState({zoom_in: zoom_in}));

    let zoom_out = new window.Image();
    zoom_out.src = require('../img/zoom_out.png');
    zoom_out.addEventListener('load', ()=> this.setState({zoom_out: zoom_out}));

    let reset = new window.Image();
    reset.src = require('../img/reset.png');
    reset.addEventListener('load', ()=> this.setState({reset: reset}));
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));
    this.state.x_img.removeEventListener('load', () => this.setState({x_img: null}));
    if (this.state.zone_bg) {this.state.zone_bg.removeEventListener('load', ()=>this.state({zone_bg: null}));}
    this.state.zoom_in.removeEventListener('load', ()=>this.setState({zoom_in: null}));
    this.state.zoom_out.removeEventListener('load', ()=>this.setState({zoom_out: null}));
    this.state.reset.removeEventListener('load', ()=>this.setState({reset: null}));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showNode !== this.props.showNode &&
        this.props.showNode.includes("-")) {
      this.toggleCard(this.props.showNode.split("-")[0]);
    }

      if (prevProps.nodes.length !== this.props.nodes.length ||
          prevProps.pixels_per_meter !== this.props.pixels_per_meter ||
          JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals)){
        let loading_key = new Date().getTime();
        this.setState({loading_key: loading_key}, ()=>this.debounceRendering(loading_key));
      }
  }

  setShowCard(e) {
    this.toggleCard(e.target.attrs.node);
  }

  make_nodes(loading_key) {
    let nodes = this.refs['nodes'];
    nodes.destroyChildren();
    let newNodes = {};
    let paths = {...this.state.paths}
    this.props.nodes.forEach( n => {
      newNodes[n] = { selected: false, x: 0, y:0 };

      if (!paths.hasOwnProperty(n)) { //initiate new nodes
          paths[n] = {color: this.props.gids.hasOwnProperty(n) ? this.props.gids[n].color : getRandomColor(), path: []}
      }
    })

    for (const nodeId in paths) { paths[nodeId].path = [] } //clear out the paths
    this.setState({loading: true, nodes: newNodes, paths: paths}, ()=>{this.fetch_wms_tracks(loading_key)});
  }

  fetch_wms_tracks(loading_key) {
    let body = {
      "time_intervals" : this.props.time_intervals,
      "assets": this.props.nodes,
      "outputs" : { "locations": {}},
    }
    /*
    let body = {
      "from" :this.props.time_intervals[0].from,
      "to" : this.props.time_intervals[this.props.time_intervals.length - 1].to,
      "assets": this.props.nodes,
      "outputs" : { "locations": {}},
    }
    */
    if (this.props.floor) { body["outputs"]["locations"]["floor"] = this.props.floor }

    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        if (loading_key === this.state.loading_key) {
          this.makeWmsPath(json, loading_key);
        } else {
          console.log("Another process is going 1")
        }
      })
      .catch(error => console.log(error));
  }

  makeWmsPath(json, loading_key) {
    let nodes = { ...this.state.nodes };
    let paths = { ...this.state.paths };

    for (const nodeId in json.assets) {
      if (!json.assets[nodeId].hasOwnProperty('locations')) { continue; }
      let locations = json.assets[nodeId].locations;

      locations.forEach(interval => {
        let path = [];
        interval.forEach((loc, index)  => {

          //oob
          if (loc.hasOwnProperty('new_segment')) {
            paths[nodeId].path.push(path);
            path =[];
          }

          let newX = (loc.point.x * METERS_PER_FOOT + this.props.origin.x) * this.props.pixels_per_meter;
          let newY = this.props.height - ((loc.point.y * METERS_PER_FOOT  + this.props.origin.y) * this.props.pixels_per_meter);
          path.push(newX);
          path.push(newY);

          if (index === (interval.length - 1) && nodes.hasOwnProperty(nodeId)) { //set x,y to first point
            nodes[nodeId].x = newX;
            nodes[nodeId].y = newY;
          }
        }) //end interval

        paths[nodeId].path.push(path);
      }) //end locations
    }

    if (this.state.loading_key === loading_key) {
      this.setState({loading_key: null, loading: false, nodes: nodes, paths: paths}, ()=>{console.log("MakeWmsPath done"); this.loadOOBs()});
    } else {
      console.log("Another process is going 2")
    }
  }

  loadOOBs() {
    let oobs = [];
    for (const o in this.props.oobs) {
      let oob = this.props.oobs[o];
      oobs.push(<Line key={oob.name} points={oob.points} stroke={oob.color} strokeWidth={5} fill={oob.color} closed={true} opacity={1} />)
    }
    this.setState({oobs: oobs})
  }


  toggleCard(nodeId) {
    if (!this.state.nodes.hasOwnProperty(nodeId)) {return;}
    if (this.state.paths[nodeId].path.length === 0) {return;}
    let nodes = { ...this.state.nodes };
    let flag = nodes[nodeId].selected;
    if (this.refs.hasOwnProperty(nodeId)) {
      if (flag) {
        this.refs[nodeId].clearCache();
      } else {
        this.refs[nodeId].cache();
        this.refs[nodeId].filters([Konva.Filters.Brighten]);
        this.refs[nodeId].brightness(.25);
      }
    }
    nodes[nodeId].selected = !flag;
    this.setState({nodes: nodes});
  }

  zoomIn(e) {
    if (this.state.zoom >= 2) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom + .1}))
    layer.scale({x: this.state.zoom , y: this.state.zoom})
    layer.draw();
  }

  zoomOut(e) {
    if (this.state.zoom <= 1) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom - .1}))
    layer.scale({x: this.state.zoom, y: this.state.zoom})
    layer.draw();
  }

  drag(e) {
    let layer = this.refs['map']
    layer.startDrag();
  }

  reset(e) {
    let map = this.refs['map']
    map.scale({x: 1, y: 1});
    map.setPosition({x: 0, y: 0});
    map.draw();
    this.setState({zoom: 1});
  }

  render() {
    let showPaths = [];
    for(const nodeId in this.state.paths) {
      const color = this.state.paths[nodeId].color;
      const paths = this.state.paths[nodeId].path;
      if (paths.length === 0 ) {continue}
      paths.forEach((pp, index) => {
        showPaths.push(<Line key={nodeId + "_path_" + index} node={nodeId} ref={nodeId} points={pp} stroke={color} lineJoin={'round'} strokeWidth={2} tension={.5} onClick={this.setShowCard}/>)
      })
    }

    let showNodeInfo = [];
    for (const nodeId in this.state.nodes) {
      const n = this.state.nodes[nodeId];
      if (!n.selected || (n.x === 0 && n.y === 0)) { continue; }

      /*let offset_x = 0
      if (this.props.width - n.new_x <= 130 ) {
        offset_x = (120 - (this.props.width - n.x)) * -1 //left
      } else if (n.x <= 130) {
        offset_x = 100 - n.x //right
      }

      let offset_y = 0
      if (n.y <= 130) { offset_y = 160 } //top

      let rect =  <Rect node={n} x={n.x - 100 + offset_x}  y ={n.y - 140 + offset_y} width={120} height={40} key={nodeId + "_box"} fill={'#ffffff'} stroke={'rgba(0, 0, 0, 0.25'}/>
      let thisNodeInfo = <Text x={n.x - 85 + offset_x} y ={n.y - 125 + offset_y} key={nodeId + "_info"} text={GIDS[this.props.siteId][nodeId].name} fontSize={14} fontFamily={'Lato'} fill={'#0068b0'}/>
      let thisNodeInfo8 = <Image image={this.state.x_img} x={n.x + 5 + offset_x} y ={n.y - 135 + offset_y} node={nodeId} key={nodeId + "_info8"} onClick={this.setShowCard}/>
      showNodeInfo.push(rect, thisNodeInfo, thisNodeInfo8,);
      */

      let thisNodeInfo = <Label key={n.nodeId + "_label"} x={n.x } y ={n.y - 30}>
                            <Tag key={n.nodeId + "_tag"} fill={'white'} stroke={'rgba(0, 0, 0, 0.25'}/>
                            <Text key={n.nodeId + "_info"} text={this.props.gids[nodeId].name} fontSize={12} fontFamily={'Lato'} fill={'#0068b0'} padding={7} node={n} onClick={this.setShowCard}/>
                          </Label>
      showNodeInfo.push(thisNodeInfo);
    }

    return (
      <div className="NavMap2D">
      <div className="loading">{this.state.loading && "Loading..Please wait..."}</div>
      <div style={{opacity: this.state.loading ? .2 : 1}}>
        <Stage width={1055} height={this.props.height < 775 ? 775 : this.props.height}>
          <Layer ref="map" onDblClick={this.zoomIn} draggable={true} onDragEnd={(e)=>{console.log("Path Map - drag end")}} /*onMouseDown={this.drag}*/ y={this.props.height >= 700 ? 0 : (700-this.props.height)/2}>
            <Image
              x={0}
              y={0}
              width = {this.props.width}
              height= {this.props.height}
              image={this.props.showZone && this.state.zone_bg ? this.state.zone_bg : this.state.background}
            />
            <Group ref="nodes">
              {showPaths}
              {showNodeInfo}
            </Group>
            {this.state.oobs}
            </Layer>
            <Layer>
              <Image image={this.state.reset} x={1015} y={5} width={30} height={30} key={'resetImg'} onClick={this.reset}/>
              <Image image={this.state.zoom_in} x={1015} y={40} width={30} height={30} key={'zoomInImg'} onClick={this.zoomIn}/>
              <Image image={this.state.zoom_out} x={1015} y={75} width={30} height={30} key={'zoomOutImg'} onClick={this.zoomOut}/>
            </Layer>
        </Stage>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({ authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(WmsPathmap);
