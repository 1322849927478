import React from 'react';
import Slider from 'react-rangeslider';
//import 'react-rangeslider/lib/index.css';

const formatPc = p => p + '%';
//const formatkg = value => value + 'kg'

const RangeSlider = (props) => {
  return (
    <div id="range_slider">
      <Slider
        min={props.min}
        max={props.max}
        orientation="horizontal"
        value={props.value}
        format={formatPc}
        onChange={val => props.updateField(props.field, val, 'range_slider')}
        tooltip={true}
        step={1}
      />
    </div>
  )
}

export default RangeSlider;

/*
<Slider
  min={0}
  max={255}
  orientation="horizontal"
  value={motion.motion_sensitivity}
  onChange={val => editMotion(val, 'motion_sensitivity')}
/>

import Slider from 'react-rangeslider'

// inside render
<Slider
  min={Number}
  max={Number}
  step={Number}
  value={Number}
  orientation={String}
  reverse={Boolean}
  tooltip={Boolean}
  labels={Object}
  handleLabel={String}
  format={Function}
  onChangeStart={Function}
  onChange={Function}
  onChangeComplete={Function}
/>
*/
