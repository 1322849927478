//New LPS POST api

import React from 'react';
import { Stage, Layer, Group, Rect, Circle, Text, Image, Line, Label, Tag} from 'react-konva';
import Konva from 'konva';
import { debounce } from 'lodash';

import { getRandomColor } from '../common/Util';
import { connect } from 'react-redux';
import { postWmsJsonNew } from '../../modules/api';
import { getCurrentSiteId } from '../../modules/sites';
import { logoutNode } from '../../modules/nodes';

const PIXEL_ADJUSTMENT_X = 0;
const PIXEL_ADJUSTMENT_Y = 30;
const METERS_PER_FOOT = 0.3048;

const WIDTH_CUTOFF = 525;
const ZONE_ADJUSTMENT = {
  1: {
    font : 22,
    stroke: 5,
  },
  .5: {
    font: 12,
    stroke: 3,
  }
}


class NavMap2dMultPaths extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      background: null,
      x_img: null,
      nodes: {},
      node_info: {},
      paths: {},
      anchors: {},
      gts: [],
      zoom: 1,
      loading: false,
      zones: [],
      zoom_in: null,
      zoom_out: null,
      reset: null,
      loading_key: null,
      scale: 1,
      oobs: [],
      totalTime: 0,
    };

    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.drag = this.drag.bind(this);
    this.reset = this.reset.bind(this);
    this.debouncePathRendering = debounce(this.path_component_update, 2000);
  }

  componentDidMount() {
    this.loadImages();
    this.loadAnchors();
    this.loadGTs();
    this.setState({scale: this.props.width < WIDTH_CUTOFF ? .5 : 1}, ()=>this.loadZones())

    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=> this.path_component_update(loading_key));
  }

  componentWillUnmount() {
    this.unloadImages();
  }

  loadImages() {
    let bg = new window.Image();
    bg.src = require("./img/" + this.props.background_src);
    bg.addEventListener('load', () => this.setState({background: bg}));

    if(this.props.zone_src) {
      let zbg = new window.Image();
      zbg.src = require("./img/" + this.props.zone_src);
      zbg.addEventListener('load', () => this.setState({zone_bg: zbg}));
    }

    let x = new window.Image();
    x.src = require("./img/x.png");
    bg.addEventListener('load', () => this.setState({x_img: x}));

    let zoom_in = new window.Image();
    zoom_in.src = require('./img/zoom_in.png');
    zoom_in.addEventListener('load', ()=> this.setState({zoom_in: zoom_in}));

    let zoom_out = new window.Image();
    zoom_out.src = require('./img/zoom_out.png');
    zoom_out.addEventListener('load', ()=> this.setState({zoom_out: zoom_out}));

    let reset = new window.Image();
    reset.src = require('./img/reset.png');
    reset.addEventListener('load', ()=> this.setState({reset: reset}));
  }

  unloadImages() {
    this.state.background.removeEventListener('load', () => this.setState({background: null}));
    this.state.x_img.removeEventListener('load', () => this.setState({x_img: null}));
    if (this.state.zone_bg) {this.state.zone_bg.removeEventListener('load', ()=>this.state({zone_bg: null}));}
    this.state.zoom_in.removeEventListener('load', ()=>this.setState({zoom_in: null}));
    this.state.zoom_out.removeEventListener('load', ()=>this.setState({zoom_out: null}));
    this.state.reset.removeEventListener('load', ()=>this.setState({reset: null}));
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.zones).length !== Object.keys(this.props.zones).length) {
      this.loadZones();
    }

    if (prevProps.cards !== this.props.cards) {
      let show = {...this.state.node_info};

      for (const nodeId in this.props.cards) {
        if (!this.refs[nodeId]) {continue}
        if (this.state.paths[nodeId].paths[0].length === 0) { continue; }
        if (this.props.cards[nodeId]) { //true
          show[nodeId] = this.state.nodes[nodeId]
          this.refs[nodeId].cache();
          this.refs[nodeId].filters([Konva.Filters.Brighten]);
          this.refs[nodeId].brightness(.25);
        } else if (show[nodeId]) {
          this.refs[nodeId].clearCache();
          delete show[nodeId];
        }
      }

      this.setState({node_info: show})
    }

    if (Object.keys(prevProps.anchors).length !== Object.keys(this.props.anchors).length) {
      this.loadAnchors();
    }

    if (Object.keys(prevProps.gts).length !== Object.keys(this.props.gts).length) {
      this.loadGTs();
    }

    if (Object.keys(prevProps.nodes).length !== Object.keys(this.props.nodes).length ||
        prevProps.pixels_per_meter !== this.props.pixels_per_meter ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals))
      {
          let loading_key = new Date().getTime();
          this.setState({loading_key: loading_key}, ()=>this.debouncePathRendering(loading_key));
      }
  }

  loadAnchors() {
    let newAnchors = {};
    for (const anchorId in this.props.anchors) {
      const anchor = this.props.anchors[anchorId];
      let newX = (anchor.x + this.props.origin.x) * this.props.pixels_per_meter;
      let newY = this.props.height - ((anchor.y + this.props.origin.y) * this.props.pixels_per_meter);
      newAnchors[anchorId] = {nodeId: anchorId, x: anchor.x, y: anchor.y, new_x: newX , new_y: newY, selected: false};
    }
    this.setState({anchors: newAnchors});
  }

  loadGTs(){
    let allGTs = [];
    for (const gt in this.props.gts) {
      const g = this.props.gts[gt];
      let newX = (g.x + this.props.origin.x) * this.props.pixels_per_meter;
      let newY = this.props.height - ((g.y + this.props.origin.y) * this.props.pixels_per_meter);
      allGTs.push(<Circle radius={4} fill={"#800080"} x={newX} y={newY} key={gt}/>);

      let thisGTInfo = <Label key={gt + "_label"} x={newX - 10} y={newY - 25}>
                          <Tag key={gt + "_tag"} fill={'white'} stroke={'rgba(0, 0, 0, 0.15'}/>
                          <Text key={gt + "_info"} text={gt} fontSize={12} fontFamily={'Lato'} fill={'#0068b0'} padding={3}/>
                        </Label>
      allGTs.push(thisGTInfo)
    }

    this.setState({gts: allGTs})
  }
  
  path_component_update(loading_key) {
    console.log("Path component update " + loading_key);
    this.setState({loading: true});

    //clear stage
    let nodes = this.refs['nodes'];
    nodes.destroyChildren();

    let newNodes = {};
    let paths = {...this.state.paths}

    for (const nodeId in this.props.nodes) {
      const node = this.props.nodes[nodeId];
      let newX = (node.x + this.props.origin.x) * this.props.pixels_per_meter - PIXEL_ADJUSTMENT_X;
      let newY = this.props.height - ((node.y + this.props.origin.y) * this.props.pixels_per_meter) - PIXEL_ADJUSTMENT_Y;
      newNodes[nodeId] = { name: node.name + (node.asset ? "/" + node.asset : ""), nodeId: nodeId, x: node.x, y: node.y, new_x: newX, new_y: newY, selected: false, type: node.type, timestamp: node.timestamp, meas_id: node.meas_id};

      if (!paths.hasOwnProperty(nodeId)) { //initiate new nodes
        if (node.color === null) {
          paths[nodeId] = {type: '', color: getRandomColor(), path: []}
        } else {
          paths[nodeId] = {type: '', color: "#" + node.color, path: []}
        }
      }
    } //endFor

    for (const nodeId in paths) { paths[nodeId].paths = [] } //clear out the paths
    this.setState({nodes: newNodes, paths: paths}, ()=>{this.fetch_wms_tracks(loading_key)});
  }

  fetch_wms_tracks(loading_key) {
    let totalTime = 0;
    this.props.time_intervals.forEach(a =>
      totalTime += a.to - a.from
    )
    this.setState({totalTime: totalTime});

    let body = {
      "time_intervals" : this.props.time_intervals,
      "assets": Object.keys(this.props.nodes),
      "use_wms" : false,
      "outputs" : { "locations": {}},
    }
    if (this.props.floor) { body["outputs"]["locations"]["floor"] = this.props.floor }

    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        if (this.state.loading_key === loading_key) {
          this.makeFilteredWmsPath(json, loading_key)
        } else {
          console.log("another process is going 1 - " + this.state.loading_key)
        }
      })
      .catch(error => { error.status === 401 ? this.props.dispatch(logoutNode()) : console.log(error)})
  }

  makeFilteredWmsPath(json, loading_key) {
    let paths = {...this.state.paths};

    for (const nodeId in json.assets) {
      json.assets[nodeId].locations.forEach((locArray)  => {
        let path = [];
        let prev_z = null;

        locArray.forEach(loc => {
          let offset_y = 0; //z offset
          if (this.props.z !== null && loc.point.z >= this.props.z.tracker_min) {
            offset_y = this.props.z.y_offset;
          }

          if (prev_z !== null && this.props.z !== null &&
            ((loc.point.z >= this.props.z.tracker_min && prev_z < this.props.z.tracker_min) ||
            (loc.point.z < this.props.z.tracker_min && prev_z >= this.props.z.tracker_min)))
          {
            paths[nodeId].paths.push(path);
            path =[];
          }

          //oob
          if (loc.hasOwnProperty('new_segment')) {
            paths[nodeId].paths.push(path);
            path =[];
          }

          let newX = (loc.point.x * METERS_PER_FOOT + this.props.origin.x) * this.props.pixels_per_meter;
          let newY = this.props.height - (((loc.point.y + offset_y) * METERS_PER_FOOT + this.props.origin.y) * this.props.pixels_per_meter);

          path.push(newX);
          path.push(newY);

          prev_z = loc.point.z;
        })

        paths[nodeId].paths.push(path);
        path =[];
      })
    }

    if (this.state.loading_key === loading_key) {
      this.setState({loading_key: null, loading: false, paths: paths}, ()=>console.log("MakeWmsFilteredPath done"));
    } else {
      console.log("another process is going 2 - " + this.state.loading_key)
    }
  }

  loadZones() {
    let zones = [];
    for (const z in this.props.zones) {
      let zone = this.props.zones[z];
      zones.push(<Text x={zone.x} y ={zone.y} key={zone.name + "_name"} text={zone.name} fontSize={ZONE_ADJUSTMENT[this.state.scale].font} fontFamily={'Lato'} fill={zone.color}/>)
      zones.push(<Line key={zone.name} points={zone.points} stroke={zone.color} strokeWidth={ZONE_ADJUSTMENT[this.state.scale].stroke} closed={true} />)
    }
    this.setState({zones: zones});

    let oobs = [];
    for (const o in this.props.oobs) {
      let oob = this.props.oobs[o];
      oobs.push(<Line key={oob.name} points={oob.points} stroke={oob.color} strokeWidth={ZONE_ADJUSTMENT[this.state.scale].stroke} fill={oob.color} closed={true} opacity={1}/>)
    }
    this.setState({oobs: oobs})
  }

  zoomIn(e) {
    if (this.state.zoom >= 2) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom + .1}))
    layer.scale({x: this.state.zoom , y: this.state.zoom})
    layer.draw();
  }

  zoomOut(e) {
    if (this.state.zoom <= .75) { return }
    let layer = this.refs['map']
    this.setState((prevState, props) => ({zoom: prevState.zoom - .1}))
    layer.scale({x: this.state.zoom, y: this.state.zoom})
    layer.draw();
  }

  drag(e) {
    let layer = this.refs['map']
    layer.startDrag();
  }

  reset(e) {
    let map = this.refs['map']
    map.scale({x: 1, y: 1});
    map.setPosition({x: 0, y: 0});
    map.draw();
    this.setState({zoom: 1});
  }

  render() {
    let showPaths = [];
    for(const nodeId in this.state.paths) {
      const color = this.state.paths[nodeId].color;
      const p = this.state.paths[nodeId].paths;
      if (p.length === 0 ) {continue}
      p.forEach((pp, index) => {
        showPaths.push(<Line key={nodeId + "_path_" + index} node={this.state.nodes[nodeId]} ref={nodeId} points={pp} stroke={color} lineJoin={'round'} strokeWidth={2} tension={.5} onClick={this.props.onShowCard}/>)
        if (this.state.totalTime <= 21600000) {
          for(let start = 0; start < pp.length; start +=2) {
            showPaths.push(<Circle key={nodeId + "_point_" + start} node={this.state.nodes[nodeId]} radius={3} fill={color} x={pp[start]} y={pp[start + 1]} onClick={this.props.onShowCard}/>)
          }
        }
      })
    }

    let showNodeInfo = [];
    for (const nodeId in this.state.node_info) {
      const n = this.state.node_info[nodeId];

      /*let offset_x = 0
      if (this.props.width - n.new_x <= 130 ) {
        offset_x = (120 - (this.props.width - n.new_x)) * -1 //left
      } else if (n.new_x <= 130) {
        offset_x = 100 - n.new_x //right
      }

      let offset_y = 0
      if (n.new_y <= 130) { offset_y = 160 } //top

      let rect =  <Rect onClick={this.props.onShowCard} node={n} x={n.new_x - 100 + offset_x}  y ={n.new_y - 140 + offset_y} width={230} height={70} key={n.nodeId + "_box"} ref={nodeId + "_box"} fill={'#ffffff'} stroke={'rgba(0, 0, 0, 0.25'}/>
      let thisNodeInfo = <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 125 + offset_y} key={n.nodeId + "_info"} text={n.name} fontSize={16} fontFamily={'Lato'} fill={'#0068b0'}/>
      let thisNodeInfo3 = <Text x={n.new_x - 85 + offset_x} y ={n.new_y - 95 + offset_y} key={n.nodeId + "_info3"} text={"LAST LOCATE"} fontSize={10} fontFamily={'Lato'} fill={'#656565'}/>
      let thisNodeInfo5 = <Text x={n.new_x + 35 + offset_x} y ={n.new_y - 97 + offset_y} key={n.nodeId + "_info5"} text={n.timestamp ? formatTime(n.timestamp) : formatTime(n.meas_id)} fontSize={14} fontFamily={'Lato'} fill={'#132c4f'}/>
      let thisNodeInfo8 = <Image image={this.state.x_img} x={n.new_x + 110 + offset_x} y ={n.new_y - 130 + offset_y} node={n} key={n.nodeId + "_info8"} onClick={this.props.onShowCard}/>
      showNodeInfo.push(rect, thisNodeInfo, thisNodeInfo3, thisNodeInfo5, thisNodeInfo8,);
      */

      let thisNodeInfo = <Label key={n.nodeId + "_label"} x={n.new_x } y ={n.new_y - 30}>
                            <Tag key={n.nodeId + "_tag"} fill={'white'} stroke={'rgba(0, 0, 0, 0.25'}/>
                            <Text key={n.nodeId + "_info"} text={n.name} fontSize={12} fontFamily={'Lato'} fill={'#0068b0'} padding={7} node={n} onClick={this.props.onShowCard}/>
                          </Label>
      showNodeInfo.push(thisNodeInfo);
    }

    let showAnchors = []
    for (const nodeId in this.state.anchors) {
      const n = this.state.anchors[nodeId];
      let thisNode = <Rect fill={'#44d7b6'} width={8} height={8} x={n.new_x} y ={n.new_y - 5} key={n.nodeId} />
      showAnchors.push(thisNode);
    }

    return (
      <div className="NavMap2D">
      <div className="loading">{this.state.loading && "Loading... Please wait..."}</div>
        <div style={{opacity: this.state.loading ? .2 : 1}}>
          <Stage width={1055} height={this.props.height < 710 ? 710 : this.props.height}>
            <Layer ref="map" onDblClick={this.zoomIn} draggable={true} onDragEnd={(e)=>{console.log("Path Map - drag end")}} /*onMouseDown={this.drag}*/ y={this.props.height >= 700 ? 0 : (700-this.props.height)/2}>
              <Image
                x={0}
                y={0}
                width = {this.props.width}
                height= {this.props.height}
                image={this.state.background}
              />
              {this.props.showZone && this.state.zones}
              {showAnchors}
              <Group ref="nodes">
                {showPaths}
                {showNodeInfo}
              </Group>
              {this.state.gts}
              {this.state.oobs}
              </Layer>
              <Layer>
                <Image image={this.state.reset} x={1015} y={5} width={30} height={30} key={'resetImg'} onClick={this.reset}/>
                <Image image={this.state.zoom_in} x={1015} y={40} width={30} height={30} key={'zoomInImg'} onClick={this.zoomIn}/>
                <Image image={this.state.zoom_out} x={1015} y={75} width={30} height={30} key={'zoomOutImg'} onClick={this.zoomOut}/>
              </Layer>
          </Stage>
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({ authToken: state.authToken,
              currentSiteId: getCurrentSiteId(state)
   }),
)(NavMap2dMultPaths);

/*
function formatTime(m) {
  if (m) {
    let d = new Date(m);

    //do date string
    let dateStr = "";
    let today = new Date();
    let midNight = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let diff = d.getTime() - midNight.getTime();

    if (diff < 0 ) {
      //if (diff < -86400000) {
        dateStr = (d.getMonth() + 1) + "/" + d.getDate() + ", ";
      //} else {
      //  dateStr = "Yesterday "
      //}
    }

    let hour = d.getHours().toString();
    let min = d.getMinutes().toString();;
    let sec = d.getSeconds().toString();;

    if (hour.length === 1 ) { hour = "0" + hour }
    if (min.length === 1) { min = "0" + min }
    if (sec.length === 1) {sec = "0" + sec}

    return dateStr + hour + ":" + min + ":" + sec;
  } else {
    return "00:00:00";
  }
}


if (loc.hasOwnProperty('new_segment')) {
  paths[nodeId].paths.push(path);
  path =[];
}

if (prev_loc !== null) {
  if (Math.pow((prev_loc.point.x - loc.point.x),2) + Math.pow((prev_loc.point.y - loc.point.y),2) > 3600) {
    paths[nodeId].paths.push(path);
    path =[];
  }
}
*/
