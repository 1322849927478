import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { NavLink } from 'react-router-dom';
import { checkACL, myHighestRole } from './Config';

const style = {
  option: (provided, state) => ({
    ...provided,
  }),
  control: base => ({
    ...base,
    maxHeight: "38px",
  }),
  valueContainer: base =>({
    ...base,
    maxHeight: "38px",
  }),
  indicatorsContainer: base => ({
    ...base,
    maxHeight: "38px",
  }),
  singleValue: base => ({
    ...base,
  })
};

const SelectOptions = (props) => (
     < Select
        options={props.options}
        styles={style}
        value={props.value}
        placeholder=""
        isClearable={false}
        onChange={e => props.selectCustomer(e)}
      />
)

const SideBar = (props) => {
  const [options, setOptions] = useState(null);

  useEffect(()=> {
    //console.log("useEffect")
    if (options === null) {
      let customers = {};
      for (const id in props.customers) {
        customers[id] = {value: id, label: props.customers[id]};
      }
      customers = Object.values(customers).sort((a, b) => a.label.localeCompare(b.label));
      //setOptions([{value: "All", label: "All Customers"}].concat(customers));
      setOptions(customers);

      if (props.customer === null ) {
        props.selectCustomer(customers[0]);
      }
    }
  }, [options, props]);

  const DASHBOARD_MANAGEMENT =
    <div>
      <h2>Dashboard Management</h2>
      {
        checkACL(props.acl, "Customer Select") &&
        <div className="select">
          <SelectOptions
            options={options}
            value={props.customer}
            selectCustomer={e => props.selectCustomer(e)}/>
        </div>
      }
      <ul>
        { checkACL(props.acl, 'User Link') && //customer admin
            ['super-admin', 'admin', 'customer-admin'].includes(myHighestRole(props.acl)) &&
            <li><NavLink to="/dashboard/management/users/customeradmin">USERS</NavLink></li>
         }
        { checkACL(props.acl, 'User Link') &&
          myHighestRole(props.acl) === 'site-admin' && //site admin
          <li><NavLink to="/dashboard/management/users/siteadmin">USERS</NavLink>
          </li>
         }
        { checkACL(props.acl, 'Site Link') && <li><NavLink to="/dashboard/management/sites">SITES</NavLink></li> }
      </ul>
    </div>

  const USER_PROFILE =
    <div>
      <h2>PROFILE</h2>
      <ul>
        { props.path === '/dashboard/management/myprofile' && <li><NavLink to="/dashboard/management/myprofile">USER PROFILE</NavLink></li> }
      </ul>
    </div>

  return (
    <div className="sidebar">
       { props.path === '/dashboard/management/myprofile' ? USER_PROFILE : DASHBOARD_MANAGEMENT }
    </div>
  )
};

export default SideBar;

//Zainar implementation - LPS/WMS/Admin only
