import React, {useState} from 'react';
import Modal from 'react-modal';

const Select = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState(props.value);

  const setShow = (e) => {
    e.stopPropagation();
    let show = !showModal;
    setShowModal(show);
  }

  const closeModal = (e) => {
    e.stopPropagation();
    setShowModal(false);
  }

  const executeAction = (e) => {
    props.action(value);
    closeModal(e);
  }

  const onChange = (e) => {
    setValue(e.target.value);
    setShow(e);
  }

  return(
    <div>
      <div className="locix-select">
        <select name={props.name} id={props.name} onChange={onChange} value={props.value}>
          { props.options.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
          </select>
      </div>
      <Modal
        ariaHideApp={false}
        isOpen={showModal}
        contentLabel="Warn"
        className="Warn"
      >
        <div className="WarnContainer">
          <h3>WARNING</h3>
          <div>{props.warn}</div>
          <button className="cancel" onClick={closeModal}>CANCEL</button>
          <button className="continue" onClick={executeAction}>CONTINUE</button>
        </div>
      </Modal>
    </div>
  )
}

export default Select;

/*
props.name
props.options
props.value
props.warn
*/
