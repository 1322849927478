import React from 'react';
import { connect } from 'react-redux';

import { getConstellationsArray } from '../../modules/constellations';
import ConstellationEvent from './ConstellationEvent';

const ConstellationEventList = ({ constellations }) => (
  <div className="node-event-frame-container">
    <h2>Constellations</h2>
    {
      constellations.map(c => <ConstellationEvent constellation={c} key={c.constellation} />)
    }
  </div>
)


export default connect(
  state => ({ constellations: getConstellationsArray(state) }),
)(ConstellationEventList)
