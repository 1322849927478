import React from 'react';
import ZoneDonutChart from './ZoneDonutChartNew';
import { WAREHOUSE } from '../2dConfig';
import {addCommas, convertSiteTime} from './ChartUtil';

const METERS_PER_FOOT = 0.3048;

function makeData(site_id, stats, duration) {
 let types = WAREHOUSE.hasOwnProperty(site_id) ? WAREHOUSE[site_id].tracker_type : WAREHOUSE['default'].tracker_type;
 let result = {};

 types.forEach(type => {
   result[type] = {distance: 0, active_time: 0, inactive_time: 0, active_count: 0};

   if (stats.hasOwnProperty("distance_by_type")) {
     if (stats["distance_by_type"].hasOwnProperty(type)) {
       result[type]['distance'] = parseInt(stats["distance_by_type"][type] * METERS_PER_FOOT, 10);
     }
   }

   if (stats.hasOwnProperty("active_stats_by_type")) {
     if (stats['active_stats_by_type'].hasOwnProperty(type)) {
       result[type]['active_time'] = stats['active_stats_by_type'][type]['active_time'] * duration;
       result[type]['inactive_time'] = stats['active_stats_by_type'][type]['inactive_time'] * duration;
       result[type]['active_count'] = stats['active_stats_by_type'][type]['active_count'];
     }
   }
 });

 let summary = {'distance': 0, 'active_time': 0, 'inactive_time': 0, 'active_count': 0};
 for(const type in result) {
   summary['distance'] += result[type]['distance'];
   summary['active_time'] += result[type]['active_time'];
   summary['inactive_time'] += result[type]['inactive_time'];
   summary['active_count'] += result[type]['active_count'];
 }

 return [types, summary, result];
}

const Summary = (({site_id, site_stats, duration, zone_config}) => {
    let data = makeData(site_id, site_stats, duration);
    let types = data[0];
    let summary = data[1];
    let result = data[2];

    return(
      <div className="SummaryChart">
        <div className="SiteHeader">SUMMARY</div>
        <div className="SiteAnalyticsContainer">
          <div className="SummaryAnalyticsBox">
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteDistance.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{addCommas(summary["distance"])} M</div>
              <div className="SummaryLabel">TOTAL DISTANCE</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {types.map(type => <div key={type} className="SummaryProperty">{type}</div>)}
              </div>
              <div className="SummaryValueContainer">
                {types.map(type => <div key={type + "distance"} className="SummaryValue">{addCommas(result[type]['distance'])}M</div>)}
              </div>
            </div>
          </div>
          <div className="SummaryAnalyticsBox">
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteActiveTime.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{convertSiteTime(summary["active_time"])}</div>
              <div className="SummaryLabel">TOTAL ACTIVE TIME</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {types.map(type => <div key={type} className="SummaryProperty">{type}</div>)}
              </div>
              <div className="SummaryValueContainer">
                {types.map(type => <div key={type + "active_time"} className="SummaryValue">{convertSiteTime(result[type]['active_time'])}</div>)}
              </div>
            </div>
          </div>
          <div className="SummaryAnalyticsBox">
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteInactive.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{convertSiteTime(summary["inactive_time"])}</div>
              <div className="SummaryLabel">TOTAL INACTIVE TIME</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {types.map(type => <div key={type} className="SummaryProperty">{type}</div>)}
              </div>
              <div className="SummaryValueContainer">
                {types.map(type => <div key={type + "inactive_time"} className="SummaryValue">{convertSiteTime(result[type]['inactive_time'])}</div>)}
              </div>
            </div>
          </div>
          <div className="SummaryAnalyticsBox" style={{marginRight: "0px"}}>
            <img className="SiteDistance" alt="site_distance" src={require("../img/SiteInactive.png")}/>
            <div className="StatsContainer">
              <div className="Stats">{summary['active_count']}</div>
              <div className="SummaryLabel">ACTIVE TRACKERS</div>
            </div>
            <div className="SummaryContainer">
              <div className="SummaryPropertyContainer">
                {types.map(type => <div key={type} className="SummaryProperty">{type}</div>)}
              </div>
              <div className="SummaryValueContainer">
                {types.map(type => <div key={type + "active_count"} className="SummaryValue">{result[type]['active_count']}</div>)}
              </div>
            </div>
          </div>
        </div>
        <ZoneDonutChart site_stats={site_stats} durationRaw={duration} zone_config={zone_config}/>
      </div>
  )
}
)

export default Summary;

/*
{"distance_by_type":{"forklift":6162.221889472133},"active_stats_by_type":{"forklift":{"active_count":5,"active_time":0.43576972222222216,"inactive_time":6.564230277777778}},"time_by_zone":{"1":0.21439916666666664,"3":0.0036769444444444436,"10":0.05736527777777777,"11":0.008074999999999999,"12":0.9439372222222218,"13":0.10015444444444442,"14":0.005042777777777778,"15":0.0007527777777777778,"16":0.026425277777777784,"17":0.02853472222222223,"19":0.008509166666666667,"20":0.02012111111111111,"21":0.08836666666666666,"22":0.0007455555555555555,"23":0.0009897222222222221,"24":0.10769749999999997,"25":0.06889277777777775,"26":0.021723611111111114,"27":0.12374166666666667}}
*/
