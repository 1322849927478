import React, { Component } from 'react';
import World from './World';

// Component properties
//  nodes: { <node_id>: { x: <x>, y: <y>, z: <z>, selected: <bool> } }
//  anchors: { <anchor_id>: { x: <x>, y: <y>, z: <z> } }
//  siteModel: <file name of Collada file>
//  onNodeClick: <callback called when a node is clicked: onNodeClick(nodeId)>

class WorldReact extends Component {
  constructor(props) {
    super(props);

    // Create the underlying 3D world object.
    this.world = new World(this.props.siteModel);

    // Listen for select events.
    this.world.on('nodeClick', (node, event) => {
      this.props.onNodeClick(node, event);
    });

    // Place the nodes.
    for (const nodeId in this.props.nodes) {
      const node = this.props.nodes[nodeId];
      this.world.addNode(nodeId, node.x, node.y, node.z, node.selected, false);
    }

    // Place the anchors.
    if (this.props.anchors) {
      for (const anchorId in this.props.anchors) {
        const anchor = this.props.anchors[anchorId];
        this.world.addNode(anchorId, anchor.x, anchor.y, anchor.z, false, true);
      }
    }

    // Keep a copy of the nodes so we know what's changed the next time
    // our properties get updated.
    this.nodes = Object.assign({}, this.props.nodes);
    this.anchors = Object.assign({}, this.props.anchors);
  }

  componentDidMount() {
    this.world.attachToElement(this.refs.world);
  }

  render() {
    //anchors
    if (Object.keys(this.anchors).length !== Object.keys(this.props.anchors).length) {
      for (const anchorId in this.props.anchors) {
        const anchor = this.props.anchors[anchorId];
        this.world.addNode(anchorId, anchor.x, anchor.y, anchor.z, false, true);
      }
      this.anchors = Object.assign({}, this.props.anchors);
    }

    for (const nodeId in this.props.nodes) {
      const node = this.props.nodes[nodeId];
      // If this is a new node...
      const oldNode = this.nodes[nodeId];
      if (oldNode === undefined) {
        this.world.addNode(nodeId, node.x, node.y, node.z,
            node.selected);
      } else {
        // Has anything changed for this node?
        if (node.x !== oldNode.x ||
            node.y !== oldNode.y ||
            node.z !== oldNode.z) {
          this.world.moveNode(nodeId, node.x, node.y, node.z);
        }
        if (node.selected !== oldNode.selected) {
          this.world.selectNode(nodeId, node.selected);
        }
      }
    }

    // Remove any nodes no longer present.
    for (const nodeId in this.nodes) {
      if (this.props.nodes[nodeId] === undefined) {
        this.world.removeNode(nodeId);
      }
    }

    // Save the nodes property for comparison on the next render.
    this.nodes = Object.assign({}, this.props.nodes);

    return (
      <div ref="world" className={this.props.className}>
        <canvas id="select-info-canvas" className="select-info-canvas" />
      </div>
    );
  }
}

export default WorldReact;
