import React from 'react';

const selectedStyle = {
  backgroundColor: "#164c88",
  color: "#ffffff",
};

const LongButton = (props) => {
  return (
    <div id="long_button">
    {props.value === props.label ?
              <button key={props.label} style={selectedStyle} onClick={()=>props.updateField(props.field, props.label, 'long_button')}>{props.label}</button>
              :
              <button key={props.label} onClick={()=>props.updateField(props.field, props.label, 'long_button')}>{props.label}</button>
    }
    </div>
  )
}

export default LongButton;
