import merge from 'lodash/merge';
import { login } from './api';
import { editAuthToken } from './authReducer';
import { push } from 'react-router-redux';
import { fetchJson, fetchObject} from './api';

export const LOAD_USER = 'users/LOAD_USER';
export const EDIT_USER = 'users/EDIT_USER';
export const LOGOUT = 'LOGOUT';

const initialState = {
  userId: null,
  userName: null,
  error: null,
}

// ==========================
// ==      The Reducer     ==
// ==========================
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_USER:
    case EDIT_USER:
      return merge({}, state, action.payload);
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}

// ==========================
// ==      Selectors       ==
// ==========================


// ==========================
// ==   Action Creators    ==
// ==========================
export function loadUser(user) {
  return { type: LOAD_USER, payload: user };
}
export function editUser(user) {
  return { type: EDIT_USER, payload: user };
}

export function logout() {
  return { type: LOGOUT };
}

// ===========================
// == Async Action Creators ==
// ===========================

export function loginUser(userName, password) {
  return (dispatch, getState) => {
    dispatch(editUser({ error: null }))
    login(userName, password)
      .then(response => response.json())
      .then(json => {
          //console.log('Successfully logged in', json);
          // dispatch(loadUser)
          // window.localStorage.authToken = json.token;
          // window.localStorage.rememberMe = rememberMe;
          // window.localStorage.username = username;

          // resolve(session);
          //dispatch(loadUser({ userName }));
          //getUser(json.token).then(json => console.log(JSON.stringify(json)))

          dispatch(loadUser({ userName: userName, userId: json.id, acl: json.acl }));
          dispatch(editAuthToken(json.token));
          dispatch(push('/dashboard'));
          //console.log(Object.keys(getState().sites.sites));
          // dispatch(selectSite(Object.keys(getState().sites.sites)[0]));
      })
      .catch(error => {
        console.log(error);
        dispatch(editAuthToken(null));
        dispatch(editUser({ error: { message: 'The email or password you entered was incorrect.' }}))
        return;
      });
      // .catch(error => {
      //     window.localStorage.authToken = null;
      //     reject(error);
      // });
  }
}

  //return info about me based on my token
  /*{"id":"54f4a26713364cce85ebb8409b93397a","type":"user","state":{"name":"site-guestsite-full-access","email":"matt-test","domain":"locix","acl":[["e04874ba6528470ca13a2856d8eadcfa","1dae6638dcee4d479a2b27b3a40e0ddb","site-full-access"],["ce2d9c8fba0b410fa630cf02cd07ecb1","4ac3dd2aa3d94fe29996867653b21788","site-guest"]]},"metadata":{"timestamp":1617170199396,"version":2,"received":1617170199396,"name":{"timestamp":1616780817544},"email":{"timestamp":1616780817544},"password":{"timestamp":1616780817544},"acl":{"timestamp":1617170199396},"domain":{"timestamp":1616780817544}},"last_update":"1c1843252e2b4a67b887df86f6c1e2c8"}
  */
  export function getUser(authToken) {
    const url = "me";
    let init = {
      method: 'get',
    }
    return fetchJson(url, authToken, init);
  }

  /*
  update user -
    body: {
      "password": "1234567@qwertyu"
    }
  */
  export function updateUser(authToken, body) {
    const url = "users";
    let init = {
      method: 'put',
      body: JSON.stringify(body)
    }
    return fetchObject(url, authToken, init);
  }

  //ACL Customers end point: GET - customers/<customer_id>/users/
  export function fetchCustomerUsers(authToken, customerId) {
    const url = `customers/${customerId}/users`;
    let init = {
      method: 'get',
    }

    return fetchJson(url, authToken, init);
  }

  /// DELETE customers/<customer_id>/users/:userId
  export function deleteCustomerUser(authToken, customerId, userId) {
    const url = `customers/${customerId}/users/${userId}`;
    let init = {
      method: 'delete',
    }

    return fetchObject(url, authToken, init);
  }

  //POST customer/<customer_id>/users/
  export function addCustomerUser(authToken, customerId, body) {
    const url = `customers/${customerId}/users`;
    let init = {
      method: 'post',
      body: JSON.stringify(body)
    }
    return fetchObject(url, authToken, init);
  }

  //GET - /sites/<site_id>/users
  export function fetchSiteUsers(authToken, siteId) {
    const url = `sites/${siteId}/users`;
    let init = {
      method: 'get',
    }
    return fetchJson(url, authToken, init);
  }

  export function deleteSiteUser(authToken, siteId, userId) {
    const url = `sites/${siteId}/users/${userId}`;
    let init = {
      method: 'delete',
    }
    return fetchObject(url, authToken, init);
  }

  //POST - /sites/<site_id>/users
  export function addSiteUser(authToken, siteId, body) {
    const url = `sites/${siteId}/users`;
    let init = {
      method: 'post',
      body: JSON.stringify(body)
    }
    return fetchObject(url, authToken, init);
  }
