import React from 'react';

import { Stage, Layer, Rect, /*Circle*/} from 'react-konva';
import h337 from 'heatmap.js';

import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { /*postWmsJson,*/ postWmsJsonNew } from '../../../modules/api';

import { setCookie, getCookie, makeLegendNew } from '../../common/Util';
import HeatmapLegend from '../HeatmapLegend';
import EditHeatmapCriteria from '../EditHeatmapCriteria';
import RackLegend from './RackLegend';
import EditRackCriteria from './EditRackCriteria';
import ToolTip from '../../common/ToolTip';

const METERS_PER_FOOT = 0.3048;

class WmsHeatmap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: {show: true, active: true, inactive: true},
      data: [],
      active_data: [],
      inactive_data: [],
      rack_raw: {},
      racks: [],
      loading: "",
      p_legend: [0,0,0,0],
      show_rack: true,
      openModal: false,
      openHModal: false,
      percent_or_quantity: "percentile", //percentile, rack_quantity
      rack_criteria: {
        0: {to: 25, color: "blue"},
        1: {to: 50, color: "green"},
        2: {to: 75, color: "yellow"},
        3: {to: 100, color: "red"}
      },
      tooltip_props: {id:null, props:null, x:null, y:null},
      tooltip_show: false,
      legends_show: true,
      legend: [".25",".55", ".85", "1"], //heatmap legend
      max: 1,
      heatmap_id: Math.random().toString(36).substring(7),
      loading_key: null,
    };

    this.onSetType = this.onSetType.bind(this);
    this.repaint = this.repaint.bind(this);
    this.setShowRack = this.setShowRack.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openHModal = this.openHModal.bind(this);
    this.setCriteria = this.setCriteria.bind(this);
    this.makeToolTip = this.makeToolTip.bind(this);
    this.closeToolTip = this.closeToolTip.bind(this);
    this.onSetMax = this.onSetMax.bind(this);
    this.onSetShowLegend = this.onSetShowLegend.bind(this);
    this.debounceRendering = debounce(this.fetch_wms_tracks, 2000);
  }

  componentDidMount() {
    //console.log("Mounted heatmap - " + JSON.stringify(this.state.type));
    if (getCookie("percent_or_quantity") !== "") {
      this.setState({percent_or_quantity: getCookie("percent_or_quantity"), rack_criteria: JSON.parse(getCookie("rack_criteria"))});
    }

    this.attach_background();
    let loading_key = new Date().getTime();
    this.setState({loading_key: loading_key}, ()=>this.fetch_wms_tracks(loading_key));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.nodes.length !== this.props.nodes.length ||
        prevProps.pixels_per_meter !== this.props.pixels_per_meter ||
        JSON.stringify(prevProps.time_intervals) !== JSON.stringify(this.props.time_intervals)) {
          //console.log("Update HeatMap - " + JSON.stringify(this.state.type));
          let loading_key = new Date().getTime();
          this.setState({loading_key: loading_key, max: 1, data: [], active_data: [], inactive_data: [], racks: [], rack_raw: {}}, ()=>{this.repaint(); this.debounceRendering(loading_key)});
      }
  }

  attach_background() {
    if (!document.getElementById(this.state.heatmap_id)) { return; }
    document.getElementById(this.state.heatmap_id).innerHTML = "";
    var img = document.createElement("img");
    img.src = require("../img/" + this.props.background_src);
    img.width = this.props.width;
    img.height = this.props.height;
    document.getElementById(this.state.heatmap_id).append(img);
  }

  fetch_wms_tracks(loading_key) {
    if (this.props.nodes.length === 0) {return}
    this.setState({loading: "Loading..Please wait...", legend: makeLegendNew(this.props.duration), max: 1, data: [], active_data: [], inactive_data: [], racks: [], rack_raw: {}});
    let body = {time_intervals: this.props.time_intervals, assets: this.props.nodes, outputs: {heat_map: {cell_size: 1}}};
    //let body = {from: this.props.time_intervals[0].from, to: this.props.time_intervals[this.props.time_intervals.length - 1].to, assets: this.props.nodes, outputs: {heat_map: {cell_size: 1}}};
    if (this.props.floor) { body["outputs"]["heat_map"]["floor"] = this.props.floor }

    postWmsJsonNew(this.props.authToken, this.props.siteId, body)
      .then(json => {
        if (loading_key === this.state.loading_key) {
          let data = [];
          let data_active = [];
          let data_inactive = [];

          json.heat_map.forEach(hm => {
            let newX = (hm.point.x * METERS_PER_FOOT + this.props.origin.x) * this.props.pixels_per_meter;
            let newY = this.props.height - ((hm.point.y * METERS_PER_FOOT + this.props.origin.y) * this.props.pixels_per_meter);

            if (hm.active > 0) {
              data_active.push({x: parseInt(newX, 10), y: parseInt(newY, 10), value: hm.active/this.props.duration });
            }

            if (hm.inactive > 0) {
              data_inactive.push({x: parseInt(newX, 10), y: parseInt(newY, 10), value: hm.inactive/this.props.duration});
            }

            if (hm.active > 0 || hm.inactive > 0) {
              data.push({x: parseInt(newX, 10), y: parseInt(newY, 10), value: (hm.active + hm.inactive)/this.props.duration});
            }
          });

          this.setState({loading: "", loading_key: null, data: data, active_data: data_active, inactive_data: data_inactive, rack_raw: json.racks});
        } else {
          console.log("Another process is going " + loading_key + " " + this.state.loading_key)
        }
      })
      .then(()=> this.repaint())
      .then(()=> this.makeRacks())
      //.then(()=> this.setState({loading: ""}))
      .catch(error => {
        this.attach_background(); this.setState({loading: "ERROR - STATUS " + error.status}, ()=>console.log(error))
      })
  }

  makeHeatmap() {
    let data = [];
    if (this.state.type.active && this.state.type.inactive) {
      data = [...this.state.data];
    } else if (this.state.type.active) {
      data = [...this.state.active_data];
    } else if (this.state.type.inactive) {
      data = [...this.state.inactive_data];
    }

    let config = {
      container: document.getElementById(this.state.heatmap_id),
      blur: .75,
    }

    this.heatmapInstance = h337.create(config)
    this.heatmapInstance.setData({min: 0, max: this.state.max, data: data})
    //console.log("makeHeatmap done - " + JSON.stringify(this.state.type));
  }

  makeRacks() {
    let highest_assignment = 0;
    let parent_racks = {};
    let coords_floor = this.props.coordinates[this.props.floor];
    let racks_floor = this.props.racks[this.props.floor];

    for(const r in this.state.rack_raw) {
      if (!racks_floor.hasOwnProperty(r)) { console.log("Missing parent " + r);  continue; }
      if (!coords_floor.hasOwnProperty(this.props.racks[this.props.floor][r])) { console.log("Missing coordinate " + r); continue;}
      let rr = this.state.rack_raw[r]
      let parent_id = racks_floor[r];
      if (rr.assignments > highest_assignment) { highest_assignment = rr.assignments }

      if (parent_racks.hasOwnProperty(parent_id)) {
        if (parent_racks[parent_id].p < rr.percentile_by_assignments) {
          parent_racks[parent_id].p = rr.percentile_by_assignments
        }
      } else {
        parent_racks[parent_id] = {
          x: coords_floor[parent_id].x,
          y: coords_floor[parent_id].y,
          width: coords_floor[parent_id].width,
          height: coords_floor[parent_id].height,
          assignments: rr.assignments,
          p: rr.percentile_by_assignments,
        }
      }
    } //end for

    let racks = [];
    let low = Math.round(highest_assignment * this.state.rack_criteria["0"].to / 100);
    let middle = Math.round(highest_assignment * this.state.rack_criteria["1"].to / 100);
    let high = Math.round(highest_assignment * this.state.rack_criteria["2"].to / 100);
    let higher = highest_assignment;

    //console.log("processing - " + this.state.percent_or_quantity);
    for (const p in parent_racks) {
      let rr = parent_racks[p];
      let newX =  (rr.x + this.props.origin.x) * this.props.pixels_per_meter;
      let newY = this.props.height - ((rr.y + this.props.origin.y) * this.props.pixels_per_meter);
      let width = rr.width * this.props.pixels_per_meter;
      let height = rr.height * this.props.pixels_per_meter;

      let color = "";
      //filter by percentile
      if (this.state.percent_or_quantity === 'percentile') {
        if (rr.p <= this.state.rack_criteria["0"].to) {
          color = this.state.rack_criteria["0"].color;
          if (low <= rr.assignments ) { low = rr.assignments }
        } else if (rr.p <= this.state.rack_criteria["1"].to) {
          color = this.state.rack_criteria["1"].color;
          if (middle <= rr.assignments) { middle = rr.assignments}
        } else if (rr.p <= this.state.rack_criteria["2"].to) {
          color = this.state.rack_criteria["2"].color;
          if (high <= rr.assignments) { high = rr.assignments }
        } else {
          color = this.state.rack_criteria["3"].color;
          if (higher <= rr.assignments) { higher = rr.assignments }
        }
      }

      //filter by percent
      let percent = Math.round(rr.assignments / highest_assignment * 100);
      if (this.state.percent_or_quantity === 'percent') {
        if (percent <= this.state.rack_criteria["0"].to) {
          color = this.state.rack_criteria["0"].color;
        } else if (percent <= this.state.rack_criteria["1"].to) {
          color = this.state.rack_criteria["1"].color;
        } else if (percent <= this.state.rack_criteria["2"].to) {
          color = this.state.rack_criteria["2"].color;
        } else {
          color = this.state.rack_criteria["3"].color;
        }
      }

      //filter by assignments
      if (this.state.percent_or_quantity === 'quantity') {
        if (rr.assignments <= this.state.rack_criteria["0"].to) {
          color = this.state.rack_criteria["0"].color;
          if (low <= rr.assignments ) { low = rr.assignments }
        } else if (rr.assignments <= this.state.rack_criteria["1"].to) {
          color = this.state.rack_criteria["1"].color;
          if (middle <= rr.assignments) { middle = rr.assignments}
        } else if (rr.assignments <= this.state.rack_criteria["2"].to) {
          color = this.state.rack_criteria["2"].color;
          if (high <= rr.assignments) { high = rr.assignments }
        } else {
          color = this.state.rack_criteria["3"].color;
          if (higher <= rr.assignments) { higher = rr.assignments }
        }
      }

      let rack_info = {id: p, props: {Visit: rr.assignments, Percentile: rr.p, Percent: percent}}
      racks.push(<Rect key={p} rack_info={rack_info} x={newX} y={newY} width={width} height={height} fill={color} onMouseOver={this.makeToolTip} onMouseOut={this.closeToolTip}/*onClick={()=>console.log(p)}*//>);
    }

    this.setState({racks: racks, p_legend: [low, middle, high, higher]}, /*()=>console.log(this.state.racks)*/);
  }

  repaint(e) {
    this.attach_background();
    this.makeHeatmap();
  }

  onSetType(e) {
    //console.log(e.target.dataset.type);
    this.setState({type: Object.assign({}, this.state.type, JSON.parse(e.target.dataset.type))}, ()=>this.repaint());
  }

  //this.setState((prevState, props) => ({display_list: !prevState.display_list}));
  setShowRack(e) {
    //console.log("toggle racks")
    this.setState((prevState, props) => ({show_rack: !prevState.show_rack}));
  }

  openModal(e) {
    //console.log("toggle modal");
    this.setState((prevState, props) => ({openModal: !prevState.openModal}));
  }

  openHModal(e) {
    this.setState((prevState, props) => ({openHModal: !prevState.openHModal}));
  }

  setCriteria(type, criteria) {
      //console.log("set criteria " + type + " " + JSON.stringify(criteria));
      setCookie("percent_or_quantity", type, 1);
      setCookie("rack_criteria", JSON.stringify(criteria), 1);
      this.setState({percent_or_quantity: type, rack_criteria: criteria}, ()=> this.makeRacks());
  }

  makeToolTip(e) {
    //console.log(e.target.attrs.rack);
    //console.log(this.refs['stage'].getPointerPosition());

    let tooltip = {
      id: e.target.attrs.rack_info.id,
      x: this.refs['stage'].getPointerPosition().x,
      y: this.refs['stage'].getPointerPosition().y,
      props: e.target.attrs.rack_info.props,
    }

    this.setState({tooltip_props: tooltip, tooltip_show: true});
  }

  closeToolTip(e) {
    this.setState({tooltip_show: false});
  }

  onSetMax(e) {
    let newMax = parseFloat(e, 10) * 3600000; //assume it's h

    //let newMax = parseFloat(e,10) * 60000; //change legend assume it's m
    // console.log((newMax)/(this.props.to - this.props.from));
    this.setState({legend: makeLegendNew(newMax),
                    max: (newMax)/(this.props.duration)},
                    ()=>this.repaint());
  }

  onSetShowLegend() {
    this.setState(prevState => ({legends_show: !prevState.legends_show}))
  }

  render() {
    let marginTop = "0px";
    if (this.props.height <= 700) {
      let adjust = (700 - this.props.height) / 2;
      marginTop = adjust + "px";
    }

    let width = this.props.width > 1055 ? this.props.width : 1055;

    return (
      <div>
        <div className="loading">{this.state.loading}</div>
        <div style={{opacity: this.state.loading.length > 0 ? .2 : 1}}>
          <div id={this.state.heatmap_id} style={{width: width, height: this.props.height, marginLeft: "10px", marginTop: marginTop}}/>
          <div style={{marginLeft: "10px", marginTop: this.props.height * -1, position: "absolute"}}>
            <Stage ref="stage" width={width} height={this.props.height}>
            <Layer>
                {this.state.show_rack? this.state.racks : []}
              </Layer>
              <ToolTip tooltip_props={this.state.tooltip_props} tooltip_show={this.state.tooltip_show}/>
            </Stage>
          </div>

          <div style={{marginLeft: "10px", marginTop: this.props.height * -1, position: "absolute"}}>
          {
            this.state.legends_show ?
            <div>
              <HeatmapLegend
                  legend={this.state.legend}
                  type={this.state.type}
                  onSetType={this.onSetType}
                  onOpenModal={this.openHModal}
              />
              <EditHeatmapCriteria
                openModal={this.state.openHModal}
                onOpenModal={this.openHModal}
                maxValue={this.state.legend[3]}
                onSetMax={this.onSetMax}
             />
              <img className="ShowHideLegends" alt="hide_legend" src={require("../img/hide_legends.png")} onClick={this.onSetShowLegend}/>
            </div>
            :
            <img className="ShowHideLegends" alt="hide_legend" src={require("../img/show_legends.png")} onClick={this.onSetShowLegend}/>
          }
          </div>
          {
            this.state.legends_show &&
            <div style={{marginLeft: "10px", marginTop: this.props.height * -1 + 60, position: "absolute"}}>
              <RackLegend p_legend={this.state.p_legend} setShowRack={this.setShowRack} show_rack={this.state.show_rack} openModal={this.openModal}/>
              <EditRackCriteria openModal={this.state.openModal} percent_or_quantity={this.state.percent_or_quantity} rack_criteria={this.state.rack_criteria} setCriteria={this.setCriteria}/>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({ authToken: state.authToken }),
)(WmsHeatmap);

/*
<Text
  x={this.props.width - 70}
  y={10}
  fontFamily="FontAwesome"
  text={'\uf205'}
  fontSize={20}
  fill={this.state.type === 'none' ? '#808080' : '#306EFF'}
  onClick={this.onSetTypeToNone}
/>

<div className="HeatmapLegendContainer">
  <div className="LegendTitle">RACK PERCENTILE</div>
  <div style={{display: "block"}}>
    <div className="Top" style={{backgroundColor: "green"}}/>&lt;={this.state.p_legend[0]}
    <div className="Top" style={{backgroundColor: "yellow"}}/>&gt;{this.state.p_legend[0]}, &lt;={this.state.p_legend[1]}
    <div className="Top"/>&gt;{this.state.p_legend[1]}, &lt;={this.state.p_legend[2]}
  </div>
</div>

<Text
  x={this.props.width - 40}
  y={10}
  fontFamily="FontAwesome"
  text={'\uf233'}
  fontSize={20}
  fill={this.state.show_rack? "#306EFF" : '#808080'}
  onClick={this.setShowRack}
  />

  //make ticket center origin
  let circle =
   <Circle key={'origin'}
    rack_info={{id: 'ORIGIN  ', props: {x: (ORIGIN.x *  METERS_PER_FOOT).toFixed(2), y: (ORIGIN.y * METERS_PER_FOOT).toFixed(2)}}}
    radius={7}
    fill={'black'}
    x={ORIGIN.x *  METERS_PER_FOOT * this.props.pixels_per_meter}
    y={this.props.height - (ORIGIN.y * METERS_PER_FOOT * this.props.pixels_per_meter)}
    onClick={this.makeToolTip}
    />

  racks.push(circle);
*/
