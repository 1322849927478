import { API_ROOT } from '../DashboardConfig';

const LOAD = 'pendingNodes/LOAD';
// const ADD = 'pendingNodes/ADD';
const ERROR = 'pendingNodes/ERROR';
// const REMOVE = 'pendingNodes/REMOVE';
// const UPDATE = 'pendingNodes/UPDATE';

const initialState = {
  nodes: [],
  status: 'pending',
  error: '',
};


export default function reducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case LOAD:
      // console.log(Object.assign({}, state, { pendingNodes: action.payload, status: 'success' }));
      return Object.assign({}, state, { nodes: action.payload, status: 'success', error: '' });
    case ERROR:
      return Object.assign({}, state, { error: action.payload });
    case 'LOGOUT':
      return initialState;
    default:
      return state;
  }
}

export function loadPendingNodes(pendingNodes) {
  return { type: LOAD, payload: pendingNodes.nodes };
}

export function fetchPendingNodes() {
  return (dispatch, getState) => {
    const { authToken, sites } = getState();
    // console.log(sites);

    if (authToken === null || authToken === undefined) {
      return;
    }

    const url = `${API_ROOT}sites/${sites.currentSite.site}/nodes`;
    const init = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + authToken,
      },
    };
    fetch(url, init)
      //.then(resp=>{return router.transitionTo('Login')})
      .then(resp => resp.json())
      .catch(err => console.log('Pending Node Fetching Error :', err));
      // .then(json => dispatch(loadPendingNodes(json)));
  };
}

export function pendingNodeError(message) {
  return { type: ERROR, payload: message };
}
